import React, { useState, useEffect } from 'react';
import '../../../App.css';
import './Services-even.css';
import Grid from '@material-ui/core/Grid';
import {useTranslation} from "react-i18next";

const greyMultiLines = require('../../../images/player-graphics/grey-lines.png');

const ServicesEven = ({service}) => {

    const [lang, setLang] = useState('en');
    const [t, i18n] = useTranslation();

    useEffect(() => {
        setLang(i18n.languages[1]);
        console.log('player', i18n.languages[1]);
    }, [i18n.languages[1]]);

    return(
    <div>
      <div className="marginHorizontalGridWeb">
        <Grid container
              className="gridRowServices"
              spacing={7}
              direction="row"
              alignItems="flex-start"
              justify="center">
          <Grid item xs={5}
                style={{
                  textAlign: 'end'
                }}>
            <div style={{
                  marginLeft: '-18px',
                  paddingBottom: '30px',
                  textAlign: 'end',
                  background: `url(${greyMultiLines}) no-repeat left bottom`
                }}>
              <img src={service.image}
                   alt={service.alt}
                   className="imageServiceEven"/>
            </div>
          </Grid>
          <Grid item xs={7}>
            <div className="titleServiceEven">
              <img src={service.icon}
                   alt={service.altIcon}
                   className="iconTitleServiceEven"/>
              <div className="textTitleServiceEven">
                {service['title_'+ lang]}
              </div>
            </div>
            <div className="textServiceEven">
              {service['text_'+ lang]}
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="marginHorizontalGridTablet">
        <Grid container
              className="gridRowServices"
              spacing={7}
              direction="row"
              alignItems="flex-start"
              justify="center">
          <Grid item xs={4}
                style={{
                  textAlign: 'end'
                }}>
            <div className="imageDivTabletEven"
                 style={{
              marginLeft: '-18px',
              paddingBottom: '30px',
              textAlign: 'end',
              background: `url(${greyMultiLines}) no-repeat left bottom`
            }}>
              <img src={service.image}
                   alt={service.alt}
                   className="imageServiceTabletEven"/>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div className="titleServiceTabletEven">
              <img src={service.icon}
                   alt={service.altIcon}
                    className="iconTitleServiceTabletEven"/>
              <div className="textTitleServiceTabletEven">
                  {service['title_'+ lang]}
              </div>
            </div>
            <div className="textServiceTabletEven">
                {service['text_'+ lang]}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ServicesEven;