import React from 'react';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import 'react-modal-video/scss/modal-video.scss';

import Header from "./components/header/Header";
import { Provider } from 'react-redux';
import { createStore, combineReducers } from 'redux';
import selectedPageReducer from './store/reducers/selectedPage';
import selectedServiceReducer from './store/reducers/selectedService';
import selectedPrivacyPolicyReducer from './store/reducers/selectedPrivacyPolicy';

// Set global state variables through Redux
const rootReducer = combineReducers({
  selectedPage: selectedPageReducer,
  selectedService: selectedServiceReducer,
  selectedPrivacyPolicy: selectedPrivacyPolicyReducer,
});
const store = createStore(rootReducer);

const App = (props) => {

  return (
    <Provider store={store}>
      <div className="App">
        <Header />
        {/*<ComingSoon/>*/}
      </div>
    </Provider>
  );
};

export default App;
