import React, { useState, useEffect } from 'react';
import '../../../App.css';
import './Press-square.css'
import Grid from '@material-ui/core/Grid';
import {useTranslation} from "react-i18next";

const leftArrow = require('../../../images/left_arrow.png');
const rightArrow = require('../../../images/right_arrow.png');
const multiSquare = require('../../../images/player-graphics/squares-multi-color.png');

const PressSquare = ({press}) => {

  const [originalPressList, setOriginalPressList] = useState([]);
  const [pressList, setPressList] = useState([]);
  const [numPressList, setNumPressList] = useState({
    initial: 0,
    end: 5,
  });
  const [width, setWidth] = useState(0);
  const [numDependingWidth, setNumDependingWidth] = useState(5);

  const [lang, setLang] = useState('en');
  const [t, i18n] = useTranslation();

  useEffect(() => {
    setLang(i18n.languages[1]);
    console.log('player', i18n.languages[1]);
  }, [i18n.languages[1]]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let srch = press.filter((item) => item.id < numDependingWidth);
    setPressList(() => [...srch]);
    setOriginalPressList(() => [...press]);
  }, [press]);

  useEffect(() => {
    let wdth = width;
    if(wdth === 0) {
      wdth = window.innerWidth;
      setWidth(wdth);
    }
    if(wdth < 960 && wdth >= 600) {
      setNumDependingWidth(4);
      setNumPressList((prev) => {
        return({
          initial: prev.initial,
          end: prev.initial + 4,
        })
      });
    } else if(wdth < 600) {
      setNumDependingWidth(3);
      setNumPressList((prev) => {
        return({
          initial: prev.initial,
          end: prev.initial + 3,
        })
      });
    } else {
      setNumDependingWidth(5);
      setNumPressList((prev) => {
        return({
          initial: prev.initial,
          end: prev.initial + 5,
        })
      });
    }
  }, [width]);

  const handleResize = () => {
    const windowSize = window.innerWidth;
    setWidth(windowSize);
  };

  useEffect(() => {
    let srch = press.filter((item) => item.id < numDependingWidth);
    setPressList(() => [...srch]);
    setOriginalPressList(() => [...press]);
  }, []);

  useEffect(() => {
    let srch = press.filter((item) => item.id > numPressList.initial && item.id < numPressList.end);
    setPressList(() => [...srch]);
    setOriginalPressList(() => [...press]);
  }, [numPressList.initial, numPressList.end]);

  const goBackward = () => {
    let initial = numPressList.initial - 1;
    let end = numPressList.end - 1;

    if(initial < 0) {
      let init = 0;
      let ends = 1;
      setNumPressList((nums) => {
        nums.initial = init;
        nums.end = ends;
        return nums;
      });
      setPressList(() => originalPressList.filter((item, index) => index + 1 > init && index + 1 < ends));
    } else {
      setNumPressList((nums) => {
        nums.initial = initial;
        nums.end = end;
        return nums;
      });
      setPressList(() => originalPressList.filter((item, index) => index + 1 > initial && index + 1 < end));
    }
  };

  const goForward = () => {
    let initial = numPressList.initial + 1;
    let end = numPressList.end + 1;

    if(end > originalPressList.length+1) {
      let init = originalPressList.length;
      let ends = originalPressList.length + 1;

      setNumPressList((nums) => {
        nums.initial = init;
        nums.end = ends;
        return nums;
      });
      setOriginalPressList(() => originalPressList.filter((item, index) => index + 1 > init && index + 1 < ends));
    } else {
      setNumPressList((nums) => {
        nums.initial = initial;
        nums.end = end;
        return nums;
      });
      setOriginalPressList(() => originalPressList.filter((item, index) => index + 1 > initial && index + 1 < end));
    }
  };

  return(
    <div>
      <div className="smallSquaresImage" style={{
        position: 'absolute',
        right: 0,
        width: '518px',
        marginTop: '-20px',
        marginRight: '25px',
        /* Full height */
        height: '28px',
        /* Center and scale the image nicely */
        background: `url(${multiSquare}) no-repeat right`,
        backgroundSize: 'cover'
      }}/>
      <Grid container
            className="gridPressFull"
            style={{
              backgroundColor: '#f1f1f0',
              paddingTop: '20px',
              paddingBottom: '20px',
              height: '290px'
            }}
            spacing={0}
            direction="row"
            alignItems="center"
            justify="center">
        {
          (press && numPressList.initial > 0) ?
          <Grid item className="shieldPress" xs={1}
                onClick={() => goBackward()}>
            <div className="arrowsDiv" style={{
              /* Full height */
              width: '100%',
              height: '100%',
              margin: 'auto',
              cursor: 'pointer',
            }}>
              <img src={leftArrow}
                   alt="left-arrow"
                   className="arrowsImg"/>
            </div>
          </Grid> : <Grid item className="shieldPress" xs={1}></Grid>
        }
        <Grid item xs={10}>
          <Grid container
                spacing={2}
                direction="row"
                alignItems="center"
                justify="center">
            {
              (pressList && pressList[0]) ?
                <Grid item xs={6} sm={4} md={3}>
                  <a href={pressList[0].url}
                     target="_blank"
                     rel="noopener noreferrer"
                     className="squarePress">
                    <img src={pressList[0].image}
                         alt={pressList[0].alt}
                         className="imageSquarePress"/>
                  </a>
                </Grid> : null
            }
            {
              (pressList && pressList[1]) ?
                <Grid item xs={6} sm={4} md={3}>
                  <a href={pressList[1].url}
                     target="_blank"
                     rel="noopener noreferrer"
                     className="squarePress">
                    <img src={pressList[1].image}
                         alt={pressList[1].alt}
                         className="imageSquarePress"/>
                  </a>
                </Grid> : null
            }
            {
              (pressList && pressList[2]) ?
                <Grid item xs={6} sm={4} md={3}>
                  <a href={pressList[2].url}
                     target="_blank"
                     rel="noopener noreferrer"
                     className="squarePress">
                    <img src={pressList[2].image}
                         alt={pressList[2].alt}
                         className="imageSquarePress"/>
                  </a>
                </Grid> : null
            }
            {
              (pressList && pressList[3]) ?
                <Grid item xs={6} sm={4} md={3}>
                  <a href={pressList[3].url}
                     target="_blank"
                     rel="noopener noreferrer"
                     className="squarePress">
                    <img src={pressList[3].image}
                         alt={pressList[3].alt}
                         className="imageSquarePress"/>
                  </a>
                </Grid> : null
            }
          </Grid>
        </Grid>
        {
          (press && numPressList.end <= press.length) ?
            <Grid item className="shieldPress" xs={1}
                  onClick={() => goForward()}>
              <div className="arrowsDiv" style={{
                /* Full height */
                width: '100%',
                height: '100%',
                margin: 'auto',
                cursor: 'pointer',
              }}>
                <img src={rightArrow}
                     alt="right-arrow"
                     className="arrowsImg"/>
              </div>
            </Grid> : <Grid item className="shieldPress" xs={1}></Grid>
        }
      </Grid>
      <Grid container
            className="gridRowPressText"
            spacing={0}
            style={{
              height: '55px',
              marginTop: '15px'
            }}
            direction="row"
            alignItems="center"
            justify="center">
        <Grid item xs={1}>
        </Grid>
        <Grid item xs={10}>
          <Grid container
                spacing={2}
                direction="row"
                alignItems="center"
                justify="center">
            {
              (pressList && pressList[0]) ?
                <Grid item xs={6} sm={4} md={3}>
                  <a href={pressList[0].url}
                     target="_blank"
                     rel="noopener noreferrer"
                     className="squareNamePress">
                    {pressList[0].media}
                  </a>
                </Grid> : null
            }
            {
              (pressList && pressList[1]) ?
                <Grid item xs={6} sm={4} md={3}>
                  <a href={pressList[1].url}
                     target="_blank"
                     rel="noopener noreferrer"
                     className="squareNamePress">
                    {pressList[1].media}
                  </a>
                </Grid> : null
            }
            {
              (pressList && pressList[2]) ?
                <Grid item xs={6} sm={4} md={3}>
                  <a href={pressList[2].url}
                     target="_blank"
                     rel="noopener noreferrer"
                     className="squareNamePress">
                    {pressList[2].media}
                  </a>
                </Grid> : null
            }
            {
              (pressList && pressList[3]) ?
                <Grid item xs={6} sm={4} md={3}>
                  <a href={pressList[3].url}
                     target="_blank"
                     rel="noopener noreferrer"
                     className="squareNamePress">
                    {pressList[3].media}
                  </a>
                </Grid> : null
            }
          </Grid>
        </Grid>
        <Grid item xs={1}>
        </Grid>
      </Grid>
    </div>
  )
};

export default PressSquare;