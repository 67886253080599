export const brandsRow = [{
  id: 1,
  title_es: 'Creación de contenido',
  title_en: 'Content creation',
  title_pt: 'Criação de conteúdo',
  desc: 1,
  media: [
    {
      urlMedia: require('../images/brands/LO-.jpg'),
      alt: 'leila-ouahabi-magari-sound',
      titleMedia: 'Leila Ouahabi x Magari Sound',
      titleMediaSmall1: 'Leila Ouahabi',
      titleMediaSmall2: 'Magari Sound',
      subtitleMedia: '"Hardpods PRO"'
    }, {
      urlMedia: require('../images/brands/video-images/viola-callligaris-foodspring.jpg'),
      alt: 'viola-calligaris-foodspring',
      titleMedia: 'Viola Calligaris x Foodspring',
      titleMediaSmall1: 'Viola Calligaris',
      titleMediaSmall2: 'Foodspring',
      subtitleMedia: '"Proteína Whey"',
      videoMedia: 'IRbcHXrPrjw'
    }, {
      urlMedia: require('../images/brands/video-images/carla-armengol-sprinter.jpg'),
      alt: 'carla-armengol-sprinter',
      titleMedia: 'Carla Armengol x Sprinter',
      titleMediaSmall1: 'Carla Armengol',
      titleMediaSmall2: 'Sprinter',
      subtitleMedia: '"Sprinterball Challenge"',
      videoMedia: 'JL7rgMfNos8'
    },
  ]
}, {
  id: 2,
  title_es: 'Campañas de publicidad',
  title_en: 'Advertising campaigns',
  title_pt: 'Campanhas de publicidade',
  desc: 2,
  media: [
    {
      urlMedia: require('../images/brands/video-images/estefania-banini-nike-antes-que-nada.jpg'),
      alt: 'estefania-banini-nike-antes-que-nada',
      titleMedia: 'Estefanía Banini x Nike',
      titleMediaSmall1: 'Estefanía Banini',
      titleMediaSmall2: 'Nike',
      subtitleMedia: '"Antes que nada"',
      videoMedia: 'ttI7fzr2-6M'
    }, {
      urlMedia: require('../images/brands/video-images/fabiana-simoes-guarana-antarctica.jpg'),
      alt: 'fabiana-simoes-guarana-antarctica',
      titleMedia: 'Fabiana Simões x Guaraná Antarctica',
      titleMediaSmall1: 'Fabiana Simões',
      titleMediaSmall2: 'Guaraná Antarctica',
      subtitleMedia: '"Seleção Feminina é #CoisaNossa"',
      videoMedia: '8wsDd_MqXwM'
    }, {
      urlMedia: require('../images/brands/BP-MR.jpg'),
      alt: 'brenda-perez-miro',
      titleMedia: 'Brenda Pérez x Miró',
      titleMediaSmall1: 'Brenda Pérez',
      titleMediaSmall2: 'Miró',
      subtitleMedia: 'Outdoors y publicidad en tienda'
    },
  ]
}, {
  id: 3,
  title_es: 'Patrocinio deportivo',
  title_en: 'Sports Sponsorship',
  title_pt: 'Patrocínio esportivo',
  desc: 3,
  media: [
    {
      urlMedia: require('../images/brands/CP.jpg'),
      alt: 'claudia-pina-nike',
      titleMedia: 'Claudia Pina x Nike',
      titleMediaSmall1: 'Claudia Pina',
      titleMediaSmall2: 'Nike',
      subtitleMedia: ''
    }, {
      urlMedia: require('../images/brands/NB-TP.jpg'),
      alt: 'tatiana-pinto-new-balance',
      titleMedia: 'Tatiana Pinto x New Balance',
      titleMediaSmall1: 'Tatiana Pinto',
      titleMediaSmall2: 'New Balance',
      subtitleMedia: ''
    }, {
      urlMedia: require('../images/brands/MC.jpg'),
      alt: 'marta-carro-puma',
      titleMedia: 'Marta Carro x Puma',
      titleMediaSmall1: 'Marta Carro',
      titleMediaSmall2: 'Puma',
      subtitleMedia: ''
    },
  ]
}, {
  id: 4,
  title_es: 'Eventos de activación de marca',
  title_en: 'Brand-activation events',
  title_pt: 'Eventos de ativação de marca',
  desc: 4,
  media: [
    {
      urlMedia: require('../images/brands/video-images/leila-ouahabi-showrrom-nike.jpg'),
      alt: 'leila-ouahabi-showrrom-nike',
      titleMedia: 'Leila Ouahabi x Showroom Nike',
      titleMediaSmall1: 'Leila Ouahabi',
      titleMediaSmall2: 'Showroom Nike',
      subtitleMedia: 'The Shopping Night, Barcelona',
      videoMedia: 'YAQ9CUiGk94'
    }, {
      urlMedia: require('../images/brands/BP-AIRE.jpg'),
      alt: 'brenda-perez-aire-ancient-baths',
      titleMedia: 'Brenda Pérez x Aire Ancient Baths',
      titleMediaSmall1: 'Brenda Pérez',
      titleMediaSmall2: 'Aire Ancient Baths',
      subtitleMedia: 'Diagonal Mar, Barcelona'
    }, {
      urlMedia: require('../images/brands/video-images/tatiana-pinto-new-balance.jpg'),
      alt: 'tatiana-pinto-new-balance',
      titleMedia: 'Tatiana Pinto x New Balance',
      titleMediaSmall1: 'Tatiana Pinto',
      titleMediaSmall2: 'New Balance',
      subtitleMedia: 'Lanzamiento Tekela y Furon, Lisboa',
      videoMedia: 'J1c96IsZakw'
    },
  ]
}];

export const otherActions1 = [{
  id: 1,
  url: require('../images/brands/other_actions_1.png'),
  alt: 'participacion-en-conferencias',
  actionText1_es: 'PARTICIPACIÓN',
  actionText2_es: 'EN CONFERENCIAS',
  actionText1_en: 'PARTICIPATION',
  actionText2_en: 'IN CONFERENCES',
  actionText1_pt: 'PARTICIPAÇÃO',
  actionText2_pt: 'EM CONFERÊNCIAS',
}, {
  id: 2,
  url: require('../images/brands/other_actions_2.png'),
  alt: 'charlas-eduactivas',
  actionText1_es: 'CHARLAS',
  actionText2_es: 'EDUCATIVAS',
  actionText1_en: 'EDUCATIONAL',
  actionText2_en: 'TALKS',
  actionText1_pt: 'PALESTRAS',
  actionText2_pt: 'EDUCATIVAS',
}, {
  id: 3,
  url: require('../images/brands/other_actions_3.png'),
  alt: 'aparaciones-personales',
  actionText1_es: 'APARICIONES',
  actionText2_es: 'PERSONALES',
  actionText1_en: 'PERSONAL',
  actionText2_en: 'APPEARANCES',
  actionText1_pt: 'APARIÇÕES',
  actionText2_pt: 'PESSOAIS',
}];
export const otherActions2 = [{
  id: 4,
  url: require('../images/brands/other_actions_4.png'),
  alt: 'masterclasses',
  actionText1_es: 'MASTERCLASSES',
  actionText2_es: '',
  actionText1_en: 'MASTERCLASSES',
  actionText2_en: '',
  actionText1_pt: 'MASTERCLASSES',
  actionText2_pt: '',
}, {
  id: 5,
  url: require('../images/brands/other_actions_5.png'),
  alt: 'video-personalizados',
  actionText1_es: 'VIDEOS',
  actionText2_es: 'PERSONALIZADOS',
  actionText1_en: 'TAILOR-MADE',
  actionText2_en: 'VIDEOS',
  actionText1_pt: 'VÍDEOS',
  actionText2_pt: 'PERSONALIZADOS',
}, ];