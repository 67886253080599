import React, { useState, useEffect } from 'react';
import '../../App.css';
import './About.css'
import Footer from "../footer/Footer";

import Grid from '@material-ui/core/Grid';
import {useTranslation} from "react-i18next";
import DocumentMeta from 'react-document-meta';

const blueLines = require('../../images/tres_rayas.png');
const triangulo = require('../../images/about/TrianguloFilosofia.png');
const unik = require('../../images/about/unikK.png');
const twoBlueLines = require('../../images/brands/twoBlueLines.png');
const bigGreyLines = require('../../images/greyBigLines.png');
const blueThreeLines = require('../../images/about/three-blue-lines.png');
const visionRow = require('../../images/about/vision_Mesa de trabajo 1.png');
const visionRow_en = require('../../images/about/unik-sports-management-vision-en.jpg');
const visionRow_pt = require('../../images/about/visao_pt.png');
const visionRowSmall = require('../../images/about/vision-mobile.png');
const visionRowSmall_en = require('../../images/about/vision-mobile-en.jpg');
const greySquare = require('../../images/about/greySquare.png');
const blueSquare = require('../../images/about/blueSquare.png');

const agd_abogados = require('../../images/about/agd_abogados.png');
const clinica_integrativa = require('../../images/about/clinica_integrativa.png');
const ecocinema = require('../../images/about/ecocinema.png');
const english_football = require('../../images/about/english_football.png');
const johan_cruyff = require('../../images/about/johan-cruyff.png');
const escuela_universitaria_madrid = require('../../images/about/escuela_universitaria_madrid.png');
const fs = require('../../images/about/fs.png');
const gomez_villares_atencia = require('../../images/about/gomez_villares_&_atencia.png');
const grupo_lx = require('../../images/about/grupo_lx.png');
const hedron_hr = require('../../images/about/hedron_hr.png');
const ricobaldi = require('../../images/about/ricobaldi.png');
const lax_society = require('../../images/about/lax-society.png');
const sport_madness = require('../../images/about/sport_madness.png');
const sportfem = require('../../images/about/sportfem.png');
const universidad_camilo_jose_cela = require('../../images/about/universidad_camilo_jose_cela.png');
const movement_active_research = require('../../images/about/movement-active-research.png');

const carlota = require('../../images/about/carlota-planas-unik-sports-management.png');
const arkaitz = require('../../images/about/arkaitz-coca-unik-sports-management.png');

const About = (props) => {
  const [width, setWidth] = useState(0);
  const [lang, setLang] = useState('en');
  const [t, i18n] = useTranslation();

  useEffect(() => {
    setLang(i18n.languages[1]);
    console.log('player', i18n.languages[1]);
  }, [i18n.languages[1]]);

  const meta = {
    title: 'UNIK SPORTS MANAGEMENT | Filosofía | Fútbol femenino',
    description: 'Nuestra misión es contribuir a la profesionalización en la gestión del fútbol femenino, difundir sus valores y facilitar la relación entre jugadoras, clubs y marcas',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let wdth = width;
    if(wdth === 0) {
      wdth = window.innerWidth;
      setWidth(wdth);
    }
  }, [width]);

  const handleResize = () => {
    const windowSize = window.innerWidth;
    setWidth(windowSize);
  };

  return(
    <DocumentMeta {...meta}>
      <div className="aboutDiv">
      <div style={{
        background: `url(${triangulo}) no-repeat right top`,
        backgroundSize: '55% auto',
        backgroundColor: '#052750'
      }}>
        <div className="serviceLabelRow">
          <div className="threeLinesServices">
            <img className="linesServices"
                 src={blueLines}
                 alt='blue-lines'/>
          </div>
          <div className="philosophyLabel">
            {t('ABOUT')}
          </div>
        </div>

        <div className="serviceLabelRowSmall">
          <div className="threeLinesServicesSmall">
            <img className="lines"
                 src={blueLines}
                 alt='blue-lines'/>
          </div>
          <div className="philosophyLabelSmall">
            {t('ABOUT')}
          </div>
        </div>

        <div className="titlePhilosophyTextWeb">
          <b>UNIK SPORTS MANAGEMENT </b>{t('ABOUT-TITLE-WEB-1')}<br/>
          {t('ABOUT-TITLE-WEB-2')}<br/>
          {t('ABOUT-TITLE-WEB-3')}<br/>
          {t('ABOUT-TITLE-WEB-4')}<b>{t('ABOUT-TITLE-WEB-5')}</b>
        </div>
        <div className="titlePhilosophyTextSmall">
          <b>UNIK SPORTS MANAGEMENT</b><br/>
          {t('ABOUT-TITLE-SMALL-1')}<br/>
          {t('ABOUT-TITLE-SMALL-2')}<br/>
          {t('ABOUT-TITLE-SMALL-3')}<b>{t('ABOUT-TITLE-SMALL-4')}</b>
        </div>
        <div className="divGridTextAboutDescription">
          <Grid container
                className="gridTextAboutDescription"
                spacing={0}
                direction="row"
                alignItems="center"
                justify="center">
            <Grid item className="iconTextAboutDescription"
                  xs={1}>
              <img className="iconTextBrandsDescriptionSmallLastImg"
                   src={twoBlueLines}
                   alt='two-blue-lines'/>
            </Grid>
            <Grid item className="textAboutDescription"
                  xs={11}>
              <div className="textAboutDescriptionText">
                {t('ABOUT-DESC-1-WEB-1')}<br/>
                {t('ABOUT-DESC-1-WEB-2')}
              </div>
              <div className="textAboutDescriptionTextSmall">
                {t('ABOUT-DESC-1-SMALL-1')}
                {t('ABOUT-DESC-1-SMALL-2')}
              </div>
            </Grid>
            <Grid item className="iconTextAboutDescription"
                  xs={1}>
              <img className="iconTextBrandsDescriptionSmallLastImg"
                   src={twoBlueLines}
                   alt='two-blue-lines'/>
            </Grid>
            <Grid item className="textAboutDescription"
                  xs={11}>
              <div className="textAboutDescriptionText">
                {t('ABOUT-DESC-2-WEB-1')}<br/>
                {t('ABOUT-DESC-2-WEB-2')}<br/>
                {t('ABOUT-DESC-2-WEB-3')}
              </div>
              <div className="textAboutDescriptionTextSmall">
                {t('ABOUT-DESC-2-SMALL-1')}
                {t('ABOUT-DESC-2-SMALL-2')}
                {t('ABOUT-DESC-2-SMALL-3')}
              </div>
            </Grid>
            <Grid item className="iconTextAboutDescription"
                  xs={1}>
              <img className="iconTextBrandsDescriptionSmallLastImg"
                   src={twoBlueLines}
                   alt='two-blue-lines'/>
            </Grid>
            <Grid item className="textAboutDescription"
                  xs={11}>
              <div className="textAboutDescriptionText">
                {t('ABOUT-DESC-3-WEB-1')}<br/>
                {t('ABOUT-DESC-3-WEB-2')}<br/>
                {t('ABOUT-DESC-3-WEB-3')}<br/>
                {t('ABOUT-DESC-3-WEB-4')}
              </div>
              <div className="textAboutDescriptionTextSmall">
                {t('ABOUT-DESC-3-SMALL-1')}
                {t('ABOUT-DESC-3-SMALL-2')}
                {t('ABOUT-DESC-3-SMALL-3')}
                {t('ABOUT-DESC-3-SMALL-4')}
                {t('ABOUT-DESC-3-SMALL-5')}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="whiteMisionDiv">
          <div className="titleRow">
            <div className="linesDivAbout">
              <img className="linesAbout"
                   src={blueThreeLines}
                   alt='three-blue-lines' />
            </div>
            <div className="titleAbout">
              {t('MISSION')}
            </div>
          </div>
          <div className="textMisionAboutDiv"
               style={{
            background: `url(${bigGreyLines}) no-repeat center`,
          }}>
            <div className="textMisionAbout">
              <div>
                <b>{t('MISSION-DESC-1')}<br/>
                   {t('MISSION-DESC-2')}</b><br/>
                {t('MISSION-DESC-3')}<br/>
                {t('MISSION-DESC-4')}
              </div>
            </div>
          </div>
        </div>
        <div className="whiteVisionDiv">
          <div className="titleRow">
            <div className="linesDivAbout">
              <img className="linesAbout"
                   src={blueThreeLines}
                   alt='three-blue-lines' />
            </div>
            <div className="titleAbout">
              {t('VISION')}
            </div>
          </div>
          {
            (lang === 'es') ?
                <div>
                  {
                    (width > 960) ?
                        <img className="visionImage"
                             src={visionRow}
                             alt='visión-info'/> :
                        <img className="visionImage"
                             src={visionRowSmall}
                             alt='visión-info'/>
                  }
                </div> : null
          }
          {
            (lang === 'en') ?
                <div>
                  {
                    (width > 960) ?
                        <img className="visionImage"
                             src={visionRow_en}
                             alt='visión-info'/> :
                        <img className="visionImage"
                             src={visionRowSmall_en}
                             alt='visión-info'/>
                  }
                </div> : null
          }
          {
            (lang === 'pt') ?
                <div>
                  {
                    (width > 960) ?
                        <img className="visionImage"
                             src={visionRow_pt}
                             alt='visión-info'/> :
                        <img className="visionImage"
                             src={visionRow_pt}
                             alt='visión-info'/>
                  }
                </div> : null
          }
        </div>
        <div className="whiteFactorsDiv">
          <div className="titleRow">
            <div className="linesDivAbout">
              <img className="linesAbout"
                   src={blueThreeLines}
                   alt='three-blue-lines'/>
            </div>
            <div className="titleAbout">
              {t('DISTINGUISHING FACTORS')}
            </div>
          </div>
          <div className="factorsInformationAbout"
               style={{
            background: `url(${unik}) no-repeat bottom left`,
          }}>
            <Grid container
                  className="gridFactorsDescription"
                  spacing={1}
                  direction="row"
                  alignItems="flex-start"
                  justify="flex-start">
              <Grid item xs={4} md={5} lg={4}>

                </Grid>
                <Grid item xs={4} md={3} lg={4}
                      className="secondColumnFactors">
                  <Grid container
                        className="gridFiltersFactors"
                        spacing={0}
                        direction="row"
                        alignItems="flex-start"
                        justify="flex-start">
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={blueSquare}
                           alt='blue-square'/>
                    </Grid>
                    <Grid item xs={11}>
                      {
                        (width > 1280) ?
                          <div className="textFactorsDescription">
                              {t('FACTORS-1-WEB-1')}<br/>
                              {t('FACTORS-1-WEB-2')}
                          </div> :
                          <div className="textFactorsDescriptionMedium">
                              {t('FACTORS-1-MEDIUM-1')}<br/>
                              {t('FACTORS-1-MEDIUM-2')}<br/>
                              {t('FACTORS-1-MEDIUM-3')}
                          </div>
                      }

                    </Grid>
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={greySquare}
                           alt='grey-square'/>
                    </Grid>
                    <Grid item xs={11}>
                      {
                        (width > 1280) ?
                          <div className="textFactorsDescription">
                              {t('FACTORS-2-WEB-1')}<br/>
                              {t('FACTORS-2-WEB-2')}
                          </div> :
                          <div className="textFactorsDescriptionMedium">
                              {t('FACTORS-2-MEDIUM-1')}<br/>
                              {t('FACTORS-2-MEDIUM-2')}
                          </div>
                      }
                    </Grid>
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={blueSquare}
                           alt='blue-square'/>
                    </Grid>
                    <Grid item xs={11}>
                      {
                        (width > 1280) ?
                          <div className="textFactorsDescription">
                              {t('FACTORS-3-WEB-1')}<br/>
                              {t('FACTORS-3-WEB-2')}
                          </div> :
                          <div className="textFactorsDescriptionMedium">
                              {t('FACTORS-3-MEDIUM-1')}<br/>
                              {t('FACTORS-3-MEDIUM-2')}
                          </div>
                      }
                    </Grid>
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={greySquare}
                           alt='grey-square'/>
                    </Grid>
                    <Grid item xs={11}>
                      {
                        (width > 1280) ?
                          <div className="textFactorsDescription">
                              {t('FACTORS-4-WEB-1')}<br/>
                              {t('FACTORS-4-WEB-2')}<br/>
                              {t('FACTORS-4-WEB-3')}
                          </div> :
                          <div className="textFactorsDescriptionMedium">
                              {t('FACTORS-4-MEDIUM-1')}<br/>
                              {t('FACTORS-4-MEDIUM-2')}<br/>
                              {t('FACTORS-4-MEDIUM-3')}<br/>
                              {t('FACTORS-4-MEDIUM-4')}
                          </div>
                      }
                    </Grid>
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={blueSquare}
                           alt='blue-square'/>
                    </Grid>
                    <Grid item xs={11}>
                      {
                        (width > 1280) ?
                          <div className="textFactorsDescription">
                              {t('FACTORS-5-WEB-1')}<br/>
                              {t('FACTORS-5-WEB-2')}
                          </div> :
                          <div className="textFactorsDescriptionMedium">
                              {t('FACTORS-5-MEDIUM-1')}<br/>
                              {t('FACTORS-5-MEDIUM-2')}<br/>
                              {t('FACTORS-5-MEDIUM-3')}
                          </div>
                      }
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4} md={3} lg={4}>
                  <Grid container
                        className="lastRowDescription"
                        spacing={0}
                        direction="row"
                        alignItems="flex-start"
                        justify="flex-start">
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={blueSquare}
                           alt='blue-square'/>
                    </Grid>
                    <Grid item xs={11}>
                      {
                        (width > 1280) ?
                          <div className="textFactorsDescription">
                              {t('FACTORS-6-WEB-1')}<br/>
                              {t('FACTORS-6-WEB-2')}
                          </div> :
                          <div className="textFactorsDescriptionMedium">
                              {t('FACTORS-6-MEDIUM-1')}<br/>
                              {t('FACTORS-6-MEDIUM-2')}
                          </div>
                      }
                    </Grid>
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={greySquare}
                           alt='grey-square'/>
                    </Grid>
                    <Grid item xs={11}>
                      {
                        (width > 1280) ?
                          <div className="textFactorsDescription">
                              {t('FACTORS-7-WEB-1')}<br/>
                              {t('FACTORS-7-WEB-2')}<br/>
                              {t('FACTORS-7-WEB-3')}
                          </div> :
                          <div className="textFactorsDescriptionMedium">
                              {t('FACTORS-7-MEDIUM-1')}<br/>
                              {t('FACTORS-7-MEDIUM-2')}<br/>
                              {t('FACTORS-7-MEDIUM-3')}
                          </div>
                      }
                    </Grid>
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={blueSquare}
                           alt='blue-square'/>
                    </Grid>
                    <Grid item xs={11}>
                      {
                        (width > 1280) ?
                          <div className="textFactorsDescription">
                              {t('FACTORS-8-WEB-1')}<br/>
                              {t('FACTORS-8-WEB-2')}
                          </div> :
                          <div className="textFactorsDescriptionMedium">
                              {t('FACTORS-8-MEDIUM-1')}<br/>
                              {t('FACTORS-8-MEDIUM-2')}
                          </div>
                      }
                    </Grid>
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={greySquare}
                           alt="grey-square"/>
                    </Grid>
                    <Grid item xs={11}>
                      {
                        (width > 1280) ?
                          <div className="textFactorsDescription">
                              {t('FACTORS-9-WEB-1')}<br/>
                              {t('FACTORS-9-WEB-2')}
                          </div> :
                          <div className="textFactorsDescriptionMedium">
                              {t('FACTORS-9-MEDIUM-1')}<br/>
                              {t('FACTORS-9-MEDIUM-2')}<br/>
                              {t('FACTORS-9-MEDIUM-3')}<br/>
                              {t('FACTORS-9-MEDIUM-4')}
                          </div>
                      }
                    </Grid>
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={blueSquare}
                           alt="blue-square"/>
                    </Grid>
                    <Grid item xs={11}>
                      {
                        (width > 1280) ?
                          <div className="textFactorsDescription">
                              {t('FACTORS-10-WEB-1')}<br/>
                              {t('FACTORS-10-WEB-2')}
                          </div> :
                          <div className="textFactorsDescriptionMedium">
                              {t('FACTORS-10-MEDIUM-1')}<br/>
                              {t('FACTORS-10-MEDIUM-2')}<br/>
                              {t('FACTORS-10-MEDIUM-3')}<br/>
                              {t('FACTORS-10-MEDIUM-4')}
                          </div>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container
                    className="gridFactorsDescriptionSmall"
                    spacing={0}
                    direction="row"
                    alignItems="flex-start"
                    justify="flex-start">
                <Grid item xs={6}>
                  <Grid container
                        className="rowDescriptionSmall"
                        id="firstRowDescriptionSmall"
                        spacing={0}
                        direction="row"
                        alignItems="flex-start"
                        justify="flex-start">
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={blueSquare}
                           alt="blue-square"/>
                    </Grid>
                    <Grid item xs={11}>
                      <div className="textFactorsDescription">
                          {t('FACTORS-1-SMALL-1')}<br/>
                          {t('FACTORS-1-SMALL-2')}
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={greySquare}
                           alt="grey-square"/>
                    </Grid>
                    <Grid item xs={11}>
                      <div className="textFactorsDescription">
                          {t('FACTORS-2-SMALL-1')}<br/>
                          {t('FACTORS-2-SMALL-2')}
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={blueSquare}
                           alt="blue-square"/>
                    </Grid>
                    <Grid item xs={11}>
                      <div className="textFactorsDescription">
                          {t('FACTORS-3-SMALL-1')}<br/>
                          {t('FACTORS-3-SMALL-2')}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <Grid container
                        className="rowDescriptionSmall"
                        spacing={0}
                        direction="row"
                        alignItems="flex-start"
                        justify="flex-start">
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={blueSquare}
                           alt="blue-square"/>
                    </Grid>
                    <Grid item xs={11}>
                      <div className="textFactorsDescription">
                          {t('FACTORS-4-SMALL-1')}<br/>
                          {t('FACTORS-4-SMALL-2')}<br/>
                          {t('FACTORS-4-SMALL-3')}
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={greySquare}
                           alt="grey-square"/>
                    </Grid>
                    <Grid item xs={11}>
                      <div className="textFactorsDescription">
                          {t('FACTORS-5-SMALL-1')}<br/>
                          {t('FACTORS-5-SMALL-2')}
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={blueSquare}
                           alt="blue-square"/>
                    </Grid>
                    <Grid item xs={11}>
                      <div className="textFactorsDescription">
                          {t('FACTORS-6-SMALL-1')}<br/>
                          {t('FACTORS-6-SMALL-2')}
                      </div>
                    </Grid><Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={blueSquare}
                           alt="blue-square"/>
                    </Grid>
                    <Grid item xs={11}>
                      <div className="textFactorsDescription">
                          {t('FACTORS-7-SMALL-1')}<br/>
                          {t('FACTORS-7-SMALL-2')}<br/>
                          {t('FACTORS-7-SMALL-3')}
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={blueSquare}
                           alt="blue-square"/>
                    </Grid>
                    <Grid item xs={11}>
                      <div className="textFactorsDescription">
                          {t('FACTORS-8-SMALL-1')}<br/>
                          {t('FACTORS-8-SMALL-2')}
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={blueSquare}
                           alt="blue-square"/>
                    </Grid>
                    <Grid item xs={11}>
                      <div className="textFactorsDescription">
                          {t('FACTORS-9-SMALL-1')}<br/>
                          {t('FACTORS-9-SMALL-2')}
                      </div>
                    </Grid>
                    <Grid item xs={1}>
                      <img className="iconFactorsDescription"
                           src={blueSquare}
                           alt="blue-square"/>
                    </Grid>
                    <Grid item xs={11}>
                      <div className="textFactorsDescription">
                          {t('FACTORS-10-SMALL-1')}<br/>
                          {t('FACTORS-10-SMALL-2')}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className="directorsDiv">
            <div className="titleRow">
              <div className="linesDivAbout">
                <img className="linesAbout"
                     src={blueThreeLines}
                     alt="three-blue-lines"/>
              </div>
              <div className="titleAboutWhite">
                  {t('FOUNDERS')}              </div>
            </div>
            <div className="descriptionDirectors">
                {t('FOUNDERS-DESC-WEB-1')}<br/>
                {t('FOUNDERS-DESC-WEB-2')}<br/>
                {t('FOUNDERS-DESC-WEB-3')}
            </div>
            <div className="descriptionDirectorsSmall">
                {t('FOUNDERS-DESC-SMALL-1')}<br/>
                {t('FOUNDERS-DESC-SMALL-2')}<br/>
                {t('FOUNDERS-DESC-SMALL-3')}<br/>
                {t('FOUNDERS-DESC-SMALL-4')}<br/>
                {t('FOUNDERS-DESC-SMALL-5')}
            </div>
          </div>
          <Grid container
                className="gridDirectors"
                spacing={1}
                direction="row"
                alignItems="flex-start"
                justify="flex-start">
            <Grid item xs={12} md={6}
                  className="gridDirectorsLeft">
              <Grid container
                    className="gridDirectorsIndividual"
                    spacing={0}
                    direction="row"
                    alignItems="flex-start"
                    justify="flex-start">
                <Grid item xs={5}>
                  <div className="directorsPhotoDiv">
                    <img className="directorsPhotoImg"
                         src={arkaitz}
                         alt="arkaitz-coca-unik-sports-management"/>
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <div>
                    <div className="directorsNameText">
                      ARKAITZ<br/>
                      COCA
                    </div>
                    <div className="directorsDescriptionText">
                        {t('ARKAITZ-DESC-1')}<br/><br/>
                        {t('ARKAITZ-DESC-2')}<br/><br/>
                        {t('ARKAITZ-DESC-3')}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item  xs={12} md={6}
                  className="gridDirectorsRight">
              <Grid container
                    className="gridDirectorsIndividual"
                    spacing={0}
                    direction="row"
                    alignItems="flex-start"
                    justify="flex-start">
                <Grid item xs={5}>
                  <div className="directorsPhotoDiv">
                    <img className="directorsPhotoImg"
                         src={carlota}
                         alt="carlota-planas-unik-sports-management"/>
                  </div>
                </Grid>
                <Grid item xs={7}>
                  <div>
                    <div className="directorsNameText">
                      CARLOTA<br/>
                      PLANAS
                    </div>
                    <div className="directorsDescriptionText">
                        {t('CARLOTA-DESC-1')}<br/><br/>
                        {t('CARLOTA-DESC-2')}<br/><br/>
                        {t('CARLOTA-DESC-3')}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="whiteCollaboratorsDiv">
          <div className="titleRow">
            <div className="linesDivAbout">
              <img className="linesAbout"
                   src={blueThreeLines}
                   alt="blue-three-lines"/>
            </div>
            <div className="titleAbout">
                {t('PARTNERS')}
            </div>
          </div>
          <Grid container
                className="gridCollaborators"
                spacing={3}
                direction="row"
                alignItems="flex-start"
                justify="flex-start">
            <Grid item xs={12} md={6}>
              <div className="titleCollaborators">
                <div className="titleCollaboratorsText">
                    {t('SPORTS')}
                </div>
              </div>
              <Grid container
                    className="gridCollaboratorsLogos"
                    spacing={4}
                    direction="row"
                    alignItems="center"
                    justify="center">
                <Grid item xs={4}>
                  <a href="https://www.instagram.com/ricobaldisoccer/"
                     target="_blank"
                     rel="noopener noreferrer">
                    <img className="gridCollaboratorsLogosImg"
                         src={ricobaldi}
                         alt="ricobaldi"/>
                  </a>
                </Grid>
                <Grid item xs={4}>
                  <a href="https://laxsocietylimited.com/"
                     target="_blank"
                     rel="noopener noreferrer">
                    <img className="gridCollaboratorsLogosImg"
                         src={lax_society}
                         alt="lax_society"/>
                  </a>
                </Grid>
                {/*<Grid item xs={4}>*/}
                  {/*<div>*/}
                    {/*<img className="gridCollaboratorsLogosImg"*/}
                         {/*src={bewolfish}/>*/}
                  {/*</div>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={4}>
                  <a href="https://sportmadness.es/"
                     target="_blank"
                     rel="noopener noreferrer">
                    <img className="gridCollaboratorsLogosImg"
                         src={sport_madness}
                         alt="sport_madness"/>
                  </a>
                </Grid>*/}
              </Grid>
            </Grid>
            <Grid item  xs={12} md={6}>
              <div className="titleCollaborators">
                <div className="titleCollaboratorsText">
                    {t('EDUCATION')}
                </div>
              </div>
              <Grid container
                    className="gridCollaboratorsLogos"
                    spacing={4}
                    direction="row"
                    alignItems="center"
                    justify="center">
                <Grid item xs={4}>
                  <a href="https://universidadeuropea.es/real-madrid"
                     target="_blank"
                     rel="noopener noreferrer">
                    <img className="gridCollaboratorsLogosImg"
                         src={escuela_universitaria_madrid}
                         alt="escuela_universitaria_madrid"/>
                  </a>
                </Grid>
                <Grid item xs={4}>
                  <a href="https://www.englishlanesacademy.com/"
                     target="_blank"
                     rel="noopener noreferrer">
                    <img className="gridCollaboratorsLogosImg"
                         src={english_football}
                         alt="english_football"/>
                  </a>
                </Grid>
                <Grid item xs={4}>
                  <a href="https://johancruyffinstitute.com/en/"
                     target="_blank"
                     rel="noopener noreferrer">
                    <img className="gridCollaboratorsLogosImg"
                         src={johan_cruyff}
                         alt="johan_cruyff"/>
                  </a>
                </Grid>
                {/*<Grid item xs={4}>*/}
                  {/*<div>*/}
                    {/*<img className="gridCollaboratorsLogosImg"*/}
                         {/*src={universidad_camilo_jose_cela}/>*/}
                  {/*</div>*/}
                {/*</Grid>*/}
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="titleCollaborators">
                <div className="titleCollaboratorsText">
                    {t('MEDIA')}
                </div>
              </div>
              <Grid container
                    className="gridCollaboratorsLogos"
                    spacing={4}
                    direction="row"
                    alignItems="center"
                    justify="center">
                <Grid item xs={4}>
                  <a href="https://www.ecocinema.net/"
                     target="_blank"
                     rel="noopener noreferrer">
                    <img className="gridCollaboratorsLogosImg"
                         src={ecocinema}
                         alt="ecocinema"/>
                  </a>
                </Grid>
                <Grid item xs={4}>
                  <a href="http://www.grupolx.com.br/"
                     target="_blank"
                     rel="noopener noreferrer">
                    <img className="gridCollaboratorsLogosImg"
                         src={grupo_lx}
                         alt="grupo_lx"/>
                  </a>
                </Grid>
                {/*<Grid item xs={4}>
                  <a href="https://www.sportfem.es/"
                     target="_blank"
                     rel="noopener noreferrer">
                    <img className="gridCollaboratorsLogosImg"
                         src={sportfem}
                         alt="sportfem"/>
                  </a>
                </Grid>*/}
              </Grid>
            </Grid>
            <Grid item  xs={12} md={6}>
              <div className="titleCollaborators">
                <div className="titleCollaboratorsText">
                    {t('HEALTHCARE')}
                </div>
              </div>
              <Grid container
                    className="gridCollaboratorsLogos"
                    spacing={4}
                    direction="row"
                    alignItems="center"
                    justify="center">
                <Grid item xs={4}>
                  <a>
                    <img className="gridCollaboratorsLogosImg"
                         src={movement_active_research}
                         alt="movement_active_research"/>
                  </a>
                </Grid>
                <Grid item xs={4}>
                  <a href="https://www.clinicaintegrativabcn.com/"
                     target="_blank"
                     rel="noopener noreferrer">
                    <img className="gridCollaboratorsLogosImg"
                         src={clinica_integrativa}
                         alt="clinica_integrativa"/>
                  </a>
                </Grid>
                <Grid item xs={4}>
                  <a href="https://fisioweb.com/"
                     target="_blank"
                     rel="noopener noreferrer">
                    <img className="gridCollaboratorsLogosImg"
                         src={fs}
                         alt="fs"/>
                  </a>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className="titleCollaborators">
                <div className="titleCollaboratorsText">
                    {t('LEGAL')}
                </div>
              </div>
              <Grid container
                    className="gridCollaboratorsLogos"
                    spacing={4}
                    direction="row"
                    alignItems="center"
                    justify="center">
                <Grid item xs={4}>
                  <a href="https://agdabogados.com/"
                     target="_blank"
                     rel="noopener noreferrer">
                    <img className="gridCollaboratorsLogosImg"
                         src={agd_abogados}
                         alt="agd_abogados"/>
                  </a>
                </Grid>
                <Grid item xs={4}>
                  <a href="https://www.gva-atencia.com/"
                     target="_blank"
                     rel="noopener noreferrer">
                    <img className="gridCollaboratorsLogosImg"
                         src={gomez_villares_atencia}
                         alt="gomez_villares_atencia"/>
                  </a>
                </Grid>
              </Grid>
            </Grid>
            <Grid item  xs={12} md={6}>
              <div className="titleCollaborators">
                <div className="titleCollaboratorsText">
                    {t('HR')}
                </div>
              </div>
              <Grid container
                    className="gridCollaboratorsLogos"
                    spacing={4}
                    direction="row"
                    alignItems="center"
                    justify="center">
                <Grid item xs={4}>
                  <a href="https://www.hedron.es/"
                     target="_blank"
                     rel="noopener noreferrer">
                    <img className="gridCollaboratorsLogosImg"
                         src={hedron_hr}
                         alt="hedron_hr"/>
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <Footer page={'Filosofía'}/>
      </div>
    </DocumentMeta>
  )
};

export default About;