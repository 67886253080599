import React, { useState, useEffect } from 'react';
import '../../../App.css';
import './Brands-row.css'
import Grid from '@material-ui/core/Grid';
import ModalVideo from 'react-modal-video'
import {useTranslation} from "react-i18next";

const blue_lines = require('../../../images/brands/rayas_blue.png');
const leftArrow = require('../../../images/left_arrow.png');
const rightArrow = require('../../../images/right_arrow.png');
const grey_lines = require('../../../images/greyBigLinesSmall.png');

const BrandsRow = ({brandRow, rayas}) => {

  const [brandsList, setBrandsList] = useState([]);
  const [originalBrandsList, setOriginalBrandsList] = useState([]);
  const [numPressList, setNumPressList] = useState({
    initial: 0,
    end: 3,
  });

  const [openModalVideo, setOpenModalVideo] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState('');

  const [width, setWidth] = useState(0);
  const [numDependingWidth, setNumDependingWidth] = useState(5);
  const [lang, setLang] = useState('en');
  const [t, i18n] = useTranslation();

  useEffect(() => {
    setLang(i18n.languages[1]);
    console.log('player', i18n.languages[1]);
  }, [i18n.languages[1]]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    let srch = brandRow.media.filter((item, index) => index + 1 > 0 && index + 1 < 3);
    setBrandsList(() => [...srch]);
    setOriginalBrandsList(() => [...brandRow.media]);
  }, []);

  useEffect(() => {
    let wdth = width;
    if(wdth === 0) {
      wdth = window.innerWidth;
      setWidth(wdth);
    }
    if(wdth < 960) {
      setNumDependingWidth(3);
    } else {
      setNumDependingWidth(4);
    }
  }, [width]);

  const handleResize = () => {
    const windowSize = window.innerWidth;
    setWidth(windowSize);
  };

  const goBackward = () => {
    setNumPressList((nums) => {
      nums.initial = 0;
      nums.end = 3;
      return nums;
    });
    setBrandsList(() => originalBrandsList.filter((item, index) => index + 1 > 0 && index + 1 < 3));
  };

  const goForward = () => {
    setNumPressList((nums) => {
      nums.initial = 1;
      nums.end = 4;
      return nums;
    });
    setBrandsList(() => originalBrandsList.filter((item, index) => index + 1 > 1 && index + 1 < 4));
  };

  return(
    <div>
      {
        (rayas === 0) ?
          <img className="leftBlueLine" src={blue_lines}
               alt="blue-lines"/> :
          <img className="rightBlueLine" src={blue_lines}
               alt="blue-lines"/>
      }

      <div className="paddingBrandRow">
        <div className="titleBrand">
          {brandRow['title_'+lang]}
        </div>
        {
          (brandRow.desc === 1) ?
            <div>
              {
                (width > 960) ?
                  <div className="descBrand">
                    {t('ACTIVATION-DESC-1')}
                  </div> : <div className="descBrand">
                    {t('ACTIVATION-DESC-1')}
                  </div>
              }
            </div> : null
        }
        {
          (brandRow.desc === 2) ?
            <div>
              {
                (width > 960) ?
                  <div className="descBrand">
                    {t('ACTIVATION-DESC-2')}
                  </div> : <div className="descBrand">
                    {t('ACTIVATION-DESC-2')}
                  </div>
              }
            </div> : null
        }
        {
          (brandRow.desc === 3) ?
            <div>
              {
                (width > 960) ?
                  <div className="descBrand">
                    {t('ACTIVATION-DESC-3')}
                  </div> : <div className="descBrand">
                    {t('ACTIVATION-DESC-3')}
                  </div>
              }
            </div> : null
        }
        {
          (brandRow.desc === 4) ?
            <div>
              {
                (width > 960) ?
                  <div className="descBrand">
                    {t('ACTIVATION-DESC-4')}
                  </div> : <div className="descBrand">
                    {t('ACTIVATION-DESC-4')}
                  </div>
              }
            </div> : null
        }

        <Grid container
              className="brandsRowImages"
              spacing={0}
              direction="row"
              alignItems="center"
              justify="center">
          <Grid item xs={4}>
            <div className="divGridBrand">
              <div className="imgBrandRowDiv">
                {
                  (brandRow.media[0] && brandRow.media[0].videoMedia) ?
                    <img className="imgBrandRowVideo"
                         src={brandRow.media[0].urlMedia}
                         alt={brandRow.media[0].alt}
                         onClick={() => {
                           setOpenModalVideo(true);
                           setSelectedVideo(brandRow.media[0].videoMedia);
                         }} /> :
                    <img className="imgBrandRow"
                         src={brandRow.media[0].urlMedia}
                         alt={brandRow.media[0].alt}/>
                }
              </div>
              <div className="titleBrandRow">
                {brandRow.media[0].titleMedia}
              </div>
              <div className="subtitleBrandRow">
                {brandRow.media[0].subtitleMedia}
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="divGridBrand">
              <div className="imgBrandRowDiv">
                {
                  (brandRow.media[1] && brandRow.media[1].videoMedia) ?
                    <img className="imgBrandRowVideo"
                         src={brandRow.media[1].urlMedia}
                         alt={brandRow.media[1].alt}
                         onClick={() => {
                           setOpenModalVideo(true);
                           setSelectedVideo(brandRow.media[1].videoMedia);
                         }} /> :
                    <img className="imgBrandRow"
                         src={brandRow.media[1].urlMedia}
                         alt={brandRow.media[1].alt}/>
                }
              </div>
              <div className="titleBrandRow">
                {brandRow.media[1].titleMedia}
              </div>
              <div className="subtitleBrandRow">
                {brandRow.media[1].subtitleMedia}
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div className="divGridBrand">
              <div className="imgBrandRowDiv">
                {
                  (brandRow.media[2] && brandRow.media[2].videoMedia) ?
                    <img className="imgBrandRowVideo"
                         src={brandRow.media[2].urlMedia}
                         alt={brandRow.media[2].alt}
                         onClick={() => {
                           setOpenModalVideo(true);
                           setSelectedVideo(brandRow.media[2].videoMedia);
                         }} /> :
                    <img className="imgBrandRow"
                         src={brandRow.media[2].urlMedia}
                         alt={brandRow.media[2].alt}/>
                }
              </div>
              <div className="titleBrandRow">
                {brandRow.media[2].titleMedia}
              </div>
              <div className="subtitleBrandRow">
                {brandRow.media[2].subtitleMedia}
              </div>
            </div>
          </Grid>
        </Grid>

        <Grid container
              className="brandsRowImagesSmall"
              spacing={0}
              direction="row"
              alignItems="flex-start"
              justify="center">
          {
            (brandsList && numPressList.initial > 0) ?
              <Grid item className="shieldBrands" xs={1}
                    onClick={() => goBackward()}>

                <div className="arrowsDiv" style={{
                  /* Full height */
                  width: '100%',
                  height: '100%',
                  margin: 'auto',
                  cursor: 'pointer',
                }}>
                  <img src={leftArrow}
                       alt="left-arrows"
                       className="arrowsImg"/>
                </div>
              </Grid> : <Grid item className="shield" xs={1}></Grid>
          }
          <Grid item xs={10} sm={9}>
            <Grid container
                  className="gridNoMargin"
                  spacing={0}
                  direction="row"
                  alignItems="center"
                  justify="center">
              {
                (brandsList && brandsList[0]) ?
                  <Grid item xs={6}
                        className="latestCampaignGrid" >
                    <div className="divGridBrand">
                      <div className="imgBrandRowDiv">

                        {
                          (brandsList[0] && brandsList[0].videoMedia) ?
                              <div>
                                {
                                  (rayas === 0) ?
                                    <div>
                                      <img className="leftGreyLines" src={grey_lines}
                                           alt="grey-lines"/>
                                      <img className="imgBrandRowVideoWithLines"
                                           src={brandsList[0].urlMedia}
                                           alt={brandsList[0].alt}
                                           onClick={() => {
                                             setOpenModalVideo(true);
                                             setSelectedVideo(brandsList[0].videoMedia);
                                           }} />
                                    </div> : <img className="imgBrandRowVideo"
                                                 src={brandsList[0].urlMedia}
                                                 alt={brandsList[0].alt}
                                                 onClick={() => {
                                                   setOpenModalVideo(true);
                                                   setSelectedVideo(brandsList[0].videoMedia);
                                                 }} />
                                }
                              </div>
                             :
                            <div>
                              {
                                (rayas === 0) ?
                                  <div>
                                    <img className="leftGreyLines" src={grey_lines}
                                         alt="grey-lines"/>
                                    <img className="imgBrandRowWithLines"
                                         src={brandsList[0].urlMedia}
                                         alt={brandsList[0].alt}/>
                                  </div> : <img className="imgBrandRow"
                                               src={brandsList[0].urlMedia}
                                               alt={brandsList[0].alt}/>
                              }
                            </div>
                        }
                      </div>
                      <div className="titleBrandRow">
                        {brandsList[0].titleMediaSmall1}
                      </div>
                      <div className="titleBrandRow2">
                        {brandsList[0].titleMediaSmall2}
                      </div>
                      <div className="subtitleBrandRow">
                        {brandsList[0].subtitleMedia}
                      </div>
                    </div>
                  </Grid>: null
              }
              {
                (brandsList && brandsList[1]) ?
                  <Grid item xs={6}
                        className="latestCampaignGrid">
                    <div className="divGridBrand">
                      <div className="imgBrandRowDiv">
                        {
                          (brandsList[1] && brandsList[1].videoMedia) ?
                              <div>
                                {
                                  (rayas === 1) ?
                                      <div>
                                        <img className="rightGreyLines" src={grey_lines}
                                             alt="grey-lines"/>
                                        <img className="imgBrandRowVideoWithLines"
                                             src={brandsList[1].urlMedia}
                                             alt={brandsList[1].alt}
                                             onClick={() => {
                                               setOpenModalVideo(true);
                                               setSelectedVideo(brandsList[1].videoMedia);
                                             }} />
                                      </div> : <img className="imgBrandRowVideo"
                                                    src={brandsList[1].urlMedia}
                                                    alt={brandsList[1].alt}
                                                    onClick={() => {
                                                      setOpenModalVideo(true);
                                                      setSelectedVideo(brandsList[1].videoMedia);
                                                    }} />
                                }
                              </div> :
                              <div>
                                {
                                  (rayas === 1) ?
                                      <div>
                                        <img className="rightGreyLines" src={grey_lines}
                                             alt="grey-lines"/>
                                        <img className="imgBrandRowWithLines"
                                             src={brandsList[1].urlMedia}
                                             alt={brandsList[1].alt}/>
                                      </div> : <img className="imgBrandRow"
                                                    src={brandsList[1].urlMedia}
                                                    alt={brandsList[1].alt}/>
                                }
                              </div>
                        }
                      </div>
                      <div className="titleBrandRow">
                        {brandsList[1].titleMediaSmall1}
                      </div>
                      <div className="titleBrandRow2">
                        {brandsList[1].titleMediaSmall2}
                      </div>
                      <div className="subtitleBrandRow">
                        {brandsList[1].subtitleMedia}
                      </div>
                    </div>
                  </Grid>: null
              }
            </Grid>
          </Grid>
          {
            (brandsList && numPressList.end < 4) ?
              <Grid item className="shieldBrands" xs={1}
                    onClick={() => goForward()}>
                <div className="arrowsDiv" style={{
                  /* Full height */
                  width: '100%',
                  height: '100%',
                  margin: 'auto',
                  cursor: 'pointer',
                }}>
                  <img src={rightArrow}
                       alt="right-arrow"
                       className="arrowsImg"/>
                </div>
              </Grid> : <Grid item className="shield" xs={1}></Grid>
          }
        </Grid>
      </div>

      <ModalVideo channel='youtube'
                  isOpen={openModalVideo}
                  videoId={selectedVideo}
                  onClose={() => setOpenModalVideo(false)} />
    </div>
  )
};

export default BrandsRow;