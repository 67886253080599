import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import React from "react";

const resources = {
  en: {
    translation: {
      "COMING SOON": "COMING SOON",
      "DAYS": "DAYS",
      "MONTHS": "MONTHS",

      // header
      "HEADER-ABOUT": "ABOUT",
      "HEADER-PLAYERS": "PLAYERS",
      "HEADER-SERVICES": "SERVICES",
      "HEADER-BRANDS": "BRANDS",
      "HEADER-CONTACT-US": "CONTACT US",
      "HEADER-SPORTS-ENTERPRISES": "SPORTS ENTERPRISES",

      // home
      "WE DELIVER WORLD-CLASS": "We deliver world-class",
      "OPPORTUNITIES": "OPPORTUNITIES",

      "FOR TOP FEMALE FOOTBALLERS": "for FEMALE FOOTBALLERS",
      "TO REALIZE THEIR": "to realize their",
      "AMBITIONS ON AND": "ambitions on and",
      "OFF THE PITCH": "off the pitch",
      "TO REALIZE THEIR3": "to realize their",
      "AMBITIONS ON AND2": "ambitions on and",
      "AMBITIONS ON AND3": "ambitions on and",
      "OFF THE PITCH2": "off the pitch",
      "OFF THE PITCH3": "off the pitch",

      "FOR BRANDS": "for BRANDS",
      "TO ENGAGE WITH AND": "to engage with and",
      "TO ENGAGE WITH AND3": "to engage with and",
      "ACTIVATE THEIR TARGET": "activate their target",
      "ACTIVATE THEIR TARGET2": "activate their target",
      "ACTIVATE THEIR TARGET3": "activate their target",
      "AUDIENCES": "audiences",
      "AUDIENCES2": "audiences",
      "AUDIENCES3": "audiences",

      "FOR SPORTS ENTERPRISES": "for SPORTS ENTERPRISES",
      "TO CONTRIBUTE TO": "to contribute to",
      "THE GROWTH OF THE": "the growth of the",
      "WOMEN'S GAME": "women's game",
      "REPRESENTING TOP FEMALE FOOTBALLERS ALL OVER THE WORLD": "Representing top female footballers all over the world",
      "SERVICES": "SERVICES",
      "WE MANAGE OUR PLAYER'S CAREERS AND MARKETING STRATEGIES": "We manage our player's careers and marketing strategies",
      "CAREER MANAGEMENT": "CAREER MANAGEMENT",
      "SPORTS SPONSORSHIPS": "SPORTS SPONSORSHIPS",
      "MARKETING OPPORTUNITIES": "MARKETING OPPORTUNITIES",
      "ONLINE & OFFLINE STRATEGIES": "ONLINE & OFFLINE STRATEGIES",
      "CONTENT CREATION": "CONTENT CREATION",
      "SPORTS CAMPS": "SPORTS CAMPS",
      "COACHING & MENTORING": "COACHING & MENTORING",
      "LEGAL & FINANCIAL ADVISORY": "LEGAL & FINANCIAL ADVISORY",
      "POST-CAREER SERVICES": "POST-CAREER SERVICES",
      "CREDENTIALS": "CREDENTIALS",
      "WE WORK WITH LEADING CLUBS AND BRANDS": "We work with leading clubs and brands",
      "CLUBS": "CLUBS",
      "SPONSORS": "SPONSORS",
      "COLLABS": "COLLABS",
      "CONTACT": "CONTACT",

      "MEET THE WHOLE TEAM": "MEET THE WHOLE TEAM",

      "MANAGEMENT": "MANAGEMENT",

      // about
      "ABOUT": "ABOUT",

      "ABOUT-TITLE-WEB-1": "is a ",
      "ABOUT-TITLE-WEB-2": "sports management and marketing agency ",
      "ABOUT-TITLE-WEB-3": "dedicated exclusively to the development ",
      "ABOUT-TITLE-WEB-4": "of ",
      "ABOUT-TITLE-WEB-5": "women's football.",
      "ABOUT-TITLE-SMALL-1": "sports management and marketing ",
      "ABOUT-TITLE-SMALL-2": "agency dedicated exclusively to ",
      "ABOUT-TITLE-SMALL-3": "the development of ",
      "ABOUT-TITLE-SMALL-4": "women's football.",

      "ABOUT-DESC-1-WEB-1": "We deliver world-class opportunities for female football",
      "ABOUT-DESC-1-WEB-2": "players, brands and sports enterprises.",
      "ABOUT-DESC-1-SMALL-1": "We deliver world-class opportunities for female football ",
      "ABOUT-DESC-1-SMALL-2": "players, brands and sports enterprises.",

      "ABOUT-DESC-2-WEB-1": "We dedicate our experience and passion for the women’s game to offer the ",
      "ABOUT-DESC-2-WEB-2": "best possible solutions to our clients, helping them to achieve their career ",
      "ABOUT-DESC-2-WEB-3": "and business goals",
      "ABOUT-DESC-2-SMALL-1": "We dedicate our experience and passion for the women’s game ",
      "ABOUT-DESC-2-SMALL-2": "to offer the best possible solutions to our clients, helping ",
      "ABOUT-DESC-2-SMALL-3": "them to achieve their career and business goals",

      "ABOUT-DESC-3-WEB-1": "We believe in a comprehensive model of sports management, where we manage and ",
      "ABOUT-DESC-3-WEB-2": "advice our footballers with their sporting careers, negotiate contracts with ",
      "ABOUT-DESC-3-WEB-3": "clubs and sponsors, and offer a number of extra services linked to each player's ",
      "ABOUT-DESC-3-WEB-4": " personal, sporting and business needs.",
      "ABOUT-DESC-3-SMALL-1": "We believe in a comprehensive model of sports management, ",
      "ABOUT-DESC-3-SMALL-2": "where we manage and advice our footballers with their sporting ",
      "ABOUT-DESC-3-SMALL-3": "careers, negotiate contracts with clubs and sponsors, and offer ",
      "ABOUT-DESC-3-SMALL-4": "a number of extra services linked to each player's personal, ",
      "ABOUT-DESC-3-SMALL-5": "sporting and business needs.",

      "MISSION": "MISSION",
      "MISSION-DESC-1": "Contribute to the professionalization of sports ",
      "MISSION-DESC-2": "management in European women's football: ",
      "MISSION-DESC-3": "players, coaches, federations, ",
      "MISSION-DESC-4": " clubs and sponsors.",

      "VISION": "VISION",
      "DISTINGUISHING FACTORS": "DISTINGUISHING FACTORS",
      "FACTORS-1-WEB-1": "Pioneers in women's football",
      "FACTORS-1-WEB-2": "management",
      "FACTORS-1-MEDIUM-1": "Pioneers in women's ",
      "FACTORS-1-MEDIUM-2": "football ",
      "FACTORS-1-MEDIUM-3": "management",
      "FACTORS-2-WEB-1": "Over 10 years experience",
      "FACTORS-2-WEB-2": "in the industry",
      "FACTORS-2-MEDIUM-1": "Over 10 years ",
      "FACTORS-2-MEDIUM-2": "of experience",
      "FACTORS-3-WEB-1": "Relationship with the best",
      "FACTORS-3-WEB-2": "European clubs",
      "FACTORS-3-MEDIUM-1": "Relationship with the best",
      "FACTORS-3-MEDIUM-2": "European clubs",
      "FACTORS-4-WEB-1": "Players in the Spanish, ",
      "FACTORS-4-WEB-2": "Portuguese, English, French ",
      "FACTORS-4-WEB-3": "and Italian leagues",
      "FACTORS-4-MEDIUM-1": "Players in the Spanish, ",
      "FACTORS-4-MEDIUM-2": "Portuguese, English, ",
      "FACTORS-4-MEDIUM-3": "French and Italian",
      "FACTORS-4-MEDIUM-4": "leagues",
      "FACTORS-5-WEB-1": "Unique international ",
      "FACTORS-5-WEB-2": "scouting system",
      "FACTORS-5-MEDIUM-1": "Unique international ",
      "FACTORS-5-MEDIUM-2": "scouting system",
      "FACTORS-5-MEDIUM-3": "",
      "FACTORS-6-WEB-1": "Talent management",
      "FACTORS-6-WEB-2": "",
      "FACTORS-6-MEDIUM-1": "Talent management",
      "FACTORS-6-MEDIUM-2": "",
      "FACTORS-7-WEB-1": "Pioneers in the creation and ",
      "FACTORS-7-WEB-2": "management of women's ",
      "FACTORS-7-WEB-3": "sports campaigns",
      "FACTORS-7-MEDIUM-1": "Pioneers in the creation and ",
      "FACTORS-7-MEDIUM-2": "management of women's ",
      "FACTORS-7-MEDIUM-3": "sports campaigns",
      "FACTORS-8-WEB-1": "Experts in ",
      "FACTORS-8-WEB-2": "personal branding",
      "FACTORS-8-MEDIUM-1": "Experts in ",
      "FACTORS-8-MEDIUM-2": "personal branding",
      "FACTORS-9-WEB-1": "Specialists in online marketing ",
      "FACTORS-9-WEB-2": "and influencer marketing",
      "FACTORS-9-MEDIUM-1": "Specialists in ",
      "FACTORS-9-MEDIUM-2": "marketing online ",
      "FACTORS-9-MEDIUM-3": "and influencer ",
      "FACTORS-9-MEDIUM-4": "marketing",
      "FACTORS-10-WEB-1": "Legal and financial advice",
      "FACTORS-10-WEB-2": "by specialized law firms",
      "FACTORS-10-MEDIUM-1": "Legal and financial ",
      "FACTORS-10-MEDIUM-2": "advice by ",
      "FACTORS-10-MEDIUM-3": "specialized law ",
      "FACTORS-10-MEDIUM-4": "firms",

      "FACTORS-1-SMALL-1": "Pioneers in women's football ",
      "FACTORS-1-SMALL-2": "management",
      "FACTORS-2-SMALL-1": "Over 10 years of",
      "FACTORS-2-SMALL-2": "experience",
      "FACTORS-3-SMALL-1": "Relationship with the best",
      "FACTORS-3-SMALL-2": "European clubs",
      "FACTORS-4-SMALL-1": "Players in the Spanish, ",
      "FACTORS-4-SMALL-2": "Portuguese, English, French ",
      "FACTORS-4-SMALL-3": "and Italian leagues",
      "FACTORS-5-SMALL-1": "Unique international ",
      "FACTORS-5-SMALL-2": "scouting system",
      "FACTORS-6-SMALL-1": "Talent management",
      "FACTORS-6-SMALL-2": "",
      "FACTORS-7-SMALL-1": "Pioneers in the creation and ",
      "FACTORS-7-SMALL-2": "management of women's ",
      "FACTORS-7-SMALL-3": "sports campaigns",
      "FACTORS-8-SMALL-1": "Experts in ",
      "FACTORS-8-SMALL-2": "personal branding",
      "FACTORS-9-SMALL-1": "Specialists in online marketing",
      "FACTORS-9-SMALL-2": "and influencer marketing",
      "FACTORS-10-SMALL-1": "Legal and financial advice by ",
      "FACTORS-10-SMALL-2": "specialized law firms",

      "FOUNDERS": "FOUNDERS",
      "FOUNDERS-DESC-WEB-1": "Over the years, our team, led by Arkaitz Coca and Carlota Planas, has built a trusted ",
      "FOUNDERS-DESC-WEB-2": "relationship with athletes, clubs, sponsors and associations, which provides us with ",
      "FOUNDERS-DESC-WEB-3": "unique expertise in the women’s football industry. ",
      "FOUNDERS-DESC-SMALL-1": "Over the years, our team, led by Arkaitz Coca and Carlota ",
      "FOUNDERS-DESC-SMALL-2": "Planas, has built a trusted relationship with athletes, ",
      "FOUNDERS-DESC-SMALL-3": "clubs, sponsors and associations, which provides us with ",
      "FOUNDERS-DESC-SMALL-4": "unique expertise in the women’s football industry. ",
      "FOUNDERS-DESC-SMALL-5": "",

      "ARKAITZ-DESC-1": "Passionate about sports and management, Arkaitz holds an MBA in Sports Entities Management and a specialization in sports law. After working for several men’s agencies, he decided to embark on his own path in women's football. ",
      "ARKAITZ-DESC-2": "With this idea in mind, 10 years ago, he opened one of the first sports management agencies for female football players in Spain and Italy, which allows him, to this day, to be one of the most experienced agents in the sector. ",
      "ARKAITZ-DESC-3": "Throughout his career, Arkaitz has handled hundreds of transfers and has helped players to join the best clubs in the world. He has also participated in several projects to give visibility to women in sport.",
      "CARLOTA-DESC-1": "Graduated in Journalism and in Business Administration, Carlota is an expert in Sports Management and Marketing. She began her professional career writing for the Spanish sports newspaper Diario AS, covering all the information relating to FC Barcelona, but soon after accepted the coordination of an international scouting project.",
      "CARLOTA-DESC-2": "After several years of detecting and managing talent, she decided to found the first marketing agency for female athletes. From 2018 to 2020, Carlota has pioneered the achievement of campaigns and sponsorship opportunities for female athletes, getting history-changing contracts for several players.",
      "CARLOTA-DESC-3": "In her time as a futsal player, she won several national and international awards. She has also trained for the last 10 years at different levels and, in addition to being a UEFA B Football Coach, she’s coordinator and expert in women's football awarded by the Catalan Football Federation.",

      "PARTNERS": "PARTNERS",
      "SPORTS": "SPORTS",
      "EDUCATION": "EDUCATION",
      "MEDIA": "MEDIA",
      "HEALTHCARE": "HEALTHCARE",
      "LEGAL": "LEGAL",
      "HR": "HR",

      // unik team
      "POSITION": "POSITION",
      "POSITION-DESC": "Select one or more positions",
      "TEAM": "TEAM",
      "TEAM-DESC": "Select a club",
      "LEAGUE": "LEAGUE",
      "LEAGUE-DESC": "Select a league",
      "AGE": "AGE",
      "AGE-DESC": "Select year/s of birth",
      "NATIONALITY": "NATIONALITY",
      "NATIONALITY-DESC": "Select a nationality",
      "SEARCH": "SEARCH",
      "FULL-SQUAD": "FULL-SQUAD",
      "GOALKEEPERS": "GOALKEEPERS",
      "DEFENDERS": "DEFENDERS",
      "MIDFIELDERS": "MIDFIELDERS",
      "FORWARDS": "FORWARDS",
      "SEE PROFILE": "See profile",
      "GOALKEEPER": "GOALKEEPER",
      "DEFENDER": "DEFENDER",
      "MIDFIELDER": "MIDFIELDER",
      "FORWARD": "FORWARD",

      // services
      "SERVICES-TITLE-WEB-1": "Committed to",
      "SERVICES-TITLE-WEB-2": "guiding the career and businesses",
      "SERVICES-TITLE-WEB-3": "strategies of our players, we help",
      "SERVICES-TITLE-WEB-4": "them maximize their revenues and",
      "SERVICES-TITLE-WEB-5": "leverage their personal brands.",
      "SERVICES-TITLE-MEDIUM-1": "Committed to guiding the career and businesses strategies of our players, ",
      "SERVICES-TITLE-MEDIUM-2": "we help them maximize their revenues and leverage their personal brands.",
      "SERVICES-TITLE-SMALL-1": "Committed to guiding the career and businesses strategies of our players, ",
      "SERVICES-TITLE-SMALL-2": "we help them maximize their revenues and leverage their personal brands.",
      "SERVICES-TITLE-EXTRASMALL-1": "Committed to guiding the career and businesses strategies of our players, ",
      "SERVICES-TITLE-EXTRASMALL-2": "we help them maximize their revenues and leverage their personal brands.",

      "SERVICE-1-WEB-1": "CAREER",
      "SERVICE-1-WEB-2": "MANAGEMENT",
      "SERVICE-2-WEB-1": "SPORTS",
      "SERVICE-2-WEB-2": "SPONSORSHIPS",
      "SERVICE-3-WEB-1": "MARKETING",
      "SERVICE-3-WEB-2": "OPPORTUNITIES",
      "SERVICE-4-WEB-1": "ONLINE & OFFLINE",
      "SERVICE-4-WEB-2": "STRATEGIES",
      "SERVICE-5-WEB-1": "CONTENT",
      "SERVICE-5-WEB-2": "CREATION",
      "SERVICE-6-WEB-1": "SPORTS",
      "SERVICE-6-WEB-2": "CAMPS",
      "SERVICE-7-WEB-1": "COACHING &",
      "SERVICE-7-WEB-2": "MENTORING",
      "SERVICE-8-WEB-1": "LEGAL & FINANCIAL",
      "SERVICE-8-WEB-2": "ADVISORY",
      "SERVICE-8a-WEB-1": "LEGAL &",
      "SERVICE-8a-WEB-2": "FINANCIAL ADV.",
      "SERVICE-9-WEB-1": "POST-CAREER",
      "SERVICE-9-WEB-2": "SERVICES",

      "SERVICE-1-SMALL-1": "CAREER",
      "SERVICE-1-SMALL-2": "MANAGEMENT",
      "SERVICE-2-SMALL-1": "SPORTS",
      "SERVICE-2-SMALL-2": "SPONSORSHIPS",
      "SERVICE-3-SMALL-1": "MARKETING",
      "SERVICE-3-SMALL-2": "OPPORTUNITIES",
      "SERVICE-4-SMALL-1": "ONLINE & OFFLINE",
      "SERVICE-4-SMALL-2": "STRATEGIES",
      "SERVICE-5-SMALL-1": "CONTENT",
      "SERVICE-5-SMALL-2": "CREATION",
      "SERVICE-6-SMALL-1": "SPORTS",
      "SERVICE-6-SMALL-2": "CAMPS",
      "SERVICE-7-SMALL-1": "COACHING &",
      "SERVICE-7-SMALL-2": "MENTORING",
      "SERVICE-8-SMALL-1": "LEGAL & FINANCIAL",
      "SERVICE-8-SMALL-2": "ADVISORY",
      "SERVICE-8a-SMALL-1": "LEGAL &",
      "SERVICE-8a-SMALL-2": "FINANCIAL ADV.",
      "SERVICE-9-SMALL-1": "POST-CAREER",
      "SERVICE-9-SMALL-2": "SERVICES",


      // contact us
      "CONTACT US": "CONTACT US",
      "GET IN TOUCH!": "GET IN TOUCH!",
      "NAME": "NAME",
      "FULL NAME": "Full name",
      "PHONE": "PHONE",
      "PHONE NUMBER": "Phone number",
      "COUNTRY": "COUNTRY",
      "Country": "Country",
      "EMAIL": "EMAIL",
      "COMPANY AND JOB TITLE": "COMPANY AND JOB TITLE",
      "COMPANY NAME AND JOB TITLE": "Company name and job title",
      "MESSAGE": "MESSAGE",
      "YOUR MESSAGE": "Your message",
      "SEND": "SEND",
      "MESSAGE SENT SUCCESSFULLY!": "Message sent successfully!",
      "COMPLETE ALL THE FIELDS!": "Complete all the fields!",
      "AN ERROR HAS OCCURRED...": "An error has occurred...",


      // sports enterprises
      "SPORTS ENTERPRISES": "SPORTS ENTERPRISES",
      "SPORTS_HEADER": "SPORTS",
      "ENTERPRISES_HEADER": "ENTERPRISES",
      "ENTERPRISES": "ENTERPRISES",
      "SPORTS-DESC-1-WEB-1": "Our experience in the industry ",
      "SPORTS-DESC-1-WEB-2": "provides us with the necessary ",
      "SPORTS-DESC-1-WEB-3": "market knowledge to operate at a ",
      "SPORTS-DESC-1-WEB-4": "local, national and international level.",
      "SPORTS-DESC-1-SMALL-1": "Our experience in the industry ",
      "SPORTS-DESC-1-SMALL-2": "provides us with the necessary ",
      "SPORTS-DESC-1-SMALL-3": "market knowledge to operate at a ",
      "SPORTS-DESC-1-SMALL-4": "local, national and international level.",
      "SPORTS-DESC-2-WEB-1": "We are capable of delivering comprehensive ",
      "SPORTS-DESC-2-WEB-2": "solutions to any federation, club, or ",
      "SPORTS-DESC-2-WEB-3": "entity that requires them.",
      "SPORTS-DESC-2-SMALL-1": "We are capable of delivering comprehensive ",
      "SPORTS-DESC-2-SMALL-2": "solutions to any federation, club, or ",
      "SPORTS-DESC-2-SMALL-3": "entity that requires them.",
      "SPORTS-DESC-3-WEB-1": "We also strive to contribute to the growth of ",
      "SPORTS-DESC-3-WEB-2": "women’s football by carrying out events and ",
      "SPORTS-DESC-3-WEB-3": "activities to promote the women's game.",
      "SPORTS-DESC-3-SMALL-1": "We also strive to contribute to the growth of ",
      "SPORTS-DESC-3-SMALL-2": "women’s football by carrying out events and ",
      "SPORTS-DESC-3-SMALL-3": "activities to promote the women's game.",

      "SPORTS-1-DESC-1": "GRASSROOTS",
      "SPORTS-1-DESC-2": "PROGRAMS",
      "SPORTS-2-DESC-1": "EDUCATIONAL",
      "SPORTS-2-DESC-2": "PROGRAMS",
      "SPORTS-3-DESC-1": "EVENT",
      "SPORTS-3-DESC-2": "ORGANIZATION",
      "SPORTS-4-DESC-1": "MARKETING",
      "SPORTS-4-DESC-2": "STRATEGIES",
      "SPORTS-5-DESC-1": "CORPORATE SOCIAL",
      "SPORTS-5-DESC-2": "RESPONSIBILITY",


      // brands
      "BRANDS": "BRANDS",
      "CONNECT": "CONNECT",
      "WITH YOUR TARGET AUDIENCE": "with your target audience",
      "CONVEY": "CONVEY",
      "YOUR VALUES": "your values",
      "CREATE": "CREATE",
      "ENGAGING CONTENT": "engaging content",
      "SURPRISE": "SURPRISE",
      "WITH NEW IDOLS": "with new idols",

      "BRAND-DESC-1-SMALL-1": "We advise and connect brands, entities and",
      "BRAND-DESC-1-SMALL-2": "footballers to create original and disruptive campaigns.",
      "BRAND-DESC-2-SMALL-1": "We help your brand select the right profiles to",
      "BRAND-DESC-2-SMALL-2": "increase the impact of each action and achieve your goals.",
      "BRAND-DESC-2-SMALL-3": "",
      "BRAND-DESC-3-SMALL-1": "We propose new forms of communication to reach",
      "BRAND-DESC-3-SMALL-2": "the audience in a different, innovative and effective way.",

      "BRAND-DESC-1-WEB-1": "We advise and connect",
      "BRAND-DESC-1-WEB-2": "brands, entities and footballers",
      "BRAND-DESC-1-WEB-3": "to create original and disruptive",
      "BRAND-DESC-1-WEB-4": "campaigns.",
      "BRAND-DESC-2-WEB-1": "We help your brand select",
      "BRAND-DESC-2-WEB-2": "the right profiles to",
      "BRAND-DESC-2-WEB-3": "increase the impact of each",
      "BRAND-DESC-2-WEB-4": "action and achieve your goals.",
      "BRAND-DESC-3-WEB-1": "We propose new forms of",
      "BRAND-DESC-3-WEB-2": "communication to reach",
      "BRAND-DESC-3-WEB-3": "the audience in a different,",
      "BRAND-DESC-3-WEB-4": "innovative and effective way.",

      "BRAND-DESC-ROW-1": "At UNIK SPORTS MANAGEMENT, we intend to contribute",
      "BRAND-DESC-ROW-2": "to presenting new idols to society for the next ",
      "BRAND-DESC-ROW-3": "generations to have female references.",

      "ACTIVATION POSSIBILITIES": "ACTIVATION POSSIBILITIES",

      "OTHER MARKETING ACTIONS": "OTHER MARKETING ACTIONS",

      "ACTIVATION-DESC-1": "Collaborate with one or more players to increase your brand awareness. Our footballers have the ability to attract potential customers, of different profiles and ages, through captivating and unique audiovisual content featuring your products and or/ services.",
      "ACTIVATION-DESC-2": "Link your image to one of our footballers to convey your brand values through the story of an elite female athlete. The newest sporting idols are examples of hard work, sacrifice, resilience, passion and self-improvement. They also have a growing influence on public opinion and the ability to connect with a wide audience.",
      "ACTIVATION-DESC-3": "Associate your brand to our players and position yourself in an industry that is undergoing an unprecedented evolution. The impact and visibility of women’s football grows day by day, so supporting the women’s game is proving to be a win-win situation; the sponsor wins and the players win.",
      "ACTIVATION-DESC-4": "Have one or more players attend promotional events or activities and bring your brand to life through a differentiated marketing action. The presence of professional sportswomen at corporate events is an excellent way to draw both public and media attention, helping to build the company's notoriety.",


      // player
      "PROFILE": "PROFILE",
      "ACHIEVEMENTS": "ACHIEVEMENTS",
      "TECHNICAL REPORT": "TECHNICAL REPORT",
      "CAREER": "CAREER",
      "LAST CAMPAIGNS": "LAST CAMPAIGNS",
      "PRESS": "PRESS",
      "DATE OF BIRTH": "DATE OF BIRTH",
      "CITY": "CITY",
      "HEIGHT": "HEIGHT",
      "WEIGHT": "WEIGHT",
      "NATIONAL TOURNAMENTS": "NATIONAL TOURNAMENTS",
      "INTERNATIONAL TOURNAMENTS": "INTERNATIONAL TOURNAMENTS",
      "INDIVIDUAL AWARDS": "INDIVIDUAL AWARDS",
      "CHARACTERISTICS": "Characteristics",
      "DOMINANT LEG": "Dominant Leg",
      "TECHNICAL": "Technical",
      "TACTICAL": "Tactical",
      "PSYCHOLOGICAL": "Psychological",
      "PHYSICAL": "Physical",
      "Position": "Position",


      // footer
      "SIGN UP TO": "Sign up to",
      "OUR NEWSLETTER!": "our newsletter!",
      "EMAIL ADDRESS": "Email address",
      "Send": "Send",
      "PRIVACY POLICY": "Privacy Policy",
      "LEGAL WARNING": "Legal Warning",
      "ALL RIGHT RESERVED": "All rights reserved",
      "INVALID EMAIL": "Invalid email",

      "SPAIN": "Spain"
    }
  },
  es: {
    translation: {
      "COMING SOON": "PRÓXIMAMENTE",
      "DAYS": "DÍAS",
      "MONTHS": "MESES",

      // header
      "HEADER-ABOUT": "FILOSOFÍA",
      "HEADER-PLAYERS": "JUGADORAS",
      "HEADER-SERVICES": "SERVICIOS",
      "HEADER-BRANDS": "MARCAS",
      "HEADER-CONTACT-US": "CONTACTO",
      "HEADER-SPORTS-ENTERPRISES": "ENTIDADES DEPORTIVAS",

      // home
      "WE DELIVER WORLD-CLASS": "Creamos oportunidades",
      "OPPORTUNITIES": "ÚNICAS",

      "FOR TOP FEMALE FOOTBALLERS": "para JUGADORAS",
      "TO REALIZE THEIR": "que confíen en nosotros para",
      "AMBITIONS ON AND": "alcanzar sus objectivos dentro y",
      "OFF THE PITCH": "fuera del campo",
      "TO REALIZE THEIR3": "que confíen en nosotros",
      "AMBITIONS ON AND2": "alcanzar sus objectivos dentro",
      "AMBITIONS ON AND3": "para alcanzar sus objectivos",
      "OFF THE PITCH2": "y fuera del campo",
      "OFF THE PITCH3": "dentro y fuera del campo",

      "FOR BRANDS": "para MARCAS",
      "TO ENGAGE WITH AND": "que apuestan por el deporte y",
      "TO ENGAGE WITH AND3": "que apuestan por el deporte",
      "ACTIVATE THEIR TARGET": "las mujeres para conectar con su",
      "ACTIVATE THEIR TARGET2": "las mujeres para conectar con",
      "ACTIVATE THEIR TARGET3": "y las mujeres para conectar",
      "AUDIENCES": "público objetivo",
      "AUDIENCES2": "su público objetivo",
      "AUDIENCES3": "con su público objetivo",

      "FOR SPORTS ENTERPRISES": "para ENTIDADES DEPORTIVAS",
      "TO CONTRIBUTE TO": "que quieran contribuir al",
      "THE GROWTH OF THE": "crecimiento y desarrollo del",
      "WOMEN'S GAME": "fútbol femenino",


      "REPRESENTING TOP FEMALE FOOTBALLERS ALL OVER THE WORLD": "Representamos talento de talla mundial",
      "SERVICES": "SERVICIOS",
      "WE MANAGE OUR PLAYER'S CAREERS AND MARKETING STRATEGIES": "Gestionamos la carrera e imagen de nuestras jugadoras",
      "CAREER MANAGEMENT": "CAREER MANAGEMENT",
      "SPORTS SPONSORSHIPS": "SPORTS SPONSORSHIPS",
      "MARKETING OPPORTUNITIES": "MARKETING OPPORTUNITIES",
      "ONLINE & OFFLINE STRATEGIES": "ONLINE & OFFLINE STRATEGIES",
      "CONTENT CREATION": "CONTENT CREATION",
      "SPORTS CAMPS": "SPORTS CAMPS",
      "COACHING & MENTORING": "COACHING & MENTORING",
      "LEGAL & FINANCIAL ADVISORY": "LEGAL & FINANCIAL ADVISORY",
      "POST-CAREER SERVICES": "POST-CAREER SERVICES",
      "CREDENTIALS": "CREDENCIALES",
      "WE WORK WITH LEADING CLUBS AND BRANDS": "Trabajamos con clubes y marcas líderes en su sector",
      "CLUBS": "CLUBES",
      "SPONSORS": "PATROCINIOS",
      "COLLABS": "COLABORACIONES",

      "MEET THE WHOLE TEAM": "CONOCE A TODO EL EQUIPO",

      "MANAGEMENT": "GESTIÓN",

      // about
      "ABOUT": "FILOSOFÍA",

      "ABOUT-TITLE-WEB-1": " es una",
      "ABOUT-TITLE-WEB-2": "agencia de representación y marketing",
      "ABOUT-TITLE-WEB-3": "dedicada exclusivamente al desarrollo",
      "ABOUT-TITLE-WEB-4": "del ",
      "ABOUT-TITLE-WEB-5": "fútbol femenino.",
      "ABOUT-TITLE-SMALL-1": "es una agencia de representación",
      "ABOUT-TITLE-SMALL-2": "y marketing dedicada exclusivamente",
      "ABOUT-TITLE-SMALL-3": "al desarrollo del ",
      "ABOUT-TITLE-SMALL-4": "fútbol femenino.",

      "ABOUT-DESC-1-WEB-1": "Creamos oportunidades únicas para jugadoras, marcas",
      "ABOUT-DESC-1-WEB-2": "y entidades deportivas.",
      "ABOUT-DESC-1-SMALL-1": "Creamos oportunidades únicas para jugadoras, marcas",
      "ABOUT-DESC-1-SMALL-2": "y entidades deportivas.",

      "ABOUT-DESC-2-WEB-1": "Nos basamos en nuestra dilatada experiencia y pasión por la modalidad",
      "ABOUT-DESC-2-WEB-2": "para ofrecer un servicio de calidad, profesional y cercano, basado en el",
      "ABOUT-DESC-2-WEB-3": "compromiso y la transparencia.",
      "ABOUT-DESC-2-SMALL-1": "Nos basamos en nuestra dilatada experiencia y pasión por la",
      "ABOUT-DESC-2-SMALL-2": "modalidad para ofrecer un servicio de calidad, profesional y",
      "ABOUT-DESC-2-SMALL-3": "cercano, basado en el compromiso y la transparencia.",

      "ABOUT-DESC-3-WEB-1": "Creemos en un modelo de representación integral, donde se gestiona y asesora sobre",
      "ABOUT-DESC-3-WEB-2": "la carrera deportiva, se negocian los contratos con clubes y patrocinadores, y se ofre-",
      "ABOUT-DESC-3-WEB-3": "cen una serie de servicios vinculados a las necesidades personales, deportivas y em-",
      "ABOUT-DESC-3-WEB-4": "presariales de cada jugadora.",
      "ABOUT-DESC-3-SMALL-1": "Creemos en un modelo de representación integral, donde",
      "ABOUT-DESC-3-SMALL-2": "se gestiona y asesora sobre la carrera deportiva, se negocian",
      "ABOUT-DESC-3-SMALL-3": "los contratos con clubes y patrocinadores, y se ofrecen una",
      "ABOUT-DESC-3-SMALL-4": "serie de servicios vinculados a las necesidades personales,",
      "ABOUT-DESC-3-SMALL-5": "deportivas y empresariales de cada jugadora.",

      "MISSION": "MISIÓN",
      "MISSION-DESC-1": "Contribuir a la profesionalización en la gestión",
      "MISSION-DESC-2": "integral del fútbol femenino europeo:",
      "MISSION-DESC-3": "jugadoras, entrenadores, federaciones,",
      "MISSION-DESC-4": "clubes y patrocinadores.",

      "VISION": "VISIÓN",
      "DISTINGUISHING FACTORS": "FACTORES DIFERENCIALES",
      "FACTORS-1-WEB-1": "Pioneros en la representación",
      "FACTORS-1-WEB-2": "del fútbol femenino",
      "FACTORS-1-MEDIUM-1": "Pioneros en la",
      "FACTORS-1-MEDIUM-2": "representación del",
      "FACTORS-1-MEDIUM-3": "fútbol femenino",
      "FACTORS-2-WEB-1": "Más de 10 años",
      "FACTORS-2-WEB-2": "en el sector",
      "FACTORS-2-MEDIUM-1": "Más de 10 años",
      "FACTORS-2-MEDIUM-2": "en el sector",
      "FACTORS-3-WEB-1": "Relación con los mejores",
      "FACTORS-3-WEB-2": "clubes europeos",
      "FACTORS-3-MEDIUM-1": "Relación con los mejores",
      "FACTORS-3-MEDIUM-2": "clubes europeos",
      "FACTORS-4-WEB-1": "Jugadoras en la Liga española,",
      "FACTORS-4-WEB-2": "portuguesa, inglesa, francesa",
      "FACTORS-4-WEB-3": "e italiana",
      "FACTORS-4-MEDIUM-1": "Jugadoras en la Liga",
      "FACTORS-4-MEDIUM-2": "española, portuguesa,",
      "FACTORS-4-MEDIUM-3": "inglesa, francesa",
      "FACTORS-4-MEDIUM-4": "e italiana",
      "FACTORS-5-WEB-1": "Programa de scouting único",
      "FACTORS-5-WEB-2": "a nivel internacional",
      "FACTORS-5-MEDIUM-1": "Programa de scouting",
      "FACTORS-5-MEDIUM-2": "único a nivel",
      "FACTORS-5-MEDIUM-3": "internacional",
      "FACTORS-6-WEB-1": "Control y gestión",
      "FACTORS-6-WEB-2": "del talento",
      "FACTORS-6-MEDIUM-1": "Control y gestión",
      "FACTORS-6-MEDIUM-2": "del talento",
      "FACTORS-7-WEB-1": "Pioneros en la creación y",
      "FACTORS-7-WEB-2": "gestión de campañas",
      "FACTORS-7-WEB-3": "deportivas femeninas",
      "FACTORS-7-MEDIUM-1": "Pioneros en la creación",
      "FACTORS-7-MEDIUM-2": "y gestión de campañas",
      "FACTORS-7-MEDIUM-3": "deportivas femeninas",
      "FACTORS-8-WEB-1": "Expertos en",
      "FACTORS-8-WEB-2": "branding personal",
      "FACTORS-8-MEDIUM-1": "Expertos en",
      "FACTORS-8-MEDIUM-2": "branding personal",
      "FACTORS-9-WEB-1": "Especialistas en marketing",
      "FACTORS-9-WEB-2": "online e influencer marketing",
      "FACTORS-9-MEDIUM-1": "Especialistas en",
      "FACTORS-9-MEDIUM-2": "marketing online",
      "FACTORS-9-MEDIUM-3": "e influencer",
      "FACTORS-9-MEDIUM-4": "marketing",
      "FACTORS-10-WEB-1": "Asesoría legal y financiera por",
      "FACTORS-10-WEB-2": "parte de bufetes especializados",
      "FACTORS-10-MEDIUM-1": "Asesoría legal y",
      "FACTORS-10-MEDIUM-2": "financiera por",
      "FACTORS-10-MEDIUM-3": "parte de bufetes",
      "FACTORS-10-MEDIUM-4": "especializados",

      "FACTORS-1-SMALL-1": "Pioneros en la representación",
      "FACTORS-1-SMALL-2": "del fútbol femenino",
      "FACTORS-2-SMALL-1": "Más de 10 años",
      "FACTORS-2-SMALL-2": "en el sector",
      "FACTORS-3-SMALL-1": "Relación con los mejores",
      "FACTORS-3-SMALL-2": "clubes europeos",
      "FACTORS-4-SMALL-1": "Jugadoras en la Liga española,",
      "FACTORS-4-SMALL-2": "portuguesa, inglesa, francesa",
      "FACTORS-4-SMALL-3": "e italiana",
      "FACTORS-5-SMALL-1": "Programa de scouting único",
      "FACTORS-5-SMALL-2": "a nivel internacional",
      "FACTORS-6-SMALL-1": "Control y gestión",
      "FACTORS-6-SMALL-2": "del talento",
      "FACTORS-7-SMALL-1": "Pioneros en la creación y",
      "FACTORS-7-SMALL-2": "gestión de campañas",
      "FACTORS-7-SMALL-3": "deportivas femeninas",
      "FACTORS-8-SMALL-1": "Expertos en",
      "FACTORS-8-SMALL-2": "branding personal",
      "FACTORS-9-SMALL-1": "Especialistas en marketing",
      "FACTORS-9-SMALL-2": "online e influencer marketing",
      "FACTORS-10-SMALL-1": "Asesoría legal y financiera por",
      "FACTORS-10-SMALL-2": "parte de bufetes especializados",

      "FOUNDERS": "DIRECTORES",
      "FOUNDERS-DESC-WEB-1": "A lo largo de los años, nuestro equipo, liderado por Arkaitz Coca y Carlota Planas, ha cons-",
      "FOUNDERS-DESC-WEB-2": "truido una relación de confianza con atletas, clubes, patrocinadores y asociaciones, lo que",
      "FOUNDERS-DESC-WEB-3": "nos proporciona un conocimiento amplio del mercado y una ventaja competitiva única.",
      "FOUNDERS-DESC-SMALL-1": "A lo largo de los años, nuestro equipo, liderado por",
      "FOUNDERS-DESC-SMALL-2": "Arkaitz Coca y Carlota Planas, ha construido una",
      "FOUNDERS-DESC-SMALL-3": "relación de confianza con atletas, clubes, patrocinadores",
      "FOUNDERS-DESC-SMALL-4": "y asociaciones, lo que nos proporciona un conocimiento",
      "FOUNDERS-DESC-SMALL-5": "amplio del mercado y una ventaja competitiva única.",

      "ARKAITZ-DESC-1": "Apasionado del deporte y de la gestión, Arkaitz tiene un MBA en Dirección de Entidades Deportivas y una especialización en derecho deportivo. Tras trabajar para varias agencias masculinas, decidió emprender su propio camino en el fútbol femenino.",
      "ARKAITZ-DESC-2": "Con esta idea en la cabeza, hace 10 años, abrió una de las primeras agencias de representación de jugadoras de fútbol en España e Italia, lo que le permite, a día de hoy, ser uno de los agentes con más experiencia del sector.",
      "ARKAITZ-DESC-3": "Arkaitz ha gestionado cientos de traspasos y ha conseguido que sus jugadoras militen en los mejores clubes del mundo. También ha participado en varios proyectos que buscaban promocionar el fútbol femenino y ha asesorado a diversas entidades para dar visibilidad a las mujeres en el deporte.",
      "CARLOTA-DESC-1": "Graduada en Periodismo y ADE por la Universidad Pompeu Fabra, Carlota es experta en Gestión Deportiva y Marketing. Empezó su carrera profesional en el Diario AS, cubriendo la información relacionada con el FC Barcelona, pero, poco después, aceptó la coordinación de un proyecto de scouting internacional.",
      "CARLOTA-DESC-2": "Tras varios años detectando y gestionando talento, decidió fundar la primera agencia de marketing dedicada, exclusivamente, a promocionar a mujeres deportistas. Pionera en el logro de campañas y oportunidades de patrocinio para futbolistas, ha conseguido que sus representadas firmen contratos históricos con varias marcas reconocidas a nivel mundial.",
      "CARLOTA-DESC-3": "En su etapa como jugadora de fútbol sala, ganó varios premios nacionales e internacionales. También ha entrenado durante los últimos 10 años a diferentes niveles y, además de ser entrenadora UEFA B, tiene los títulos de coordinadora y experta en fútbol femenino otorgados por la Federación Catalana de Fútbol",

      "PARTNERS": "COLABORADORES",
      "SPORTS": "DEPORTIVOS",
      "EDUCATION": "ACADÉMICOS",
      "MEDIA": "COMUNICACIÓN",
      "HEALTHCARE": "SALUD",
      "LEGAL": "ASESORAMIENTO LEGAL",
      "HR": "RR.HH",

      // unik team
      "POSITION": "POSICIÓN",
      "POSITION-DESC": "Selecciona una o varias posiciones",
      "TEAM": "EQUIPO",
      "TEAM-DESC": "Selecciona club",
      "LEAGUE": "LIGA",
      "LEAGUE-DESC": "Selecciona liga",
      "AGE": "NACIMIENTO",
      "AGE-DESC": "Selecciona año/s",
      "NATIONALITY": "NACIONALIDAD",
      "NATIONALITY-DESC": "Selecciona nacionalidad",
      "SEARCH": "BUSCAR",
      "FULL-SQUAD": "TODAS",
      "GOALKEEPERS": "PORTERAS",
      "DEFENDERS": "DEFENSAS",
      "MIDFIELDERS": "CENTROCAMPISTAS",
      "FORWARDS": "DELANTERAS",
      "SEE PROFILE": "Ver perfil",
      "GOALKEEPER": "PORTERA",
      "DEFENDER": "DEFENSA",
      "MIDFIELDER": "CENTROCAMPISTA",
      "FORWARD": "DELANTERA",


      // services
      "SERVICES-TITLE-WEB-1": "Comprometidos con",
      "SERVICES-TITLE-WEB-2": "guiar las estrategias profesionales",
      "SERVICES-TITLE-WEB-3": "y comerciales de nuestras jugadoras,",
      "SERVICES-TITLE-WEB-4": "las ayudamos a maximizar ingresos",
      "SERVICES-TITLE-WEB-5": "y aprovechar sus marcas personales.",
      "SERVICES-TITLE-MEDIUM-1": "Comprometidos con guiar las estrategias profesionales y comerciales de nuestras jugadoras,",
      "SERVICES-TITLE-MEDIUM-2": "las ayudamos a maximizar ingresos y aprovechar sus marcas personales.",
      "SERVICES-TITLE-SMALL-1": "Comprometidos con guiar las estrategias profesionales y comerciales de nuestras jugadoras,",
      "SERVICES-TITLE-SMALL-2": "las ayudamos a maximizar ingresos y aprovechar sus marcas personales.",
      "SERVICES-TITLE-EXTRASMALL-1": "Comprometidos con guiar las estrategias profesionales y comerciales de nuestras jugadoras,",
      "SERVICES-TITLE-EXTRASMALL-2": "las ayudamos a maximizar ingresos y aprovechar sus marcas personales.",

      "SERVICE-1-WEB-1": "GESTIÓN ",
      "SERVICE-1-WEB-2": "DEPORTIVA",
      "SERVICE-2-WEB-1": "BÚSQUEDA ",
      "SERVICE-2-WEB-2": "DE PATROCINIO",
      "SERVICE-3-WEB-1": "OPORTUNIDADES ",
      "SERVICE-3-WEB-2": "COMERCIALES",
      "SERVICE-4-WEB-1": "ESTRATEGIAS ",
      "SERVICE-4-WEB-2": "ONLINE Y OFFLINE",
      "SERVICE-5-WEB-1": "CREACIÓN ",
      "SERVICE-5-WEB-2": "DE CONTENIDO",
      "SERVICE-6-WEB-1": "COACHING ",
      "SERVICE-6-WEB-2": "& MENTORING",
      "SERVICE-7-WEB-1": "ORGANIZACIÓN ",
      "SERVICE-7-WEB-2": "DE CAMPUS",
      "SERVICE-8-WEB-1": "ASESORAMIENTO ",
      "SERVICE-8-WEB-2": "LEGAL Y FINANCIERO",
      "SERVICE-8a-WEB-1": "ASS. LEGAL Y ",
      "SERVICE-8a-WEB-2": "FINANCIERO",
      "SERVICE-9-WEB-1": "SERVICIOS ",
      "SERVICE-9-WEB-2": "POST-CARRERA",

      "SERVICE-1-SMALL-1": "GESTIÓN ",
      "SERVICE-1-SMALL-2": "DEPORTIVA",
      "SERVICE-2-SMALL-1": "BÚSQUEDA ",
      "SERVICE-2-SMALL-2": "DE PATROCINIO",
      "SERVICE-3-SMALL-1": "OPORTUNIDADES ",
      "SERVICE-3-SMALL-2": "COMERCIALES",
      "SERVICE-4-SMALL-1": "ESTRATEGIAS ",
      "SERVICE-4-SMALL-2": "ONLINE Y OFFLINE",
      "SERVICE-5-SMALL-1": "CREACIÓN ",
      "SERVICE-5-SMALL-2": "DE CONTENIDO",
      "SERVICE-6-SMALL-1": "COACHING ",
      "SERVICE-6-SMALL-2": "& MENTORING",
      "SERVICE-7-SMALL-1": "ORGANIZACIÓN ",
      "SERVICE-7-SMALL-2": "DE CAMPUS",
      "SERVICE-8-SMALL-1": "ASESORAMIENTO ",
      "SERVICE-8-SMALL-2": "LEGAL Y FINANCIERO",
      "SERVICE-8a-SMALL-1": "ASS. LEGAL Y ",
      "SERVICE-8a-SMALL-2": "FINANCIERO",
      "SERVICE-9-SMALL-1": "SERVICIOS ",
      "SERVICE-9-SMALL-2": "POST-CARRERA",


      // contact us
      "CONTACT US": "CONTACTO",
      "GET IN TOUCH!": "¡ESCRÍBENOS!",
      "NAME": "NOMBRE",
      "FULL NAME": "Nombre y apellidos",
      "PHONE": "TELÉFONO",
      "PHONE NUMBER": "Teléfono o móvil de contacto",
      "COUNTRY": "PAÍS",
      "Country": "País",
      "EMAIL": "CORREO",
      "COMPANY AND JOB TITLE": "EMPRESA Y CARGO",
      "COMPANY NAME AND JOB TITLE": "Nombre de empresa y cargo",
      "MESSAGE": "MENSAJE",
      "YOUR MESSAGE": "Descripción",
      "SEND": "ENVIAR",
      "MESSAGE SENT SUCCESSFULLY!": "Mensaje enviado correctamente!",
      "COMPLETE ALL THE FIELDS!": "Completa todos los campos!",
      "AN ERROR HAS OCCURRED...": "Ha ocurrido un error...",

      // sports enterprises
      "SPORTS ENTERPRISES": "ENTIDADES DEPORTIVAS",
      "ENTERPRISES": "ENTIDADES",
      "SPORTS_HEADER": "ENTIDADES",
      "ENTERPRISES_HEADER": "DEPORTIVAS",
      "SPORTS-DESC-1-WEB-1": "Nuestra experiencia en el sector",
      "SPORTS-DESC-1-WEB-2": "nos proporciona los conocimientos de",
      "SPORTS-DESC-1-WEB-3": "mercado necesarios para operar a nivel",
      "SPORTS-DESC-1-WEB-4": "local, nacional e internacional.",
      "SPORTS-DESC-1-SMALL-1": "Nuestra experiencia en el sector",
      "SPORTS-DESC-1-SMALL-2": "nos proporciona los conocimientos de",
      "SPORTS-DESC-1-SMALL-3": "mercado necesarios para operar a nivel",
      "SPORTS-DESC-1-SMALL-4": "local, nacional e internacional.",
      "SPORTS-DESC-2-WEB-1": "Estamos capacitados para ofrecer soluciones",
      "SPORTS-DESC-2-WEB-2": "integrales a cualquier federación, club o entidad",
      "SPORTS-DESC-2-WEB-3": "que los requiera.",
      "SPORTS-DESC-2-SMALL-1": "Estamos capacitados para ofrecer soluciones",
      "SPORTS-DESC-2-SMALL-2": "integrales a cualquier federación, club o entidad",
      "SPORTS-DESC-2-SMALL-3": "que los requiera.",
      "SPORTS-DESC-3-WEB-1": "También buscamos contribuir al crecimiento del fútbol femenino",
      "SPORTS-DESC-3-WEB-2": "mediante la realización de eventos y actividades que promuevan",
      "SPORTS-DESC-3-WEB-3": "la práctica del deporte y ayuden a su promoción y difusión.",
      "SPORTS-DESC-3-SMALL-1": "También buscamos contribuir al crecimiento del fútbol femenino",
      "SPORTS-DESC-3-SMALL-2": "mediante la realización de eventos y actividades que promuevan",
      "SPORTS-DESC-3-SMALL-3": "la práctica del deporte y ayuden a su promoción y difusión.",

      "SPORTS-1-DESC-1": "DESARROLLO DEL",
      "SPORTS-1-DESC-2": "FÚTBOL BASE",
      "SPORTS-2-DESC-1": "PROGRAMAS",
      "SPORTS-2-DESC-2": "EDUCACIONALES",
      "SPORTS-3-DESC-1": "ORGANIZACIÓN",
      "SPORTS-3-DESC-2": "DE EVENTOS",
      "SPORTS-4-DESC-1": "ESTRATEGIAS",
      "SPORTS-4-DESC-2": "DE MARKETING",
      "SPORTS-5-DESC-1": "RESPONSABILIDAD",
      "SPORTS-5-DESC-2": "SOCIAL CORPORATIVA",


      // brands
      "BRANDS": "MARCAS",
      "CONNECT": "CONECTA",
      "WITH YOUR TARGET AUDIENCE": "con tu público objetivo",
      "CONVEY": "TRANSMITE",
      "YOUR VALUES": "valores",
      "CREATE": "CREA",
      "ENGAGING CONTENT": "contenido diferente",
      "SURPRISE": "SORPRENDE",
      "WITH NEW IDOLS": "a través de nuevas idolos",

      "BRAND-DESC-1-SMALL-1": "Asesoramos y conectamos a marcas, entidades y",
      "BRAND-DESC-1-SMALL-2": "futbolistas para crear campañas originales y disruptivas.",
      "BRAND-DESC-2-SMALL-1": "Ayudamos a seleccionar los perfiles adecuados para",
      "BRAND-DESC-2-SMALL-2": "aumentar el impacto de las acciones y alcanzar los",
      "BRAND-DESC-2-SMALL-3": "objetivos definidos.",
      "BRAND-DESC-3-SMALL-1": "Proponemos nuevas formas de comunicación para llegar",
      "BRAND-DESC-3-SMALL-2": "a la audiencia de una forma diferente, novedosa y eficaz.",

      "BRAND-DESC-1-WEB-1": "Asesoramos y conectamos",
      "BRAND-DESC-1-WEB-2": "a marcas, entidades y futbolistas",
      "BRAND-DESC-1-WEB-3": "para crear campañas originales",
      "BRAND-DESC-1-WEB-4": "y disruptivas.",
      "BRAND-DESC-2-WEB-1": "Ayudamos a seleccionar los perfiles",
      "BRAND-DESC-2-WEB-2": "adecuados para aumentar el",
      "BRAND-DESC-2-WEB-3": "impacto de las acciones y",
      "BRAND-DESC-2-WEB-4": "alcanzar los objetivos definidos.",
      "BRAND-DESC-3-WEB-1": "Proponemos nuevas formas de",
      "BRAND-DESC-3-WEB-2": "comunicación para llegar a la",
      "BRAND-DESC-3-WEB-3": "audiencia de una forma diferente,",
      "BRAND-DESC-3-WEB-4": "novedosa y eficaz.",

      "BRAND-DESC-ROW-1": "En UNIK SPORTS MANAGEMENT, pretendemos contribuir",
      "BRAND-DESC-ROW-2": "a presentar nuevas ídolos a la sociedad para que las",
      "BRAND-DESC-ROW-3": "próximas generaciones tengan referentes femeninos.",

      "ACTIVATION POSSIBILITIES": "POSIBILIDADES DE ACTIVACIÓN",

      "OTHER MARKETING ACTIONS": "OTRAS ACCIONES",

      "ACTIVATION-DESC-1": "Colabora con una o varias jugadoras para aumentar el conocimiento de tu marca. Nuestras futbolistas tienen la capacidad de atraer a clientes potenciales, de perfiles diversos, a través de contenido promocional original sobre sus experiencias y recomendaciones de vuestros productos y/o servicios.",
      "ACTIVATION-DESC-2": "Vincula tu imagen a la de una futbolista para transmitir los valores de tu marca a través de la historia de una deportista de élite. Las nuevas ídolos del balón son ejemplo de trabajo, sacrificio, resiliencia, pasión y superación. Además, tienen influencia creciente en la opinión pública y capacidad de conectar con jóvenes y adultos.",
      "ACTIVATION-DESC-3": "Une tu marca a nuestras jugadoras y posiciónate en una industria que está experimentando una evolución sin precedentes. La repercusión y visibilidad de la modalidad crece día a día, por lo que apostar por el fútbol femenino está demostrando ser una ecuación win-win; gana el patrocinador y ganan las futbolistas.",
      "ACTIVATION-DESC-4": "Cuenta con una o varias jugadoras en eventos o actividades promocionales y proyecta la imagen de tu marca mediante una acción de marketing diferenciada. La presencia de deportistas profesionales en actos corporativos es una excelente forma de llamar la atención tanto del público como de los medios.",

      // player
      "PROFILE": "PERFIL",
      "ACHIEVEMENTS": "PALMARÉS",
      "TECHNICAL REPORT": "INFORME TÉCNICO",
      "CAREER": "TRAYECTORIA",
      "LAST CAMPAIGNS": "ÚLTIMAS CAMPAÑAS",
      "PRESS": "PRENSA",
      "DATE OF BIRTH": "FECHA NAC. ",
      "CITY": "CIUDAD",
      "HEIGHT": "ALTURA",
      "WEIGHT": "PESO",
      "NATIONAL TOURNAMENTS": "TORNEOS NACIONALES",
      "INTERNATIONAL TOURNAMENTS": "TORNEOS INTERNACIONALES",
      "INDIVIDUAL AWARDS": "PREMIOS INDIVIDUALES",
      "CHARACTERISTICS": "Características",
      "DOMINANT LEG": "Pierna hábil",
      "TECHNICAL": "Técnicas",
      "TACTICAL": "Tácticas",
      "PSYCHOLOGICAL": "Psicológicas",
      "PHYSICAL": "Físicas",
      "Position": "Posición",


      // footer
      "SIGN UP TO": "¡Suscríbete a",
      "OUR NEWSLETTER!": "nuestra newsletter!",
      "EMAIL ADDRESS": "Correo electrónico",
      "Send": "Enviar",
      "PRIVACY POLICY": "Política de privacidad",
      "LEGAL WARNING": "Aviso Legal",
      "ALL RIGHT RESERVED": "Todos los derechos reservados",
      "INVALID EMAIL": "Correo no válido",

      "SPAIN": "España"
    }
  },
    pt: {
      translation: {
        "COMING SOON": "EM BREVE",
        "DAYS": "DIAS",
        "MONTHS": "MESES",

        // header
        "HEADER-ABOUT": "QUEM SOMOS",
        "HEADER-PLAYERS": "JOGADORAS",
        "HEADER-SERVICES": "SERVIÇOS",
        "HEADER-BRANDS": "MARCAS",
        "HEADER-CONTACT-US": "CONTATO",
        "HEADER-SPORTS-ENTERPRISES": "ENTIDADES ESPORTIVAS",

        // home
        "WE DELIVER WORLD-CLASS": "Criamos oportunidades",
        "OPPORTUNITIES": "ÚNICAS",

        "FOR TOP FEMALE FOOTBALLERS": "para JOGADORAS",
        "TO REALIZE THEIR": "que confiem na Unik para",
        "AMBITIONS ON AND": "alcançar seus objetivos dentro e",
        "OFF THE PITCH": "fora de campo",
        "TO REALIZE THEIR3": "que confiem na Unik",
        "AMBITIONS ON AND2": "alcançar seus objetivos dentro",
        "AMBITIONS ON AND3": "para alcançar seus objetivos",
        "OFF THE PITCH2": "e fora de campo",
        "OFF THE PITCH3": "dentro e fora de campo",

        "FOR BRANDS": "para MARCAS",
        "TO ENGAGE WITH AND": "que apostem em atletas",
        "TO ENGAGE WITH AND3": "que apostem em atletas",
        "ACTIVATE THEIR TARGET": "femininas para conectar com seu",
        "ACTIVATE THEIR TARGET2": "femininas para conectar com",
        "ACTIVATE THEIR TARGET3": "femininas para conectar",
        "AUDIENCES": "público-alvo",
        "AUDIENCES2": "seu público-alvo",
        "AUDIENCES3": "com seu público-alvo",

        "FOR SPORTS ENTERPRISES": "para ENTIDADES ESPORTIVAS",
        "TO CONTRIBUTE TO": "que queiram contribuir para",
        "THE GROWTH OF THE": "o crescimento e desenvolvimento",
        "WOMEN'S GAME": "do futebol feminino",


        "REPRESENTING TOP FEMALE FOOTBALLERS ALL OVER THE WORLD": "Representamos talentos mundiais",
        "SERVICES": "SERVIÇOS",
        "WE MANAGE OUR PLAYER'S CAREERS AND MARKETING STRATEGIES": "Gerenciamos a carreira e a imagem de nossas jogadoras",
        "CAREER MANAGEMENT": "GESTÃO DE CARREIRA",
        "SPORTS SPONSORSHIPS": "BUSCA DE PATROCÍNIO",
        "MARKETING OPPORTUNITIES": "OPORTUNIDADES DE MARKETING",
        "ONLINE & OFFLINE STRATEGIES": "ESTRATÉGIAS ON-LINE E OFF-LINE",
        "CONTENT CREATION": "CRIAÇÃO DE CONTEÚDO",
        "SPORTS CAMPS": "ORGANIZAÇÃO DE CAMPUS",
        "COACHING & MENTORING": "COACHING E MENTORING",
        "LEGAL & FINANCIAL ADVISORY": "ASSESSORAMENTO JURÍDICO E FINANCEIRO",
        "POST-CAREER SERVICES": "SERVIÇOS PÓS-CARREIRA",
        "CREDENTIALS": "CREDENCIAIS",
        "WE WORK WITH LEADING CLUBS AND BRANDS": "Trabalhamos com clubes e marcas líderes de mercado",
        "CLUBS": "CLUBES",
        "SPONSORS": "PATROCÍNIOS",
        "COLLABS": "COLABORAÇÕES",

        "MEET THE WHOLE TEAM": "CONHEÇA A EQUIPE COMPLETA",

        "MANAGEMENT": "GESTÃO",

        // about
        "ABOUT": "QUEM SOMOS",

        "ABOUT-TITLE-WEB-1": " é uma",
        "ABOUT-TITLE-WEB-2": "agência de representação e de marketing",
        "ABOUT-TITLE-WEB-3": "esportivo dedicada exclusivamente ao",
        "ABOUT-TITLE-WEB-4": "desenvolvimento do ",
        "ABOUT-TITLE-WEB-5": "futebol feminino.",
        "ABOUT-TITLE-SMALL-1": " é uma agência de representação",
        "ABOUT-TITLE-SMALL-2": "e de marketing esportivo dedicada",
        "ABOUT-TITLE-SMALL-3": "exclusivamente ao desenvolvimento ",
        "ABOUT-TITLE-SMALL-4": "do futebol feminino.",

        "ABOUT-DESC-1-WEB-1": "Criamos oportunidades únicas para jogadoras, marcas",
        "ABOUT-DESC-1-WEB-2": "e entidades esportivas.",
        "ABOUT-DESC-1-SMALL-1": "Criamos oportunidades únicas para jogadoras, marcas ",
        "ABOUT-DESC-1-SMALL-2": "e entidades esportivas.",

        "ABOUT-DESC-2-WEB-1": "Contamos com nossa vasta experiência e paixão pelo futebol feminino",
        "ABOUT-DESC-2-WEB-2": "para oferecer as melhores soluções às nossas atletas, ajudando-as a",
        "ABOUT-DESC-2-WEB-3": "alcançar seus objetivos de carreira e negócios.",
        "ABOUT-DESC-2-SMALL-1": "Contamos com nossa vasta experiência e paixão pelo futebol ",
        "ABOUT-DESC-2-SMALL-2": "feminino para oferecer as melhores soluções às nossas atletas, ",
        "ABOUT-DESC-2-SMALL-3": " ajudando-as a alcançar seus objetivos de carreira e negócios.",

        "ABOUT-DESC-3-WEB-1": "Trabalhamos com um modelo de representação integral que inclui agenciamento e",
        "ABOUT-DESC-3-WEB-2": "gestão de carreira, negociação de contratos com clubes e patrocinadores, e diversos",
        "ABOUT-DESC-3-WEB-3": "serviços relacionados às necessidades pessoais, esportivas e comerciais de nossas",
        "ABOUT-DESC-3-WEB-4": "jogadoras.",
        "ABOUT-DESC-3-SMALL-1": "Trabalhamos com um modelo de representação integral que ",
        "ABOUT-DESC-3-SMALL-2": "inclui agenciamento e gestão de carreira, negociação de ",
        "ABOUT-DESC-3-SMALL-3": "contratos com clubes e patrocinadores, e diversos serviços ",
        "ABOUT-DESC-3-SMALL-4": "relacionados às necessidades pessoais, esportivas e ",
        "ABOUT-DESC-3-SMALL-5": "comerciais de nossas jogadoras.",

        "MISSION": "MISSÃO",
        "MISSION-DESC-1": "Contribuir para a profissionalização na gestão",
        "MISSION-DESC-2": "integral do futebol feminino europeu:",
        "MISSION-DESC-3": "jogadoras, técnicos, federações,",
        "MISSION-DESC-4": "clubes e patrocinadores.",

        "VISION": "VISÃO",
        "DISTINGUISHING FACTORS": "FATORES DIFERENCIAIS",
        "FACTORS-1-WEB-1": "Pioneiros no agenciamento de",
        "FACTORS-1-WEB-2": "jogadoras de futebol feminino",
        "FACTORS-1-MEDIUM-1": "Pioneiros no",
        "FACTORS-1-MEDIUM-2": "agenciamento de",
        "FACTORS-1-MEDIUM-3": "jogadoras de futebol",
        "FACTORS-2-WEB-1": "Mais de 10 ano",
        "FACTORS-2-WEB-2": "no mercado",
        "FACTORS-2-MEDIUM-1": "Mais de 10 ano",
        "FACTORS-2-MEDIUM-2": "no mercado",
        "FACTORS-3-WEB-1": "Relação com os melhores",
        "FACTORS-3-WEB-2": "clubes europeus",
        "FACTORS-3-MEDIUM-1": "Relação com os melhores",
        "FACTORS-3-MEDIUM-2": "clubes europeus",
        "FACTORS-4-WEB-1": "Jogadoras nas ligas espanhola,",
        "FACTORS-4-WEB-2": "portuguesa, inglesa, francesa",
        "FACTORS-4-WEB-3": "e italiana",
        "FACTORS-4-MEDIUM-1": "Jogadoras nas ligas",
        "FACTORS-4-MEDIUM-2": "espanhola, portuguesa,",
        "FACTORS-4-MEDIUM-3": "inglesa, francesa",
        "FACTORS-4-MEDIUM-4": "e italiana",
        "FACTORS-5-WEB-1": "Programa de scouting único",
        "FACTORS-5-WEB-2": "a nível internacional",
        "FACTORS-5-MEDIUM-1": "Programa de scouting",
        "FACTORS-5-MEDIUM-2": "único a nível",
        "FACTORS-5-MEDIUM-3": "internacional",
        "FACTORS-6-WEB-1": "Controle e gestão",
        "FACTORS-6-WEB-2": "de talento",
        "FACTORS-6-MEDIUM-1": "Controle e gestão",
        "FACTORS-6-MEDIUM-2": "de talento",
        "FACTORS-7-WEB-1": "Pioneiros na criação e",
        "FACTORS-7-WEB-2": "gestão de campanhas",
        "FACTORS-7-WEB-3": "por atletas femininas",
        "FACTORS-7-MEDIUM-1": "Pioneiros na criação",
        "FACTORS-7-MEDIUM-2": "e gestão de campanhas",
        "FACTORS-7-MEDIUM-3": "por atletas femininas",
        "FACTORS-8-WEB-1": "Expertos em",
        "FACTORS-8-WEB-2": "branding pessoal",
        "FACTORS-8-MEDIUM-1": "Expertos em",
        "FACTORS-8-MEDIUM-2": "branding pessoal",
        "FACTORS-9-WEB-1": "Especialistas em marketing",
        "FACTORS-9-WEB-2": "on-line e influencer marketing",
        "FACTORS-9-MEDIUM-1": "Especialistas em",
        "FACTORS-9-MEDIUM-2": "marketing on-line",
        "FACTORS-9-MEDIUM-3": "e influencer",
        "FACTORS-9-MEDIUM-4": "marketing",
        "FACTORS-10-WEB-1": "Assessoramento jurídico e financeiro em",
        "FACTORS-10-WEB-2": "parceria com escritórios conceituados",
        "FACTORS-10-MEDIUM-1": "Assessoramento jurídico",
        "FACTORS-10-MEDIUM-2": "e financeiro em",
        "FACTORS-10-MEDIUM-3": "parceria com escritórios",
        "FACTORS-10-MEDIUM-4": "conceituados",

        "FACTORS-1-SMALL-1": "Pioneiros no agenciamento de",
        "FACTORS-1-SMALL-2": "jogadoras de futebol feminino",
        "FACTORS-2-SMALL-1": "Mais de 10 ano",
        "FACTORS-2-SMALL-2": "no mercado",
        "FACTORS-3-SMALL-1": "Relação com os melhores",
        "FACTORS-3-SMALL-2": "clubes europeus",
        "FACTORS-4-SMALL-1": "Jogadoras nas ligas espanhola,",
        "FACTORS-4-SMALL-2": "portuguesa, inglesa, francesa",
        "FACTORS-4-SMALL-3": "e italiana",
        "FACTORS-5-SMALL-1": "Programa de scouting único",
        "FACTORS-5-SMALL-2": "a nível internacional",
        "FACTORS-6-SMALL-1": "Controle e gestão",
        "FACTORS-6-SMALL-2": "de talento",
        "FACTORS-7-SMALL-1": "Pioneiros na criação e",
        "FACTORS-7-SMALL-2": "gestão de campanhas",
        "FACTORS-7-SMALL-3": "por atletas femininas",
        "FACTORS-8-SMALL-1": "Expertos em",
        "FACTORS-8-SMALL-2": "branding pessoal",
        "FACTORS-9-SMALL-1": "Especialistas em marketing",
        "FACTORS-9-SMALL-2": "online e influencer marketing",
        "FACTORS-10-SMALL-1": "Assessoramento jurídico e",
        "FACTORS-10-SMALL-2": "financeiro em parceria com escritórios conceituados",

        "FOUNDERS": "FUNDADORES",
        "FOUNDERS-DESC-WEB-1": "Ao longo dos anos, nossa equipe, liderada por Arkaitz Coca e Carlota Planas, construiu",
        "FOUNDERS-DESC-WEB-2": "uma relação de confiança com jogadoras, clubes, patrocinadores e associações, o que",
        "FOUNDERS-DESC-WEB-3": "nos garante uma ampla visão de mercado e uma vantagem competitiva única.",
        "FOUNDERS-DESC-SMALL-1": "Ao longo dos anos, nossa equipe, liderada por Arkaitz",
        "FOUNDERS-DESC-SMALL-2": "Coca e Carlota Planas, construiu uma relação de confiança",
        "FOUNDERS-DESC-SMALL-3": "com jogadoras, clubes, patrocinadores e associações, o que",
        "FOUNDERS-DESC-SMALL-4": "nos garante uma ampla visão de mercado e uma vantagem",
        "FOUNDERS-DESC-SMALL-5": "competitiva única.",

        "ARKAITZ-DESC-1": "Apaixonado por esporte e agenciamento, Arkaitz possui MBA em Direção de Entidades Esportivas e especialização em Direito Esportivo. Depois de trabalhar para várias agências que atendem jogadores de futebol masculino, ele decidiu empreender seu próprio caminho no futebol feminino.",
        "ARKAITZ-DESC-2": "Com esse objetivo, há 10 anos, Arkaitz abriu uma das primeiras agências de representação de jogadoras na Espanha e na Itália, o que lhe permite ser, hoje, um dos agentes com mais experiência nesse mercado.",
        "ARKAITZ-DESC-3": "Arkaitz gerenciou centenas de transferências e ajudou suas jogadoras a ingressarem nos melhores clubes do mundo. Ele também prestou consultoria a projetos de diversas entidades para dar visibilidade às mulheres no esporte.",
        "CARLOTA-DESC-1": "Graduada em Jornalismo e Administração de Empresas pela Universidade Pompeu Fabra, Carlota é especialista em Gestão e Marketing Esportivo. Iniciou sua carreira profissional no Diario AS, cobrindo as atividades do FC Barcelona; pouco tempo depois, assumiu a coordenação de um projeto de scouting internacional.",
        "CARLOTA-DESC-2": "Após alguns anos trabalhando com detecção e gestão de talentos, decidiu fundar a primeira agência de marketing dedicada exclusivamente à promoção de atletas femininas. Pioneira na conquista de campanhas e oportunidades de patrocínio para jogadoras de futebol, ela ajudou suas clientes a assinassem contratos históricos com marcas reconhecidas mundialmente.",
        "CARLOTA-DESC-3": "Em sua etapa como jogadora de futsal, ganhou vários prêmios nacionais e internacionais. Nos últimos 10 anos, atuou como treinadora em diferentes níveis; além de ser técnica da UEFA B, possui os títulos de coordenadora e especialista em futebol feminino concedidos pela Federação Catalã de Futebol.",

        "PARTNERS": "COLABORADORES",
        "SPORTS": "ESPORTIVOS",
        "EDUCATION": "ACADÊMICOS",
        "MEDIA": "COMUNICAÇÃO",
        "HEALTHCARE": "SAÚDE",
        "LEGAL": "ASSESSORAMENTO JURÍDICO",
        "HR": "RECURSOS HUMANOS",

        // unik team
        "POSITION": "POSIÇÃO",
        "POSITION-DESC": "Selecione uma ou mais posições",
        "TEAM": "EQUIPE",
        "TEAM-DESC": "Selecione um clube",
        "LEAGUE": "LIGA",
        "LEAGUE-DESC": "Selecione uma liga",
        "AGE": "IDADE",
        "AGE-DESC": "Selecione ano(s) de nascimento",
        "NATIONALITY": "NACIONALIDADE",
        "NATIONALITY-DESC": "Selecione uma nacionalidade",
        "SEARCH": "BUSCAR",
        "FULL-SQUAD": "TODAS",
        "GOALKEEPERS": "GOLEIRAS",
        "DEFENDERS": "DEFESA",
        "MIDFIELDERS": "MEIO-CAMPO",
        "FORWARDS": "ATAQUE",
        "SEE PROFILE": "Ver perfil",
        "GOALKEEPER": "GOLEIRA",
        "DEFENDER": "DEFENSORA",
        "MIDFIELDER": "MEIO-CAMPISTA",
        "FORWARD": "ATACANTE",


        // services
        "SERVICES-TITLE-WEB-1": "Comprometidos em",
        "SERVICES-TITLE-WEB-2": "rientar as estratégias profissionais",
        "SERVICES-TITLE-WEB-3": "e comerciais de nossas jogadoras, as",
        "SERVICES-TITLE-WEB-4": "ajudamos a aumentar suas fontes de renda",
        "SERVICES-TITLE-WEB-5": "e a impulsionar suas marcas pessoais.",
        "SERVICES-TITLE-MEDIUM-1": "Comprometidos em orientar as estratégias profissionais e comerciais de nossas jogadoras, ",
        "SERVICES-TITLE-MEDIUM-2": "as ajudamos a aumentar suas fontes de renda e a impulsionar suas marcas pessoais.",
        "SERVICES-TITLE-SMALL-1": "Comprometidos em orientar as estratégias profissionais e comerciais de nossas jogadoras, ",
        "SERVICES-TITLE-SMALL-2": "as ajudamos a aumentar suas fontes de renda e a impulsionar suas marcas pessoais.",
        "SERVICES-TITLE-EXTRASMALL-1": "Comprometidos em orientar as estratégias profissionais e comerciais de nossas jogadoras, ",
        "SERVICES-TITLE-EXTRASMALL-2": "as ajudamos a aumentar suas fontes de renda e a impulsionar suas marcas pessoais.",

        "SERVICE-1-WEB-1": "GESTÃO DE ",
        "SERVICE-1-WEB-2": "CARREIRA",
        "SERVICE-2-WEB-1": "BUSCA DE ",
        "SERVICE-2-WEB-2": "PATROCÍNIO",
        "SERVICE-3-WEB-1": "OPORTUNIDADES ",
        "SERVICE-3-WEB-2": "DE MARKETING",
        "SERVICE-4-WEB-1": "ESTRATÉGIAS ",
        "SERVICE-4-WEB-2": "ONLINE E OFFLINE",
        "SERVICE-5-WEB-1": "CRIAÇÃO ",
        "SERVICE-5-WEB-2": "DE CONTEÚDO",
        "SERVICE-6-WEB-1": "COACHING ",
        "SERVICE-6-WEB-2": "E MENTORING",
        "SERVICE-7-WEB-1": "ORGANIZAÇÃO ",
        "SERVICE-7-WEB-2": "DE CAMPUS",
        "SERVICE-8-WEB-1": "ASSESSORAMENTO ",
        "SERVICE-8-WEB-2": "JURÍDICO E FINANCEIRO",
        "SERVICE-8a-WEB-1": "ASS. JURÍDICO",
        "SERVICE-8a-WEB-2": "E FINANCEIRO",
        "SERVICE-9-WEB-1": "SERVIÇOS ",
        "SERVICE-9-WEB-2": "PÓS-CARREIRA",

        "SERVICE-1-SMALL-1": "GESTÃO DE ",
        "SERVICE-1-SMALL-2": "CARREIRA",
        "SERVICE-2-SMALL-1": "BUSCA DE ",
        "SERVICE-2-SMALL-2": "PATROCÍNIO",
        "SERVICE-3-SMALL-1": "OPORTUNIDADES ",
        "SERVICE-3-SMALL-2": "DE MARKETING",
        "SERVICE-4-SMALL-1": "ESTRATÉGIAS ",
        "SERVICE-4-SMALL-2": "ONLINE E OFFLINE",
        "SERVICE-5-SMALL-1": "CRIAÇÃO ",
        "SERVICE-5-SMALL-2": "DE CONTEÚDO",
        "SERVICE-6-SMALL-1": "COACHING ",
        "SERVICE-6-SMALL-2": "E MENTORING",
        "SERVICE-7-SMALL-1": "ORGANIZAÇÃO ",
        "SERVICE-7-SMALL-2": "DE CAMPUS",
        "SERVICE-8-SMALL-1": "ASSESSORAMENTO ",
        "SERVICE-8-SMALL-2": "JURÍDICO E FINANCEIRO",
        "SERVICE-8a-SMALL-1": "ASS. JURÍDICO ",
        "SERVICE-8a-SMALL-2": "E FINANCEIRO",
        "SERVICE-9-SMALL-1": "SERVIÇOS ",
        "SERVICE-9-SMALL-2": "PÓS-CARREIRA",


        // contact us
        "CONTACT US": "CONTATO",
        "GET IN TOUCH!": "FALE CONOSCO!",
        "NAME": "NOME",
        "FULL NAME": "Nome e sobrenome",
        "PHONE": "TELEFONE",
        "PHONE NUMBER": "Telefone fixo ou celular de contato",
        "COUNTRY": "PAÍS",
        "Country": "País",
        "EMAIL": "E-MAIL",
        "COMPANY AND JOB TITLE": "EMPRESA E CARGO",
        "COMPANY NAME AND JOB TITLE": "Nome da empresa e cargo",
        "MESSAGE": "MENSAGEM",
        "YOUR MESSAGE": "Descrição",
        "SEND": "ENVIAR",
        "MESSAGE SENT SUCCESSFULLY!": "Mensagem enviada corretamente!",
        "COMPLETE ALL THE FIELDS!": "Preencha todos os campos!",
        "AN ERROR HAS OCCURRED...": "Ocorreu um erro...",

        // sports enterprises
        "SPORTS ENTERPRISES": "ENTIDADES ESPORTIVAS",
        "SPORTS_HEADER": "ENTIDADES",
        "ENTERPRISES_HEADER": "ESPORTIVAS",
        "ENTERPRISES": "ENTIDADES",
        "SPORTS-DESC-1-WEB-1": "Nossa experiência com o futebol",
        "SPORTS-DESC-1-WEB-2": "feminino nos proporciona o conhecimento",
        "SPORTS-DESC-1-WEB-3": "e mercado necessário para atuar em nível",
        "SPORTS-DESC-1-WEB-4": "local, nacional e internacional.",
        "SPORTS-DESC-1-SMALL-1": "Nossa experiência com o futebol",
        "SPORTS-DESC-1-SMALL-2": "feminino nos proporciona o conhecimento",
        "SPORTS-DESC-1-SMALL-3": "e mercado necessário para atuar em nível",
        "SPORTS-DESC-1-SMALL-4": "local, nacional e internacional.",
        "SPORTS-DESC-2-WEB-1": "Estamos capacitados para oferecer soluções",
        "SPORTS-DESC-2-WEB-2": "ntegrais a qualquer federação, clube ou entidade",
        "SPORTS-DESC-2-WEB-3": "esportiva que os solicite.",
        "SPORTS-DESC-2-SMALL-1": "Estamos capacitados para oferecer soluções",
        "SPORTS-DESC-2-SMALL-2": "ntegrais a qualquer federação, clube ou entidade",
        "SPORTS-DESC-2-SMALL-3": "esportiva que os solicite.",
        "SPORTS-DESC-3-WEB-1": "Também procuramos contribuir com o crescimento do futebol feminino,",
        "SPORTS-DESC-3-WEB-2": "ealizando eventos e atividades que promovam a prática do esporte e",
        "SPORTS-DESC-3-WEB-3": "ajudem em sua promoção e difusão.",
        "SPORTS-DESC-3-SMALL-1": "Também procuramos contribuir com o crescimento do futebol feminino,",
        "SPORTS-DESC-3-SMALL-2": "ealizando eventos e atividades que promovam a prática do esporte e",
        "SPORTS-DESC-3-SMALL-3": "ajudem em sua promoção e difusão.",

        "SPORTS-1-DESC-1": "DESENVOLVIMENTO DE",
        "SPORTS-1-DESC-2": "FUTEBOL DE BASE",
        "SPORTS-2-DESC-1": "PROGRAMAS",
        "SPORTS-2-DESC-2": "EDUCATIVOS",
        "SPORTS-3-DESC-1": "ORGANIZAÇÃO",
        "SPORTS-3-DESC-2": "DE EVENTOS",
        "SPORTS-4-DESC-1": "ESTRATÉGIAS",
        "SPORTS-4-DESC-2": "DE MARKETING",
        "SPORTS-5-DESC-1": "RESPONSABILIDADE",
        "SPORTS-5-DESC-2": "SOCIAL CORPORATIVA",


        // brands
        "BRANDS": "MARCAS",
        "CONNECT": "CONECTE-SE",
        "WITH YOUR TARGET AUDIENCE": "com seu público-alvo",
        "CONVEY": "TRANSMITA",
        "YOUR VALUES": "valores",
        "CREATE": "CRIE",
        "ENGAGING CONTENT": "conteúdo original",
        "SURPRISE": "SURPREENDA",
        "WITH NEW IDOLS": "por meio de novas referências",

        "BRAND-DESC-1-SMALL-1": "Assessoramos e conectamos marcas, organizações e",
        "BRAND-DESC-1-SMALL-2": "atletas para criar campanhas originais e disruptivas.",
        "BRAND-DESC-2-SMALL-1": "Ajudamos a selecionar os perfis certos para",
        "BRAND-DESC-2-SMALL-2": "aumentar o impacto das ações e alcançar os",
        "BRAND-DESC-2-SMALL-3": "objetivos definidos.",
        "BRAND-DESC-3-SMALL-1": "Propomos novas formas de comunicação para engajar",
        "BRAND-DESC-3-SMALL-2": "a audiência de uma forma diferente, inovadora e eficaz.",

        "BRAND-DESC-1-WEB-1": "Assessoramos e conectamos",
        "BRAND-DESC-1-WEB-2": "marcas, organizações e atletas",
        "BRAND-DESC-1-WEB-3": "para criar campanhas originais",
        "BRAND-DESC-1-WEB-4": "e disruptivas.",
        "BRAND-DESC-2-WEB-1": "Ajudamos a selecionar os perfis",
        "BRAND-DESC-2-WEB-2": "certos para aumentar o impacto",
        "BRAND-DESC-2-WEB-3": "das ações e alcançar",
        "BRAND-DESC-2-WEB-4": "os objetivos definidos.",
        "BRAND-DESC-3-WEB-1": "Propomos novas formas de",
        "BRAND-DESC-3-WEB-2": "comunicação para engajar a",
        "BRAND-DESC-3-WEB-3": "audiência de uma forma diferente,",
        "BRAND-DESC-3-WEB-4": "inovadora e eficaz.",

        "BRAND-DESC-ROW-1": "Na UNIK SPORTS MANAGEMENT, queremos contribuir",
        "BRAND-DESC-ROW-2": "a apresentar novas ídolas à sociedade, para que as",
        "BRAND-DESC-ROW-3": "futuras gerações tenham mais referências femininas.",

        "ACTIVATION POSSIBILITIES": "POSSIBILIDADES DE ATIVAÇÃO",

        "OTHER MARKETING ACTIONS": "OUTRAS AÇÕES",

        "ACTIVATION-DESC-1": "Colabore com uma ou mais jogadoras para aumentar o conhecimento de marca da sua empresa. Nossas atletas têm potencial para atrair clientes e consumidores de diversos perfis, produzindo conteúdo original sobre suas experiências e recomendações de produtos e/ou serviços.",
        "ACTIVATION-DESC-2": "Vincule sua imagem a de uma jogadora para transmitir os valores de sua marca através da história de uma atleta de elite. As novas ídolas do futebol são um exemplo de trabalho, sacrifício, resiliência, paixão e superação. Além disso, elas têm influência crescente na opinião pública e a capacidade de conectar com jovens e adultos.",
        "ACTIVATION-DESC-3": "Associe sua marca às nossas atletas e posicione-se em um mercado que vive uma evolução sem precedentes. A repercussão e a visibilidade do futebol feminino crescem a cada dia, o que prova que apostar pelo futebol feminino é um investimento win-win: ganha o patrocinador e ganham as jogadoras.",
        "ACTIVATION-DESC-4": "Conte com uma ou mais jogadoras em eventos ou atividades promocionais e projete a imagem da sua marca por meio de uma ação de marketing diferenciada. A presença de atletas profissionais em eventos corporativos é uma excelente forma de atrair a atenção do público e da mídia.",

        // player
        "PROFILE": "PERFIL",
        "ACHIEVEMENTS": "TÍTULOS",
        "TECHNICAL REPORT": "INFORME TÉCNICO",
        "CAREER": "TRAJETÓRIA",
        "LAST CAMPAIGNS": "ÚLTIMAS CAMPANHAS",
        "PRESS": "IMPRENSA",
        "DATE OF BIRTH": "DATA DE NASC. ",
        "CITY": "CIDADE",
        "HEIGHT": "ALTURA",
        "WEIGHT": "PESO",
        "NATIONAL TOURNAMENTS": "TORNEIOS NACIONAIS",
        "INTERNATIONAL TOURNAMENTS": "TORNEIOS INTERNACIONAIS",
        "INDIVIDUAL AWARDS": "PRÊMIOS INDIVIDUAIS",
        "CHARACTERISTICS": "Características",
        "DOMINANT LEG": "Perna dominante",
        "TECHNICAL": "Técnicas",
        "TACTICAL": "Táticas",
        "PSYCHOLOGICAL": "Psicológicas",
        "PHYSICAL": "Física",
        "Position": "Posição",


        // footer
        "SIGN UP TO": "Assine a nossa",
        "OUR NEWSLETTER!": "newsletter!",
        "EMAIL ADDRESS": "E-mail",
        "Send": "Enviar",
        "PRIVACY POLICY": "Política de privacidade",
        "LEGAL WARNING": "Aviso Legal",
        "ALL RIGHT RESERVED": "Todos os direitos reservados",
        "INVALID EMAIL": "E-mail inválido",

        "SPAIN": "Espanha"
      }
    }
};

i18n
  .use(Backend)
  .use(initReactI18next) // bind react-i18next to the instance
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: 'es', // use en if detected lng is not available
    debug: true,

    keySeparator: false,

    /*interpolation: {
      escapeValue: false, // not needed for react!!
    },*/

    // react i18next special options (optional)
    // override if needed - omit if ok with defaults
    /*
    react: {
      bindI18n: 'languageChanged',
      bindI18nStore: '',
      transEmptyNodeValue: '',
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
      useSuspense: true,
    }
    */
    react: {
      useSuspense: false,
      wait: false,
    },
  });


export default i18n;