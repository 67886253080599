export const gestionDeportiva = {
  image: require('../images/services/service/gestion-deportiva-claudia-pina-service.jpg'),
  alt: 'gestion-deportiva-claudia-pina-service',
  icon: require('../images/services/icons/servicios-gestion-deportiva.png'),
  altIcon: 'servicios-gestion-deportiva',
  title_es: 'GESTIÓN DEPORTIVA',
  text_es: 'Entendemos cuánto valor aportan nuestras jugadoras a su equipos, por eso buscamos las mejores oportunidades en los mejores clubes del mundo. Nuestros agentes han participado en más de 200 transferencias y han cerrado acuerdos con decenas de clubes, obteniendo un amplio conocimiento de la grandes ligas europeas.',
  title_en: 'CAREER MANAGEMENT',
  text_en: 'We understand how much value our players add to their teams, that’s why we pursue the best possible opportunities with the best clubs in the world. Our agents have participated in more than 200 transfers and have closed agreements with dozens of clubs, obtaining a broad knowledge of the major European leagues.',
  title_pt: 'GESTÃO DE CARREIRA',
  text_pt: 'Sabemos o valor que nossas jogadoras agregam às suas equipes, por isso buscamos as melhores oportunidades nos melhores clubes do mundo. Nossos agentes realizaram mais de 200 transferências e assinaram acordos com dezenas de clubes, tendo adquirido um amplo conhecimento das principais ligas europeias.'
};
export const busquedaDePatrocinio = {
  image: require('../images/services/service/servicio-busqueda-de-patrocinio-Tatiana-Pinto-New Balance-service.jpg'),
  alt: 'servicio-busqueda-de-patrocinio-Tatiana-Pinto-New Balance-service',
  icon: require('../images/services/icons/servicios-busqueda-patrocinio.png'),
  altIcon: 'servicios-busqueda-patrocinio',
  title_es: 'BÚSQUEDA DE PATROCÍNIO',
  text_es: 'Conectamos a las jugadoras con las marcas deportivas más influyentes, ayudándolas a satisfacer sus necesidades. Desde material deportivo, hasta bonus y participaciones en eventos y/o campañas, el equipo de marketing de UNIK es pionero en la negociación de contratos de patrocinio en el sector del fútbol femenino.',
  title_en: 'SPORTS SPONSORSHIPS',
  text_en: "We connect players with the most influential sports brands, helping them meet their needs. From sporting equipment, to bonuses and participation in events and/or campaigns, UNIK's marketing team is a pioneer in negotiating sponsorship contracts in the women's football sector.",
  title_pt: 'BUSCA DE PATROCÍNIO',
  text_pt: 'Conectamos jogadoras com as marcas esportivas mais influentes do mercado, ajudando-as a atender suas necessidades. De equipamentos esportivos a bônus e participação em eventos e/ou campanhas publicitárias, a equipe de marketing da UNIK é pioneira na negociação de contratos de patrocínio no futebol feminino.'
};
export const oportunidadesComerciales = {
  image: require('../images/services/service/oportunidades-comerciales-nayeli-rangel-innovasport-service.jpg'),
  alt: 'oportunidades-comerciales-nayeli-rangel-innovasport-service',
  icon: require('../images/services/icons/servicios-oportunidades-comerciales.png'),
  altIcon: 'servicios-oportunidades-comerciales',
  title_es: 'OPORTUNIDADES COMERCIALES',
  text_es: 'Buscamos oportunidades de colaboración, adaptadas a cada perfil, para incrementar la presencia online y offline de nuestras jugadoras; ayudando a aumentar su visibilidad, impacto y retorno económico. Para ello, trabajamos con marcas nacionales e internacionales.',
  title_en: 'MARKETING OPPORTUNITIES',
  text_en: 'We seek for interesting business opportunities, tailored to each profile, to increase the online and offline presence of our footballers; helping them to increase their visibility, impact and economic return. To do so, we work with national and international brands.',
  title_pt: 'OPORTUNIDADES DE MARKETING',
  text_pt: 'Buscamos oportunidades de participação em campanhas de marketing, adaptadas a cada perfil, para aumentar a presença on-line e off-line de nossas jogadoras. Trabalhamos com marcas nacionais e internacionais, com o objetivo de ajudar a aumentar a visibilidade, impacto e retorno econômico das atletas.'
};
export const estrategiasOnlineYOffline = {
  image: require('../images/services/service/estrategias-online-y-offline-Brenda-Perez-Futbolmania-service.jpg'),
  alt: 'estrategias-online-y-offline-Brenda-Perez-Futbolmania-service',
  icon: require('../images/services/icons/servicios-estrategias-online-offline.png'),
  altIcon: 'servicios-estrategias-online-offline',
  title_es: 'ESTRATEGIAS ONLINE Y OFFLINE',
  text_es: 'Ayudamos a construir marcas personales y creamos estrategias personalizadas de redes sociales. Con el objetivo de resaltar las aptitudes y transmitir los valores de cada jugadora, trazamos planes de acciones totalmente individualizados.',
  title_en: 'ONLINE & OFFLINE STRATEGIES',
  text_en: 'We help build personal brands and create impactful social media strategies. In order to highlight the skills and transmit the values of each player, we draw up individualized action plans.',
  title_pt: 'ESTRATÉGIAS ON-LINE E OFF-LINE',
  text_pt: 'Ajudamos a construir marcas pessoais e criamos estratégias de impacto para as redes sociais. Elaboramos planos de ação totalmente personalizados para destacar as habilidades e transmitir os valores de cada jogadora.'
};
export const creacionDeContenido = {
  image: require('../images/services/service/servicio-creacion-contenido-service.jpg'),
  alt: 'servicio-creacion-contenido-service',
  icon: require('../images/services/icons/servicios-creacion-contenido.png'),
  altIcon: 'servicios-creacion-contenido',
  title_es: 'CREACIÓN DE CONTENIDO',
  text_es: 'Tenemos una amplia experiencia en el uso del diseño gráfico y la creación de material audiovisual para compartir historias y vivencias de una manera creativa y efectiva. También ofrecemos la posibilidad de que nuestras jugadoras dispongan de diseños personalizados para publicar en sus perfiles oficiales. ',
  title_en: 'CONTENT CREATION',
  text_en: 'We have expertise in using audiovisual content and graphic design to share narratives in a creative and effective way. We also offer the possibility to create customized designs for our players to publish on their official social media profiles.',
  title_pt: 'CRIAÇÃO DE CONTEÚDO',
  text_pt: 'Somos experientes em design gráfico e na produção de material audiovisual para contar histórias de maneira criativa e eficaz. Também oferecemos a possibilidade de criar conteúdo personalizado para nossas jogadoras compartilharem com seus seguidores em seus perfis nas redes sociais.'
};
export const organizacionDeCampus = {
  image: require('../images/services/service/organizacion-de-campus-viola-calligaris-service.jpg'),
  alt: 'organizacion-de-campus-viola-calligaris-service',
  icon: require('../images/services/icons/servicios-organizacion-campus.png'),
  altIcon: 'servicios-organizacion-campus',
  title_es: 'ORGANIZACIÓN DE CAMPUS',
  text_es: 'Organizamos y gestionamos campus deportivos y escuelas de tecnificación para que las jugadoras puedan compartir su metodología y filosofía de entrenamiento con todos aquellos que estén interesados.',
  title_en: 'SPORTS CAMPS',
  text_en: 'We organize and manage sports camps and football technification schools so that players can share their training methodology and philosophy with the younger generations.',
  title_pt: 'ORGANIZAÇÃO DE CAMPUS',
  text_pt: 'Organizamos campus esportivos e escolas de tecnificação para que as atletas possam compartilhar sua metodologia e filosofia de treinamento com o público interessado em aprender com uma jogadora de futebol profissional.'
};
export const coachingYMentoring = {
  image: require('../images/services/service/servicio-coaching-y-mentoring-Nayeli-Rangel-service.png'),
  alt: 'servicio-coaching-y-mentoring-Nayeli-Rangel-service',
  icon: require('../images/services/icons/servicios-coaching-mentoring.png'),
  altIcon: 'servicios-coaching-mentoring',
  title_es: 'COACHING Y MENTORING',
  text_es: 'A través de soluciones de aprendizaje enriquecedoras, profundas y motivadoras, buscamos favorecer las relaciones personales, mejorar el rendimiento y provocar cambios en la vida profesional y personal de las jugadoras que soliciten dicho servicio.',
  title_en: 'COACHING & MENTORING',
  text_en: 'Through enriching, deep and motivating learning solutions, we seek to foster personal relationships, improve performance and bring about important changes in the personal and professional lives of our players.',
  title_pt: 'COACHING E MENTORING',
  text_pt: 'Por meio de soluções de aprendizado enriquecedoras, profundas e motivadoras, buscamos melhorar relacionamentos pessoais, aumentar o desempenho e promover mudanças na vida profissional e pessoal das jogadoras que solicitem este serviço.'
};
export const asesoramientoLegalYFinanciero = {
  image: require('../images/services/service/servicio-asesoramiento-legal-financiero-service.jpg'),
  alt: 'servicio-asesoramiento-legal-financiero-service',
  icon: require('../images/services/icons/servicios-asesoramiento-legal-financiero.png'),
  altIcon: 'servicios-asesoramiento-legal-financiero',
  title_es: 'ASESORAMIENTO LEGAL Y FINANCIERO',
  text_es: 'Trabajamos con bufetes especializados para ofrecer asesoramiento legal y financiero a todas las jugadoras que lo requieran. Desde gestión de  impuestos y tramitación de seguros y planes de pensiones, hasta tributación internacional y análisis de inversiones financieras.',
  title_en: 'LEGAL & FINANCIAL ADVISORY',
  text_en: 'We work with specialized law firms to offer legal and financial advice to all players. From tax and income management, to insurance and pension plans, international taxation and financial investment analysis.',
  title_pt: 'ASSESSORAMENTO JURÍDICO E FINANCEIRO',
  text_pt: 'Trabalhamos com empresas conceituadas no mercado para oferecer consultoria jurídica e financeira a todas as jogadoras que a requeiram. Podemos assessorá-las com gestão tributária e de rendimentos, processamento de seguros e planos de pensão, tributação internacional e análise de investimentos financeiros.'
};
export const serviciosPostCarrera = {
  image: require('../images/services/service/servicios-post-carrera-Esther-Gonzalez-service.jpg'),
  alt: 'servicios-post-carrera-Esther-Gonzalez-service',
  icon: require('../images/services/icons/servicios-post-carrera.png'),
  altIcon: 'servicios-post-carrera',
  title_es: 'SERVICIOS POST-CARRERA',
  text_es: 'La vida después del fútbol profesional puede ser un momento difícil, por este motivo, ponemos a disposición de nuestras jugadoras todos los recursos para que se formen y encuentren una salida laboral de su conveniencia. Para cumplir con nuestro cometido, colaboramos con varias instituciones y universidades de renombre internacional.',
  title_en: 'POST-CAREER SERVICES',
  text_en: 'Life after professional football can be a challenging time, but we offer support, advice and networking to help our players decide what career will suit them best. To fulfill our mission, we collaborate with several internationally renowned institutions and universities.',
  title_pt: 'SERVIÇOS PÓS-CARREIRA',
  text_pt: 'A aposentadoria do futebol pode ser um momento desafiador para uma jogadora de elite. Por esse motivo, disponibilizamos às nossas atletas todos os recursos para que elas tenham uma excelente formação e possam encontrar uma oportunidade de trabalho que as interesse quando decidirem pendurar as chuteiras. Para cumprir nossa missão, colaboramos com várias instituições e universidades de renome internacional.'
};
