import * as actionTypes from '../actions/actionTypes';

const initialState = {
  selectedPrivacyPolicy: ''
};

// Set Modified
const setSelectedPrivacyPolicy = (state, action) => {
  state.selectedPrivacyPolicy = action.selectedPrivacyPolicy;
  return state;
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_SERVICE: return setSelectedPrivacyPolicy(state, action);
    default: return state;
  }
};

export default reducer;