import React, { useState, useEffect } from 'react';
import '../../App.css';
import './Services.css';
import Footer from "../footer/Footer";
import Grid from '@material-ui/core/Grid';

import ServiesEven from '../services-types/services-even/Services-even';
import ServiesMobile from '../services-types/services-mobile/Services-mobile';
import ServiesOdd from '../services-types/services-odd/Services-odd';

import { gestionDeportiva, busquedaDePatrocinio, asesoramientoLegalYFinanciero,
  coachingYMentoring, creacionDeContenido, estrategiasOnlineYOffline,
  oportunidadesComerciales, organizacionDeCampus, serviciosPostCarrera } from '../../data/services';

import { useDispatch, useSelector } from 'react-redux';
import { setSelectedService } from '../../store/actions/selectedService'
import { useTranslation } from "react-i18next";
import DocumentMeta from 'react-document-meta';

const blueLines = require('../../images/tres_rayas.png');
const serviceTrophy = require('../../images/services/trophy-service.png');

const Services = () => {
  const dispatch = useDispatch();
  const selectedServiceSelector = useSelector(state => state.selectedService.selectedService);
  const meta = {
    title: 'UNIK SPORTS MANAGEMENT | Servicios para jugadoras',
    description: 'Ofrecemos una gestión integral basada en la representación deportiva, búsqueda de patrocinio y maximización de oportunidades.',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  };

  const [t, i18n] = useTranslation();

  useEffect(() => {
    if(selectedServiceSelector) {
      goToId(selectedServiceSelector);
      dispatch(setSelectedService(null));
    }
  }, [selectedServiceSelector]);

  const goToId = (serviceId) => {
    let offsetTop  = document.getElementById(serviceId).offsetTop;
    window.scrollTo({
      top: offsetTop-10,
      behavior: "smooth"
    });
  };

  return(
    <DocumentMeta {...meta}>
      <div className="serviceDiv"
           style={{
        background: `url(${serviceTrophy}) no-repeat right top`,
        backgroundSize: '55% auto'
      }}>
        <div className="serviceLabelRow">
          <div className="threeLinesServices">
            <img className="linesServices"
                 src={blueLines}
                 alt='blue lines' />
          </div>
          <div className="unikLabel">
            {t('SERVICES')}
          </div>
        </div>

        <div className="serviceLabelRowSmall">
          <div className="serviceThreeLinesSmall">
            <img className="lines"
                 src={blueLines}
                 alt='blue lines' />
          </div>
          <div className="serviceLabelSmall">
            {t('SERVICES')}
          </div>
        </div>

        <div className="titleServicesText">
          <b>{t('SERVICES-TITLE-WEB-1')}<br/>
          {t('SERVICES-TITLE-WEB-2')}<br/>
            {t('SERVICES-TITLE-WEB-3')}<br/></b>
          {t('SERVICES-TITLE-WEB-4')}<br/>
          {t('SERVICES-TITLE-WEB-5')}
        </div>
        <div className="titleServicesTextMedium">
          <b>{t('SERVICES-TITLE-MEDIUM-1')}</b>
          {t('SERVICES-TITLE-MEDIUM-2')}
        </div>
        <div className="titleServicesTextSmall">
          <b>{t('SERVICES-TITLE-SMALL-1')}</b>
          {t('SERVICES-TITLE-SMALL-2')}
        </div>
        <div className="titleServicesTextExtraSmall">
          <b>{t('SERVICES-TITLE-EXTRASMALL-1')}</b>
          {t('SERVICES-TITLE-EXTRASMALL-2')}
        </div>
        <div className="divGridsPositionsBig">
          <Grid container
                className="gridFiltersServices"
                spacing={3}
                wrap={"nowrap"}
                direction="row"
                alignItems="center"
                justify="center">
            <Grid item xs={2}
                  onClick={() => goToId('gestion-deportiva')}
                  className="columnService">
              <div className="labelService">{t('SERVICE-1-WEB-1')}<br/>{t('SERVICE-1-WEB-2')}</div>
            </Grid>
            <Grid item xs={2}
                  onClick={() => goToId('busqueda-patrocinio')}
                  className="columnService">
              <div className="labelService">{t('SERVICE-2-WEB-1')}<br/>{t('SERVICE-2-WEB-2')}</div>
            </Grid>
            <Grid item xs={2}
                  onClick={() => goToId('oportunidades-comerciales')}
                  className="columnService">
              <div className="labelService">{t('SERVICE-3-WEB-1')}<br/>{t('SERVICE-3-WEB-2')}</div>
            </Grid>
            <Grid item xs={2}
                  onClick={() => goToId('estrategias-online-offline')}
                  className="columnService">
              <div className="labelService">{t('SERVICE-4-WEB-1')}<br/>{t('SERVICE-4-WEB-2')}</div>
            </Grid>
            <Grid item xs={2}
                  onClick={() => goToId('creacion-contenido')}
                  className="columnServiceLast">
              <div className="labelService">{t('SERVICE-5-WEB-1')}<br/>{t('SERVICE-5-WEB-2')}</div>
            </Grid>
          </Grid>
          <Grid container
                className="gridFiltersSecondServices"
                spacing={3}
                direction="row"
                alignItems="center"
                justify="center">
            <Grid item xs={2}
                  onClick={() => goToId('coaching-mentoring')}
                  className="columnService">
              <div className="labelService">{t('SERVICE-6-WEB-1')}<br/>{t('SERVICE-6-WEB-2')}</div>
            </Grid>
            <Grid item xs={2}
                  onClick={() => goToId('organizacion-campus')}
                  className="columnService">
              <div className="labelService">{t('SERVICE-7-WEB-1')}<br/>{t('SERVICE-7-WEB-2')}</div>
            </Grid>
            <Grid item xs={2}
                  onClick={() => goToId('asesoramiento-legal-financiero')}
                  className="columnService">
              <div id="asesoramientoWeb" className="labelService">{t('SERVICE-8-WEB-1')}<br/>{t('SERVICE-8-WEB-2')}</div>
              <div id="asesoramientoTablet" className="labelService">{t('SERVICE-8a-WEB-1')}<br/>{t('SERVICE-8a-WEB-2')}</div>
            </Grid>
            <Grid item xs={2}
                  onClick={() => goToId('servicios-post-carrera')}
                  className="columnServiceLast">
              <div className="labelService">{t('SERVICE-9-WEB-1')}<br/>{t('SERVICE-9-WEB-2')}</div>
            </Grid>
          </Grid>
        </div>

        <div className="divGridsPositionsSmall">
          <Grid container
                className="gridFiltersPositionsSmall"
                spacing={3}
                direction="row"
                alignItems="center"
                justify="center">
            <Grid item xs={4}
                  onClick={() => goToId('gestion-deportiva')}
                  className="columnService">
              <div className="labelService">{t('SERVICE-1-SMALL-1')}<br/>{t('SERVICE-1-SMALL-2')}</div>
            </Grid>
            <Grid item xs={4}
                  onClick={() => goToId('busqueda-patrocinio')}
                  className="columnService">
              <div className="labelService">{t('SERVICE-2-SMALL-1')}<br/>{t('SERVICE-2-SMALL-2')}</div>
            </Grid>
            <Grid item xs={4}
                  onClick={() => goToId('oportunidades-comerciales')}
                  className="columnServiceLast">
              <div className="labelService">{t('SERVICE-3-SMALL-1')}<br/>{t('SERVICE-3-SMALL-2')}</div>
            </Grid>
          </Grid>
          <Grid container
                className="gridFiltersPositionsSmall"
                spacing={3}
                direction="row"
                alignItems="center"
                justify="center">
            <Grid item xs={4}
                  onClick={() => goToId('estrategias-online-offline')}
                  className="columnService">
              <div className="labelService">{t('SERVICE-4-SMALL-1')}<br/>{t('SERVICE-4-SMALL-2')}</div>
            </Grid>
            <Grid item xs={4}
                  onClick={() => goToId('creacion-contenido')}
                  className="columnService">
              <div className="labelService">{t('SERVICE-5-SMALL-1')}<br/>{t('SERVICE-5-SMALL-2')}</div>
            </Grid>
            <Grid item xs={4}
                  onClick={() => goToId('coaching-mentoring')}
                  className="columnServiceLast">
              <div className="labelService">{t('SERVICE-6-SMALL-1')}<br/>{t('SERVICE-6-SMALL-2')}</div>
            </Grid>
          </Grid>
          <Grid container
                className="gridFiltersPositionsSmall"
                spacing={3}
                direction="row"
                alignItems="center"
                justify="center">
            <Grid item xs={4}
                  onClick={() => goToId('organizacion-campus')}
                  className="columnService">
              <div className="labelService">{t('SERVICE-7-SMALL-1')}<br/>{t('SERVICE-7-SMALL-2')}</div>
            </Grid>
            <Grid item xs={4}
                  onClick={() => goToId('asesoramiento-legal-financiero')}
                  className="columnService">
              <div id="asesoramientoWeb" className="labelService">{t('SERVICE-8-SMALL-1')}<br/>{t('SERVICE-8-SMALL-2')}</div>
              <div id="asesoramientoTablet" className="labelService">{t('SERVICE-8a-SMALL-1')}<br/>{t('SERVICE-8a-SMALL-2')}</div>
            </Grid>
            <Grid item xs={4}
                  onClick={() => goToId('servicios-post-carrera')}
                  className="columnServiceLast">
              <div className="labelService">{t('SERVICE-9-SMALL-1')}<br/>{t('SERVICE-9-SMALL-2')}</div>
            </Grid>
          </Grid>
        </div>

        <div id="gestion-deportiva">
          <ServiesOdd service={gestionDeportiva} className="rowOdd"/>
          <ServiesMobile service={gestionDeportiva} className="rowMobile"/>
        </div>
        <div id="busqueda-patrocinio">
          <ServiesEven service={busquedaDePatrocinio} className="rowEven"/>
          <ServiesMobile service={busquedaDePatrocinio} className="rowMobile"/>
        </div>
        <div id="oportunidades-comerciales">
          <ServiesOdd service={oportunidadesComerciales} className="rowOdd"/>
          <ServiesMobile service={oportunidadesComerciales} className="rowMobile"/>
        </div>
        <div id="estrategias-online-offline">
          <ServiesEven service={estrategiasOnlineYOffline} className="rowEven"/>
          <ServiesMobile service={estrategiasOnlineYOffline} className="rowMobile"/>
        </div>
        <div id="creacion-contenido">
          <ServiesOdd service={creacionDeContenido} className="rowOdd"/>
          <ServiesMobile service={creacionDeContenido} className="rowMobile"/>
        </div>
        <div id="organizacion-campus">
          <ServiesEven service={organizacionDeCampus} className="rowEven"/>
          <ServiesMobile service={organizacionDeCampus} className="rowMobile"/>
        </div>
        <div id="coaching-mentoring">
          <ServiesOdd service={coachingYMentoring} className="rowOdd"/>
          <ServiesMobile service={coachingYMentoring} className="rowMobile"/>
        </div>
        <div id="asesoramiento-legal-financiero">
          <ServiesEven service={asesoramientoLegalYFinanciero} className="rowEven"/>
          <ServiesMobile service={asesoramientoLegalYFinanciero} className="rowMobile"/>
        </div>
        <div id="servicios-post-carrera">
          <ServiesOdd service={serviciosPostCarrera} className="rowOdd"/>
          <ServiesMobile service={serviciosPostCarrera} className="rowMobile"/>
        </div>
        <Footer page={'Jugadoras'}/>
      </div>
    </DocumentMeta>
  );
};

export default Services;