import React, { useState, useEffect } from 'react';
import '../../App.css';
import './Player-square-grid.css'
import { useHistory, useRouteMatch } from 'react-router-dom';
import {useTranslation} from "react-i18next";

const PlayerSquareGrid = ({player}) => {

  const history = useHistory();
  const [t, i18n] = useTranslation();

  const clickSquare = () => {
    history.push(`/futbolistas/${player.nameUrl}`, player);
  };

  return(
    <div className="square-grid"
         onClick={() => clickSquare()}>
      <div className="divPhoto-grid">
        <img className="photo-grid"
             src={player.url}
             alt={player.alt}/>
      </div>

      <div className="infoPlayer-grid">
        <div className="name-grid">{player.name}</div>
        <div className="club-grid">{player.team}</div>
      </div>
      <div className="blueHover-grid">
        <div className="seeProfile-grid">
            {t('SEE PROFILE')}
        </div>
        <div className="arrowSeeProfile-grid">
          &#8594;
        </div>
      </div>
    </div>
  )
};

export default PlayerSquareGrid;