import React, { useState, useEffect } from 'react';
import '../../App.css';
import './Header.css'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import Fade from '@material-ui/core/Fade';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  Redirect
} from "react-router-dom";


import Home from "../home/Home";
import About from "../about/About";
import Players from "../players/Players";
import Player from "../player/Player";
import ContactUs from "../contact-us/Contact-us";
import Services from "../services/Services";
import Brands from "../brands/Brands";
import EntidadesDeportivas from "../entidades-deportivas/Entidades-deportivas";
import PrivacyPolicy from "../privacy-policy/Privacy-policy";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPage } from '../../store/actions/selectedPage'
import { createBrowserHistory } from "history";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const logo = require('../../images/logo-unik-sports-management.png');
const whitePoint = require('../../images/whitePoint.png');

const customHistory = createBrowserHistory();

const Header = (props) => {
  const dispatch = useDispatch();
  const selectedPageSelector = useSelector(state => state.selectedPage.selectedPage);

  // const [selected, setSelected] = useState(window.location.href.split('/')[4]);
  const [selected, setSelected] = useState(window.location.pathname.split('/')[2]);

  const [lang, setLang] = useState('en-GB');
  const [t, i18n] = useTranslation();

  useEffect(() => {
    let pathName = window.location.pathname;
    let url = pathName.split('/')[1];
    setSelected('/'+url);
    // let url = window.location.href.split('/')[4];
    // setSelected('/'+url);
    console.log(url, selected)
  });

  const selectedPage = (slctd) => {
    let url = window.location.href.split('/')[4];
    setSelected(slctd)
  };

  useEffect(() => {
    setLang(i18n.language);
  }, []);

  useEffect(() => {
    setSelected(selectedPageSelector);
  }, [selectedPageSelector]);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorMenuMobile, setAnchorMenuMobile] = React.useState(null);
  const [anchorMenuMobileSubMenu, setAnchorMenuMobileSubMenu] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickMenuMobile = (event) => {
    setAnchorMenuMobile(event.currentTarget);
  };

  const handleCloseMenuMobile = () => {
    setAnchorMenuMobile(null);
  };

  const handleClickMenuMobileSubMenu = () => {
    setAnchorMenuMobileSubMenu((bool) => !bool);
  };


  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setLang(lng);
  };

  return(
    <Router history={customHistory} basename={'/'}>
      {
        // (window.location.href.split('/')[4] === '/' || selected === '/') ?
        (window.location.pathname.split('/')[1] === '/' || selected === '/') ?
            <AppBar position="absolute"
                    id="menuAbsolute"
                    style={{background: 'transparent', boxShadow: 'none'}}>
              {/*</Toolbar>*/}
              {/*<Link>*/}
                <div className="logo">
                  <img className="photoHeader"
                       src={logo}
                       alt="unik-sports-management-logo"/>
                </div>
              {/*</Link>*/}
              <div className="languageHeader">
                {
                  (lang === 'en-GB') ?
                      <div className="selectedLanguage">ENG</div> :
                      <div className="language"
                           onClick={() => changeLanguage('en-GB')}>ENG</div>
                }
                {
                  (lang === 'es-ES') ?
                      <div className="selectedLanguage">ES</div> :
                      <div className="language"
                           onClick={() => changeLanguage('es-ES')}>ES</div>
                }
                {
                  (lang === 'pt-PT') ?
                      <div className="selectedLanguage">PT</div> :
                      <div className="language"
                           onClick={() => changeLanguage('pt-PT')}>PT</div>
                }
              </div>
              <Toolbar>
                <div className="Header">
                  <Link to="/filosofia"
                        onClick={() => selectedPage('/filosofia')}>{t('HEADER-ABOUT')}</Link>

                  <Link to="#">
                    <div id="playersLabel"
                         aria-controls="simple-menu"
                         aria-haspopup="true"
                         aria-owns={anchorEl ? "simple-menu" : undefined}
                         onClick={handleClick}
                         onMouseOver={handleClick}>
                      {t('HEADER-PLAYERS')}
                    </div>
                    <Menu
                      id="simple-menu-absolute"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                      MenuListProps={{ onMouseLeave: handleClose }}
                    >
                      <Link to="/players"
                            className="linkStyle">
                        <MenuItem className="menuItem"
                                  onClick={() => {
                                    selectedPage('/players');
                                    handleClose();
                                  }}>UNIK TEAM</MenuItem>
                      </Link>
                      <Link to="/services"
                            className="linkStyle">
                        <MenuItem className="menuItem"
                                  onClick={() => {
                                    selectedPage('/services');
                                    handleClose();
                                  }}>{t('HEADER-SERVICES')}</MenuItem>
                      </Link>
                    </Menu>
                    {
                      (selected === '/players' || selected === '/player' || selected === '/futbolistas' || selected === '/services') ?
                        <div className="divWhitePoint">
                          <img className="whitePoint"
                               src={whitePoint}
                               alt="white-point"/>
                        </div> : null
                    }
                  </Link>

                  <Link to="/brands"
                        onClick={() => selectedPage('/brands')}>{t('HEADER-BRANDS')}</Link>

                  <Link to="/sports-enterprises"
                        onClick={() => selectedPage('/sports-enterprises')}>{t('HEADER-SPORTS-ENTERPRISES')}</Link>

                  <Link to="/contact-us"
                        onClick={() => selectedPage('/contact')}>{t('HEADER-CONTACT-US')}</Link>
                </div>
                <IconButton  className="threeDotsMenu"
                             size="small"
                             aria-label="display more actions"
                             edge="end"
                             color="inherit"
                             onClick={handleClickMenuMobile}>
                  <MoreIcon />
                </IconButton>
                <Menu
                  id="menu-mobile-home"
                  anchorEl={anchorMenuMobile}
                  keepMounted
                  open={Boolean(anchorMenuMobile)}
                  onClose={handleCloseMenuMobile}
                  TransitionComponent={Fade}
                >
                  <Link to="/filosofia"
                        className="linkStyle">
                    <MenuItem className="menuItem"
                              onClick={() => {
                                selectedPage('/filosofia');
                                handleCloseMenuMobile();
                              }}>{t('HEADER-ABOUT')}</MenuItem>
                  </Link>
                  <Link to="#"
                        className="linkStyle">
                    <MenuItem className="menuItem"
                              onClick={() => {
                                handleClickMenuMobileSubMenu()
                              }}>{t('HEADER-PLAYERS')}</MenuItem>
                  </Link>
                  {
                    (anchorMenuMobileSubMenu) ?
                      <div>
                        <Link to="/players"
                              className="linkStyle">
                          <MenuItem className="menuSubItem"
                                    onClick={() => {
                                      selectedPage('/players');
                                      handleCloseMenuMobile();
                                    }}>UNIK TEAM</MenuItem>
                        </Link>
                        <Link to="/services"
                              className="linkStyle">
                          <MenuItem className="menuSubItemLast"
                                    onClick={() => {
                                      selectedPage('/services');
                                      handleCloseMenuMobile();
                                    }}>{t('HEADER-SERVICES')}</MenuItem>
                        </Link>
                      </div>: null
                  }

                  <Link to="/brands"
                        className="linkStyle">
                    <MenuItem className="menuItem"
                              onClick={() => {
                                selectedPage('/brands');
                                handleCloseMenuMobile();
                              }}>{t('HEADER-BRANDS')}</MenuItem>
                  </Link>
                  <Link to="/sports-enterprises"
                        className="linkStyle">
                    <MenuItem className="menuItem"
                              onClick={() => {
                                selectedPage('/sports-enterprises');
                                handleCloseMenuMobile();
                              }}>{t('HEADER-SPORTS-ENTERPRISES')}</MenuItem>
                  </Link>
                  <Link to="/contact-us"
                        className="linkStyle">
                    <MenuItem className="menuItem"
                              onClick={() => {
                                selectedPage('/contact-us');
                                handleCloseMenuMobile();
                              }}>{t('HEADER-CONTACT-US')}</MenuItem>
                  </Link>
                </Menu>
              </Toolbar>
            </AppBar> :
            <AppBar position="static"
              style={{ background: '#052750', boxShadow: 'none'}}>
              <Link to="/"
                    className="linkPhoto"
                    onClick={() => selectedPage('/')}>
                <div className="logo">
                  <img className="photoHeader"
                       src={logo}
                       alt="unik-sports-management"/>
                </div>
              </Link>
              <div className="languageHeader">
                {
                  (lang === 'en-GB') ?
                      <div className="selectedLanguage">ENG</div> :
                      <div className="language"
                           onClick={() => changeLanguage('en-GB')}>ENG</div>
                }
                {
                  (lang === 'es-ES') ?
                      <div className="selectedLanguage">ES</div> :
                      <div className="language"
                           onClick={() => changeLanguage('es-ES')}>ES</div>
                }
                {
                  (lang === 'pt-PT') ?
                      <div className="selectedLanguage">PT</div> :
                      <div className="language"
                           onClick={() => changeLanguage('pt-PT')}>PT</div>
                }
              </div>
              <Toolbar>
                <div className="Header-static">
                  <Link to="/filosofia"
                        className="linkStyle"
                        onClick={() => selectedPage('/filosofia')}>
                    <div className="pageName">
                      {t('HEADER-ABOUT')}
                    </div>
                    {
                      (selected === '/filosofia') ?
                        <div className="divWhitePoint">
                          <img className="whitePoint"
                               src={whitePoint}
                               alt="white-point"/>
                        </div> : null
                    }
                  </Link>

                  <Link to="#"
                        className="linkStyle">
                    <div className="pageName"
                         aria-controls="simple-menu"
                         aria-haspopup="true"
                         aria-owns={anchorEl ? "simple-menu" : undefined}
                         onClick={handleClick}
                         onMouseOver={handleClick}>
                      {t('HEADER-PLAYERS')}
                    </div>
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      TransitionComponent={Fade}
                      MenuListProps={{ onMouseLeave: handleClose }}
                    >
                      <Link to="/players"
                            className="linkStyle">
                        <MenuItem className="menuItem"
                                  onClick={() => {
                                    selectedPage('/players');
                                    handleClose();
                                  }}>UNIK TEAM</MenuItem>
                      </Link>
                      <Link to="/services"
                            className="linkStyle">
                        <MenuItem className="menuItem"
                                  onClick={() => {
                                    selectedPage('/services');
                                    handleClose();
                                  }}>{t('HEADER-SERVICES')}</MenuItem>
                      </Link>
                    </Menu>
                    {
                      (selected === '/players' || selected === '/player' || selected === '/futbolistas' || selected === '/services') ?
                        <div className="divWhitePoint">
                          <img className="whitePoint"
                               src={whitePoint}
                               alt="white-point"/>
                        </div> : null
                    }
                  </Link>

                  <Link to="/brands"
                        className="linkStyle"
                        onClick={() => selectedPage('/brands')}>
                    <div className="pageName">
                      {t('HEADER-BRANDS')}
                    </div>
                    {
                      (selected === '/brands') ?
                        <div className="divWhitePoint">
                          <img className="whitePoint"
                               src={whitePoint}
                               alt="white-point"/>
                        </div> : null
                    }
                  </Link>

                  <Link to="/sports-enterprises"
                        className="linkStyle"
                        onClick={() => selectedPage('/sports-enterprises')}>
                    <div className="pageName">
                      {t('HEADER-SPORTS-ENTERPRISES')}
                    </div>
                    {
                      (selected === '/sports-enterprises') ?
                        <div className="divWhitePoint">
                          <img className="whitePoint"
                               src={whitePoint}
                               alt="white-point"/>
                        </div> : null
                    }
                  </Link>

                  <Link to="/contact-us"
                        className="linkStyle"
                        onClick={() => selectedPage('/contact-us')}>
                    <div className="pageName">
                      {t('HEADER-CONTACT-US')}
                    </div>
                    {
                      (selected === '/contact-us') ?
                        <div className="divWhitePoint">
                          <img className="whitePoint"
                               src={whitePoint}
                               alt="white-point"/>
                        </div> : null
                    }
                  </Link>
                </div>
                <IconButton  className="threeDotsMenu"
                             size="small"
                             aria-label="display more actions"
                             edge="end"
                             color="inherit"
                             onClick={handleClickMenuMobile}>
                  <MoreIcon />
                </IconButton>
                <Menu
                  id="menu-mobile"
                  anchorEl={anchorMenuMobile}
                  keepMounted
                  open={Boolean(anchorMenuMobile)}
                  onClose={handleCloseMenuMobile}
                  TransitionComponent={Fade}
                >
                  <Link to="/filosofia"
                        className="linkStyle">
                    <MenuItem className="menuItem"
                              onClick={() => {
                                selectedPage('/filosofia');
                                handleCloseMenuMobile();
                              }}>{t('HEADER-ABOUT')}</MenuItem>
                  </Link>
                  <Link to="#"
                        className="linkStyle">
                    <MenuItem className="menuItem"
                              onClick={() => {
                                handleClickMenuMobileSubMenu()
                              }}>{t('HEADER-PLAYERS')}</MenuItem>
                  </Link>
                  {
                    (anchorMenuMobileSubMenu) ?
                      <div>
                        <Link to="/players"
                              className="linkStyle">
                          <MenuItem className="menuSubItem"
                                    onClick={() => {
                                      selectedPage('/players');
                                      handleCloseMenuMobile();
                                    }}>UNIK TEAM</MenuItem>
                        </Link>
                        <Link to="/services"
                              className="linkStyle">
                          <MenuItem className="menuSubItemLast"
                                    onClick={() => {
                                      selectedPage('/services');
                                      handleCloseMenuMobile();
                                    }}>{t('HEADER-SERVICES')}</MenuItem>
                        </Link>
                      </div>: null
                  }

                  <Link to="/brands"
                        className="linkStyle">
                    <MenuItem className="menuItem"
                              onClick={() => {
                                selectedPage('/brands');
                                handleCloseMenuMobile();
                              }}>{t('HEADER-BRANDS')}</MenuItem>
                  </Link>
                  <Link to="/sports-enterprises"
                        className="linkStyle">
                    <MenuItem className="menuItem"
                              onClick={() => {
                                selectedPage('/sports-enterprises');
                                handleCloseMenuMobile();
                              }}>{t('HEADER-SPORTS-ENTERPRISES')}</MenuItem>
                  </Link>
                  <Link to="/contact-us"
                        className="linkStyle">
                    <MenuItem className="menuItem"
                              onClick={() => {
                                selectedPage('/contact-us');
                                handleCloseMenuMobile();
                              }}>{t('HEADER-CONTACT-US')}</MenuItem>
                  </Link>
                </Menu>
              </Toolbar>
            </AppBar>
      }



      <Switch>
        <Route exact path="filosofia">
          <Redirect to="/filosofia" />
        </Route>

        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/filosofia">
          <About />
        </Route>
        <Route path="/players">
          <Players />
        </Route>
        <Route path="/brands">
          <Brands />
        </Route>
        <Route path="/sports-enterprises">
          <EntidadesDeportivas/>
        </Route>
        <Route path="/contact-us">
          <ContactUs/>
        </Route>
        <Route path="/services">
          <Services/>
        </Route>
        <Route path="/futbolistas/:name">
          <Player/>
        </Route>
        <Route path="/privacy-policy">
          <PrivacyPolicy/>
        </Route>
        {/*<Redirect from='/players' to='/#/players' />*/}
        {/*<Redirect from='/brands' to='/#/brands' />*/}
        {/*<Redirect from='/sports-enterprises' to='/#/sports-enterprises' />*/}
        {/*<Redirect from='/contact-us' to='/#/contact-us' />*/}
        {/*<Redirect from='/services' to='/#/services' />*/}
        {/*<Redirect from='/futbolistas/:name' to='/#/futbolistas/:name' />*/}
        {/*<Redirect from='/privacy-policy' to='/#/privacy-policy' />*/}
        <Route path="*">
          <Redirect to="/#/" />
        </Route>
      </Switch>
    </Router>
  )
};

export default Header;
