import React, { useState, useEffect } from 'react';
import '../../../App.css';
import './Services-odd.css';
import Grid from '@material-ui/core/Grid';
import {useTranslation} from "react-i18next";

const blueMultiLines = require('../../../images/player-graphics/blue-lines.png');

const ServicesOdd = ({service}) => {

  const [lang, setLang] = useState('en');
  const [t, i18n] = useTranslation();

  useEffect(() => {
    setLang(i18n.languages[1]);
    console.log('player', i18n.languages[1]);
  }, [i18n.languages[1]]);

  return(
    <div>
      <div className="marginHorizontalGridWeb">
        <Grid container
              className="gridRowServices"
              spacing={7}
              direction="row"
              alignItems="flex-start"
              justify="center">
          <Grid item xs={5}
                style={{
                  textAlign: 'end'
                }}>
            <div className="imageDivOdd">
              <img src={service.image}
                   alt={service.alt}
                   className="imageServiceOdd"/>
            </div>
          </Grid>
          <Grid item xs={7}>
            <div style={{
              paddingBottom: '30px',
              marginRight: '-60px',
              background: `url(${blueMultiLines}) no-repeat right bottom`
            }}>
              <div style={{
                marginRight: '60px'
              }}>
                <div className="titleServiceOdd">
                  <img src={service.icon}
                       alt={service.altIcon}
                       className="iconTitleServiceOdd"/>
                  <div className="textTitleServiceOdd">
                    {service['title_'+ lang]}
                  </div>
                </div>
                <div className="textServiceOdd">
                  {service['text_'+ lang]}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className="marginHorizontalGridTablet">
        <Grid container
              className="gridRowServices"
              spacing={7}
              direction="row"
              alignItems="flex-start"
              justify="center">
          <Grid item xs={4}
                style={{
                  textAlign: 'end'
                }}>
            <div className="imageDivTabletOdd">
              <img src={service.image}
                   alt={service.alt}
                   className="imageServiceTabletOdd"/>
            </div>
          </Grid>
          <Grid item xs={8}>
            <div style={{
              paddingBottom: '30px',
              marginRight: '-60px',
              background: `url(${blueMultiLines}) no-repeat right bottom`
            }}>
              <div style={{
                marginRight: '60px'
              }}>
                <div className="titleServiceTabletOdd">
                  <img src={service.icon}
                       alt={service.altIcon}
                       className="iconTitleServiceTabletOdd"/>
                  <div className="textTitleServiceTabletOdd">
                    {service['title_'+ lang]}
                  </div>
                </div>
                <div className="textServiceTabletOdd">
                  {service['text_'+ lang]}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ServicesOdd;