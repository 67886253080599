import React, { useState, useEffect } from 'react';
import '../../../App.css';
import './Latest-campaigns-bar.css'
import Grid from '@material-ui/core/Grid';
import {useTranslation} from "react-i18next";

const leftArrow = require('../../../images/left_arrow.png');
const rightArrow = require('../../../images/right_arrow.png');
const bluePoint = require('../../../images/player-graphics/round-blue.png');
const backgroundLastCampaigns = require('../../../images/player-graphics/last-campaigns-background.png');

const LatestCampaignsBar = ({lastCampaigns}) => {

  const [originalLastCampaigns, setOriginalLastCampaigns] = useState([]);
  const [lastCampaignsList, setLastCampaignsList] = useState([]);
  const [numPressList, setNumPressList] = useState({
    initial: 0,
    end: 4,
  });
  const [width, setWidth] = useState(0);
  const [numDependingWidth, setNumDependingWidth] = useState(5);

  const [lang, setLang] = useState('en');
  const [t, i18n] = useTranslation();

  useEffect(() => {
    setLang(i18n.languages[1]);
    console.log('player', i18n.languages[1]);
  }, [i18n.languages[1]]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let srch = lastCampaigns.filter((item) => item.id < numDependingWidth);
    setLastCampaignsList(() => [...srch]);
    setOriginalLastCampaigns(() => [...lastCampaigns]);
  }, [lastCampaigns]);

  useEffect(() => {
    let wdth = width;
    if(wdth === 0) {
      wdth = window.innerWidth;
      setWidth(wdth);
    }
    if(wdth < 960) {
      setNumDependingWidth(3);
      setNumPressList((prev) => {
        return({
          initial: prev.initial,
          end: prev.initial + 3,
        })
      });
    } else {
      setNumDependingWidth(4);
      setNumPressList((prev) => {
        return({
          initial: prev.initial,
          end: prev.initial + 4,
        })
      });
    }
  }, [width]);

  const handleResize = () => {
    const windowSize = window.innerWidth;
    setWidth(windowSize);
  };

  useEffect(() => {
    let srch = lastCampaigns.filter((item) => item.id < numDependingWidth);
    setLastCampaignsList(() => [...srch]);
    setOriginalLastCampaigns(() => [...lastCampaigns]);
  }, []);

  useEffect(() => {
    let srch = lastCampaigns.filter((item) => item.id > numPressList.initial && item.id < numPressList.end);
    setLastCampaignsList(() => [...srch]);
    setOriginalLastCampaigns(() => [...lastCampaigns]);
  }, [numPressList.initial, numPressList.end]);

  const goBackward = () => {
    let initial = numPressList.initial - 1;
    let end = numPressList.end - 1;

    if(initial < 0) {
      let init = 0;
      let ends = 1;
      setNumPressList((nums) => {
        nums.initial = init;
        nums.end = ends;
        return nums;
      });
      setLastCampaignsList(() => originalLastCampaigns.filter((item, index) => index + 1 > init && index + 1 < ends));
    } else {
      setNumPressList((nums) => {
        nums.initial = initial;
        nums.end = end;
        return nums;
      });
      setLastCampaignsList(() => originalLastCampaigns.filter((item, index) => index + 1 > initial && index + 1 < end));
    }
  };

  const goForward = () => {
    let initial = numPressList.initial + 1;
    let end = numPressList.end + 1;

    if(end > originalLastCampaigns.length+1) {
      let init = originalLastCampaigns.length;
      let ends = originalLastCampaigns.length + 1;

      setNumPressList((nums) => {
        nums.initial = init;
        nums.end = ends;
        return nums;
      });
      setLastCampaignsList(() => originalLastCampaigns.filter((item, index) => index + 1 > init && index + 1 < ends));
    } else {
      setNumPressList((nums) => {
        nums.initial = initial;
        nums.end = end;
        return nums;
      });
      setLastCampaignsList(() => originalLastCampaigns.filter((item, index) => index + 1 > initial && index + 1 < end));
    }
  };

  return(
    <div>
      <div className="divFullLastCampaigns" style={{
        maxHeight: '450px',
        // minHeight: '450px',
        maxWidth: '100%',
        width: '100%',
        minWidth: '100%',
        /* Full height */
        height: '450px',
        /* Center and scale the image nicely */
        background: `url(${backgroundLastCampaigns}) no-repeat center`,
        backgroundSize: 'cover'
      }}>
        <Grid container
              id="gridFullLastCampaigns"
              className="gridNoMargin"
              style={{
                height: '450px'
              }}
              spacing={0}
              direction="row"
              alignItems="center"
              justify="flex-start">
        {
          (lastCampaigns && numPressList.initial > 0) ?
          <Grid item className="shield" xs={1}
                onClick={() => goBackward()}>

                <div className="arrowsDiv" style={{
                  /* Full height */
                  width: '100%',
                  height: '100%',
                  margin: 'auto',
                  cursor: 'pointer',
                }}>
                  <img src={leftArrow}
                       alt="left-arrow"
                       className="arrowsImg"/>
                </div>
          </Grid> : <Grid item className="shield" xs={1}></Grid>
        }
          <Grid item xs={10}>
            <Grid container
                  className="gridNoMargin"
                  spacing={0}
                  direction="row"
                  alignItems="center"
                  justify="center">
              {
                (lastCampaignsList && lastCampaignsList[0]) ?
                  <Grid item xs={5} md={3}
                        className="latestCampaignGrid" >
                    {
                      (lastCampaignsList[0].url) ?
                        <a className="latestCampaignSquareLink"
                           href={lastCampaignsList[0].url} target="_blank"
                           rel="noopener noreferrer">
                          <img src={lastCampaignsList[0].image}
                               alt={lastCampaignsList[0].alt}
                               className="imageLatestCampaignSquare"/>
                        </a> :
                        <div className="latestCampaignSquare">
                          <img src={lastCampaignsList[0].image}
                               alt={lastCampaignsList[0].alt}
                               className="imageLatestCampaignSquare"/>
                        </div>
                    }
                  </Grid>: null
              }
              {
                (lastCampaignsList && lastCampaignsList[1]) ?
                  <Grid item className="shield" xs={1}>
                    <div style={{
                      /* Full height */
                      width: '100%',
                      height: '100%',
                      margin: 'auto',
                      /* Center and scale the image nicely */
                      background: `url(${bluePoint}) no-repeat center`,
                    }}>
                    </div>
                  </Grid>: null
              }
              {
                (lastCampaignsList && lastCampaignsList[1]) ?
                  <Grid item xs={5} md={3}
                        className="latestCampaignGrid">
                    {
                      (lastCampaignsList[1].url) ?
                        <a className="latestCampaignSquareLink"
                           href={lastCampaignsList[1].url} target="_blank"
                           rel="noopener noreferrer">
                          <img src={lastCampaignsList[1].image}
                               alt={lastCampaignsList[1].alt}
                               className="imageLatestCampaignSquare"/>
                        </a> :
                        <div className="latestCampaignSquare">
                          <img src={lastCampaignsList[1].image}
                               alt={lastCampaignsList[1].alt}
                               className="imageLatestCampaignSquare"/>
                        </div>
                    }
                  </Grid>: null
              }
              {
                (lastCampaignsList && lastCampaignsList[2]) ?
                  <Grid item className="shield" xs={1}>
                    <div style={{
                      /* Full height */
                      width: '100%',
                      height: '100%',
                      margin: 'auto',
                      /* Center and scale the image nicely */
                      background: `url(${bluePoint}) no-repeat center`,
                    }}>
                    </div>
                  </Grid>: null
              }
              {
                (lastCampaignsList && lastCampaignsList[2]) ?
                  <Grid item xs={3}
                        className="latestCampaignGrid" >
                    {
                      (lastCampaignsList[2].url) ?
                        <a className="latestCampaignSquareLink"
                           href={lastCampaignsList[2].url} target="_blank"
                           rel="noopener noreferrer">
                          <img src={lastCampaignsList[2].image}
                               alt={lastCampaignsList[2].alt}
                               className="imageLatestCampaignSquare"/>
                        </a> :
                        <div className="latestCampaignSquare">
                          <img src={lastCampaignsList[2].image}
                               alt={lastCampaignsList[2].alt}
                               className="imageLatestCampaignSquare"/>
                        </div>
                    }
                  </Grid>: null
              }
            </Grid>
          </Grid>
          {
            (lastCampaigns && numPressList.end <= lastCampaigns.length) ?
              <Grid item className="shield" xs={1}
                onClick={() => goForward()}>
                <div className="arrowsDiv" style={{
                      /* Full height */
                      width: '100%',
                      height: '100%',
                      margin: 'auto',
                      cursor: 'pointer',
                    }}>
                      <img src={rightArrow}
                           alt="right-arrow"
                           className="arrowsImg"/>
                    </div>
              </Grid> : <Grid item className="shield" xs={1}></Grid>
          }
        </Grid>
      </div>
    </div>
  )
};

export default LatestCampaignsBar;