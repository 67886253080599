import { useTranslation } from 'react-i18next';

export const carouselHomeImages = [{
  id: 1,
  url: require('../images/home/leila-ouahabi-fc-barcelona.jpg'),
  alt: 'leila-ouahabi-fc-barcelona'
}, {
  id: 2,
  url: require('../images/home/claudia-pina-seleccion-espanola.jpg'),
  alt: 'claudia-pina-seleccion-espanola'
}, {
  id: 3,
  url: require('../images/home/estefania-banini-seleccion-argentina.jpg'),
  alt: 'estefania-banini-seleccion-argentina'
}, {
  id: 4,
  url: require('../images/home/portera-misa-rodriguez-real-madrid-futbol-femenino.jpg'),
  alt: 'portera-misa-rodriguez-real-madrid-futbol-femenino'
}, {
  id: 5,
  url: require('../images/home/fabiana-simoes-selecao-brasileira.jpg'),
  alt: 'fabiana-simoes-selecao-brasileira'
}, {
  id: 6,
  url: require('../images/home/viola-calligaris-seleccion-suiza.jpg'),
  alt: 'viola-calligaris-seleccion-suiza'
}];
export const carouselHomeImagesMobile = [{
  id: 1,
  url: require('../images/home/mobile/leila-ouahabi-fc-barcelona.jpg'),
  alt: 'leila-ouahabi-fc-barcelona'
}, {
  id: 2,
  url: require('../images/home/mobile/claudia-pina-seleccion-espanola.jpg'),
  alt: 'claudia-pina-seleccion-espanola'
}, {
  id: 3,
  url: require('../images/home/mobile/estefania-banini-seleccion-argentina.jpg'),
  alt: 'estefania-banini-seleccion-argentina'
}, {
  id: 4,
  url: require('../images/home/mobile/misa-rodriguez-real-madrid-femenino.jpg'),
  alt: 'misa-rodriguez-real-madrid-futbol-femenino'
}, {
  id: 5,
  url: require('../images/home/mobile/fabiana-simoes-seleccion-brasilena.jpg'),
  alt: 'fabiana-simoes-seleccion-brasilena'
}, {
  id: 6,
  url: require('../images/home/mobile/viola-calligaris-seleccion-suiza.jpg'),
  alt: 'viola-calligaris-seleccion-suiza'
}];

export const playerSquaresHome1 = [{
  id: 1,
  url: require('../images/players-profile-photo/leila-ouahabi-fc-barcelona.png'),
  alt: 'leila-ouahabi-fc-barcelona',
  name: 'LEILA OUAHABI',
  nameUrl: 'leila-ouahabi',
  team: 'FC Barcelona'
}, {
  id: 2,
  url: require('../images/players-profile-photo/juila-simic-ac-milan.png'),
  alt: 'juila-simic-ac-milan',
  name: 'JULIA SIMIC',
  nameUrl: 'julia-simic',
  team: 'AC Milan'
}, {
  id: 3,
  url: require('../images/players-profile-photo/patri-guijarro-prensa-diario-as.png'),
  alt: 'patri-guijarro-prensa-diario-as',
  name: 'PATRI GUIJARRO',
  nameUrl: 'patri-guijarro',
  team: 'FC Barcelona'
}, {
  id: 4,
  url: require('../images/players-profile-photo/mariona-caldentey-fc-barcelona.png'),
  alt: 'mariona-caldentey-fc-barcelona',
  name: 'MARIONA CALDENTEY',
  nameUrl: 'mariona-caldentey',
  team: 'FC Barcelona'
},  {
  id: 5,
  url: require('../images/players-profile-photo/misa-rodriguez-real-madrid-fem.png'),
  alt: 'misa-rodriguez-real-madrid-femenino',
  name: 'MISA RODRÍGUEZ',
  nameUrl: 'misa-rodriguez',
  team: 'Real Madrid CF'
}];

export const playerSquaresHome2 = [{
  id: 1,
  url: require('../images/players-profile-photo/esther-gonzalez-levante-ud.png'),
  alt: 'esther-gonzalez-levante-ud',
  name: 'ESTHER GONZÁLEZ',
  nameUrl: 'esther-gonzalez',
  team: 'Levante UD'
}, {
  id: 2,
  url: require('../images/players-profile-photo/estefania-banini-levante-ud.png'),
  alt: 'estefania-banini-levante-ud',
  name: 'ESTEFANÍA BANINI',
  nameUrl: 'estefania-banini',
  team: 'Levante UD'
}, {
  id: 3,
  url: require('../images/players-profile-photo/claudia-pina-fc-barcelona.png'),
  alt: 'claudia-pina-fc-barcelona',
  name: 'CLAUDIA PINA',
  nameUrl: 'claudia-pina',
  team: 'Sevilla FC'
}, {
  id: 4,
  url: require('../images/players-profile-photo/viola-calligaris-valencia-cf.png'),
  alt: 'viola-calligaris-valencia-cf',
  name: 'VIOLA CALLIGARIS',
  nameUrl: 'viola-calligaris',
  team: 'Levante UD'
}, {
  id: 5,
  url: require('../images/players-profile-photo/lola-gallardo-olympique-lyon.png'),
  alt: 'lola-gallardo-olympique-lyon',
  name: 'LOLA GALLARDO',
  nameUrl: 'lola-gallardo',
  team: 'Olympique de Lyon'
}];

export const servicesRow = [{
  id: 1,
  url: require('../images/services/settings.png'),
  alt: 'settings',
  urlSelected: require('../images/services/settings_selected.png'),
  altSelected: 'settings_selected',
  serviceText1_es: 'GESTIÓN',
  serviceText2_es: 'DEPORTIVA',
  serviceText1_en: 'CAREER',
  serviceText2_en: 'MANAGEMENT',
  serviceText1_pt: 'GESTÃO DE',
  serviceText2_pt: 'CARREIRA',
  link: 'gestion-deportiva'
}, {
  id: 2,
  url: require('../images/services/peopleMoney.png'),
  alt: 'peopleMoney',
  urlSelected: require('../images/services/peopleMoney_selected.png'),
  altSelected: 'peopleMoney_selected',
  serviceText1_es: 'BÚSQUEDA',
  serviceText2_es: 'DE PATROCÍNIO',
  serviceText1_en: 'SPORTS',
  serviceText2_en: 'SPONSORSHIPS',
  serviceText1_pt: 'BUSCA DE',
  serviceText2_pt: 'PATROCÍNIO',
  link: 'busqueda-patrocinio'
}, {
  id: 3,
  url: require('../images/services/comunication.png'),
  alt: 'comunication',
  urlSelected: require('../images/services/comunication_selected.png'),
  altSelected: 'comunication_selected',
  serviceText1_es: 'OPORTUNIDADES',
  serviceText2_es: 'COMERCIALES',
  serviceText1_en: 'MARKETING',
  serviceText2_en: 'OPPORTUNITIES',
  serviceText1_pt: 'OPORTUNIDADES',
  serviceText2_pt: 'DE MARKETING',
  link: 'oportunidades-comerciales'
}, {
  id: 4,
  url: require('../images/services/tagPerson.png'),
  alt: 'tagPerson',
  urlSelected: require('../images/services/tagPerson_selected.png'),
  altSelected: 'tagPerson_selected',
  serviceText1_es: 'ESTRATEGIAS',
  serviceText2_es: 'ONLINE Y OFFLINE',
  serviceText1_en: 'ONLINE & OFFLINE',
  serviceText2_en: 'STRATEGIES',
  serviceText1_pt: 'ESTRATÉGIAS',
  serviceText2_pt: 'ON-LINE E OFF-LINE',
  link: 'estrategias-online-offline'
}, {
  id: 5,
  url: require('../images/services/bulb.png'),
  alt: 'bulb',
  urlSelected: require('../images/services/bulb_selected.png'),
  altSelected: 'bulb_selected',
  serviceText1_es: 'CREACIÓN',
  serviceText2_es: 'DE CONTENIDO',
  serviceText1_en: 'CONTENT',
  serviceText2_en: 'CREATION',
  serviceText1_pt: 'CRIAÇÃO DE',
  serviceText2_pt: 'CONTEÚDO',
  link: 'creacion-contenido'
}, {
  id: 5,
  url: require('../images/services/calendar.png'),
  alt: 'calendar',
  urlSelected: require('../images/services/calendar_selected.png'),
  altSelected: 'calendar_selected',
  serviceText1_es: 'ORGANIZACIÓN',
  serviceText2_es: 'DE CAMPUS',
  serviceText1_en: 'SPORTS',
  serviceText2_en: 'CAMPS',
  serviceText1_pt: 'ORGANIZAÇÃO',
  serviceText2_pt: 'DE CAMPUS',
  link: 'organizacion-campus'
}, {
  id: 6,
  url: require('../images/services/togheter.png'),
  alt: 'togheter',
  urlSelected: require('../images/services/togheter_selected.png'),
  altSelected: 'togheter_selected',
  serviceText1_es: 'COACHING',
  serviceText2_es: 'Y MENTORING',
  serviceText1_en: 'COACHING',
  serviceText2_en: '& MENTORING',
  serviceText1_pt: 'COACHING',
  serviceText2_pt: 'E MENTORING',
  link: 'coaching-mentoring'
}, {
  id: 7,
  url: require('../images/services/contracts.png'),
  alt: 'contracts',
  urlSelected: require('../images/services/contracts_selected.png'),
  altSelected: 'contracts_selected',
  serviceText1_es: 'ASESORAMIENTO',
  serviceText2_es: 'LEGAL Y FINANCIERO',
  serviceText1Small_es: 'ASES. LEGAL',
  serviceText2Small_es: 'Y FINANCIERO',
  serviceText1_en: 'LEGAL & FINANCIAL',
  serviceText2_en: 'ADVISORY',
  serviceText1Small_en: 'LEGAL & FINANCIAL',
  serviceText2Small_en: 'ADVISORY',
  serviceText1_pt: 'ASSESSORAMENTO',
  serviceText2_pt: 'JURÍDICO E FINANCEIRO',
  serviceText1Small_pt: 'ASSES. JURÍDICO',
  serviceText2Small_pt: 'E FINANCEIRO',
  link: 'asesoramiento-legal-financiero'
}, {
  id: 8,
  url: require('../images/services/people.png'),
  alt: 'people',
  urlSelected: require('../images/services/people_selected.png'),
  altSelected: 'people_selected',
  serviceText1_es: 'SERVICIOS',
  serviceText2_es: 'POST-CARRERA',
  serviceText1_en: 'POST-CAREER',
  serviceText2_en: 'SERVICES',
  serviceText1_pt: 'SERVIÇOS',
  serviceText2_pt: 'PÓS-CARREIRA',
  link: 'servicios-post-carrera'
}];

export const servicesFirstRow = [{
  id: 1,
  url: require('../images/services/settings.png'),
  alt: 'settings',
  urlSelected: require('../images/services/settings_selected.png'),
  altSelected: 'settings_selected',
  serviceText1_es: 'GESTIÓN',
  serviceText2_es: 'DEPORTIVA',
  serviceText1_en: 'CAREER',
  serviceText2_en: 'MANAGEMENT',
  serviceText1_pt: 'GESTÃO DE',
  serviceText2_pt: 'CARREIRA',
  link: 'gestion-deportiva'
}, {
  id: 2,
  url: require('../images/services/peopleMoney.png'),
  alt: 'peopleMoney',
  urlSelected: require('../images/services/peopleMoney_selected.png'),
  altSelected: 'peopleMoney_selected',
  serviceText1_es: 'BÚSQUEDA',
  serviceText2_es: 'DE PATROCÍNIO',
  serviceText1_en: 'SPORTS',
  serviceText2_en: 'SPONSORSHIPS',
  serviceText1_pt: 'BUSCA DE',
  serviceText2_pt: 'PATROCÍNIO',
  link: 'busqueda-patrocinio'
}, {
  id: 3,
  url: require('../images/services/comunication.png'),
  alt: 'comunication',
  urlSelected: require('../images/services/comunication_selected.png'),
  altSelected: 'comunication_selected',
  serviceText1_es: 'OPORTUNIDADES',
  serviceText2_es: 'COMERCIALES',
  serviceText1_en: 'MARKETING',
  serviceText2_en: 'OPPORTUNITIES',
  serviceText1_pt: 'OPORTUNIDADES',
  serviceText2_pt: 'DE MARKETING',
  link: 'oportunidades-comerciales'
}, {
  id: 4,
  url: require('../images/services/tagPerson.png'),
  alt: 'tagPerson',
  urlSelected: require('../images/services/tagPerson_selected.png'),
  altSelected: 'tagPerson_selected',
  serviceText1_es: 'ESTRATEGIAS',
  serviceText2_es: 'ONLINE Y OFFLINE',
  serviceText1_en: 'ONLINE & OFFLINE',
  serviceText2_en: 'STRATEGIES',
  serviceText1_pt: 'ESTRATÉGIAS',
  serviceText2_pt: 'ON-LINE E OFF-LINE',
  link: 'estrategias-online-offline'
}, {
  id: 5,
  url: require('../images/services/bulb.png'),
  alt: 'bulb',
  urlSelected: require('../images/services/bulb_selected.png'),
  altSelected: 'bulb_selected',
  serviceText1_es: 'CREACIÓN',
  serviceText2_es: 'DE CONTENIDO',
  serviceText1_en: 'CONTENT',
  serviceText2_en: 'CREATION',
  serviceText1_pt: 'CRIAÇÃO DE',
  serviceText2_pt: 'CONTEÚDO',
  link: 'creacion-contenido'
}];
export const servicesSecondRow = [{
  id: 1,
  url: require('../images/services/calendar.png'),
  alt: '',
  urlSelected: require('../images/services/calendar_selected.png'),
  altSelected: '',
  serviceText1_es: 'ORGANIZACIÓN',
  serviceText2_es: 'DE CAMPUS',
  serviceText1_en: 'SPORTS',
  serviceText2_en: 'CAMPS',
  serviceText1_pt: 'ORGANIZAÇÃO',
  serviceText2_pt: 'DE CAMPUS',
  link: 'organizacion-campus'
}, {
  id: 2,
  url: require('../images/services/togheter.png'),
  alt: 'togheter',
  urlSelected: require('../images/services/togheter_selected.png'),
  altSelected: 'togheter_selected',
  serviceText1_es: 'COACHING',
  serviceText2_es: 'Y MENTORING',
  serviceText1_en: 'COACHING',
  serviceText2_en: '& MENTORING',
  serviceText1_pt: 'COACHING',
  serviceText2_pt: 'E MENTORING',
  link: 'coaching-mentoring'
}, {
  id: 3,
  url: require('../images/services/contracts.png'),
  alt: 'contracts',
  urlSelected: require('../images/services/contracts_selected.png'),
  altSelected: 'contracts_selected',
  serviceText1_es: 'ASESORAMIENTO',
  serviceText2_es: 'LEGAL Y FINANCIERO',
  serviceText1_en: 'LEGAL & FINANCIAL',
  serviceText2_en: 'ADVISORY',
  serviceText1_pt: 'ASSESSORAMENTO',
  serviceText2_pt: 'JURÍDICO E FINANCEIRO',
  serviceText1Small_pt: 'ASSES. JURÍDICO',
  serviceText2Small_pt: 'E FINANCEIRO',
  link: 'asesoramiento-legal-financiero'
}, {
  id: 4,
  url: require('../images/services/people.png'),
  alt: 'people',
  urlSelected: require('../images/services/people_selected.png'),
  altSelected: 'people_selected',
  serviceText1_es: 'SERVICIOS',
  serviceText2_es: 'POST-CARRERA',
  serviceText1_en: 'POST-CAREER',
  serviceText2_en: 'SERVICES',
  serviceText1_pt: 'SERVIÇOS',
  serviceText2_pt: 'PÓS-CARREIRA',
  link: 'servicios-post-carrera'
}];
export const clubsImages = [{
  id: 1,
  url: require('../images/clubs/fc-barcelona-futbol-femenino.png'),
  alt: 'fc-barcelona-futbol-femenino',
  club: 'Barça'
},{
  id: 2,
  url: require('../images/clubs/real-madrid-fc-futbol-femenino.png'),
  alt: 'real-madrid-fc-futbol-femenino',
  club: 'Madrid'
},{
  id: 3,
  url: require('../images/clubs/ac-milan-futbol-femenino.png'),
  alt: 'ac-milan-futbol-femenino',
  club: 'Milan'
},{
  id: 4,
  url: require('../images/clubs/juventus-fc-futbol-femenino.png'),
  alt: 'juventus-fc-futbol-femenino',
  club: 'Juventus'
},{
  id: 5,
  url: require('../images/clubs/olympique-de-lyon-futbol-femenino.png'),
  alt: 'olympique-de-lyon-futbol-femenino',
  club: 'Lyon'
},{
  id: 6,
  url: require('../images/clubs/club-atletico-de-madrid-futbol-femenino.png'),
  alt: 'club-atletico-de-madrid-futbol-femenino',
  club: 'Atletico'
},{
  id: 7,
  url: require('../images/clubs/as-roma-futbol-femenino.png'),
  alt: 'as-roma-futbol-femenino',
  club: 'Roma'
},{
  id: 8,
  url: require('../images/clubs/sevilla-cf-futbol-femenino.png'),
  alt: 'sevilla-cf-futbol-femenino',
  club: 'Sevilla'
}, /*{
  id: 7,
  url: require('../images/clubs/athletic-club-bilbao-futbol-femenino.png'),
  alt: 'athletic-club-bilbao-futbol-femenino',
  club: 'Athletic'
},*/{
  id: 9,
  url: require('../images/clubs/sport-lisboa-e-benfica-futbol-femenino.png'),
  alt: 'sport-lisboa-e-benfica-futbol-femenino',
  club: 'Benfica'
},{
  id: 10,
  url: require('../images/clubs/sporting-clube-de-portugal-futbol-femenino.png'),
  alt: 'sporting-clube-de-portugal-futbol-femenino',
  club: 'Sporting Clube'
},{
  id: 11,
  url: require('../images/clubs/levante-ud-futbol-femenino.png'),
  alt: 'levante-ud-futbol-femenino',
  club: 'Levante'
},{
  id: 12,
  url: require('../images/clubs/valencia-cf-futbol-femenino.png'),
  alt: 'valencia-cf-futbol-femenino',
  club: 'Valencia'
},{
  id: 13,
  url: require('../images/clubs/tottenham-hotspur-futbol-femenino.png'),
  alt: 'tottenham-hotspur-futbol-femenino',
  club: 'Tottenham'
},{
  id: 14,
  url: require('../images/clubs/real-sociedad-futbol-femenino.png'),
  alt: 'real-sociedad-futbol-femenino',
  club: 'Sociedad'
},{
  id: 15,
  url: require('../images/clubs/real-betis-balompie-futbol-femenino.png'),
  alt: 'real-betis-balompie-futbol-femenino',
  club: 'Betis'
},{
  id: 16,
  url: require('../images/clubs/rcd-espanyol-futbol-femenino.png'),
  alt: 'rcd-espanyol-futbol-femenino',
  club: 'Espanyol'
}/*,{
  id: 15,
  url: require('../images/clubs/olympique-de-marselle-futbol-femenino.png'),
  alt: 'olympique-de-marselle-futbol-femenino',
  club: 'Marselle'
},{
  id: 16,
  url: require('../images/clubs/cr-flamengo-futbol-femenino.png'),
  alt: 'cr-flamengo-futbol-femenino',
  club: 'Flamengo'
},{
  id: 17,
  url: require('../images/clubs/deportivo-de-la-coruna-futbol-femenino.png'),
  alt: 'deportivo-de-la-coruna-futbol-femenino',
  club: 'Deportivo'
},{
  id: 19,
  url: require('../images/clubs/madrid-cff-futbol-femenino.png'),
  alt: 'madrid-cff-futbol-femenino',
  club: 'Madrid CFF'
},{
  id: 20,
  url: require('../images/clubs/tigres-uanl-mexico-futbol-femenino.png'),
  alt: 'tigres-uanl-mexico-futbol-femenino',
  club: 'Tigres'
},{
  id: 21,
  url: require('../images/clubs/sc-internacional-porto-alegre-futbol-femenino.png'),
  alt: 'sc-internacional-porto-alegre-futbol-femenino',
  club: 'Internacional'
},{
  id: 22,
  url: require('../images/clubs/shangai-shenhua-china-futbol-femenino.png'),
  alt: 'shangai-shenhua-china-futbol-femenino',
  club: 'Shanghai'
},{
  id: 23,
  url: require('../images/clubs/sd-eibar-futbol-femenino.png'),
  alt: 'sd-eibar-futbol-femenino',
  club: 'Eibar'
},{
  id: 24,
  url: require('../images/clubs/red-star-belgrade-futbol-femenino.png'),
  alt: 'red-star-belgrade-futbol-femenino',
  club: 'Belgrade'
},{
  id: 25,
  url: require('../images/clubs/znk-split-futbol-femenino.png'),
  alt: 'znk-split-futbol-femenino',
  club: 'Split'
}*/];
export const sponsorshipsImages = [{
  id: 1,
  url: require('../images/sponsorships/patrocinio-nike-futbol-femenino.png'),
  alt: 'patrocinio-nike-futbol-femenino',
  club: 'Nike'
},{
  id: 2,
  url: require('../images/sponsorships/patrocinio-new-balance-futbol-femenino.png'),
  alt: 'patrocinio-new-balance-futbol-femenino',
  club: 'New Balance'
},{
  id: 3,
  url: require('../images/sponsorships/patrocinio-puma-futbol-femenino.png'),
  alt: 'patrocinio-puma-futbol-femenino',
  club: 'Puma'
},{
  id: 4,
  url: require('../images/sponsorships/patrocinio-adidas-futbol-femenino.png'),
  alt: 'patrocinio-adidas-futbol-femenino',
  club: 'Adidas'
},{
  id: 5,
  url: require('../images/sponsorships/patrocinio-joma-futbol-femenino.png'),
  alt: 'patrocinio-joma-futbol-femenino',
  club: 'Joma'
},{
  id: 6,
  url: require('../images/sponsorships/patrocinio-umbro-futbol-femenino.png'),
  alt: 'patrocinio-umbro-futbol-femenino',
  club: 'Umbro'
},{
  id: 7,
  url: require('../images/sponsorships/patrocinio-solo-porteros-futbol-femenino.png'),
  alt: 'patrocinio-solo-porteros-futbol-femenino',
  club: 'SP'
},{
  id: 8,
  url: require('../images/sponsorships/patrocinio-rg-goalkeeper-gloves-futbol-femenino.png'),
  alt: 'patrocinio-rg-goalkeeper-gloves-futbol-femenino',
  club: 'RG'
},{
  id: 9,
  url: require('../images/sponsorships/patrocinio-rinat-futbol-femenino.png'),
  alt: 'patrocinio-rinat-futbol-femenino',
  club: 'Rinat'
},{
  id: 10,
  url: require('../images/sponsorships/sprinter-futbol-femenino.png'),
  alt: 'patrocinio-sprinter-futbol-femenino',
  club: 'Sprinter'
},{
  id: 11,
  url: require('../images/sponsorships/patrocinio-futbolmania-futbol-femenino.png'),
  alt: 'patrocinio-futbolmania-futbol-femenino',
  club: 'Futmolmania'
},{
  id: 12,
  url: require('../images/sponsorships/patrocinio-penalti-futbol-femenino.png'),
  alt: 'patrocinio-penalti-futbol-femenino',
  club: 'Punto Pentali'
},{
  id: 13,
  url: require('../images/sponsorships/patrocinio-sak-project-futbol-femenino.png'),
  alt: 'patrocinio-sak-project-futbol-femenino',
  club: 'Sak Project'
},{
  id: 14,
  url: require('../images/sponsorships/patrocinio-shock-absorber-futbol-femenino.png'),
  alt: 'patrocinio-shock-absorber-futbol-femenino',
  club: 'Shock Absorber'
},{
  id: 15,
  url: require('../images/sponsorships/trusox-futbol-femenino.png'),
  alt: 'patrocinio-trusox-futbol-femenino',
  club: 'Trusox'
},{
  id: 16,
  url: require('../images/sponsorships/patrocinio-reebok-futbol-femenino.png'),
  alt: 'patrocinio-reebok-futbol-femenino',
  club: 'Reebok'
},{
  id: 17,
  url: require('../images/sponsorships/uhlsport-futbol-femenino.png'),
  alt: 'patrocinio-uhlsport-futbol-femenino',
  club: 'Uhlsport'
},{
  id: 18,
  url: require('../images/sponsorships/jako-futbol-femenino.png'),
  alt: 'patrocinio-jako-futbol-femenino',
  club: 'Jako'
}/*,{
  id: 8,
  url: require('../images/sponsorships/patrocinio-ho-soccer-futbol-femenino.png'),
  alt: 'patrocinio-ho-soccer-futbol-femenino',
  club: 'Ho Soccer'
},{
  id: 11,
  url: require('../images/sponsorships/patrocinio-wilson-futbol-femenino.png'),
  alt: 'patrocinio-wilson-futbol-femenino',
  club: 'Wilson'
},{
  id: 17,
  url: require('../images/sponsorships/patrocinio-innovasport-futbol-femenino.png'),
  alt: 'patrocinio-innovasport-futbol-femenino',
  club: 'Innovasport'
},{
  id: 5,
  url: require('../images/sponsorships/patrocinio-redbull-futbol-femenino.png'),
  alt: 'patrocinio-redbull-futbol-femenino',
  club: 'Red Bull'
}*/];
export const collaborationImages = [{
  id: 1,
  url: require('../images/collaboration/marca-google-futbol-femenino.png'),
  alt: 'marca-google-futbol-femenino',
  club: 'Google'
},{
  id: 2,
  url: require('../images/collaboration/marca-pepsi-futbol-femenino.png'),
  alt: 'marca-pepsi-futbol-femenino',
  club: 'Pepsi'
},{
  id: 3,
  url: require('../images/collaboration/marca-visa-futbol-femenino.png'),
  alt: 'marca-visa-futbol-femenino',
  club: 'Visa'
},{
  id: 4,
  url: require('../images/collaboration/marca-audi-futbol-femenino.png'),
  alt: 'marca-audi-futbol-femenino',
  club: 'Audi'
},{
  id: 5,
  url: require('../images/collaboration/marca-guarana-antarctica-futbol-femenino.png'),
  alt: 'marca-guarana-antarctica-futbol-femenino',
  club: 'Guaraná Antarctica'
},{
  id: 6,
  url: require('../images/collaboration/marca-quilmes-futbol-femenino.png'),
  alt: 'marca-quilmes-futbol-femenino',
  club: 'Quilmes'
},{
  id: 7,
  url: require('../images/collaboration/marca-super-bock-futbol-femenino.png'),
  alt: 'marca-super-bock-futbol-femenino',
  club: 'Super Bock'
},{
  id: 8,
  url: require('../images/collaboration/marca-sprinter-futbol-femenino.png'),
  alt: 'marca-sprinter-futbol-femenino',
  club: 'Sprinter'
},{
  id: 9,
  url: require('../images/collaboration/marca-miro-futbol-femenino.png'),
  alt: 'marca-miro-futbol-femenino',
  club: 'Miró'
},{
  id: 10,
  url: require('../images/collaboration/marca-balr-futbol-femenino.png'),
  alt: 'marca-balr-futbol-femenino',
  club: 'Balr'
},{
  id: 11,
  url: require('../images/collaboration/marca-recovery-pump-futbol-femenino.png'),
  alt: 'marca-recovery-pump-futbol-femenino',
  club: 'Recovery Pump'
},{
  id: 12,
  url: require('../images/collaboration/marca-aire-ancient-baths-futbol-femenino.png'),
  alt: 'marca-aire-ancient-baths-futbol-femenino',
  club: 'Aire Ancient Baths'
},{
  id: 13,
  url: require('../images/collaboration/marca-foodspring-futbol-femenino.png'),
  alt: 'marca-foodspring-futbol-femenino',
  club: 'Foodspring'
},{
  id: 14,
  url: require('../images/collaboration/marca-movo-futbol-femenino.png'),
  alt: 'marca-movo-futbol-femenino',
  club: 'Movo'
},{
  id: 15,
  url: require('../images/collaboration/marca-filmin-futbol-femenino.png'),
  alt: 'marca-filmin-futbol-femenino',
  club: 'Filmin'
},{
  id: 16,
  url: require('../images/collaboration/marca-primor-futbol-femenino.png'),
  alt: 'marca-primor-futbol-femenino',
  club: 'Primor'
},{
  id: 17,
  url: require('../images/collaboration/marca-prozis-futbol-femenino.png'),
  alt: 'marca-prozis-futbol-femenino',
  club: 'Prozis'
},{
  id: 18,
  url: require('../images/collaboration/marca-herbalife-futbol-femenino.png'),
  alt: 'marca-herbalife-futbol-femenino',
  club: 'Herbalife'
},{
  id: 19,
  url: require('../images/collaboration/marca-wefan-futbol-femenino.png'),
  alt: 'marca-wefan-futbol-femenino',
  club: 'WeFan'
},{
  id: 20,
  url: require('../images/collaboration/marca-scalpers-futbol-femenino.png'),
  alt: 'marca-scalpers-futbol-femenino',
  club: 'Scalpers'
},{
  id: 21,
  url: require('../images/collaboration/marca-san-benedetto-futbol-femenino.png'),
  alt: 'marca-san-benedetto-futbol-femenino',
  club: 'San Benedetto'
},{
  id: 22,
  url: require('../images/collaboration/marca-magari-sound-futbol-femenino.png'),
  alt: 'marca-magari-sound-futbol-femenino',
  club: 'Magari Sound'
}];
