import React, { useState, useEffect } from 'react';
import '../../App.css';
import './Player.css'
import Carousel from 'react-material-ui-carousel'
import { carouselHomeImages } from "../../data/home";
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useParams, useRouteMatch, useLocation, useHistory } from "react-router-dom";
import TrajectoryBar from "./trajectory-bar/Trajectory-bar";
import LatestCampaignsBar from "./latest-campaigns-bar/Latest-campaigns-bar";
import Footer from "../footer/Footer";
import { allPlayers } from '../../data/players';
import PlayerSquareGrid from "../player-square-grid/Player-square-grid";
import PressSquare from "./press-square/Press-square";
import PalmaresInfo from "./palmares-info/Palmares-info";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPage } from '../../store/actions/selectedPage'
import DocumentMeta from 'react-document-meta';
import axios from 'axios';
import bigInt from "big-integer";
import {useTranslation} from "react-i18next";

const blueRayas = require('../../images/blueRayas.png');
const arrowBlue = require('../../images/player-graphics/small-arrow-blue.png');
const leftArrow = require('../../images/left_arrow.png');
const rightArrow = require('../../images/right_arrow.png');
const whiteLines = require('../../images/player-graphics/white-lines.png');
const multipleTriangle = require('../../images/player-graphics/multiple-triangles-background.png');
const instagramLogo = require('../../images/player-graphics/instagram-grey.png');
const twitterLogo = require('../../images/player-graphics/twitter-grey.png');
const facebookLogo = require('../../images/player-graphics/facebook-grey.png');
const trianglesBackground = require('../../images/player-graphics/background-triangles.png');
const threeBlueLines = require('../../images/home/three-blue-lines.png');

const instagramIconSmall = require('../../images/rrssIcons/instagram_icon.png');
const twitterIconSmall = require('../../images/rrssIcons/twitter_icon.png');
const facebookIconSmall = require('../../images/rrssIcons/facebook_icon.png');

const instagramLogoBlue = require('../../images/player-graphics/instagram-blue.png');
const instagramLabel = require('../../images/player-graphics/instagram-label.png');
const blueLines = require('../../images/player-graphics/blue-lines.png');

const campo = require('../../images/campo_profile.png');
const greyPoint = require('../../images/greyPoint.png');
const bluePoint = require('../../images/bluePoint.png');

const segundaDelanteraLabel = require('../../images/positions-labels/2ª Delantera_Mesa de trabajo 1.png');
const centralDerLabel = require('../../images/positions-labels/Central Der_Mesa de trabajo 1.png');
const centralIzqLabel = require('../../images/positions-labels/Central Izq_Mesa de trabajo 1.png');
const delanteraLabel = require('../../images/positions-labels/Delantera_Mesa de trabajo 1.png');
const extremoDerLabel = require('../../images/positions-labels/Extremo Der_Mesa de trabajo 1.png');
const extremoIzqLabel = require('../../images/positions-labels/Extremo Izq_Mesa de trabajo 1.png');
const interiorDerLabel = require('../../images/positions-labels/Interior Der_Mesa de trabajo 1.png');
const interiorIzqLabel = require('../../images/positions-labels/Interior izq_Mesa de trabajo 1.png');
const lateralDerLabel = require('../../images/positions-labels/Lateral Der_Mesa de trabajo 1.png');
const lateralIzqLabel = require('../../images/positions-labels/Lateral izq_Mesa de trabajo 1.png');
const liberoLabel = require('../../images/positions-labels/Líbero_Mesa de trabajo 1.png');
const mcOfensivaLabel = require('../../images/positions-labels/MC Ofensiva_Mesa de trabajo 1.png');
const mcLabel = require('../../images/positions-labels/MC_Mesa de trabajo 1.png');
const mediaPuntaLabel = require('../../images/positions-labels/Media punta_Mesa de trabajo 1.png');
const porteraLabel = require('../../images/positions-labels/Portera_Mesa de trabajo 1.png');

const segundaDelanteraLabel_en = require('../../images/positions-labels/en/Forward_Mesa de trabajo 1.png');
const centralDerLabel_en = require('../../images/positions-labels/en/RCB_Mesa de trabajo 1.png');
const centralIzqLabel_en = require('../../images/positions-labels/en/LCB_Mesa de trabajo 1.png');
const delanteraLabel_en = require('../../images/positions-labels/en/Striker_Mesa de trabajo 1.png');
const extremoDerLabel_en = require('../../images/positions-labels/en/Right Wing_Mesa de trabajo 1.png');
const extremoIzqLabel_en = require('../../images/positions-labels/en/Left Wing_Mesa de trabajo 1.png');
const interiorDerLabel_en = require('../../images/positions-labels/en/RM_Mesa de trabajo 1.png');
const interiorIzqLabel_en = require('../../images/positions-labels/en/LM_Mesa de trabajo 1.png');
const lateralDerLabel_en = require('../../images/positions-labels/en/Right Back_Mesa de trabajo 1.png');
const lateralIzqLabel_en = require('../../images/positions-labels/en/Left Back_Mesa de trabajo 1.png');
const liberoLabel_en = require('../../images/positions-labels/en/Center Back_Mesa de trabajo 1.png');
const mcOfensivaLabel_en = require('../../images/positions-labels/en/Midfielder_Mesa de trabajo 1.png');
const mcLabel_en = require('../../images/positions-labels/en/Sweeper_Mesa de trabajo 1.png');
const mediaPuntaLabel_en = require('../../images/positions-labels/en/CAM_Mesa de trabajo 1.png');
const porteraLabel_en = require('../../images/positions-labels/en/Goalkeeper_Mesa de trabajo 1.png');

const segundaDelanteraLabel_pt = require('../../images/positions-labels/pt/Ponta.png');
const centralDerLabel_pt = require('../../images/positions-labels/pt/Zagueira-Dir.png');
const centralIzqLabel_pt = require('../../images/positions-labels/pt/Zagueira-Esq.png');
const delanteraLabel_pt = require('../../images/positions-labels/pt/Atacante.png');
const extremoDerLabel_pt = require('../../images/positions-labels/pt/Ala-dir.png');
const extremoIzqLabel_pt = require('../../images/positions-labels/pt/Ala-esq.png');
const interiorDerLabel_pt = require('../../images/positions-labels/pt/Meia-dir.png');
const interiorIzqLabel_pt = require('../../images/positions-labels/pt/Meia-Esq.png');
const lateralDerLabel_pt = require('../../images/positions-labels/pt/Lateral-dir.png');
const lateralIzqLabel_pt = require('../../images/positions-labels/pt/Lateral-esq.png');
const liberoLabel_pt = require('../../images/positions-labels/pt/Libero.png');
const mcOfensivaLabel_pt = require('../../images/positions-labels/pt/MC-Ofensiva.png');
const mcLabel_pt = require('../../images/positions-labels/pt/MC.png');
const mediaPuntaLabel_pt = require('../../images/positions-labels/pt/Centroavante.png');
const porteraLabel_pt = require('../../images/positions-labels/pt/Goleira.png');

const characteristics = {
  1: {
    icon: require('../../images/characteristics-icons/1-Ambidiestra.png'),
    en: require('../../images/characteristics-icons/en/1_Ambidiestra.png'),
    es: require('../../images/characteristics-icons/es/1-Ambidiestra.png'),
    pt: require('../../images/characteristics-icons/pt/1_Ambidiestra.png')
  },
  2: {
    icon: require('../../images/characteristics-icons/2-Asistencias.png'),
    en: require('../../images/characteristics-icons/en/2_Asistencias.png'),
    es: require('../../images/characteristics-icons/es/2-Asistencias.png'),
    pt: require('../../images/characteristics-icons/pt/2_Asistencias.png')
  },
  3: {
    icon: require('../../images/characteristics-icons/3-Asociacion.png'),
    en: require('../../images/characteristics-icons/en/3_Asociacion.png'),
    es: require('../../images/characteristics-icons/es/3-Asociacion.png'),
    pt: require('../../images/characteristics-icons/pt/3_Asociacion.png')
  },
  4: {
    icon: require('../../images/characteristics-icons/4-Blocaje.png'),
    en: require('../../images/characteristics-icons/en/4_Blocaje.png'),
    es: require('../../images/characteristics-icons/es/4-Blocaje.png'),
    pt: require('../../images/characteristics-icons/pt/4_Blocaje.png')
  },
  5: {
    icon: require('../../images/characteristics-icons/5-Caracter.png'),
    en: require('../../images/characteristics-icons/en/5_Caracter.png'),
    es: require('../../images/characteristics-icons/es/5-Caracter.png'),
    pt: require('../../images/characteristics-icons/pt/5_Caracter.png')
  },
  6: {
    icon: require('../../images/characteristics-icons/6-Colocacion.png'),
    en: require('../../images/characteristics-icons/en/6_Colocacion.png'),
    es: require('../../images/characteristics-icons/es/6-Colocacion.png'),
    pt: require('../../images/characteristics-icons/pt/6_Colocacion.png')
  },
  7: {
    icon: require('../../images/characteristics-icons/7-Comunicacion.png'),
    en: require('../../images/characteristics-icons/en/7_Comunicacion.png'),
    es: require('../../images/characteristics-icons/es/7-Comunicacion.png'),
    pt: require('../../images/characteristics-icons/pt/7_Comunicacion.png')
  },
  8: {
    icon: require('../../images/characteristics-icons/8-Conducion.png'),
    en: require('../../images/characteristics-icons/en/8_Conduccion.png'),
    es: require('../../images/characteristics-icons/es/8-Conducion.png'),
    pt: require('../../images/characteristics-icons/pt/8_Conduccion.png')
  },
  9: {
    icon: require('../../images/characteristics-icons/9-Control-del-balon.png'),
    en: require('../../images/characteristics-icons/en/9_Control.png'),
    es: require('../../images/characteristics-icons/es/9-Control-de-balon.png'),
    pt: require('../../images/characteristics-icons/pt/9_Control-del-balon.png')
  },
  10: {
    icon: require('../../images/characteristics-icons/10-Criterio-defensivo.png'),
    en: require('../../images/characteristics-icons/en/10_CriterioDefensivo.png'),
    es: require('../../images/characteristics-icons/es/10-Criterio-defensivo.png'),
    pt: require('../../images/characteristics-icons/pt/10_Criterio-defensivo.png')
  },
  11: {
    icon: require('../../images/characteristics-icons/11-Criterio-ofensivo.png'),
    en: require('../../images/characteristics-icons/en/11_CriterioOfensivo.png'),
    es: require('../../images/characteristics-icons/es/11-Criterio-ofensivo.png'),
    pt: require('../../images/characteristics-icons/pt/11_Criterio-ofensivo.png')
  },
  12: {
    icon: require('../../images/characteristics-icons/12-Despeje.png'),
    en: require('../../images/characteristics-icons/en/12_Despeje.png'),
    es: require('../../images/characteristics-icons/es/12-Despeje.png'),
    pt: require('../../images/characteristics-icons/pt/12_Despeje.png')
  },
  13: {
    icon: require('../../images/characteristics-icons/13-Diestra.png'),
    en: require('../../images/characteristics-icons/en/13_Diestra.png'),
    es: require('../../images/characteristics-icons/es/13-Diestra.png'),
    pt: require('../../images/characteristics-icons/pt/13-Diestra.png')
  },
  14: {
    icon: require('../../images/characteristics-icons/14-Dominio-balon-con-los-pies.png'),
    en: require('../../images/characteristics-icons/en/14_DominioPies.png'),
    es: require('../../images/characteristics-icons/es/14-Dominio-balon-con-los-pies.png'),
    pt: require('../../images/characteristics-icons/pt/14_Dominio-balon-con-los-pies.png')
  },
  15: {
    icon: require('../../images/characteristics-icons/15-Agilidad-flexibilidad.png'),
    en: require('../../images/characteristics-icons/en/15_Flexibilidad.png'),
    es: require('../../images/characteristics-icons/es/15-Agilidad-flexibilidad.png'),
    pt: require('../../images/characteristics-icons/pt/15-Agilidad-flexibilidad.png')
  },
  16: {
    icon: require('../../images/characteristics-icons/16-Fuera-de-juego.png'),
    en: require('../../images/characteristics-icons/en/16_FueraDeJuego.png'),
    es: require('../../images/characteristics-icons/es/16-Fuera-de-juego.png'),
    pt: require('../../images/characteristics-icons/pt/16_Fuera-de-juego.png')
  },
  17: {
    icon: require('../../images/characteristics-icons/17-Fuerza.png'),
    en: require('../../images/characteristics-icons/en/17_Fuerza.png'),
    es: require('../../images/characteristics-icons/es/17-Fuerza.png'),
    pt: require('../../images/characteristics-icons/pt/17_Fuerza.png')
  },
  18: {
    icon: require('../../images/characteristics-icons/18-Gol.png'),
    en: require('../../images/characteristics-icons/en/18_Gol.png'),
    es: require('../../images/characteristics-icons/es/18-Gol.png'),
    pt: require('../../images/characteristics-icons/pt/18_Gol.png')
  },
  19: {
    icon: require('../../images/characteristics-icons/19-Juego-aereo.png'),
    en: require('../../images/characteristics-icons/en/19_JuegoAereo.png'),
    es: require('../../images/characteristics-icons/es/19-Juego-aereo.png'),
    pt: require('../../images/characteristics-icons/pt/19_Juego-aereo.png')
  },
  20: {
    icon: require('../../images/characteristics-icons/20-Lanzamiento-de-faltas.png'),
    en: require('../../images/characteristics-icons/en/20_LanzamientoFaltas.png'),
    es: require('../../images/characteristics-icons/es/20-Lanzamiento-de-faltas.png'),
    pt: require('../../images/characteristics-icons/pt/20_Lanzamiento-de-faltas.png')
  },
  21: {
    icon: require('../../images/characteristics-icons/21-Lanzamiento-de-penaltis.png'),
    en: require('../../images/characteristics-icons/en/21_LanzamientoPenaltis.png'),
    es: require('../../images/characteristics-icons/es/21-Lanzamiento-de-penaltis.png'),
    pt: require('../../images/characteristics-icons/pt/21_Lanzamiento-de-penaltis.png')
  },
  22: {
    icon: require('../../images/characteristics-icons/22-Lectura-de-partido.png'),
    en: require('../../images/characteristics-icons/en/22_LecturaPartido.png'),
    es: require('../../images/characteristics-icons/es/22-Lectura-de-partido.png'),
    pt: require('../../images/characteristics-icons/pt/22_Lectura-de-partido.png')
  },
  23: {
    icon: require('../../images/characteristics-icons/23-Lider.png'),
    en: require('../../images/characteristics-icons/en/23_Lider.png'),
    es: require('../../images/characteristics-icons/es/23-Lider.png'),
    pt: require('../../images/characteristics-icons/pt/23_Lider.png')
  },
  24: {
    icon: require('../../images/characteristics-icons/24-Pase-corto.png'),
    en: require('../../images/characteristics-icons/en/24_PaseCorto.png'),
    es: require('../../images/characteristics-icons/es/24-Pase-corto.png'),
    pt: require('../../images/characteristics-icons/pt/24_Pase-corto.png')
  },
  25: {
    icon: require('../../images/characteristics-icons/25-Pase-largo.png'),
    en: require('../../images/characteristics-icons/en/25_PaseLargo.png'),
    es: require('../../images/characteristics-icons/es/25-Pase-largo.png'),
    pt: require('../../images/characteristics-icons/pt/25_Pase-largo.png')
  },
  26: {
    icon: require('../../images/characteristics-icons/26-Pase-medio.png'),
    en: require('../../images/characteristics-icons/en/26_PaseMedio.png'),
    es: require('../../images/characteristics-icons/es/26-Pase-medio.png'),
    pt: require('../../images/characteristics-icons/pt/26_Pase-medio.png')
  },
  27: {
    icon: require('../../images/characteristics-icons/27-Potencia.png'),
    en: require('../../images/characteristics-icons/en/27_Potencia.png'),
    es: require('../../images/characteristics-icons/es/27-Potencia.png'),
    pt: require('../../images/characteristics-icons/pt/27_Potencia.png')
  },
  28: {
    icon: require('../../images/characteristics-icons/28-Racional.png'),
    en: require('../../images/characteristics-icons/en/28_Racional.png'),
    es: require('../../images/characteristics-icons/es/28-Racional.png'),
    pt: require('../../images/characteristics-icons/pt/28_Racional.png')
  },
  29: {
    icon: require('../../images/characteristics-icons/29-Reflejos.png'),
    en: require('../../images/characteristics-icons/en/29_Reflejos.png'),
    es: require('../../images/characteristics-icons/es/29-Reflejos.png'),
    pt: require('../../images/characteristics-icons/pt/29_Reflejos.png')
  },
  30: {
    icon: require('../../images/characteristics-icons/30-Regate.png'),
    en: require('../../images/characteristics-icons/en/30_Regate.png'),
    es: require('../../images/characteristics-icons/es/30-Regate.png'),
    pt: require('../../images/characteristics-icons/pt/30_Regate.png')
  },
  31: {
    icon: require('../../images/characteristics-icons/31-Resistencia.png'),
    en: require('../../images/characteristics-icons/en/31_Resistencia.png'),
    es: require('../../images/characteristics-icons/es/31-Resistencia.png'),
    pt: require('../../images/characteristics-icons/pt/31_Resistencia.png')
  },
  32: {
    icon: require('../../images/characteristics-icons/32-Salida-de-balon.png'),
    en: require('../../images/characteristics-icons/en/32_SalidaBalon.png'),
    es: require('../../images/characteristics-icons/es/32-Salida-de-balon.png'),
    pt: require('../../images/characteristics-icons/pt/32_Salida-de-balon.png')
  },
  33: {
    icon: require('../../images/characteristics-icons/33-Velocidad.png'),
    en: require('../../images/characteristics-icons/en/33_Velocidad.png'),
    es: require('../../images/characteristics-icons/es/33-Velocidad.png'),
    pt: require('../../images/characteristics-icons/pt/33_Velocidad.png')
  },
  34: {
    icon: require('../../images/characteristics-icons/34-Vision-de-juego.png'),
    en: require('../../images/characteristics-icons/en/34_VisionJuego.png'),
    es: require('../../images/characteristics-icons/es/34-Vision-de-juego.png'),
    pt: require('../../images/characteristics-icons/pt/34_Vision-de-juego.png')
  },
  35: {
    icon: require('../../images/characteristics-icons/35-Zurda.png'),
    en: require('../../images/characteristics-icons/en/35_Zurda.png'),
    es: require('../../images/characteristics-icons/es/35-Zurda.png'),
    pt: require('../../images/characteristics-icons/pt/35_Zurda.png')
  }
};

const Player = (props) => {
  const dispatch = useDispatch();
  const selectedPage = useSelector(state => state.selectedPage.selectedPage);

  const [player, setPlayer] = useState({});
  const [players, setPlayers] = useState([]);
  const [instagramPhotos, setInstagramPhotos] = useState([]);
  const [mainPositionLowerCase, setMainPositionLowerCase] = useState('');
  const [showSmallHonors, setShowSmallHonors] = useState(0);
  const [unikTeamPlayers, setUnikTeamPlayers] = useState([]);
  const [numImagesUnikTeam, setNumImagesUnikTeam] = useState({
    initial: 0,
    end: 6,
  });
  const [carrouselImages, setCarrouselImages] = useState({
    first: true,
    second: false,
    third: false
  });
  const [initialImage, setInitialImage] = useState(0);
  const [meta, setMeta] = useState({});
  const [charged, setCharged] = useState(false);

  let location = useLocation();

  const history = useHistory();

  const [width, setWidth] = useState(0);
  const [numDependingWidth, setNumDependingWidth] = useState(5);

  const [lang, setLang] = useState('en');
  const [t, i18n] = useTranslation();

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    setLang(i18n.languages[1]);
  }, [i18n.languages[1]]);

  useEffect(() => {
    let wdth = width;
    if(wdth === 0) {
      wdth = window.innerWidth;
      setWidth(wdth);
    }
    if(wdth <= 960 && wdth >= 600) {
      setNumDependingWidth(4);
    } else if(wdth < 600) {
      setNumDependingWidth(3);
    } else {
      setNumDependingWidth(5);
    }
  }, [width]);

  const handleResize = () => {
    const windowSize = window.innerWidth;
    setWidth(windowSize);
  };

  useEffect(() => {
    startFunction();
  }, []);

  useEffect(() => {
    setCharged(false);
    startFunction();
  }, [player]);

  const startFunction = async () => {
    // let pathName = window.location.href;
    // let nameUrl = pathName.split('/')[5];
    let pathName = window.location.pathname;
    let nameUrl = pathName.split('/')[2];

    let plyr = allPlayers.find((item) => item.nameUrl === nameUrl);
    if(plyr){
      setMeta({
        title: plyr.title,
        description: plyr.metadescription,
        meta: {
          charset: 'utf-8',
          name: {
            keywords: 'react,meta,document,html,tags'
          }
        }
      });
      let instaPhotos = [];
      let instaInfo = {};

      try{
       instaInfo = await instagramInfo(plyr);
      } catch (e) {
        console.log(e);
      }

      if(instaInfo) {
        if(instaInfo.edge_followed_by && instaInfo.edge_followed_by.count) {
          plyr.instagramFollowers = instaInfo.edge_followed_by.count;
        }

        if(instaInfo.edge_owner_to_timeline_media && instaInfo.edge_owner_to_timeline_media.edges) {
          if(instaInfo.edge_owner_to_timeline_media.edges.length >= 6) {
            let lastInstaPosts = instaInfo.edge_owner_to_timeline_media.edges.slice(0, 6);

            lastInstaPosts.forEach((post, i, posts) => {
              let postToShow = {};

              postToShow.media_url = post.node.display_url;
              postToShow.url = `https://www.instagram.com/p/${post.node.shortcode}`;
              instaPhotos.push(postToShow);

              if(lastInstaPosts.length === i + 1) {
                setInstagramPhotos(instaPhotos);
              }
            });
          } else if(instaInfo.edge_owner_to_timeline_media.edges.length < 6) {
            let lastInstaPosts = instaInfo.edge_owner_to_timeline_media.edges.slice(0);

            lastInstaPosts.forEach((post, i, posts) => {
              let postToShow = {};

              postToShow.media_url = post.node.display_url;
              postToShow.url = `https://www.instagram.com/p/${post.node.shortcode}/`;
              instaPhotos.push(postToShow);

              if(lastInstaPosts.length === i + 1) {
                setInstagramPhotos(instaPhotos);
              }
            });
          }
        }

      } else {
        instaPhotos = await instagramImages(plyr);

        if(instaPhotos) {
          instaPhotos.forEach((photo, i, photos) => {
            /*let url_prefix = "https://www.instagram.com/p/";
            const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';

            let userId = plyr.instagramId;
            let instagramId = photo.id;
            let url_suffix;

            while(instagramId > 0) {
              let remainder = instagramId % 64;
              instagramId = (instagramId - remainder) / 64;

              url_suffix = alphabet[remainder] + url_suffix;

              if(instagramId <= 0) {
                photos[i].url = url_prefix + url_suffix;
              }
            }
            if(instaPhotos.length === i + 1) {
              setInstagramPhotos(instaPhotos);
            }

            let alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-_';
            let shortenedId = '';
            let media_id = photo.id;

            while (media_id > 0) {
              let remainder = bigInt(media_id).mod(64);
              media_id = bigInt(media_id).minus(remainder).divide(64).toString();
              shortenedId = alphabet.charAt(remainder) + shortenedId;

            }*/

            let id = bigInt(photo.id);
            const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmopqrstuvwxyz0123456789-_";
            let shortcode = '';

            while (id.greater(0)) {
              let division = id.divmod(64);
              id = division.quotient;
              shortcode = `${alphabet.charAt(division.remainder)}${shortcode}`;
            }
            // photos[i].url = 'https://www.instagram.com/p/' + shortcode + '/';
            photos[i].url = `https://www.instagram.com/${plyr.instagramUsername}`;

            if(instaPhotos.length === i + 1) {
              setInstagramPhotos(instaPhotos);
            }
          })
        }
      }
      setPlayer(plyr);
      instagramImages(plyr);
      setCharged(true);
    }

    let sortedArray = await sortArray([...allPlayers], plyr);
    let srch = sortedArray.filter((item, index) => index < 5);

    setUnikTeamPlayers(() => [...srch]);
    setPlayers(() => [...sortedArray]);

    let position = plyr['mainPosition_' + lang].toLowerCase();
    setMainPositionLowerCase(position.charAt(0).toUpperCase() + position.slice(1));
    window.scrollTo(0, 0)
  };

  const instagramImages = (player) => {
    return new Promise((resolve, reject) => {
      if(player.instagramAccessToken) {
        axios.get(`https://graph.instagram.com/me/media?fields=id,media_type,media_url,username,timestamp&access_token=${player.instagramAccessToken}`)
            .then(async res => {
              if(res.data.data) {
                if(res.data.data.length >= 6) {
                  // setInstagramPhotos(res.data.data.slice(0, 6));
                  resolve(res.data.data.slice(0, 6));
                } else if(res.data.data.length < 6) {
                  // setInstagramPhotos(res.data.data.slice(0));
                  resolve(res.data.data.slice(0));
                }
              }

            });
      } else {
        resolve(null);
      }
    });
  };

  const instagramInfo = (player) => {
    return new Promise((resolve, reject) => {
      if(player.instagramUsername) {
        axios.get(`https://www.instagram.com/${player.instagramUsername}/?__a=1`)
            .then(async res => {
              if(res.data && res.data.graphql && res.data.graphql.user) {
                resolve(res.data.graphql.user);
              } else {
                reject(null);

              }
            }).catch(function (error) {
              reject(null);

        });
      } else {
        reject(null);
      }
    });
  };

  const kFormatter = (num) => {
    return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(1)) + 'k' : Math.sign(num)*Math.abs(num)
  }

  const sortArray = (array, player) => {
    return array.filter((item) => item['mainPosition_' + lang] === player['mainPosition_' + lang] && item.id !== player.id);
  };

  const goBackwardTeam = () => {
    let initial = numImagesUnikTeam.initial - 1;
    let end = numImagesUnikTeam.end - 1;
    let array = [...players, ...players];

    if(initial < 0) {
      let newInitial = numImagesUnikTeam.initial + players.length - 1;
      let newFinal = numImagesUnikTeam.end + players.length - 1;

      setPlayers(() => [...array]);
      setNumImagesUnikTeam((nums) => {
        nums.initial = newInitial;
        nums.end = newFinal;
        return nums;
      });
      setUnikTeamPlayers(() => array.filter((item, index) => index + 1 > newInitial && index + 1 < newFinal));
    } else {
      setNumImagesUnikTeam((nums) => {
        nums.initial = initial;
        nums.end = end;
        return nums;
      });
      setUnikTeamPlayers(() => array.filter((item, index) => index + 1 > initial && index + 1 < end));
    }
  };

  const goForwardTeam = () => {
    let initial = numImagesUnikTeam.initial + 1;
    let end = numImagesUnikTeam.end + 1;
    let array = [...players, ...players];
    setNumImagesUnikTeam((nums) => {
      nums.initial = initial;
      nums.end = end;
      return nums;
    });
    if(numImagesUnikTeam.end > players.length) {
      setPlayers(() => [...array]);
      setUnikTeamPlayers(() => array.filter((item, index) => index + 1 > initial && index + 1 < end));
    } else {
      setUnikTeamPlayers(() => array.filter((item, index) => index + 1 > initial && index + 1 < end));
    }
  };

  const goTo = (id) => {
    let offsetTop  = document.getElementById(id).offsetTop;
    window.scrollTo({
      top: offsetTop,
      behavior: "smooth"
    });
  };

  const nextImageFunction = (next, active) => {
    if(active === 0) {
      setCarrouselImages({
        first: false,
        second: true,
        third: false
      });
    } else if(active === 1) {
      setCarrouselImages({
        first: false,
        second: false,
        third: true
      });
    } else if(active === 2) {
      setCarrouselImages({
        first: true,
        second: false,
        third: false
      });
    }
  };

  return(
    <DocumentMeta {...meta}>
      {
        (charged) ?
          <div className="playerDiv">
            {
              (width > 700) ?
                  <Carousel indicators={false}
                            autoPlay={true}
                            next={nextImageFunction}
                            startAt={initialImage}
                            timeout={200}
                            interval={4000}>
                    {
                      (player && player.headerPhotos) ?
                          player.headerPhotos.map( (item, index) => {
                            return <div
                                className="backgroundCover"
                                style={{
                                  textAlign: 'center',
                                  maxHeight: '98vh',
                                  minHeight: '100vh',
                                  maxWidth: '100%',
                                  minWidth: '100%',
                                  margin: '0 !important',
                                  fontFamily: 'Montserrat',
                                  /* Full height */
                                  height: '100%',
                                  /* Center and scale the image nicely */
                                  background: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(${item.url}) no-repeat center`,
                                  title: item.alt
                                }}
                                key={index}>
                              <div>
                                <div className="namePlayer">
                                  {player.name}
                                </div>
                              </div>
                              <div className="secondRowHeader">
                                {
                                  (player && player.team) ?
                                      player.team.toUpperCase() : null
                                }
                                {
                                  (player['selection_' + lang] && player['selection_' + lang] !== '') ?
                                      <div className="barraAnd">&nbsp;-&nbsp;</div> : null

                                }
                                {
                                  player['selection_' + lang]
                                }
                              </div>
                              <div className="thirdRowHeader">
                                  {player['mainPosition_' + lang]}
                              </div>
                              {/*<div className="buttonsCarrousel">*/}
                              {/*<div className="buttonsCarrouselInline"*/}
                              {/*onClick={() => {*/}
                              {/*setInitialImage(0);*/}
                              {/*}}>*/}
                              {/*{*/}
                              {/*(carrouselImages.first) ?*/}
                              {/*<img className="imageButtonCarrousel"*/}
                              {/*src={bluePoint}/> :*/}
                              {/*<img className="imageButtonCarrousel"*/}
                              {/*src={greyPoint}/>*/}
                              {/*}*/}
                              {/*</div>*/}
                              {/*<div className="buttonsCarrouselInline"*/}
                              {/*onClick={() => {*/}
                              {/*setInitialImage(1);*/}
                              {/*}}>*/}
                              {/*{*/}
                              {/*(carrouselImages.second) ?*/}
                              {/*<img className="imageButtonCarrousel"*/}
                              {/*src={bluePoint}/> :*/}
                              {/*<img className="imageButtonCarrousel"*/}
                              {/*src={greyPoint}/>*/}
                              {/*}*/}
                              {/*</div>*/}
                              {/*<div className="buttonsCarrouselInline"*/}
                              {/*onClick={() => {*/}
                              {/*setInitialImage(2);*/}
                              {/*}}>*/}
                              {/*{*/}
                              {/*(carrouselImages.third) ?*/}
                              {/*<img className="imageButtonCarrousel"*/}
                              {/*src={bluePoint}/> :*/}
                              {/*<img className="imageButtonCarrousel"*/}
                              {/*src={greyPoint}/>*/}
                              {/*}*/}
                              {/*</div>*/}
                              {/*</div>*/}
                            </div>
                          }) : null
                    }
                  </Carousel>
                  : <div
                      className="backgroundCover"
                      style={{
                        textAlign: 'center',
                        maxHeight: '98vh',
                        minHeight: '100vh',
                        maxWidth: '100%',
                        minWidth: '100%',
                        margin: '0 !important',
                        fontFamily: 'Montserrat',
                        /* Full height */
                        height: '100%',
                        /* Center and scale the image nicely */
                        background: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(${player.mobileHeaderPhoto}) no-repeat center`,
                        title: player.mobileHeaderPhotoAlt
                      }}>
                    <div>
                      <div className="namePlayer">
                        {player.name}
                      </div>
                    </div>
                    <div className="secondRowHeader">
                      {
                        (player && player.team) ?
                            player.team.toUpperCase() : null
                      }
                      {
                        (player['selection_' + lang] && player['selection_' + lang] !== '') ?
                            <div className="barraAnd">&nbsp;-&nbsp;</div> : null

                      }
                      {
                        player['selection_' + lang]
                      }
                    </div>
                    <div className="thirdRowHeader">
                        {player['mainPosition_' + lang]}
                    </div>
                  </div>
            }

            <div>
              <Grid container
                    className="gridFilters"
                    spacing={0}
                    wrap={"nowrap"}
                    direction="row"
                    alignItems="center"
                    justify="center">
                <Grid item xs={2} className="columnInfo"
                      onClick={() => goTo('perfil')}>
                  <div>{t('PROFILE')}</div>
                </Grid>
                <Grid item xs={2} className="columnInfo"
                      onClick={() => goTo('palmares')}>
                  <div>{t('ACHIEVEMENTS')}</div>
                </Grid>
                <Grid item xs={2} className="columnInfo"
                      onClick={() => goTo('informeTecnico')}>
                  <div>{t('TECHNICAL REPORT')}</div>
                </Grid>
                <Grid item xs={2} className="columnInfo"
                      onClick={() => goTo('trayectoria')}>
                  <div>{t('CAREER')}</div>
                </Grid>
                <Grid item xs={2} className="columnInfo"
                      onClick={() => goTo('ultimasCampañas')}>
                  <div>{t('LAST CAMPAIGNS')}</div>
                </Grid>
                <Grid item xs={2} className="columnInfo"
                      onClick={() => goTo('prensa')}>
                  <div>{t('PRESS')}</div>
                </Grid>
              </Grid>

              <div className="gridFiltersSmallDiv">
                <Grid container
                      className="gridFiltersSmall"
                      spacing={0}
                      wrap={"nowrap"}
                      direction="row"
                      alignItems="center"
                      justify="center">
                  <Grid item xs={4} className="columnInfo"
                        onClick={() => goTo('perfil')}>
                    <div>{t('PROFILE')}</div>
                  </Grid>
                  <Grid item xs={4} className="columnInfo"
                        onClick={() => goTo('palmares')}>
                    <div>{t('ACHIEVEMENTS')}</div>
                  </Grid>
                  <Grid item xs={4} className="columnInfo"
                        onClick={() => goTo('informeTecnico')}>
                    <div>{t('TECHNICAL REPORT')}</div>
                  </Grid>
                </Grid>
                <div className="blueRowProfile"></div>
                <Grid container
                      className="gridFiltersSmall"
                      spacing={0}
                      wrap={"nowrap"}
                      direction="row"
                      alignItems="center"
                      justify="center">
                  <Grid item xs={4} className="columnInfo"
                        onClick={() => goTo('trayectoria')}>
                    <div>{t('CAREER')}</div>
                  </Grid>
                  <Grid item xs={4} className="columnInfo"
                        onClick={() => goTo('ultimasCampañas')}>
                    <div>{t('LAST CAMPAIGNS')}</div>
                  </Grid>
                  <Grid item xs={4} className="columnInfo"
                        onClick={() => goTo('prensa')}>
                    <div>{t('PRESS')}</div>
                  </Grid>
                </Grid>
                <div className="blueRowProfile"></div>
              </div>

            </div>
            <div className="profileRow"
                 id="perfil">
              <div className="profileLabel">
                <div className="profileLabelText">
                    {t('PROFILE')}
                </div>
                <div className="smallIconsProfile">
                  {
                    (player.instagram && player.instagram !== "") ?

                        <a href={player.instagram}
                           target="_blank"
                           rel="noopener noreferrer"
                           className="rrssImageLinkPlayer">
                          <img className="iconRrssImageLink"
                               src={instagramIconSmall}
                               alt="instagram-logo"/>
                        </a>
                        : null
                  }
                  {
                    (player.facebook && player.facebook !== "") ?

                        <a href={player.facebook}
                           target="_blank"
                           rel="noopener noreferrer"
                           className="rrssImageLinkPlayer">
                          <img className="iconRrssImageLink"
                               src={facebookIconSmall}
                               alt="facebook-logo"/>
                        </a>
                        : null
                  }
                  {
                    (player.twitter && player.twitter !== "") ?

                        <a href={player.twitter}
                           target="_blank"
                           rel="noopener noreferrer"
                           className="rrssImageLinkPlayer">
                          <img className="iconRrssImageLink"
                               src={twitterIconSmall}
                               alt="twitter-logo"/>
                        </a>
                        : null
                  }
                </div>
              </div>
              <div className="divGridProfile"
                   style={{
                     /* Center and scale the image nicely */
                     background: `url(${whiteLines}) 10% 10%`,
                     backgroundRepeat: 'space no-repeat',
                     backgroundAttachment: 'absolute'
                   }}>
                <Grid container
                      className="gridProfile"
                      spacing={0}
                      wrap={"nowrap"}
                      direction="row"
                      alignItems="flex-end"
                      justify="center">
                  <Grid item xs={6} sm={5}
                        className="profileBasicInfo">
                    <div className="rectangleBasicInfoNoMargin">
                        {t('DATE OF BIRTH')} <b className="boldRectangleInfo">&nbsp;&nbsp;{player.birthDate}</b>
                    </div>
                    <div className="rectangleBasicInfo">
                        {t('CITY')} <b className="boldRectangleInfo">&nbsp;{player.town}</b>
                    </div>
                    <div className="rectangleBasicInfo">
                        {t('NATIONALITY')} <b className="boldRectangleInfo">&nbsp;{player['nationality_'+lang]}</b>
                    </div>
                    <div className="rectangleBasicInfo">
                        {t('HEIGHT')} <b className="boldRectangleInfo">&nbsp;{player.height} m</b>
                    </div>
                    <div className="rectangleBasicInfo">
                        {t('WEIGHT')} <b className="boldRectangleInfo">&nbsp;{player.weight} kg</b>
                    </div>
                  </Grid>
                  <Grid item xs={1}
                        className="socialNetworkLogos">
                    {
                      (player.instagram && player.instagram !== "") ?
                          <a href={player.instagram}
                             target="_blank"
                             rel="noopener noreferrer"
                             style={{
                               marginBottom: '20px',
                               zIndex: 10
                             }}>
                            <img src={instagramLogo}
                                 alt="instagram-logo"
                                 className="logoSocialNetwork"/>
                          </a>
                          : null
                    }
                    {
                      (player.facebook && player.facebook !== "") ?
                          <a href={player.facebook}
                             target="_blank"
                             rel="noopener noreferrer"
                             style={{
                               marginBottom: '20px',
                               zIndex: 10
                             }}>
                            <img src={facebookLogo}
                                 alt="facebook-logo"
                                 className="logoSocialNetwork"/>
                          </a>
                          : null
                    }
                    {
                      (player.twitter && player.twitter !== "") ?
                          <a href={player.twitter}
                             target="_blank"
                             rel="noopener noreferrer"
                             style={{
                               marginBottom: '20px',
                               zIndex: 10
                             }}>
                            <img src={twitterLogo}
                                 alt="twitter-logo"
                                 className="logoSocialNetwork"
                            />
                          </a>
                          : null
                    }
                    {/*{
              (player.tikTok && player.tikTok !== "") ?
                <a href={player.tikTok}
                   target="_blank"
                   rel="noopener noreferrer"
                   style={{
                     marginBottom: '20px',
                     zIndex: 10
                   }}>
                  <img src={twitterLogo}
                       className="logoSocialNetwork"
                  />
                </a>
                : null
            }*/}
                  </Grid>
                  <Grid item xs={6} sm={7} md={6}>
                    <div className="signaturePhotoDiv">
                      <img className="signaturePhoto"
                           src={player.signaturePhoto}
                           alt={player.signaturePhotoAlt}/>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="honorsRow" id="palmares">
              {
                (player && player.nationalTournament && player.nationalTournament.length === 0 &&
                    player && player.internationalTournament && player.internationalTournament.length === 0 &&
                    player && player.individualAwards && player.individualAwards.length === 0) ? null :
                    <div style={{
                      position: 'relative',
                      marginLeft: 'auto',
                      height: '100%',
                      width: '100%',
                      maxWidth: '100%',
                      /* Full height */
                      /* Center and scale the image nicely */
                      background: `url(${multipleTriangle}) no-repeat`,
                      backgroundSize: '100%',
                      zIndex: 0
                    }}>
                      <div className="honorsLabel">
                          {t('ACHIEVEMENTS')}
                      </div>
                      <Grid container
                            className="gridProfileHonors"
                            spacing={0}
                            direction="row"
                            alignItems="flex-start"
                            justify="center">
                        {
                          (player && player.nationalTournament && player.nationalTournament.length > 0) ?
                              <Grid item xs={4}
                                    className="gridTournament">
                                <div className="honorsTitle">
                                    {t('NATIONAL TOURNAMENTS')}
                                </div>
                                <PalmaresInfo info={player.nationalTournament}/>
                              </Grid> : null
                        }
                        {
                          (player && player.internationalTournament && player.internationalTournament.length > 0) ?
                              <Grid item xs={4}
                                    className="gridTournament">
                                <div className="honorsTitle">
                                    {t('INTERNATIONAL TOURNAMENTS')}
                                </div>
                                <PalmaresInfo info={player.internationalTournament}/>
                              </Grid> : null
                        }
                        {
                          (player && player.individualAwards && player.individualAwards.length > 0) ?
                              <Grid item xs={4}
                                    className="gridTournament">
                                <div className="honorsTitle">
                                    {t('INDIVIDUAL AWARDS')}
                                </div>
                                <PalmaresInfo info={player.individualAwards}/>
                              </Grid> : null
                        }
                      </Grid>
                      {
                        (player && player.nationalTournament && player.nationalTournament.length > 0) ?
                            <Grid container
                                  className="gridProfileHonorsSmall"
                                  spacing={0}
                                  direction="row"
                                  alignItems="flex-start"
                                  justify="center">
                              <Grid item xs={10} sm={8}
                                    className="gridTournamentSmall">
                                <div className="honorsTitleSmall"
                                     onClick={() => {
                                       (showSmallHonors === 1) ?
                                           setShowSmallHonors(0) :
                                           setShowSmallHonors(1)
                                     }}>
                                    {t('NATIONAL TOURNAMENTS')}
                                </div>
                                {
                                  (showSmallHonors === 1) ?
                                      <PalmaresInfo info={player.nationalTournament}/>
                                      : null
                                }
                              </Grid>
                            </Grid> : null
                      }
                      {
                        (player && player.internationalTournament && player.internationalTournament.length > 0) ?
                            <Grid container
                                  className="gridProfileHonorsSmall"
                                  spacing={0}
                                  direction="row"
                                  alignItems="flex-start"
                                  justify="center">
                              <Grid item xs={10} sm={8}
                                    className="gridTournamentSmall">
                                <div className="honorsTitleSmall"
                                     onClick={() => {
                                       (showSmallHonors === 2) ?
                                           setShowSmallHonors(0) :
                                           setShowSmallHonors(2)
                                     }}>
                                    {t('INTERNATIONAL TOURNAMENTS')}
                                </div>
                                {
                                  (showSmallHonors === 2) ?
                                      <PalmaresInfo info={player.internationalTournament}/>
                                      : null
                                }
                              </Grid>
                            </Grid> : null
                      }
                      {
                        (player && player.individualAwards && player.individualAwards.length > 0) ?
                            <Grid container
                                  className="gridProfileHonorsSmall"
                                  spacing={0}
                                  direction="row"
                                  alignItems="flex-start"
                                  justify="center">
                              <Grid item xs={10} sm={8}
                                    className="gridTournamentSmall">
                                <div className="honorsTitleSmall"
                                     onClick={() => {
                                       (showSmallHonors === 3) ?
                                           setShowSmallHonors(0) :
                                           setShowSmallHonors(3)
                                     }}>
                                    {t('INDIVIDUAL AWARDS')}
                                </div>
                                {
                                  (showSmallHonors === 3) ?
                                      <PalmaresInfo info={player.individualAwards}/>
                                      : null
                                }
                              </Grid>
                            </Grid> : null
                      }
                    </div>
              }
            </div>
            <div className="technicalRow"
                 id="informeTecnico">
              <div className="technicalLabel">
                  {t('TECHNICAL REPORT')}
              </div>
              <Grid container
                    className="gridProfileTechnical"
                    style={{
                      position: 'relative',
                      background: `url(${trianglesBackground}) no-repeat right top`,
                      backgroundSize: '20%'
                    }}
                    spacing={8}
                    direction="row"
                    alignItems="flex-start"
                    justify="center">
                <Grid item xs={12} md={7}
                      className="gridCharacteristics">
                  <div className="inlineCol">
                    <div className="arrowBlue">
                      <img src={arrowBlue}
                           alt="arrow-blue"/>
                    </div>
                    <div className="technicalTitle">
                        {t('CHARACTERISTICS')}
                    </div>
                  </div>
                  <Grid container
                        className="characteristicRows"
                        style={{
                          marginTop: '3%'
                        }}
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justify="flex-start">
                    <Grid item xs={4}
                          className="characteristicTitleGrid">
                      <div className="characteristicTitle">
                          {t('DOMINANT LEG')}
                      </div>
                    </Grid>
                    <Grid item xs={2}
                          className="iconsCharacteristics">
                      {
                        (player && player.skilledLegNum) ?
                            <div className="skilledLegDiv">
                              <img className='skilledLeg'
                                   src={characteristics[player.skilledLegNum].icon}
                                   alt='skilled-leg-icon'/>
                              <img className='skilledLegHover'
                                   src={characteristics[player.skilledLegNum][lang]}
                                   alt='skilled-leg-icon-description'/>
                            </div>
                            : null
                      }
                    </Grid>
                  </Grid>
                  <Grid container
                        style={{
                          marginTop: '3%'
                        }}
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justify="flex-start">
                    <Grid item xs={4}
                          className="characteristicTitleGrid">
                      <div className="characteristicTitle">
                          {t('TECHNICAL')}
                      </div>
                    </Grid>
                    {
                      (player && player.technicalCharacteristics) ?
                          player.technicalCharacteristics.map((item, index) => {
                            return(<div key={index}
                                        className="characteristicIconDiv">
                              <img className={'characteristic'+index}
                                   src={characteristics[item.num].icon}
                                   alt='characteristic-icon'/>
                              <img className={'characteristic'+index+'Hover'}
                                   src={characteristics[item.num][lang]}
                                   alt='characteristic-icon-description'/>
                            </div>)
                          }) : null
                    }
                  </Grid>
                  <Grid container
                        style={{
                          marginTop: '3%'
                        }}
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justify="flex-start">
                    <Grid item xs={4}
                          className="characteristicTitleGrid">
                      <div className="characteristicTitle">
                          {t('TACTICAL')}
                      </div>
                    </Grid>
                    {
                      (player && player.tacticalCharacteristics) ?
                          player.tacticalCharacteristics.map((item, index) => {
                            return(<div key={index}
                                        className="characteristicIconDiv">
                              <img className={'characteristic'+index}
                                   src={characteristics[item.num].icon}
                                   alt='characteristic-icon'/>
                              <img className={'characteristic'+index+'Hover'}
                                   src={characteristics[item.num][lang]}
                                   alt='characteristic-icon-description'/>
                            </div>)
                          }) : null
                    }
                  </Grid>
                  <Grid container
                        style={{
                          marginTop: '3%'
                        }}
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justify="flex-start">
                    <Grid item xs={4}
                          className="characteristicTitleGrid">
                      <div className="characteristicTitle">
                          {t('PSYCHOLOGICAL')}
                      </div>
                    </Grid>
                    {
                      (player && player.psychologicalCharacteristics) ?
                          player.psychologicalCharacteristics.map((item, index) => {
                            return(<div key={index}
                                        className="characteristicIconDiv">
                              <img className={'characteristic'+index}
                                   src={characteristics[item.num].icon}
                                   alt='characteristic-icon'/>
                              <img className={'characteristic'+index+'Hover'}
                                   src={characteristics[item.num][lang]}
                                   alt='characteristic-icon-description'/>
                            </div>)
                          }) : null
                    }
                  </Grid>
                  <Grid container
                        style={{
                          marginTop: '3%'
                        }}
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justify="flex-start">
                    <Grid item xs={4}
                          className="characteristicTitleGrid">
                      <div className="characteristicTitle">
                          {t('PHYSICAL')}
                      </div>
                    </Grid>
                    {
                      (player && player.physicalCharacteristics) ?
                          player.physicalCharacteristics.map((item, index) => {
                            return(<div key={index}
                                        className="characteristicIconDiv">
                              <img className={'characteristic'+index}
                                   src={characteristics[item.num].icon}
                                   alt='characteristic-icon'/>
                              <img className={'characteristic'+index+'Hover'}
                                   src={characteristics[item.num][lang]}
                                   alt='characteristic-icon-description'/>
                            </div>)
                          }) : null
                    }
                  </Grid>
                </Grid>
                <Grid item xs={12} md={5}
                      className="gridCharacteristics">
                  <div className="inlineCol">
                    <div className="arrowBlue">
                      <img src={arrowBlue}
                           alt="arrow-blue"/>
                    </div>
                    <div className="technicalTitle">
                        {t('Position')} I
                    </div>
                    <div className="technicalTitlePosition">
                      &nbsp;{mainPositionLowerCase}
                    </div>
                  </div>

                  <div className="divCampoPosicion" style={{
                    height: `${671*0.8}px`,
                    marginTop: '27px',
                    position: 'relative'
                  }}>
                    <img className="divCampoPosicionImg" src={campo}
                         alt="campo-futbol"
                         style={{
                           width: `${451*0.8}px`,
                           height: `${671*0.8}px`,
                           marginTop: '27px',
                           position: 'absolute'
                         }}/>

                    {
                      (lang === 'es') ?
                          <div className="positionsRoundsWeb">
                            {
                              (player.positions && player.positions[14] && player.positions[14].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointOneBlue" className="selectedPlayer" style={{
                                    position: 'relative',
                                    top: 52,
                                    left: 208
                                  }}/> : <img src={greyPoint}
                                              alt="grey-point"
                                              id="pointOne" className="nonSelectedPlayer"
                                              style={{
                                                position: 'relative',
                                                top: 52,
                                                left: 208
                                              }}/>
                            }
                            {
                              (player.positions && player.positions[14] && player.positions[14].selected) ?
                                  <img src={delanteraLabel}
                                       alt="position-description"
                                       id="labelOne" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 70,
                                    left: 163
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[13] && player.positions[13].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTwoBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 75,
                                         left: 115
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTwo" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 75,
                                                     left: 115
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[13] && player.positions[13].selected) ?
                                  <img src={segundaDelanteraLabel}
                                       alt="position-description"
                                       id="labelTwo" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 93,
                                    left: 90
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[12] && player.positions[12].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointThreeBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 110,
                                         left: 260
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointThree" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 110,
                                                     left: 260
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[12] && player.positions[12].selected) ?
                                  <img src={extremoDerLabel}
                                       alt="position-description"
                                       id="labelThree" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 128,
                                    left: 255
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[11] && player.positions[11].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFourBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 110,
                                         left: -20
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFour" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 110,
                                                     left: -20
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[11] && player.positions[11].selected) ?
                                  <img src={extremoIzqLabel}
                                       alt="position-description"
                                       id="labelFour" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 128,
                                    left: -5
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[10] && player.positions[10].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFiveBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 170,
                                         left: 93
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFive" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 170,
                                                     left: 93
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[10] && player.positions[10].selected) ?
                                  <img src={mediaPuntaLabel}
                                       alt="position-description"
                                       id="labelFive" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 188,
                                    left: 129
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[9] && player.positions[9].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointSixBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 200,
                                         left: 185
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointSix" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 200,
                                                     left: 185
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[9] && player.positions[9].selected) ?
                                  <img src={interiorDerLabel}
                                       alt="position-description"
                                       id="labelSix" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 217,
                                    left: 240
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[8] && player.positions[8].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointSevenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 200,
                                         left: -60
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointSeven" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 200,
                                                     left: -60
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[8] && player.positions[8].selected) ?
                                  <img src={interiorIzqLabel}
                                       alt="position-description"
                                       id="labelSeven" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 217,
                                    left: 15
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[7] && player.positions[7].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointEightBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 245,
                                         left: 33
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointEight" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 245,
                                                     left: 33
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[7] && player.positions[7].selected) ?
                                  <img src={mcOfensivaLabel}
                                       alt="position-description"
                                       id="labelEight" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 261,
                                    left: 128
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[6] && player.positions[6].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointNineBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 323,
                                         left: 12.5
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointNine" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 323,
                                                     left: 12.5
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[6] && player.positions[6].selected) ?
                                  <img src={mcLabel}
                                       alt="position-description"
                                       id="labelNine" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 340,
                                    left: 128
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[5] && player.positions[5].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 390,
                                         left: -7
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTen" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 390,
                                                     left: -7
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[5] && player.positions[5].selected) ?
                                  <img src={liberoLabel}
                                       alt="position-description"
                                       id="labelTen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 408,
                                    left: 128
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[1] && player.positions[1].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointElevenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 400,
                                         left: -165
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointEleven" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 400,
                                                     left: -165
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[1] && player.positions[1].selected) ?
                                  <img src={lateralIzqLabel}
                                       alt="position-description"
                                       id="labelEleven" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 417,
                                    left: -10
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[4] && player.positions[4].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTwelveBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 400,
                                         left: 80
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTwelve" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 400,
                                                     left: 80
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[4] && player.positions[4].selected) ?
                                  <img src={lateralDerLabel}
                                       alt="position-description"
                                       id="labelTwelve" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 417,
                                    left: 255
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[2] && player.positions[2].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointThirteenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 450,
                                         left: -120
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointThirteen" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 450,
                                                     left: -120
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[2] && player.positions[2].selected) ?
                                  <img src={centralIzqLabel}
                                       alt="position-description"
                                       id="labelThirteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 465,
                                    left: 75
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[3] && player.positions[3].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFourteenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 450,
                                         left: -40
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFourteen"  className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 450,
                                                     left: -40
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[3] && player.positions[3].selected) ?
                                  <img src={centralDerLabel}
                                       alt="position-description"
                                       id="labelFourteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 468,
                                    left: 175
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[0] && player.positions[0].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFifteenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 518,
                                         left: -109
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFifteen" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 518,
                                                     left: -109
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[0] && player.positions[0].selected) ?
                                  <img src={porteraLabel}
                                       alt="position-description"
                                       id="labelFifteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 535,
                                    left: 126
                                  }}/> : null
                            }
                          </div> : null
                    }

                    {
                      (lang === 'en') ?
                          <div className="positionsRoundsWeb">
                            {
                              (player.positions && player.positions[14] && player.positions[14].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointOneBlue" className="selectedPlayer" style={{
                                    position: 'relative',
                                    top: 52,
                                    left: 208
                                  }}/> : <img src={greyPoint}
                                              alt="grey-point"
                                              id="pointOne" className="nonSelectedPlayer"
                                              style={{
                                                position: 'relative',
                                                top: 52,
                                                left: 208
                                              }}/>
                            }
                            {
                              (player.positions && player.positions[14] && player.positions[14].selected) ?
                                  <img src={delanteraLabel_en}
                                       alt="position-description"
                                       id="labelOne" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 70,
                                    left: 163
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[13] && player.positions[13].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTwoBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 75,
                                         left: 115
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTwo" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 75,
                                                     left: 115
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[13] && player.positions[13].selected) ?
                                  <img src={segundaDelanteraLabel_en}
                                       alt="position-description"
                                       id="labelTwo" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 93,
                                    left: 90
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[12] && player.positions[12].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointThreeBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 110,
                                         left: 260
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointThree" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 110,
                                                     left: 260
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[12] && player.positions[12].selected) ?
                                  <img src={extremoDerLabel_en}
                                       alt="position-description"
                                       id="labelThree" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 128,
                                    left: 255
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[11] && player.positions[11].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFourBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 110,
                                         left: -20
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFour" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 110,
                                                     left: -20
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[11] && player.positions[11].selected) ?
                                  <img src={extremoIzqLabel_en}
                                       alt="position-description"
                                       id="labelFour" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 128,
                                    left: -5
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[10] && player.positions[10].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFiveBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 170,
                                         left: 93
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFive" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 170,
                                                     left: 93
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[10] && player.positions[10].selected) ?
                                  <img src={mediaPuntaLabel_en}
                                       alt="position-description"
                                       id="labelFive" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 188,
                                    left: 129
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[9] && player.positions[9].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointSixBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 200,
                                         left: 185
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointSix" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 200,
                                                     left: 185
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[9] && player.positions[9].selected) ?
                                  <img src={interiorDerLabel_en}
                                       alt="position-description"
                                       id="labelSix" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 217,
                                    left: 240
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[8] && player.positions[8].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointSevenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 200,
                                         left: -60
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointSeven" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 200,
                                                     left: -60
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[8] && player.positions[8].selected) ?
                                  <img src={interiorIzqLabel_en}
                                       alt="position-description"
                                       id="labelSeven" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 217,
                                    left: 15
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[7] && player.positions[7].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointEightBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 245,
                                         left: 33
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointEight" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 245,
                                                     left: 33
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[7] && player.positions[7].selected) ?
                                  <img src={mcOfensivaLabel_en}
                                       alt="position-description"
                                       id="labelEight" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 261,
                                    left: 128
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[6] && player.positions[6].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointNineBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 323,
                                         left: 12.5
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointNine" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 323,
                                                     left: 12.5
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[6] && player.positions[6].selected) ?
                                  <img src={mcLabel_en}
                                       alt="position-description"
                                       id="labelNine" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 340,
                                    left: 128
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[5] && player.positions[5].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 390,
                                         left: -7
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTen" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 390,
                                                     left: -7
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[5] && player.positions[5].selected) ?
                                  <img src={liberoLabel_en}
                                       alt="position-description"
                                       id="labelTen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 408,
                                    left: 128
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[1] && player.positions[1].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointElevenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 400,
                                         left: -165
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointEleven" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 400,
                                                     left: -165
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[1] && player.positions[1].selected) ?
                                  <img src={lateralIzqLabel_en}
                                       alt="position-description"
                                       id="labelEleven" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 417,
                                    left: -10
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[4] && player.positions[4].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTwelveBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 400,
                                         left: 80
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTwelve" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 400,
                                                     left: 80
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[4] && player.positions[4].selected) ?
                                  <img src={lateralDerLabel_en}
                                       alt="position-description"
                                       id="labelTwelve" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 417,
                                    left: 255
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[2] && player.positions[2].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointThirteenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 450,
                                         left: -120
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointThirteen" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 450,
                                                     left: -120
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[2] && player.positions[2].selected) ?
                                  <img src={centralIzqLabel_en}
                                       alt="position-description"
                                       id="labelThirteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 465,
                                    left: 75
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[3] && player.positions[3].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFourteenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 450,
                                         left: -40
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFourteen"  className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 450,
                                                     left: -40
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[3] && player.positions[3].selected) ?
                                  <img src={centralDerLabel_en}
                                       alt="position-description"
                                       id="labelFourteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 468,
                                    left: 175
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[0] && player.positions[0].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFifteenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 518,
                                         left: -109
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFifteen" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 518,
                                                     left: -109
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[0] && player.positions[0].selected) ?
                                  <img src={porteraLabel_en}
                                       alt="position-description"
                                       id="labelFifteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 535,
                                    left: 126
                                  }}/> : null
                            }
                          </div> : null
                    }
                    {
                      (lang === 'pt') ?
                          <div className="positionsRoundsWeb">
                            {
                              (player.positions && player.positions[14] && player.positions[14].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointOneBlue" className="selectedPlayer" style={{
                                    position: 'relative',
                                    top: 52,
                                    left: 208
                                  }}/> : <img src={greyPoint}
                                              alt="grey-point"
                                              id="pointOne" className="nonSelectedPlayer"
                                              style={{
                                                position: 'relative',
                                                top: 52,
                                                left: 208
                                              }}/>
                            }
                            {
                              (player.positions && player.positions[14] && player.positions[14].selected) ?
                                  <img src={delanteraLabel_pt}
                                       alt="position-description"
                                       id="labelOne" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 70,
                                    left: 163
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[13] && player.positions[13].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTwoBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 75,
                                         left: 115
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTwo" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 75,
                                                     left: 115
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[13] && player.positions[13].selected) ?
                                  <img src={segundaDelanteraLabel_pt}
                                       alt="position-description"
                                       id="labelTwo" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 93,
                                    left: 90
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[12] && player.positions[12].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointThreeBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 110,
                                         left: 260
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointThree" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 110,
                                                     left: 260
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[12] && player.positions[12].selected) ?
                                  <img src={extremoDerLabel_pt}
                                       alt="position-description"
                                       id="labelThree" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 128,
                                    left: 255
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[11] && player.positions[11].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFourBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 110,
                                         left: -20
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFour" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 110,
                                                     left: -20
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[11] && player.positions[11].selected) ?
                                  <img src={extremoIzqLabel_pt}
                                       alt="position-description"
                                       id="labelFour" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 128,
                                    left: -5
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[10] && player.positions[10].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFiveBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 170,
                                         left: 93
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFive" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 170,
                                                     left: 93
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[10] && player.positions[10].selected) ?
                                  <img src={mediaPuntaLabel_pt}
                                       alt="position-description"
                                       id="labelFive" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 188,
                                    left: 129
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[9] && player.positions[9].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointSixBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 200,
                                         left: 185
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointSix" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 200,
                                                     left: 185
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[9] && player.positions[9].selected) ?
                                  <img src={interiorDerLabel_pt}
                                       alt="position-description"
                                       id="labelSix" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 217,
                                    left: 240
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[8] && player.positions[8].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointSevenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 200,
                                         left: -60
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointSeven" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 200,
                                                     left: -60
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[8] && player.positions[8].selected) ?
                                  <img src={interiorIzqLabel_pt}
                                       alt="position-description"
                                       id="labelSeven" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 217,
                                    left: 15
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[7] && player.positions[7].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointEightBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 245,
                                         left: 33
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointEight" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 245,
                                                     left: 33
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[7] && player.positions[7].selected) ?
                                  <img src={mcOfensivaLabel_pt}
                                       alt="position-description"
                                       id="labelEight" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 261,
                                    left: 128
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[6] && player.positions[6].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointNineBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 323,
                                         left: 12.5
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointNine" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 323,
                                                     left: 12.5
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[6] && player.positions[6].selected) ?
                                  <img src={mcLabel_pt}
                                       alt="position-description"
                                       id="labelNine" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 340,
                                    left: 128
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[5] && player.positions[5].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 390,
                                         left: -7
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTen" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 390,
                                                     left: -7
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[5] && player.positions[5].selected) ?
                                  <img src={liberoLabel_pt}
                                       alt="position-description"
                                       id="labelTen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 408,
                                    left: 128
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[1] && player.positions[1].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointElevenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 400,
                                         left: -165
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointEleven" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 400,
                                                     left: -165
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[1] && player.positions[1].selected) ?
                                  <img src={lateralIzqLabel_pt}
                                       alt="position-description"
                                       id="labelEleven" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 417,
                                    left: -10
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[4] && player.positions[4].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTwelveBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 400,
                                         left: 80
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTwelve" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 400,
                                                     left: 80
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[4] && player.positions[4].selected) ?
                                  <img src={lateralDerLabel_pt}
                                       alt="position-description"
                                       id="labelTwelve" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 417,
                                    left: 255
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[2] && player.positions[2].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointThirteenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 450,
                                         left: -120
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointThirteen" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 450,
                                                     left: -120
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[2] && player.positions[2].selected) ?
                                  <img src={centralIzqLabel_pt}
                                       alt="position-description"
                                       id="labelThirteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 465,
                                    left: 75
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[3] && player.positions[3].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFourteenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 450,
                                         left: -40
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFourteen"  className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 450,
                                                     left: -40
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[3] && player.positions[3].selected) ?
                                  <img src={centralDerLabel_pt}
                                       alt="position-description"
                                       id="labelFourteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 468,
                                    left: 175
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[0] && player.positions[0].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFifteenBlue" className="selectedPlayer"
                                       style={{
                                         position: 'relative',
                                         top: 518,
                                         left: -109
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFifteen" className="nonSelectedPlayer"
                                                   style={{
                                                     position: 'relative',
                                                     top: 518,
                                                     left: -109
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[0] && player.positions[0].selected) ?
                                  <img src={porteraLabel_pt}
                                       alt="position-description"
                                       id="labelFifteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 535,
                                    left: 126
                                  }}/> : null
                            }
                          </div> : null
                    }


                    {
                      (lang === 'es') ?
                          <div className="positionsRoundsMobile">
                            {
                              (player.positions && player.positions[14] && player.positions[14].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointOneBlue" className="selectedPlayerMobile" style={{
                                    position: 'relative',
                                    top: 40,
                                    left: 129
                                  }}/> : <img src={greyPoint}
                                              alt="grey-point"
                                              id="pointOne" className="nonSelectedPlayerMobile"
                                              style={{
                                                position: 'relative',
                                                top: 40,
                                                left: 129
                                              }}/>
                            }
                            {
                              (player.positions && player.positions[14] && player.positions[14].selected) ?
                                  <img src={delanteraLabel}
                                       alt="position-description"
                                       id="labelOne" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 53,
                                    left: 82
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[13] && player.positions[13].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTwoBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 55,
                                         left: 65
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTwo" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 55,
                                                     left: 65
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[13] && player.positions[13].selected) ?
                                  <img src={segundaDelanteraLabel}
                                       alt="position-description"
                                       id="labelTwo" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 67,
                                    left: 33
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[12] && player.positions[12].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointThreeBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 90,
                                         left: 152
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointThree" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 90,
                                                     left: 152
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[12] && player.positions[12].selected) ?
                                  <img src={extremoDerLabel}
                                       alt="position-description"
                                       id="labelThree" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 103,
                                    left: 135
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[11] && player.positions[11].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFourBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 90,
                                         left: -20
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFour" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 90,
                                                     left: -20
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[11] && player.positions[11].selected) ?
                                  <img src={extremoIzqLabel}
                                       alt="position-description"
                                       id="labelFour" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 103,
                                    left: -22
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[10] && player.positions[10].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFiveBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 115,
                                         left: 47
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFive" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 115,
                                                     left: 47
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[10] && player.positions[10].selected) ?
                                  <img src={mediaPuntaLabel}
                                       alt="position-description"
                                       id="labelFive" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 130,
                                    left: 60
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[9] && player.positions[9].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointSixBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 140,
                                         left: 95
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointSix" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 140,
                                                     left: 95
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[9] && player.positions[9].selected) ?
                                  <img src={interiorDerLabel}
                                       alt="position-description"
                                       id="labelSix" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 154,
                                    left: 123
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[8] && player.positions[8].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointSevenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 140,
                                         left: -50
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointSeven" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 140,
                                                     left: -50
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[8] && player.positions[8].selected) ?
                                  <img src={interiorIzqLabel}
                                       alt="position-description"
                                       id="labelSeven" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 154,
                                    left: -7
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[7] && player.positions[7].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointEightBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 161,
                                         left: 2
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointEight" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 161,
                                                     left: 2
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[7] && player.positions[7].selected) ?
                                  <img src={mcOfensivaLabel}
                                       alt="position-description"
                                       id="labelEight" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 171,
                                    left: 60
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[6] && player.positions[6].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointNineBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 210,
                                         left: -13
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointNine" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 210,
                                                     left: -13
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[6] && player.positions[6].selected) ?
                                  <img src={mcLabel}
                                       alt="position-description"
                                       id="labelNine" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 223,
                                    left: 60
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[5] && player.positions[5].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 250,
                                         left: -28
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTen" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 250,
                                                     left: -28
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[5] && player.positions[5].selected) ?
                                  <img src={liberoLabel}
                                       alt="position-description"
                                       id="labelTen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 265,
                                    left: 60
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[1] && player.positions[1].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointElevenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 250,
                                         left: -125
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointEleven" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 250,
                                                     left: -125
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[1] && player.positions[1].selected) ?
                                  <img src={lateralIzqLabel}
                                       alt="position-description"
                                       id="labelEleven" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 265,
                                    left: -22
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[4] && player.positions[4].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTwelveBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 250,
                                         left: 19
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTwelve" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 250,
                                                     left: 19
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[4] && player.positions[4].selected) ?
                                  <img src={lateralDerLabel}
                                       alt="position-description"
                                       id="labelTwelve" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 265,
                                    left: 137
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[2] && player.positions[2].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointThirteenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 290,
                                         left: -110
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointThirteen" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 290,
                                                     left: -110
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[2] && player.positions[2].selected) ?
                                  <img src={centralIzqLabel}
                                       alt="position-description"
                                       id="labelThirteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 304,
                                    left: 23
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[3] && player.positions[3].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFourteenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 290,
                                         left: -56
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFourteen"  className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 290,
                                                     left: -56
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[3] && player.positions[3].selected) ?
                                  <img src={centralDerLabel}
                                       alt="position-description"
                                       id="labelFourteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 304,
                                    left: 93
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[0] && player.positions[0].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFifteenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 330,
                                         left: -104
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFifteen" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 330,
                                                     left: -104
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[0] && player.positions[0].selected) ?
                                  <img src={porteraLabel}
                                       alt="position-description"
                                       id="labelFifteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 343,
                                    left: 59
                                  }}/> : null
                            }
                          </div> : null
                    }
                    {
                      (lang === 'en') ?
                          <div className="positionsRoundsMobile">
                            {
                              (player.positions && player.positions[14] && player.positions[14].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointOneBlue" className="selectedPlayerMobile" style={{
                                    position: 'relative',
                                    top: 40,
                                    left: 129
                                  }}/> : <img src={greyPoint}
                                              alt="grey-point"
                                              id="pointOne" className="nonSelectedPlayerMobile"
                                              style={{
                                                position: 'relative',
                                                top: 40,
                                                left: 129
                                              }}/>
                            }
                            {
                              (player.positions && player.positions[14] && player.positions[14].selected) ?
                                  <img src={delanteraLabel_en}
                                       alt="position-description"
                                       id="labelOne" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 53,
                                    left: 82
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[13] && player.positions[13].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTwoBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 55,
                                         left: 65
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTwo" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 55,
                                                     left: 65
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[13] && player.positions[13].selected) ?
                                  <img src={segundaDelanteraLabel_en}
                                       alt="position-description"
                                       id="labelTwo" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 67,
                                    left: 33
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[12] && player.positions[12].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointThreeBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 90,
                                         left: 152
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointThree" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 90,
                                                     left: 152
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[12] && player.positions[12].selected) ?
                                  <img src={extremoDerLabel_en}
                                       alt="position-description"
                                       id="labelThree" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 103,
                                    left: 135
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[11] && player.positions[11].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFourBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 90,
                                         left: -20
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFour" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 90,
                                                     left: -20
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[11] && player.positions[11].selected) ?
                                  <img src={extremoIzqLabel_en}
                                       alt="position-description"
                                       id="labelFour" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 103,
                                    left: -22
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[10] && player.positions[10].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFiveBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 115,
                                         left: 47
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFive" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 115,
                                                     left: 47
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[10] && player.positions[10].selected) ?
                                  <img src={mediaPuntaLabel_en}
                                       alt="position-description"
                                       id="labelFive" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 130,
                                    left: 60
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[9] && player.positions[9].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointSixBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 140,
                                         left: 95
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointSix" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 140,
                                                     left: 95
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[9] && player.positions[9].selected) ?
                                  <img src={interiorDerLabel_en}
                                       alt="position-description"
                                       id="labelSix" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 154,
                                    left: 123
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[8] && player.positions[8].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointSevenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 140,
                                         left: -50
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointSeven" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 140,
                                                     left: -50
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[8] && player.positions[8].selected) ?
                                  <img src={interiorIzqLabel_en}
                                       alt="position-description"
                                       id="labelSeven" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 154,
                                    left: -7
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[7] && player.positions[7].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointEightBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 161,
                                         left: 2
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointEight" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 161,
                                                     left: 2
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[7] && player.positions[7].selected) ?
                                  <img src={mcOfensivaLabel_en}
                                       alt="position-description"
                                       id="labelEight" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 171,
                                    left: 60
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[6] && player.positions[6].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointNineBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 210,
                                         left: -13
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointNine" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 210,
                                                     left: -13
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[6] && player.positions[6].selected) ?
                                  <img src={mcLabel_en}
                                       alt="position-description"
                                       id="labelNine" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 223,
                                    left: 60
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[5] && player.positions[5].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 250,
                                         left: -28
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTen" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 250,
                                                     left: -28
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[5] && player.positions[5].selected) ?
                                  <img src={liberoLabel_en}
                                       alt="position-description"
                                       id="labelTen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 265,
                                    left: 60
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[1] && player.positions[1].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointElevenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 250,
                                         left: -125
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointEleven" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 250,
                                                     left: -125
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[1] && player.positions[1].selected) ?
                                  <img src={lateralIzqLabel_en}
                                       alt="position-description"
                                       id="labelEleven" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 265,
                                    left: -22
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[4] && player.positions[4].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTwelveBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 250,
                                         left: 19
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTwelve" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 250,
                                                     left: 19
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[4] && player.positions[4].selected) ?
                                  <img src={lateralDerLabel_en}
                                       alt="position-description"
                                       id="labelTwelve" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 265,
                                    left: 137
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[2] && player.positions[2].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointThirteenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 290,
                                         left: -110
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointThirteen" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 290,
                                                     left: -110
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[2] && player.positions[2].selected) ?
                                  <img src={centralIzqLabel_en}
                                       alt="position-description"
                                       id="labelThirteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 304,
                                    left: 23
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[3] && player.positions[3].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFourteenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 290,
                                         left: -56
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFourteen"  className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 290,
                                                     left: -56
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[3] && player.positions[3].selected) ?
                                  <img src={centralDerLabel_en}
                                       alt="position-description"
                                       id="labelFourteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 304,
                                    left: 93
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[0] && player.positions[0].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFifteenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 330,
                                         left: -104
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFifteen" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 330,
                                                     left: -104
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[0] && player.positions[0].selected) ?
                                  <img src={porteraLabel_en}
                                       alt="position-description"
                                       id="labelFifteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 343,
                                    left: 59
                                  }}/> : null
                            }
                          </div> : null
                    }
                    {
                      (lang === 'pt') ?
                          <div className="positionsRoundsMobile">
                            {
                              (player.positions && player.positions[14] && player.positions[14].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointOneBlue" className="selectedPlayerMobile" style={{
                                    position: 'relative',
                                    top: 40,
                                    left: 129
                                  }}/> : <img src={greyPoint}
                                              alt="grey-point"
                                              id="pointOne" className="nonSelectedPlayerMobile"
                                              style={{
                                                position: 'relative',
                                                top: 40,
                                                left: 129
                                              }}/>
                            }
                            {
                              (player.positions && player.positions[14] && player.positions[14].selected) ?
                                  <img src={delanteraLabel_pt}
                                       alt="position-description"
                                       id="labelOne" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 53,
                                    left: 82
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[13] && player.positions[13].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTwoBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 55,
                                         left: 65
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTwo" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 55,
                                                     left: 65
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[13] && player.positions[13].selected) ?
                                  <img src={segundaDelanteraLabel_pt}
                                       alt="position-description"
                                       id="labelTwo" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 67,
                                    left: 33
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[12] && player.positions[12].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointThreeBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 90,
                                         left: 152
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointThree" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 90,
                                                     left: 152
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[12] && player.positions[12].selected) ?
                                  <img src={extremoDerLabel_pt}
                                       alt="position-description"
                                       id="labelThree" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 103,
                                    left: 135
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[11] && player.positions[11].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFourBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 90,
                                         left: -20
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFour" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 90,
                                                     left: -20
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[11] && player.positions[11].selected) ?
                                  <img src={extremoIzqLabel_pt}
                                       alt="position-description"
                                       id="labelFour" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 103,
                                    left: -22
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[10] && player.positions[10].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFiveBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 115,
                                         left: 47
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFive" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 115,
                                                     left: 47
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[10] && player.positions[10].selected) ?
                                  <img src={mediaPuntaLabel_pt}
                                       alt="position-description"
                                       id="labelFive" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 130,
                                    left: 60
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[9] && player.positions[9].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointSixBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 140,
                                         left: 95
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointSix" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 140,
                                                     left: 95
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[9] && player.positions[9].selected) ?
                                  <img src={interiorDerLabel_pt}
                                       alt="position-description"
                                       id="labelSix" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 154,
                                    left: 123
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[8] && player.positions[8].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointSevenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 140,
                                         left: -50
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointSeven" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 140,
                                                     left: -50
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[8] && player.positions[8].selected) ?
                                  <img src={interiorIzqLabel_pt}
                                       alt="position-description"
                                       id="labelSeven" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 154,
                                    left: -7
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[7] && player.positions[7].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointEightBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 161,
                                         left: 2
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointEight" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 161,
                                                     left: 2
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[7] && player.positions[7].selected) ?
                                  <img src={mcOfensivaLabel_pt}
                                       alt="position-description"
                                       id="labelEight" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 171,
                                    left: 60
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[6] && player.positions[6].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointNineBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 210,
                                         left: -13
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointNine" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 210,
                                                     left: -13
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[6] && player.positions[6].selected) ?
                                  <img src={mcLabel_pt}
                                       alt="position-description"
                                       id="labelNine" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 223,
                                    left: 60
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[5] && player.positions[5].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 250,
                                         left: -28
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTen" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 250,
                                                     left: -28
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[5] && player.positions[5].selected) ?
                                  <img src={liberoLabel_pt}
                                       alt="position-description"
                                       id="labelTen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 265,
                                    left: 60
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[1] && player.positions[1].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointElevenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 250,
                                         left: -125
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointEleven" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 250,
                                                     left: -125
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[1] && player.positions[1].selected) ?
                                  <img src={lateralIzqLabel_pt}
                                       alt="position-description"
                                       id="labelEleven" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 265,
                                    left: -22
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[4] && player.positions[4].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointTwelveBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 250,
                                         left: 19
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointTwelve" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 250,
                                                     left: 19
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[4] && player.positions[4].selected) ?
                                  <img src={lateralDerLabel_pt}
                                       alt="position-description"
                                       id="labelTwelve" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 265,
                                    left: 137
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[2] && player.positions[2].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointThirteenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 290,
                                         left: -110
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointThirteen" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 290,
                                                     left: -110
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[2] && player.positions[2].selected) ?
                                  <img src={centralIzqLabel_pt}
                                       alt="position-description"
                                       id="labelThirteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 304,
                                    left: 23
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[3] && player.positions[3].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFourteenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 290,
                                         left: -56
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFourteen"  className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 290,
                                                     left: -56
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[3] && player.positions[3].selected) ?
                                  <img src={centralDerLabel_pt}
                                       alt="position-description"
                                       id="labelFourteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 304,
                                    left: 93
                                  }}/> : null
                            }

                            {
                              (player.positions && player.positions[0] && player.positions[0].selected) ?
                                  <img src={bluePoint}
                                       alt="blue-point"
                                       id="pointFifteenBlue" className="selectedPlayerMobile"
                                       style={{
                                         position: 'relative',
                                         top: 330,
                                         left: -104
                                       }}/> : <img src={greyPoint}
                                                   alt="grey-point"
                                                   id="pointFifteen" className="nonSelectedPlayerMobile"
                                                   style={{
                                                     position: 'relative',
                                                     top: 330,
                                                     left: -104
                                                   }}/>
                            }
                            {
                              (player.positions && player.positions[0] && player.positions[0].selected) ?
                                  <img src={porteraLabel_pt}
                                       alt="position-description"
                                       id="labelFifteen" className="labelSelectedPlayer" style={{
                                    position: 'absolute',
                                    top: 343,
                                    left: 59
                                  }}/> : null
                            }
                          </div> : null
                    }

                  </div>
                </Grid>
              </Grid>
            </div>
            {
              (player.trajectory) ?
                  <div className="trajectoryRow" id="trayectoria">
                    <div className="trajectoryLabel">
                        {t('CAREER')}
                    </div>
                    <TrajectoryBar trajectory={player.trajectory}/>
                  </div> : null
            }
            {
              (player.lastsCampaigns && player.lastsCampaigns.length > 0) ?
                  <div className="lastCampaignsRow" id="ultimasCampañas">
                    <div className="lastCampaignsLabel">
                        {t('LAST CAMPAIGNS')}
                    </div>
                    <LatestCampaignsBar lastCampaigns={player.lastsCampaigns}/>
                  </div> : null
            }
            {
              (player.press && player.press.length > 0) ?
                  <div className="pressRow" id="prensa">
                    <div className="pressLabel">
                        {t('PRESS')}
                    </div>
                    <PressSquare press={player.press}/>
                  </div> : null
            }
            {
              (player && player.instagram && instagramPhotos.length > 0) ?
                  <div className="instagramRow">
                    <Grid container
                          spacing={0}
                          className="instagramRowInfoSmall"
                          direction="row"
                          alignItems="center"
                          justify="center">
                      <Grid item xs={2} sm={2}
                            className="logoInstagramInfoSmallGrid">
                        <a href={`https://www.instagram.com/${player.instagramUsername}`} target='_blank'>
                          <img className="logoInstagramInfoSmall"
                               src={instagramLogoBlue}/>
                        </a>
                      </Grid>
                      {
                        (player && player.instagramFollowers) ?
                            <Grid item xs={4} sm={2}
                                  className="logoInstagramInfoSmallGrid">
                              <div className="numberFollowersInsta">
                                <div>
                                  <b>{kFormatter(player.instagramFollowers)}</b>
                                </div>
                                seguidores
                              </div>
                            </Grid> : null
                      }
                      <Grid item xs={6} sm={4}>
                        {
                          (player.instagramUsername) ?
                              <a href={`https://www.instagram.com/${player.instagramUsername}`} target='_blank'
                                 className="instagramLabelDivSmall">
                                <img className="instagramLabelImg" src={instagramLabel}/>
                                <div className="instagramLabel">@{player.instagramUsername.toUpperCase()}</div>
                              </a> : null
                        }
                      </Grid>
                    </Grid>
                    <Grid container
                          spacing={0}
                          direction="row"
                          alignItems="flex-start"
                          justify="center">
                      <Grid item xs={3} className="instagramBorderColums">
                        <a href={`https://www.instagram.com/${player.instagramUsername}`} target='_blank'>
                          <img src={instagramLogoBlue} alt="instagram-blue-icon"/>
                        </a>
                        <div className="blueRectangle"></div>
                        <img className="imagesBlueLines" src={blueLines} alt="blue-lines"/>
                      </Grid>
                      <Grid item xs={12} sm={11} md={6} className="instagramPhotos">
                        <Grid container
                              spacing={2}
                              direction="row"
                              alignItems="center"
                              justify="center">
                          <Grid item xs={4}>
                            <div className="instagramImages">
                              {
                                (instagramPhotos && instagramPhotos[0] && instagramPhotos[0].media_url && instagramPhotos[0].media_url !== '') ?
                                    <a href={instagramPhotos[0].url} target='_blank'>
                                      <img src={instagramPhotos[0].media_url}
                                           className="instagramImage"/>
                                    </a>  :
                                    <img src={require('../../images/background.jpg')}
                                         className="instagramImage"/>
                              }
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <div className="instagramImages">
                              {
                                (instagramPhotos && instagramPhotos[1] && instagramPhotos[1].media_url && instagramPhotos[1].media_url !== '') ?
                                    <a href={instagramPhotos[1].url} target='_blank'>
                                      <img src={instagramPhotos[1].media_url}
                                           className="instagramImage"/>
                                    </a>  :
                                    <img src={require('../../images/background.jpg')}
                                         className="instagramImage"/>
                              }
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <div className="instagramImages">
                              {
                                (instagramPhotos && instagramPhotos[2] && instagramPhotos[2].media_url && instagramPhotos[2].media_url !== '') ?
                                    <a href={instagramPhotos[2].url} target='_blank'>
                                      <img src={instagramPhotos[2].media_url}
                                           className="instagramImage"/>
                                    </a> :
                                    <img src={require('../../images/background.jpg')}
                                         className="instagramImage"/>
                              }
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <div className="instagramImages">
                              {
                                (instagramPhotos && instagramPhotos[3] && instagramPhotos[3].media_url && instagramPhotos[3].media_url !== '') ?
                                    <a href={instagramPhotos[3].url} target='_blank'>
                                      <img src={instagramPhotos[3].media_url}
                                           className="instagramImage"/>
                                    </a> :
                                    <img src={require('../../images/background.jpg')}
                                         className="instagramImage"/>
                              }
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <div className="instagramImages">
                              {
                                (instagramPhotos && instagramPhotos[4] && instagramPhotos[4].media_url && instagramPhotos[4].media_url !== '') ?
                                    <a href={instagramPhotos[4].url} target='_blank'>
                                      <img src={instagramPhotos[4].media_url}
                                           className="instagramImage"/>
                                    </a> :
                                    <img src={require('../../images/background.jpg')}
                                         className="instagramImage"/>
                              }
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <div className="instagramImages">
                              {
                                (instagramPhotos && instagramPhotos[5] && instagramPhotos[5].media_url && instagramPhotos[5].media_url !== '') ?
                                    <a href={instagramPhotos[5].url} target='_blank'>
                                      <img src={instagramPhotos[5].media_url}
                                           className="instagramImage"/>
                                    </a>  :
                                    <img src={require('../../images/background.jpg')}
                                         className="instagramImage"/>
                              }
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                      {
                        (player.instagramFollowers) ?
                            <Grid item xs={3} className="instagramBorderColumsRight">
                              <div className="numberFollowersInsta">
                                <div>
                                  <b>{kFormatter(player.instagramFollowers)}</b>
                                </div>
                                seguidores
                              </div>
                              {
                                (player.instagramUsername) ?
                                    <a href={`https://www.instagram.com/${player.instagramUsername}`} target='_blank'
                                       className="instagramLabelDiv">
                                      <img className="instagramLabelImg" src={instagramLabel}/>
                                      <div className="instagramLabel">@{player.instagramUsername.toUpperCase()}</div>
                                    </a> : null
                              }
                              <div className="blueRectangleSmallMarginTop"></div>
                              <img className="imagesBlueLines" src={blueLines}/>
                            </Grid> :
                            <Grid item xs={3} className="instagramBorderColums">
                              {
                                (player.instagramUsername) ?
                                    <a href={`https://www.instagram.com/${player.instagramUsername}`} target='_blank'
                                       className="instagramLabelDiv">
                                      <img className="instagramLabelImg" src={instagramLabel}/>
                                      <div className="instagramLabel">@{player.instagramUsername.toUpperCase()}</div>
                                    </a> : null
                              }
                              <div className="blueRectangle"></div>
                              <img className="imagesBlueLines" src={blueLines} alt="blue-lines"/>
                            </Grid>
                      }

                    </Grid>
                  </div> : null
            }

            <div className="unikTeamPlayerRow">
              <div className="unikTeamPlayerLabel">
                UNIK TEAM
              </div>
              <Grid container
                    className="unikTeamOtherPlayersGrid"
                    spacing={0}
                    direction="row"
                    alignItems="center"
                    justify="center">
                <Grid item className="shield" xs={1}
                      onClick={() => goBackwardTeam()}>
                  <div className="arrowsDiv" style={{
                    /* Full height */
                    width: '100%',
                    height: '100%',
                    margin: 'auto',
                    cursor: 'pointer',
                  }}>
                    <img src={leftArrow}
                         alt="left-arrow"
                         className="arrowsImg"/>
                  </div>
                </Grid>
                {/*<Grid item xs={10}>*/}
                {/*<Grid container*/}
                {/*className="gridFilters"*/}
                {/*wrap={"nowrap"}*/}
                {/*spacing={0}*/}
                {/*direction="row"*/}
                {/*alignItems="center"*/}
                {/*justify="center">*/}
                {
                  unikTeamPlayers.map((item, i) => {
                    return(<Grid key={i}
                                 item xs={2}
                                 onClick={() => {
                                   history.push(`/futbolistas/${item.nameUrl}`, player);
                                   dispatch(setSelectedPage(`/futbolistas/${item.nameUrl}`));
                                   let allPlayersSearch = [...allPlayers];
                                   let plyr = allPlayersSearch.find((plyrs) => item.nameUrl === plyrs.nameUrl);

                                   if(plyr){
                                     setPlayer(plyr);
                                   }
                                   window.scrollTo(0, 0);
                                 }}
                                 className="unikTeamRowPlayersWeb">
                      <PlayerSquareGrid player={item} key={item.id}/>
                    </Grid>)
                  })
                }
                {/*</Grid>*/}
                {/*</Grid>*/}
                <Grid item xs={10}
                      className="unikTeamRowPlayersGridWeb">
                  <Grid container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justify="center">
                    {
                      unikTeamPlayers.map((item, i) => {
                        if(numDependingWidth-1 > i) {
                          return(<Grid key={i}
                                       item xs={6} sm={4} md={3}
                                       onClick={() => {
                                         history.push(`/futbolistas/${item.nameUrl}`, player);
                                         dispatch(setSelectedPage(`/futbolistas/${item.nameUrl}`));
                                         let plyr = allPlayers.find((plyrs) => item.nameUrl === plyrs.nameUrl);
                                         if(plyr){
                                           setPlayer(plyr);
                                         }
                                         window.scrollTo(0, 0);
                                       }}>
                            <PlayerSquareGrid player={item} key={item.id}/>
                          </Grid>)
                        }
                      })
                    }
                  </Grid>
                </Grid>

                <Grid item className="shield" xs={1}
                      onClick={() => goForwardTeam()}>
                  <div className="arrowsDiv" style={{
                    /* Full height */
                    width: '100%',
                    height: '100%',
                    margin: 'auto',
                    cursor: 'pointer',
                  }}>
                    <img src={rightArrow}
                         alt="right-arrow"
                         className="arrowsImg"/>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="knowAllTeamRowPlayer">
              <div className="knowAllTeamBlueLinesPlayer"
                   style={{
                     display: 'inline-block',
                     textAlign: 'center',
                     verticalAlign: 'middle',
                     width: '299px',
                     height: '61px',
                     background: `url(${blueRayas}) no-repeat center`,
                   }}/>
              <div className="threeBlueLinesPlayer">
                <img className="threeBlueLinesImagePlayer"
                     alt="three-blue-lines"
                     src={threeBlueLines}/>
              </div>
              <div onClick={() => {
                history.push(`/players`);
                dispatch(setSelectedPage('/players'));
                window.scrollTo(0, 0);
              }}
                   className="knowAllTeamButtonPlayer">
                {t('MEET THE WHOLE TEAM')}
              </div>
              <div className="knowAllTeamBlueLinesPlayer"
                   style={{
                     display: 'inline-block',
                     textAlign: 'center',
                     verticalAlign: 'middle',
                     width: '299px',
                     height: '61px',
                     background: `url(${blueRayas}) no-repeat center`,
                   }}/>
              <div className="threeBlueLinesPlayer">
                <img className="threeBlueLinesImagePlayer"
                     alt="three-blue-lines"
                     src={threeBlueLines}/>
              </div>
            </div>
            <Footer page={'Jugadoras'}/>
          </div>
            :
          <div className="loaderDiv">
            <CircularProgress/>
          </div>
      }
    </DocumentMeta>
  )
};

export default Player;