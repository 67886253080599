import React, { useState, useEffect } from 'react';
import '../../../App.css';
import './Palmares-info.css'
import Grid from '@material-ui/core/Grid';
import {useTranslation} from "react-i18next";

const arrowMoreInfoSmall = require('../../../images/right_arrow.png');
const arrowMoreInfo = require('../../../images/flechaPalmares.png');
const blueSquares = require('../../../images/cuadradosPalmares.png');

const PalmaresInfo = ({info}) => {

  const [originalInfo, setOriginalInfo] = useState([]);
  const [infoList, setInfoList] = useState([]);
  const [numPressList, setNumPressList] = useState({
    initial: 0,
    end: 2,
  });

  const [lang, setLang] = useState('en');
  const [t, i18n] = useTranslation();

  useEffect(() => {
    setLang(i18n.languages[1]);
    console.log('player', i18n.languages[1]);
  }, [i18n.languages[1]]);

  useEffect(() => {
    let srch = info.filter((item) => item.id < 3);
    setInfoList(() => [...srch]);
    setOriginalInfo(() => [...info]);
  }, [info]);

  useEffect(() => {
    let srch = info.filter((item) => item.id < 3);
    setInfoList(() => [...srch]);
    setOriginalInfo(() => [...info]);
  }, []);

  const nextInfo = () => {
    if(numPressList.end >=  originalInfo.length) {
      setInfoList(() => originalInfo.filter((item, index) => index >= 0 && index < 2));

      setNumPressList((nums) => {
        nums.initial = 0;
        nums.end = 2;
        return nums;
      });
    } else {
      let init = numPressList.initial + 2;
      let end = numPressList.end + 2;
      setInfoList(() => originalInfo.filter((item, index) => index >= init && index < end));

      setNumPressList((nums) => {
        nums.initial = init;
        nums.end = end;
        return nums;
      });
    }
  };

  return(
    <div>
      <div className="honorsInfo">
        <div className="honorDiv">
          {
            (infoList && infoList[0]) ?
              <div className="honorDivInfo">
                <div className="nameFirstRow">
                  {infoList[0]['nameFirstRow_'+lang]}
                </div>
                <div className="nameSecondRow">
                  {infoList[0]['nameSecondRow_'+lang]}
                </div>
                {
                  (infoList[0] && infoList[0].nameThirdRow) ?
                    <div className="nameSecondRow">
                      {infoList[0]['nameThirdRow_'+lang]}
                    </div> : null
                }
                <div className="team">
                  {infoList[0]['team_'+lang]}
                </div>
                <div className="years">
                  {infoList[0]['years_'+lang]}
                </div>
              </div> : null
          }
        </div>
        {
          (infoList && infoList.length > 1) ?
            <div className="squaresMiddle">
              <img src={blueSquares}
                   alt="blue-square"/>
            </div>: null
        }
        <div className="honorDiv">
          {
            (infoList && infoList[1]) ?
              <div className="honorDivInfo">
                <div className="nameFirstRow">
                  {infoList[1]['nameFirstRow_'+lang]}
                </div>
                <div className="nameSecondRow">
                  {infoList[1]['nameSecondRow_'+lang]}
                </div>
                {
                  (infoList[0] && infoList[0].nameThirdRow) ?
                    <div className="nameSecondRow">
                      {infoList[0]['nameThirdRow_'+lang]}
                    </div> : null
                }
                <div className="team">
                  {infoList[1]['team_'+lang]}
                </div>
                <div className="years">
                  {infoList[1]['years_'+lang]}
                </div>
              </div> : null
          }
        </div>
      </div>
      {
        (info && info.length > 2) ?
          <div>
            <img className="arrowMore"
                 onClick={() => nextInfo()}
                 src={arrowMoreInfo}
                 alt="arrow-more-info"/>
            <div className="arrowMoreSmallDiv"
                 onClick={() => nextInfo()}>
              <img className="arrowMoreSmall"
                   src={arrowMoreInfoSmall}
                   alt="arrow-more-info"/>
            </div>
          </div>
          : null
      }
    </div>
  )
};

export default PalmaresInfo;