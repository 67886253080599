import React, { useState, useEffect } from 'react';
import '../../App.css';
import './Entidades-deportivas.css';
import Footer from "../footer/Footer";
import RowEntidadesDeportivas from "./row-entidades-deportivas/Row-entidades-deportivas";

import Grid from '@material-ui/core/Grid';
import DocumentMeta from 'react-document-meta';
import {useTranslation} from "react-i18next";

const blueLines = require('../../images/tres_rayas.png');
const triangulo = require('../../images/entidades-deportivas/background_entidades_updated.png');
const trianguloSmall = require('../../images/entidades-deportivas/background_entidades_xs_small.png');
const twoBlueLines = require('../../images/brands/twoBlueLines.png');

const iconoFutbolBase = require('../../images/entidades-deportivas/entidades-futbol-base.png');
const iconoProgramasEducacionales = require('../../images/entidades-deportivas/entidades-programas-educacionales.png');
const iconoOrganizacionEventos = require('../../images/entidades-deportivas/entidades-organizacion-eventos.png');
const iconoEstrategiasMarketing = require('../../images/entidades-deportivas/entidades-estrategias-marketing.png');
const iconoResponsabilidadSocial = require('../../images/entidades-deportivas/entidades-responsabilidad-social-corporativa.png');


const EntidadesDeportivas = () => {

  const [width, setWidth] = useState(0);
  const [t, i18n] = useTranslation();
  const meta = {
    title: 'UNIK SPORTS MANAGEMENT | Entidades deportivas',
    description: 'Ofrecemos soluciones integrales a clubes, federaciones y entidades deportivas que quieran contribuir al crecimiento y desarrollo del fútbol femenino.',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let wdth = width;
    if(wdth === 0) {
      wdth = window.innerWidth;
      setWidth(wdth);
    }
  }, [width]);

  const handleResize = () => {
    const windowSize = window.innerWidth;
    setWidth(windowSize);
  };

  const goToId = (serviceId) => {
    let offsetTop  = document.getElementById(serviceId).offsetTop;
    window.scrollTo({
      top: offsetTop-20,
      behavior: "smooth"
    });
  };

  const InfoEntidades = () => {
    return(
      <div className="entidadesDiv"
           style={{
        background: `url(${triangulo}) no-repeat`,
        backgroundSize: '100%'
      }}>
        <div className="serviceLabelRow">
          <div className="threeLinesServices">
            <img className="linesServices"
                 src={blueLines}
                 alt='blue lines' />
          </div>
          <div className="entidadesLabel">
            {t('SPORTS ENTERPRISES')}
          </div>
        </div>

        <div className="serviceLabelRowSmall">
          <div className="threeLinesServices">
            <img className="lines"
                 src={blueLines}
                 alt='blue lines' />
          </div>
          <div className="entidadesLabelSmall">
            {t('SPORTS')} <br/> {t('ENTERPRISES')}
          </div>
        </div>

        {/*<div>*/}
        <Grid container
              className="gridTextEntidades"
              spacing={0}
              direction="row"
              alignItems="center"
              justify="center">
          <Grid item className="iconTextEntidadesDescription"
                id="iconEntidadesDescriptionFirst"
                xs={1}>
            <img className="iconTextEntidadesDescriptionImg"
                 src={twoBlueLines}
                 alt="two-blue-lines"/>
          </Grid>
          <Grid item className="textEntidadesDescription"
                id="textEntidadesDescriptionFirst"
                xs={11}>
            <div className="textEntidadesDescriptionText">
              {t('SPORTS-DESC-1-WEB-1')}<br/>
              {t('SPORTS-DESC-1-WEB-2')}<br/>
              {t('SPORTS-DESC-1-WEB-3')}<br/>
              {t('SPORTS-DESC-1-WEB-4')}
            </div>
            <div className="textEntidadesDescriptionTextSmall">
              {t('SPORTS-DESC-1-SMALL-1')}<br/>
              {t('SPORTS-DESC-1-SMALL-2')}<br/>
              {t('SPORTS-DESC-1-SMALL-3')}<br/>
              {t('SPORTS-DESC-1-SMALL-4')}
            </div>
          </Grid>
          <Grid item className="iconTextEntidadesDescription"
                xs={1}>
            <img className="iconTextEntidadesDescriptionImg"
                 src={twoBlueLines}
                 alt="two-blue-lines"/>
          </Grid>
          <Grid item className="textEntidadesDescription"
                xs={11}>
            <div className="textEntidadesDescriptionText">
              {t('SPORTS-DESC-2-WEB-1')}<br/>
              {t('SPORTS-DESC-2-WEB-2')}<br/>
              {t('SPORTS-DESC-2-WEB-3')}<br/>
            </div>
            <div className="textEntidadesDescriptionTextSmall">
              {/*Estamos capacitados para ofrecer<br/>*/}
              {/*soluciones integrales a cualquier<br/>*/}
              {/*federación, club o entidad que <br/>*/}
              {/*los requiera.*/}
              {t('SPORTS-DESC-2-SMALL-1')}<br/>
              {t('SPORTS-DESC-2-SMALL-2')}<br/>
              {t('SPORTS-DESC-2-SMALL-3')}<br/>
            </div>
          </Grid>
          <Grid item className="iconTextEntidadesDescription"
                xs={1}>
            <img className="iconTextEntidadesDescriptionImg"
                 id="iconEntidadesDescriptionLast"
                 src={twoBlueLines}
                 alt="two-blue-lines"/>
          </Grid>
          <Grid item className="textEntidadesDescription"
                id="textEntidadesDescriptionLast"
                xs={11}>
            <div className="textEntidadesDescriptionText">
              {t('SPORTS-DESC-3-WEB-1')}<br/>
              {t('SPORTS-DESC-3-WEB-2')}<br/>
              {t('SPORTS-DESC-3-WEB-3')}<br/>
            </div>
            <div className="textEntidadesDescriptionTextSmall">
              {t('SPORTS-DESC-3-SMALL-1')}<br/>
              {t('SPORTS-DESC-3-SMALL-2')}<br/>
              {t('SPORTS-DESC-3-SMALL-3')}<br/>
              {/*También buscamos contribuir al <br/>*/}
              {/*crecimiento del fútbol femenino<br/>*/}
              {/*mediante la realización de eventos<br/>*/}
              {/*y actividades que promuevan la<br/>*/}
              {/*práctica del deporte y ayuden a su<br/>*/}
              {/*promoción y difusión.*/}
            </div>
          </Grid>
        </Grid>
        {/*</div>*/}
      </div>
    )
  };

  const InfoEntidadesSmall = () => {
    return(
      <div style={{
        background: `url(${trianguloSmall}) no-repeat`,
        backgroundSize: '100%'
      }}>
        <div className="serviceLabelRow">
          <div className="threeLinesServices">
            <img className="linesServices"
                 src={blueLines}
                 alt='blue lines' />
          </div>
          <div className="entidadesLabel">
            {t('SPORTS ENTERPRISES')}
          </div>
        </div>

        <div className="serviceLabelRowSmall">
          <div className="threeLinesServicesSmall">
            <img className="lines"
                 src={blueLines}
                 alt='blue lines' />
          </div>
          <div className="entidadesLabelSmall">
            {t('SPORTS_HEADER')} <br/> {t('ENTERPRISES_HEADER')}
          </div>
        </div>

        {/*<div>*/}
        <Grid container
              className="gridTextEntidades"
              spacing={0}
              direction="row"
              alignItems="center"
              justify="center">
          <Grid item className="iconTextEntidadesDescription"
                id="iconEntidadesDescriptionFirst"
                xs={1}>
            <img className="iconTextEntidadesDescriptionImg"
                 src={twoBlueLines}
                 alt="two-blue-lines"/>
          </Grid>
          <Grid item className="textEntidadesDescription"
                id="textEntidadesDescriptionFirst"
                xs={11}>
            <div className="textEntidadesDescriptionText">
              {t('SPORTS-DESC-1-WEB-1')}<br/>
              {t('SPORTS-DESC-1-WEB-2')}<br/>
              {t('SPORTS-DESC-1-WEB-3')}<br/>
              {t('SPORTS-DESC-1-WEB-4')}
            </div>
            <div className="textEntidadesDescriptionTextSmall">
              {t('SPORTS-DESC-1-SMALL-1')}<br/>
              {t('SPORTS-DESC-1-SMALL-2')}<br/>
              {t('SPORTS-DESC-1-SMALL-3')}<br/>
              {t('SPORTS-DESC-1-SMALL-4')}
            </div>
          </Grid>
          <Grid item className="iconTextEntidadesDescription"
                xs={1}>
            <img className="iconTextEntidadesDescriptionImg"
                 src={twoBlueLines}
                 alt="two-blue-lines"/>
          </Grid>
          <Grid item className="textEntidadesDescription"
                xs={11}>
            <div className="textEntidadesDescriptionText">
              {t('SPORTS-DESC-2-WEB-1')}<br/>
              {t('SPORTS-DESC-2-WEB-2')}<br/>
              {t('SPORTS-DESC-2-WEB-3')}
            </div>
            <div className="textEntidadesDescriptionTextSmall">
              {/*Estamos capacitados para ofrecer<br/>*/}
              {/*soluciones integrales a cualquier<br/>*/}
              {/*federación, club o entidad que <br/>*/}
              {/*los requiera.*/}
              {t('SPORTS-DESC-2-SMALL-1')}<br/>
              {t('SPORTS-DESC-2-SMALL-2')}<br/>
              {t('SPORTS-DESC-2-SMALL-3')}
            </div>
          </Grid>
          <Grid item className="iconTextEntidadesDescription"
                xs={1}>
            <img className="iconTextEntidadesDescriptionImg"
                 id="iconEntidadesDescriptionLast"
                 src={twoBlueLines}
                 alt="two-blue-lines"/>
          </Grid>
          <Grid item className="textEntidadesDescription"
                id="textEntidadesDescriptionLast"
                xs={11}>
            <div className="textEntidadesDescriptionText">
              {t('SPORTS-DESC-3-WEB-1')}<br/>
              {t('SPORTS-DESC-3-WEB-2')}<br/>
              {t('SPORTS-DESC-3-WEB-3')}
            </div>
            <div className="textEntidadesDescriptionTextSmall">
              {/*También buscamos contribuir al <br/>*/}
              {/*crecimiento del fútbol femenino<br/>*/}
              {/*mediante la realización de eventos<br/>*/}
              {/*y actividades que promuevan la<br/>*/}
              {/*práctica del deporte y ayuden a su<br/>*/}
              {/*promoción y difusión.*/}
              {t('SPORTS-DESC-3-SMALL-1')}<br/>
              {t('SPORTS-DESC-3-SMALL-2')}<br/>
              {t('SPORTS-DESC-3-SMALL-3')}
            </div>
          </Grid>
        </Grid>
        {/*</div>*/}
      </div>
    )
  };

  return(
    <DocumentMeta {...meta}>
      <div>
        {
          (width > 600) ?
            <InfoEntidades/>:
            <InfoEntidadesSmall/>
        }
        <Grid container
              className="gridFiltersEntidades"
              spacing={5}
              wrap={"nowrap"}
              direction="row"
              alignItems="center"
              justify="center">
          <Grid item xs={3}
                onClick={() => goToId('desarrollo-futbol-base')}
                className="columnEntidades">
            <div className="labelEntidades">{t('SPORTS-1-DESC-1')}<br/>{t('SPORTS-1-DESC-2')}</div>
          </Grid>
          <Grid item xs={3}
                onClick={() => goToId('programas-educacionales')}
                className="columnEntidades">
            <div className="labelEntidades">{t('SPORTS-2-DESC-1')}<br/>{t('SPORTS-2-DESC-2')}</div>
          </Grid>
          <Grid item xs={3}
                onClick={() => goToId('organizacion-eventos')}
                className="columnEntidades">
            <div className="labelEntidades">{t('SPORTS-3-DESC-1')}<br/>{t('SPORTS-3-DESC-2')}</div>
          </Grid>
          <Grid item xs={3}
                onClick={() => goToId('estrategias-marketing')}
                className="columnEntidades">
            <div className="labelEntidades">{t('SPORTS-4-DESC-1')}<br/>{t('SPORTS-4-DESC-2')}</div>
          </Grid>
          <Grid item xs={3}
                onClick={() => goToId('resopnabilidad-social-corporativa')}
                className="columnEntidadesLast">
            <div className="labelEntidades">{t('SPORTS-5-DESC-1')}<br/>{t('SPORTS-5-DESC-2')}</div>
          </Grid>
        </Grid>
        <div className="divGridEntidades">
          <div id="desarrollo-futbol-base">
            <RowEntidadesDeportivas entidad={{
              icon: iconoFutbolBase,
              alt: 'futbol-base',
              title_es: 'DESAROLLO DEL FÚTBOL BASE',
              text_es: 'Diseño e implementación de programas de scouting y gestión de talento para ayudar a los clubes a desarrollar su sección femenina.',
              title_en: "GRASSROOTS PROGRAMS",
              text_en: "Design and implementation of scouting and talent management programs to help clubs develop their women's section.",
              title_pt: "DESENVOLVIMENTO DE FUTEBOL DE BASE",
              text_pt: "Planificação e implementação de programas de scouting e gestão de talentos para ajudar clubes a desenvolver suas seções femininas."
            }}/>
          </div>
          <div id="programas-educacionales">
            <RowEntidadesDeportivas entidad={{
              icon: iconoProgramasEducacionales,
              alt: 'programas-educacionales',
              title_es: 'PROGRAMAS EDUCACIONALES',
              text_es: 'Creación de cursos a medida, online y presenciales, organización de conferencias y actividades relacionadas con el deporte y la mujer.',
              title_en: "EDUCATIONAL PROGRAMS",
              text_en: "Creation of tailor-made, online and face-to-face courses, organizing conferences and activities related to sport and women.",
              title_pt: "PROGRAMAS EDUCATIVOS",
              text_pt: "Criação de cursos personalizados, on-line e presenciais, organização de conferências e atividades relacionadas ao esporte feminino."
            }}/>
          </div>
          <div id="organizacion-eventos">
            <RowEntidadesDeportivas entidad={{
              icon: iconoOrganizacionEventos,
              alt: 'organizacion-de-eventos',
              title_es: 'ORGANIZACIÓN DE EVENTOS',
              text_es: 'Planificación y desarrollo de congresos, campus deportivos y jornadas dedicas al fútbol femenino.',
              title_en: "EVENT ORGANIZATION",
              text_en: "Planning and development of congresses, sports campus and women's football days.",
              title_pt: "ORGANIZAÇÃO DE EVENTOS",
              text_pt: "Organização de conferências, campus esportivos e atividades dedicadas ao futebol feminino."
            }}/>
          </div>
          <div id="estrategias-marketing">
            <RowEntidadesDeportivas entidad={{
              icon: iconoEstrategiasMarketing,
              alt: 'estrategias-de-marketing',
              title_es: 'ESTRATEGIAS DE MARKETING',
              text_es: 'Propuesta e implementación de estrategias online y offline. Búsqueda de sponsors, diseño de webs y apps, creación de contenido gestión de redes sociales, y relación con los medios.',
              title_en: "MARKETING STRATEGIES",
              text_en: "Proposal and implementation of online and offline strategies. Search for sponsors, design of websites and apps, content creation, social media management and media relations.",
              title_pt: "ESTRATÉGIAS DE MARKETING",
              text_pt: "Planejamento e implementação de estratégias on-line e off-line. Busca de patrocinadores, desenvolvimento de aplicativos e sites, criação de conteúdo, gerenciamento de mídias sociais e assessoria de imprensa."
            }}/>
          </div>
          <div id="resopnabilidad-social-corporativa">
            <RowEntidadesDeportivas entidad={{
              icon: iconoResponsabilidadSocial,
              alt: 'responsabilidad-social-corporativa',
              title_es: 'RESPONSABILIDAD SOCIAL CORPORATIVA',
              text_es: 'Definición e implantación de planes de acción orientados a ayudar a las entidades deportivas a realizar aportaciones económicas, sociales y medioambientales al medio donde se encuentran vinculadas, fomentando buenas prácticas y siendo organizaciones inclusivas e integradas.',
              title_en: "CORPORATE SOCIAL RESPONSIBILITY",
              text_en: "Definition and implementation of action plans aimed at helping sports entities make economic, social, and environmental contributions to society, promoting good practices and being inclusive and integrated organizations.",
              title_pt: "RESPONSABILIDADE SOCIAL CORPORATIVA",
              text_pt: "Definição e implementação de planos de ação destinados a ajudar entidades esportivas a contribuírem de forma econômica, social e ambiental ao meio onde estão vinculadas, promovendo boas práticas e tornando-se organizações inclusivas e integradas."
            }}/>
          </div>
        </div>
        <Footer page={'Jugadoras'}/>
      </div>
    </DocumentMeta>
  );
};

export default EntidadesDeportivas;