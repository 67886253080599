import React, { useState, useEffect } from 'react';
import '../../App.css';
import './Footer.css'
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import { rrssIconsFirstRow, rrssIconsSecondRow } from '../../data/footer'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import emailjs from 'emailjs-com';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPage } from '../../store/actions/selectedPage'
import { setSelectedPrivacyPolicy } from '../../store/actions/selectedPrivacyPolicy'

import {useTranslation} from "react-i18next";
const logo = require('../../images/UNIK_blue.png');

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const Footer = ({page}) => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const selectedPage = useSelector(state => state.selectedPage.selectedPage);
  const selectedPrivacyPolicy = useSelector(state => state.selectedPrivacyPolicy.selectedPrivacyPolicy);

  const [email, setEmail] = useState('');
  const history = useHistory();
  const [lang, setLang] = useState('en');
  const [t, i18n] = useTranslation();

  useEffect(() => {
    setLang(i18n.languages[1]);
    console.log('player', i18n.languages[1]);
  }, [i18n.languages[1]]);

  const [contactForm, setContactForm] = useState({
    from_email: '',
  });
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError1, setOpenError1] = useState(false);
  const [openError2, setOpenError2] = useState(false);

  const handleClickSuccess = () => {
    setOpenSuccess(true);
  };
  const handleClickError1 = () => {
    setOpenError1(true);
  };
  const handleClickError2 = () => {
    setOpenError2(true);
  };
  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };
  const handleCloseError1 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError1(false);
  };
  const handleCloseError2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenError2(false);
  };

  const Menu = ({item_es, item_en, item_pt, link}) =>{
    if(page === item_es) {
      return(
          <div>
            {
              (lang === 'es') ?
                  <div className="contactMenuItemSelected">
                    {item_es.toUpperCase()}
                  </div> : null
            }
            {
              (lang === 'en') ?
                  <div className="contactMenuItemSelected">
                    {item_en.toUpperCase()}
                  </div> : null
            }
            {
              (lang === 'pt') ?
                  <div className="contactMenuItemSelected">
                    {item_pt.toUpperCase()}
                  </div> : null
            }
          </div>

      )
    } else {
      return(
          <div>
            {
              (lang === 'es') ?
                  <div className="contactMenuItem"
                       onClick={() => {
                         history.push(link);
                         dispatch(setSelectedPage(link));
                         window.scrollTo(0, 0);
                       }}>
                    {item_es.toUpperCase()}
                  </div> : null
            }
            {
              (lang === 'en') ?
                  <div className="contactMenuItem"
                       onClick={() => {
                         history.push(link);
                         dispatch(setSelectedPage(link));
                         window.scrollTo(0, 0);
                       }}>
                    {item_en.toUpperCase()}
                  </div> : null
            }
            {
              (lang === 'pt') ?
                  <div className="contactMenuItem"
                       onClick={() => {
                         history.push(link);
                         dispatch(setSelectedPage(link));
                         window.scrollTo(0, 0);
                       }}>
                    {item_pt.toUpperCase()}
                  </div> : null
            }
          </div>
      )
    }
  };

  const addEmail = () => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if(contactForm.from_email && re.test(contactForm.from_email.toLowerCase())){
      emailjs.send('smtp_server', 'email_template_newletter', contactForm, 'user_Dps8j15SRGoGcTdabWNFl')
        .then((result) => {
          setOpenSuccess(true);
        }, (error) => {
          setOpenError2(true);
        });
    } else {
      setOpenError1(true);
    }
  };

  const updateField = (key, e) => {
    setContactForm({
      ...contactForm,
      [key]: e.target.value
    });
  };

  return(
    <div>
      {
        (page !== 'Contacto') ?
          <div>
            <div className="contactRow">
              <div className="contactLabel">
                {t('HEADER-CONTACT-US')}
              </div>
              <Grid container
                    style={{
                      paddingLeft: '3%',
                      paddingRight: '3%',
                     /* paddingTop: '3%'*/
                    }}
                    direction="row"
                    spacing={0}
                    alignItems="flex-start"
                    justify="center">
                <Grid item xs={6} sm={4} md={5}>
                  <div className="contactRowLogoMailPhone">
                    <div className="contactLogo"
                         style={{
                           background: `url(${logo}) no-repeat center`,
                           title: 'logo-unik-sports-management'
                         }}>
                    </div>
                    <div className="contactMail">
                      info@unikteam.com
                    </div>
                    {/*<div className="contactPhone">*/}
                    {/*/!*Tel. 932 805 644*!/*/}
                    {/*</div>*/}
                    <div className="divRRSSLogos">
                      {
                        rrssIconsFirstRow.map( (item, index) => {
                          return (<div key={index}
                                       className="RRSSLogos">
                            <a href={item.link}
                               target="_blank"
                               rel="noopener noreferrer">
                              <div style={{
                                height: '36px',
                                width: '36px',
                                margin: 'auto',
                                cursor: 'pointer',
                                /* Center and scale the image nicely */
                                background: `url(${item.url}) no-repeat center`,
                                title: item.alt
                              }}
                              >
                              </div>
                            </a>
                          </div>)
                        })
                      }
                      {
                        rrssIconsSecondRow.map( (item, index) => {
                          return (<div key={index}
                                       className="RRSSLogos">
                            <a href={item.link} target="_blank"
                               rel="noopener noreferrer">
                              <div style={{
                                height: '36px',
                                width: '36px',
                                margin: 'auto',
                                cursor: 'pointer',
                                /* Center and scale the image nicely */
                                background: `url(${item.url}) no-repeat center`,
                                title: item.alt
                              }}>
                              </div>
                            </a>
                          </div>)
                        })
                      }
                    </div>
                    {/*<Grid container*/}
                          {/*className="rrssBig"*/}
                          {/*direction="row"*/}
                          {/*spacing={0}*/}
                          {/*alignItems="flex-start"*/}
                          {/*justify="center">*/}
                      {/*{*/}
                        {/*rrssIconsFirstRow.map( (item, index) => {*/}
                          {/*return (<Grid item sm={2} lg={1}>*/}
                            {/*<a href={item.link} target="_blank"
                     rel="noopener noreferrer">*/}
                              {/*<div style={{*/}
                                {/*height: '36px',*/}
                                {/*width: '36px',*/}
                                {/*margin: 'auto',*/}
                                {/*cursor: 'pointer',*/}
                                {/*background: `url(${item.url}) no-repeat center`,*/}
                              {/*}}*/}
                              {/*>*/}
                              {/*</div>*/}
                            {/*</a>*/}
                          {/*</Grid>)*/}
                        {/*})*/}
                      {/*}*/}
                      {/*{*/}
                        {/*rrssIconsSecondRow.map( (item, index) => {*/}
                          {/*return (<Grid item sm={2} lg={1}>*/}
                            {/*<a href={item.link} target="_blank"
                     rel="noopener noreferrer">*/}
                              {/*<div style={{*/}
                                {/*height: '36px',*/}
                                {/*width: '36px',*/}
                                {/*margin: 'auto',*/}
                                {/*cursor: 'pointer',*/}
                                {/*background: `url(${item.url}) no-repeat center`,*/}
                              {/*}}>*/}
                              {/*</div>*/}
                            {/*</a>*/}
                          {/*</Grid>)*/}
                        {/*})*/}
                      {/*}*/}
                    {/*</Grid>*/}
                  </div>
                </Grid>
                <Grid item
                      className="contactMenu"
                      style={{
                        paddingLeft: '1%',
                        height: '230px',
                      }}
                      sm={3} md={2}>
                  <Menu item_es={'Home'}
                        item_en={'Home'}
                        item_pt={'Home'}
                        link={'/'}/>
                  <Menu item_es={'Filosofía'}
                        item_en={'About'}
                        item_pt={'Quem Somos'}
                        link={'/filosofia'}/>
                  <Menu item_es={'Jugadoras'}
                        item_en={'Players'}
                        item_pt={'Jogadoras'}
                        link={'/players'}/>
                  <Menu item_es={'Marcas'}
                        item_en={'Brands'}
                        item_pt={'Marcas'}
                        link={'/brands'}/>
                  <Menu item_es={'Entidades Deportivas'}
                        item_en={'Sports Enterprises'}
                        item_pt={'Entidades Esportivas'}
                        link={'/sports-enterprises'}/>
                  <Menu item_es={'Contacto'}
                        item_en={'Contact us'}
                        item_pt={'Contato'}
                        link={'/contact-us'}/>
                </Grid>
                {/*<Grid item*/}
                      {/*className="followUsColumn"*/}
                      {/*md={2}>*/}
                  {/*<div className="followUsLabel">*/}
                    {/*FOLLOW US*/}
                  {/*</div>*/}
                  {/*<Grid container*/}
                        {/*direction="row"*/}
                        {/*spacing={0}*/}
                        {/*alignItems="flex-start"*/}
                        {/*justify="center">*/}
                    {/*{*/}
                      {/*rrssIconsFirstRow.map( (item, index) => {*/}
                        {/*return (<Grid item xs={3}>*/}
                          {/*<a href={item.link} target="_blank"
                     rel="noopener noreferrer">*/}
                            {/*<div style={{*/}
                              {/*height: '36px',*/}
                              {/*width: '36px',*/}
                              {/*margin: 'auto',*/}
                              {/*cursor: 'pointer',*/}
                              {/*background: `url(${item.url}) no-repeat center`,*/}
                            {/*}}>*/}
                            {/*</div>*/}
                          {/*</a>*/}
                        {/*</Grid>)*/}
                      {/*})*/}
                    {/*}*/}
                  {/*</Grid>*/}
                  {/*<Grid container*/}
                        {/*direction="row"*/}
                        {/*spacing={0}*/}
                        {/*alignItems="flex-start"*/}
                        {/*justify="center">*/}
                    {/*{*/}
                      {/*rrssIconsSecondRow.map( (item, index) => {*/}
                        {/*return (<Grid item xs={3}>*/}
                          {/*<a href={item.link} target="_blank"
                     rel="noopener noreferrer">*/}
                            {/*<div style={{*/}
                              {/*height: '36px',*/}
                              {/*width: '36px',*/}
                              {/*margin: 'auto',*/}
                              {/*cursor: 'pointer',*/}
                              {/*background: `url(${item.url}) no-repeat center`,*/}
                            {/*}}>*/}
                            {/*</div>*/}
                          {/*</a>*/}
                        {/*</Grid>)*/}
                      {/*})*/}
                    {/*}*/}
                  {/*</Grid>*/}
                {/*</Grid>*/}
                <Grid item
                      className="newsletterColumn"
                      xs={6} sm={5} md={5}>
                  <div className="newsletterLabel">
                    <div className="subscribeLabel">
                      {t('SIGN UP TO')}
                    </div>
                    {t('OUR NEWSLETTER!')}
                  </div>
                  {/*<div className="emailAddressLabel">*/}
                  {/*Dirección de email*/}
                  {/*</div>*/}
                  <Input className="emailAddressLabel"
                         placeholder={t('EMAIL ADDRESS')}
                         value={contactForm.from_email}
                         onChange={(e) => updateField('from_email', e)}
                         disableUnderline={true}>
                  </Input>
                  <div className="sendLabel"
                       onClick={addEmail}>
                    {t('Send')}
                  </div>
                </Grid>
              </Grid>
              {/*<Grid container*/}
                    {/*className="rrssSmall"*/}
                    {/*direction="row"*/}
                    {/*spacing={0}*/}
                    {/*alignItems="flex-start"*/}
                    {/*justify="center">*/}
                {/*{*/}
                  {/*rrssIconsFirstRow.map( (item, index) => {*/}
                    {/*return (<Grid item sm={1}>*/}
                      {/*<a href={item.link} target="_blank"
                     rel="noopener noreferrer">*/}
                        {/*<div style={{*/}
                          {/*height: '36px',*/}
                          {/*width: '36px',*/}
                          {/*margin: 'auto',*/}
                          {/*cursor: 'pointer',*/}
                          {/*background: `url(${item.url}) no-repeat center`,*/}
                        {/*}}*/}
                        {/*>*/}
                        {/*</div>*/}
                      {/*</a>*/}
                    {/*</Grid>)*/}
                  {/*})*/}
                {/*}*/}
                {/*{*/}
                  {/*rrssIconsSecondRow.map( (item, index) => {*/}
                    {/*return (<Grid item sm={1}>*/}
                      {/*<a href={item.link} target="_blank"
                     rel="noopener noreferrer">*/}
                        {/*<div style={{*/}
                          {/*height: '36px',*/}
                          {/*width: '36px',*/}
                          {/*margin: 'auto',*/}
                          {/*cursor: 'pointer',*/}
                          {/*background: `url(${item.url}) no-repeat center`,*/}
                        {/*}}>*/}
                        {/*</div>*/}
                      {/*</a>*/}
                    {/*</Grid>)*/}
                  {/*})*/}
                {/*}*/}
              {/*</Grid>*/}
            </div>
            <div className="finalRow">
              <Grid container
                    className="lastRowXSmall"
                    direction="row"
                    spacing={0}
                    alignItems="center"
                    justify="center">
                <Grid item xs={6} className="itemsRowSmall">
                  <div className="itemFinalRow"
                     onClick={() => {
                        history.push(`/privacy-policy`);
                        dispatch(setSelectedPage('/privacy-policy'));
                        window.scrollTo(0, 0);
                        dispatch(setSelectedPrivacyPolicy('privacy-policy'));
                    }}>
                    {t('PRIVACY POLICY')}
                  </div>
                  <p className="itemFinalRowNoBorder"
                     onClick={() => {
                      history.push(`/privacy-policy`);
                      dispatch(setSelectedPage('/privacy-policy'));
                      window.scrollTo(0, 0);
                      dispatch(setSelectedPrivacyPolicy('aviso-legal'));
                    }}>
                    {t('LEGAL WARNING')}
                  </p>
                </Grid>
                {/*<Grid item xs={6} className="itemsRowSmall">*/}
                  {/*<p className="itemFinalRow">*/}
                    {/*Política de Cookies*/}
                  {/*</p>*/}
                  {/*<p className="itemFinalRowNoBorder">*/}
                    {/*Política de privacidad a Redes Sociales*/}
                  {/*</p>*/}
                {/*</Grid>*/}
                {/*<Grid item xs={12}*/}
                      {/*className="copyrightLabelXSmall">*/}
                  {/*<p>*/}
                    {/*&copy; 2020 Unik. Todos los derechos reservados*/}
                  {/*</p>*/}
                {/*</Grid>*/}
              </Grid>
              <Grid container
                    className="lastRowSmall"
                    direction="row"
                    spacing={0}
                    alignItems="center"
                    justify="center">
                <Grid item xs={12} className="itemsRowSmall">
                  <div className="itemFinalRow"
                     onClick={() => {
                       history.push(`/privacy-policy`);
                       dispatch(setSelectedPage('/privacy-policy'));
                       window.scrollTo(0, 0);
                       dispatch(setSelectedPrivacyPolicy('privacy-policy'));
                     }}>
                    {t('PRIVACY POLICY')}
                  </div>
                  <p className="itemFinalRowNoBorder"
                     onClick={() => {
                       history.push(`/privacy-policy`);
                       dispatch(setSelectedPage('/privacy-policy'));
                       window.scrollTo(0, 0);
                       dispatch(setSelectedPrivacyPolicy('aviso-legal'));
                     }}>
                    {t('LEGAL WARNING')}
                  </p>
                  {/*<p className="itemFinalRow">*/}
                    {/*Política de Cookies*/}
                  {/*</p>*/}
                  {/*<p className="itemFinalRowNoBorder">*/}
                    {/*Política de privacidad a Redes Sociales*/}
                  {/*</p>*/}
                </Grid>
                <Grid item xs={12}
                      className="copyrightLabelSmall">
                  <p>
                    &copy; 2020 Unik. {t('ALL RIGHT RESERVED')}
                  </p>
                </Grid>
              </Grid>
              <Grid container
                    style={{
                      height: '100%'
                    }}
                    className="lastRowWeb"
                    direction="row"
                    spacing={0}
                    alignItems="center"
                    justify="center">
                <Grid item xs={4}
                      className="copyrightLabel">
                  <p>
                    &copy; 2020 Unik Sports Management. {t('ALL RIGHT RESERVED')}
                  </p>
                </Grid>
                <Grid item xs={8}
                      className="itemsRow">
                  <p className="itemFinalRow"
                     onClick={() => {
                        history.push(`/privacy-policy`);
                        dispatch(setSelectedPage('/privacy-policy'));
                        window.scrollTo(0, 0);
                        dispatch(setSelectedPrivacyPolicy('privacy-policy'));
                      }}>
                    {t('PRIVACY POLICY')}
                  </p>
                  <p className="itemFinalRowNoBorder"
                     onClick={() => {
                        history.push(`/privacy-policy`);
                        dispatch(setSelectedPage('/privacy-policy'));
                        window.scrollTo(0, 0);
                        dispatch(setSelectedPrivacyPolicy('aviso-legal'));
                      }}>
                    {t('LEGAL WARNING')}
                  </p>
                  {/*<p className="itemFinalRow">*/}
                    {/*Política de Cookies*/}
                  {/*</p>*/}
                  {/*<p className="itemFinalRowNoBorder">*/}
                    {/*Política de privacidad a Redes Sociales*/}
                  {/*</p>*/}
                </Grid>
              </Grid>
            </div>
          </div>:
          null
      }

      <div className={classes.root}>
        {
          (openSuccess) ?
            <Snackbar open={handleClickSuccess} autoHideDuration={4000} onClose={handleCloseSuccess}>
              <Alert onClose={handleCloseSuccess} severity="success">
                {t('MESSAGE SENT SUCCESSFULLY!')}
              </Alert>
            </Snackbar> : null
        }
        {
          (openError1) ?
            <Snackbar open={handleClickError1} autoHideDuration={4000} onClose={handleCloseError1}>
              <Alert onClose={handleCloseError1} severity="error">
                {t('INVALID EMAIL')}
              </Alert>
            </Snackbar> : null
        }
        {
          (openError2) ?
            <Snackbar open={handleClickError2} autoHideDuration={4000} onClose={handleCloseError2}>
              <Alert onClose={handleCloseError2} severity="error">
                {t('AN ERROR HAS OCCURRED...')}
              </Alert>
            </Snackbar> : null
        }
      </div>

    </div>
  )
};

export default Footer;