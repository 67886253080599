import React, { useState, useEffect } from 'react';
// import '../../App.css';
import './Info-logo-services.css'
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPage } from '../../../store/actions/selectedPage'
import { setSelectedService } from '../../../store/actions/selectedService'
import {useTranslation} from "react-i18next";

const InfoLogoServices = ({service}) => {
  const dispatch = useDispatch();
  const selectedPage = useSelector(state => state.selectedPage.selectedPage);

  const history = useHistory();
    const [lang, setLang] = useState('en');
    const [t, i18n] = useTranslation();

    useEffect(() => {
        setLang(i18n.languages[1]);
        console.log('player', i18n.languages[1]);
    }, [i18n.languages[1]]);

  return(
    <div className="squareService"
         onClick={() => {
           history.push(`/services`);
           dispatch(setSelectedPage('/services'));
           window.scrollTo(0, 0);
           dispatch(setSelectedService(service.link));
        }}>
      <div className="nonSelected"
           style={{
        /* Full height */
        width: '110px',
        height: '110px',
        margin: 'auto',
        /* Center and scale the image nicely */
        background: `url(${service.url}) no-repeat center`,
        title: service.alt
      }}/>
      <div className="selected"
           style={{
        /* Full height */
        width: '110px',
        height: '110px',
        margin: 'auto',
        /* Center and scale the image nicely */
        background: `url(${service.urlSelected}) no-repeat center`,
        title: service.altSelected
      }}/>
      <div className="selectedImage">
        <img className="selectedImagePhoto"
             src={service.urlSelected}
             alt={service.altSelected}/>
      </div>
      <div className="nonSelectedImage">
        <img className="nonSelectedImagePhoto"
             src={service.url}
             alt={service.alt}/>
      </div>

      {
        (service && service.serviceText1Small && service.serviceText2Small) ?
        <div className="infoRectanglePossibleSmall">
          <div className="labelRectangle">{service['serviceText1Small_'+lang]}</div>
          <div className="labelRectangle">{service['serviceText2Small_'+lang]}</div>
        </div> :
        <div className="infoRectangle">
          <div className="labelRectangle">{service['serviceText1_'+lang]}</div>
          <div className="labelRectangle">{service['serviceText2_'+lang]}</div>
        </div>
      }

    </div>
  )
};

export default InfoLogoServices;