import React, { useState, useEffect } from 'react';
import './Multiple-image-bar.css'
import Grid from '@material-ui/core/Grid';
import { clubsImages, collaborationImages, sponsorshipsImages } from "../../../data/home";

const leftArrow = require('../../../images/left_arrow.png');
const rightArrow = require('../../../images/right_arrow.png');
const blueLines = require('../../../images/dos_rayas.png');


const MultipleImageBar = ({title, row}) => {
  const [clubs, setClubs] = useState([...clubsImages]);
  const [sponsors, setSponsors] = useState([...sponsorshipsImages]);
  const [collaborations, setCollaborations] = useState([...collaborationImages]);
  const [images, setImages] = useState([]);
  const [numImages, setNumImages] = useState({
    clubsInitial: 0,
    clubsFinal: 6,
    sponsorInitial: 0,
    sponsorFinal: 6,
    collaborationInitial: 0,
    collaborationFinal: 6,
  });
  const [width, setWidth] = useState(0);
  const [numDependingWidth, setNumDependingWidth] = useState(6);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let wdth = width;
    if(wdth === 0) {
      wdth = window.innerWidth;
      setWidth(wdth);
    }
    if(wdth <= 960) {
      setNumDependingWidth(3);
      setNumImages({
        clubsInitial: 0,
        clubsFinal: 4,
        sponsorInitial: 0,
        sponsorFinal: 4,
        collaborationInitial: 0,
        collaborationFinal: 4,
      });
      if(row === 0) {
        setImages( () => clubs.filter((item) => item.id > 0 && item.id < 4));
      } else if(row === 1) {
        setImages( () => sponsorshipsImages.filter((item) => item.id > 0 && item.id < 4));
      } else if(row === 2) {
        setImages( () => collaborationImages.filter((item) => item.id > 0 && item.id < 4));
      }
    } else {
      setNumDependingWidth(5);
      setNumImages({
        clubsInitial: 0,
        clubsFinal: 6,
        sponsorInitial: 0,
        sponsorFinal: 6,
        collaborationInitial: 0,
        collaborationFinal: 6,
      });
      if(row === 0) {
        setImages( () => clubs.filter((item) => item.id > 0 && item.id < 6));
      } else if(row === 1) {
        setImages( () => sponsorshipsImages.filter((item) => item.id > 0 && item.id < 6));
      } else if(row === 2) {
        setImages( () => collaborationImages.filter((item) => item.id > 0 && item.id < 6));
      }
    }
  }, [width]);

  const handleResize = () => {
    const windowSize = window.innerWidth;
    setWidth(windowSize);
  };

  const goForward = (rowNum) => {
    if(rowNum === 0) {
      let initial = numImages.clubsInitial + 1;
      let final = numImages.clubsFinal + 1;
      let array = [...clubs, ...clubs];

      setNumImages((nums) => {
        nums.clubsInitial = initial;
        nums.clubsFinal = final;
        return nums;
      });
      if(numImages.clubsFinal > clubs.length) {
        setClubs(() => [...array]);
        setImages(() => array.filter((item, index) => index + 1 > initial && index + 1 < final));
      } else {
        setImages(() => array.filter((item, index) => index + 1 > initial && index + 1 < final));
      }
    } else if(rowNum === 1) {
      let initial = numImages.sponsorInitial + 1;
      let final = numImages.sponsorFinal + 1;
      let array = [...sponsors, ...sponsors];

      setNumImages((nums) => {
        nums.sponsorInitial = initial;
        nums.sponsorFinal = final;
        return nums;
      });
      if(numImages.sponsorFinal > sponsors.length) {
        setSponsors(() => [...array]);
        setImages(() => array.filter((item, index) => index + 1 > initial && index + 1 < final));
      } else {
        setImages(() => array.filter((item, index) => index + 1 > initial && index + 1 < final));
      }
    } else if(rowNum === 2) {
      let initial = numImages.collaborationInitial + 1;
      let final = numImages.collaborationFinal + 1;
      let array = [...collaborations, ...collaborations];
      setNumImages((nums) => {
        nums.collaborationInitial = initial;
        nums.collaborationFinal = final;
        return nums;
      });
      if(numImages.collaborationFinal > collaborations.length) {
        setCollaborations(() => [...array]);
        setImages(() => array.filter((item, index) => index + 1 > initial && index + 1 < final));
      } else {
        setImages(() => array.filter((item, index) => index + 1 > initial && index + 1 < final));
      }
    }
  };

  const goBackward = (rowNum) => {
    if(rowNum === 0) {
      let initial = numImages.clubsInitial - 1;
      let final = numImages.clubsFinal - 1;
      let array = [...clubs, ...clubs];

      if(initial < 0) {
        let newInitial = numImages.clubsInitial + clubs.length - 1;
        let newFinal = numImages.clubsFinal + clubs.length - 1;

        setClubs(() => [...array]);
        setNumImages((nums) => {
          nums.clubsInitial = newInitial;
          nums.clubsFinal = newFinal;
          return nums;
        });
        setImages(() => array.filter((item, index) => index + 1 > newInitial && index + 1 < newFinal));
      } else {
        setNumImages((nums) => {
          nums.clubsInitial = initial;
          nums.clubsFinal = final;
          return nums;
        });
        setImages(() => array.filter((item, index) => index + 1 > initial && index + 1 < final));
      }
    } else if(rowNum === 1) {
      let initial = numImages.sponsorInitial - 1;
      let final = numImages.sponsorFinal - 1;
      let array = [...sponsors, ...sponsors];

      if(initial < 0) {
        let newInitial = numImages.sponsorInitial + sponsors.length - 1;
        let newFinal = numImages.sponsorFinal + sponsors.length - 1;

        setSponsors(() => [...array]);
        setNumImages((nums) => {
          nums.sponsorInitial = newInitial;
          nums.sponsorFinal = newFinal;
          return nums;
        });
        setImages(() => array.filter((item, index) => index + 1 > newInitial && index +1 < newFinal));
      } else {
        setNumImages((nums) => {
          nums.sponsorInitial = initial;
          nums.sponsorFinal = final;
          return nums;
        });
        setImages(() => array.filter((item, index) => index + 1 > initial && index + 1 < final));
      }
    } else if(rowNum === 2) {
      let initial = numImages.collaborationInitial - 1;
      let final = numImages.collaborationFinal - 1;
      let array = [...collaborations, ...collaborations];

      if(initial < 0) {
        let newInitial = numImages.collaborationInitial + collaborations.length - 1;
        let newFinal = numImages.collaborationFinal + collaborations.length - 1;

        setCollaborations(() => [...array]);
        setNumImages((nums) => {
          nums.collaborationInitial = newInitial;
          nums.collaborationFinal = newFinal;
          return nums;
        });
        setImages(() => array.filter((item, index) => index + 1 > newInitial && index + 1 < newFinal));
      } else {
        setNumImages((nums) => {
          nums.collaborationInitial = initial;
          nums.collaborationFinal = final;
          return nums;
        });
        setImages(() => array.filter((item, index) => index + 1 > initial && index + 1 < final));
      }
    }
  };

  return(
    <div className="container">
      <div className="titleRow">
        <div className="linesDiv">
          <img className="lines"
               src={blueLines}
               alt='blue-lines' />
        </div>
        <div className="title">
          {title}
        </div>
      </div>
      <Grid container
            style={{
              backgroundColor: 'rgba(241, 241, 240, 0.1)'
            }}
            spacing={0}
            direction="row"
            alignItems="center"
            justify="center">
        <Grid item className="shield" xs={1}
              onClick={() => goBackward(row)}>
          <div className="shieldArrow"
               style={{
            /* Full height */
            width: '100%',
            height: '100%',
            margin: 'auto',
            cursor: 'pointer'
          }}>
            <img className="shieldArrowImg"
                 src={leftArrow}
                 alt="left-arrow"/>
          </div>
        </Grid>
        {
          (images && images.length !== 0) ?
          images.map( (item, index) => {
            return (<Grid key={index} item className="shield" xs={3} md={2} lg={2} xl={2}>
              <div className="shieldImageLogo"
                   style={{
                /* Full height */
                width: '100%',
                height: '100%',
                margin: 'auto',
              }}>
                <img className="shieldImageLogoImg"
                     src={item.url}
                     alt={item.alt}/>
              </div>
            </Grid>)
          }) : null
        }
        <Grid item className="shield" xs={1}
              onClick={() => goForward(row)}>
          <div className="shieldArrow"
               style={{
            /* Full height */
            width: '100%',
            height: '100%',
            margin: 'auto',
            cursor: 'pointer',
          }}>
            <img className="shieldArrowImg"
                 src={rightArrow}
                 alt="right-arrow"/>
          </div>
        </Grid>
      </Grid>
    </div>
  )
};

export default MultipleImageBar;