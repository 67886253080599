export const rrssIconsFirstRow = [{
  id: 1,
  url: require('../images/rrssIcons/instagram_icon.png'),
  alt: 'instagram_icon',
  club: 'Instagram',
  link: 'https://www.instagram.com/unik.team/'
}, {
  id: 2,
  url: require('../images/rrssIcons/twitter_icon.png'),
  alt: 'twitter_icon',
  club: 'Twitter',
  link: 'https://twitter.com/unikteam_'
}];
export const rrssIconsSecondRow = [{
  id: 1,
  url: require('../images/rrssIcons/linkedin_icon.png'),
  alt: 'linkedin_icon',
  club: 'Linkedin',
  link: 'https://www.linkedin.com/company/uniksportsmanagement/'
}, {
  id: 2,
  url: require('../images/rrssIcons/tiktok_icon.png'),
  alt: 'tiktok_icon',
  club: 'TikTok',
  link: 'https://vm.tiktok.com/Jeaf3Lm/'
}];
