import React, { useState, useEffect } from 'react';
import '../../App.css';
import './Players.css';

import Footer from "../footer/Footer";
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PlayerSquare from '../player-square/Player-square.js';
import PlayerSquareGrid from '../player-square-grid/Player-square-grid.js';
import DocumentMeta from 'react-document-meta';
import { allPlayers, teams, nationalities_es, nationalities_en, nationalities_pt, dates, leagues_es, leagues_en, leagues_pt } from '../../data/players';
import {useTranslation} from "react-i18next";

const blueLines = require('../../images/tres_rayas.png');
const teamIcon = require('../../images/icons/team_icon.png');
const calendarIcon = require('../../images/icons/calendar_icon.png');
const nationalityIcon = require('../../images/icons/nationality_icon.png');
const trophyIcon = require('../../images/icons/trophy_icon.png');
const campo = require('../../images/campo.png');
const greyPoint = require('../../images/greyPoint.png');
const bluePoint = require('../../images/bluePoint.png');

const segundaDelanteraLabel = require('../../images/positions-labels/2ª Delantera_Mesa de trabajo 1.png');
const centralDerLabel = require('../../images/positions-labels/Central Der_Mesa de trabajo 1.png');
const centralIzqLabel = require('../../images/positions-labels/Central Izq_Mesa de trabajo 1.png');
const delanteraLabel = require('../../images/positions-labels/Delantera_Mesa de trabajo 1.png');
const extremoDerLabel = require('../../images/positions-labels/Extremo Der_Mesa de trabajo 1.png');
const extremoIzqLabel = require('../../images/positions-labels/Extremo Izq_Mesa de trabajo 1.png');
const interiorDerLabel = require('../../images/positions-labels/Interior Der_Mesa de trabajo 1.png');
const interiorIzqLabel = require('../../images/positions-labels/Interior izq_Mesa de trabajo 1.png');
const lateralDerLabel = require('../../images/positions-labels/Lateral Der_Mesa de trabajo 1.png');
const lateralIzqLabel = require('../../images/positions-labels/Lateral izq_Mesa de trabajo 1.png');
const liberoLabel = require('../../images/positions-labels/Líbero_Mesa de trabajo 1.png');
const mcOfensivaLabel = require('../../images/positions-labels/MC Ofensiva_Mesa de trabajo 1.png');
const mcLabel = require('../../images/positions-labels/MC_Mesa de trabajo 1.png');
const mediaPuntaLabel = require('../../images/positions-labels/Media punta_Mesa de trabajo 1.png');
const porteraLabel = require('../../images/positions-labels/Portera_Mesa de trabajo 1.png');

const segundaDelanteraLabel_en = require('../../images/positions-labels/en/Forward_Mesa de trabajo 1.png');
const centralDerLabel_en = require('../../images/positions-labels/en/RCB_Mesa de trabajo 1.png');
const centralIzqLabel_en = require('../../images/positions-labels/en/LCB_Mesa de trabajo 1.png');
const delanteraLabel_en = require('../../images/positions-labels/en/Striker_Mesa de trabajo 1.png');
const extremoDerLabel_en = require('../../images/positions-labels/en/Right Wing_Mesa de trabajo 1.png');
const extremoIzqLabel_en = require('../../images/positions-labels/en/Left Wing_Mesa de trabajo 1.png');
const interiorDerLabel_en = require('../../images/positions-labels/en/RM_Mesa de trabajo 1.png');
const interiorIzqLabel_en = require('../../images/positions-labels/en/LM_Mesa de trabajo 1.png');
const lateralDerLabel_en = require('../../images/positions-labels/en/Right Back_Mesa de trabajo 1.png');
const lateralIzqLabel_en = require('../../images/positions-labels/en/Left Back_Mesa de trabajo 1.png');
const liberoLabel_en = require('../../images/positions-labels/en/Center Back_Mesa de trabajo 1.png');
const mcOfensivaLabel_en = require('../../images/positions-labels/en/Midfielder_Mesa de trabajo 1.png');
const mcLabel_en = require('../../images/positions-labels/en/Sweeper_Mesa de trabajo 1.png');
const mediaPuntaLabel_en = require('../../images/positions-labels/en/CAM_Mesa de trabajo 1.png');
const porteraLabel_en = require('../../images/positions-labels/en/Goalkeeper_Mesa de trabajo 1.png');

const segundaDelanteraLabel_pt = require('../../images/positions-labels/pt/Ponta.png');
const centralDerLabel_pt = require('../../images/positions-labels/pt/Zagueira-Dir.png');
const centralIzqLabel_pt = require('../../images/positions-labels/pt/Zagueira-Esq.png');
const delanteraLabel_pt = require('../../images/positions-labels/pt/Atacante.png');
const extremoDerLabel_pt = require('../../images/positions-labels/pt/Ala-dir.png');
const extremoIzqLabel_pt = require('../../images/positions-labels/pt/Ala-esq.png');
const interiorDerLabel_pt = require('../../images/positions-labels/pt/Meia-dir.png');
const interiorIzqLabel_pt = require('../../images/positions-labels/pt/Meia-Esq.png');
const lateralDerLabel_pt = require('../../images/positions-labels/pt/Lateral-dir.png');
const lateralIzqLabel_pt = require('../../images/positions-labels/pt/Lateral-esq.png');
const liberoLabel_pt = require('../../images/positions-labels/pt/Libero.png');
const mcOfensivaLabel_pt = require('../../images/positions-labels/pt/MC-Ofensiva.png');
const mcLabel_pt = require('../../images/positions-labels/pt/MC.png');
const mediaPuntaLabel_pt = require('../../images/positions-labels/pt/Centroavante.png');
const porteraLabel_pt = require('../../images/positions-labels/pt/Goleira.png');

const Players = () => {
  const [positionSelected, setPositionSelected] = useState('Todas');
  const [team, setTeam] = useState('');
  const [birth, setBirth] = useState([]);
  const [league, setLeague] = useState('');
  const [globalPositionFilter, setGlobalPositionFilter] = useState(0);
  const [nationality, setNationality] = useState('');
  const [searched, setSearched] = useState([]);
  const [positionsLabel, setPositionsLabel] = useState([false, false,
    false, false, false, false, false, false, false, false,
    false, false, false, false, false]);
  const [positions, setPositions] = useState([false, false,
    false, false, false, false, false, false, false, false, false,
    false, false, false, false]);
  const meta = {
    title: 'UNIK SPORTS MANAGEMENT | Jugadoras de fútbol femenino',
    description: 'Representamos talento de talla mundial, como Esther González, Francisca Ordega, Nayeli Rangel, Tatiana Pinto, Noelia Bermúdez y Fabiana Simões.',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  };

  const [nationalities, setNationalities] = useState([]);
  const [leagues, setLeagues] = useState([]);

  const [lang, setLang] = useState('en');
  const [t, i18n] = useTranslation();

  useEffect(() => {
    setLang(i18n.languages[1]);
    if(i18n.languages[1] === 'es') {
      setNationalities(nationalities_es);
      setLeagues(leagues_es);
    } else if(i18n.languages[1] === 'en') {
      setNationalities(nationalities_en);
      setLeagues(leagues_en);
    } else if(i18n.languages[1] === 'pt') {
      setNationalities(nationalities_pt);
      setLeagues(leagues_pt);
    }
    setNationality('');
    let allPlayersSort = allPlayers.sort(function(a, b){
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    });
    setSearched(() => [...allPlayersSort]);

    console.log('player', i18n.languages[1]);
  }, [i18n.languages[1]]);

  useEffect(() => {
    let allPlayersSort = allPlayers.sort(function(a, b){
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    });
    setSearched(() => [...allPlayersSort]);
    console.log(allPlayersSort.length)
  }, []);

  const filters = () => {
    setSearched(() => {
      let plyrs = allPlayers.sort(function(a, b){
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
      });

      let plyrsFilter = plyrs.filter((plyr, index) => {
        if(team && team !== "" && nationality && nationality !== "" && league && league !== "") {
          return (plyr.team === team && plyr['nationality_'+lang] === nationality && plyr['league_'+lang] === league)
        } else if(team && team !== "" && nationality && nationality !== "" && (!league || league === "")) {
          return (plyr.team === team && plyr['nationality_'+lang] === nationality && plyr['league_'+lang] !== '')
        } else if(team && team !== "" && (!nationality || nationality === "") && league && league !== "") {
          return (plyr.team === team && plyr['nationality_'+lang] !== '' && plyr['league_'+lang] === league)
        } else if(team && team !== "" && (!nationality || nationality === "") && (!league || league === "")) {
          return (plyr.team === team && plyr['nationality_'+lang] !== '' && plyr['league_'+lang] !== '')
        } else if((!team || team === "") && nationality && nationality !== "" && (!league || league === "")) {
          return (plyr.team !== '' && plyr['nationality_'+lang] === nationality && plyr['league_'+lang] !== '')
        } else if((!team || team === "") && nationality && nationality !== "" && league && league !== "") {
          return (plyr.team !== '' && plyr['nationality_'+lang] === nationality && plyr['league_'+lang] === league)
        } else if((!team || team === "") && (!nationality || nationality === "") && league && league !== "") {
          return (plyr.team !== '' && plyr['nationality_'+lang] !== '' && plyr['league_'+lang] === league)
        } else if((!team || team === "") && (!nationality || nationality === "") && (!league || league === "")) {
          return (plyr.team !== '' && plyr['nationality_'+lang] !== '' && plyr['league_'+lang] !== '')
        }
      });

      let plyrsSecondFilter = [];
      if(birth.length > 0) {
        plyrsFilter.forEach((plyr, index) => {
          birth.forEach((brth, ind) => {
            if(plyr.birthYear == brth) {
              plyrsSecondFilter.push(plyr);
            }
          });
        });
      } else {
        plyrsSecondFilter = plyrsFilter
      }

      let lastFilter = [];

      if(!positions[0] && !positions[1] && !positions[2] && !positions[3] && !positions[4] && !positions[5] &&
        !positions[6] && !positions[7] && !positions[8] && !positions[9] && !positions[10] && !positions[11] &&
        !positions[12] && !positions[13] && !positions[14]) {
        lastFilter = plyrsSecondFilter;
      } else {
        plyrsSecondFilter.forEach((plyr, index) => {
          plyr.positions.forEach((item, ind) => {
            if(item.selected && positions[ind] && item.selected === positions[ind]) {
              if(lastFilter.length === 0 || lastFilter[lastFilter.length-1].id !== plyr.id) {
                lastFilter.push(plyr);
              }
            }
            if(plyrsFilter.length-1 === index && plyr.positions.length -1 === ind) {
              console.log(lastFilter);
            }
          });
        });
      }

      return(lastFilter)
    });
    let offsetTop  = document.getElementById('firstBlueRow').offsetTop;
    window.scrollTo({
      top: offsetTop,
      behavior: "smooth"
    });
    setGlobalPositionFilter(null);
  };

  const teamChange = (event) => {
    setTeam(event.target.value);
  };
  const leagueChange = (event) => {
    setLeague(event.target.value);
  };
  const nationalityChange = (event) => {
    setNationality(event.target.value);
  };

  const yearChange = (event) => {
    setBirth(event.target.value);
  };

  const selectOrNotPosition = (num) => {
    positions[num] = !positions[num];
    if(positions[num]) {
      setPositionsLabel(pstns => {
        pstns[num] = true;
        return [...pstns];
      });
      setInterval(() => {
        setPositionsLabel(pstns => {
          pstns[num] = false;
          return [...pstns];
        });
      }, 3000)
    } else {
      setPositionsLabel(pstns => {
        pstns[num] = false;
        return [...pstns];
      });
    }
  };

  const globalFilters = (position) => {
    let allPlayersSort = allPlayers.sort(function(a, b){
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    });
    if(position === 0) {
      setSearched([...allPlayersSort]);
      setGlobalPositionFilter(0);
    } else if(position === 1) {
      setSearched(() => {
        if(lang === 'es') {
          return allPlayersSort.filter((item, index) => item['mainPosition_'+lang] === 'PORTERA')
        } else if(lang === 'en') {
          return allPlayersSort.filter((item, index) => item['mainPosition_'+lang] === 'GOALKEEPER')
        } else if(lang === 'pt') {
          return allPlayersSort.filter((item, index) => item['mainPosition_'+lang] === 'GOLEIRA')
        }
      });
      setGlobalPositionFilter(1);
    } else if(position === 2) {
      setSearched(() => {
        if(lang === 'es') {
          return allPlayersSort.filter((item, index) => item['mainPosition_'+lang] === 'DEFENSA')
        } else if(lang === 'en') {
          return allPlayersSort.filter((item, index) => item['mainPosition_'+lang] === 'DEFENDER')
        } else if(lang === 'pt') {
          return allPlayersSort.filter((item, index) => item['mainPosition_'+lang] === 'DEFESA')
        }
      });
      setGlobalPositionFilter(2);
    } else if(position === 3) {
      setSearched(() => {
        if(lang === 'es') {
          return allPlayersSort.filter((item, index) => item['mainPosition_'+lang] === 'CENTROCAMPISTA')
        } else if(lang === 'en') {
          return allPlayersSort.filter((item, index) => item['mainPosition_'+lang] === 'MIDFIELDER')
        } else if(lang === 'pt') {
          return allPlayersSort.filter((item, index) => item['mainPosition_'+lang] === 'MEIO-CAMPISTA')
        }
      });
      setGlobalPositionFilter(3);
    } else if(position === 4) {
      setSearched(() => {
        if(lang === 'es') {
          return allPlayersSort.filter((item, index) => item['mainPosition_'+lang] === 'DELANTERA')
        } else if(lang === 'en') {
          return allPlayersSort.filter((item, index) => item['mainPosition_'+lang] === 'FORWARD')
        } else if(lang === 'pt') {
          return allPlayersSort.filter((item, index) => item['mainPosition_'+lang] === 'ATACANTE')
        }
      });
      setGlobalPositionFilter(4);
    }
    setTeam('');
    setBirth([]);
    setLeague('');
    setNationality('');
    setPositions([false, false,
      false, false, false, false, false, false, false, false, false,
      false, false, false, false]);
  };

  return(
    <DocumentMeta {...meta}>
      <div className="playersDiv">
        <div className="unikRow">
          <div className="threeLines">
            <img className="lines"
                 src={blueLines}
                 alt='blue lines' />
          </div>
          <div className="unikLabel">
            UNIK TEAM
          </div>
        </div>
        <div className="unikRowSmall">
          <div className="unikThreeLinesSmall">
            <img className="lines"
                 src={blueLines}
                 alt='blue lines'/>
          </div>
          <div className="unikLabelSmall">
            UNIK TEAM
          </div>
        </div>

        <div className="squareFilters">
          <Grid container
                className="gridFiltersFullSmall"
                spacing={10}
                direction="row"
                alignItems="flex-start"
                justify="flex-start">

            <Grid item xs={12} sm={6} md={4} lg={3} xl={3}
                  className="positionFilterSmall">
              <div className="titleFilter">
                  {t('POSITION')}
              </div>
              <div className="descriptionFilter">
                  {t('POSITION-DESC')}
              </div>
              {
                (lang === 'es') ?
                    <div style={{
                      width: '270px',
                      height: '400px',
                      marginTop: '10px',
                      position: 'absolute',
                      background: `url(${campo}) no-repeat center`,
                    }}>
                      {
                        (positions[14]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(14)}
                                 id="pointOneBlue" className="selectedPosition" style={{
                              position: 'relative',
                              top: 18,
                              left: 155
                            }}/> : <img src={greyPoint}
                                        alt='grey-point'
                                        onClick={() => selectOrNotPosition(14)}
                                        id="pointOne" className="nonSelectedPosition"
                                        style={{
                                          position: 'relative',
                                          top: 18,
                                          left: 155
                                        }}/>
                      }
                      {
                        (positionsLabel[14]) ?
                            <img src={delanteraLabel}
                                 alt='position-description'
                                 id="labelOne" className="labelSelected" style={{
                              position: 'absolute',
                              top: 30,
                              left: 109
                            }}/> : null
                      }

                      {
                        (positions[13]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(13)}
                                 id="pointTwoBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 35,
                                   left: 82
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(13)}
                                             id="pointTwo" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 35,
                                               left: 82
                                             }}/>
                      }
                      {
                        (positionsLabel[13]) ?
                            <img src={segundaDelanteraLabel}
                                 alt='position-description'
                                 id="labelTwo" className="labelSelected" style={{
                              position: 'absolute',
                              top: 47,
                              left: 53
                            }}/> : null
                      }

                      {
                        (positions[12]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(12)}
                                 id="pointThreeBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 65,
                                   left: 190
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(12)}
                                             id="pointThree" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 65,
                                               left: 190
                                             }}/>
                      }
                      {
                        (positionsLabel[12]) ?
                            <img src={extremoDerLabel}
                                 alt='position-description'
                                 id="labelThree" className="labelSelected" style={{
                              position: 'absolute',
                              top: 77,
                              left: 178
                            }}/> : null
                      }

                      {
                        (positions[11]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(11)}
                                 id="pointFourBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 65,
                                   left: -20
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(11)}
                                             id="pointFour" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 65,
                                               left: -20
                                             }}/>
                      }
                      {
                        (positionsLabel[11]) ?
                            <img src={extremoIzqLabel}
                                 alt='position-description'
                                 id="labelFour" className="labelSelected" style={{
                              position: 'absolute',
                              top: 77,
                              left: -15
                            }}/> : null
                      }

                      {
                        (positions[10]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(10)}
                                 id="pointFifthBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 103,
                                   left: 60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(10)}
                                             id="pointFive" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 103,
                                               left: 60
                                             }}/>
                      }
                      {
                        (positionsLabel[10]) ?
                            <img src={mediaPuntaLabel}
                                 alt='position-description'
                                 id="labelFive" className="labelSelected" style={{
                              position: 'absolute',
                              top: 115,
                              left: 82
                            }}/> : null
                      }

                      {
                        (positions[9]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(9)}
                                 id="pointSixBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 130,
                                   left: 130
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(9)}
                                             id="pointSix" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 130,
                                               left: 130
                                             }}/>
                      }
                      {
                        (positionsLabel[9]) ?
                            <img src={interiorDerLabel}
                                 alt='position-description'
                                 id="labelSix" className="labelSelected" style={{
                              position: 'absolute',
                              top: 142,
                              left: 169
                            }}/> : null
                      }

                      {
                        (positions[8]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(8)}
                                 id="pointSevenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 130,
                                   left: -60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(8)}
                                             id="pointSeven" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 130,
                                               left: -60
                                             }}/>
                      }
                      {
                        (positionsLabel[8]) ?
                            <img src={interiorIzqLabel}
                                 alt='position-description'
                                 id="labelSeven" className="labelSelected" style={{
                              position: 'absolute',
                              top: 142,
                              left: -4
                            }}/> : null
                      }

                      {
                        (positions[7]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(7)}
                                 id="pointEightBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 163,
                                   left: 10
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(7)}
                                             id="pointEight" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 163,
                                               left: 10
                                             }}/>
                      }
                      {
                        (positionsLabel[7]) ?
                            <img src={mcOfensivaLabel}
                                 alt='position-description'
                                 id="labelEight" className="labelSelected" style={{
                              position: 'absolute',
                              top: 174,
                              left: 83
                            }}/> : null
                      }

                      {
                        (positions[6]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(6)}
                                 id="pointNineBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 220,
                                   left: -7
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(6)}
                                             id="pointNine" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 220,
                                               left: -7
                                             }}/>
                      }
                      {
                        (positionsLabel[6]) ?
                            <img src={mcLabel}
                                 alt='position-description'
                                 id="labelNine" className="labelSelected" style={{
                              position: 'absolute',
                              top: 232,
                              left: 83
                            }}/> : null
                      }

                      {
                        (positions[5]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(5)}
                                 id="pointTenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: -25
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(5)}
                                             id="pointTen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: -25
                                             }}/>
                      }
                      {
                        (positionsLabel[5]) ?
                            <img src={liberoLabel}
                                 alt='position-description'
                                 id="labelTen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: 82
                            }}/> : null
                      }

                      {
                        (positions[1]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(1)}
                                 id="pointElevenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: -140
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(1)}
                                             id="pointEleven" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: -140
                                             }}/>
                      }
                      {
                        (positionsLabel[1]) ?
                            <img src={lateralIzqLabel}
                                 alt='position-description'
                                 id="labelEleven" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: -16
                            }}/> : null
                      }

                      {
                        (positions[4]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(4)}
                                 id="pointTwelveBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: 35
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(4)}
                                             id="pointTwelve" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: 35
                                             }}/>
                      }
                      {
                        (positionsLabel[4]) ?
                            <img src={lateralDerLabel}
                                 alt='position-description'
                                 id="labelTwelve" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: 176
                            }}/> : null
                      }

                      {
                        (positions[2]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(2)}
                                 id="pointThirteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 315,
                                   left: -110
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(2)}
                                             id="pointThirteen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 315,
                                               left: -110
                                             }}/>
                      }
                      {
                        (positionsLabel[2]) ?
                            <img src={centralIzqLabel}
                                 alt='position-description'
                                 id="labelThirteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 328,
                              left: 48
                            }}/> : null
                      }

                      {
                        (positions[3]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(3)}
                                 id="pointFourteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 315,
                                   left: -60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(3)}
                                             id="pointFourteen"  className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 315,
                                               left: -60
                                             }}/>
                      }
                      {
                        (positionsLabel[3]) ?
                            <img src={centralDerLabel}
                                 alt='position-description'
                                 id="labelFourteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 328,
                              left: 115
                            }}/> : null
                      }

                      {
                        (positions[0]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(0)}
                                 id="pointFifteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 365,
                                   left: -111
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(0)}
                                             id="pointFifteen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 365,
                                               left: -111
                                             }}/>
                      }
                      {
                        (positionsLabel[0]) ?
                            <img src={porteraLabel}
                                 alt='position-description'
                                 id="labelFifteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 377,
                              left: 81
                            }}/> : null
                      }
                    </div> : null
              }

              {
                (lang === 'en') ?
                    <div style={{
                      width: '270px',
                      height: '400px',
                      marginTop: '10px',
                      position: 'absolute',
                      background: `url(${campo}) no-repeat center`,
                    }}>
                      {
                        (positions[14]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(14)}
                                 id="pointOneBlue" className="selectedPosition" style={{
                              position: 'relative',
                              top: 18,
                              left: 155
                            }}/> : <img src={greyPoint}
                                        alt='grey-point'
                                        onClick={() => selectOrNotPosition(14)}
                                        id="pointOne" className="nonSelectedPosition"
                                        style={{
                                          position: 'relative',
                                          top: 18,
                                          left: 155
                                        }}/>
                      }
                      {
                        (positionsLabel[14]) ?
                            <img src={delanteraLabel_en}
                                 alt='position-description'
                                 id="labelOne" className="labelSelected" style={{
                              position: 'absolute',
                              top: 30,
                              left: 109
                            }}/> : null
                      }

                      {
                        (positions[13]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(13)}
                                 id="pointTwoBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 35,
                                   left: 82
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(13)}
                                             id="pointTwo" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 35,
                                               left: 82
                                             }}/>
                      }
                      {
                        (positionsLabel[13]) ?
                            <img src={segundaDelanteraLabel_en}
                                 alt='position-description'
                                 id="labelTwo" className="labelSelected" style={{
                              position: 'absolute',
                              top: 47,
                              left: 53
                            }}/> : null
                      }

                      {
                        (positions[12]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(12)}
                                 id="pointThreeBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 65,
                                   left: 190
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(12)}
                                             id="pointThree" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 65,
                                               left: 190
                                             }}/>
                      }
                      {
                        (positionsLabel[12]) ?
                            <img src={extremoDerLabel_en}
                                 alt='position-description'
                                 id="labelThree" className="labelSelected" style={{
                              position: 'absolute',
                              top: 77,
                              left: 178
                            }}/> : null
                      }

                      {
                        (positions[11]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(11)}
                                 id="pointFourBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 65,
                                   left: -20
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(11)}
                                             id="pointFour" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 65,
                                               left: -20
                                             }}/>
                      }
                      {
                        (positionsLabel[11]) ?
                            <img src={extremoIzqLabel_en}
                                 alt='position-description'
                                 id="labelFour" className="labelSelected" style={{
                              position: 'absolute',
                              top: 77,
                              left: -15
                            }}/> : null
                      }

                      {
                        (positions[10]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(10)}
                                 id="pointFifthBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 103,
                                   left: 60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(10)}
                                             id="pointFive" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 103,
                                               left: 60
                                             }}/>
                      }
                      {
                        (positionsLabel[10]) ?
                            <img src={mediaPuntaLabel_en}
                                 alt='position-description'
                                 id="labelFive" className="labelSelected" style={{
                              position: 'absolute',
                              top: 115,
                              left: 82
                            }}/> : null
                      }

                      {
                        (positions[9]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(9)}
                                 id="pointSixBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 130,
                                   left: 130
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(9)}
                                             id="pointSix" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 130,
                                               left: 130
                                             }}/>
                      }
                      {
                        (positionsLabel[9]) ?
                            <img src={interiorDerLabel_en}
                                 alt='position-description'
                                 id="labelSix" className="labelSelected" style={{
                              position: 'absolute',
                              top: 142,
                              left: 169
                            }}/> : null
                      }

                      {
                        (positions[8]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(8)}
                                 id="pointSevenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 130,
                                   left: -60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(8)}
                                             id="pointSeven" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 130,
                                               left: -60
                                             }}/>
                      }
                      {
                        (positionsLabel[8]) ?
                            <img src={interiorIzqLabel_en}
                                 alt='position-description'
                                 id="labelSeven" className="labelSelected" style={{
                              position: 'absolute',
                              top: 142,
                              left: -4
                            }}/> : null
                      }

                      {
                        (positions[7]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(7)}
                                 id="pointEightBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 163,
                                   left: 10
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(7)}
                                             id="pointEight" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 163,
                                               left: 10
                                             }}/>
                      }
                      {
                        (positionsLabel[7]) ?
                            <img src={mcOfensivaLabel_en}
                                 alt='position-description'
                                 id="labelEight" className="labelSelected" style={{
                              position: 'absolute',
                              top: 174,
                              left: 83
                            }}/> : null
                      }

                      {
                        (positions[6]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(6)}
                                 id="pointNineBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 220,
                                   left: -7
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(6)}
                                             id="pointNine" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 220,
                                               left: -7
                                             }}/>
                      }
                      {
                        (positionsLabel[6]) ?
                            <img src={mcLabel_en}
                                 alt='position-description'
                                 id="labelNine" className="labelSelected" style={{
                              position: 'absolute',
                              top: 232,
                              left: 83
                            }}/> : null
                      }

                      {
                        (positions[5]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(5)}
                                 id="pointTenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: -25
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(5)}
                                             id="pointTen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: -25
                                             }}/>
                      }
                      {
                        (positionsLabel[5]) ?
                            <img src={liberoLabel_en}
                                 alt='position-description'
                                 id="labelTen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: 82
                            }}/> : null
                      }

                      {
                        (positions[1]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(1)}
                                 id="pointElevenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: -140
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(1)}
                                             id="pointEleven" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: -140
                                             }}/>
                      }
                      {
                        (positionsLabel[1]) ?
                            <img src={lateralIzqLabel_en}
                                 alt='position-description'
                                 id="labelEleven" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: -16
                            }}/> : null
                      }

                      {
                        (positions[4]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(4)}
                                 id="pointTwelveBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: 35
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(4)}
                                             id="pointTwelve" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: 35
                                             }}/>
                      }
                      {
                        (positionsLabel[4]) ?
                            <img src={lateralDerLabel_en}
                                 alt='position-description'
                                 id="labelTwelve" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: 176
                            }}/> : null
                      }

                      {
                        (positions[2]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(2)}
                                 id="pointThirteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 315,
                                   left: -110
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(2)}
                                             id="pointThirteen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 315,
                                               left: -110
                                             }}/>
                      }
                      {
                        (positionsLabel[2]) ?
                            <img src={centralIzqLabel_en}
                                 alt='position-description'
                                 id="labelThirteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 328,
                              left: 48
                            }}/> : null
                      }

                      {
                        (positions[3]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(3)}
                                 id="pointFourteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 315,
                                   left: -60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(3)}
                                             id="pointFourteen"  className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 315,
                                               left: -60
                                             }}/>
                      }
                      {
                        (positionsLabel[3]) ?
                            <img src={centralDerLabel_en}
                                 alt='position-description'
                                 id="labelFourteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 328,
                              left: 115
                            }}/> : null
                      }

                      {
                        (positions[0]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(0)}
                                 id="pointFifteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 365,
                                   left: -111
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(0)}
                                             id="pointFifteen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 365,
                                               left: -111
                                             }}/>
                      }
                      {
                        (positionsLabel[0]) ?
                            <img src={porteraLabel_en}
                                 alt='position-description'
                                 id="labelFifteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 377,
                              left: 81
                            }}/> : null
                      }
                    </div> : null
              }
              {
                (lang === 'pt') ?
                    <div style={{
                      width: '270px',
                      height: '400px',
                      marginTop: '10px',
                      position: 'absolute',
                      background: `url(${campo}) no-repeat center`,
                    }}>
                      {
                        (positions[14]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(14)}
                                 id="pointOneBlue" className="selectedPosition" style={{
                              position: 'relative',
                              top: 18,
                              left: 155
                            }}/> : <img src={greyPoint}
                                        alt='grey-point'
                                        onClick={() => selectOrNotPosition(14)}
                                        id="pointOne" className="nonSelectedPosition"
                                        style={{
                                          position: 'relative',
                                          top: 18,
                                          left: 155
                                        }}/>
                      }
                      {
                        (positionsLabel[14]) ?
                            <img src={delanteraLabel_pt}
                                 alt='position-description'
                                 id="labelOne" className="labelSelected" style={{
                              position: 'absolute',
                              top: 30,
                              left: 109
                            }}/> : null
                      }

                      {
                        (positions[13]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(13)}
                                 id="pointTwoBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 35,
                                   left: 82
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(13)}
                                             id="pointTwo" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 35,
                                               left: 82
                                             }}/>
                      }
                      {
                        (positionsLabel[13]) ?
                            <img src={segundaDelanteraLabel_pt}
                                 alt='position-description'
                                 id="labelTwo" className="labelSelected" style={{
                              position: 'absolute',
                              top: 47,
                              left: 53
                            }}/> : null
                      }

                      {
                        (positions[12]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(12)}
                                 id="pointThreeBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 65,
                                   left: 190
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(12)}
                                             id="pointThree" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 65,
                                               left: 190
                                             }}/>
                      }
                      {
                        (positionsLabel[12]) ?
                            <img src={extremoDerLabel_pt}
                                 alt='position-description'
                                 id="labelThree" className="labelSelected" style={{
                              position: 'absolute',
                              top: 77,
                              left: 178
                            }}/> : null
                      }

                      {
                        (positions[11]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(11)}
                                 id="pointFourBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 65,
                                   left: -20
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(11)}
                                             id="pointFour" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 65,
                                               left: -20
                                             }}/>
                      }
                      {
                        (positionsLabel[11]) ?
                            <img src={extremoIzqLabel_pt}
                                 alt='position-description'
                                 id="labelFour" className="labelSelected" style={{
                              position: 'absolute',
                              top: 77,
                              left: -15
                            }}/> : null
                      }

                      {
                        (positions[10]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(10)}
                                 id="pointFifthBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 103,
                                   left: 60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(10)}
                                             id="pointFive" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 103,
                                               left: 60
                                             }}/>
                      }
                      {
                        (positionsLabel[10]) ?
                            <img src={mediaPuntaLabel_pt}
                                 alt='position-description'
                                 id="labelFive" className="labelSelected" style={{
                              position: 'absolute',
                              top: 115,
                              left: 82
                            }}/> : null
                      }

                      {
                        (positions[9]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(9)}
                                 id="pointSixBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 130,
                                   left: 130
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(9)}
                                             id="pointSix" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 130,
                                               left: 130
                                             }}/>
                      }
                      {
                        (positionsLabel[9]) ?
                            <img src={interiorDerLabel_pt}
                                 alt='position-description'
                                 id="labelSix" className="labelSelected" style={{
                              position: 'absolute',
                              top: 142,
                              left: 169
                            }}/> : null
                      }

                      {
                        (positions[8]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(8)}
                                 id="pointSevenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 130,
                                   left: -60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(8)}
                                             id="pointSeven" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 130,
                                               left: -60
                                             }}/>
                      }
                      {
                        (positionsLabel[8]) ?
                            <img src={interiorIzqLabel_pt}
                                 alt='position-description'
                                 id="labelSeven" className="labelSelected" style={{
                              position: 'absolute',
                              top: 142,
                              left: -4
                            }}/> : null
                      }

                      {
                        (positions[7]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(7)}
                                 id="pointEightBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 163,
                                   left: 10
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(7)}
                                             id="pointEight" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 163,
                                               left: 10
                                             }}/>
                      }
                      {
                        (positionsLabel[7]) ?
                            <img src={mcOfensivaLabel_pt}
                                 alt='position-description'
                                 id="labelEight" className="labelSelected" style={{
                              position: 'absolute',
                              top: 174,
                              left: 83
                            }}/> : null
                      }

                      {
                        (positions[6]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(6)}
                                 id="pointNineBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 220,
                                   left: -7
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(6)}
                                             id="pointNine" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 220,
                                               left: -7
                                             }}/>
                      }
                      {
                        (positionsLabel[6]) ?
                            <img src={mcLabel_pt}
                                 alt='position-description'
                                 id="labelNine" className="labelSelected" style={{
                              position: 'absolute',
                              top: 232,
                              left: 83
                            }}/> : null
                      }

                      {
                        (positions[5]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(5)}
                                 id="pointTenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: -25
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(5)}
                                             id="pointTen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: -25
                                             }}/>
                      }
                      {
                        (positionsLabel[5]) ?
                            <img src={liberoLabel_pt}
                                 alt='position-description'
                                 id="labelTen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: 82
                            }}/> : null
                      }

                      {
                        (positions[1]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(1)}
                                 id="pointElevenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: -140
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(1)}
                                             id="pointEleven" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: -140
                                             }}/>
                      }
                      {
                        (positionsLabel[1]) ?
                            <img src={lateralIzqLabel_pt}
                                 alt='position-description'
                                 id="labelEleven" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: -16
                            }}/> : null
                      }

                      {
                        (positions[4]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(4)}
                                 id="pointTwelveBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: 35
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(4)}
                                             id="pointTwelve" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: 35
                                             }}/>
                      }
                      {
                        (positionsLabel[4]) ?
                            <img src={lateralDerLabel_pt}
                                 alt='position-description'
                                 id="labelTwelve" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: 176
                            }}/> : null
                      }

                      {
                        (positions[2]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(2)}
                                 id="pointThirteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 315,
                                   left: -110
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(2)}
                                             id="pointThirteen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 315,
                                               left: -110
                                             }}/>
                      }
                      {
                        (positionsLabel[2]) ?
                            <img src={centralIzqLabel_pt}
                                 alt='position-description'
                                 id="labelThirteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 328,
                              left: 48
                            }}/> : null
                      }

                      {
                        (positions[3]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(3)}
                                 id="pointFourteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 315,
                                   left: -60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(3)}
                                             id="pointFourteen"  className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 315,
                                               left: -60
                                             }}/>
                      }
                      {
                        (positionsLabel[3]) ?
                            <img src={centralDerLabel_pt}
                                 alt='position-description'
                                 id="labelFourteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 328,
                              left: 115
                            }}/> : null
                      }

                      {
                        (positions[0]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(0)}
                                 id="pointFifteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 365,
                                   left: -111
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(0)}
                                             id="pointFifteen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 365,
                                               left: -111
                                             }}/>
                      }
                      {
                        (positionsLabel[0]) ?
                            <img src={porteraLabel_pt}
                                 alt='position-description'
                                 id="labelFifteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 377,
                              left: 81
                            }}/> : null
                      }
                    </div> : null
              }

            </Grid>
          </Grid>
          <Grid container
              className="gridFiltersFull"
              spacing={10}
              direction="row"
              alignItems="flex-start"
              justify="center">
            <Grid item xs={12} sm={12} md={4} lg={3} xl={3}
                  className="positionFilter">
              <div className="titleFilter">
                  {t('POSITION')}
              </div>
              <div className="descriptionFilter">
                  {t('POSITION-DESC')}
              </div>
              {
                (lang === 'es') ?
                    <div style={{
                      width: '270px',
                      height: '400px',
                      marginTop: '10px',
                      position: 'absolute',
                      background: `url(${campo}) no-repeat center`,
                    }}>
                      {
                        (positions[14]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(14)}
                                 id="pointOneBlue" className="selectedPosition" style={{
                              position: 'relative',
                              top: 18,
                              left: 155
                            }}/> : <img src={greyPoint}
                                        alt='grey-point'
                                        onClick={() => selectOrNotPosition(14)}
                                        id="pointOne" className="nonSelectedPosition"
                                        style={{
                                          position: 'relative',
                                          top: 18,
                                          left: 155
                                        }}/>
                      }
                      {
                        (positionsLabel[14]) ?
                            <img src={delanteraLabel}
                                 alt='position-description'
                                 id="labelOne" className="labelSelected" style={{
                              position: 'absolute',
                              top: 30,
                              left: 109
                            }}/> : null
                      }

                      {
                        (positions[13]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(13)}
                                 id="pointTwoBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 35,
                                   left: 82
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(13)}
                                             id="pointTwo" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 35,
                                               left: 82
                                             }}/>
                      }
                      {
                        (positionsLabel[13]) ?
                            <img src={segundaDelanteraLabel}
                                 alt='position-description'
                                 id="labelTwo" className="labelSelected" style={{
                              position: 'absolute',
                              top: 47,
                              left: 53
                            }}/> : null
                      }

                      {
                        (positions[12]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(12)}
                                 id="pointThreeBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 65,
                                   left: 190
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(12)}
                                             id="pointThree" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 65,
                                               left: 190
                                             }}/>
                      }
                      {
                        (positionsLabel[12]) ?
                            <img src={extremoDerLabel}
                                 alt='position-description'
                                 id="labelThree" className="labelSelected" style={{
                              position: 'absolute',
                              top: 77,
                              left: 178
                            }}/> : null
                      }

                      {
                        (positions[11]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(11)}
                                 id="pointFourBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 65,
                                   left: -20
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(11)}
                                             id="pointFour" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 65,
                                               left: -20
                                             }}/>
                      }
                      {
                        (positionsLabel[11]) ?
                            <img src={extremoIzqLabel}
                                 alt='position-description'
                                 id="labelFour" className="labelSelected" style={{
                              position: 'absolute',
                              top: 77,
                              left: -15
                            }}/> : null
                      }

                      {
                        (positions[10]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(10)}
                                 id="pointFifthBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 103,
                                   left: 60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(10)}
                                             id="pointFive" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 103,
                                               left: 60
                                             }}/>
                      }
                      {
                        (positionsLabel[10]) ?
                            <img src={mediaPuntaLabel}
                                 alt='position-description'
                                 id="labelFive" className="labelSelected" style={{
                              position: 'absolute',
                              top: 115,
                              left: 82
                            }}/> : null
                      }

                      {
                        (positions[9]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(9)}
                                 id="pointSixBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 130,
                                   left: 130
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(9)}
                                             id="pointSix" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 130,
                                               left: 130
                                             }}/>
                      }
                      {
                        (positionsLabel[9]) ?
                            <img src={interiorDerLabel}
                                 alt='position-description'
                                 id="labelSix" className="labelSelected" style={{
                              position: 'absolute',
                              top: 142,
                              left: 169
                            }}/> : null
                      }

                      {
                        (positions[8]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(8)}
                                 id="pointSevenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 130,
                                   left: -60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(8)}
                                             id="pointSeven" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 130,
                                               left: -60
                                             }}/>
                      }
                      {
                        (positionsLabel[8]) ?
                            <img src={interiorIzqLabel}
                                 alt='position-description'
                                 id="labelSeven" className="labelSelected" style={{
                              position: 'absolute',
                              top: 142,
                              left: -4
                            }}/> : null
                      }

                      {
                        (positions[7]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(7)}
                                 id="pointEightBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 163,
                                   left: 10
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(7)}
                                             id="pointEight" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 163,
                                               left: 10
                                             }}/>
                      }
                      {
                        (positionsLabel[7]) ?
                            <img src={mcOfensivaLabel}
                                 alt='position-description'
                                 id="labelEight" className="labelSelected" style={{
                              position: 'absolute',
                              top: 174,
                              left: 83
                            }}/> : null
                      }

                      {
                        (positions[6]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(6)}
                                 id="pointNineBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 220,
                                   left: -7
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(6)}
                                             id="pointNine" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 220,
                                               left: -7
                                             }}/>
                      }
                      {
                        (positionsLabel[6]) ?
                            <img src={mcLabel}
                                 alt='position-description'
                                 id="labelNine" className="labelSelected" style={{
                              position: 'absolute',
                              top: 232,
                              left: 83
                            }}/> : null
                      }

                      {
                        (positions[5]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(5)}
                                 id="pointTenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: -25
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(5)}
                                             id="pointTen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: -25
                                             }}/>
                      }
                      {
                        (positionsLabel[5]) ?
                            <img src={liberoLabel}
                                 alt='position-description'
                                 id="labelTen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: 82
                            }}/> : null
                      }

                      {
                        (positions[1]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(1)}
                                 id="pointElevenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: -140
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(1)}
                                             id="pointEleven" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: -140
                                             }}/>
                      }
                      {
                        (positionsLabel[1]) ?
                            <img src={lateralIzqLabel}
                                 alt='position-description'
                                 id="labelEleven" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: -16
                            }}/> : null
                      }

                      {
                        (positions[4]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(4)}
                                 id="pointTwelveBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: 35
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(4)}
                                             id="pointTwelve" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: 35
                                             }}/>
                      }
                      {
                        (positionsLabel[4]) ?
                            <img src={lateralDerLabel}
                                 alt='position-description'
                                 id="labelTwelve" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: 176
                            }}/> : null
                      }

                      {
                        (positions[2]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(2)}
                                 id="pointThirteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 315,
                                   left: -110
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(2)}
                                             id="pointThirteen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 315,
                                               left: -110
                                             }}/>
                      }
                      {
                        (positionsLabel[2]) ?
                            <img src={centralIzqLabel}
                                 alt='position-description'
                                 id="labelThirteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 328,
                              left: 48
                            }}/> : null
                      }

                      {
                        (positions[3]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(3)}
                                 id="pointFourteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 315,
                                   left: -60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(3)}
                                             id="pointFourteen"  className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 315,
                                               left: -60
                                             }}/>
                      }
                      {
                        (positionsLabel[3]) ?
                            <img src={centralDerLabel}
                                 alt='position-description'
                                 id="labelFourteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 328,
                              left: 115
                            }}/> : null
                      }

                      {
                        (positions[0]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(0)}
                                 id="pointFifteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 365,
                                   left: -111
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(0)}
                                             id="pointFifteen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 365,
                                               left: -111
                                             }}/>
                      }
                      {
                        (positionsLabel[0]) ?
                            <img src={porteraLabel}
                                 alt='position-description'
                                 id="labelFifteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 377,
                              left: 81
                            }}/> : null
                      }
                    </div> : null
              }
              {
                (lang === 'en') ?
                    <div style={{
                      width: '270px',
                      height: '400px',
                      marginTop: '10px',
                      position: 'absolute',
                      background: `url(${campo}) no-repeat center`,
                    }}>
                      {
                        (positions[14]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(14)}
                                 id="pointOneBlue" className="selectedPosition" style={{
                              position: 'relative',
                              top: 18,
                              left: 155
                            }}/> : <img src={greyPoint}
                                        alt='grey-point'
                                        onClick={() => selectOrNotPosition(14)}
                                        id="pointOne" className="nonSelectedPosition"
                                        style={{
                                          position: 'relative',
                                          top: 18,
                                          left: 155
                                        }}/>
                      }
                      {
                        (positionsLabel[14]) ?
                            <img src={delanteraLabel_en}
                                 alt='position-description'
                                 id="labelOne" className="labelSelected" style={{
                              position: 'absolute',
                              top: 30,
                              left: 109
                            }}/> : null
                      }

                      {
                        (positions[13]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(13)}
                                 id="pointTwoBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 35,
                                   left: 82
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(13)}
                                             id="pointTwo" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 35,
                                               left: 82
                                             }}/>
                      }
                      {
                        (positionsLabel[13]) ?
                            <img src={segundaDelanteraLabel_en}
                                 alt='position-description'
                                 id="labelTwo" className="labelSelected" style={{
                              position: 'absolute',
                              top: 47,
                              left: 53
                            }}/> : null
                      }

                      {
                        (positions[12]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(12)}
                                 id="pointThreeBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 65,
                                   left: 190
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(12)}
                                             id="pointThree" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 65,
                                               left: 190
                                             }}/>
                      }
                      {
                        (positionsLabel[12]) ?
                            <img src={extremoDerLabel_en}
                                 alt='position-description'
                                 id="labelThree" className="labelSelected" style={{
                              position: 'absolute',
                              top: 77,
                              left: 178
                            }}/> : null
                      }

                      {
                        (positions[11]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(11)}
                                 id="pointFourBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 65,
                                   left: -20
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(11)}
                                             id="pointFour" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 65,
                                               left: -20
                                             }}/>
                      }
                      {
                        (positionsLabel[11]) ?
                            <img src={extremoIzqLabel_en}
                                 alt='position-description'
                                 id="labelFour" className="labelSelected" style={{
                              position: 'absolute',
                              top: 77,
                              left: -15
                            }}/> : null
                      }

                      {
                        (positions[10]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(10)}
                                 id="pointFifthBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 103,
                                   left: 60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(10)}
                                             id="pointFive" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 103,
                                               left: 60
                                             }}/>
                      }
                      {
                        (positionsLabel[10]) ?
                            <img src={mediaPuntaLabel_en}
                                 alt='position-description'
                                 id="labelFive" className="labelSelected" style={{
                              position: 'absolute',
                              top: 115,
                              left: 82
                            }}/> : null
                      }

                      {
                        (positions[9]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(9)}
                                 id="pointSixBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 130,
                                   left: 130
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(9)}
                                             id="pointSix" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 130,
                                               left: 130
                                             }}/>
                      }
                      {
                        (positionsLabel[9]) ?
                            <img src={interiorDerLabel_en}
                                 alt='position-description'
                                 id="labelSix" className="labelSelected" style={{
                              position: 'absolute',
                              top: 142,
                              left: 169
                            }}/> : null
                      }

                      {
                        (positions[8]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(8)}
                                 id="pointSevenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 130,
                                   left: -60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(8)}
                                             id="pointSeven" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 130,
                                               left: -60
                                             }}/>
                      }
                      {
                        (positionsLabel[8]) ?
                            <img src={interiorIzqLabel_en}
                                 alt='position-description'
                                 id="labelSeven" className="labelSelected" style={{
                              position: 'absolute',
                              top: 142,
                              left: -4
                            }}/> : null
                      }

                      {
                        (positions[7]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(7)}
                                 id="pointEightBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 163,
                                   left: 10
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(7)}
                                             id="pointEight" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 163,
                                               left: 10
                                             }}/>
                      }
                      {
                        (positionsLabel[7]) ?
                            <img src={mcOfensivaLabel_en}
                                 alt='position-description'
                                 id="labelEight" className="labelSelected" style={{
                              position: 'absolute',
                              top: 174,
                              left: 83
                            }}/> : null
                      }

                      {
                        (positions[6]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(6)}
                                 id="pointNineBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 220,
                                   left: -7
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(6)}
                                             id="pointNine" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 220,
                                               left: -7
                                             }}/>
                      }
                      {
                        (positionsLabel[6]) ?
                            <img src={mcLabel_en}
                                 alt='position-description'
                                 id="labelNine" className="labelSelected" style={{
                              position: 'absolute',
                              top: 232,
                              left: 83
                            }}/> : null
                      }

                      {
                        (positions[5]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(5)}
                                 id="pointTenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: -25
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(5)}
                                             id="pointTen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: -25
                                             }}/>
                      }
                      {
                        (positionsLabel[5]) ?
                            <img src={liberoLabel_en}
                                 alt='position-description'
                                 id="labelTen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: 82
                            }}/> : null
                      }

                      {
                        (positions[1]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(1)}
                                 id="pointElevenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: -140
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(1)}
                                             id="pointEleven" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: -140
                                             }}/>
                      }
                      {
                        (positionsLabel[1]) ?
                            <img src={lateralIzqLabel_en}
                                 alt='position-description'
                                 id="labelEleven" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: -16
                            }}/> : null
                      }

                      {
                        (positions[4]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(4)}
                                 id="pointTwelveBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: 35
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(4)}
                                             id="pointTwelve" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: 35
                                             }}/>
                      }
                      {
                        (positionsLabel[4]) ?
                            <img src={lateralDerLabel_en}
                                 alt='position-description'
                                 id="labelTwelve" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: 176
                            }}/> : null
                      }

                      {
                        (positions[2]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(2)}
                                 id="pointThirteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 315,
                                   left: -110
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(2)}
                                             id="pointThirteen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 315,
                                               left: -110
                                             }}/>
                      }
                      {
                        (positionsLabel[2]) ?
                            <img src={centralIzqLabel_en}
                                 alt='position-description'
                                 id="labelThirteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 328,
                              left: 48
                            }}/> : null
                      }

                      {
                        (positions[3]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(3)}
                                 id="pointFourteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 315,
                                   left: -60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(3)}
                                             id="pointFourteen"  className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 315,
                                               left: -60
                                             }}/>
                      }
                      {
                        (positionsLabel[3]) ?
                            <img src={centralDerLabel_en}
                                 alt='position-description'
                                 id="labelFourteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 328,
                              left: 115
                            }}/> : null
                      }

                      {
                        (positions[0]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(0)}
                                 id="pointFifteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 365,
                                   left: -111
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(0)}
                                             id="pointFifteen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 365,
                                               left: -111
                                             }}/>
                      }
                      {
                        (positionsLabel[0]) ?
                            <img src={porteraLabel_en}
                                 alt='position-description'
                                 id="labelFifteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 377,
                              left: 81
                            }}/> : null
                      }
                    </div> : null
              }
              {
                (lang === 'pt') ?
                    <div style={{
                      width: '270px',
                      height: '400px',
                      marginTop: '10px',
                      position: 'absolute',
                      background: `url(${campo}) no-repeat center`,
                    }}>
                      {
                        (positions[14]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(14)}
                                 id="pointOneBlue" className="selectedPosition" style={{
                              position: 'relative',
                              top: 18,
                              left: 155
                            }}/> : <img src={greyPoint}
                                        alt='grey-point'
                                        onClick={() => selectOrNotPosition(14)}
                                        id="pointOne" className="nonSelectedPosition"
                                        style={{
                                          position: 'relative',
                                          top: 18,
                                          left: 155
                                        }}/>
                      }
                      {
                        (positionsLabel[14]) ?
                            <img src={delanteraLabel_pt}
                                 alt='position-description'
                                 id="labelOne" className="labelSelected" style={{
                              position: 'absolute',
                              top: 30,
                              left: 109
                            }}/> : null
                      }

                      {
                        (positions[13]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(13)}
                                 id="pointTwoBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 35,
                                   left: 82
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(13)}
                                             id="pointTwo" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 35,
                                               left: 82
                                             }}/>
                      }
                      {
                        (positionsLabel[13]) ?
                            <img src={segundaDelanteraLabel_pt}
                                 alt='position-description'
                                 id="labelTwo" className="labelSelected" style={{
                              position: 'absolute',
                              top: 47,
                              left: 53
                            }}/> : null
                      }

                      {
                        (positions[12]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(12)}
                                 id="pointThreeBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 65,
                                   left: 190
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(12)}
                                             id="pointThree" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 65,
                                               left: 190
                                             }}/>
                      }
                      {
                        (positionsLabel[12]) ?
                            <img src={extremoDerLabel_pt}
                                 alt='position-description'
                                 id="labelThree" className="labelSelected" style={{
                              position: 'absolute',
                              top: 77,
                              left: 178
                            }}/> : null
                      }

                      {
                        (positions[11]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(11)}
                                 id="pointFourBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 65,
                                   left: -20
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(11)}
                                             id="pointFour" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 65,
                                               left: -20
                                             }}/>
                      }
                      {
                        (positionsLabel[11]) ?
                            <img src={extremoIzqLabel_pt}
                                 alt='position-description'
                                 id="labelFour" className="labelSelected" style={{
                              position: 'absolute',
                              top: 77,
                              left: -15
                            }}/> : null
                      }

                      {
                        (positions[10]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(10)}
                                 id="pointFifthBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 103,
                                   left: 60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(10)}
                                             id="pointFive" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 103,
                                               left: 60
                                             }}/>
                      }
                      {
                        (positionsLabel[10]) ?
                            <img src={mediaPuntaLabel_pt}
                                 alt='position-description'
                                 id="labelFive" className="labelSelected" style={{
                              position: 'absolute',
                              top: 115,
                              left: 82
                            }}/> : null
                      }

                      {
                        (positions[9]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(9)}
                                 id="pointSixBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 130,
                                   left: 130
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(9)}
                                             id="pointSix" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 130,
                                               left: 130
                                             }}/>
                      }
                      {
                        (positionsLabel[9]) ?
                            <img src={interiorDerLabel_pt}
                                 alt='position-description'
                                 id="labelSix" className="labelSelected" style={{
                              position: 'absolute',
                              top: 142,
                              left: 169
                            }}/> : null
                      }

                      {
                        (positions[8]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(8)}
                                 id="pointSevenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 130,
                                   left: -60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(8)}
                                             id="pointSeven" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 130,
                                               left: -60
                                             }}/>
                      }
                      {
                        (positionsLabel[8]) ?
                            <img src={interiorIzqLabel_pt}
                                 alt='position-description'
                                 id="labelSeven" className="labelSelected" style={{
                              position: 'absolute',
                              top: 142,
                              left: -4
                            }}/> : null
                      }

                      {
                        (positions[7]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(7)}
                                 id="pointEightBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 163,
                                   left: 10
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(7)}
                                             id="pointEight" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 163,
                                               left: 10
                                             }}/>
                      }
                      {
                        (positionsLabel[7]) ?
                            <img src={mcOfensivaLabel_pt}
                                 alt='position-description'
                                 id="labelEight" className="labelSelected" style={{
                              position: 'absolute',
                              top: 174,
                              left: 83
                            }}/> : null
                      }

                      {
                        (positions[6]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(6)}
                                 id="pointNineBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 220,
                                   left: -7
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(6)}
                                             id="pointNine" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 220,
                                               left: -7
                                             }}/>
                      }
                      {
                        (positionsLabel[6]) ?
                            <img src={mcLabel_pt}
                                 alt='position-description'
                                 id="labelNine" className="labelSelected" style={{
                              position: 'absolute',
                              top: 232,
                              left: 83
                            }}/> : null
                      }

                      {
                        (positions[5]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(5)}
                                 id="pointTenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: -25
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(5)}
                                             id="pointTen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: -25
                                             }}/>
                      }
                      {
                        (positionsLabel[5]) ?
                            <img src={liberoLabel_pt}
                                 alt='position-description'
                                 id="labelTen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: 82
                            }}/> : null
                      }

                      {
                        (positions[1]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(1)}
                                 id="pointElevenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: -140
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(1)}
                                             id="pointEleven" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: -140
                                             }}/>
                      }
                      {
                        (positionsLabel[1]) ?
                            <img src={lateralIzqLabel_pt}
                                 alt='position-description'
                                 id="labelEleven" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: -16
                            }}/> : null
                      }

                      {
                        (positions[4]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(4)}
                                 id="pointTwelveBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 265,
                                   left: 35
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(4)}
                                             id="pointTwelve" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 265,
                                               left: 35
                                             }}/>
                      }
                      {
                        (positionsLabel[4]) ?
                            <img src={lateralDerLabel_pt}
                                 alt='position-description'
                                 id="labelTwelve" className="labelSelected" style={{
                              position: 'absolute',
                              top: 278,
                              left: 176
                            }}/> : null
                      }

                      {
                        (positions[2]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(2)}
                                 id="pointThirteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 315,
                                   left: -110
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(2)}
                                             id="pointThirteen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 315,
                                               left: -110
                                             }}/>
                      }
                      {
                        (positionsLabel[2]) ?
                            <img src={centralIzqLabel_pt}
                                 alt='position-description'
                                 id="labelThirteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 328,
                              left: 48
                            }}/> : null
                      }

                      {
                        (positions[3]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(3)}
                                 id="pointFourteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 315,
                                   left: -60
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(3)}
                                             id="pointFourteen"  className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 315,
                                               left: -60
                                             }}/>
                      }
                      {
                        (positionsLabel[3]) ?
                            <img src={centralDerLabel_pt}
                                 alt='position-description'
                                 id="labelFourteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 328,
                              left: 115
                            }}/> : null
                      }

                      {
                        (positions[0]) ?
                            <img src={bluePoint}
                                 alt='blue-point'
                                 onClick={() => selectOrNotPosition(0)}
                                 id="pointFifteenBlue" className="selectedPosition"
                                 style={{
                                   position: 'relative',
                                   top: 365,
                                   left: -111
                                 }}/> : <img src={greyPoint}
                                             alt='grey-point'
                                             onClick={() => selectOrNotPosition(0)}
                                             id="pointFifteen" className="nonSelectedPosition"
                                             style={{
                                               position: 'relative',
                                               top: 365,
                                               left: -111
                                             }}/>
                      }
                      {
                        (positionsLabel[0]) ?
                            <img src={porteraLabel_pt}
                                 alt='position-description'
                                 id="labelFifteen" className="labelSelected" style={{
                              position: 'absolute',
                              top: 377,
                              left: 81
                            }}/> : null
                      }
                    </div> : null
              }
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}
                  className="columnFilter">
              <div className="titleFilter">
                  {t('TEAM')}
              </div>
              <FormControl className="formControl">
                <img className="iconSelect"
                     alt='team-icon'
                     src={teamIcon}/>
                <Select
                  className="select"
                  value={team}
                  onChange={teamChange}
                  MenuProps={{
                    PopoverClasses: {
                      width: '380px !important',
                      marginLeft: '-62px !important',
                      marginTop: '37px !important',
                    }
                  }}
                  displayEmpty>
                  <MenuItem value="">
                      {t('TEAM-DESC')}
                  </MenuItem>
                  {
                    teams.map((item, index) => {
                      return(<MenuItem key={index}
                                       value={item}>{item}</MenuItem>);
                    })
                  }
                </Select>
              </FormControl>
              <div className="titleFilter">
                  {t('LEAGUE')}
              </div>
              <FormControl className="formControl">
                <img className="iconSelect"
                     alt='trophy-icon'
                     src={trophyIcon}/>
                <Select
                  className="select"
                  value={league}
                  onChange={leagueChange}
                  displayEmpty>
                  <MenuItem value="" >
                      {t('LEAGUE-DESC')}
                  </MenuItem>
                  {
                    leagues.map((item, index) => {
                      return(<MenuItem key={index}
                                       value={item}>{item}</MenuItem>);
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}
                  className="columnFilter">
              <div className="titleFilter">
                  {t('AGE')}
              </div>
              <FormControl className="formControl">
                <img className="iconSelect"
                     alt='calendar-icon'
                     src={calendarIcon}/>
                <Select
                  className="select"
                  multiple
                  value={birth}
                  onChange={yearChange}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <div className="yearPlaceholder">
                          {t('AGE-DESC')}
                      </div>;
                    }

                    return selected.join(', ');
                  }}
                  displayEmpty>
                  <MenuItem value="">
                      {t('AGE-DESC')}
                  </MenuItem>
                  {
                    dates.map((item, index) => {
                      return(<MenuItem key={index}
                                       value={item}>{item}</MenuItem>);
                    })
                  }
                </Select>
              </FormControl>
              <div className="titleFilter">
                  {t('NATIONALITY')}
              </div>
              <FormControl className="formControl">
                <img className="iconSelect"
                     alt='flag-icon'
                     src={nationalityIcon}/>
                <Select
                  className="select"
                  value={nationality}
                  onChange={nationalityChange}
                  displayEmpty>
                  <MenuItem value="">
                      {t('NATIONALITY-DESC')}
                  </MenuItem>
                  {
                    nationalities.map((item, index) => {
                      return(<MenuItem key={index}
                                       value={item}>{item}</MenuItem>);
                    })
                  }
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container
                className="gridFiltersFull"
                spacing={10}
                direction="row"
                alignItems="center"
                justify="center">
            <Grid item xs={3} className="searchFirstRow">

            </Grid>
            <Grid item xs={12} md={8} className="searchSecondRow">
              <div className="searchButton" onClick={() => filters()}>
                  {t('SEARCH')}
              </div>
            </Grid>
          </Grid>
        </div>
        <div className="blueRow" id="firstBlueRow"/>
        <div className="positionFiltersWeb">
          <Grid container
                className="gridFiltersPositions"
                spacing={5}
                wrap={"nowrap"}
                direction="row"
                alignItems="center"
                justify="center">
            {
              (globalPositionFilter !== null && globalPositionFilter === 0) ?
                <Grid item xs={3} className="columnPositionSelected">
                  <div className="labelPosition">{t('FULL-SQUAD')}</div>
                </Grid>
                :
                <Grid item xs={3}
                      onClick={() => globalFilters(0)}
                      className="columnPosition">
                  <div className="labelPosition">{t('FULL-SQUAD')}</div>
                </Grid>
            }
            {
              (globalPositionFilter && globalPositionFilter === 1) ?
                <Grid item xs={3} className="columnPositionSelected">
                  <div className="labelPosition">{t('GOALKEEPERS')}</div>
                </Grid>
                :
                <Grid item xs={3}
                      onClick={() => globalFilters(1)}
                      className="columnPosition">
                  <div className="labelPosition">{t('GOALKEEPERS')}</div>
                </Grid>
            }
            {
              (globalPositionFilter && globalPositionFilter === 2) ?
                <Grid item xs={3} className="columnPositionSelected">
                  <div className="labelPosition">{t('DEFENDERS')}</div>
                </Grid>
                :
                <Grid item xs={3}
                      onClick={() => globalFilters(2)}
                      className="columnPosition">
                  <div className="labelPosition">{t('DEFENDERS')}</div>
                </Grid>
            }
            {
              (globalPositionFilter && globalPositionFilter === 3) ?
                <Grid item xs={3} className="columnPositionSelected">
                  <div className="labelPosition">{t('MIDFIELDERS')}</div>
                </Grid>
                :
                <Grid item xs={3}
                      onClick={() => globalFilters(3)}
                      className="columnPosition">
                  <div className="labelPosition">{t('MIDFIELDERS')}</div>
                </Grid>
            }
            {
              (globalPositionFilter && globalPositionFilter === 4) ?
                <Grid item xs={3} className="columnPositionSelected">
                  <div className="labelPosition">{t('FORWARDS')}</div>
                </Grid>
                :
                <Grid item xs={3}
                      onClick={() => globalFilters(4)}
                      className="columnPosition">
                  <div className="labelPosition">{t('FORWARDS')}</div>
                </Grid>
            }
          </Grid>
        </div>
        <div className="positionFiltersSmall">
          <Grid container
                className="gridFiltersPositions"
                spacing={1}
                direction="row"
                alignItems="center"
                justify="center">
            {
              (globalPositionFilter !== null && globalPositionFilter === 0) ?
                <Grid item xs={4} className="columnPositionSelected">
                  <div className="labelPositionSmall">{t('FULL-SQUAD')}</div>
                </Grid>
                :
                <Grid item xs={4}
                      onClick={() => globalFilters(0)}
                      className="columnPosition">
                  <div className="labelPositionSmall">{t('FULL-SQUAD')}</div>
                </Grid>
            }
            {
              (globalPositionFilter && globalPositionFilter === 1) ?
                <Grid item xs={4} className="columnPositionSelected">
                  <div className="labelPositionSmall">{t('GOALKEEPERS')}</div>
                </Grid>
                :
                <Grid item xs={4}
                      onClick={() => globalFilters(1)}
                      className="columnPosition">
                  <div className="labelPositionSmall">{t('GOALKEEPERS')}</div>
                </Grid>
            }
            {
              (globalPositionFilter && globalPositionFilter === 2) ?
                <Grid item xs={4}
                      className="columnPositionSelected">
                  <div className="labelPositionSmall">{t('DEFENDERS')}</div>
                </Grid>
                :
                <Grid item xs={4}
                      onClick={() => globalFilters(2)}
                      className="columnPosition">
                  <div className="labelPositionSmall">{t('DEFENDERS')}</div>
                </Grid>
            }
            {
              (globalPositionFilter && globalPositionFilter === 3) ?
                <Grid item xs={4}
                      style={{
                        marginTop: '10px'
                      }}
                      className="columnPositionSelected">
                  <div className="labelPositionSmall">{t('MIDFIELDERS')}</div>
                </Grid>
                :
                <Grid item xs={4}
                      onClick={() => globalFilters(3)}
                      style={{
                        marginTop: '10px'
                      }}
                      className="columnPosition">
                  <div className="labelPositionSmall">{t('MIDFIELDERS')}</div>
                </Grid>
            }
            {
              (globalPositionFilter && globalPositionFilter === 4) ?
                <Grid item xs={4}
                      style={{
                        marginTop: '10px'
                      }}
                      className="columnPositionSelected">
                  <div className="labelPositionSmall">{t('FORWARDS')}</div>
                </Grid>
                :
                <Grid item xs={4}
                      onClick={() => globalFilters(4)}
                      style={{
                        marginTop: '10px'
                      }}
                      className="columnPosition">
                  <div className="labelPositionSmall">{t('FORWARDS')}</div>
                </Grid>
            }
          </Grid>
        </div>
        <div className="blueRow"/>
        <Grid container
              className="gridFiltersPlayers"
              spacing={0}
              wrap={"no-wrap"}
              direction="row"
              alignItems="center"
              justify="flex-start">
          {
            searched.map( (plyr, index) => {
              return (<Grid key={index}
                            item xs={6} sm={4} md={3}>
                <PlayerSquareGrid player={plyr} key={plyr.id}/>
              </Grid>)
            })
          }
        </Grid>
        <div className="allPlayersRow">
        {
          (searched && searched.length !== 0) ?
            <div className="playersRow">
              {
                searched.map( (plyr, index) => {
                  return index < 5 ? <PlayerSquare className="players"
                                                   player={plyr}
                                                   key={plyr.id}/> : null;
                })
              }
            </div> : null
        }
        {
          (searched.length > 4) ?
            <div className="playersRow">
              {
                searched.map( (plyr, index) => {
                  return (index > 4 && index < 10) ? <PlayerSquare className="players" player={plyr} key={plyr.id}/> : null;
                })
              }
            </div> : null
        }
        {
          (searched.length > 9) ?
            <div className="playersRow">
              {
                searched.map( (plyr, index) => {
                  return (index > 9 && index < 15) ? <PlayerSquare className="players" player={plyr} key={plyr.id}/> : null;
                })
              }
            </div> : null
        }
        {
          (searched.length > 14) ?
            <div className="playersRow">
              {
                searched.map( (plyr, index) => {
                  return (index > 14 && index < 20) ? <PlayerSquare className="players" player={plyr} key={plyr.id}/> : null;
                })
              }
            </div> : null
        }
        {
          (searched.length > 19) ?
            <div className="playersRow">
              {
                searched.map( (plyr, index) => {
                  return (index > 19 && index < 25) ? <PlayerSquare className="players" player={plyr} key={plyr.id}/> : null;
                })
              }
            </div> : null
        }
        {
          (searched.length > 24) ?
            <div className="playersRow">
              {
                searched.map( (item, index) => {
                  if (index > 24 && index < 30)
                    return <PlayerSquare className="players" player={item} key={item.id}/>
                })
              }
            </div> : null
        }
        {
          (searched.length > 29) ?
            <div className="playersRow">
              {
                searched.map( (item, index) => {
                  if (index > 29 && index < 35)
                    return <PlayerSquare className="players" player={item} key={item.id}/>
                })
              }
            </div> : null
        }
        {
          (searched.length > 35) ? /*SEE WHY IS 35 AND NOT 34*/
            <div className="playersRow">
              {
                searched.map( (item, index) => {
                  if (index > 34 && index < 40)
                    return <PlayerSquare className="players" player={item} key={item.id}/>
                })
              }
              <PlayerSquare className="players" player={{}} key={40}/>
            </div> : null
        }
        {
          (searched.length > 39) ?
            <div className="playersRow">
              {
                searched.map( (item, index) => {
                  if (index > 39 && index < 45)
                    return <PlayerSquare className="players" player={item} key={item.id}/>
                })
              }
              <PlayerSquare className="players" player={{}} key={40}/>
            </div> : null
        }
        </div>
        <Footer page={'Jugadoras'}/>
      </div>
    </DocumentMeta>
  )
};

export default Players;