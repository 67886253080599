import * as actionTypes from '../actions/actionTypes';

const initialState = {
  selectedService: ''
};

// Set Modified
const setSelectedService = (state, action) => {
  state.selectedService = action.selectedService;
  return state;
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_SERVICE: return setSelectedService(state, action);
    default: return state;
  }
};

export default reducer;