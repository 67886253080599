import React, { useState, useEffect } from 'react';
import '../../App.css';
import './Home.css'
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import Carousel from 'react-material-ui-carousel'
import { useHistory, useRouteMatch } from 'react-router-dom';

import { carouselHomeImages, carouselHomeImagesMobile, playerSquaresHome1, playerSquaresHome2, servicesRow, servicesFirstRow, servicesSecondRow , clubsImages, collaborationImages, sponsorshipsImages } from "../../data/home";
import PlayerSquare from "../player-square/Player-square"
import PlayerSquareGrid from "../player-square-grid/Player-square-grid"
import InfoLogoServices from "./info-logo-services/Info-logo-services"
import MultipleImageBar from "./multiple-image-bar/Multiple-image-bar"
import Footer from "../footer/Footer";
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPage } from '../../store/actions/selectedPage'
import DocumentMeta from 'react-document-meta';

const blueRayas = require('../../images/blueRayas.png');
const greyRayas = require('../../images/home/rayas_grey.png');
const greyRayasSmall = require('../../images/home/rayas_grey_small.png');
const threeBlueLines = require('../../images/home/three-blue-lines.png');
const bigLines = require('../../images/greyBigLines.png');
const whitePoint = require('../../images/whitePoint.png');
const plusLogo = require('../../images/home/plus.png');

const Home = (props) => {
  const dispatch = useDispatch();
  const selectedPage = useSelector(state => state.selectedPage.selectedPage);

  const history = useHistory();
  const [width, setWidth] = useState(0);

  const [lang, setLang] = useState('en');
  const [t, i18n] = useTranslation();

  useEffect(() => {
    setLang(i18n.languages[1]);
    console.log('player', i18n.languages[1]);
  }, [i18n.languages[1]]);

  const meta = {
    title: 'UNIK SPORTS MANAGEMENT | Agencia de Fútbol Femenino',
    description: 'Agencia de representación y marketing deportivo dedicada al fútbol femenino. Ofrecemos oportunidades únicas a jugadoras, marcas y entidades deportivas',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let wdth = width;
    if(wdth === 0) {
      wdth = window.innerWidth;
      setWidth(wdth);
    }
  }, [width]);

  const handleResize = () => {
    const windowSize = window.innerWidth;
    setWidth(windowSize);
  };

  const allHomePlayers = playerSquaresHome1.concat(playerSquaresHome2);

  return(
    <DocumentMeta {...meta}>
      <div className="homeDiv">
        {
          (width > 700) ?
            <Carousel indicators={false}
                      autoPlay={true}
                      timeout={100}
                      interval={5000}>
              {
                carouselHomeImages.map( (item, index) => {
                  return <div
                    className="backgroundCover"
                    style={{
                      maxHeight: '100%',
                      minHeight: '100vh',
                      maxWidth: '100%',
                      minWidth: '100%',
                      margin: '0 !important',
                      fontFamily: 'Montserrat',
                      /* Full height */
                      height: '100%',
                      /* Center and scale the image nicely */
                      background: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(${item.url}) no-repeat center`,
                      title: item.url
                    }}
                    key={index}>
                    <div className="titleTop">
                        {t('WE DELIVER WORLD-CLASS')}
                    </div>
                    <div className="titleMid">
                      <div className="greyLinesSmall">
                        <img className="greyLinesImages"
                             src={greyRayasSmall}
                             alt="grey-lines"/>
                      </div>
                      <div className="greyLinesText">
                        {t('OPPORTUNITIES')}
                      </div>
                      <div className="greyLinesBig">
                        <img className="greyLinesImages"
                             src={greyRayas}
                             alt="grey-lines"/>
                      </div>
                    </div>
                    <Grid style={{
                      textAlign: 'center',
                      color: '#f1f1f0',
                      fontSize: 22,
                      fontWeight: 'bold',
                      marginTop: '10vh'
                    }}
                          id="gridTextHeader"
                          container
                          spacing={4}
                          direction="row"
                          alignItems="flex-start"
                          justify="center">
                      <Grid item xs={4}
                            onClick={() => {
                              history.push(`/services`);
                              dispatch(setSelectedPage('/services'));
                              window.scrollTo(0, 0);
                            }}
                            className="playersTextHeader">
                        <div className="fullWebTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR TOP FEMALE FOOTBALLERS')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO REALIZE THEIR')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('AMBITIONS ON AND')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('OFF THE PITCH')}
                          </div>
                        </div>
                        <div className="semiWebTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR TOP FEMALE FOOTBALLERS')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO REALIZE THEIR')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('AMBITIONS ON AND')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('OFF THE PITCH')}
                          </div>
                        </div>
                        <div className="tabletTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR TOP FEMALE FOOTBALLERS')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO REALIZE THEIR')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('AMBITIONS ON AND')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('OFF THE PITCH')}
                          </div>
                        </div>

                        <div id="playerWhitePoint"
                             className="whitePointDiv">
                          <img src={whitePoint}
                               alt="white-point"/>
                        </div>
                      </Grid>
                      <Grid item xs={4}
                            onClick={() => {
                              history.push(`/brands`);
                              dispatch(setSelectedPage('/brands'));
                              window.scrollTo(0, 0);
                            }}
                            className="brandsTextHeader">
                        <div className="fullWebTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR BRANDS')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO ENGAGE WITH AND')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('ACTIVATE THEIR TARGET')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('AUDIENCES')}
                          </div>
                        </div>
                        <div className="semiWebTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR BRANDS')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO ENGAGE WITH AND')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('ACTIVATE THEIR TARGET')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('AUDIENCES')}
                          </div>
                        </div>
                        <div className="tabletTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR BRANDS')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO ENGAGE WITH AND')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('ACTIVATE THEIR TARGET')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('AUDIENCES')}
                          </div>
                        </div>

                        <div id="brandsWhitePoint"
                             className="whitePointDiv">
                          <img src={whitePoint}
                               alt="white-point"/>
                        </div>
                      </Grid>
                      <Grid item xs={4}
                            onClick={() => {
                              history.push(`/sports-enterprises`);
                              dispatch(setSelectedPage('/sports-enterprises'));
                              window.scrollTo(0, 0);
                            }}
                            className="entidadesTextHeader">
                        <div className="fullWebTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR SPORTS ENTERPRISES')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO CONTRIBUTE TO')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('THE GROWTH OF THE')}
                          </div>
                          <div className="textPaddingBottom">
                              {t("WOMEN'S GAME")}
                          </div>
                        </div>
                        <div className="semiWebTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR SPORTS ENTERPRISES')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO CONTRIBUTE TO')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('THE GROWTH OF THE')}
                          </div>
                          <div className="textPaddingBottom">
                              {t("WOMEN'S GAME")}
                          </div>
                        </div>
                        <div className="tabletTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR SPORTS ENTERPRISES')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO CONTRIBUTE TO')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('THE GROWTH OF THE')}
                          </div>
                          <div className="textPaddingBottom">
                              {t("WOMEN'S GAME")}
                          </div>
                        </div>

                        <div id="entidadesWhitePoint"
                             className="whitePointDiv">
                          <img src={whitePoint}
                               alt="white-point"/>
                        </div>
                      </Grid>
                    </Grid>
                    <div id="gridTextMobileHeader">
                      <div className="evenRowTextHeader">

                      </div>
                      <div id="playersOddRowTextHeader"
                           className="oddRowTextHeader">
                        <div className="iconTextMobileHeader"
                             onClick={() => {
                               history.push(`/services`);
                               dispatch(setSelectedPage('/services'));
                               window.scrollTo(0, 0);
                             }}>
                          <div className="iconMobileHeader">
                            <img src={plusLogo}
                                 alt="plus-icon"
                                 id="playersIconMobileHeader"
                                 className="imagePlusIcon"/>
                          </div>
                          <div id="playersTextMobileHeader"
                               className="textMobileHeader">
                              {t('FOR TOP FEMALE FOOTBALLERS')}
                          </div>
                        </div>
                      </div>
                      <div className="evenRowTextHeader">

                      </div>
                      <div id="brandsOddRowTextHeader"
                           className="oddRowTextHeader">
                        <div className="evenRowTextHeader">
                          <div className="iconTextMobileHeader"
                               onClick={() => {
                                 history.push(`/brands`);
                                 dispatch(setSelectedPage('/brands'));
                                 window.scrollTo(0, 0);
                               }}>
                            <div className="iconMobileHeader">
                              <img src={plusLogo}
                                   alt="plus-icon"
                                   id="brandsIconMobileHeader"
                                   className="imagePlusIcon"/>
                            </div>
                            <div id="brandsTextMobileHeader"
                                 className="textMobileHeader">
                                {t('FOR BRANDS')}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="evenRowTextHeader">

                      </div>
                      <div id="entidadesOddRowTextHeader"
                           className="oddRowTextHeader">
                        <div className="evenRowTextHeader">
                          <div className="iconTextMobileHeader"
                               onClick={() => {
                                 history.push(`/sports-enterprises`);
                                 dispatch(setSelectedPage('/sports-enterprises'));
                                 window.scrollTo(0, 0);
                               }}>
                            <div className="iconMobileHeader">
                              <img src={plusLogo}
                                   alt="plus-icon"
                                   id="entidadesIconMobileHeader"
                                   className="imagePlusIcon"/>
                            </div>
                            <div id="entidadesTextMobileHeader"
                                 className="textMobileHeader">
                                {t('FOR SPORTS ENTERPRISES')}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="evenRowTextHeader">

                      </div>
                    </div>
                  </div>
                })
              }
            </Carousel> : <Carousel indicators={false}
                                    autoPlay={true}
                                    timeout={100}
                                    interval={5000}>
              {
                carouselHomeImagesMobile.map( (item, index) => {
                  return <div
                    className="backgroundCover"
                    style={{
                      maxHeight: '100%',
                      minHeight: '100vh',
                      maxWidth: '100%',
                      minWidth: '100%',
                      margin: '0 !important',
                      fontFamily: 'Montserrat',
                      /* Full height */
                      height: '100%',
                      /* Center and scale the image nicely */
                      background: `linear-gradient( rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4) ), url(${item.url}) no-repeat center`,
                      title: item.alt
                    }}
                    key={index}>
                    <div className="titleTop">
                        {t('WE DELIVER WORLD-CLASS')}
                    </div>
                    <div className="titleMid">
                      <div className="greyLinesSmall">
                        <img className="greyLinesImages"
                             src={greyRayasSmall}
                             alt="grey-lines"/>
                      </div>
                      {
                        (lang === 'es') ?
                            <div className="greyLinesText">
                              {t('OPPORTUNITIES')}
                            </div> :
                            <div className="greyLinesTextEnglish">
                              {t('OPPORTUNITIES')}
                            </div>
                      }
                      {/*<div className="greyLinesText">
                          {t('OPPORTUNITIES')}
                      </div>*/}
                      <div className="greyLinesBig">
                        <img className="greyLinesImages"
                             src={greyRayas}
                             alt="grey-lines"/>
                      </div>
                    </div>
                    <Grid style={{
                      textAlign: 'center',
                      color: '#f1f1f0',
                      fontSize: 22,
                      fontWeight: 'bold',
                      marginTop: '10vh'
                    }}
                          id="gridTextHeader"
                          container
                          spacing={4}
                          direction="row"
                          alignItems="flex-start"
                          justify="center">
                      <Grid item xs={4}
                            onClick={() => {
                              history.push(`/services`);
                              dispatch(setSelectedPage('/services'));
                              window.scrollTo(0, 0);
                            }}
                            className="playersTextHeader">
                        <div className="fullWebTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR TOP FEMALE FOOTBALLERS')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO REALIZE THEIR')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('AMBITIONS ON AND')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('OFF THE PITCH')}
                          </div>
                        </div>
                        <div className="semiWebTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR TOP FEMALE FOOTBALLERS')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO REALIZE THEIR')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('AMBITIONS ON AND2')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('OFF THE PITCH2')}
                          </div>
                        </div>
                        <div className="tabletTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR TOP FEMALE FOOTBALLERS')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO REALIZE THEIR3')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('AMBITIONS ON AND3')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('OFF THE PITCH3')}
                          </div>
                        </div>

                        <div id="playerWhitePoint"
                             className="whitePointDiv">
                          <img src={whitePoint}
                               alt="white-point"/>
                        </div>
                      </Grid>
                      <Grid item xs={4}
                            onClick={() => {
                              history.push(`/brands`);
                              dispatch(setSelectedPage('/brands'));
                              window.scrollTo(0, 0);
                            }}
                            className="brandsTextHeader">
                        <div className="fullWebTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR BRANDS')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO ENGAGE WITH AND')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('ACTIVATE THEIR TARGET')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('AUDIENCES')}
                          </div>
                        </div>
                        <div className="semiWebTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR BRANDS')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO ENGAGE WITH AND')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('ACTIVATE THEIR TARGET2')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('AUDIENCES2')}
                          </div>
                        </div>
                        <div className="tabletTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR BRANDS')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO ENGAGE WITH AND3')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('ACTIVATE THEIR TARGET3')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('AUDIENCES3')}
                          </div>
                        </div>

                        <div id="brandsWhitePoint"
                             className="whitePointDiv">
                          <img src={whitePoint}
                               alt="white-point"/>
                        </div>
                      </Grid>
                      <Grid item xs={4}
                            onClick={() => {
                              history.push(`/sports-enterprises`);
                              dispatch(setSelectedPage('/sports-enterprises'));
                              window.scrollTo(0, 0);
                            }}
                            className="entidadesTextHeader">
                        <div className="fullWebTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR SPORTS ENTERPRISES')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO CONTRIBUTE TO')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('THE GROWTH OF THE')}
                          </div>
                          <div className="textPaddingBottom">
                              {t("WOMEN'S GAME")}
                          </div>
                        </div>
                        <div className="semiWebTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR SPORTS ENTERPRISES')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO CONTRIBUTE TO')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('THE GROWTH OF THE')}
                          </div>
                          <div className="textPaddingBottom">
                              {t("WOMEN'S GAME")}
                          </div>
                        </div>
                        <div className="tabletTextHeader">
                          <div className="titlePaddingBottom">
                              {t('FOR SPORTS ENTERPRISES')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('TO CONTRIBUTE TO')}
                          </div>
                          <div className="textPaddingBottom">
                              {t('THE GROWTH OF THE')}
                          </div>
                          <div className="textPaddingBottom">
                              {t("WOMEN'S GAME")}
                          </div>
                        </div>

                        <div id="entidadesWhitePoint"
                             className="whitePointDiv">
                          <img src={whitePoint}
                               alt="white-point"/>
                        </div>
                      </Grid>
                    </Grid>
                    <div id="gridTextMobileHeader">
                      <div className="evenRowTextHeader">

                      </div>
                      <div id="playersOddRowTextHeader"
                           className="oddRowTextHeader">
                        <div className="iconTextMobileHeader"
                             onClick={() => {
                               history.push(`/services`);
                               dispatch(setSelectedPage('/services'));
                               window.scrollTo(0, 0);
                             }}>
                          <div className="iconMobileHeader">
                            <img src={plusLogo}
                                 alt="plus-icon"
                                 id="playersIconMobileHeader"
                                 className="imagePlusIcon"/>
                          </div>
                          <div id="playersTextMobileHeader"
                               className="textMobileHeader">
                              {t('FOR TOP FEMALE FOOTBALLERS')}
                          </div>
                        </div>
                      </div>
                      <div className="evenRowTextHeader">

                      </div>
                      <div id="brandsOddRowTextHeader"
                           className="oddRowTextHeader">
                        <div className="evenRowTextHeader">
                          <div className="iconTextMobileHeader"
                               onClick={() => {
                                 history.push(`/brands`);
                                 dispatch(setSelectedPage('/brands'));
                                 window.scrollTo(0, 0);
                               }}>
                            <div className="iconMobileHeader">
                              <img src={plusLogo}
                                   alt="plus-icon"
                                   id="brandsIconMobileHeader"
                                   className="imagePlusIcon"/>
                            </div>
                            <div id="brandsTextMobileHeader"
                                 className="textMobileHeader">
                                {t('FOR BRANDS')}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="evenRowTextHeader">

                      </div>
                      <div id="entidadesOddRowTextHeader"
                           className="oddRowTextHeader">
                        <div className="evenRowTextHeader">
                          <div className="iconTextMobileHeader"
                               onClick={() => {
                                 history.push(`/sports-enterprises`);
                                 dispatch(setSelectedPage('/sports-enterprises'));
                                 window.scrollTo(0, 0);
                               }}>
                            <div className="iconMobileHeader">
                              <img src={plusLogo}
                                   alt="plus-icon"
                                   id="entidadesIconMobileHeader"
                                   className="imagePlusIcon"/>
                            </div>
                            <div id="entidadesTextMobileHeader"
                                 className="textMobileHeader">
                                {t('FOR SPORTS ENTERPRISES')}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="evenRowTextHeader">

                      </div>
                    </div>
                  </div>
                })
              }
            </Carousel>
        }

        <div className="unikTeamRow">
          <div className="unikTeamLabel">
            UNIK TEAM
          </div>
          <div className="unikTeamDescriptionLabel">
              {t('REPRESENTING TOP FEMALE FOOTBALLERS ALL OVER THE WORLD')}
          </div>
        </div>
        <Grid container
              className="gridFiltersPlayersHome"
              spacing={0}
              wrap={"no-wrap"}
              direction="row"
              alignItems="center"
              justify="flex-start">
          {
            allHomePlayers.map( (plyr, index) => {
              return (<Grid key={index} item xs={6} sm={4} md={3}>
                <PlayerSquareGrid player={plyr} key={plyr.id}/>
              </Grid>)
            })
          }
        </Grid>
        <div className="playersRow">
          {
            playerSquaresHome1.map( (item, index) => {
              return <PlayerSquare className="players" player={item} key={item.id}/>
            })
          }
        </div>
        <div className="playersRow">
          {
            playerSquaresHome2.map( (item, index) => {
              return <PlayerSquare className="players" player={item} key={item.id}/>
            })
          }
        </div>
        <div className="knowAllTeamRow">
          <div className="knowAllTeamBlueLines"
            style={{
              display: 'inline-block',
              textAlign: 'center',
              verticalAlign: 'middle',
              width: '299px',
              height: '61px',
              background: `url(${blueRayas}) no-repeat center`,
            }}/>
          <div className="threeBlueLines">
            <img className="threeBlueLinesImage"
                 src={threeBlueLines}
                 alt="three-blue-lines"/>
          </div>
          <div
            onClick={() => {
              history.push(`/players`);
              dispatch(setSelectedPage('/players'));
              window.scrollTo(0, 0);
            }}
            className="knowAllTeamButton">
              {t('MEET THE WHOLE TEAM')}
          </div>
          <div className="knowAllTeamBlueLines"
            style={{
              display: 'inline-block',
              textAlign: 'center',
              verticalAlign: 'middle',
              width: '299px',
              height: '61px',
              background: `url(${blueRayas}) no-repeat center`,
            }}/>
          <div className="threeBlueLines">
            <img className="threeBlueLinesImage"
              src={threeBlueLines}
              alt="three-blue-lines"/>
          </div>
        </div>
        <div className="serviceRow">
          <div className="serviceLabel">
              {t('SERVICES')}
          </div>
          <div className="serviceDescriptionLabel">
              {t("WE MANAGE OUR PLAYER'S CAREERS AND MARKETING STRATEGIES")}
          </div>
          <div className="webGridServices"
            style={{
            textAlign: 'center',
            verticalAlign: 'middle',
            width: '100%',
            height: '100%',
            margin: '0',
            background: `url(${bigLines}) no-repeat center`,
            paddingTop: '5%'
          }}>
            <Grid container
                  className="gridNoMargin"
                  spacing={4}
                  direction="row"
                  alignItems="center"
                  justify="center">
              {
                servicesFirstRow.map( (item, index) => {
                  return (<Grid key={index} item xs={2}>
                    <InfoLogoServices className="services" service={item} key={item.id}/>
                  </Grid>)
                })
              }
            </Grid>
            <Grid container
                  className="gridNoMarginOnlyBottom"
                  spacing={4}
                  direction="row"
                  alignItems="center"
                  justify="center">
              {
                servicesSecondRow.map( (item, index) => {
                  return (<Grid key={index} item xs={2}>
                    <InfoLogoServices className="services"
                                      service={item}
                                      key={item.id}/>
                  </Grid>)
                })
              }
            </Grid>
          </div>
          <Grid container
                className="tabletGridServices"
                spacing={2}
                direction="row"
                alignItems="center"
                justify="center">
            {
              servicesRow.map( (item, index) => {
                return (<Grid key={index} item xs={4}>
                  <InfoLogoServices className="services" service={item} key={item.id}/>
                </Grid>)
              })
            }
          </Grid>
        </div>
        <div className="credentialsRow">
          <div className="unikTeamLabel">
              {t('CREDENTIALS')}
          </div>
          <div className="credentialsLabel">
              {t('WE WORK WITH LEADING CLUBS AND BRANDS')}
          </div>
          <MultipleImageBar key={0} row={0} title={t('CLUBS')}/>
          <MultipleImageBar key={1} row={1} title={t('SPONSORS')}/>
          <MultipleImageBar key={2} row={2} title={t('COLLABS')}/>
          <div className="bottomRow">

          </div>
        </div>
        <Footer page={'Home'}/>
      </div>
    </DocumentMeta>
  )
};

export default Home;
