import React, { useState, useEffect } from 'react';
import '../../App.css';
import './Contact-us.css';
import Footer from "../footer/Footer";

import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import emailjs from 'emailjs-com';
import { makeStyles } from '@material-ui/core/styles';
import {useTranslation} from "react-i18next";

const blueLines = require('../../images/tres_rayas.png');
const skyline = require('../../images/skyline-full.png');
const mensaje = require('../../images/mensaje.png');
const mensajeWeb = require('../../images/mensajeWeb.png');
const localization = require('../../images/localization.png');
const localizationWeb = require('../../images/localitationWeb.png');
const instagramIcon = require('../../images/contact-us/instagram.png');
const twitterIcon = require('../../images/contact-us/twitter.png');
const linkedinIcon = require('../../images/contact-us/linkedin.png');
const tiktokIcon = require('../../images/contact-us/tiktok.png');
const instagramIconSmall = require('../../images/rrssIcons/instagram_icon.png');
const twitterIconSmall = require('../../images/rrssIcons/twitter_icon.png');
const linkedinIconSmall = require('../../images/rrssIcons/linkedin_icon.png');
const tiktokIconSmall = require('../../images/rrssIcons/tiktok_icon.png');


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const ContactUs = () => {
  const classes = useStyles();
  const [t, i18n] = useTranslation();

  const [contactForm, setContactForm] = useState({
    from_name: '',
    from_phone: '',
    from_country: '',
    from_email: '',
    message_html: '',
  });
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError1, setOpenError1] = useState(false);
  const [openError2, setOpenError2] = useState(false);

  const handleClickSuccess = () => {
    setOpenSuccess(true);
  };
  const handleClickError1 = () => {
    setOpenError1(true);
  };
  const handleClickError2 = () => {
    setOpenError2(true);
  };
  const handleCloseSuccess = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSuccess(false);
  };
  const handleCloseError1 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError1(false);
  };
  const handleCloseError2 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenError2(false);
  };

  const updateField = (key, e) => {
    setContactForm({
      ...contactForm,
      [key]: e.target.value
    });
  };

  const sendEmail = () => {
    if(contactForm.from_name && contactForm.from_name !== '' && contactForm.from_phone && contactForm.from_phone !== ''
      && contactForm.from_country && contactForm.from_country !== '' && contactForm.from_email && contactForm.from_email !== ''
      && contactForm.message_html && contactForm.message_html !== ''){
      emailjs.send('smtp_server', 'template_kMCT0xoe', contactForm, 'user_Dps8j15SRGoGcTdabWNFl')
        .then((result) => {
          console.log(result.text);
          setOpenSuccess(true);
        }, (error) => {
          console.log(error.text);
          setOpenError2(true);
        });
    } else {
      setOpenError1(true);
    }
  };

  return(
    <div className="contactUsDiv">
      <Grid container
            className="gridProfileContactSmall"
            spacing={0}
            direction="row"
            alignItems="flex-start"
            justify="flex-start">
        <Grid item xs={6} style={{
          height: '100%'
        }}>
          <div className="contactLabelRowSmall">
            <div className="threeLinesSmall">
              <img className="lines"
                   src={blueLines}
                   alt='blue lines' />
            </div>
            <div className="contactoLabelSmall">
              {t('CONTACT US')}
            </div>
          </div>
        </Grid>
        <Grid item xs={6} style={{
          height: '100%'
        }}>
          <Grid container
                className="gridNoMargin"
                style={{
                  height: '100%'
                }}
                spacing={1}
                wrap={"no-wrap"}
                direction="row"
                alignItems="center"
                justify="center">
            <Grid item xs={2}>
              <a href="https://www.instagram.com/unik.team/"
                 target="_blank"
                 rel="noopener noreferrer"
                 className="rrssImageLink">
                <img className="iconRrssImageLink"
                     src={instagramIconSmall}
                     alt="instagram-icon"/>
              </a>
            </Grid>
            <Grid item xs={2}>
              <a href="https://twitter.com/unikteam_"
                 target="_blank"
                 rel="noopener noreferrer"
                 className="rrssImageLink">
                <img className="iconRrssImageLink"
                     src={twitterIconSmall}
                     alt="twitter-icon"/>
              </a>
            </Grid>
            <Grid item xs={2}>
              <a href="https://www.linkedin.com/company/uniksportsmanagement/"
                 target="_blank"
                 rel="noopener noreferrer"
                 className="rrssImageLink">
                <img className="iconRrssImageLink"
                     src={linkedinIconSmall}
                     alt="linkedin-icon"/>
              </a>
            </Grid>
            <Grid item xs={2}>
              <a href="https://vm.tiktok.com/Jeaf3Lm/"
                 target="_blank"
                 rel="noopener noreferrer"
                 className="rrssImageLink">
                <img className="iconRrssImageLink"
                     src={tiktokIconSmall}
                     alt="tiktok-icon"/>
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container
            className="gridProfileContact"
            spacing={0}
            wrap={"no-wrap"}
            direction="row"
            alignItems="flex-start"
            justify="flex-start">
        <Grid item xs={5}
              className="contactWebColumn">
          <div className="contactLabelRow">
            <div className="threeLines">
              <img className="lines"
                   src={blueLines}
                   alt='blue lines' />
            </div>
            <div className="contactoLabel">
              {t('CONTACT US')}
            </div>
          </div>
          <Grid container
                className="gridIcons"
                spacing={0}
                direction="row"
                alignItems="flex-end"
                justify="center">
            <Grid item xl={1}>
              <a href="https://www.instagram.com/unik.team/"
                 target="_blank"
                 rel="noopener noreferrer"
                 className="rrssImageLink">
                <img className="iconRrss"
                     src={instagramIcon}
                     alt="instagram-icon"/>
              </a>
            </Grid>
            <Grid item xl={1}>
              <a href="http://twitter.com/unikteam_"
                 target="_blank"
                 rel="noopener noreferrer"
                 className="rrssImageLink">
                <img className="iconRrss"
                     src={twitterIcon}
                     alt="twitter-icon"/>
              </a>
            </Grid>
            <Grid item xl={1}>
              <a href="https://www.linkedin.com/company/uniksportsmanagement/"
                 target="_blank"
                 rel="noopener noreferrer"
                 className="rrssImageLink">
                <img className="iconRrss"
                     src={linkedinIcon}
                     alt="linkedin-icon"/>
              </a>
            </Grid>
            <Grid item xl={1}>
              <a href="https://vm.tiktok.com/Jeaf3Lm/"
                 target="_blank"
                 rel="noopener noreferrer"
                 className="rrssImageLink">
                <img className="iconRrss"
                     src={tiktokIcon}
                     alt="tiktok-icon"/>
              </a>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}
              className="contactUsGrid">
          <div className="contactUsForm">
            <div className="escribenosLabel">
              {t('GET IN TOUCH!')}
            </div>
            <div className="divLabelInput">
              <div className="labelInput">
                {t('NAME')}*
              </div>
              <Input className="bigInput"
                     value={contactForm.from_name}
                     onChange={(e) => updateField('from_name', e)}
                     placeholder={t('FULL NAME')}
                     disableUnderline={true}>
              </Input>
            </div>
            <div className="divLabelInput">
              <div className="labelInput">
                {t('PHONE')}*
              </div>
              <Input className="bigInput"
                     value={contactForm.from_phone}
                     onChange={(e) => updateField('from_phone', e)}
                     placeholder={t('PHONE NUMBER')}
                     disableUnderline={true}>
              </Input>
            </div>
            <div className="divLabelInput">
              <div className="labelInput">
                {t('COUNTRY')}*
              </div>
              <Input className="bigInput"
                     value={contactForm.from_country}
                     onChange={(e) => updateField('from_country', e)}
                     placeholder={t('Country')}
                     disableUnderline={true}>
              </Input>
            </div>
            <div className="divLabelInput">
              <div className="labelInput">
                {t('EMAIL')}*
              </div>
              <Input className="bigInput"
                     value={contactForm.from_email}
                     onChange={(e) => updateField('from_email', e)}
                     placeholder={t('EMAIL ADDRESS')}
                     disableUnderline={true}>
              </Input>
            </div>
            <div className="divLabelInput">
              <div className="labelInput">
                {t('COMPANY AND JOB TITLE')}
              </div>
              <Input className="bigInput"
                     value={contactForm.from_business}
                     onChange={(e) => updateField('from_business', e)}
                     placeholder={t('COMPANY NAME AND JOB TITLE')}
                     disableUnderline={true}>
              </Input>
            </div>
            <div className="divLabelInput">
              <div className="labelInput">
                {t('MESSAGE')}*
              </div>
              <Input className="bigTextArea"
                     value={contactForm.message_html}
                     onChange={(e) => updateField('message_html', e)}
                     multiline={true}
                     rows={11}
                     placeholder={t('YOUR MESSAGE')}
                     disableUnderline={true}>
              </Input>
            </div>
            <div className="sendButton"
                 onClick={() => sendEmail()}>
              {t('SEND')}
            </div>
            <div className="sendButtonSmall"
                 onClick={() => sendEmail()}>
              {t('SEND')}
            </div>
          </div>
        </Grid>
          <Grid container
                className='contactInfoSmall'
                spacing={0}
                direction="row"
                alignItems="center"
                justify="center">
            <Grid item xs={12}
                  className="localizationSmallDiv">
              <img src={localization}
                   alt="localization-icon"
                   className="localizationSmall"/>
            </Grid>
            <Grid item xs={12}>
              <div className="cityNameLabel">
                UNIK SPORTS MANAGEMENT
              </div>
              <div className="cityNameLabel">
                Barcelona
              </div>
              <div className="addressNameLabel">
                C/ Copérnico, 9, 7A
              </div>
              <div className="cpCityNameLabel">
                08021, Barcelona
              </div>
              <div className="countryLabel">
                {t('SPAIN')}
              </div>
            </Grid>
            <Grid item xs={12}
                  className="mensajeSmallDiv">
              <img src={mensaje}
                   alt="message-icon"
                   className="mensajeSmall"/>
            </Grid>
            <Grid item xs={12}
                  className="cityNameLabel">
              <a href="mailto:info@unikteam.com"
                 className="cityNameLabel">
                info@unikteam.com
              </a>
            </Grid>
            <Grid item xs={12}>
            </Grid>
        </Grid>
      </Grid>
      <Grid container
            className='contactInfoWeb'
            spacing={0}
            direction="row"
            alignItems="flex-start"
            justify="flex-start">
        <Grid item xs={5}>
          <Grid container
                spacing={0}
                direction="row"
                alignItems="center"
                justify="flex-start">
            <Grid item xs={4} xl={3}>
              <img src={localizationWeb}
                   alt="localization-icon"
                   className="sobreWeb"/>
            </Grid>
            <Grid item xs={8} xl={9}>
              <div className="unikLabelWeb">
                UNIK SPORTS MANAGEMENT
              </div>
              <div className="cityNameLabelWeb">
                Barcelona
              </div>
              <div className="addressNameLabelWeb">
                C/ Copérnico, 9, 7A
              </div>
              <div className="cpCityNameLabelWeb">
                08021, Barcelona
              </div>
              <div className="countryLabelWeb">
                {t('SPAIN')}
              </div>
            </Grid>
            <Grid item xs={4} xl={3}
                  className="messagesDiv">
              <img src={mensajeWeb}
                   alt="message-icon"
                   className="sobreWeb"/>
            </Grid>
            <Grid item xs={8} xl={9}
                  className="cityNameLabelWeb">
              <a href="mailto:info@unikteam.com"
                 className="cityNameLabelWeb">
                info@unikteam.com
              </a>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div className="imageSkylineDiv">
        <img src={skyline}
             className="imageSkyline"
             alt="barcelona-skyline"/>
      </div>
      <div className={classes.root}>
        {
          (openSuccess) ?
            <Snackbar open={handleClickSuccess} autoHideDuration={4000} onClose={handleCloseSuccess}>
                  <Alert onClose={handleCloseSuccess} severity="success">
                    {t('MESSAGE SENT SUCCESSFULLY!')}
                  </Alert>
            </Snackbar> : null
        }
        {
          (openError1) ?
            <Snackbar open={handleClickError1} autoHideDuration={4000} onClose={handleCloseError1}>
              <Alert onClose={handleCloseError1} severity="error">
                {t('COMPLETE ALL THE FIELDS!')}
              </Alert>
            </Snackbar> : null
        }
        {
          (openError2) ?
            <Snackbar open={handleClickError2} autoHideDuration={4000} onClose={handleCloseError2}>
              <Alert onClose={handleCloseError2} severity="error">
                {t('AN ERROR HAS OCCURRED...')}
              </Alert>
            </Snackbar> : null
        }
      </div>
      <Footer page={'Contacto'}/>
    </div>
  );
};

export default ContactUs;
