import React, { useState, useEffect } from 'react';
import '../../../App.css';
import './Row-entidades-deportivas.css';

import Grid from '@material-ui/core/Grid';
import {useTranslation} from "react-i18next";

const RowEntidadesDeportivas = ({entidad}) => {
  const [lang, setLang] = useState('en');
  const [t, i18n] = useTranslation();

  useEffect(() => {
    setLang(i18n.languages[1]);
    console.log('player', i18n.languages[1]);
  }, [i18n.languages[1]]);

  return(
    <div>
      {
        (entidad) ?
          <Grid container
                style={{
                  marginBottom: '8%'
                }}
                className="gridRowEntidadesDeportivas"
                spacing={0}
                direction="row"
                alignItems="center"
                justify="flex-start">
            <Grid item xs={3}>
              <img style={{
                width: '100%'
              }}
                   src={entidad.icon}
                   alt={entidad.alt}/>
            </Grid>
            <Grid item xs={8}
                  style={{
                    marginLeft: '3%'
                  }}>
              <div>
                <div className="titleRowEntidades">
                  {entidad['title_'+ lang]}
                </div>
                <div className="textRowEntidades">
                  <div className="insideTextRowEntidades">
                    {entidad['text_'+ lang]}
                  </div>
                </div>
              </div>
            </Grid>
          </Grid> : null
      }
    </div>

  );
};

export default RowEntidadesDeportivas;