import React, { useState, useEffect } from 'react';
import '../../App.css';
import './Privacy-policy.css'
import { useHistory, useRouteMatch } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Footer from "../footer/Footer";

import { useDispatch, useSelector } from 'react-redux';
import { setSelectedPrivacyPolicy } from '../../store/actions/selectedPrivacyPolicy'

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const selectedPrivacyPolicy = useSelector(state => state.selectedPrivacyPolicy.selectedPrivacyPolicy);

  useEffect(() => {
    console.log('llega aquiii 2', selectedPrivacyPolicy);
    if(selectedPrivacyPolicy) {
      goToId(selectedPrivacyPolicy);
      dispatch(setSelectedPrivacyPolicy(null));
    }
  }, [selectedPrivacyPolicy]);

  const goToId = (serviceId) => {
    let offsetTop  = document.getElementById(serviceId).offsetTop;
    window.scrollTo({
      top: offsetTop,
      behavior: "smooth"
    });
  };

  return(
    <div>
      <Grid container
            className="gridPrivacyPolicy"
            spacing={10}
            direction="row"
            alignItems="flex-start"
            justify="flex-start">
        <Grid item xs={12} id="politica-privacidad">
          <div>
            <b>Política de Privacidad</b><br/><br/>
          </div>
          <div>
            Agradecemos que estés en esta página, ya que significa que tus datos te importan, y quieres conocer el destino de los mismos y quién los recoge.
            <br/><br/>
            POLÍTICA DE PRIVACIDAD
            <br/><br/>
            De conformidad con lo dispuesto en el Reglamento General (UE) sobre Protección de Datos, mediante la aceptación de la presente Política de Privacidad prestas tu consentimiento informado, expreso, libre e inequívoco para que los datos personales que proporciones a través de la página web https://www.unikteam.com/ (en adelante SITIO WEB) sean incluidos en un fichero de “USUARIOS WEB Y SUSCRIPTORES”.
            <br/><br/>
            Nuestra denominación social es UNIK SPORTS MANAGEMENT SL;<br/>
            Nuestro domicilio social se encuentra en Rambla Catalunya, 90, 08008, Barcelona;<br/>
            Nuestro e-mail es info@unikteam.com;<br/>
            Nuestra actividad social es la representación de deportistas profesionales.
            <br/><br/>
            Todo ello debidamente inscrito ante la agencia Española de Protección de Datos y respecto del que UNIK SPORTS MANAGEMENT SL garantiza que se han aplicado las medidas de seguridad tanto organizativas como técnicas requeridas por el RGPD.
            <br/><br/>
            La presente Política de Privacidad será válida únicamente para los datos de carácter personal obtenidos en el SITIO WEB, no siendo aplicable para aquella información recabada por terceros en otras páginas web, incluso si éstas se encuentran enlazadas por el SITIO WEB.
            <br/><br/>
            Con ello manifestamos nuestro compromiso por mantener y garantizar las relaciones comerciales de forma seguridad mediante la protección de los datos personales y garantizando el derecho a la privacidad de cada uno de los usuarios de nuestro SITIO WEB.
            <br/><br/>
            ¿QUÉ SON LOS DATOS PERSONALES?
            <br/><br/>
            Una pequeña aproximación es importante, por ello, debes saber que sería cualquier información relativa a una persona que nos facilita cuando visita nuestro SITIO WEB. En nuestro caso nombre, apellidos, teléfono, país y correo electrónico.
            <br/><br/>
            Adicionalmente, cuando visitas nuestro SITIO WEB, determinada información se almacena automáticamente por motivos técnicos, como la dirección IP asignada por tu proveedor de acceso a Internet.
            <br/><br/>
            FINALIDAD, LEGITIMACIÓN, CATEGORÍA DE LOS DATOS RECABADOS, CONSENTIMIENTO AL TRATAMIENTO Y MENORES DE EDAD.
            <br/><br/>
            2.1 FINALIDAD
            <br/><br/>
            Tal y como se recoge en la normativa, se informa al USUARIO de que, a través de los formularios de contacto, o suscripciones se recaban datos, los cuales se almacenan en un fichero, con la exclusiva finalidad de envío de comunicaciones electrónicas, tales como: boletines (newsletters), nuevas entradas (posts), ofertas comerciales, webinars gratuitos, así como otras comunicaciones que UNIK SPORTS MANAGEMENT SL entiende interesantes para sus USUARIOS. Los campos marcados como de cumplimentación obligatoria son imprescindibles para realizar la finalidad expresada.
            <br/><br/>
            Asimismo, podrá dar cumplimiento mediante los datos, a los requerimientos solicitados por los USUARIOS.
            <br/><br/>
            En definitiva, la FINALIDAD es la siguiente:<br/><br/>
            Representación de jugadoras de fútbol;<br/>
            Negociación de contratos deportivos y de patrocinio;<br/>
            Búsqueda de oportunidades comerciales;<br/>
            Asesoramiento integral para jugadoras;<br/>
            Asesoramiento a marcas y entidades deportivas.<br/>
            <br/><br/>
            Únicamente el titular tendrá acceso a tus datos, y bajo ningún concepto, estos datos serán cedidos, compartidos, transferidos, ni vendidos a ningún tercero.
            <br/><br/>
            La aceptación de la política de privacidad, mediante el procedimiento establecido de dole opt-in, se entenderá a todos los efectos como la prestación de CONSENTIMIENTO EXPRESO E INEQUÍVOCO del USUARIO al tratamiento de los datos de carácter personal en los términos que se exponen en el presente documento, así como a la transferencia internacional de datos que se produce, exclusivamente debido a la ubicación física de las instalaciones de los proveedores de servicios y encargados del tratamiento de datos.
            <br/><br/>
            En ningún caso se realizará un uso diferente que la finalidad para los que han sido recabados los datos ni muchos menos cederé a un tercero estos datos.
            <br/><br/>
            2.2 MENORES DE EDAD
            <br/><br/>
            En el supuesto de ser mayor de trece años, podrás registrarte en https://www.unikteam.com/ sin necesidad del consentimiento previo de tus padres o tutores.
            <br/><br/>
            ¿Qué ocurre en el caso de que seas menor de 14 años?
            <br/><br/>
            En este supuesto, será condición obligatoria el consentimiento de tus padres o tutores para que podamos tratar tus datos personales,
            <br/><br/>
            Advertencia: Si tienes menos de catorce años y no has obtenido el consentimiento de tus padres, no puedes registrarte en la web por lo que procederemos a denegar tu solicitud en caso de tener constancia de ello.
            <br/><br/>
            2.3 LEGITIMACIÓN
            <br/><br/>
            Gracias al consentimiento, podemos tratar tus datos siendo requisito obligatorio para poder suscribirte a la página web.
            <br/><br/>
            Como bien sabes, puedes retirar tu consentimiento en el momento que lo desees.
            <br/><br/>
            2.4 CATEGORÍA DE LOS DATOS
            <br/><br/>
            Los datos recabados en ningún momento son especialmente protegidos, sino que se encuentran categorizados como datos identificativos.
            <br/><br/>
            2.5 TIEMPO DE CONSERVACIÓN DE LOS DATOS
            <br/><br/>
            Conservaremos tus datos durante el tiempo legalmente establecido o hasta que solicites eliminarlos.
            <br/><br/>
            2.6 EXACTITUD Y VERACIDAD DE LOS DATOS
            <br/><br/>
            Obviamente eres el único responsable de la veracidad y exactitud de los datos que me remitas, eximiéndonos de cualquier tipo de responsabilidad al respecto.
            <br/><br/>
            Como usuario, debes garantizar la exactitud y autenticidad de los datos personales facilitados, debiendo aportar la información completa y correcta en los distintos formularios de captación de datos.
            <br/><br/>
            CUMPLIMIENTO DE LA NORMATIVA DE APLICACIÓN
            <br/><br/>
            UNIK SPORTS MANAGEMENT SL hasta la fecha cumple con las directrices de la Ley Orgánica 15/1999 del 13 de diciembre, de Protección de Datos de Carácter Personal, el Real Decreto 1720/2007 del 21 de diciembre, por el que se aprueba el Reglamento de desarrollo de dicha Ley Orgánica y demás normativa vigente y de aplicación en cada momento; velando por garantizar un correcto uso y tratamiento de los datos personales del usuario.
            <br/><br/>
            A partir de mayo del 2018, nos regiremos por la normativa que entrará en aplicación, siendo el Reglamento General sobre Protección de Datos (RGPD) de la Unión Europea.
            <br/><br/>
            Asimismo, UNIK SPORTS MANAGEMENT SL informa que da cumplimiento a la Ley 34/2002 del 11 de julio, de Servicios de la Sociedad de la Información y el Comercio Electrónico, y solicitará el consentimiento al USUARIO para el tratamiento de su correo electrónico con fines comerciales en cada momento.
            <br/><br/>
            En cumplimiento de lo establecido en la normativa, informamos que los datos suministrados, así como aquellos datos derivados de tu navegación, podrán ser almacenados en los ficheros de UNIK SPORTS MANAGEMENT SL y tratados para la finalidad de atender su solicitud y el mantenimiento de la relación que se establezca en los formularios que suscriba.
            <br/><br/>
            Adicionalmente, el USUARIO consiente el tratamiento de sus datos con la finalidad de informarles, por cualquier medio, incluido el correo electrónico, de productos y servicios de UNIK SPORTS MANAGEMENT SL.
            <br/><br/>
            En caso de no autorizar el tratamiento de sus datos con la finalidad señalada anteriormente, el USUARIO podrá ejercer su derecho de oposición al tratamiento de sus datos en los términos y condiciones previstos más adelante en el apartado “Ejercicio de Derechos”.
            <br/><br/>
            MEDIDAS DE SEGURIDAD
            <br/><br/>
            UNIK SPORTS MANAGEMENT SL informa que tiene implantadas las medidas de seguridad de índole técnica y organizativas necesarias para garantizar la seguridad de sus datos de carácter personal y evitar su alteración, pérdida y tratamiento y/o acceso no autorizado, habida cuenta del estado de la tecnología, la naturaleza de los datos almacenados y los riesgos a que están expuestos, ya provengan de la acción humana o del medio físico o natural. Todo ello de conformidad con lo previsto en el RGPD.
            <br/><br/>
            Asimismo, UNIK SPORTS MANAGEMENT SL ha establecido medidas adicionales en orden a reforzar la confidencialidad e integridad de la información, manteniendo continuamente la supervisión, control y evaluación de los procesos para asegurar el respeto a la privacidad de los datos.
            <br/><br/>
            EJERCICIO DE DERECHOS
            <br/><br/>
            Aquellas personas físicas que hayan facilitado sus datos a través de https://www.unikteam.com/, podrán dirigirse al titular de la misma con el fin de poder ejercitar gratuitamente sus derechos de acceso a sus datos, rectificación o supresión, limitación y oposición respecto de los datos incorporados en nuestros ficheros.
            <br/><br/>
            El interesado podrá ejercitar sus derechos mediante comunicación por escrito dirigida a UNIK SPORTS MANAGEMENT SL con la referencia “Protección de datos”, especificando sus datos, acreditando su identidad y los motivos de su solicitud en la siguiente dirección:
            <br/><br/>
            UNIK SPORTS MANAGEMENT SL<br/>
            Rambla Catalunya, 90, 08008, Barcelona<br/><br/>
            También podrá ejercitar los derechos, a través del correo electrónico: info@unikteam.com
            <br/><br/>
            LINKS O ENLACES EXTERNOS
            <br/><br/>
            Como un servicio a nuestros visitantes, nuestro SITIO WEB puede incluir hipervínculos a otros sitios que no son operados o controlados por el SITIO WEB. Por ello, https://www.unikteam.com/ no garantiza, ni se hace responsable de la licitud, fiabilidad, utilidad, veracidad y actualidad de los contenidos de tales sitios web o de sus prácticas de privacidad. Por favor, antes de proporcionar tu información personal a estos sitios web ajenos a https://www.unikteam.com, ten en cuenta que sus prácticas de privacidad pueden diferir de las nuestras.
            <br/><br/>
            El único objeto de los enlaces es proporcionar al USUARIO la posibilidad de acceder a dichos enlaces y su trabajo, aunque no ofrece ni comercializa por sí, ni por medio de terceros, la información, contenidos y servicios disponibles en los sitios enlazados, ni aprueba, supervisa o controla en modo alguno los contenidos y servicios o cualquier material de cualquier naturaleza existente en los mismos. https://www.unikteam.com no se responsabiliza en ningún caso de los resultados que puedan derivarse al USUARIO por acceso a dichos enlaces.
            <br/><br/>
            MODIFICACIÓN DE LA POLÍTICA DE PRIVACIDAD
            <br/><br/>
            UNIK SPORTS MANAGEMENT SL se reserva el derecho a modificar su Política de Privacidad, de acuerdo a su propio criterio, motivado por un cambio legislativo, jurisprudencial o doctrinal de la Agencia Española de Protección de Datos.
            <br/><br/>
            Cualquier modificación de la Política de Privacidad será publicada al menos diez días antes de su efectiva aplicación.
            <br/><br/>
            El uso de UNIK SPORTS MANAGEMENT SL después de dichos cambios, implicará la aceptación de los mismos.
            <br/><br/>
            RESPONSABLE DEL FICHERO, Y ENCARGADOS DEL TRATAMIENTO
            <br/><br/>
            El responsable del fichero de datos es UNIK SPORTS MANAGEMENT SL;
            <br/><br/>
            Como encargados de tratamiento ajenos al citado responsable:
            <br/><br/>
            Los servicios de suscripción por correo electrónico y envío de newsletters a la compañía THE ROCKET SCIENCE GROUP (identificada por la marca comercial “Mailchimp”), pudiéndose consultar su política de privacidad en https://mailchimp.com/legal/privacy/.
            <br/><br/>
            SERVICIOS OFRECIDOS POR TERCEROS EN ESTA WEB
            <br/><br/>
            Para prestar servicios estrictamente necesarios para el desarrollo de nuestra actividad, https://www.unikteam.com utiliza los siguientes prestadores bajo sus correspondientes condiciones de privacidad.
            <br/><br/>
            Servicios de Mensajería y envío de boletines: MailChimp<br/>
            Privacidad de fuentes de rastreo utilizadas en este sitio: Google (Analytics)
            <br/><br/>
            En https://www.unikteam.com también se estudian las preferencias de nuestros usuarios, sus características demográficas, sus patrones de tráfico, y otra información en conjunto para comprender mejor quiénes constituyen nuestra audiencia y qué es lo que necesita. El rastreo de las preferencias de nuestros usuarios también nos ayuda a mostrar avisos publicitarios más relevantes.
            <br/><br/>
            El USUARIO y, en general, cualquier persona física o jurídica, podrá establecer un hiperenlace o dispositivo técnico de enlace (por ejemplo, links o botones) desde su sitio web a https://www.unikteam.com (el “Hiperenlace“). El establecimiento del Hiperenlace no implica en ningún caso la existencia de relaciones entre https://www.unikteam.com y el propietario del sitio o de la página web en la que se establezca el Hiperenlace, ni la aceptación o aprobación por parte de https://www.unikteam.com de sus contenidos o servicios. En todo caso, https://www.unikteam.com se reserva el derecho de prohibir o inutilizar en cualquier momento cualquier Hiperenlace al SITIO WEB.
            <br/><br/>
            ¿NO DESESAS RECIBIR INFORACION DE NOSOTROS O DESEAS REVOCAR TU CONSENTIMIENTO?
            <br/><br/>
            De conformidad con lo dispuesto en la Ley 34/20023 del 11 de junio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, puedes oponerte al uso de su información para fines publicitarios, investigaciones de mercado o desarrollo de encuestas de satisfacción en cualquier momento, así como revocar tu consentimiento en cualquier momento (sin efecto retroactivo).
            <br/><br/>
            Para ello, deberás enviar un correo electrónico a la dirección info@unikteam.com.  Si has recibido publicidad por correo electrónico, también podrás oponerte desde dicho correo electrónico, pinchando en el enlace incluido en el mismo siguiendo las instrucciones que te sean facilitadas.
            <br/><br/>
            Por favor, ten en cuenta que nuestros sistemas pueden requerir un lapso de tiempo, que en ningún caso superará 48 horas, para que tu oposición o revocación se hagan efectivas; entendiéndose que durante dicho periodo de tiempo puedes seguir recibiendo mensajes.
            <br/><br/>
            En relación con la gestión de tus datos asociados a los perfiles sociales de UNIK SPORTS MANAGEMENT SL, el ejercicio del derecho de acceso dependerá de la funcionalidad de la red social y de las posibilidades de acceso a la información de los perfiles de los usuarios. Con relación a los derechos de acceso y rectificación, solo podrá satisfacerse en relación a aquella información que se encuentre bajo el control de UNIK SPORTS MANAGEMENT SL.
            <br/><br/>
            Además, podrás dejar de interactuar, seguir o recibir información de los perfiles sociales de UNIK SPORTS MANAGEMENT SL, eliminar los contenidos que dejen de interesarte o restringir con quien compartes tus conexiones mediante los mecanismos estipulados en las diferentes redes sociales.
            <br/><br/>
            El USUARIO podrá acceder a las políticas de privacidad de cada Red Social, así como configurar su perfil para garantizar su privacidad. UNIK SPORTS MANAGEMENT SL anima a los usuarios a familiarizarse con las condiciones de uso de las distintas redes sociales antes de comenzar a usarlas.
            <br/><br/>
            SISTEMAS DE CAPTURA DE DATOS PERSONALES Y SU FINALIDAD
            <br/><br/>
            1) Formularios de suscripción a contenidos: dentro de la web existen varios formularios para activar la suscripción gestionada por Mailchimp con la finalidad de enviar boletines o noticias.
            <br/><br/>
            2) Formulario de contacto: Existe un formulario de contacto cuya finalidad es la respuesta de consultas, sugerencias o contacto profesional. En este caso se utilizará la dirección de correo electrónico para responder a las mismas y enviar la información que el usuario requiera a través de la web.
            <br/><br/>
            3) Sistemas de rastreo utilizados en este sitio: Google (Analytics y Tag Manager). Puedes conocer la política de privacidad general de los servicios de Google aquí. Adicionalmente, puedes conocer cómo utiliza Google la información de sitios web o aplicaciones que utilizan sus servicios aquí.
            <br/><br/>
            El rastreo de las preferencias de nuestros usuarios también nos ayuda a mostrarte los avisos publicitarios más relevantes, por medio del uso del producto de Google, Google Adwords. De acuerdo a la política de privacidad de Google, no recolectan datos personales. Sin embargo, si no quieres que se utilicemos la herramienta de «remarketing» de Google, puedes desactivar esta opción modificando las configuraciones adecuadas aquí: https://adssettings.google.com/.
            <br/><br/>
            Más información de como utiliza Google las cookies en publicidad: https://policies.google.com/technologies/ads?hl=es
            <br/><br/>
            ACEPTACIÓN, CONSENTIMIENTO Y REVOCABILIDAD
            <br/><br/>
            El USUARIO declara haber sido informado de las condiciones sobre protección de datos de carácter personal, aceptando y consintiendo el tratamiento de los mismos por parte de UNIK SPORTS MANAGEMENT SL en la forma y para las finalidades indicadas en el aviso legal.
            <br/><br/>
            Como hemos comunicado a lo largo de las presentes políticas de privacidad, en cualquier momento podrás revocar tus datos, pero siempre sin carácter retroactivo.
            <br/><br/>
            CAMBIOS EN LA PRESENTE POLÍTICA DE PRIVACIDAD
            <br/><br/>
            UNIK SPORTS MANAGEMENT SL se reserva el derecho a modificar la presente política para adaptarla a novedades legislativas o jurisprudenciales, así como a prácticas de la industria. En dichos supuestos, el Prestador anunciará en esta página los cambios introducidos con razonable antelación a su puesta en práctica.
            <br/><br/>
          </div>
        </Grid>
        <Grid item xs={12} id="aviso-legal">
          <div>
            <b>Aviso Legal</b><br/><br/>
          </div>
          <div>
            AVISO LEGAL Y TÉRMINOS DE USO
            <br/><br/>
            Podemos garantizar que nos encontramos en un espacio 100% seguro, por ello, dando cumplimiento con el deber de información recogido en el artículo 10 de la Ley 34/2002 del 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, a continuación, hacemos constar:
            <br/><br/>
            1.1. Datos identificativos del responsable
            <br/><br/>
            Tal y como recoge la Ley 34/2002 del 11 de julio, de Servicios de la Sociedad de la Información y del Comercio Electrónico, informamos que:
            <br/><br/>
            Nuestra denominación social es UNIK SPORTS MANAGEMENT SL;<br/>
            Nuestro domicilio social se encuentra en Rambla Catalunya, 90, 08008, Barcelona;<br/>
            Nuestro e-mail es info@unikteam.com;<br/>
            Nuestra actividad social es la representación de deportistas profesionales.<br/>
            <br/><br/>
            1.2. Finalidad de la página web
            <br/><br/>
            Los servicios prestados por el responsable de la página web son los siguientes:<br/><br/>
            - Representación de jugadoras de fútbol;<br/>
            - Negociación de contratos deportivos y de patrocinio;<br/>
            - Búsqueda de oportunidades comerciales;<br/>
            - Asesoramiento integral para jugadoras;<br/>
            - Asesoramiento a marcas y entidades deportivas.
            <br/><br/>
            1.3. Usuarios
            <br/><br/>
            El acceso y/o uso de este sitio web atribuye la condición de USUARIO, que acepta, desde dicho acceso y/o uso, los presentes términos de uso. No obstante, por el mero uso de la página web, no significa el inicio de relación laboral/comercial alguna.
            <br/><br/>
            1.4. Uso del sitio web y captura de información
            <br/><br/>
            1.4.1. USO DEL SITIO WEB
            <br/><br/>
            La página web https://unikteam.com/, en adelante LA WEB, proporciona el acceso a informaciones, servicios, artículos y datos (en adelante, “los contenidos”) propiedad de UNIK SPORTS MANAGEMENT SL.
            <br/><br/>
            El USUARIO asume la responsabilidad del uso de la web.
            <br/><br/>
            El USUARIO se compromete a hacer un uso adecuado de los contenidos que ofrece la web y con carácter enunciativo, pero no limitativo, a no emplearlos para:
            <br/><br/>
            (a) incurrir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público;
            <br/><br/>
            (b) difundir contenidos o propaganda de carácter racista, xenófobo,
            pornográfico-ilegal, de apología del terrorismo o atentatorio contra los
            derechos humanos;
            <br/><br/>
            (c) provocar daños en los sistemas físicos y lógicos de
            https://www.unikteam.com/, de sus proveedores o de terceras personas,
            introducir o difundir en la red virus informáticos o cualesquiera otros
            sistemas físicos o lógicos que sean susceptibles de provocar los daños
            anteriormente mencionados;
            <br/><br/>
            (d) intentar acceder y, en su caso, utilizar las cuentas de correo electrónico de
            otros usuarios y modificar o manipular sus mensajes.
            <br/><br/>
            UNIK SPORTS MANAGEMENT SL se reserva el derecho de retirar todos aquellos comentarios y aportaciones que vulneren el respeto a la dignidad de la persona, que sean discriminatorios, xenófobos, racistas, pornográficos, que atenten contra la juventud o la infancia, el orden o la seguridad pública o que, a su juicio, no resultaran adecuados para su publicación.
            <br/><br/>
            En cualquier caso, UNIK SPORTS MANAGEMENT SL no será responsable de las opiniones vertidas por los usuarios a través del blog u otras herramientas de participación que puedan crearse, conforme a lo previsto en la normativa de aplicación.
            <br/><br/>
            1.4.2. CAPTURA DE INFORMACIÓN
            <br/><br/>
            – Formulario de contacto, donde el USUARIO deberá rellenar el campo de nombre, apellidos, teléfono, país y correo electrónico.<br/>
            – Formulario de suscripción, rellenando el USUARIO el campo de correo electrónico.<br/>
            – Navegación y Dirección IP: Al navegar por esta web, el usuario facilita de forma automática al servidor de la web información relativa a tu dirección IP, fecha y hora de acceso, el hipervínculo que le ha reenviado a éstas, tu sistema operativo y el navegador utilizado.<br/>
            <br/><br/>
            A pesar de lo anterior, los usuarios podrán cancelar su suscripción en cualquier momento, dando cumplimiento a la normativa vigente sobre Protección de Datos. Asimismo, tanto al suscribirse a esta página web como al realizar algún comentario en cualquiera de sus páginas y/o entradas, el usuario consiente:<br/><br/>
            - El tratamiento de sus datos personales conforme a sus políticas de privacidad;<br/>
            - El acceso de UNIK SPORTS MANAGEMENT SL a los datos que necesite el usuario aportar bien para la suscripción a la newsletter como para cualquier consulta mediante el formulario de contacto.<br/><br/>
            Asimismo, informamos que la información de nuestros usuarios está protegida de acuerdo con nuestra política de privacidad.
            UNIK SPORTS MANAGEMENT SL se reserva el derecho de modificar, en cualquier momento y sin necesidad de previo aviso, la presentación y configuración de la web https://www.unikteam.com/, así como el presente aviso legal.
            <br/><br/>
            1.5. Propiedad intelectual e industrial
            <br/><br/>
            UNIK SPORTS MANAGEMENT SL por sí, o como cesionario, es titular de todos los derechos de propiedad intelectual e industrial de su página web, así como de los elementos contenidos en la misma (a título enunciativo, imágenes, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc.), titularidad de UNIK SPORTS MANAGEMENT SL o bien de sus licenciantes. Todos los derechos reservados.
            <br/><br/>
            Cualquier uso no autorizado previamente por UNIK SPORTS MANAGEMENT SL, será considerado un incumplimiento grave de los derechos de propiedad intelectual o industrial del autor.
            <br/><br/>
            Quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización de UNIK SPORTS MANAGEMENT SL.
            <br/><br/>
            El USUARIO se compromete a respetar los derechos de Propiedad Intelectual e Industrial, titularidad de UNIK SPORTS MANAGEMENT SL. El USUARIO podrá visualizar únicamente los elementos de la web sin posibilidad de imprimirlos, copiarlos o almacenarlos en el disco duro de su ordenador o en cualquier otro soporte físico. El USUARIO deberá abstenerse de suprimir, alterar, eludir o manipular cualquier dispositivo de protección o sistema de seguridad que estuviera instalado en las páginas de UNIK SPORTS MANAGEMENT SL.
            <br/><br/>
            Queda terminantemente prohibido compartir la licencia para uso con más personas; cada licencia es personal e intransferible, reservándonos cuantas acciones civiles y penales nos asistan en aras de salvaguardar nuestros derechos. Todo ello bajo apercibimiento de incurrir en un delito contra la propiedad intelectual del art. 270 y SS del Código Penal, con penas de prisión de hasta 4 años.
            <br/><br/>
            1.6. Exclusión de garantías y responsabilidad
            <br/><br/>
            UNIK SPORTS MANAGEMENT SL no se hace responsable, en ningún caso, de los daños y perjuicios de cualquier naturaleza que pudieran ocasionar, a título enunciativo: por errores u omisiones en los contenidos, por falta de disponibilidad del sitio web, – el cual realizará paradas periódicas por mantenimientos técnicos – así como por la transmisión de virus o programas maliciosos o lesivos en los contenidos, a pesar de haber adoptado todas las medidas tecnológicas necesarias para evitarlo.
            <br/><br/>
            1.7. Modificaciones
            <br/><br/>
            UNIK SPORTS MANAGEMENT SL se reserva el derecho de efectuar, sin previo aviso, las modificaciones que considere oportunas en su web, pudiendo cambiar, suprimir o añadir tanto los contenidos y servicios que se presten a través de la misma como la forma en la que éstos aparezcan presentados o localizados en la web.
            <br/><br/>
            1.8. Política de enlaces
            <br/><br/>
            Las personas o entidades que pretendan realizar, o realicen, un hiperenlace desde una página web de otro portal de Internet a la web de UNIK SPORTS MANAGEMENT SL, deberán someterse las siguientes condiciones:
            <br/><br/>
            - No se permite la reproducción total o parcial de ninguno de los servicios ni contenidos del sitio web sin la previa autorización expresa de UNIK SPORTS MANAGEMENT SL;
            <br/>
            - No se establecerán deep-links ni enlaces IMG o de imagen, ni frames con la web de UNIK SPORTS MANAGEMENT SL, sin su previa autorización expresa;
            <br/>
            - No se establecerá ninguna manifestación falsa, inexacta o incorrecta sobre la web de UNIK SPORTS MANAGEMENT SL, ni sobre los servicios o contenidos de la misma;
            <br/>
            - Salvo aquellos signos que formen parte del hipervínculo, la página web en la que se establezca no contendrá ninguna marca, nombre comercial, rótulo de establecimiento, denominación, logotipo, eslogan u otros signos distintivos pertenecientes a UNIK SPORTS MANAGEMENT SL, salvo autorización expresa de éste;
            <br/>
            -  El establecimiento del hipervínculo no implicará la existencia de relaciones entre UNIK SPORTS MANAGEMENT SL y el titular de la página web o del portal desde el cual se realice, ni el conocimiento y aceptación de UNIK SPORTS MANAGEMENT SL de los servicios y contenidos ofrecidos en dicha página web o portal;
            <br/>
            - UNIK SPORTS MANAGEMENT SL no será responsable de los contenidos o servicios puestos a disposición del público en la página web o portal desde el cual se realice el hipervínculo, ni de las informaciones y manifestaciones incluidas en los mismos;
            <br/>
            - El sitio web de UNIK SPORTS MANAGEMENT SL puede poner a disposición del usuario conexiones y enlaces a otros sitios web gestionados y controlados por terceros. Dichos enlaces tienen como exclusiva función, la de facilitar a los usuarios la búsqueda de información, contenidos y servicios en Internet, sin que en ningún caso pueda considerarse una sugerencia, recomendación o invitación para la visita de los mismos;
            <br/>
            - UNIK SPORTS MANAGEMENT SL no comercializa, ni dirige, ni controla previamente, ni hace propios los contenidos, servicios, informaciones y manifestaciones disponibles en dichos sitios web;
            <br/>
            - UNIK SPORTS MANAGEMENT SL no asume ningún tipo de responsabilidad, ni siquiera de forma indirecta o subsidiaria, por los daños y perjuicios de toda clase que pudieran derivarse del acceso, mantenimiento, uso, calidad, licitud, fiabilidad y utilidad de los contenidos, informaciones, comunicaciones, opiniones, manifestaciones, productos y servicios existentes u ofrecidos en los sitios web no gestionados por UNIK SPORTS MANAGEMENT SL y que resulten accesibles a través de UNIK SPORTS MANAGEMENT SL.
            <br/><br/>
            1.9. Derecho de exclusión
            <br/><br/>
            UNIK SPORTS MANAGEMENT SL se reserva el derecho a denegar o retirar el acceso al portal y/o los servicios ofrecidos sin necesidad de preaviso, a instancia propia o de un tercero, a aquellos usuarios que incumplan las presentes Condiciones Generales de Uso.
            <br/><br/>
            1.10. Generalidades
            <br/><br/>
            UNIK SPORTS MANAGEMENT SL perseguirá el incumplimiento de las presentes condiciones, así como cualquier utilización indebida de su web ejerciendo todas las acciones civiles y penales que le puedan corresponder en derecho.
            <br/><br/>
            1.11. Modificación de las presentes condiciones y duración
            <br/><br/>
            UNIK SPORTS MANAGEMENT SL podrá modificar en cualquier momento las condiciones aquí determinadas, siendo debidamente publicadas como aquí aparecen. La vigencia de las citadas condiciones irá en función de su exposición y estarán vigentes hasta que sean modificadas por otras debidamente publicadas.
            <br/><br/>
            1.12. Reclamaciones y dudas
            <br/><br/>
            UNIK SPORTS MANAGEMENT SL informa de que existen hojas de reclamación a disposición de usuarios y clientes pudiendo remitir un correo a info@unikteam.com,  indicando su nombre y apellidos, y exponiendo los motivos de su reclamación.
            También puede dirigir su reclamación por correo postal dirigido a: UNIK SPORTS MANAGEMENT SL, Rambla Catalunya, 90, 08008, Barcelona.
            <br/><br/>
            1.13. Legislación aplicable y jurisdicción
            <br/><br/>
            La relación entre UNIK SPORTS MANAGEMENT SL y el USUARIO se regirá por la normativa española vigente ,y cualquier controversia se someterá a los Juzgados y tribunales de la ciudad de Barcelona, salvo que la Ley aplicable disponga otra cosa.
            <br/><br/>
          </div>
        </Grid>
      </Grid>
      <Footer page={''}/>
    </div>
  )
};

export default PrivacyPolicy;