import React, { useState, useEffect } from 'react';
import '../../App.css';
import './Brands.css';
import Footer from "../footer/Footer";

import Grid from '@material-ui/core/Grid';
import BrandsRow from "./brands-row/Brands-row";
import { brandsRow, otherActions1, otherActions2 } from "../../data/brands";
import {useTranslation} from "react-i18next";
import DocumentMeta from 'react-document-meta';

const backgroundBrand = require('../../images/brands/background-brands-header-try.png');
const backgroundBrandTablet = require('../../images/brands/background-brands-header-tablet.png');
const icon_conecta = require('../../images/brands/icon-conecta.png');
const icon_crea = require('../../images/brands/icon-crea.png');
const icon_sorprende = require('../../images/brands/icon-sorprende.png');
const icon_transmite = require('../../images/brands/icon-transmite.png');
const blueLines = require('../../images/tres_rayas.png');
const twoBlueLines = require('../../images/brands/twoBlueLines.png');
const bigLines = require('../../images/greyBigLines.png');
const cuadraditos = require('../../images/brands/Cuadraditos.png');

const Brands = () => {

  const goToId = (serviceId) => {
    let offsetTop  = document.getElementById(serviceId).offsetTop;
    window.scrollTo({
      top: offsetTop-20,
      behavior: "smooth"
    });
  };

  const [width, setWidth] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [numDependingWidth, setNumDependingWidth] = useState(5);
  const [lang, setLang] = useState('en');
  const [t, i18n] = useTranslation();

  useEffect(() => {
    setLang(i18n.languages[1]);
    console.log('player', i18n.languages[1]);
  }, [i18n.languages[1]]);

  const meta = {
    title: 'UNIK SPORTS MANAGEMENT | Marketing | Fútbol femenino',
    description: 'Ayudamos a las marcas a conectar con su público objetivo a través de campañas de marketing y acciones comerciales con jugadoras de fútbol femenino.',
    meta: {
      charset: 'utf-8',
      name: {
        keywords: 'react,meta,document,html,tags'
      }
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let wdth = width;
    if(wdth === 0) {
      wdth = window.innerWidth;
      setWidth(wdth);
    }
    if(wdth < 960) {
      setNumDependingWidth(3);
    } else {
      setNumDependingWidth(4);
    }
  }, [width]);

  const handleResize = () => {
    const windowSize = window.innerWidth;
    setWidth(windowSize);
  };

  return(
    <DocumentMeta {...meta}>
      <div className="brandsDiv">
        <div className="brandsDivFullHeader">
          {
            (width > 960) ?
              <img src={backgroundBrand}
                   alt="background-brands"
                   onLoad={() => setLoaded(true)}
                   className="imageBackgroundBrands"/> :
              <div>
                {
                  (width > 600) ?
                  <img src={backgroundBrandTablet}
                       alt="background-brand-tablet"
                       onLoad={() => setLoaded(true)}
                       className="imageBackgroundBrands"/> :
                  <img src={backgroundBrandTablet}
                       alt="background-brand-tablet"
                       onLoad={() => setLoaded(true)}
                       className="imageBackgroundBrands"/>
                }
              </div>
          }
          {
            (loaded) ?
              <div>
                <div className="brandsLabelRow">
                  <div className="threeLinesBrands">
                    <img className="linesBrands"
                         src={blueLines}
                         alt='blue lines' />
                  </div>
                  <div className="brandsLabel">
                      {t('BRANDS')}
                  </div>
                </div>
                <div className="brandsLabelRowSmall">
                  <div className="brandsThreeLinesSmall">
                    <img className="lines"
                         src={blueLines}
                         alt='blue lines' />
                  </div>
                  <div className="brandsLabelSmall">
                      {t('BRANDS')}
                  </div>
                </div>
                <div className="brandsIconsHeader">
                  <div className="brandBigDiv" id="conectaIcon">
                    <div>
                      <img className="imageIconBrandsHeader"
                           src={icon_conecta}
                           alt="icon-conecta"/>
                    </div>
                    <div className="brandInlineDiv">
                      <div className="titleIconTextBrand">
                          {t('CONNECT')}
                      </div>
                      <div className="descriptionIconTextBrand">
                          {t('WITH YOUR TARGET AUDIENCE')}
                      </div>
                    </div>
                  </div>
                  <div className="brandBigDiv" id="creaIcon">
                    <div>
                      <img className="imageIconBrandsHeader"
                           src={icon_crea}
                           alt="icon-crea"/>
                    </div>
                    <div className="brandInlineDiv">
                      <div className="titleIconTextBrand">
                          {t('CREATE')}
                      </div>
                      <div className="descriptionIconTextBrand">
                          {t('ENGAGING CONTENT')}
                      </div>
                    </div>
                  </div>
                  <div className="brandBigDiv" id="transmiteIcon">
                    <div>
                      <img className="imageIconBrandsHeader"
                           src={icon_transmite}
                           alt="icon-transmite"/>
                    </div>
                    <div className="brandInlineDiv">
                      <div className="titleIconTextBrand">
                          {t('CONVEY')}
                      </div>
                      <div className="descriptionIconTextBrand">
                          {t('YOUR VALUES')}
                      </div>
                    </div>
                  </div>
                  <div className="brandBigDiv" id="sorprendeIcon">
                    <div>
                      <img className="imageIconBrandsHeader"
                           src={icon_sorprende}
                           alt="icon-sorprende"/>
                    </div>
                    <div className="brandInlineDiv">
                      <div className="titleIconTextBrand">
                          {t('SURPRISE')}
                      </div>
                      <div className="descriptionIconTextBrand">
                          {t('WITH NEW IDOLS')}
                      </div>
                    </div>
                  </div>
                </div>
              </div> : null
          }
        </div>

        {
          (loaded) ?
            <div>
              <div className="smallRowBlue"/>
              <div className="divGridTextBrandsDescriptionSmall">
                <Grid container
                      className="gridTextBrandsDescription"
                      spacing={0}
                      direction="row"
                      alignItems="center"
                      justify="center">
                  <Grid item className="iconTextBrandsDescriptionSmall"
                        xs={1}>
                    <img className="iconTextBrandsDescriptionSmallLastImg"
                         src={twoBlueLines}
                         alt="two-blue-lines"/>
                  </Grid>
                  <Grid item className="textBrandsDescriptionSmall"
                        xs={11}>
                    <div className="textBrandsDescriptionSmallText">
                        {t('BRAND-DESC-1-SMALL-1')} {t('BRAND-DESC-1-SMALL-2')}                    </div>
                  </Grid>
                  <Grid item className="iconTextBrandsDescriptionSmall"
                        xs={1}>
                    <img className="iconTextBrandsDescriptionSmallLastImg"
                         src={twoBlueLines}
                         alt="two-blue-lines"/>
                  </Grid>
                  <Grid item className="textBrandsDescriptionSmall"
                        xs={11}>
                    <div className="textBrandsDescriptionSmallText">
                        {t('BRAND-DESC-2-SMALL-1')} {t('BRAND-DESC-2-SMALL-2')} {t('BRAND-DESC-2-SMALL-3')}                    </div>
                  </Grid>
                  <Grid item className="iconTextBrandsDescriptionSmallLast"
                        xs={1}>
                    <img className="iconTextBrandsDescriptionSmallLastImg"
                         src={twoBlueLines}
                         alt="two-blue-lines"/>
                  </Grid>
                  <Grid item className="textBrandsDescriptionSmallLast"
                        xs={11}>
                    <div className="textBrandsDescriptionSmallText">
                        {t('BRAND-DESC-3-SMALL-1')} {t('BRAND-DESC-3-SMALL-2')}                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="divGridTextBrandsDescription">
                <Grid container
                      className="gridTextBrandsDescription"
                      spacing={0}
                      direction="row"
                      alignItems="center"
                      justify="center">
                  <Grid item className="columnTextBrandsDescription" xs={4}>
                    <div className="divColumnTextBrandsDescription">
                        {t('BRAND-DESC-1-WEB-1')}<br/>
                        {t('BRAND-DESC-1-WEB-2')}<br/>
                        {t('BRAND-DESC-1-WEB-3')}<br/>
                        {t('BRAND-DESC-1-WEB-4')}
                    </div>
                  </Grid>
                  <Grid item className="columnTextBrandsDescription" xs={4}>
                    <div className="divColumnTextBrandsDescription">
                        {t('BRAND-DESC-2-WEB-1')}<br/>
                        {t('BRAND-DESC-2-WEB-2')}<br/>
                        {t('BRAND-DESC-2-WEB-3')}<br/>
                        {t('BRAND-DESC-2-WEB-4')}
                    </div>
                  </Grid>
                  <Grid item className="columnTextBrandsDescription" xs={4}>
                    <div className="divColumnTextBrandsDescription">
                        {t('BRAND-DESC-3-WEB-1')}<br/>
                        {t('BRAND-DESC-3-WEB-2')}<br/>
                        {t('BRAND-DESC-3-WEB-3')}<br/>
                        {t('BRAND-DESC-3-WEB-4')}
                    </div>
                  </Grid>
                </Grid>
              </div>
              <div className="smallRowBlue"/>
              <div className="descriptionRowGrey">
                  {t('BRAND-DESC-ROW-1')}<br/>
                  {t('BRAND-DESC-ROW-2')}<br/>
                  {t('BRAND-DESC-ROW-3')}
              </div>

              <div className="titleBrandsDescriptionsDiv">
                <div className="titleBrandsDescriptions">
                    {t('ACTIVATION POSSIBILITIES')}
                </div>
              </div>

              <BrandsRow brandRow={brandsRow[0]} rayas={0}/>
              <div className="cuadraditosImgDiv">
                <img className="cuadraditosImg"
                     src={cuadraditos}
                     alt="background-cuadrados"/>
              </div>
              <BrandsRow brandRow={brandsRow[1]} rayas={1}/>
              <BrandsRow brandRow={brandsRow[2]} rayas={0}/>
              <BrandsRow brandRow={brandsRow[3]} rayas={1}/>

              <div className="otherActionsRowDiv">
                <div className="titleBrandsDescriptions">
                    {t('OTHER MARKETING ACTIONS')}
                </div>
                <div className="gridBrandsdiv"
                     style={{
                       textAlign: 'center',
                       verticalAlign: 'middle',
                       width: '100%',
                       height: '100%',
                       margin: '0',
                       background: `url(${bigLines}) no-repeat center`,
                       paddingBottom: '3%'
                     }}>
                  <Grid container
                        className="gridNoMargin"
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justify="center">
                    {
                      otherActions1.map( (item, index) => {
                        return (<Grid key={index}
                                      item xs={4} md={3} lg={3}>
                          <img className="otherActionLogo"
                               src={item.url}
                               alt={item.alt}/>
                          <div className="infoRectangleBrands">
                            <div className="labelRectangleBrand">{item['actionText1_'+lang]}</div>
                            <div className="labelRectangleBrand">{item['actionText2_'+lang]}</div>
                          </div>
                        </Grid>)
                      })
                    }
                  </Grid>
                  <Grid container
                        className="gridNoMarginOnlyBottom"
                        spacing={2}
                        direction="row"
                        alignItems="center"
                        justify="center">
                    {
                      otherActions2.map( (item, index) => {
                        return (<Grid key={index} item xs={4} md={3} lg={3}>
                          <img className="otherActionLogo"
                               src={item.url}
                               alt={item.alt}/>
                          <div className="infoRectangleBrands">
                            <div className="labelRectangleBrand">{item['actionText1_'+lang]}</div>
                            <div className="labelRectangleBrand">{item['actionText2_'+lang]}</div>
                          </div>
                        </Grid>)
                      })
                    }
                  </Grid>
                </div>
              </div>

              <Footer page={'Marcas'}/>
            </div> : null
        }

      </div>
    </DocumentMeta>
  );
};

export default Brands;