import React, { useState, useEffect } from 'react';
import '../../../App.css';
import './Services-mobile.css';
import Grid from '@material-ui/core/Grid';
import {useTranslation} from "react-i18next";

const blueMultiLines = require('../../../images/player-graphics/blue-lines.png');
const greyMultiLines = require('../../../images/player-graphics/grey-lines.png');

const ServicesMobile = ({service}) => {

  const [lang, setLang] = useState('en');
  const [t, i18n] = useTranslation();

  useEffect(() => {
    setLang(i18n.languages[1]);
    console.log('player', i18n.languages[1]);
  }, [i18n.languages[1]]);

  return(
    <div className="serviceMobileGrid">
      <div className="imageServiceMobileDiv">
        <div style={{
          paddingTop: '30px',
          background: `url(${greyMultiLines}) no-repeat left top`
        }}>
        <img src={service.image}
             alt={service.alt}
             className="imageServiceMobile"/>
        </div>
      </div>
      <div className="serviceTextDiv">
        <div style={{
          paddingBottom: '30px',
          background: `url(${blueMultiLines}) no-repeat right bottom`
        }}>
          <div style={{
            width: '100%'
          }}>
            <div className="titleServiceMobile">
              <img src={service.icon}
                   alt={service.altIcon}
                   className="iconTitleServiceMobile"/>
              <div className="textTitleServiceMobile">
                {service['title_'+ lang]}
              </div>
            </div>
          </div>
          <div className="textServiceMobile">
            {service['text_'+ lang]}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesMobile;