export const teams = [
  'AC Milan', 'Athletic Club',/* 'Atlético de Madrid', 'Colgate Raiders',*/ 'CR Flamengo', 'Emek Hefer WFC', 'FC Barcelona', 'FC Barcelona B',
  'Juventus FC', 'Levante UD', 'Madrid CFF',  /*'Málaga CFF', 'OGC Nice', 'Olympique de Marseille',*/'Olympique de Lyon',
  'Querétaro FC', 'Deportivo Abanca', 'RCD Espanyol', 'Real Betis', 'Real Madrid CF', /*'Red Star Belgrade',*/
  'Real Sociedad', 'SC Internacional', 'SD Eibar', 'Sevilla FC', 'Shanghai Shenhua', 'Sporting Clube de Portugal',
  'Tigres UANL', 'Tottenham Hotspur', /*'UD Granadilla Tenerife',*/ 'Valencia CF', 'ŽNK Split'
];
export const dates = [
  2001, 2000, 1999, 1998, 1996, 1995, 1994, 1993, 1992, 1991, 1990, 1989, 1988
];
export const leagues_es = [
  "Barclays FA Women's Super League (Inglaterra)", 'Brasileirão Feminino - Série A1 (Brasil)', 'Chinese Super League (China)', 'Prva HNL (Croacia)', 'Liga BPI (Portugal)',
  'Liga MX Femenil (México)', 'Ligat Nashim (Israel)', 'Division 1 Féminine (Francia)', /*'Patriot League (Estados Unidos)',*/ 'Primera Iberdrola (España)', 'Reto Iberdrola (España)',
  /*'Superliga Srbije (Serbia)',*/ 'Serie A (Italia)'
];
export const leagues_en = [
  "Barclays FA Women's Super League (England)", 'Brasileirão Feminino - Série A1 (Brazil)', 'Chinese Super League (China)', 'Prva HNL (Croatia)', 'Liga BPI (Portugal)',
  'Liga MX Femenil (Mexico)', 'Ligat Nashim (Israel)', 'Division 1 Féminine (France)', /*'Patriot League (United States)', */ 'Primera Iberdrola (Spain)', 'Reto Iberdrola (Spain)',
  /*'Superliga Srbije (Serbia)',*/ 'Serie A (Italy)'
];
export const leagues_pt = [
  "Barclays FA Women's Super League (Inglaterra)", 'Brasileirão Feminino - Série A1 (Brasil)', 'Chinese Super League (China)', 'Prva HNL (Croácia)', 'Liga BPI (Portugal)',
  'Liga MX Femenil (México)', 'Ligat Nashim (Israel)', 'Division 1 Féminine (France)', /*'Patriot League (Estados Unidos)',*/ 'Primera Iberdrola (Espanha)', 'Reto Iberdrola (Espanha)',
  /*'Superliga Srbije (Sérvia)',*/ 'Serie A (Itália)'
];

export const nationalities_es = [
  'Alemana', 'Argentina', 'Bosnia', 'Brasileña', 'Canadiense', 'Costarricense', 'Croata',/* 'Eslovena',*/
  'Española', 'Estadounidense', 'Francesa', 'Nigeriana', /*'Italiana',*/ 'Mexicana', /*'Polaca',*/ 'Portuguesa', /*'Serbia',*/ 'Suiza'
];

export const nationalities_en = [
  'American', 'Argentinian', 'Bosnian', 'Brazilian', 'Canadian', 'Costa Rican', 'Croatian', /* 'Slovenian', */
  'French', 'German', 'Nigerian', /* 'Italian', */ 'Mexican', /* 'Polish', */ 'Portuguese', 'Spanish', /* 'Serbian', */ 'Swiss'
];

export const nationalities_pt = [
  'Americano', 'Argentino', 'Bósnio', 'Brasileiro', 'Canadense', 'Colombiano', 'Costa-riquenho', 'Croata', /* 'Esloveno', */
  'Francês', 'Alemão', 'Nigeriano', /* 'Italiano', */ 'Mexicano', /* 'Polonês', */ 'Português', 'Espanhol', /* 'Sérvio', */ 'Suíço'
];

export const allPlayers = [
  {
    id: 1,
    title: 'UNIK TEAM | Aida Hadžić | Jugadora de fútbol femenino',
    metadescription: 'Aida Hadžić, futbolista bosnia, actualmente juega como centrocampista en el Žnk Split de la Prva HNL y en la Selección de Bosnia y Herzegovina.',
    url: require('../images/players-profile-photo/aida-hadzic-znk-split.png'),
    alt: 'aida-hadzic-znk-split',
    name: 'AIDA HADŽIĆ',
    nameUrl: 'aida-hadzic',
    team: 'ŽNK Split',
    league_es: 'Prva HNL (Croacia)',
    league_en: 'Prva HNL (Croatia)',
    league_pt: 'Prva HNL (Croácia)',
    selection_es: 'SEL. DE BIH',
    selection_en: 'BiH TEAM',
    selection_pt: 'SEL. BÓSNIA',
    mainPosition_es: 'CENTROCAMPISTA',
    mainPosition_en: 'MIDFIELDER',
    mainPosition_pt: 'MEIO-CAMPISTA',
    birthDate: '11 - 09 - 1992',
    birthYear: '1992',
    town: 'Makarska',
    country: 'Croacia',
    nationality_es : 'Bosnia',
    nationality_en : 'Bosnian',
    nationality_pt: 'Bósnia',
    height: '1,63',
    weight: '60',
    signaturePhoto: require('../images/players/aida-hadzic/jugadora-aida-hadzic-unikteam.png'),
    signaturePhotoAlt: 'jugadora-aida-hadzic-unikteam',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/aida-hadzic/header/aida-hadzic-bosnia-herzegovina.jpg'),
      alt: 'aida-hadzic-bosnia-herzegovina'
    }, {
      id: 2,
      url: require('../images/players/aida-hadzic/header/aida-hadzic-bosnia-herzegovina-femenino.jpg'),
      alt: 'aida-hadzic-bosnia-herzegovina-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/aida-hadzic/header-mobile/aida-hadzic-bosni-y-herzegovina.jpg'),
    mobileHeaderPhotoAlt: 'aida-hadzic-bosni-y-herzegovina',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: '12 veces Campeona de la Primera División Bosnia',
      nameFirstRow_en: '12-times Bosnian First Division "Premijer',
      nameFirstRow_pt: '12 vezes Campeã da Primeira Divisão Bósnia',
      nameSecondRow_es: '"Premijer Liga BiH"',
      nameSecondRow_en: 'Liga BiH" Champion',
      nameSecondRow_pt: '"Premijer Liga BiH"',
      team_es: 'SFK 2000 Sarajevo',
      team_en: 'SFK 2000 Sarajevo',
      team_pt: 'SFK 2000 Sarajevo',
      years_es: '2007 - 2008 hasta 2018 - 2019',
      years_en: '2007-2008 to 2018-2019',
      years_pt: '2007-2008 até 2018-2019'
    }, {
      id: 2,
      nameFirstRow_es: '12 veces Campeona de la',
      nameFirstRow_en: '12-times Bosnian',
      nameFirstRow_pt: '12 vezes Campeã da',
      nameSecondRow_es: 'Copa de Bosnia',
      nameSecondRow_en: 'Cup Champion',
      nameSecondRow_pt: 'Copa da Bósnia',
      team_es: 'SFK 2000 Sarajevo',
      team_en: 'SFK 2000 Sarajevo',
      team_pt: 'SFK 2000 Sarajevo',
      years_es: '2007 - 2008 hasta 2018 - 2019',
      years_en: '2008 to 2019',
      years_pt: '2008 até 2019',
    }],
    internationalTournament: [],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Mejor Jugadora',
      nameFirstRow_en: 'Best Player',
      nameFirstRow_pt: 'Melhor Jogadora',
      nameSecondRow_es: 'Primera División Bosnia "Premijer Liga BiH"',
      nameSecondRow_en: 'Bosnian First Division "Premijer Liga BiH"',
      nameSecondRow_pt: 'Primeira Divisão Bósnia "Premijer Liga BiH"',
      years_es: '2019',
      years_en: '2019',
      years_pt: '2019'
    }, {
      id: 2,
      nameFirstRow_es: 'Mejor Jugadora',
      nameFirstRow_en: 'Best Player',
      nameFirstRow_pt: 'Melhor Jogadora',
      nameSecondRow_es: 'SFK 2000 Sarajevo',
      nameSecondRow_en: 'SFK 2000 Sarajevo',
      nameSecondRow_pt: 'SFK 2000 Sarajevo',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    }, {
      id: 3,
      nameFirstRow_es: 'Mejor Jugadora',
      nameFirstRow_en: 'Best Female Football Player',
      nameFirstRow_pt: 'Melhor Jogadora',
      nameSecondRow_es: 'Bosnia y Herzegovina',
      nameSecondRow_en: 'in Bosnia-Herzegovina',
      nameSecondRow_pt: 'Bósnia e Herzegovina',
      years_es: '2009',
      years_en: '2009',
      years_pt: '2009'
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Ball control',
      characteristic_pt: 'Controle de bola',
      characteristic_es: 'Control de balón',
      num: 9
    }, {
      id: 2,
      characteristic_en: 'Ball handling',
      characteristic_pt: 'Condução',
      characteristic_es: 'Conducción',
      num: 8
    }, {
      id: 3,
      characteristic_en: 'Medium range passes',
      characteristic_pt: 'Passe médio',
      characteristic_es: 'Pase Medio',
      num: 26
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Defensive player',
      characteristic_pt: 'Jogadora defensiva',
      characteristic_es: 'Criterio defensivo',
      num: 10
    }, {
      id: 2,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Rational thinking',
      characteristic_pt: 'Racional',
      characteristic_es: 'Racional',
      num: 28
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Power',
      characteristic_pt: 'Potência',
      characteristic_es: 'Potencia',
      num: 27
    }, {
      id: 2,
      characteristic_en: 'Resistance',
      characteristic_pt: 'Resistência',
      characteristic_es: 'Resistencia',
      num: 31
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: true,
      position: 'LIB'
    },{
      id: 6,
      selected: true,
      position: 'MC'
    },{
      id: 7,
      selected: true,
      position: 'MCO'
    },{
      id: 8,
      selected: true,
      position: 'INTi'
    },{
      id: 9,
      selected: true,
      position: 'INTd'
    },{
      id: 10,
      selected: true,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'ŽNK SPLIT',
      country: 'Croatia',
      years_es: '2019 - Actualidad',
      years_en: '2019 - Currently',
      years_pt: '2019 - Presente',
      image: require('../images/clubs/trayectoria/znk-split-croacia-futbol-femenino.png'),
      alt: 'znk-split-croacia-futbol-femenino'
    }, {
      id: 2,
      team: 'SFK 2000 SARAJEVO',
      country: 'BiH',
      years_es: '2007 - 2019',
      years_en: '2007 - 2019',
      years_pt: '2007 - 2019',
      image: require('../images/clubs/trayectoria/sfk-2000-sarajevo-bosnia-herzegovina-futbol-femenino.png'),
      alt: 'sfk-2000-sarajevo-bosnia-herzegovina-futbol-femenino'
    }, {
      id: 3,
      team: 'ŽNK TRAVNIK',
      country: 'BiH',
      years_es: '2006 - 2007',
      years_en: '2006 - 2007',
      years_pt: '2006 - 2007',
      image: require('../images/clubs/trayectoria/znk-travnik-bosnia-herzegovina-futbol-femenino.png'),
      alt: 'znk-travnik-bosnia-herzegovina-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'krof23',
    instagramId: '291121653',
    instagram: 'https://www.instagram.com/krof23/',
    twitter: '',
    facebook: 'https://www.facebook.com/Krofnica.23',
    tikTok: '',
    lastsCampaigns: [],
    press: [{
      id: 1,
      media: 'BIH FOOTBALL FEDERATION',
      url: 'https://www.nfsbih.ba/en/news/an-interview-with-aida-hadzic/',
      image: require('../images/players/aida-hadzic/press/aida-hadzic-prensa-bih-football-federation.jpg'),
      alt: 'aida-hadzic-prensa-bih-football-federation'
    }, {
      id: 2,
      media: 'ZIMBIO',
      url: 'https://www.zimbio.com/photos/Aida+Hadzic/England+Women+v+Bosnia+Women+UEFA+Women+Euro/WQb3Fh6UcgW',
      image: require('../images/players/aida-hadzic/press/aida-hadzic-prensa-bih-zimbio.jpg'),
      alt: 'aida-hadzic-prensa-bih-zimbio'
    }, {
      id: 3,
      media: 'THE FOOTBALL ASSOCIATION',
      url: 'http://www.thefa.com/news/2015/nov/29/euro-2017-qualifier-bosnia-herzegovina-ashton-gate-bristol-city-homecoming',
      image: require('../images/players/aida-hadzic/press/aida-hadzic-prensa-the-football-federation.jpg'),
      alt: 'aida-hadzic-prensa-the-football-federation'
    }, {
      id: 4,
      media: 'RADIO SARAJEVO',
      url: 'https://radiosarajevo.ba/sport/nogomet/hurem-zenski-se-fudbal-vrlo-brzo-razvija/324183',
      image: require('../images/players/aida-hadzic/press/aida-hadzic-prensa-radio-sarajevo.jpg'),
      alt: 'aida-hadzic-prensa-radio-sarajevo'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },/* {
    id: 2,
    title: '',
    metadescription: '',
    url: require('../images/players-profile-photo/aleksandra-zaremba-udg-tenerife.png'),
    alt: '',
    name: 'ALEKSANDRA ZAREMBA',
    nameUrl: 'aleksandra-zaremba',
    team: 'UD Granadilla Tenerife',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : '',
    mainPosition_en: '',
    mainPosition_pt: ' ',
    birthDate: '',
    birthYear: '1900',
    town: '',
    country: '',
    nationality_es : 'Polaca',
    nationality_en : '',
    nationality_pt: '',
    height: '',
    weight: '',
    signaturePhoto: '',,
    signaturePhotoAlt: '',
    headerPhotos: [],
    mobileHeaderPhoto: '',
    mobileHeaderPhotoAlt: '',
    nationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: '',
      team_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    internationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: '',
      team_en: '',
      team_pt: '',
      years_es: '',
      years_en: '',
      years_pt: ''
    }],
    individualAwards: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: '',
      years_pt: ''
    }],
    skilledLeg_es: '',
    skilledLeg_en: '',
    skilledLeg_pt: '',
    skilledLegNum: 35,
    technicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    tacticalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    psychologicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    physicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 0,
      team: '',
      country: '',
      years_es: '',
      years_en: '',
      years_pt: '',
      image: ''
    }],
    instagramAccessToken: '',
    instagramUsername: '',
    instagramId: '',
    instagram: '',
    twitter: '',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 0,
      brand: '',
      url: '',
      image: '',
    }],
    press: [{
      id: 0,
      media: '',
      url: '',
      image: '',
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },*/ {
    id: 3,
    title: 'UNIK TEAM | Andrea Sierra | Jugadora de fútbol femenino',
    metadescription: 'Andrea Sierra, futbolista española, actualmente juega como defensa en el Athletic Club de la Primera Iberdrola y en la Selección Española Sub-20.',
    url: require('../images/players-profile-photo/andrea-sierra-athletic-club-bilbao.png'),
    alt: 'andrea-sierra-athletic-club-bilbao',
    name: 'ANDREA SIERRA',
    nameUrl: 'andrea-sierra',
    team: 'Athletic Club',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: 'SEL. ESPAÑOLA SUB-20',
    selection_en: 'SPAIN U20',
    selection_pt: 'SEL. ESPANHOLA SUB-20',
    mainPosition_es : 'DEFENSA',
    mainPosition_en: 'DEFENDER',
    mainPosition_pt: 'DEFESA',
    birthDate: '15 - 05 - 1998',
    birthYear: '1998',
    town: 'Bilbao',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,69',
    weight: '62',
    signaturePhoto: require('../images/players/andrea-sierra/jugadora-andrea-sierra-unik-team.png'),
    signaturePhotoAlt: 'jugadora-andrea-sierra-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/andrea-sierra/header/andrea-sierra-athletic-club-bilbao.jpg'),
      alt: 'andrea-sierra-athletic-club-bilbao'
    }, {
      id: 2,
      url: require('../images/players/andrea-sierra/header/andrea-sierra-athletic-club-bilbao-femenino.jpg'),
      alt: 'andrea-sierra-athletic-club-bilbao-femenino'
    }, {
      id: 3,
      url: require('../images/players/andrea-sierra/header/andrea-sierra-seleccion-espana-femenino.jpg'),
      alt: 'andrea-sierra-seleccion-espana-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/andrea-sierra/header-mobile/andrea-sierra-athletic-club-bilbao.jpg'),
    mobileHeaderPhotoAlt: 'andrea-sierra-athletic-club-bilbao',
    nationalTournament: [],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Subcampeona del',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'Mundial Sub-20',
      nameSecondRow_en: 'U-20 World Cup',
      nameSecondRow_pt: 'Copa do Mundo Sub-20',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Francia 2018',
      years_en: 'France 2018',
      years_pt: 'França 2018',
    }, {
      id: 2,
      nameFirstRow_es: 'Eurocopa Sub-19',
      nameFirstRow_en: 'UEFA Euro U-19',
      nameFirstRow_pt: 'Eurocopa Sub-19',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Irlanda del Norte 2017',
      years_en: 'Northern Ireland 2017',
      years_pt: 'Irlanda del Norte  2017'
    }, {
      id: 3,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã de la',
      nameSecondRow_es: 'Eurocopa Sub-19',
      nameSecondRow_en: 'UEFA Euro U-19',
      nameSecondRow_pt: 'Eurocopa Sub-19',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Eslovaquia 2016',
      years_en: 'Slovakia 2016',
      years_pt: 'Eslovaquia 2016'
    }, {
      id: 4,
      nameFirstRow_es: 'Eurocopa Sub-17',
      nameFirstRow_en: 'UEFA Euro U-17',
      nameFirstRow_pt: 'Eurocopa Sub-17',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Islandia 2015',
      years_en: 'Iceland 2015',
      years_pt: 'Islandia 2015'
    }],
    individualAwards: [],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Clearing the ball',
      characteristic_pt: 'Saída de bola',
      characteristic_es: 'Salida de balón',
      num: 32
    }, {
      id: 2,
      characteristic_en: 'Aerial ball control',
      characteristic_pt: 'Jogo aéreo',
      characteristic_es: 'Juego aéreo',
      num: 19
    }, {
      id: 3,
      characteristic_en: 'Short passes',
      characteristic_pt: 'Passe curto',
      characteristic_es: 'Pase corto',
      num: 24
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Space control',
      characteristic_pt: 'Colocação no campo',
      characteristic_es: 'Colocación',
      num: 6
    }, {
      id: 2,
      characteristic_en: 'Defensive player',
      characteristic_pt: 'Jogadora defensiva',
      characteristic_es: 'Criterio defensivo',
      num: 10
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Rational thinking',
      characteristic_pt: 'Racional',
      characteristic_es: 'Racional',
      num: 28
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Strenght',
      characteristic_pt: 'Força',
      characteristic_es: 'Fuerza',
      num: 17
    }, {
      id: 2,
      characteristic_en: 'Resistance',
      characteristic_pt: 'Resistência',
      characteristic_es: 'Resistencia',
      num: 31
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: true,
      position: 'DFCi'
    },{
      id: 3,
      selected: true,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: true,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'ATHLETIC CLUB',
      country: 'España',
      years_es: '2016 - Actualidad',
      years_en: '2016 - Currently',
      years_pt: '2016 - Presente',
      image: require('../images/clubs/trayectoria/athletic-club-bilbao-espana-futbol-femenino.png'),
      alt: 'athletic-club-bilbao-espana-futbol-femenino'
    }, {
      id: 2,
      team: 'ATHLETIC CLUB B',
      country: 'España',
      years_es: '2014 - 2017',
      years_en: '2014 - 2017',
      years_pt: '2014 - 2017',
      image: require('../images/clubs/trayectoria/athletic-club-bilbao-espana-futbol-femenino.png'),
      alt: 'athletic-club-bilbao-espana-futbol-femenino'
    }, {
      id: 3,
      team: 'BIZKERRE CADETE',
      country: 'España',
      years_es: '2013 - 2014',
      years_en: '2013 - 2014',
      years_pt: '2013 - 2014',
      image: require('../images/clubs/trayectoria/bizkerre-cadete-espana-futbol-femenino.png'),
      alt: 'bizkerre-cadete-espana-futbol-femenino'
    }, {
      id: 4,
      team: 'LOIOLA INDAUTXU',
      country: 'España',
      years_es: '2006 - 2012',
      years_en: '2006 - 2012',
      years_pt: '2006 - 2012',
      image: require('../images/clubs/trayectoria/loiola-indautxu-espana-futbol-femenino.png'),
      alt: 'loiola-indautxu-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'andreasierra_10',
    instagramId: '432028122',
    instagram: 'https://www.instagram.com/andreasierra_10/',
    twitter: 'https://twitter.com/Andreasierraa2',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'SPRINTER',
      url: '',
      image: require('../images/players/andrea-sierra/last-campaigns/campana-andrea-sierra-sprinterball-challenge.jpg'),
      alt: 'campana-andrea-sierra-sprinterball-challenge'
    }, {
      id: 2,
      brand: 'MAGARI SOUND',
      url: '',
      image: require('../images/players/andrea-sierra/last-campaigns/campana-andrea-sierra-magari-sound.jpg'),
      alt: 'campana-andrea-sierra-magari-sound'
    }],
    press: [{
      id: 1,
      media: 'EL CORREO',
      url: 'https://www.elcorreo.com/deporte-femenino/futbol/athletic-femenino/andrea-sierra-equipo-20181206154530-nt.html',
      image: require('../images/players/andrea-sierra/press/andrea-sierra-prensa-el-correo.jpg'),
      alt: 'andrea-sierra-prensa-el-correo'
    }, {
      id: 2,
      media: 'DEIA',
      url: 'https://www.deia.eus/athletic/athletic-femenino/2020/01/12/andrea-sierra-quita-victoria-levante/1011286.html',
      image: require('../images/players/andrea-sierra/press/andrea-sierra-prensa-deia.jpg'),
      alt: 'andrea-sierra-prensa-deia'
    }, {
      id: 3,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/athletic-bilbao/20200113/472862079573/athletic-andrea-sierra-levante-real-sociedad.html',
      image: require('../images/players/andrea-sierra/press/andrea-sierra-prensa-mundo-deportivo.jpg'),
      alt: 'andrea-sierra-prensa-mundo-deportivo'
    }, {
      id: 4,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2020/04/18/femenino/1587238145_148632.html',
      image: require('../images/players/andrea-sierra/press/andrea-sierra-prensa-diario-AS.jpg'),
      alt: 'andrea-sierra-prensa-diario-AS'
    }, {
      id: 5,
      media: 'EL DESMARQUE',
      url: 'https://eldesmarque.com/actualidad/futbol/1368028-andrea-sierra-le-quita-la-victoria-al-levante-en-el-ultimo-suspiro',
      image: require('../images/players/andrea-sierra/press/andrea-sierra-prensa-el-desmarque.jpg'),
      alt: 'andrea-sierra-prensa-el-desmarque'
    }, {
      id: 6,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/athletic-bilbao/20180823/451411161431/athletic-mundial-sub-20-japon-final-damaris-andrea-sierra-oroz-lucia.html',
      image: require('../images/players/andrea-sierra/press/andrea-sierra-prensa-mundo-deportivo-2.jpg'),
      alt: 'andrea-sierra-prensa-mundo-deportivo-2'
    }, {
      id: 7,
      media: 'EL DESMARQUE',
      url: 'https://eldesmarque.com/bizkaia/athletic-club/243893-nueve-leonas-en-el-futbol-draft-19-20',
      image: require('../images/players/andrea-sierra/press/andrea-sierra-prensa-el-desmarque-2.jpg'),
      alt: 'andrea-sierra-prensa-el-desmarque-2'
    }, {
      id: 8,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/athletic-bilbao/20200112/472849957552/athletic-levante-andrea-sierra-villamcapa.html',
      image: require('../images/players/andrea-sierra/press/andrea-sierra-prensa-mundo-deportivo-3.jpg'),
      alt: 'andrea-sierra-prensa-mundo-deportivo-3'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },/* {
    id: 4,
    title: '',
    metadescription: '',
    url: require('../images/players-profile-photo/athenea-del-castillo-rc-deportivo-de-la-coruna.png'),
    alt: '',
    name: 'ATHENEA DEL CASTILLO',
    nameUrl: 'athenea-del-castillo',
    team: 'RC Deportivo',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : '',
    mainPosition_en: '',
    mainPosition_pt: ' ',
    birthDate: '',
    birthYear: '1900',
    town: '',
    country: '',
    nationality_es : 'Española',
    nationality_en : '',
    nationality_pt: '',
    height: '',
    weight: '',
    signaturePhoto: '',
    signaturePhotoAlt: '',
    headerPhotos: [],
    mobileHeaderPhoto: '',
    mobileHeaderPhotoAlt: '',
    nationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: '',
      team_en: '',
      team_pt: '',
      years_es: '',
      years_en: '',
      years_pt: '',
    }],
    internationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: '',
      team_en: '',
      team_pt: '',
      years_es: '',
      years_en: '',
      years_pt: '',
    }],
    individualAwards: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: '',
      years_pt: '',
    }],
    skilledLeg_es: '',
    skilledLeg_en: '',
    skilledLeg_pt: '',
    skilledLegNum: 35,
    technicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    tacticalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    psychologicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    physicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 0,
      team: '',
      country: '',
      years_es: '',
      years_en: '',
      years_pt: '',
      image: ''
    }],
    instagramAccessToken: '',
    instagramUsername: '',
    instagramId: '',
    instagram: '',
    twitter: '',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 0,
      brand: '',
      url: '',
      image: '',
    }],
    press: [{
      id: 0,
      media: '',
      url: '',
      image: '',
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },*/ {
    id: 5,
    title: 'UNIK TEAM | Brenda Pérez | Jugadora de fútbol femenino',
    metadescription: 'Brenda Pérez, futbolista española, actualmente juega como centrocampista en el Levante UD de la Primera Iberdrola.',
    url: require('../images/players-profile-photo/brenda-perez-rcd-espanyol.png'),
    alt: 'brenda-perez-rcd-espanyol',
    name: 'BRENDA PÉREZ',
    nameUrl: 'brenda-perez',
    team: 'RCD Espanyol',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : 'CENTROCAMPISTA',
    mainPosition_en: 'MIDFIELDER',
    mainPosition_pt: 'MEIO-CAMPISTA',
    birthDate: '27 - 06 - 1993',
    birthYear: '1993',
    town: 'Vilassar de Dalt',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,56',
    weight: '49',
    signaturePhoto: require('../images/players/brenda-perez/jugadora-brenda-perez-unik-team.png'),
    signaturePhotoAlt: 'jugadora-brenda-perez-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/brenda-perez/header/brenda-perez-rcd-espanyol-femenino.jpg'),
      alt: 'brenda-perez-rcd-espanyol-femenino'
    }, {
      id: 2,
      url: require('../images/players/brenda-perez/header/brenda-perez-rcd-espanyol-futbol-femenino.jpg'),
      alt: 'brenda-perez-rcd-espanyol-futbol-femenino'
    }, {
      id: 3,
      url: require('../images/players/brenda-perez/header/jugadora-brenda-perez-rcd-espanyol.jpg'),
      alt: 'jugadora-brenda-perez-rcd-espanyol'
    }],
    mobileHeaderPhoto: require('../images/players/brenda-perez/header-mobile/brenda-perez-rcd-espanyol.jpg'),
    mobileHeaderPhotoAlt: 'brenda-perez-rcd-espanyol',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'Copa Catalunya',
      nameSecondRow_en: 'Catalonia Cup',
      nameSecondRow_pt: 'Copa Catalunha',
      team_es: 'RCD Espanyol',
      team_en: 'RCD Espanyol',
      team_pt: 'RCD Espanyol',
      years_es: '2012 , 2013 , 2016 , 2017 , 2018 y 2019',
      years_en: '2012, 2013, 2016, 2017, 2018 and 2019',
      years_pt: '2012, 2013, 2016, 2017, 2018 e 2019',
    }, {
      id: 2,
      nameFirstRow_es: 'Copa de España',
      nameFirstRow_en: 'Spanish Cup',
      nameFirstRow_pt: 'Copa da Espanha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'RCD Espanyol',
      team_en: 'RCD Espanyol',
      team_pt: 'RCD Espanyol',
      years_es: '2012',
      years_en: '2012',
      years_pt: '2012',
    }],
    internationalTournament: [],
    individualAwards: [],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Assists',
      characteristic_pt: 'Assistências',
      characteristic_es: 'Asistencias',
      num: 2
    }, {
      id: 2,
      characteristic_en: 'Ball handling',
      characteristic_pt: 'Condução',
      characteristic_es: 'Conducción',
      num: 8
    }, {
      id: 3,
      characteristic_en: 'Medium range passes',
      characteristic_pt: 'Passe médio',
      characteristic_es: 'Pase medio',
      num: 26
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }, {
      id: 2,
      characteristic_en: 'Vision',
      characteristic_pt: 'Visão de jogo',
      characteristic_es: 'Visión de juego',
      num: 34
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Speed',
      characteristic_pt: 'Velocidade',
      characteristic_es: 'Velocidad',
      num: 33
    }, {
      id: 2,
      characteristic_en: 'Resistance',
      characteristic_pt: 'Resistência',
      characteristic_es: 'Resistencia',
      num: 31
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: true,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: true,
      position: 'INTi'
    },{
      id: 9,
      selected: true,
      position: 'INTd'
    },{
      id: 10,
      selected: true,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: true,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'RCD ESPANYOL',
      country: 'España',
      years_es: '2016 - Actualidad',
      years_en: '2016 - Currently',
      years_pt: '2016 - Presente',
      image: require('../images/clubs/trayectoria/rcd-espanyol-espana-futbol-femenino.png'),
      alt: 'rcd-espanyol-espana-futbol-femenino'
    }, {
      id: 2,
      team: 'C.D. CANILLAS',
      country: 'España',
      years_es: '2015 - 2016',
      years_en: '2015 - 2016',
      years_pt: '2015 - 2016',
      image: require('../images/clubs/trayectoria/cd-canillas-espana-futbol-femenino.png'),
      alt: 'cd-canillas-espana-futbol-femenino'
    }, {
      id: 3,
      team: 'ATLÉTICO MADRID',
      country: 'España',
      years_es: '2014 - 2015',
      years_en: '2014 - 2015',
      years_pt: '2014 - 2015',
      image: require('../images/clubs/trayectoria/atletico-de-madrid-espana-futbol-femenino.png'),
      alt: 'atletico-de-madrid-espana-futbol-femenino'
    }, {
      id: 4,
      team: 'VALENCIA FC',
      country: 'España',
      years_es: '2014',
      years_en: '2014',
      years_pt: '2014',
      image: require('../images/clubs/trayectoria/valencia-cf-espana-futbol-femenino.png'),
      alt: 'valencia-cf-espana-futbol-femenino'
    }, {
      id: 5,
      team: 'CE. SANT GABRIEL',
      country: 'España',
      years_es: '2013 - 2014',
      years_en: '2013 - 2014',
      years_pt: '2013 - 2014',
      image: require('../images/clubs/trayectoria/ce-sant-gabriel-espana-futbol-femenino.png'),
      alt: 'ce-sant-gabriel-espana-futbol-femenino'
    }, {
      id: 6,
      team: 'RCD ESPANYOL',
      country: 'España',
      years_es: '2011 - 2013',
      years_en: '2011 - 2013',
      years_pt: '2011 - 2013',
      image: require('../images/clubs/trayectoria/rcd-espanyol-espana-futbol-femenino.png'),
      alt: 'rcd-espanyol-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'brendaps19',
    instagramId: '230811360',
    instagram: 'https://www.instagram.com/brendaps19/',
    twitter: 'https://twitter.com/bbrendaperez',
    facebook: 'https://www.facebook.com/Brendaps93/',
    tikTok: 'https://vm.tiktok.com/JJPufFL/',
    lastsCampaigns: [{
      id: 1,
      brand: 'MIRÓ',
      url: 'https://www.instagram.com/p/B3DFRKxoQV-/',
      image: require('../images/players/brenda-perez/last-campaigns/campana-brenda-perez-miro.jpg'),
      alt: 'campana-brenda-perez-miro'
    }, {
      id: 2,
      brand: 'FUTBOLMANIA',
      url: 'https://www.youtube.com/channel/UCaRhCmiMws7tUPQlsAkoebQ/search?query=brenda+perez',
      image: require('../images/players/brenda-perez/last-campaigns/campana-brenda-perez-futbolmania.jpg'),
      alt: 'campana-brenda-perez-futbolmania'
    }, {
      id: 3,
      brand: 'MAGARI SOUND',
      url: '',
      image: require('../images/players/brenda-perez/last-campaigns/campana-brenda-perez-magari-sound.jpg'),
      alt: 'campana-brenda-perez-magari-sound'
    }, {
      id: 4,
      brand: 'ADIDAS FOOTBALL',
      url: 'https://www.instagram.com/p/Bwc3eRCnhc7/',
      image: require('../images/players/brenda-perez/last-campaigns/campana-brenda-perez-adidas-football.jpg'),
      alt: 'campana-brenda-perez-adidas-football'
    }, {
      id: 5,
      brand: 'SHOCK ABSORBER',
      url: 'https://www.facebook.com/watch/?v=1852821251598620',
      image: require('../images/players/brenda-perez/last-campaigns/campana-brenda-perez-shock-absorber.jpg'),
      alt: 'campana-brenda-perez-shock-absorber'
    }, {
      id: 6,
      brand: 'SAK PROJECT',
      url: '',
      image: require('../images/players/brenda-perez/last-campaigns/campana-brenda-perez-sak-project.jpg'),
      alt: 'campana-brenda-perez-sak-project'
    }, {
      id: 7,
      brand: 'FILMIN',
      url: '',
      image: require('../images/players/brenda-perez/last-campaigns/campana-brenda-perez-filmin.jpg'),
      alt: 'campana-brenda-perez-filmin'
    }, {
      id: 8,
      brand: 'AIRE ANCIENT BATHS',
      url: '',
      image: require('../images/players/brenda-perez/last-campaigns/activacion-brenda-perez-aire-ancient-baths.jpg'),
      alt: 'activacion-brenda-perez-aire-ancient-baths'
    }, {
      id: 9,
      brand: 'FEED',
      url: '',
      image: require('../images/players/brenda-perez/last-campaigns/campana-brenda-perez-feed.jpg'),
      alt: 'campana-brenda-perez-feed'
    }, {
      id: 10,
      brand: 'MOVO',
      url: 'https://www.instagram.com/p/CAD4OSJgQOh/',
      image: require('../images/players/brenda-perez/last-campaigns/campana-brenda-perez-movo.jpg'),
      alt: 'campana-brenda-perez-movo'
    }, {
      id: 11,
      brand: 'VIDAL',
      url: 'https://www.instagram.com/p/Bhr1IfXB94u/',
      image: require('../images/players/brenda-perez/last-campaigns/campana-brenda-perez-vidal.jpg'),
      alt: 'campana-brenda-perez-vidal'
    }, {
      id: 12,
      brand: 'OBLACK',
      url: 'https://www.instagram.com/p/B97gmc0KTC1/',
      image: require('../images/players/brenda-perez/last-campaigns/campana-brenda-perez-oblack.jpg'),
      alt: 'campana-brenda-perez-oblack'
    }],
    press: [{
      id: 1,
      media: 'LA VANGUARDIA',
      url: 'https://www.lavanguardia.com/deportes/rcde-espanyol/femenino/20190610/462770269512/brenda-perez-renovacion-espanyol.html',
      image: require('../images/players/brenda-perez/press/brenda-perez-prensa-la-vanguardia.jpg'),
      alt: 'brenda-perez-prensa-la-vanguardia'
    }, {
      id: 2,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/primera-division/2020/03/31/5e833f0d268e3e7f698b45dd.html',
      image: require('../images/players/brenda-perez/press/brenda-perez-prensa-marca.jpg'),
      alt: 'brenda-perez-prensa-marca'
    }, {
      id: 3,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/femenino/20160930/41580799492/brenda-perez-una-mujer-de-bandera.html',
      image: require('../images/players/brenda-perez/press/brenda-perez-prensa-mundo-deportivo.jpg'),
      alt: 'brenda-perez-prensa-mundo-deportivo'
    }, {
      id: 4,
      media: 'LA VANGUARDIA',
      url: 'https://www.lavanguardia.com/deportes/rcde-espanyol/femenino/20190408/461533402358/espanyol-femenino-atletico-brenda-perez-maria-llompart-elba-verges.html',
      image: require('../images/players/brenda-perez/press/brenda-perez-prensa-la-vanguardia.jpg'),
      alt: 'brenda-perez-prensa-la-vanguardia'
    }, {
      id: 5,
      media: 'RCD ESPANYOL',
      url: 'https://www.rcdespanyol.com/es/actualidad/un-cafe-con-brenda-perez/_n:11034/',
      image: require('../images/players/brenda-perez/press/brenda-perez-prensa-rcd-espanyol.jpg'),
      alt: 'brenda-perez-prensa-rcd-espanyol'
    }, {
      id: 6,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/2017/05/18/591daedee5fdea311b8b4578.html',
      image: require('../images/players/brenda-perez/press/brenda-perez-prensa-marca-2.jpg'),
      alt: 'brenda-perez-prensa-marca-2'
    }, {
      id: 7,
      media: 'BESOCCER',
      url: 'https://es.besoccer.com/noticia/brenda-perez-renueva-con-el-espanyol-hasta-el-2020-651233',
      image: require('../images/players/brenda-perez/press/brenda-perez-prensa-besoccer.jpg'),
      alt: 'brenda-perez-prensa-besoccer'
    }, {
      id: 8,
      media: 'ÚLTIMA HORA',
      url: 'https://www.ultimahora.es/noticias/sociedad/2015/11/05/166128/hormiguero-demuestra-futbol-solo-cosa-chicos.html',
      image: require('../images/players/brenda-perez/press/brenda-perez-prensa-ultima-hora.jpg'),
      alt: 'brenda-perez-prensa-ultima-hora'
    }, {
      id: 9,
      media: 'SPORTFEM',
      url: 'https://www.sportfem.es/2020/01/28/brenda-perez-futbolista-del-espanyol-creci-con-un-balon-en-los-pies/',
      image: require('../images/players/brenda-perez/press/brenda-perez-prensa-sportfem.jpg'),
      alt: 'brenda-perez-prensa-sportfem'
    }, {
      id: 10,
      media: 'RCD ESPANYOL',
      url: 'https://www.rcdespanyol.com/es/actualidad/los-pericos-entrevistan-a-brenda-perez/_n:11028/',
      image: require('../images/players/brenda-perez/press/brenda-perez-prensa-rcd-espanyol-2.jpg'),
      alt: 'brenda-perez-prensa-rcd-espanyol-2'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 6,
    title: 'UNIK TEAM | Carla Armengol | Jugadora de fútbol femenino',
    metadescription: 'Carla Armengol, futbolista española, actualmente juega como delantera en el Sevilla de la Primera Iberdrola y en la Selección Española Sub-20.',
    url: require('../images/players-profile-photo/carla-armengol-sevilla.png'),
    alt: 'carla-armengol-sevilla',
    name: 'CARLA ARMENGOL',
    nameUrl: 'carla-armengol',
    team: 'Sevilla FC',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : 'DELANTERA',
    mainPosition_en: 'FORWARD',
    mainPosition_pt: 'ATACANTE',
    birthDate: '02 - 04 - 1998',
    birthYear: '1998',
    town: 'Barcelona',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,64',
    weight: '52',
    signaturePhoto: require('../images/players/carla-armengol/jugadora-carla-armengol-sevilla-unik-team.png'),
    signaturePhotoAlt: 'jugadora-carla-armengol-sevilla-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/carla-armengol/header/carla-armengol-fc-barcelona.jpg'),
      alt: 'carla-armengol-fc-barcelona'
    }, {
      id: 2,
      url: require('../images/players/carla-armengol/header/carla-armengol-fc-barcelona-femenino.jpg'),
      alt: 'carla-armengol-fc-barcelona-femenino'
    }, {
      id: 3,
      url: require('../images/players/carla-armengol/header/carla-armengol-fc-barcelona-futbol-femenino.jpg'),
      alt: 'carla-armengol-fc-barcelona-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/carla-armengol/header-mobile/carla-armengol-fc-barcelona.jpg'),
    mobileHeaderPhotoAlt: 'carla-armengol-fc-barcelona',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2019 - 2020',
      years_en: '2019 - 2020',
      years_pt: '2019 - 2020',
    }, {
      id: 2,
      nameFirstRow_es: 'Supercopa de España',
      nameFirstRow_en: 'Spanish Supercup',
      nameFirstRow_pt: 'Supercopa da Espanha',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2019 - 2020',
      years_en: '2019 - 2020',
      years_pt: '2019 - 2020'
    }, {
      id: 3,
      nameFirstRow_es: 'Copa de España',
      nameFirstRow_en: 'Spanish Cup',
      nameFirstRow_pt: 'Copa da Espanha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    }, {
      id: 4,
      nameFirstRow_es: 'Copa Catalunya',
      nameFirstRow_en: 'Catalonia Cup',
      nameFirstRow_pt: 'Copa Catalunha',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2018 y 2019',
      years_en: '2018 and 2019',
      years_pt: '2018 e 2019'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'Champions League',
      nameSecondRow_en: 'Champions League',
      nameSecondRow_pt: 'Champions League',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2019',
      years_en: '2018-2019',
      years_pt: '2019',
    }, {
      id: 2,
      nameFirstRow_es: 'Torneo de desarrollo',
      nameFirstRow_en: 'UEFA U-16',
      nameFirstRow_pt: 'Torneio de Desenvolvimento',
      nameSecondRow_es: 'UEFA Sub-16',
      nameSecondRow_en: 'Development Tournament',
      nameSecondRow_pt: 'UEFA Sub-16',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Serbia 2014',
      years_en: 'Serbia 2014',
      years_pt: 'Serbia 2014'
    }],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Máxima Goleadora',
      nameFirstRow_en: 'Top Scorer',
      nameFirstRow_pt: 'Artilheira',
      nameSecondRow_es: 'Barça B',
      nameSecondRow_en: 'Barça B',
      nameSecondRow_pt: 'Barça B',
      years_es: '2018 - 2019',
      years_en: '2018 - 2019',
      years_pt: '2018 - 2019'
    }],
    skilledLeg_es: 'Ambidiestra',
    skilledLeg_en: 'Ambidextrous',
    skilledLeg_pt: 'Ambidestra',
    skilledLegNum: 1,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Goals',
      characteristic_pt: 'Gol',
      characteristic_es: 'Gol',
      num: 18
    }, {
      id: 2,
      characteristic_en: 'Assists',
      characteristic_pt: 'Assistências',
      characteristic_es: 'Asistencias',
      num: 2
    }, {
      id: 3,
      characteristic_en: 'Dribbling',
      characteristic_pt: 'Drible',
      characteristic_es: 'Regate',
      num: 30
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }, {
      id: 2,
      characteristic_en: 'Skilled passer',
      characteristic_pt: 'Ligação',
      characteristic_es: 'Asociación',
      num: 3
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Speed',
      characteristic_pt: 'Velocidade',
      characteristic_es: 'Velocidad',
      num: 33
    }, {
      id: 2,
      characteristic_en: 'Agility/Flexibility',
      characteristic_pt: 'Agilidade/Flexibilidade',
      characteristic_es: 'Agilidad/Flexibilidad',
      num: 15
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: true,
      position: 'INTi'
    },{
      id: 9,
      selected: true,
      position: 'INTd'
    },{
      id: 10,
      selected: true,
      position: 'MP'
    },{
      id: 11,
      selected: true,
      position: 'EXTi'
    },{
      id: 12,
      selected: true,
      position: 'EXTd'
    },{
      id: 13,
      selected: true,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'SEVILLA FC',
      country: 'España',
      years_es: '2020 - Cedida',
      years_en: '2020 - Loan',
      years_pt: '2020 - Cedida',
      image: require('../images/clubs/trayectoria/sevilla-fc-espana-futbol-femenino.png'),
      alt: 'sevilla-fc-espana-futbol-femenino'
    },{
      id: 2,
      team: 'FC BARCELONA',
      country: 'España',
      years_es: '2019 - Actualidad',
      years_en: '2019 - Currently',
      years_pt: '2019 - Presente',
      image: require('../images/clubs/trayectoria/fc-barcelona-espana-futbol-femenino.png'),
      alt: 'fc-barcelona-espana-futbol-femenino'
    }, {
      id: 3,
      team: 'FC BARCELONA B',
      country: 'España',
      years_es: '2010 - 2019',
      years_en: '2010 - 2019',
      years_pt: '2010 - 2019',
      image: require('../images/clubs/trayectoria/fc-barcelona-espana-futbol-femenino.png'),
      alt: 'fc-barcelona-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'carlarmengol',
    instagramId: '363354688',
    instagram: 'https://www.instagram.com/carlarmengol/',
    twitter: 'https://twitter.com/carlarmengol',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'SPRINTER',
      url: '',
      image: require('../images/players/carla-armengol/last-campaigns/campana-carla-armengol-sprinterball-challenge.jpg'),
      alt: 'campana-carla-armengol-sprinterball-challenge'
    }, {
      id: 2,
      brand: 'BRANDY Y BLOND',
      url: 'https://www.instagram.com/p/B7UHfxyons6/',
      image: require('../images/players/carla-armengol/last-campaigns/campana-carla-armengol-brand-y-blond.jpg'),
      alt: 'campana-carla-armengol-brand-y-blond'
    }, {
      id: 3,
      brand: 'GIANNI KAVANAGH',
      url: 'https://www.instagram.com/p/B-DN22pK8h2/',
      image: require('../images/players/carla-armengol/last-campaigns/campana-carla-armengol-gianni-kavanagh.jpg'),
      alt: 'campana-carla-armengol-gianni-kavanagh'
    }, {
      id: 4,
      brand: 'PERE COAST',
      url: 'https://www.instagram.com/p/B_A5lwOqRxx/',
      image: require('../images/players/carla-armengol/last-campaigns/campana-carla-armengol-pere-coast.jpg'),
      alt: 'campana-carla-armengol-pere-coast'
    }],
    press: [{
      id: 1,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/fc-barcelona/20191112/471444213380/carla-armengol-sueno-con-ganar-la-champions-con-el-barca.html',
      image: require('../images/players/carla-armengol/press/carla-armengol-prensa-mundo-deportivo.jpg'),
      alt: 'carla-armengol-prensa-mundo-deportivo'
    }, {
      id: 2,
      media: 'SPORT',
      url: 'https://www.sport.es/es/noticias/futbol-femenino-barca/carla-armengol-segunda-alumna-barca-escola-llegar-primer-equipo-7707623',
      image: require('../images/players/carla-armengol/press/carla-armengol-prensa-sport.jpg'),
      alt: 'carla-armengol-prensa-sport'
    }, {
      id: 3,
      media: 'VAVEL',
      url: 'https://www.vavel.com/es/futbol/2019/10/30/fc-barcelona/1001877-carla-armengol-tenia-ganas-de-disputar-unos-minutos-con-el-primer-equipo.html',
      image: require('../images/players/carla-armengol/press/carla-armengol-prensa-vavel.jpg'),
      alt: 'carla-armengol-prensa-vavel'
    }, {
      id: 4,
      media: 'SPORT',
      url: 'https://www.sport.es/es/videos/barca/20191011/melanie-serrano-vs-laia-codina-y-carla-armengol-cuanto-se-conocen/4697008.shtml',
      image: require('../images/players/carla-armengol/press/carla-armengol-prensa-sport_2.jpg'),
      alt: 'carla-armengol-prensa-sport_2'
    }, {
      id: 5,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/fc-barcelona/20190629/463162296419/laia-codina-y-carla-armengol-estaran-en-dinamica-del-primer-equipo.html',
      image: require('../images/players/carla-armengol/press/carla-armengol-prensa-mundo-deportivo_2.jpg'),
      alt: 'carla-armengol-prensa-mundo-deportivo_2'
    }, {
      id: 6,
      media: 'SPORT',
      url: 'https://www.sport.es/es/noticias/futbol-femenino-barca/dos-generaciones-barca-melanie-serrano-carla-armengol-laia-codina-7676890',
      image: require('../images/players/carla-armengol/press/carla-armengol-prensa-sport_3.jpg'),
      alt: 'carla-armengol-prensa-sport_3'
    }, {
      id: 7,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/fc-barcelona/20191031/471300217813/carla-armengol-fcb-femeni-barca-debut-fc-barcelona.html',
      image: require('../images/players/carla-armengol/press/carla-armengol-prensa-mundo-deportivo_3.jpg'),
      alt: 'carla-armengol-prensa-mundo-deportivo_3'
    }, {
      id: 8,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/fc-barcelona/20200203/473276976625/barca-femenino-cantera-masia-primera-iberdrola.html',
      image: require('../images/players/carla-armengol/press/carla-armengol-prensa-mundo-deportivo_4.jpg'),
      alt: 'carla-armengol-prensa-mundo-deportivo_4'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 7,
    title: 'UNIK TEAM | Cata Coll | Jugadora de fútbol femenino',
    metadescription: 'Cata Coll, futbolista española, actualmente juega como portera en el FC Barcelona de la Primera Iberdrola y en la Selección Española Sub-20.',
    url: require('../images/players-profile-photo/cata-coll-barcelona.png'),
    alt: 'cata-coll-barcelona',
    name: 'CATA COLL',
    nameUrl: 'cata-coll',
    team: 'FC Barcelona',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: 'SEL. ESPAÑOLA',
    selection_en: 'SPANISH TEAM',
    selection_pt: 'SEL. ESPANHOLA',
    mainPosition_es : 'PORTERA',
    mainPosition_en: 'GOALKEEPER',
    mainPosition_pt: 'GOLEIRA',
    birthDate: '23 - 04 - 2001',
    birthYear: '2001',
    town: 'Pòrtol',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,70',
    weight: '68',
    signaturePhoto: require('../images/players/cata-coll/jugadora-cata-coll-barcelona-unikteam.png'),
    signaturePhotoAlt: 'jugadora-cata-coll-barcelona-unikteam',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/cata-coll/header/cata-coll-seleccion-espana-femenino.jpg'),
      alt: 'cata-coll-seleccion-espana-femenino'
    }, {
      id: 2,
      url: require('../images/players/cata-coll/header/cata-coll-seleccion-espana-futbol-femenino.jpg'),
      alt: 'cata-coll-seleccion-espana-futbol-femenino'
    }, {
      id: 3,
      url: require('../images/players/cata-coll/header/portera-cata-coll-seleccion-espanola-femenina.jpg'),
      alt: 'portera-cata-coll-seleccion-espanola-femenina'
    }],
    mobileHeaderPhoto: require('../images/players/cata-coll/header-mobile/cata-coll-seleccion-espana-futbol-femenino.jpg'),
    mobileHeaderPhotoAlt: 'cata-coll-seleccion-espana-futbol-femenino',
    nationalTournament: [],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Mundial Sub-17',
      nameFirstRow_en: 'U-17 World Cup',
      nameFirstRow_pt: 'Copa do Mundo Sub-17',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Uruguay 2018',
      years_en: 'Uruguay 2018',
      years_pt: 'Uruguay 2018',
    }, {
      id: 2,
      nameFirstRow_es: 'Eurocopa Sub-17',
      nameFirstRow_en: 'UEFA Euro U-17',
      nameFirstRow_pt: 'Eurocopa Sub-17',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Lituania 2018',
      years_en: 'Lithuania 2018',
      years_pt: 'Lituânia 2018'
    }, {
      id: 3,
      nameFirstRow_es: 'Subcampeona del',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'Mundial Sub-20',
      nameSecondRow_en: 'U-20 World Cup',
      nameSecondRow_pt: 'Copa do Mundo Sub-20',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Francia 2018',
      years_en: 'France 2018',
      years_pt: 'França 2018'
    }, {
      id: 4,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'Eurocopa Sub-17',
      nameSecondRow_en: 'UEFA Euro U-17',
      nameSecondRow_pt: 'Eurocopa Sub-17',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'República Checa 2017',
      years_en: 'Czech Republic 2017',
      years_pt: 'República Checa 2017'
    }, {
      id: 5,
      nameFirstRow_es: 'Tercer lugar en el ',
      nameFirstRow_en: 'Third place in the',
      nameFirstRow_pt: 'Terceiro lugar na',
      nameSecondRow_es: 'Mundial Sub-17',
      nameSecondRow_en: 'U-17 World Cup',
      nameSecondRow_pt: 'Copa do Mundo Sub-17',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Jordania 2016',
      years_en: 'Jordan 2016',
      years_pt: 'Jordânia 2016'
    }, {
      id: 6,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'Eurocopa Sub-17',
      nameSecondRow_en: 'UEFA Euro U-17',
      nameSecondRow_pt: 'Eurocopa Sub-17',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Bielorrusia 2016',
      years_en: 'Belarus 2016',
      years_pt: 'Bielorrúsia 2016'
    }],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Guante de Oro Adidas',
      nameFirstRow_en: 'Adidas Golden Glove',
      nameFirstRow_pt: 'Luva de Ouro Adidas',
      nameSecondRow_es: 'Mundial Sub-17',
      nameSecondRow_en: 'U-17 World Cup',
      nameSecondRow_pt: 'Copa do Mundo Sub-17',
      years_es: 'Uruguay 2018',
      years_en: 'Uruguay 2018',
      years_pt: 'Uruguai 2018'
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Blocking',
      characteristic_pt: 'Agarrar',
      characteristic_es: 'Blocaje',
      num: 4
    }, {
      id: 2,
      characteristic_en: 'Punt',
      characteristic_pt: 'Espalmar',
      characteristic_es: 'Despeje',
      num: 12
    }, {
      id: 3,
      characteristic_en: 'Aerial ball control',
      characteristic_pt: 'Jogo aéreo',
      characteristic_es: 'Juego Aéreo',
      num: 19
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Space control',
      characteristic_pt: 'Colocação no campo',
      characteristic_es: 'Colocación',
      num: 6
    }, {
      id: 2,
      characteristic_en: 'Communication skills',
      characteristic_pt: 'Comunicação',
      characteristic_es: 'Comunicación',
      num: 7
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Reflexes',
      characteristic_pt: 'Reflexos',
      characteristic_es: 'Reflejos',
      num: 29
    }, {
      id: 2,
      characteristic_en: 'Agility/Flexibility',
      characteristic_pt: 'Agilidade/Flexibilidade',
      characteristic_es: 'Agilidad/Flexibilidad',
      num: 15
    }],
    positions: [{
      id: 0,
      selected: true,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'FC BARCELONA',
      country: 'España',
      years_es: '2019 - Actualidad',
      years_en: '2019 - Currently',
      years_pt: '2019 - Presente',
      image: require('../images/clubs/trayectoria/fc-barcelona-espana-futbol-femenino.png'),
      alt: 'fc-barcelona-espana-futbol-femenino'
    }, {
      id: 2,
      team: 'SEVILLA FC',
      country: 'España',
      years_es: '2019 - 2020 (Cedida)',
      years_en: '2019 - 2020 (on loan)',
      years_pt: '2019 - 2020 (Cedida)',
      image: require('../images/clubs/trayectoria/sevilla-fc-espana-futbol-femenino.png'),
      alt: 'sevilla-fc-espana-futbol-femenino'
    }, {
      id: 3,
      team: 'UD COLLERENSE',
      country: 'España',
      years_es: '2016 - 2019',
      years_en: '2016 - 2019',
      years_pt: '2016 - 2019',
      image: require('../images/clubs/trayectoria/ud-collerense-futbol-femenino.png'),
      alt: 'ud-collerense-espana-futbol-femenino'
    }, {
      id: 4,
      team: 'AT. MARRATXI',
      country: 'España',
      years_es: '2015 - 2016',
      years_en: '2015 - 2016',
      years_pt: '2015 - 2016',
      image: require('../images/clubs/trayectoria/athletic-marratxi-espana-futbol-femenino.png'),
      alt: 'athletic-marratxi-espana-futbol-femenino'
    }, {
      id: 5,
      team: 'CIDE',
      country: 'España',
      years_es: '2014 - 2015',
      years_en: '2014 - 2015',
      years_pt: '2014 - 2015',
      image: require('../images/clubs/trayectoria/club-deportivo-cide-espana-futbol-femenino.png'),
      alt: 'club-deportivo-cide-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: '__cata13',
    instagramId: '783716538',
    instagram: 'https://www.instagram.com/__cata13/',
    twitter: 'https://twitter.com/catacoll2001',
    facebook: 'https://www.facebook.com/cata.coll.7',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'GOALKEEPERS GK',
      url: 'https://www.instagram.com/p/Bu54FfiA3gX/',
      image: require('../images/players/cata-coll/last-campaigns/campana-cata-coll-gk-gloves-spain.jpg'),
      alt: 'campana-cata-coll-gk-gloves-spain'
    }, {
      id: 2,
      brand: 'SPRINTER',
      url: '',
      image: require('../images/players/cata-coll/last-campaigns/campana-cata-coll-sprinterball-challenge.jpg'),
      alt: 'campana-cata-coll-sprinterball-challenge'
    }, {
      id: 3,
      brand: 'NATURPOKE',
      url: '',
      image: require('../images/players/cata-coll/last-campaigns/campanas-cata-coll-naturpoke.jpg'),
      alt: 'campanas-cata-coll-naturpoke'
    }],
    press: [{
      id: 1,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/femenino/20200406/48333333579/cata-coll-asi-se-prepara-la-promesa-del-barca-que-triunfa-en-el-sevila.html',
      image: require('../images/players/cata-coll/press/cata-coll-prensa-mundo-deportivo.jpg'),
      alt: 'cata-coll-prensa-mundo-deportivo'
    }, {
      id: 2,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2019/07/24/seleccion/1563979068_714827.html',
      image: require('../images/players/cata-coll/press/cata-coll-prensa-diario-as-0.jpg'),
      alt: 'cata-coll-prensa-diario-as-0'
    }, {
      id: 3,
      media: 'ESTADIO DEPORTIVO',
      url: 'https://www.estadiodeportivo.com/noticias-futbol/futbol-femenino/2020/04/16/dia-dia-cata-coll-cuarentana/275280.html',
      image: require('../images/players/cata-coll/press/cata-coll-prensa-estadio-deportivo.jpg'),
      alt: 'cata-coll-prensa-estadio-deportivo'
    }, {
      id: 4,
      media: 'LA VANGUARDIA',
      url: 'https://www.lavanguardia.com/deportes/futbol/20190709/463394974879/barcelona-cata-coll.html',
      image: require('../images/players/cata-coll/press/cata-coll-prensa-la-vanguardia.jpg'),
      alt: 'cata-coll-prensa-la-vanguardia'
    }, {
      id: 5,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2020/04/18/femenino/1587238145_148632.html',
      image: require('../images/players/cata-coll/press/cata-coll-prensa-diario-as.jpg'),
      alt: 'cata-coll-prensa-diario-as'
    }, {
      id: 6,
      media: 'BESOCCER',
      url: 'https://es.besoccer.com/noticia/promesa-del-barca-triunfa-en-sevilla-y-le-quiere-el-real-madrid-818229',
      image: require('../images/players/cata-coll/press/cata-coll-prensa-besoccer.jpg'),
      alt: 'cata-coll-prensa-besoccer'
    }, {
      id: 7,
      media: 'ESTADIO DEPORTIVO',
      url: 'https://www.estadiodeportivo.com/noticias-futbol/futbol-femenino/2020/04/21/jovenes-toman-batuta/275866.html',
      image: require('../images/players/cata-coll/press/cata-coll-prensa-estadio-deportivo.jpg'),
      alt: 'cata-coll-prensa-estadio-deportivo'
    }, {
      id: 8,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2018/12/02/seleccion/1543709784_101489.html',
      image: require('../images/players/cata-coll/press/cata-coll-prensa-diario-as-4.jpg'),
      alt: 'cata-coll-prensa-diario-as-4'
    }, {
      id: 9,
      media: 'DIARIO DE MALLORCA',
      url: 'https://www.diariodemallorca.es/deportes/2019/07/09/cata-coll-firma-barcelona-cuatro/1432363.html',
      image: require('../images/players/cata-coll/press/cata-coll-prensa-diario-de-mallorca.jpg'),
      alt: 'cata-coll-prensa-diario-de-mallorca'
    }, {
      id: 10,
      media: 'UH DEPORTES',
      url: 'https://www.ultimahora.es/deportes/futbol/2019/08/06/1099061/cata-coll-marcha-cedida-sevilla-femenino.html',
      image: require('../images/players/cata-coll/press/cata-coll-prensa-diario-de-mallorca(1).jpg'),
      alt: 'cata-coll-prensa-diario-de-mallorca(1)'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 8,
    title: 'UNIK TEAM | Claudia Pina | Jugadora de fútbol femenino',
    metadescription: 'Claudia Pina, futbolista española, actualmente juega como delantera en el Sevilla FC de la Primera Iberdrola y en la Selección Española Sub-20.',
    url: require('../images/players-profile-photo/claudia-pina-sevilla.png'),
    alt: 'claudia-pina-sevilla',
    name: 'CLAUDIA PINA',
    nameUrl: 'claudia-pina',
    team: 'Sevilla FC',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: 'SEL. ESPAÑOLA SUB-20',
    selection_en: 'SPAIN U-20',
    selection_pt: 'SEL. ESPANHOLA SUB-20',
    mainPosition_es : 'DELANTERA',
    mainPosition_en: 'FORWARD',
    mainPosition_pt: 'ATACANTE',
    birthDate: '12 - 08 - 2001',
    birthYear: '2001',
    town: 'Montcada i Reixac',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,60',
    weight: '59',
    signaturePhoto: require('../images/players/claudia-pina/jugadora-claudia-pina-sevilla-unik-team.png'),
    signaturePhotoAlt: 'jugadora-claudia-sevilla-pina-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/claudia-pina/header/claudia-pina-seleccion-espanola.jpg'),
      alt: 'claudia-pina-seleccion-espanola'
    }, {
      id: 2,
      url: require('../images/players/claudia-pina/header/claudia-pina-fc-barcelona-femenino.jpg'),
      alt: 'claudia-pina-fc-barcelona-femenino'
    },{
      id: 3,
      url: require('../images/players/claudia-pina/header/claudia-pina-bota-plata-balon-oro-mundial-sub-17.jpg'),
      alt: 'claudia-pina-bota-plata-balon-oro-mundial-sub-17'
    }],
    mobileHeaderPhoto: require('../images/home/mobile/claudia-pina-seleccion-espanola.jpg'),
    mobileHeaderPhotoAlt: 'claudia-pina-seleccion-espanola',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2019 - 2020',
      years_en: '2019 - 2020',
      years_pt: '2019 - 2020'
    }, {
      id: 2,
      nameFirstRow_es: 'Supercopa de España',
      nameFirstRow_en: 'Spanish Supercup',
      nameFirstRow_pt: 'Supercopa da Espanha',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2019 - 2020',
      years_en: '2019 - 2020',
      years_pt: '2019 - 2020'
    }, {
      id: 3,
      nameFirstRow_es: 'Copa de España',
      nameFirstRow_en: 'Spanish Cup',
      nameFirstRow_pt: 'Copa da Espanha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    }, {
      id: 4,
      nameFirstRow_es: 'Copa Catalunya',
      nameFirstRow_en: 'Catalonia Cup',
      nameFirstRow_pt: 'Copa Catalunha',
      nameSecondRow_es: 'Femenina',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2018 y 2019',
      years_en: '2018 and 2019',
      years_pt: '2018 e 2019'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'Champions League',
      nameSecondRow_en: 'Champions League',
      nameSecondRow_pt: 'Champions League',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2019',
      years_en: '2019',
      years_pt: '2019'
    }, {
      id: 2,
      nameFirstRow_es: 'Subcampeona del',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da ',
      nameSecondRow_es: 'Mundial Sub-20',
      nameSecondRow_en: 'U-20 World Cup',
      nameSecondRow_pt: 'Copa do Mundo Sub-20',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Francia 2018',
      years_en: 'France 2018',
      years_pt: '',
    }, {
      id: 3,
      nameFirstRow_es: 'Mundial Sub-17',
      nameFirstRow_en: 'U-17 World Cup',
      nameFirstRow_pt: 'Copa do Mundo Sub-17',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Uruguay 2018',
      years_en: 'Uruguay 2018',
      years_pt: 'Uruguay 2018'
    }, {
      id: 4,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da ',
      nameSecondRow_es: 'Eurocopa Sub-17',
      nameSecondRow_en: 'UEFA Euro U-17',
      nameSecondRow_pt: 'Eurocopa Sub-17',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'República Checa 2017',
      years_en: 'Czech Republic 2017',
      years_pt: 'República Checa 2017'
    }, {
      id: 5,
      nameFirstRow_es: 'Tercer lugar en el ',
      nameFirstRow_en: 'Third place in the ',
      nameFirstRow_pt: 'Terceiro lugar na',
      nameSecondRow_es: 'Mundial Sub-17',
      nameSecondRow_en: 'U-17 World Cup',
      nameSecondRow_pt: 'Copa do Mundo Sub-17',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Jordania 2016',
      years_en: 'Jordan 2016',
      years_pt: 'Jordânia 2016'
    }],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Balón de Oro Adidas',
      nameFirstRow_en: 'Adidas Golden Ball',
      nameFirstRow_pt: 'Bola de Ouro Adidas',
      nameSecondRow_es: 'Mundial Sub-17',
      nameSecondRow_en: 'U-17 World Cup',
      nameSecondRow_pt: 'Copa do Mundo Sub-17',
      years_es: 'Uruguay 2018',
      years_en: 'Uruguay 2018',
      years_pt: 'Uruguai 2018'
    }, {
      id: 2,
      nameFirstRow_es: 'Bota de Plata Adidas',
      nameFirstRow_en: 'Adidas Silver Boot',
      nameFirstRow_pt: 'Chuteira de prata Adidas',
      nameSecondRow_es: 'Mundial Sub-17',
      nameSecondRow_en: 'U-17 World Cup',
      nameSecondRow_pt: 'Copa do Mundo Sub-17',
      years_es: 'Uruguay 2018',
      years_en: 'Uruguay 2018',
      years_pt: 'Uruguai 2018'
    }, {
      id: 3,
      nameFirstRow_es: 'Máxima Goleadora',
      nameFirstRow_en: 'Top Scorer',
      nameFirstRow_pt: 'Artilheira',
      nameSecondRow_es: 'Eurocopa Sub-17',
      nameSecondRow_en: 'UEFA Euro U-17',
      nameSecondRow_pt: 'Eurocopa Sub-17',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    }, {
      id: 4,
      nameFirstRow_es: 'Máxima Goleadora de la',
      nameFirstRow_en: 'UEFA Top Scorer',
      nameFirstRow_pt: 'Artilheira de la',
      nameSecondRow_es: 'UEFA',
      nameSecondRow_en: '',
      nameSecondRow_pt: 'UEFA',
      years_es: '2017',
      years_en: '2017',
      years_pt: '2017'
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Goals',
      characteristic_pt: 'Gol',
      characteristic_es: 'Gol',
      num: 18
    }, {
      id: 2,
      characteristic_en: 'Dribbling',
      characteristic_pt: 'Drible',
      characteristic_es: 'Regate',
      num: 30
    }, {
      id: 3,
      characteristic_en: 'Penalty kicks',
      characteristic_pt: 'Lançamento de pênaltis',
      characteristic_es: 'Lanzamiento de penaltis',
      num: 21
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }, {
      id: 2,
      characteristic_en: 'Vision',
      characteristic_pt: 'Visão de jogo',
      characteristic_es: 'Visión de juego',
      num: 34
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Speed',
      characteristic_pt: 'Velocidade',
      characteristic_es: 'Velocidad',
      num: 33
    }, {
      id: 2,
      characteristic_en: 'Power',
      characteristic_pt: 'Potência',
      characteristic_es: 'Potencia',
      num: 27
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: true,
      position: 'INTi'
    },{
      id: 9,
      selected: true,
      position: 'INTd'
    },{
      id: 10,
      selected: true,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: true,
      position: 'SDC'
    },{
      id: 14,
      selected: true,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'SEVILLA FC',
      country: 'España',
      years_es: '2020 - Cedida',
      years_en: '2020 - Loan',
      years_pt: '2020 - Cedida',
      image: require('../images/clubs/trayectoria/sevilla-fc-espana-futbol-femenino.png'),
      alt: 'sevilla-fc-espana-futbol-femenino'
    },{
      id: 2,
      team: 'FC BARCELONA',
      country: 'España',
      years_es: '2013 - Actualidad',
      years_en: '2013 - Currently',
      years_pt: '2013 - Presente',
      image: require('../images/clubs/trayectoria/fc-barcelona-espana-futbol-femenino.png'),
      alt: 'fc-barcelona-espana-futbol-femenino'
    }, {
      id: 3,
      team: 'RCD ESPANYOL',
      country: 'España',
      years_es: '2011 - 2013',
      years_en: '2011 - 2013',
      years_pt: '2011 - 2013',
      image: require('../images/clubs/trayectoria/rcd-espanyol-espana-futbol-femenino.png'),
      alt: 'rcd-espanyol-espana-futbol-femenino'
    }],
    instagramAccessToken: 'IGQVJVM0tJd2l0TlZA1TUZAzQzJsMkhLSU9NbWs4dWp0VGZAmN05hd05RazhuZA1p6Yy1ibVpyTVFJS3BrTHI1ZAGV0WHRKM1c2V2I2QURDVzNyWlBDazhNSEozWEZAFNlFqUDVrdU4wdXJ3',
    instagramUsername: 'claudiaapina',
    instagramId: '2112766765',
    instagram: 'https://www.instagram.com/claudiaapina/',
    twitter: 'https://twitter.com/claudiaapinaa',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'AUDI',
      url: '',
      image: require('../images/players/claudia-pina/last-campaigns/campana-claudia-pina-audi.jpg'),
      alt: 'campana-claudia-pina-audi'
    }, {
      id: 2,
      brand: 'NIKE',
      url: 'https://www.instagram.com/p/B0TSZLzoR_t/',
      image: require('../images/players/claudia-pina/last-campaigns/campana-claudia-pina-nike.jpg'),
      alt: 'campana-claudia-pina-nike'
    }, {
      id: 3,
      brand: 'MAGARI SOUND',
      url: '',
      image: require('../images/players/claudia-pina/last-campaigns/campana-claudia-pina-magari-sound.jpg'),
      alt: 'campana-claudia-pina-magari-sound'
    }],
    press: [{
      id: 1,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/primera-division/2020/05/17/5ec17faf22601dee348b45bc.html',
      image: require('../images/players/claudia-pina/press/claudia-pina-prensa-marca_0.jpg'),
      alt: 'claudia-pina-prensa-marca_0'
    }, {
      id: 2,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/femenino/20200324/4885871677/los-malabarismos-de-claudia-pina-durante-su-confinamiento.html',
      image: require('../images/players/claudia-pina/press/Claudia-Pina-prensa-mundo-deportivo.jpg'),
      alt: 'claudia-Pina-prensa-mundo-deportivo'
    }, {
      id: 3,
      media: 'EL PERIODICO',
      url: 'https://www.elperiodico.com/es/deportes/20181214/claudia-pina-mejor-del-mundo-sub-17-futbol-femenino-fc-barcelona-espana-7203090',
      image: require('../images/players/claudia-pina/press/Claudia-Pina-prensa-el-periodico.jpg'),
      alt: 'claudia-Pina-prensa-el-periodico'
    }, {
      id: 4,
      media: 'SPORT',
      url: 'https://www.sport.es/es/noticias/futbol-femenino-barca/barcelona-no-dejara-escapar-claudia-pina-solo-cedida-7565577',
      image: require('../images/players/claudia-pina/press/Claudia-Pina-prensa-sport.jpg'),
      alt: 'claudia-Pina-prensa-sport'
    }, {
      id: 5,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/2018/01/15/5a5cad0546163fda558b4608.html',
      image: require('../images/players/claudia-pina/press/Claudia-Pina-prensa-marca.jpg'),
      alt: 'claudia-Pina-prensa-marca'
    }, {
      id: 6,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2018/12/04/seleccion/1543882392_552671.html?omnil=resrelart',
      image: require('../images/players/claudia-pina/press/Claudia-Pina-prensa-diario-as.jpg'),
      alt: 'claudia-Pina-prensa-diario-as'
    }, {
      id: 7,
      media: 'EFE',
      url: 'https://www.efe.com/efe/espana/deportes/2018-el-ano-de-pina-claudia/10006-3830915',
      image: require('../images/players/claudia-pina/press/Claudia-Pina-prensa-efe.jpg'),
      alt: 'claudia-Pina-prensa-efe'
    }, {
      id: 8,
      media: 'SPORT',
      url: 'https://www.sport.es/es/noticias/futbol-femenino/claudia-pina-una-goleadora-talla-mundial-7180710',
      image: require('../images/players/claudia-pina/press/Claudia-Pina-prensa-sport_3.jpg'),
      alt: 'claudia-Pina-prensa-sport_3'
    }, {
      id: 9,
      media: 'EL MUNDO',
      url: 'https://www.elmundo.es/deportes/futbol/2018/12/03/5c0441acfc6c8369778b4612.html',
      image: require('../images/players/claudia-pina/press/Claudia-Pina-prensa-el-mundo.jpg'),
      alt: 'claudia-Pina-prensa-el-mundo'
    }, {
      id: 10,
      media: 'EL PAÍS',
      url: 'https://elpais.com/deportes/2018/12/02/actualidad/1543764824_250903.html',
      image: require('../images/players/claudia-pina/press/Claudia-Pina-prensa-el-pais.jpg'),
      alt: 'claudia-Pina-prensa-el-pais'
    }, {
      id: 11,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/20181202/453270863600/claudia-pina-es-un-sueno-hecho-realidad.html',
      image: require('../images/players/claudia-pina/press/Claudia-Pina-prensa-mundo-deportivo_2.jpg'),
      alt: 'claudia-Pina-prensa-mundo-deportivo_2'
    }, {
      id: 12,
      media: 'MARCA',
      url: 'https://www.marca.com/radio/2018/12/02/5c03f2a9e2704e498e8b45c4.html',
      image: require('../images/players/claudia-pina/press/Claudia-Pina-prensa-marca_2.jpg'),
      alt: 'claudia-Pina-prensa-marca_2'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },/* {
      id: 9,
      url: require('../images/players-profile-photo/claudia-zornoza-levante-ud.png'),
      name: 'CLAUDIA ZORNOZA',
      nameUrl: 'claudia-zornoza',
      team: 'Levante UD',
      league_es: 'Primera Iberdrola (España)',
      league_en: 'Primera Iberdrola (Spain)',
      league_pt: 'Primera Iberdrola (Espanha)',
      selection_es: '',
      selection_en: '',
      selection_pt: '',
    mainPosition_es : '',
      mainPosition_en: '',
      mainPosition_pt: ' ',
    birthDate: '',
      birthYear: '1900',
      town: '',
      country: '',
      nationality_es : 'Española',
      nationality_en : '',
      nationality_pt: '',
    height: '',
      weight: '',
      signaturePhoto: '',
      signaturePhotoAlt: '',
      headerPhotos: [],
      mobileHeaderPhoto: '',
      mobileHeaderPhotoAlt: '',
      nationalTournament: [{
        id: 0,
        nameFirstRow_es: '',
        nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
        team_es: '',
        team_en: '',
        nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
      }],
      internationalTournament: [{
        id: 0,
        nameFirstRow_es: '',
        nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
        team_es: '',
        team_en: '',
        nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
      }],
      individualAwards: [{
        id: 0,
        nameFirstRow_es: '',
        nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
        nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
      }],
      skilledLeg_es: '',
      skilledLeg_en: '',
    skilledLeg_pt: '',
    skilledLegNum: 35,
      technicalCharacteristics: [{
        id: 0,
        characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
        num: 0
      }],
      tacticalCharacteristics: [{
        id: 0,
        characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
        num: 0
      }],
      psychologicalCharacteristics: [{
        id: 0,
        characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
        num: 0
      }],
      physicalCharacteristics: [{
        id: 0,
        characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
        num: 0
      }],
      positions: [{
        id: 0,
        selected: false,
        position: 'POR'
      },{
        id: 1,
        selected: false,
        position: 'LI'
      },{
        id: 2,
        selected: false,
        position: 'DFCi'
      },{
        id: 3,
        selected: false,
        position: 'DFCd'
      },{
        id: 4,
        selected: false,
        position: 'LD'
      },{
        id: 5,
        selected: false,
        position: 'LIB'
      },{
        id: 6,
        selected: false,
        position: 'MC'
      },{
        id: 7,
        selected: false,
        position: 'MCO'
      },{
        id: 8,
        selected: false,
        position: 'INTi'
      },{
        id: 9,
        selected: false,
        position: 'INTd'
      },{
        id: 10,
        selected: false,
        position: 'MP'
      },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 0,
      team: '',
      country: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: '',
      image: ''
    }],
    instagramAccessToken: '',
    instagramUsername: '',
    instagramId: '',
    instagram: '',
    twitter: '',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 0,
      brand: '',
      url: '',
      image: '',
    }],
    press: [{
      id: 0,
      media: '',
      url: '',
      image: '',
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },*/ {
    id: 10,
    title: 'UNIK TEAM | Débora Sorriso | Jugadora de fútbol femenino',
    metadescription: 'Débora Sorriso, futbolista brasileña, actualmente juega como defensa en el CR Flamengo del Brasileirão Feminino - Série A1 y en la Selección Universitaria Brasileña.',
    url: require('../images/players-profile-photo/debora-sorriso-cr-flamengo-marinha.png'),
    alt: 'debora-sorriso-cr-flamengo-marinha',
    name: 'DÉBORA SORRISO',
    nameUrl: 'debora-sorriso',
    team: 'CR Flamengo',
    league_es: 'Brasileirão Feminino - Série A1 (Brasil)',
    league_en: 'Brasileirão Feminino - Série A1 (Brazil)',
    league_pt: 'Brasileirão Feminino - Série A1 (Brasil)',
    selection_es: 'SEL. UNI. BRASILEÑA',
    selection_en: 'BRAZIL UNI. TEAM',
    selection_pt: 'SEL. UNI. BRASILEIRA',
    mainPosition_es : 'DEFENSA',
    mainPosition_en: 'DEFENDER',
    mainPosition_pt: 'DEFESA',
    birthDate: '20 - 10 -1994',
    birthYear: '1994',
    town: 'São Gonçalo',
    country: 'Brasil',
    nationality_es : 'Brasileña',
    nationality_en : 'Brazilian',
    nationality_pt: 'Brasileña',
    height: '1,61',
    weight: '50',
    signaturePhoto: require('../images/players/debora-sorriso/jugadora-debora-sorriso-unik-team.png'),
    signaturePhotoAlt: 'jugadora-debora-sorriso-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/debora-sorriso/header/debora-sorriso-cr-flamengo-brasil.jpg'),
      alt: 'debora-sorriso-cr-flamengo-brasil'
    }, {
      id: 2,
      url: require('../images/players/debora-sorriso/header/debora-sorriso-cr-flamengo-futbol-femenino.jpg'),
      alt: 'debora-sorriso-cr-flamengo-futbol-femenino'
    }, {
      id: 3,
      url: require('../images/players/debora-sorriso/header/debora-sorriso-futbol-femenino-brasil.jpg'),
      alt: 'debora-sorriso-futbol-femenino-brasil'
    }],
    mobileHeaderPhoto: require('../images/players/debora-sorriso/header-mobile/debora-sorriso-cr-flamengo.jpg'),
    mobileHeaderPhotoAlt: 'debora-sorriso-cr-flamengo',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera División del Estado de Rio de Janeiro',
      nameFirstRow_en: 'First Division in the State of Rio de Janeiro',
      nameFirstRow_pt: '"Campeonato Carioca"',
      nameSecondRow_es: '"Campeonato Carioca"',
      nameSecondRow_en: '"Campeonato Carioca"',
      nameSecondRow_pt: '',
      team_es: 'CR Flamengo',
      team_en: 'CR Flamengo',
      team_pt: 'CR Flamengo',
      years_es: '2019',
      years_en: '2019',
      years_pt: '2019'
    }, {
      id: 2,
      nameFirstRow_es: 'Primera División del Estado de Rio de Janeiro',
      nameFirstRow_en: 'First Division in the State of Rio de Janeiro',
      nameFirstRow_pt: '"Campeonato Carioca"',
      nameSecondRow_es: '"Campeonato Carioca"',
      nameSecondRow_en: '"Campeonato Carioca"',
      nameSecondRow_pt: '',
      team_es: 'CR Flamengo',
      team_en: 'CR Flamengo',
      team_pt: 'CR Flamengo',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    }],
    internationalTournament: [],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Máxima Asistente',
      nameFirstRow_en: 'Most Assists',
      nameFirstRow_pt: 'Máxima Assistente',
      nameSecondRow_es: 'Primera División del Estado de Rio de Janeiro',
      nameSecondRow_en: 'First Division in the State of Rio de Janeiro',
      nameSecondRow_pt: '"Campeonato Carioca"',
      nameThirdRow_es: ' "Campeonato Carioca"',
      nameThirdRow_en: ' "Campeonato Carioca"',
      nameThirdRow_pt: ' "Campeonato Carioca"',
      years_es: '2020',
      years_en: '2020',
      years_pt: '2020'
    }],
    skilledLeg_es: 'Zurda',
    skilledLeg_en: 'Left-footed',
    skilledLeg_pt: 'Canhota',
    skilledLegNum: 35,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Assists',
      characteristic_pt: 'Assistências',
      characteristic_es: 'Asistencias',
      num: 2
    }, {
      id: 2,
      characteristic_en: 'Ball handling',
      characteristic_pt: 'Condução',
      characteristic_es: 'Conducción',
      num: 8
    }, {
      id: 3,
      characteristic_en: 'Medium range passes',
      characteristic_pt: 'Passe médio',
      characteristic_es: 'Pase Medio',
      num: 26
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Defensive player',
      characteristic_pt: 'Jogadora defensiva',
      characteristic_es: 'Criterio defensivo',
      num: 10
    }, {
      id: 2,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Power',
      characteristic_pt: 'Potência',
      characteristic_es: 'Potencia',
      num: 27
    }, {
      id: 2,
      characteristic_en: 'Strenght',
      characteristic_pt: 'Força',
      characteristic_es: 'Fuerza',
      num: 17
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: true,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: true,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: true,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'CR FLAMENGO',
      country: 'Brasil',
      years_es: '2018 - Atualidad',
      years_en: '2018 - Currently',
      years_pt: '2018 - Presente',
      image: require('../images/clubs/trayectoria/flamengo-brasil-futbol-femenino.png'),
      alt: 'flamengo-brasil-futbol-femenino'
    }, {
      id: 2,
      team: 'CRESSPOM DF',
      country: 'Brasil',
      years_es: '2015 - 2017',
      years_en: '2015 - 2017',
      years_pt: '2015 - 2017',
      image: require('../images/clubs/trayectoria/cresspom-brasil-futbol-femenino.png'),
      alt: 'cresspom-brasil-futbol-femenino'
    }, {
      id: 3,
      team: 'KARANBA',
      country: 'Brasil',
      years_es: '2013',
      years_en: '2013',
      years_pt: '2013',
      image: require('../images/clubs/trayectoria/karanba-brasil-futbol-femenino.png'),
      alt: 'karanba-brasil-futbol-femenino'
    }, {
      id: 4,
      team: 'JOIVILLE',
      country: 'Brasil',
      years_es: '2011 - 2012',
      years_en: '2011 - 2012',
      years_pt: '2011 - 2012',
      image: require('../images/clubs/trayectoria/joiville-esporte-clube-brasil-futbol-femenino.png'),
      alt: 'joiville-esporte-clube-brasil-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'sorriso_ousada',
    instagramId: '543686949',
    instagram: 'https://www.instagram.com/sorriso_ousada/',
    twitter: 'https://twitter.com/sorrisoolli',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'GRUPO LX',
      url: '',
      image: require('../images/players/debora-sorriso/last-campaigns/campana-debora-sorriso-grupo-lx.jpg'),
      alt: 'campana-debora-sorriso-grupo-lx'
    }, {
      id: 2,
      brand: 'JOGAJUNTO',
      url: 'https://www.instagram.com/p/B-YKKDWjZr_/?igshid=18t9eaeskc0js',
      image: require('../images/players/debora-sorriso/last-campaigns/campana-debora-sorriso-jogajuntoff.jpg'),
      alt: 'campana-debora-sorriso-jogajuntoff'
    }],
    press: [{
      id: 1,
      media: 'FLA TV',
      url: 'https://www.youtube.com/watch?v=TiJLfVZEMm8',
      image: require('../images/players/debora-sorriso/press/debora-sorriso-prensa-fla-tv.jpg'),
      alt: 'debora-sorriso-prensa-fla-tv'
    }, {
      id: 2,
      media: 'O SÃO GONÇALO',
      url: 'https://www.osaogoncalo.com.br/esportes/57925/goncalense-alem-de-ser-marinheira-e-lateral-esquerda-do-flamengo',
      image: require('../images/players/debora-sorriso/press/debora-sorriso-prensa-o-sangoncalo.jpg'),
      alt: 'debora-sorriso-prensa-o-sangoncalo'
    }, {
      id: 3,
      media: 'O TEMPO',
      url: 'https://www.otempo.com.br/superfc/futebol/flamengo-vence-por-56-a-0-no-campeonato-carioca-feminino-e-levanta-debate-1.2242883',
      image: require('../images/players/debora-sorriso/press/debora-sorriso-prensa-o-tempo.jpg'),
      alt: 'debora-sorriso-prensa-o-tempo'
    }, {
      id: 4,
      media: 'ONE FOOTBALL',
      url: 'https://onefootball.com/br/noticias/flamengo-vence-jogo-por-56-x-0-no-cariocao-feminino-27413742?variable=20200511',
      image: require('../images/players/debora-sorriso/press/debora-sorriso-prensa-one-football.jpg'),
      alt: 'debora-sorriso-prensa-one-football'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },/* {
    id: 11,
    title: '',
    metadescription: '',
    url: require('../images/players-profile-photo/dominika-conc-ac-milan.png'),
    alt: '',
    name: 'DOMINIKA ČONC',
    nameUrl: 'dominika-conc',
    team: 'AC Milan',
    league_es: 'Serie A (Italia)',
    league_en: 'Serie A (Italy)',
    league_pt: 'Serie A (Itália)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : '',
    mainPosition_en: '',
    mainPosition_pt: ' ',
    birthDate: '',
    birthYear: '1900',
    town: '',
    country: '',
    nationality_es : 'Eslovaca',
    nationality_en : '',
    nationality_pt: '',
    height: '',
    weight: '',
    signaturePhoto: '',
    signaturePhotoAlt: '',
    headerPhotos: [],
    mobileHeaderPhoto: '',
    mobileHeaderPhotoAlt: '',
    nationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: '',
      team_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    internationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: '',
      team_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    individualAwards: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    skilledLeg_es: '',
    skilledLeg_en: '',
    skilledLeg_pt: '',
    skilledLegNum: 35,
    technicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    tacticalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    psychologicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    physicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 0,
      team: '',
      country: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: '',
      image: ''
    }],
    instagramAccessToken: '',
    instagramUsername: '',
    instagramId: '',
    instagram: '',
    twitter: '',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 0,
      brand: '',
      url: '',
      image: '',
    }],
    press: [{
      id: 0,
      media: '',
      url: '',
      image: '',
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },*/ {
    id: 12,
    title: 'UNIK TEAM | Doris Bačić | Jugadora de fútbol femenino',
    metadescription: 'Doris Bačić, futbolista croata, actualmente juega como portera en el Juventus FC de la Serie A y en la Selección Croata. ',
    url: require('../images/players-profile-photo/doris-bacic-juventus-fc.png'),
    alt: 'doris-bacic-juventus-fc',
    name: 'DORIS BAČIĆ',
    nameUrl: 'doris-bacic',
    team: 'Juventus FC',
    league_es: 'Serie A (Italia)',
    league_en: 'Serie A (Italy)',
    league_pt: 'Serie A (Itália)',
    selection_es: 'SEL. CROATA',
    selection_en: 'CROATIAN TEAM',
    selection_pt: 'SEL. CROATA',
    mainPosition_es : 'PORTERA',
    mainPosition_en: 'GOALKEEPER',
    mainPosition_pt: 'GOLEIRA',
    birthDate: '23 - 02 - 1995',
    birthYear: '1995',
    town: 'Neum',
    country: 'Bosnia y Herzegovina',
    nationality_es : 'Croata',
    nationality_en : 'Croatian',
    nationality_pt: 'Croata',
    height: '1,85',
    weight: '59',
    signaturePhoto: require('../images/players/doris-bacic/jugadora-doris-bacic-unik-team.png'),
    signaturePhotoAlt: 'jugadora-doris-bacic-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/doris-bacic/header/Doris_Bacic.jpg'),
      alt: 'Doris_Bacic'
    }, {
      id: 2,
      url: require('../images/players/doris-bacic/header/Doris_Bacic2.jpg'),
      alt: 'Doris_Bacic2'
    }, {
      id: 3,
      url: require('../images/players/doris-bacic/header/Doris_Bacic3.jpg'),
      alt: 'Doris_Bacic3'
    }],
    mobileHeaderPhoto: require('../images/players/doris-bacic/header-mobile/doris-bacic-seleccion-croata.jpg'),
    mobileHeaderPhotoAlt: 'doris-bacic-seleccion-croata',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera División Italiana',
      nameFirstRow_en: 'Italian First Division',
      nameFirstRow_pt: 'Primeira Divisão Italiana',
      nameSecondRow_es: '"Serie A"',
      nameSecondRow_en: '"Serie A"',
      nameSecondRow_pt: '"Serie A"',
      team_es: 'Juventus FC',
      team_en: 'Juventus FC',
      team_pt: 'Juventus FC',
      years_es: '2019 - 2020',
      years_en: '2019 - 2020',
      years_pt: '2019 - 2020'
    }, {
      id: 2,
      nameFirstRow_es: 'Supercopa de Italia',
      nameFirstRow_en: 'Italian SuperCup',
      nameFirstRow_pt: 'Supercopa da Itália',
      nameSecondRow_es: '"SuperCoppa Italiana"',
      nameSecondRow_en: '"SuperCoppa Italiana"',
      nameSecondRow_pt: '"SuperCoppa Italiana"',
      team_es: 'Juventus FC',
      team_en: 'Juventus FC',
      team_pt: 'Juventus FC',
      years_es: '2019',
      years_en: '2019',
      years_pt: '2019'
    }, {
      id: 3,
      nameFirstRow_es: 'Copa de Italia',
      nameFirstRow_en: 'Italian Cup',
      nameFirstRow_pt: 'Copa da Itália',
      nameSecondRow_es: '"Coppa Italia"',
      nameSecondRow_en: '"Coppa Italia"',
      nameSecondRow_pt: '"Coppa Italia"',
      team_es: 'Juventus FC',
      team_en: 'Juventus FC',
      team_pt: 'Juventus FC',
      years_es: '2019',
      years_en: '2019',
      years_pt: '2019'
    }, {
      id: 4,
      nameFirstRow_es: 'Primera División Italiana',
      nameFirstRow_en: 'Italian Cup',
      nameFirstRow_pt: 'Primeira Divisão Italiana',
      nameSecondRow_es: '"Serie A"',
      nameSecondRow_en: '"Serie A"',
      nameSecondRow_pt: '"Serie A"',
      team_es: 'Juventus FC',
      team_en: 'Juventus FC',
      team_pt: 'Juventus FC',
      years_es: '2018 - 2019',
      years_en: '2018 - 2019',
      years_pt: '2018 - 2019'
    }, {
      id: 5,
      nameFirstRow_es: 'Primera División Belga',
      nameFirstRow_en: 'Belgium First Division',
      nameFirstRow_pt: 'Primeira Divisão Belga',
      nameSecondRow_es: '"Super League"',
      nameSecondRow_en: '"Super League"',
      nameSecondRow_pt: '"Super League"',
      team_es: 'RSC Anderletch',
      team_en: 'RSC Anderletch',
      team_pt: 'RSC Anderletch',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    }, {
      id: 6,
      nameFirstRow_es: 'Primera División Bosnia',
      nameFirstRow_en: 'Bosnian First Division',
      nameFirstRow_pt: 'Primeira Divisão Bósnia',
      nameSecondRow_es: '"Premijer Liga BiH"',
      nameSecondRow_en: '"Premijer Liga BiH"',
      nameSecondRow_pt: '"Premijer Liga BiH"',
      team_es: 'SFK 2000 Sarajevo',
      team_en: 'SFK 2000 Sarajevo',
      team_pt: 'SFK 2000 Sarajevo',
      years_es: '2015 - 2016',
      years_en: '2015 - 2016',
      years_pt: '2015 - 2016'
    }, {
      id: 7,
      nameFirstRow_es: 'Copa Bosnia',
      nameFirstRow_en: 'Bosnian Cup',
      nameFirstRow_pt: 'Copa Bósnia',
      nameSecondRow_es: '"Kup Bosne i Hercegovine"',
      nameSecondRow_en: '"Kup Bosne i Hercegovine"',
      nameSecondRow_pt: '"Kup Bosne i Hercegovine"',
      team_es: 'SFK 2000 Sarajevo',
      team_en: 'SFK 2000 Sarajevo',
      team_pt: 'SFK 2000 Sarajevo',
      years_es: '2012 y 2016',
      years_en: '2012 and 2016',
      years_pt: '2012 e 2016'
    }, {
      id: 8,
      nameFirstRow_es: 'Primera División Sueca',
      nameFirstRow_en: 'Swedish First Division',
      nameFirstRow_pt: 'Primeira Divisão Sueca',
      nameSecondRow_es: '"Damallsvenskan"',
      nameSecondRow_en: '"Damallsvenskan"',
      nameSecondRow_pt: '"Damallsvenskan"',
      team_es: 'FC Rosengård',
      team_en: 'FC Rosengård',
      team_pt: 'FC Rosengård',
      years_es: '2015',
      years_en: '2015',
      years_pt: '2015'
    }, {
      id: 9,
      nameFirstRow_es: 'Supercopa Sueca',
      nameFirstRow_en: 'Swedish SuperCup',
      nameFirstRow_pt: 'Supercopa Sueca',
      nameSecondRow_es: '"Svenska Supercupen"',
      nameSecondRow_en: '"Svenska Supercupen"',
      nameSecondRow_pt: '"Svenska Supercupen"',
      team_es: 'FC Rosengård',
      team_en: 'FC Rosengård',
      team_pt: 'FC Rosengård',
      years_es: '2015',
      years_en: '2015',
      years_pt: '2015'
    }, {
      id: 10,
      nameFirstRow_es: 'Subcampeona de la Copa Sueca',
      nameFirstRow_en: 'Runner-up of the Swedish Cup',
      nameFirstRow_pt: 'Vice-campeã da Copa Sueca',
      nameSecondRow_es: '"Svenska Cupen"',
      nameSecondRow_en: '"Svenska Cupen"',
      nameSecondRow_pt: '"Svenska Cupen"',
      team_es: 'FC Rosengård',
      team_en: 'FC Rosengård',
      team_pt: 'FC Rosengård',
      years_es: '2015',
      years_en: '2015',
      years_pt: '2015'
    }, {
      id: 11,
      nameFirstRow_es: 'Copa de Croacia',
      nameFirstRow_en: 'Croatian Cup',
      nameFirstRow_pt: 'Copa da Croácia',
      nameSecondRow_es: '"Hrvatski nogometni kup"',
      nameSecondRow_en: '"Hrvatski nogometni kup"',
      nameSecondRow_pt: '"Hrvatski nogometni kup"',
      team_es: 'ŽNK Osijek',
      team_en: 'ŽNK Osijek',
      team_pt: 'ŽNK Osijek',
      years_es: '2013',
      years_en: '2013',
      years_pt: '2013'
    }, {
      id: 12,
      nameFirstRow_es: 'Primera División Croata',
      nameFirstRow_en: 'Croatian First Division',
      nameFirstRow_pt: 'Primeira Divisão Croata',
      nameSecondRow_es: '"Prva HNL"',
      nameSecondRow_en: '"Prva HNL"',
      nameSecondRow_pt: '"Prva HNL"',
      team_es: 'ŽNK Osijek',
      team_en: 'ŽNK Osijek',
      team_pt: 'ŽNK Osijek',
      years_es: '2012 - 2013',
      years_en: '2012 - 2013',
      years_pt: '2012 - 2013'
    }, {
      id: 13,
      nameFirstRow_es: 'Primera División Bosnia',
      nameFirstRow_en: 'Bosnian First Division',
      nameFirstRow_pt: 'Primeira Divisão Bósnia',
      nameSecondRow_es: '"Premijer Liga BiH"',
      nameSecondRow_en: '"Premijer Liga BiH"',
      nameSecondRow_pt: '"Premijer Liga BiH"',
      team_es: 'SFK 2000 Sarajevo',
      team_en: 'SFK 2000 Sarajevo',
      team_pt: 'SFK 2000 Sarajevo',
      years_es: '2011 - 2012',
      years_en: '2011 - 2012',
      years_pt: '2011 - 2012'
    }],
    internationalTournament: [],
    individualAwards: [],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Aerial ball control',
      characteristic_pt: 'Jogo aéreo',
      characteristic_es: 'Juego Aéreo',
      num: 19
    }, {
      id: 2,
      characteristic_en: 'Blocking',
      characteristic_pt: 'Agarrar',
      characteristic_es: 'Blocaje',
      num: 4
    }, {
      id: 3,
      characteristic_en: 'Punt',
      characteristic_pt: 'Espalmar',
      characteristic_es: 'Despeje',
      num: 12
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Space control',
      characteristic_pt: 'Colocação no campo',
      characteristic_es: 'Colocación',
      num: 6
    }, {
      id: 2,
      characteristic_en: 'Space control',
      characteristic_pt: 'Colocação no campo',
      characteristic_es: 'Comunicación',
      num: 7
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Leader',
      characteristic_pt: 'Líder',
      characteristic_es: 'Líder',
      num: 23
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Reflexes',
      characteristic_pt: 'Reflexos',
      characteristic_es: 'Reflejos',
      num: 29
    }, {
      id: 2,
      characteristic_en: 'Agility/Flexibility',
      characteristic_pt: 'Agilidade/Flexibilidade',
      characteristic_es: 'Agilidad/Flexibilidad',
      num: 15
    }],
    positions: [{
      id: 0,
      selected: true,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'JUVENTUS FC',
      country: 'Italia',
      years_es: '2018 - Actualidad',
      years_en: '2018 - Currently',
      years_pt: '2018 - Presente',
      image: require('../images/clubs/trayectoria/juventus-fc-italia-futbol-femenino.png'),
      alt: 'juventus-fc-italia-futbol-femenino'
    }, {
      id: 2,
      team: 'RSC ANDERLETCH',
      country: 'Bélgica',
      years_es: '2017 - 2018',
      years_en: '2017 - 2018',
      years_pt: '2017 - 2018',
      image: require('../images/clubs/trayectoria/rsc-anderletch-belgica-futbol.femenino.png'),
      alt: 'rsc-anderletch-belgica-futbol.femenino'
    }, {
      id: 3,
      team: 'EINHERJI',
      country: 'Islandia',
      years_es: '2017',
      years_en: '2017',
      years_pt: '2017',
      image: require('../images/clubs/trayectoria/einherji-islandia-futbol-femenino.png'),
      alt: 'einherji-islandia-futbol-femenino'
    }, {
      id: 4,
      team: 'SC SAND',
      country: 'Alemania',
      years_es: '2016',
      years_en: '2016',
      years_pt: '2016',
      image: require('../images/clubs/trayectoria/sc-sand-alemania-futbol-femenino.png'),
      alt: 'sc-sand-alemania-futbol-femenino'
    }, {
      id: 5,
      team: 'SFK 2000 SARAJEVO',
      country: 'BiH',
      years_es: '2015 - 2016',
      years_en: '2015 - 2016',
      years_pt: '2015 - 2016',
      image: require('../images/clubs/trayectoria/sfk-2000-sarajevo-bosnia-herzegovina-futbol-femenino.png'),
      alt: 'sfk-2000-sarajevo-bosnia-herzegovina-futbol-femenino'
    }, {
      id: 6,
      team: 'FC ROSENGåRD',
      country: 'Suecia',
      years_es: '2014 - 2015',
      years_en: '2014 - 2015',
      years_pt: '2014 - 2015',
      image: require('../images/clubs/trayectoria/fc-rosengard-suecia-futbol-femenino.png'),
      alt: 'fc-rosengard-suecia-futbol-femenino'
    }, {
      id: 7,
      team: 'ARSENAL',
      country: 'Inglaterra',
      years_es: '2013 - 2014',
      years_en: '2013 - 2014',
      years_pt: '2013 - 2014',
      image: require('../images/clubs/trayectoria/arsenal-inglaterra-futbol-femenino.png'),
      alt: 'arsenal-inglaterra-futbol-femenino'
    }, {
      id: 8,
      team: 'ŽNK OSIJEK',
      country: 'Croacia',
      years_es: '2012 - 2013',
      years_en: '2012 - 2013',
      years_pt: '2012 - 2013',
      image: require('../images/clubs/trayectoria/znk-osijek-croacia-futbol-femenino.png'),
      alt: 'znk-osijek-croacia-futbol-femenino'
    }, {
      id: 9,
      team: 'SFK 2000 SARAJEVO',
      country: 'BiH',
      years_es: '2011 - 2012',
      years_en: '2011 - 2012',
      years_pt: '2011 - 2012',
      image: require('../images/clubs/trayectoria/sfk-2000-sarajevo-bosnia-herzegovina-futbol-femenino.png'),
      alt: 'sfk-2000-sarajevo-bosnia-herzegovina-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'bacicdoris',
    instagramId: '29226702247',
    instagram: 'https://www.instagram.com/bacicdoris/',
    twitter: 'https://twitter.com/dorisbacic42',
    facebook: 'https://www.facebook.com/Doris-Ba%C4%8Di%C4%87-1400366130237041/',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'SOLOPORTEROS',
      url: '',
      image: require('../images/players/doris-bacic/last-campaigns/campana-doris-bacic-sp-futbol.jpg'),
      alt: 'campana-doris-bacic-sp-futbol'
    }, {
      id: 2,
      brand: 'UMBRO',
      url: '',
      image: require('../images/players/doris-bacic/last-campaigns/campana-doris-bacic-umbro.jpg'),
      alt: 'campana-doris-bacic-umbro'
    }, {
      id: 3,
      brand: 'BALR',
      url: '',
      image: require('../images/players/doris-bacic/last-campaigns/campana-doris-bacic-balr.jpg'),
      alt: 'campana-doris-bacic-balr'
    }, {
      id: 4,
      brand: 'YSSA',
      url: '',
      image: require('../images/players/doris-bacic/last-campaigns/campana-doris-bacic-yssa.jpg'),
      alt: 'campana-doris-bacic-yssa'
    }],
    press: [{
      id: 1,
      media: 'IL BIANCONERO',
      url: 'https://www.ilbianconero.com/a/women-bacic-la-juve-e-una-famiglia-facevo-l-attaccante-ma-poi-41078',
      image: require('../images/players/doris-bacic/press/doris-bacic-prensa-il-bianconero.jpg'),
      alt: 'doris-bacic-prensa-il-bianconero'
    }, {
      id: 2,
      media: '24 SATA',
      url: 'https://www.24sata.hr/sport/doris-brani-u-juveu-ucila-sam-igrati-uz-viduku-i-gocu-jurica-588344',
      image: require('../images/players/doris-bacic/press/doris-bacic-prensa-24-sata.jpg'),
      alt: 'doris-bacic-prensa-24-sata'
    }, {
      id: 3,
      media: 'CORRIERE DELLO SPORT',
      url: 'https://www.corrieredellosport.it/news/calcio/serie-a/juve/2019/05/22-57193425/juventus_women_in_sei_rinnovano_fino_al_2021_/',
      image: require('../images/players/doris-bacic/press/doris-bacic-prensa-corriere-dello-sport.jpg'),
      alt: 'doris-bacic-prensa-corriere-dello-sport'
    }, {
      id: 4,
      media: '24 SATA',
      url: 'https://www.24sata.hr/sport/hrvatica-u-juventusu-slavila-sam-s-cristianom-ronaldom-630814',
      image: require('../images/players/doris-bacic/press/doris-bacic-prensa-24-sata_2.jpg'),
      alt: 'doris-bacic-prensa-24-sata_2'
    }, {
      id: 5,
      media: 'JUVENTUS NEWS 24',
      url: 'https://www.juventusnews24.com/bacic-juventus-women-famiglia-saragama/',
      image: require('../images/players/doris-bacic/press/doris-bacic-prensa-juventus-news-24.jpg'),
      alt: 'doris-bacic-prensa-juventus-news-24'
    }, {
      id: 6,
      media: 'TUTTO MERCATO WEB',
      url: 'https://www.tuttomercatoweb.com/calcio-femminile/juventus-women-anche-doris-bacic-lancia-l-appello-donazioni-per-gli-ospedali-del-piemonte-1361117',
      image: require('../images/players/doris-bacic/press/doris-bacic-prensa-tutto-mercato-web.jpg'),
      alt: 'doris-bacic-prensa-tutto-mercato-web'
    }, {
      id: 7,
      media: 'INDEX HR',
      url: 'https://www.index.hr/sport/clanak/hrvatica-koja-brani-za-juventus-testirana-na-koronavirus/2165402.aspx',
      image: require('../images/players/doris-bacic/press/doris-bacic-prensa-index-hr.jpg'),
      alt: 'doris-bacic-prensa-index-hr'
    }, {
      id: 8,
      media: 'BLITZ QUOTIDIANO',
      url: 'https://www.blitzquotidiano.it/sport/doris-bacic-juventus-instagram-3013464/',
      image: require('../images/players/doris-bacic/press/doris-bacic-prensa-blitz-quotidiano.jpg'),
      alt: 'doris-bacic-prensa-blitz-quotidiano'
    }, {
      id: 9,
      media: 'JUVENTUS WEB',
      url: 'https://www.juventus.com/es/news/news/2020/women-partidos-internacionales.php',
      image: require('../images/players/doris-bacic/press/doris-bacic-prensa-juventus-web.jpg'),
      alt: 'doris-bacic-prensa-juventus-web'
    }, {
      id: 10,
      media: 'JUVENTUS NEWS 24',
      url: 'https://www.juventusnews24.com/bacic-a-suon-di-rock-la-playlist-del-portiere-della-juventus-women/',
      image: require('../images/players/doris-bacic/press/doris-bacic-prensa-juventus-news-24_2.jpg'),
      alt: 'doris-bacic-prensa-juventus-news-24_2'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 13,
    title: 'UNIK TEAM | Estefanía Banini | Jugadora de fútbol femenino',
    metadescription: 'Estefanía Banini, futbolista argentina, actualmente juega como delantera en el Levante UD de la Primera Iberdrola y en la Selección Argentina.',
    url: require('../images/players-profile-photo/estefania-banini-levante-ud.png'),
    alt: 'estefania-banini-levante-ud',
    name: 'ESTEFANÍA BANINI',
    nameUrl: 'estefania-banini',
    team: 'Levante UD',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: 'SEL. ARGENTINA',
    selection_en: 'ARGENTINA TEAM',
    selection_pt: 'SEL. ARGENTINA',
    mainPosition_es : 'DELANTERA',
    mainPosition_en: 'FORWARD',
    mainPosition_pt: 'ATACANTE',
    birthDate: '21 - 06 - 1990',
    birthYear: '1990',
    town: 'Mendoza',
    country: 'Argentina',
    nationality_es : 'Argentina',
    nationality_en : 'Argentinian',
    nationality_pt: 'Argentina',
    height: '1,68',
    weight: '58',
    signaturePhoto: require('../images/players/estefania-banini/jugadoras-estefania-banini-unik-team.png'),
    signaturePhotoAlt: 'jugadoras-estefania-banini-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/estefania-banini/header/estefania-banini-seleccion-argentina-femenina.jpg'),
      alt: 'estefania-banini-seleccion-argentina-femenina'
    }, {
      id: 2,
      url: require('../images/players/estefania-banini/header/estefania-banini-levante-ud-femenino.jpg'),
      alt: 'estefania-banini-levante-ud-femenino'
    }, {
      id: 3,
      url: require('../images/players/estefania-banini/header/estefania-banini-seleccion-argentina-futbol-femenino.jpg'),
      alt: 'estefania-banini-seleccion-argentina-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/home/mobile/estefania-banini-seleccion-argentina.jpg'),
    mobileHeaderPhotoAlt: 'estefania-banini-seleccion-argentina',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Copa de Chile',
      nameFirstRow_en: 'Chilean Cup',
      nameFirstRow_pt: 'Copa do Chile',
      nameSecondRow_es: '"Copa de Campeonas"',
      nameSecondRow_en: '"Copa de Campeonas"',
      nameSecondRow_pt: '"Copa de Campeonas"',
      team_es: 'Colo-Colo',
      team_en: 'Colo-Colo',
      team_pt: 'Colo-Colo',
      years_es: '2015',
      years_en: '2015',
      years_pt: '2015'
    }, {
      id: 2,
      nameFirstRow_es: 'Primera División Chilena',
      nameFirstRow_en: 'Chilean First Division',
      nameFirstRow_pt: 'Primeira Divisão Chilena',
      nameSecondRow_es: '"Campeonato de Clausura"',
      nameSecondRow_en: '"Campeonato de Clausura"',
      nameSecondRow_pt: '"Campeonato de Clausura"',
      team_es: 'Colo-Colo',
      team_en: 'Colo-Colo',
      team_pt: 'Colo-Colo',
      years_es: '2014',
      years_en: '2014',
      years_pt: '2014',
    }, {
      id: 3,
      nameFirstRow_es: 'Primera División Chilena',
      nameFirstRow_en: 'Chilean First Division',
      nameFirstRow_pt: 'Primeira Divisão Chilena',
      nameSecondRow_es: '"Campeonato de Apertura"',
      nameSecondRow_en: '"Campeonato de Apertura"',
      nameSecondRow_pt: '"Campeonato de Apertura"',
      team_es: 'Colo-Colo',
      team_en: 'Colo-Colo',
      team_pt: 'Colo-Colo',
      years_es: '2014',
      years_en: '2014',
      years_pt: '2014'
    }, {
      id: 4,
      nameFirstRow_es: 'Primera División Chilena',
      nameFirstRow_en: 'Chilean First Division',
      nameFirstRow_pt: 'Primeira Divisão Chilena',
      nameSecondRow_es: '"Campeonato de Clausura"',
      nameSecondRow_en: '"Campeonato de Clausura"',
      nameSecondRow_pt: '"Campeonato de Clausura"',
      team_es: 'Colo-Colo',
      team_en: 'Colo-Colo',
      team_pt: 'Colo-Colo',
      years_es: '2013',
      years_en: '2013',
      years_pt: '2013'
    }, {
      id: 5,
      nameFirstRow_es: 'Primera División Chilena',
      nameFirstRow_en: 'Chilean First Division',
      nameFirstRow_pt: 'Primeira Divisão Chilena',
      nameSecondRow_es: '"Campeonato de Apertura"',
      nameSecondRow_en: '"Campeonato de Apertura"',
      nameSecondRow_pt: '"Campeonato de Apertura"',
      team_es: 'Colo-Colo',
      team_en: 'Colo-Colo',
      team_pt: 'Colo-Colo',
      years_es: '2013',
      years_en: '2013',
      years_pt: '2013'
    }, {
      id: 6,
      nameFirstRow_es: 'Primera División Chilena',
      nameFirstRow_en: 'Chilean First Division',
      nameFirstRow_pt: 'Primeira Divisão Chilena',
      nameSecondRow_es: '"Campeonato de Clausura"',
      nameSecondRow_en: '"Campeonato de Clausura"',
      nameSecondRow_pt: '"Campeonato de Clausura"',
      team_es: 'Colo-Colo',
      team_en: 'Colo-Colo',
      team_pt: 'Colo-Colo',
      years_es: '2012',
      years_en: '2012',
      years_pt: '2012'
    }, {
      id: 7,
      nameFirstRow_es: 'Primera División Chilena',
      nameFirstRow_en: 'Chilean First Division',
      nameFirstRow_pt: 'Primeira Divisão Chilena',
      nameSecondRow_es: '"Campeonato de Apertura"',
      nameSecondRow_en: '"Campeonato de Apertura"',
      nameSecondRow_pt: '"Campeonato de Apertura"',
      team_es: 'Colo-Colo',
      team_en: 'Colo-Colo',
      team_pt: 'Colo-Colo',
      years_es: '2012',
      years_en: '2012',
      years_pt: '2012'
    }, {
      id: 8,
      nameFirstRow_es: 'Primera División Chilena',
      nameFirstRow_en: 'Chilean First Division',
      nameFirstRow_pt: 'Primeira Divisão Chilena',
      nameSecondRow_es: '"Campeonato de Clausura"',
      nameSecondRow_en: '"Campeonato de Clausura"',
      nameSecondRow_pt: '"Campeonato de Clausura"',
      team_es: 'Colo-Colo',
      team_en: 'Colo-Colo',
      team_pt: 'Colo-Colo',
      years_es: '2011',
      years_en: '2011',
      years_pt: '2011'
    }, {
      id: 9,
      nameFirstRow_es: 'Primera División Chilena',
      nameFirstRow_en: 'Chilean First Division',
      nameFirstRow_pt: 'Primeira Divisão Chilena',
      nameSecondRow_es: '"Campeonato de Apertura"',
      nameSecondRow_en: '"Campeonato de Apertura"',
      nameSecondRow_pt: '"Campeonato de Apertura"',
      team_es: 'Colo-Colo',
      team_en: 'Colo-Colo',
      team_pt: 'Colo-Colo',
      years_es: '2011',
      years_en: '2011',
      years_pt: '2011'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Medalla de Oro en los',
      nameFirstRow_en: 'South American Games',
      nameFirstRow_pt: 'Jogos Sulamericanos',
      nameSecondRow_es: 'Juegos Sudamericanos',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Argentina',
      team_en: 'Argentinian National Team',
      team_pt: 'Seleção Argentina',
      years_es: 'Chile 2014',
      years_en: 'Chile 2014',
      years_pt: 'Chile 2014'
    }, {
      id: 2,
      nameFirstRow_es: 'Copa Libertadores',
      nameFirstRow_en: 'CONMEBOL Libertadores',
      nameFirstRow_pt: 'Copa Libertadores',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Colo-Colo',
      team_en: 'Colo-Colo',
      team_pt: 'Colo-Colo',
      years_es: '2012',
      years_en: '2012',
      years_pt: '2012'
    }],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'MVP del partido Argentinva vs Japón',
      nameFirstRow_en: 'MVP of the match Argentina vs Japan',
      nameFirstRow_pt: 'MVP do jogo Argentina vs Japón',
      nameSecondRow_es: 'Mundial',
      nameSecondRow_en: 'World Cup',
      nameSecondRow_pt: 'Copa do Mundo',
      years_es: 'Francia 2019',
      years_en: 'France 2019',
      years_pt: 'France 2019'
    }, {
      id: 2,
      nameFirstRow_es: 'Mejor Deportista del',
      nameFirstRow_en: 'Best Football',
      nameFirstRow_pt: 'Melhor Atleta de',
      nameSecondRow_es: 'Fútbol de Chile',
      nameSecondRow_en: 'Player in Chile',
      nameSecondRow_pt: 'Fútbol do Chile',
      years_es: '2013',
      years_en: '2013',
      years_pt: '2013',
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Goals',
      characteristic_pt: 'Gol',
      characteristic_es: 'Gol',
      num: 18
    }, {
      id: 2,
      characteristic_en: 'Dribbling',
      characteristic_pt: 'Drible',
      characteristic_es: 'Regate',
      num: 30
    }, {
      id: 3,
      characteristic_en: 'Assists',
      characteristic_pt: 'Assistências',
      characteristic_es: 'Asistencias',
      num: 2
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }, {
      id: 2,
      characteristic_en: 'Vision',
      characteristic_pt: 'Visão de jogo',
      characteristic_es: 'Visión de juego',
      num: 34
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Speed',
      characteristic_pt: 'Velocidade',
      characteristic_es: 'Velocidad',
      num: 33
    }, {
      id: 2,
      characteristic_en: 'Strength',
      characteristic_pt: 'Força',
      characteristic_es: 'Fuerza',
      num: 17
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: true,
      position: 'INTi'
    },{
      id: 9,
      selected: true,
      position: 'INTd'
    },{
      id: 10,
      selected: true,
      position: 'MP'
    },{
      id: 11,
      selected: true,
      position: 'EXTi'
    },{
      id: 12,
      selected: true,
      position: 'EXTd'
    },{
      id: 13,
      selected: true,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'LEVANTE UD',
      country: 'España',
      years_es: '2018 - Actualidad',
      years_en: '2018 - Currently',
      years_pt: '2018 - Presente',
      image: require('../images/clubs/trayectoria/levante-ud-espana-futbol-femenino.png'),
      alt: 'levante-ud-espana-futbol-femenino'
    }, {
      id: 2,
      team: 'WASHINGTON SPIRIT',
      country: 'Estados Unidos',
      years_es: '2017 - 2018',
      years_en: '2017 - 2018',
      years_pt: '2017 - 2018',
      image: require('../images/clubs/trayectoria/washington-spirit-estados-unidos-futbol-femenino..png'),
      alt: 'washington-spirit-estados-unidos-futbol-femenino'
    }, {
      id: 3,
      team: 'VALENCIA CF',
      country: 'España',
      years_es: '2016 - 2017',
      years_en: '2016 - 2017',
      years_pt: '2016 - 2017',
      image: require('../images/clubs/trayectoria/valencia-cf-espana-futbol-femenino.png'),
      alt: 'valencia-cf-espana-futbol-femenino'
    }, {
      id: 4,
      team: 'WASHINGTON SPIRIT',
      country: 'Estados Unidos',
      years_es: '2015 - 2016',
      years_en: '2015 - 2016',
      years_pt: '2015 - 2016',
      image: require('../images/clubs/trayectoria/washington-spirit-estados-unidos-futbol-femenino..png'),
      alt: 'washington-spirit-estados-unidos-futbol-femenino'
    }, {
      id: 5,
      team: 'COLO-COLO',
      country: 'Chile',
      years_es: '2011 - 2014',
      years_en: '2011 - 2014',
      years_pt: '2011 - 2014',
      image: require('../images/clubs/trayectoria/colo-colo-chile-futbol-femenino.png'),
      alt: 'colo-colo-chile-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'estefibanini_10',
    instagramId: '1399213586',
    instagram: 'https://www.instagram.com/estefibanini_10',
    twitter: 'https://twitter.com/EstefiBanini_10',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'NIKE "Antes que nada/Before That"',
      url: 'https://news.nike.com/featured_video/nike-just-do-it-before-that-argentina',
      image: require('../images/players/estefania-banini/last-campaigns/campana-estefania-banini-nike-antes-que-nada.jpg'),
      alt: 'campana-estefania-banini-nike-antes-que-nada'
    }, {
      id: 2,
      brand: 'NIKE',
      url: 'https://www.instagram.com/p/BxILP0GnBlJ/',
      image: require('../images/players/estefania-banini/last-campaigns/campana-estefania-banini-nike.jpg'),
      alt: 'campana-estefania-banini-nike'
    }, {
      id: 3,
      brand: 'QUILMES',
      url: 'https://www.youtube.com/watch?v=6Q59qtikIMY',
      image: require('../images/players/estefania-banini/last-campaigns/campana-estefania-banini-quilmes.jpg'),
      alt: 'campana-estefania-banini-quilmes'
    }, {
      id: 4,
      brand: 'BALR',
      url: 'https://www.instagram.com/p/B3mfxt5Inau/',
      image: require('../images/players/estefania-banini/last-campaigns/campana-estefania-banini-balr.jpg'),
      alt: 'campana-estefania-banini-balr'
    }, {
      id: 5,
      brand: 'FILMIN',
      url: '',
      image: require('../images/players/estefania-banini/last-campaigns/campana-estefania-banini-filmin.jpg'),
      alt: 'campana-estefania-banini-filmin'
    }, {
      id: 6,
      brand: 'VISA',
      url: 'https://www.instagram.com/p/BrIxGhpn9j-/',
      image: require('../images/players/estefania-banini/last-campaigns/campana-estefania-banini-visa.jpg'),
      alt: 'campana-estefania-banini-visa'
    }],
    press: [{
      id: 1,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2020/04/28/femenino/1588076483_839891.html',
      image: require('../images/players/estefania-banini/press/Estefania-Banini-prensa-diario-as.jpg'),
      alt: 'Estefania-Banini-prensa-diario-as'
    }, {
      id: 2,
      media: 'OLÉ',
      url: 'https://www.ole.com.ar/futbol-femenino/estefania-banini-seleccion-borrello_0_lTx3L8Zoj.html',
      image: require('../images/players/estefania-banini/press/Estefania-Banini-prensa-ole.jpg'),
      alt: 'Estefania-Banini-prensa-ole'
    }, {
      id: 3,
      media: 'TNT SPORTS',
      url: 'https://tntsports.com/videos/estefania-banini-futbol-femenino-seleccion-argentina-messi-vf20200415mp4.html',
      image: require('../images/players/estefania-banini/press/Estefania-Banini-prensa-tnt-sports.jpg'),
      alt: 'Estefania-Banini-prensa-tnt-sports'
    }, {
      id: 4,
      media: 'EL PERIODICO',
      url: 'https://www.elperiodico.com/es/deportes/20190610/estefania-banini-capitana-argentina-mundial-femenino-francia-machismo-futbol-7496303',
      image: require('../images/players/estefania-banini/press/Estefania-Banini-prensa-el-periodico.jpg'),
      alt: 'Estefania-Banini-prensa-el-periodico'
    } ,{
      id: 5,
      media: 'FOX SPORTS',
      url: 'https://www.foxsports.com.ar/news/411291-estefania-banini-la-argentina-que-brillo-en-su-presentacion-en-el-mundial-de-francia',
      image: require('../images/players/estefania-banini/press/Estefania-Banini-prensa-fox-sports.jpg'),
      alt: 'Estefania-Banini-prensa-fox-sports'
    }, {
      id: 6,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2019/06/10/mas_futbol/1560149533_082044.html',
      image: require('../images/players/estefania-banini/press/Estefania-Banini-prensa-diario-as_2.jpg'),
      alt: 'Estefania-Banini-prensa-diario-as_2'
    }, {
      id: 7,
      media: 'MARCA',
      url: 'https://ar.marca.com/claro/futbol/futbol-femenino/seleccion-argentina/2019/06/10/5cfea88d22601d524b8b45a5.html',
      image: require('../images/players/estefania-banini/press/Estefania-Banini-prensa-marca.jpg'),
      alt: 'Estefania-Banini-prensa-marca'
    }, {
      id: 8,
      media: 'CLARÍN',
      url: 'https://www.clarin.com/deportes/futbol/estefania-banini-reflejamos-fortaleza-mujer-argentina_0_k7-BhzRyp.html',
      image: require('../images/players/estefania-banini/press/Estefania-Banini-prensa-clarin.jpg'),
      alt: 'Estefania-Banini-prensa-clarin'
    }, {
      id: 9,
      media: 'INFOBAE',
      url: 'https://www.infobae.com/deportes-2/2019/05/14/estefania-banini-la-capitana-de-la-seleccion-argentina-que-denuncia-el-machismo-en-el-futbol-y-se-ilusiona-con-el-mundial-este-grupo-puede-lograr-grandes-cosas/',
      image: require('../images/players/estefania-banini/press/Estefania-Banini-prensa-ifobae.jpg'),
      alt: 'Estefania-Banini-prensa-ifobae'
    }, {
      id: 10,
      media: 'EL DESMARQUE',
      url: 'https://eldesmarque.com/valencia/levante-ud/211262-banini-el-levante-esta-preparado-para-ganar-partido-a-partido',
      image: require('../images/players/estefania-banini/press/Estefania-Banini-prensa-el-desmarque.jpg'),
      alt: 'Estefania-Banini-prensa-el-desmarque'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },/* {
    id: 14,
    title: '',
    metadescription: '',
    url: require('../images/players-profile-photo/test.png'),
    alt: '',
    name: 'ESTELA FERNÁNDEZ',
    nameUrl: 'estela-fernandez',
    team: 'Madrid CFF',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : '',
    mainPosition_en: '',
    mainPosition_pt: ' ',
    birthDate: '',
    birthYear: '1900',
    town: '',
    country: '',
    nationality_es : 'Española',
    nationality_en : '',
    nationality_pt: '',
    height: '',
    weight: '',
    signaturePhoto: '',
    signaturePhotoAlt: '',
    headerPhotos: [],
    mobileHeaderPhoto: '',
    mobileHeaderPhotoAlt: '',
    nationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: '',
      team_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    internationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: '',
      team_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    individualAwards: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    skilledLeg_es: '',
    skilledLeg_en: '',
    skilledLeg_pt: '',
    skilledLegNum: 35,
    technicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    tacticalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    psychologicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    physicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 0,
      team: '',
      country: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
      image: ''
    }],
    instagramAccessToken: '',
    instagramUsername: '',
    instagramId: '',
    instagram: '',
    twitter: '',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 0,
      brand: '',
      url: '',
      image: '',
    }],
    press: [{
      id: 0,
      media: '',
      url: '',
      image: '',
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },*/ {
    id: 15,
    title: 'UNIK TEAM | Esther González | Jugadora de fútbol femenino',
    metadescription: 'Esther González, futbolista española, actualmente juega como delantera en el Levante UD de la Primera Iberdrola y en la Selección Española.',
    url: require('../images/players-profile-photo/esther-gonzalez-levante-ud.png'),
    alt: 'esther-gonzalez-levante-ud',
    name: 'ESTHER GONZÁLEZ',
    nameUrl: 'esther-gonzalez',
    team: 'Levante UD',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: 'SEL. ESPAÑOLA',
    selection_en: 'SPANISH TEAM',
    selection_pt: 'SEL. ESPANHOLA',
    mainPosition_es : 'DELANTERA',
    mainPosition_en: 'FORWARD',
    mainPosition_pt: 'ATACANTE',
    birthDate: '08 - 12 - 1992',
    birthYear: '1992',
    town: 'Huéscar',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,65',
    weight: '55',
    signaturePhoto: require('../images/players/esther-gonzalez/jugadora-esther-gonzalez-unik-team.png'),
    signaturePhotoAlt: 'jugadora-esther-gonzalez-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/esther-gonzalez/header/esther-gonzalez-levante-ud-femenino-partido.jpg'),
      alt: 'esther-gonzalez-levante-ud-femenino-partido'
    }, {
      id: 2,
      url: require('../images/players/esther-gonzalez/header/esther-gonzalez-levante-ud-futbol-femenino.jpg'),
      alt: 'esther-gonzalez-levante-ud-futbol-femenino'
    }, {
      id: 3,
      url: require('../images/players/esther-gonzalez/header/esther-gonzalez-seleccion-espanola-femenina.jpg'),
      alt: 'esther-gonzalez-seleccion-espanola-femenina'
    }, {
      id: 4,
      url: require('../images/players/esther-gonzalez/header/esther-gonzalez-seleccion-espanola-futbol-femenino.jpg'),
      alt: 'esther-gonzalez-seleccion-espanola-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/esther-gonzalez/header-mobile/esther-gonzalez-levante-ud.jpg'),
    mobileHeaderPhotoAlt: 'esther-gonzalez-levante-ud',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2018 - 2019',
      years_en: '2018 - 2019',
      years_pt: '2018 - 2019'
    }, {
      id: 2,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2017 - 2018',
      years_en: '2017 - 2018',
      years_pt: '2017 - 2018'
    }, {
      id: 3,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2016 - 2017',
      years_en: '2016 - 2017',
      years_pt: '2016 - 2017'
    }, {
      id: 4,
      nameFirstRow_es: 'Copa de España',
      nameFirstRow_en: 'Spanish Cup',
      nameFirstRow_pt: 'Copa da Espanha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2016',
      years_en: '2016',
      years_pt: '2016'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'Eurocopa Sub-17',
      nameSecondRow_en: 'UEFA Euro U-17',
      nameSecondRow_pt: 'Eurocopa Sub-17',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Suiza 2009',
      years_en: 'Switzerland 2009',
      years_pt: 'Suiça 2009'
    }],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Premios por la Igualdad de Género',
      nameFirstRow_en: 'Gender Equality Awards',
      nameFirstRow_pt: 'Prêmio pela Igualdade de Gênero',
      nameSecondRow_es: 'Diputación de Granada - Mención Especial',
      nameSecondRow_en: 'Granada Provincial Council - Special Mention',
      nameSecondRow_pt: 'Província de Granada -  Menção Especial',
      years_es: '2017',
      years_en: '2017',
      years_pt: '2017',
    }, {
      id: 2,
      nameFirstRow_es: 'Jugadora 5 Estrellas',
      nameFirstRow_en: '5-Star Player',
      nameFirstRow_pt: 'Jogadora 5 Estrelas',
      nameSecondRow_es: 'Atlético de Madrid',
      nameSecondRow_en: 'Atlético de Madrid',
      nameSecondRow_pt: 'Atlético de Madrid',
      years_es: '2015 - 2016',
      years_en: '2015 - 2016',
      years_pt: '2015 - 2016'
    }, {
      id: 3,
      nameFirstRow_es: 'Jugadora 5 Estrellas',
      nameFirstRow_en: '5-Star Player',
      nameFirstRow_pt: 'Jogadora 5 Estrelas',
      nameSecondRow_es: 'Atlético de Madrid',
      nameSecondRow_en: 'Atlético de Madrid',
      nameSecondRow_pt: 'Atlético de Madrid',
      years_es: '2014 - 2015',
      years_en: '2014 - 2015',
      years_pt: '2014 - 2015'
    }, {
      id: 4,
      nameFirstRow_es: 'Jugadora Revelación',
      nameFirstRow_en: 'Revelation Player',
      nameFirstRow_pt: 'Jogadora Revelação',
      nameSecondRow_es: 'Atlético de Madrid',
      nameSecondRow_en: 'Atlético de Madrid',
      nameSecondRow_pt: 'Atlético de Madrid',
      years_es: '2013 - 2014',
      years_en: '2013 - 2014',
      years_pt: '2013 - 2014'
    }, {
      id: 5,
      nameFirstRow_es: 'Once de Oro',
      nameFirstRow_en: 'Best 11',
      nameFirstRow_pt: 'Onze de Ouro',
      nameSecondRow_es: 'Fútbol Draft',
      nameSecondRow_en: 'Fútbol Draft',
      nameSecondRow_pt: 'Fútbol Draft',
      years_es: '2013',
      years_en: '2013',
      years_pt: '2013',
    }, {
      id: 6,
      nameFirstRow_es: 'Mejor Jugadora',
      nameFirstRow_en: 'Best Player',
      nameFirstRow_pt: 'Melhor Jogadora',
      nameSecondRow_es: 'Recreativo de Huelva',
      nameSecondRow_en: 'Recreativo de Huelva',
      nameSecondRow_pt: 'Recreativo de Huelva',
      years_es: '2012 - 2013',
      years_en: '2012 - 2013',
      years_pt: '2012 - 2013',
    }, {
      id: 7,
      nameFirstRow_es: 'Once de Oro',
      nameFirstRow_en: 'Best 11',
      nameFirstRow_pt: 'Onze de Ouro',
      nameSecondRow_es: 'Fútbol Draft',
      nameSecondRow_en: 'Fútbol Draft',
      nameSecondRow_pt: 'Fútbol Draft',
      years_es: '2012',
      years_en: '2012',
      years_pt: '2012',
    }, {
      id: 8,
      nameFirstRow_es: 'Máxima Goleadora',
      nameFirstRow_en: 'Top Scorer',
      nameFirstRow_pt: 'Artilheira',
      nameSecondRow_es: 'Atlético de Málaga',
      nameSecondRow_en: 'Atlético de Málaga',
      nameSecondRow_pt: 'Atlético de Málaga',
      years_es: '2011 - 2012',
      years_en: '2011 - 2012',
      years_pt: '2011 - 2012',
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Assists',
      characteristic_pt: 'Assistências',
      characteristic_es: 'Asistencias',
      num: 2
    }, {
      id: 2,
      characteristic_en: 'Ball handling',
      characteristic_pt: 'Condução',
      characteristic_es: 'Conducción',
      num: 8
    }, {
      id: 3,
      characteristic_en: 'Medium range passes',
      characteristic_pt: 'Passe médio',
      characteristic_es: 'Pase Medio',
      num: 26
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Defensive player',
      characteristic_pt: 'Jogadora defensiva',
      characteristic_es: 'Criterio defensivo',
      num: 10
    }, {
      id: 2,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Power',
      characteristic_pt: 'Potência',
      characteristic_es: 'Potencia',
      num: 27
    }, {
      id: 2,
      characteristic_en: 'Strenght',
      characteristic_pt: 'Força',
      characteristic_es: 'Fuerza',
      num: 17
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: true,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: true,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: true,
      position: 'SDC'
    },{
      id: 14,
      selected: true,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'LEVANTE UD',
      country: 'España',
      years_es: '2019 - Actualidad',
      years_en: '2019 - Currently',
      years_pt: '2019 - Presente',
      image: require('../images/clubs/trayectoria/levante-ud-espana-futbol-femenino.png'),
      alt: 'levante-ud-espana-futbol-femenino'
    }, {
      id: 2,
      team: 'ATLÉTICO DE MADRID',
      country: 'España',
      years_es: '2013 - 2019',
      years_en: '2013 - 2019',
      years_pt: '2013 - 2019',
      image: require('../images/clubs/trayectoria/atletico-de-madrid-espana-futbol-femenino.png'),
      alt: 'atletico-de-madrid-espana-futbol-femenino'
    }, {
      id: 3,
      team: 'SPORTING DE HUELVA',
      country: 'España',
      years_es: '2012 - 2013',
      years_en: '2012 - 2013',
      years_pt: '2012 - 2013',
      image: require('../images/clubs/trayectoria/sporting-club-huelva-espana-futbol-femenino.png'),
      alt: 'sporting-club-huelva-espana-futbol-femenino'
    }, {
      id: 4,
      team: 'MÁLAGA CFF',
      country: 'España',
      years_es: '2011 - 2012',
      years_en: '2011 - 2012',
      years_pt: '2011 - 2012',
      image: require('../images/clubs/trayectoria/malaga-cff-espana-futbol-femenino.png'),
      alt: 'malaga-cff-espana-futbol-femenino'
    }, {
      id: 5,
      team: 'LEVANTE UD',
      country: 'España',
      years_es: '2009 - 2011',
      years_en: '2009 - 2011',
      years_pt: '2009 - 2011',
      image: require('../images/clubs/trayectoria/levante-ud-espana-futbol-femenino.png'),
      alt: 'levante-ud-espana-futbol-femenino'
    }, {
      id: 6,
      team: 'ALGAIDAS CD',
      country: 'España',
      years_es: '2007 - 2009',
      years_en: '2007 - 2009',
      years_pt: '2007 - 2009',
      image: require('../images/clubs/trayectoria/algaidas-cd-espana-futbol-femenino.png'),
      alt: 'algaidas-cd-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'estheeer9',
    instagramId: '509133423',
    instagram: 'https://www.instagram.com/estheeer9/',
    twitter: 'https://twitter.com/Estheeer9',
    facebook: 'https://www.facebook.com/esther.gonzalezrodriguez.12',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'NIKE',
      url: 'https://www.instagram.com/p/B7rPeEQIlt1/',
      image: require('../images/players/esther-gonzalez/last-campaigns/campana-esther-gonzalez-nike.jpg'),
      alt: 'campana-esther-gonzalez-nike'
    }, {
      id: 2,
      brand: 'MAGARI SOUND',
      url: '',
      image: require('../images/players/esther-gonzalez/last-campaigns/campana-esther-gonzalez-magari-sound.jpg'),
      alt: 'campana-esther-gonzalez-magari-sound'
    }, {
      id: 3,
      brand: 'SAK PROJECT',
      url: '',
      image: require('../images/players/esther-gonzalez/last-campaigns/campana-esther-gonzalez-sak-project.jpg'),
      alt: 'campana-esther-gonzalez-sak-project'
    }, {
      id: 4,
      brand: 'BALR.',
      url: 'https://www.instagram.com/p/B3mXt0voLCC/',
      image: require('../images/players/esther-gonzalez/last-campaigns/campana-esther-gonzalez-balr.jpg'),
      alt: 'campana-esther-gonzalez-balr'
    }, {
      id: 5,
      brand: 'FILMIN',
      url: '',
      image: require('../images/players/esther-gonzalez/last-campaigns/campana-esther-gonzalez-filmin.jpg'),
      alt: 'campana-esther-gonzalez-filmin'
    }, {
      id: 6,
      brand: 'PRANAMAT ECO',
      url: 'https://www.instagram.com/p/Bys66ZEoW0V/',
      image: require('../images/players/esther-gonzalez/last-campaigns/campana-esther-gonzalez-pranamat-eco.jpg'),
      alt: 'campana-esther-gonzalez-pranamat-eco'
    }, {
      id: 7,
      brand: 'WILSON',
      url: 'https://www.instagram.com/p/B6auAB2I6x3/',
      image: require('../images/players/esther-gonzalez/last-campaigns/campana-esther-gonzalez-wilson-espana.jpg'),
      alt: 'campana-esther-gonzalez-wilson-espana'
    }, {
      id: 8,
      brand: 'ECOVACS ROBOTICS',
      url: 'https://www.instagram.com/p/B2mmxeMnB7g/',
      image: require('../images/players/esther-gonzalez/last-campaigns/campana-esther-gonzalez-ecovacs-robotics.jpg'),
      alt: 'campana-esther-gonzalez-ecovacs-robotics'
    }, {
      id: 9,
      brand: 'AREA ZERO',
      url: 'https://www.instagram.com/p/BugeEbkF2UN/',
      image: require('../images/players/esther-gonzalez/last-campaigns/campana-esther-gonzalez-area-zero.jpg'),
      alt: 'campana-esther-gonzalez-area-zero'
    }, {
      id: 10,
      brand: 'HERBALIFE',
      url: 'https://www.instagram.com/p/BiAi7onlBhJ/',
      image: require('../images/players/esther-gonzalez/last-campaigns/campana-esther-gonzalez-herbalife.jpg'),
      alt: 'campana-esther-gonzalez-herbalife'
    }, {
      id: 11,
      brand: 'WEIDER NUTRITION',
      url: 'https://www.instagram.com/p/ByFqlkxIAb4/',
      image: require('../images/players/esther-gonzalez/last-campaigns/campana-esther-gonzalez-weider.jpg'),
      alt: 'campana-esther-gonzalez-weider'
    }],
    press: [{
      id: 1,
      media: 'LA VANGUARDIA',
      url: 'https://www.lavanguardia.com/television/20190607/462715286242/la-resistencia-broncano-futbolista-atleti-femenino-eshter-gonzalez-dinero-banco.html',
      image: require('../images/players/esther-gonzalez/press/esther-gonzalez-prensa-la-vanguardia.jpg'),
      alt: 'esther-gonzalez-prensa-la-vanguardia'
    }, {
      id: 2,
      media: 'RTVE',
      url: 'https://www.rtve.es/alacarta/videos/futbol/tdp_esthergonzalez_050220/5503566/',
      image: require('../images/players/esther-gonzalez/press/esther-gonzalez-prensa-rtve.jpg'),
      alt: 'esther-gonzalez-prensa-rtve'
    }, {
      id: 3,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/2019/07/11/5d270eeae2704e6e5d8b46b1.html',
      image: require('../images/players/esther-gonzalez/press/esther-gonzalez-prensa-marca.jpg'),
      alt: 'esther-gonzalez-prensa-marca'
    }, {
      id: 4,
      media: 'SPORT',
      url: 'https://www.sport.es/es/videos/futbol-femenino/20190607/esther-gonzalez-confiesa-en-la-resistencia-que-deja-el-atletico-de-madrid/4634782.shtml',
      image: require('../images/players/esther-gonzalez/press/esther-gonzalez-prensa-sport.jpg'),
      alt: 'esther-gonzalez-prensa-sport'
    }, {
      id: 5,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/primera-division/2019/05/08/5cd2f5f622601d87778b461c.html',
      image: require('../images/players/esther-gonzalez/press/esther-gonzalez-prensa-marca_2.jpg'),
      alt: 'esther-gonzalez-prensa-marca_2'
    }, {
      id: 6,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/femenino/20190320/461129126797/esther-gonzalez-una-vida-a-todo-tren-entre-el-futbol-y-la-universidad.html',
      image: require('../images/players/esther-gonzalez/press/esther-gonzalez-prensa-mundo-deportivo.jpg'),
      alt: 'esther-gonzalez-prensa-mundo-deportivo'
    }, {
      id: 7,
      media: 'EL DESMARQUE',
      url: 'https://eldesmarque.com/valencia/levante-ud/213664-esther-nuestro-objetivo-es-meternos-en-champions',
      image: require('../images/players/esther-gonzalez/press/esther-gonzalez-prensa-el-desmarque.jpg'),
      alt: 'esther-gonzalez-prensa-el-desmarque'
    }, {
      id: 8,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2019/05/01/mas_futbol/1556726251_522540.html',
      image: require('../images/players/esther-gonzalez/press/esther-gonzalez-prensa-diario-as.jpg'),
      alt: 'esther-gonzalez-prensa-diario-as'
    }, {
      id: 9,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/femenino/20190510/462146104204/la-goleadora-del-atletico-feminas-trolea-a-david-broncano.html',
      image: require('../images/players/esther-gonzalez/press/esther-gonzalez-prensa-mundo-deportivo_2.jpg'),
      alt: 'esther-gonzalez-prensa-mundo-deportivo_2'
    }, {
      id: 10,
      media: 'TELEMADRID',
      url: 'http://www.telemadrid.es/programas/el-partido-de-la-una/Esther-Gonzalez-competitividad-equipo-mejores-9-2119378060--20190506033851.html',
      image: require('../images/players/esther-gonzalez/press/esther-gonzalez-prensa-tele-madrid.jpg'),
      alt: 'esther-gonzalez-prensa-tele-madrid'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 16,
    title: 'UNIK TEAM | Fabiana Simões | Jugadora de fútbol femenino',
    metadescription: 'Fabiana Simões, futbolista brasileña, actualmente juega como defensa en el SC Internacional del Brasileirão Feminino - Série A1 y en la Selección Brasileña.',
    url: require('../images/players-profile-photo/fabiana-simoes-sc-internacional.png'),
    alt: 'fabiana-simoes-sc-internacional',
    name: 'FABIANA SIMÕES',
    nameUrl: 'fabiana-simoes',
    team: 'SC Internacional',
    league_es: 'Brasileirão Feminino - Série A1 (Brasil)',
    league_en: 'Brasileirão Feminino - Série A1 (Brazil)',
    league_pt: 'Brasileirão Feminino - Série A1 (Brasil)',
    selection_es: 'SEL. BRASILEÑA',
    selection_en: 'BRAZILIAN TEAM',
    selection_pt: 'SELEÇÃO BRASILEIRA',
    mainPosition_es : 'DEFENSA',
    mainPosition_en: 'DEFENDER',
    mainPosition_pt: 'DEFESA',
    birthDate: '04 - 08 - 1989',
    birthYear: '1989',
    town: 'Salvador',
    country: 'Brasil',
    nationality_es : 'Brasileña',
    nationality_en : 'Brazilian',
    nationality_pt: 'Brasileira',
    height: '1,63',
    weight: '60',
    signaturePhoto: require('../images/players/fabiana-simoes/jugadora-fabiana-simoes-unik-team.png'),
    signaturePhotoAlt: 'jugadora-fabiana-simoes-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/fabiana-simoes/header/fabiana-simoes-futbol-femenino-brasil.jpg'),
      alt: 'fabiana-simoes-futbol-femenino-brasil'
    }, {
      id: 2,
      url: require('../images/players/fabiana-simoes/header/fabiana-simooes-seleccion-brasilena-femenina.jpg'),
      alt: 'fabiana-simooes-seleccion-brasilena-femenina'
    }, {
      id: 3,
      url: require('../images/players/fabiana-simoes/header/fabiana-simooes-seleccion-brasilena-futbol-femenino..jpg'),
      alt: 'fabiana-simooes-seleccion-brasilena-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/home/mobile/fabiana-simoes-seleccion-brasilena.jpg'),
    mobileHeaderPhotoAlt: 'fabiana-simoes-seleccion-brasilena',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Copa de España',
      nameFirstRow_en: 'Spanish Cup',
      nameFirstRow_pt: 'Copa da Espanha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    }, {
      id: 2,
      nameFirstRow_es: 'Copa Catalunya',
      nameFirstRow_en: 'Catalonia Cup',
      nameFirstRow_pt: 'Copa Catalunha',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    }, {
      id: 3,
      nameFirstRow_es: 'Subcampeona de la Primera División Brasil',
      nameFirstRow_en: 'Runner-up of the Brazilian First Division',
      nameFirstRow_pt: 'Vice-campeã da Primeira Divisão Brasileira',
      nameSecondRow_es: ' "Brasileirão Feminino - Série A1"',
      nameSecondRow_en: '"Brasileirão Feminino - Série A1"',
      nameSecondRow_pt: '"Brasileirão Feminino - Série A1"',
      team_es: 'São José y Corinthians',
      team_en: 'São José y Corinthians',
      team_pt: 'São José y Corinthians',
      years_es: '2013 y 2017',
      years_en: '2013 and 2017',
      years_pt: '2013 e 2017'
    }, {
      id: 4,
      nameFirstRow_es: 'Subcampeona de la SuperCopa China',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da SuperCopa Chinesa',
      nameSecondRow_es: '"Chinese FA Super Cup"',
      nameSecondRow_en: '"Chinese FA Super Cup"',
      nameSecondRow_pt: '"Chinese FA Super Cup"',
      team_es: 'Dalian Quanjian',
      team_en: 'Dalian Quanjian',
      team_pt: 'Dalian Quanjian',
      years_es: '2016',
      years_en: '2016',
      years_pt: '2016'
    }, {
      id: 5,
      nameFirstRow_es: 'Primera División China',
      nameFirstRow_en: 'Chinese First Division',
      nameFirstRow_pt: 'Primeira Divisão Chinesa',
      nameSecondRow_es: '"Chinese Super League"',
      nameSecondRow_en: '"Chinese Super League"',
      nameSecondRow_pt: '"Chinese Super League"',
      team_es: 'Dalian Quanjian',
      team_en: 'Dalian Quanjian',
      team_pt: 'Dalian Quanjian',
      years_es: '2016',
      years_en: '2016',
      years_pt: '2016'
    }, {
      id: 6,
      nameFirstRow_es: 'Primera División Rusa',
      nameFirstRow_en: 'Russian First Division',
      nameFirstRow_pt: 'Primeira Divisão Russa',
      nameSecondRow_es: '"Top Division"',
      nameSecondRow_en: '"Top Division"',
      nameSecondRow_pt: '"Top Division"',
      team_es: 'WFC Rossiyanka',
      team_en: 'WFC Rossiyanka',
      team_pt: 'WFC Rossiyanka',
      years_es: '2011 y 2012',
      years_en: '2011 and 2012',
      years_pt: '2011 e 2012'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Medalla de oro en los',
      nameFirstRow_en: 'Golden medal in the',
      nameFirstRow_pt: 'Medalha de ouro nos',
      nameSecondRow_es: 'Juegos Panamericanos',
      nameSecondRow_en: 'Panamerican Games',
      nameSecondRow_pt: 'Jogos Panamericanos',
      team_es: 'Selección Brasileña',
      team_en: 'Brazilian National Team',
      team_pt: 'Seleção Brasileira',
      years_es: 'Canadá 2015',
      years_en: 'Canada 2015',
      years_pt: 'Canadá 2015',
    }, {
      id: 2,
      nameFirstRow_es: 'Copa América',
      nameFirstRow_en: 'CONMEBOL America Cup',
      nameFirstRow_pt: 'Copa América',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Brasileña',
      team_en: 'Brazilian National Team',
      team_pt: 'Seleção Brasileira',
      years_es: 'Ecuador 2014',
      years_en: 'Ecuador 2014',
      years_pt: 'Ecuador 2014'
    }, {
      id: 3,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: 'Runner-up of the ',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'Champions League',
      nameSecondRow_en: 'Champions League',
      nameSecondRow_pt: 'Champions League',
      team_es: 'Tyresö ',
      team_en: 'Tyresö ',
      team_pt: 'Tyresö ',
      years_es: '2014',
      years_en: '2014',
      years_pt: '2014'
    }, {
      id: 4,
      nameFirstRow_es: 'Copa Libertadores',
      nameFirstRow_en: 'CONMEBOL Libertadores',
      nameFirstRow_pt: 'Copa Libertadores',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Brasileña',
      team_en: 'Brazilian National Team',
      team_pt: 'Seleção Brasileira',
      years_es: 'Brasil 2013',
      years_en: 'Brazil 2013',
      years_pt: 'Brazil 2013'
    }, {
      id: 5,
      nameFirstRow_es: 'Copa América',
      nameFirstRow_en: 'South American Championship',
      nameFirstRow_pt: 'Copa América',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Brasileña',
      team_en: 'Brazilian National Team',
      team_pt: 'Seleção Brasileira',
      years_es: 'Ecuador 2010',
      years_en: 'Ecuador 2010',
      years_pt: 'Ecuador 2010',
    }, {
      id: 6,
      nameFirstRow_es: 'Medalla de plata en los',
      nameFirstRow_en: 'Silver medal in the',
      nameFirstRow_pt: 'Medalha de prata nos',
      nameSecondRow_es: 'Juegos Olímpicos',
      nameSecondRow_en: 'Olympic Games',
      nameSecondRow_pt: 'Jogos Olímpicos​',
      team_es: 'Selección Brasileña',
      team_en: 'Brazilian National Team',
      team_pt: 'Seleção Brasileira',
      years_es: 'Pequín 2008',
      years_en: 'Beijing 2008',
      years_pt: 'Pequim 2008'
    }, {
      id: 7,
      nameFirstRow_es: 'Campeonato Panamericano',
      nameFirstRow_en: 'Panamerican Championship',
      nameFirstRow_pt: 'Campeonato Panamericano',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Brasileña',
      team_en: 'Brazilian National Team',
      team_pt: 'Seleção Brasileira',
      years_es: 'Argentina 2007',
      years_en: 'Argentina 2007',
      years_pt: 'Pequim 2008'
    }, {
      id: 8,
      nameFirstRow_es: 'Medalla de bronce en el',
      nameFirstRow_en: 'Broze medal in the',
      nameFirstRow_pt: 'Medalha de bronze na',
      nameSecondRow_es: 'Mundial Sub-20',
      nameSecondRow_en: 'U-20 World Cup',
      nameSecondRow_pt: 'Copa do Mundial Sub-20',
      team_es: 'Selección Brasileña',
      team_en: 'Brazilian National Team',
      team_pt: 'Seleção Brasileira',
      years_es: 'Rusia 2006',
      years_en: 'Russia 2006',
      years_pt: 'Russia 2006'
    }, {
      id: 9,
      nameFirstRow_es: 'Campeonato Sudamericano',
      nameFirstRow_en: 'U-20 South American Championship',
      nameFirstRow_pt: 'Campeonato Sulamericano',
      nameSecondRow_es: 'Sub-20',
      nameSecondRow_en: '',
      nameSecondRow_pt: 'Sub-20',
      team_es: 'Selección Brasileña',
      team_en: 'Brazilian National Team',
      team_pt: 'Seleção Brasileira',
      years_es: 'Chile 2006',
      years_en: 'Chile 2006',
      years_pt: 'Chile 2006'
    }],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Mejor Lateral',
      nameFirstRow_en: 'Best Right Back',
      nameFirstRow_pt: 'Melhor Lateral',
      nameSecondRow_es: 'Primera División Brasil "Brasileirão Feminino - Série A1"',
      nameSecondRow_en: 'Brazilian First Division "Brasileirão Feminino - Série A1"',
      nameSecondRow_pt: 'Brasileirão Feminino - Série A1',
      years_es: '2019',
      years_en: '2019',
      years_pt: '2019'
    }, {
      id: 2,
      nameFirstRow_es: 'Mejor Jugadora',
      nameFirstRow_en: 'Best Player',
      nameFirstRow_pt: 'Melhor Jogadora',
      nameSecondRow_es: 'Torneo Internacional de Fútbol Femenino "Torneio Internacional São Paulo"',
      nameSecondRow_en: 'International Women`s Football Tournament "Torneio Internacional São Paulo"',
      nameSecondRow_pt: 'Torneio Internacional São Paulo',
      years_es: '2012',
      years_en: '2012',
      years_pt: '2012'
    }, {
      id: 3,
      nameFirstRow_es: 'Jugadora Revelación',
      nameFirstRow_en: 'Best Revelation Player',
      nameFirstRow_pt: 'Jogadora Revelação ',
      nameSecondRow_es: 'Primera División del Estado de Rio de Janeiro "Campeonato Carioca"',
      nameSecondRow_en: 'First Division in the State of Rio de Janeiro "Campeonato Carioca Feminino"',
      nameSecondRow_pt: 'Campeonato Carioca',
      years_es: '2006',
      years_en: '2006',
      years_pt: '2006'
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Assists',
      characteristic_pt: 'Assistências',
      characteristic_es: 'Asistencias',
      num: 2
    }, {
      id: 2,
      characteristic_en: 'Ball handling',
      characteristic_pt: 'Condução',
      characteristic_es: 'Conducción',
      num: 8
    }, {
      id: 3,
      characteristic_en: 'Medium range passes',
      characteristic_pt: 'Passe médio',
      characteristic_es: 'Pase medio',
      num: 26
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Defensive player',
      characteristic_pt: 'Jogadora defensiva',
      characteristic_es: 'Criterio defensivo',
      num: 10
    }, {
      id: 2,
      characteristic_en: 'Offensive player ',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Power',
      characteristic_pt: 'Potência',
      characteristic_es: 'Potencia',
      num: 27
    }, {
      id: 2,
      characteristic_en: 'Strenght',
      characteristic_pt: 'Força',
      characteristic_es: 'Fuerza',
      num: 17
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: true,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: true,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: true,
      position: 'EXTd'
    },{
      id: 13,
      selected: true,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'SC INTERNACIONAL',
      country: 'Brasil',
      years_es: '2019 - Actualidad',
      years_en: '2019 - Currently',
      years_pt: '2019 - Presente',
      image: require('../images/clubs/trayectoria/sc-internaciona-brasil-futbol-femeninol.png'),
      alt: 'sc-internaciona-brasil-futbol-femenino'
    }, {
      id: 2,
      team: 'WUHAN ZALL FC',
      country: 'China',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018',
      image: require('../images/clubs/trayectoria/wuhan-zall-china-futbol-femenino.png'),
      alt: 'wuhan-zall-china-futbol-femenino'
    }, {
      id: 3,
      team: 'FC BARCELONA',
      country: 'España',
      years_es: '2017 - 2018',
      years_en: '2017 - 2018',
      years_pt: '2017 - 2018',
      image: require('../images/clubs/trayectoria/fc-barcelona-espana-futbol-femenino.png'),
      alt: 'fc-barcelona-espana-futbol-femenino'
    }, {
      id: 4,
      team: 'CORINTHIANS',
      country: 'Brasil',
      years_es: '2017',
      years_en: '2017',
      years_pt: '2017',
      image: require('../images/clubs/trayectoria/corinthians-brasil-futbol-femenino.png'),
      alt: 'corinthians-brasil-futbol-femenino'
    }, {
      id: 5,
      team: 'DALIAN QUANJIAN',
      country: 'China',
      years_es: '2016',
      years_en: '2016',
      years_pt: '2016',
      image: require('../images/clubs/trayectoria/quanjian-china-futbol-femenino.png'),
      alt: 'quanjian-china-futbol-femenino'
    }, {
      id: 6,
      team: 'CENTRO OLÍMPICO',
      country: 'Brasil',
      years_es: '2014 - 2016',
      years_en: '2014 - 2016',
      years_pt: '2014 - 2016',
      image: require('../images/clubs/trayectoria/centro-olimpico-treinamento-brasil-futbol-femenino.png'),
      alt: 'centro-olimpico-treinamento-brasil-futbol-femenino'
    }, {
      id: 7,
      team: 'TYRESÖ',
      country: 'Suecia',
      years_es: '2014',
      years_en: '2014',
      years_pt: '2014',
      image: require('../images/clubs/trayectoria/tyres-ff-suecia-futbol-femenino.png'),
      alt: 'tyres-ff-suecia-futbol-femenino'
    }, {
      id: 8,
      team: 'SÃO JOSÉ',
      country: 'Brasil',
      years_es: '2013',
      years_en: '2013',
      years_pt: '2013',
      image: require('../images/clubs/trayectoria/sao-jose-esporte-clube-brasil-futbol-femenino.png'),
      alt: 'sao-jose-esporte-clube-brasil-futbol-femenino'
    }, {
      id: 9,
      team: 'WFC ROSSIYANKA',
      country: 'Rusia',
      years_es: '2011 - 2013',
      years_en: '2011 - 2013',
      years_pt: '2011 - 2013',
      image: require('../images/clubs/trayectoria/rossiyanka-rusia-futbol-femenino..png'),
      alt: 'rossiyanka-rusia-futbol-femenino'
    }, {
      id: 10,
      team: 'SANTOS',
      country: 'Brasil',
      years_es: '2011',
      years_en: '2011',
      years_pt: '2011',
      image: require('../images/clubs/trayectoria/santos-futebol-clube-brasil-futbol-femenino.png'),
      alt: 'santos-futebol-clube-brasil-futbol-femenino'
    }, {
      id: 11,
      team: 'BOSTON BREAKERS',
      country: 'Estados Unidos',
      years_es: '2009 - 2010',
      years_en: '2009 - 2010',
      years_pt: '2009 - 2010',
      image: require('../images/clubs/trayectoria/boston-breakers-estados-unidos-futbol-femenino.png'),
      alt: 'boston-breakers-estados-unidos-futbol-femenino'
    }, {
      id: 12,
      team: 'CORINTHIANS',
      country: 'Brasil',
      years_es: '2008',
      years_en: '2008',
      years_pt: '2008',
      image: require('../images/clubs/trayectoria/corinthians-brasil-futbol-femenino.png'),
      alt: 'corinthians-brasil-futbol-femenino'
    }, {
      id: 13,
      team: 'SPORTING DE HUELVA',
      country: 'España',
      years_es: '2007 - 2008',
      years_en: '2007 - 2008',
      years_pt: '2007 - 2008',
      image: require('../images/clubs/trayectoria/sporting-club-huelva-espana-futbol-femenino.png'),
      alt: 'sporting-club-huelva-espana-futbol-femenino'
    }, {
      id: 14,
      team: 'CEPE-CAXIAS',
      country: 'Brasil',
      years_es: '2007',
      years_en: '2007',
      years_pt: '2007',
      image: require('../images/clubs/trayectoria/cep-brasil-futbol-femenino.png'),
      alt: 'cep-brasil-futbol-femenino'
    }, {
      id: 15,
      team: 'AMÉRICA',
      country: 'Brasil',
      years_es: '2006',
      years_en: '2006',
      years_pt: '2006',
      image: require('../images/clubs/trayectoria/america-fc-brasil-futbol-femenino.png'),
      alt: 'america-fc-brasil-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'fabsimoes',
    instagramId: '199312639',
    instagram: 'https://www.instagram.com/fabsimoes',
    twitter: 'https://twitter.com/fabianasimoes15',
    facebook: 'https://www.facebook.com/FabianaSimoesFS7',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'GUARANÁ ANTÁRCTICA',
      url: 'https://www.youtube.com/watch?v=8wsDd_MqXwM',
      image: require('../images/players/fabiana-simoes/last-campaigns/campana-fabiana-simoes-guarana-antarctica.jpg'),
      alt: 'campana-fabiana-simoes-guarana-antarctica'
    }, {
      id: 2,
      brand: 'GOOGLE BRASIL',
      url: 'https://www.instagram.com/p/BxaHw2iBJTT/',
      image: require('../images/players/fabiana-simoes/last-campaigns/campana-fabiana-simoes-google-brasil.jpg'),
      alt: 'campana-fabiana-simoes-google-brasil'
    }, {
      id: 3,
      brand: 'PUMA',
      url: 'https://www.instagram.com/p/B-P9c6GpoLI/',
      image: require('../images/players/fabiana-simoes/last-campaigns/campana-fabiana-simoes-puma.jpg'),
      alt: 'campana-fabiana-simoes-puma'
    }, {
      id: 4,
      brand: 'NUTRATA',
      url: 'https://www.instagram.com/p/B8ME5KLJuO8/',
      image: require('../images/players/fabiana-simoes/last-campaigns/campana-fabiana-simoes-nutrata.jpg'),
      alt: 'campana-fabiana-simoes-nutrata'
    }, {
      id: 5,
      brand: 'MOVEMENT ACTIVE RESEARCH',
      url: 'https://www.instagram.com/p/BrAti5jnAk9/',
      image: require('../images/players/fabiana-simoes/last-campaigns/campana-fabiana-simoes-movement-active-research.jpg'),
      alt: 'campana-fabiana-simoes-movement-active-research'
    }, {
      id: 6,
      brand: 'OFICIAL FARMA',
      url: '',
      image: require('../images/players/fabiana-simoes/last-campaigns/campana-fabiana-simoes-oficial-farma.jpg'),
      alt: 'campana-fabiana-simoes-oficial-farma'
    }],
    press: [{
      id: 1,
      media: 'RÁDIO GRENAL',
      url: 'http://www.radiogrenal.com.br/fabi-simoes-do-inter-recebe-premio-de-melhor-lateral-direita-do-brasileirao/',
      image: require('../images/players/fabiana-simoes/press/Fabiana-Simoes-prensa-radio-grenal.jpg'),
      alt: 'Fabiana-Simoes-prensa-radio-grenal'
    }, {
      id: 2,
      media: 'ESPN',
      url: 'https://www.espn.com.br/espnw/artigo/_/id/5411719/inter-anuncia-contratacao-de-fabi-simoes-lateral-da-selecao-brasileira',
      image: require('../images/players/fabiana-simoes/press/Fabiana-Simoes-prensa-espn.jpg'),
      alt: 'Fabiana-Simoes-prensa-espn'
    }, {
      id: 3,
      media: 'GAÚCHA ZH',
      url: 'https://gauchazh.clicrbs.com.br/esportes/inter/noticia/2019/09/marcado-na-minha-vida-diz-fabi-simoes-autora-de-tres-gols-no-gre-nal-feminino-ck12gu5on00uw01n3ha7gq5zh.html',
      image: require('../images/players/fabiana-simoes/press/Fabiana-Simoes-prensa-gaucha-zh-1.jpg'),
      alt: 'Fabiana-Simoes-prensa-gaucha-zh-1'
    }, {
      id: 4,
      media: 'CORREIO DO POVO',
      url: 'https://www.correiodopovo.com.br/esportes/com-jogadora-do-inter-vad%C3%A3o-convoca-sele%C3%A7%C3%A3o-feminina-para-copa-do-mundo-1.339428',
      image: require('../images/players/fabiana-simoes/press/Fabiana-Simoes-prensa-correio-do-povo.jpg'),
      alt: 'Fabiana-Simoes-prensa-correio-do-povo'
    }, {
      id: 5,
      media: 'GAÚCHA ZH',
      url: 'https://gauchazh.clicrbs.com.br/esportes/gauchao/noticia/2019/09/fabi-simoes-marca-tres-vezes-e-inter-vence-gre-nal-por-4-a-0-ck0u320p00dl701tgitde8qg3.html',
      image: require('../images/players/fabiana-simoes/press/Fabiana-Simoes-prensa-gaucha-zh-2.jpg'),
      alt: 'Fabiana-Simoes-prensa-gaucha-zh-2'
    }, {
      id: 6,
      media: 'CORREIO 24 HORAS',
      url: 'https://www.correio24horas.com.br/noticia/nid/com-pegadinha-no-pai-fabiana-comemora-convocacao-para-a-copa/',
      image: require('../images/players/fabiana-simoes/press/Fabiana-Simoes-prensa-correio-24-horas.jpg'),
      alt: 'Fabiana-Simoes-prensa-correio-24-horas'
    }, {
      id: 7,
      media: 'EXTRA',
      url: 'https://extra.globo.com/esporte/lateral-brasileira-fabiana-simoes-confirmada-como-reforco-do-barcelona-21649328.html',
      image: require('../images/players/fabiana-simoes/press/Fabiana-Simoes-prensa-extra.jpg'),
      alt: 'Fabiana-Simoes-prensa-extra'
    }, {
      id: 8,
      media: 'HOJE EM DIA',
      url: 'https://www.hojeemdia.com.br/esportes/fabiana-lembra-drama-em-belo-horizonte-antes-de-chegar-%C3%A0-sele%C3%A7%C3%A3o-brasileira-e-ao-barcelona-1.608557',
      image: require('../images/players/fabiana-simoes/press/Fabiana-Simoes-prensa-hoje-em-dia.jpg'),
      alt: 'Fabiana-Simoes-prensa-hoje-em-dia'
    }, {
      id: 9,
      media: 'ESPN',
      url: 'https://www.espn.com.br/espnw/artigo/_/id/5693390/sele%C3%A7%C3%A3o-brasileira-fabiana-%C3%A9-desconvocada-por-les%C3%A3o-e-poliana-%C3%A9-chamada-para-a-copa-do-mundo',
      image: require('../images/players/fabiana-simoes/press/Fabiana-Simoes-prensa-espn-2.jpg'),
      alt: 'Fabiana-Simoes-prensa-espn-2'
    }, {
      id: 10,
      media: 'GAÚCHA ZH',
      url: 'https://gauchazh.clicrbs.com.br/esportes/inter/noticia/2019/12/menino-pede-e-ganha-camiseta-de-fabi-simoes-vou-mostrar-para-todos-no-colegio-ck3nosqkk01m401rzb5n9wrdn.html',
      image: require('../images/players/fabiana-simoes/press/Fabiana-Simoes-prensa-gaucha-zh-3.jpg'),
      alt: 'Fabiana-Simoes-prensa-gaucha-zh-3'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 17,
    title: 'UNIK TEAM | Francisca Ordega | Jugadora de fútbol femenino',
    metadescription: 'Francisca Ordega, futbolista nigeriana, actualmente juega como delantera en el Shanghai Shenhua de la Chinese Super League y en la Selección Nigeriana.',
    url: require('../images/players-profile-photo/Francisca-Ordega.png'),
    alt: 'Francisca-Ordega',
    name: 'FRANCISCA ORDEGA',
    nameUrl: 'francisca-ordega',
    team: 'Shanghai Shenhua',
    league_es: 'Chinese Super League (China)',
    league_en: 'Chinese Super League (China)',
    league_pt: 'Chinese Super League (China)',
    selection_es: 'SEL. NIGERIANA',
    selection_en: 'NIGERIAN TEAM',
    selection_pt: 'SEL. NIGERIANA',
    mainPosition_es : 'DELANTERA',
    mainPosition_en: 'FORWARD',
    mainPosition_pt: 'ATACANTE',
    birthDate: '19 - 10 - 1993',
    birthYear: '1993',
    town: 'Gboko',
    country: 'Nigeria',
    nationality_es : 'Nigeriana',
    nationality_en : 'Nigerian',
    nationality_pt: 'Nigeriana',
    height: '1,62',
    weight: '56',
    signaturePhoto: require('../images/players/francisca-ordega/jugadora-francisca-ordega-unikteam.png'),
    signaturePhotoAlt: 'jugadora-francisca-ordega-unikteam',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/francisca-ordega/header/francisca-ordega-seleccion-nigeria-femenina.jpg'),
      alt: 'francisca-ordega-seleccion-nigeria-femenina'
    }, {
      id: 2,
      url: require('../images/players/francisca-ordega/header/francisca-ordega-seleccion-nigeria-futbol-femenino.jpg'),
      alt: 'francisca-ordega-seleccion-nigeria-futbol-femenino'
    }, {
      id: 3,
      url: require('../images/players/francisca-ordega/header/francisca-ordega-shanghai-senhua-futbol-femenino.jpg'),
      alt: 'francisca-ordega-shanghai-senhua-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/francisca-ordega/header-mobile/francisca-ordega-seleccion-nigeria.jpg'),
    mobileHeaderPhotoAlt: 'francisca-ordega-seleccion-nigeria',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2017 - 2018',
      years_en: '2017 - 2018',
      years_pt: '2017 - 2018'
    }, {
      id: 2,
      nameFirstRow_es: 'Subcampeona de la Máxima División de Estados Unidos',
      nameFirstRow_en: 'Runner-up of the American League',
      nameFirstRow_pt: 'Vice-campeã da Primeira Divisão Americana',
      nameSecondRow_es: "National Women's Soccer League",
      nameSecondRow_en: "National Women's Soccer League",
      nameSecondRow_pt: "National Women's Soccer League",
      team_es: 'Washington Spirit',
      team_en: 'Washington Spirit',
      team_pt: 'Washington Spirit',
      years_es: '2016',
      years_en: '2016',
      years_pt: '2016'
    }, {
      id: 3,
      nameFirstRow_es: 'Copa de Nigeria',
      nameFirstRow_en: 'Nigerian Cup',
      nameFirstRow_pt: 'Copa da Nigéria',
      nameSecondRow_es: '"AITEO Cup"',
      nameSecondRow_en: '"AITEO Cup"',
      nameSecondRow_pt: '"AITEO Cup"',
      team_es: 'Rivers Angels F.C.',
      team_en: 'Rivers Angels F.C.',
      team_pt: 'Rivers Angels F.C.',
      years_es: '2012',
      years_en: '2012',
      years_pt: '2012'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Campeonato Africano de',
      nameFirstRow_en: 'Africa Women Cup of',
      nameFirstRow_pt: 'Campeonato Africano',
      nameSecondRow_es: 'Fútbol',
      nameSecondRow_en: 'Nations',
      nameSecondRow_pt: '',
      team_es: 'Selección Nigeriana',
      team_en: 'Nigerian National Team',
      team_pt: 'Seleção Nigeriana',
      years_es: '2010, 2014, 2016 y 2018',
      years_en: '2010, 2014, 2016 and 2018',
      years_pt: '2010, 2014, 2016 e 2018'
    }],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Nominada al premio de',
      nameFirstRow_en: 'Nominated for',
      nameFirstRow_pt: 'Indicação ao prêmio de',
      nameSecondRow_es: 'Mejor Futbolista Africana',
      nameSecondRow_en: 'Best Female African Footballer',
      nameSecondRow_pt: 'Mejor Jogadora Africana',
      years_es: '2014 y 2018',
      years_en: '2014 and 2018',
      years_pt: '2014 e 2018',
    }, {
      id: 2,
      nameFirstRow_es: 'Elegida dos veces MVP',
      nameFirstRow_en: 'Two-times MVP',
      nameFirstRow_pt: 'Duas vezes MVP',
      nameSecondRow_es: 'Campeonato Africano de Fútbol',
      nameSecondRow_en: 'Africa Women Cup of Nations',
      nameSecondRow_pt: 'Campeonato Africano',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    }, {
      id: 3,
      nameFirstRow_es: 'Mejor 11 del mes y elegida tres veces Mejor Jugadora de la jornada',
      nameFirstRow_en: 'Best 11 Players of the Month and three-time Player of the Week',
      nameFirstRow_pt: 'Melhor 11 do mês e três vezes Melhor Jogadora da rodada',
      nameSecondRow_es: "National Women's Soccer League",
      nameSecondRow_en: "National Women's Soccer League",
      nameSecondRow_pt: "National Women's Soccer League",
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Goals',
      characteristic_pt: 'Gol',
      characteristic_es: 'Gol',
      num: 18
    }, {
      id: 2,
      characteristic_en: 'Dribbling',
      characteristic_pt: 'Drible',
      characteristic_es: 'Regate',
      num: 30
    }, {
      id: 3,
      characteristic_en: 'Assists',
      characteristic_pt: 'Assistências',
      characteristic_es: 'Asistencias',
      num: 2
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio Ofensivo',
      num: 11
    }, {
      id: 2,
      characteristic_en: 'Skilled passer',
      characteristic_pt: 'Ligação',
      characteristic_es: 'Asociación',
      num: 3
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Strong personality',
      characteristic_pt: 'Personalidade forte',
      characteristic_es: 'Carácter',
      num: 5
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Speed',
      characteristic_pt: 'Velocidade',
      characteristic_es: 'Velocidad',
      num: 33
    }, {
      id: 2,
      characteristic_en: 'Power',
      characteristic_pt: 'Potência',
      characteristic_es: 'Potencia',
      num: 27
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: true,
      position: 'MP'
    },{
      id: 11,
      selected: true,
      position: 'EXTi'
    },{
      id: 12,
      selected: true,
      position: 'EXTd'
    },{
      id: 13,
      selected: true,
      position: 'SDC'
    },{
      id: 14,
      selected: true,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'SHANGHAI SHENHUA',
      country: 'China',
      years_es: '2019 - Actualidad',
      years_en: '2019 - Currently',
      years_pt: '2019 - Presente',
      image: require('../images/clubs/trayectoria/Shanghai-Shenhua-futbol-femenino.png'),
      alt: 'Shanghai-Shenhua-futbol-femenino'
    }, {
      id: 2,
      team: 'ATLÉTICO DE MADRID',
      country: 'España',
      years_es: '2017 - 2018',
      years_en: '2017 - 2018',
      years_pt: '2017 - 2018',
      image: require('../images/clubs/trayectoria/atletico-de-madrid-espana-futbol-femenino.png'),
      alt: 'atletico-de-madrid-espana-futbol-femenino'
    }, {
      id: 3,
      team: 'SYDNEY FC',
      country: 'Australia',
      years_es: '2016 - 2017',
      years_en: '2016 - 2017',
      years_pt: '2016 - 2017',
      image: require('../images/clubs/trayectoria/sydney-fc-australia-futbol-femenino.png'),
      alt: 'sydney-fc-australia-futbol-femenino'
    }, {
      id: 4,
      team: 'WASHINGTON SPIRIT',
      country: 'Estados Unidos',
      years_es: '2015 - 2018',
      years_en: '2015 - 2018',
      years_pt: '2015 - 2018',
      image: require('../images/clubs/trayectoria/washington-spirit-estados-unidos-futbol-femenino..png'),
      alt: 'washington-spirit-estados-unidos-futbol-femenino'
    }, {
      id: 5,
      team: 'PITEå IF',
      country: 'Suecia',
      years_es: '2013 - 2014',
      years_en: '2013 - 2014',
      years_pt: '2013 - 2014',
      image: require('../images/clubs/trayectoria/pitea-if-suecia-futbol-femenino.png'),
      alt: 'pitea-if-suecia-futbol-femenino'
    }, {
      id: 6,
      team: 'ROSSIYANKA',
      country: 'Rusia',
      years_es: '2012 - 2013',
      years_en: '2012 - 2013',
      years_pt: '2012 - 2013',
      image: require('../images/clubs/trayectoria/rossiyanka-rusia-futbol-femenino..png'),
      alt: 'rossiyanka-rusia-futbol-femenino'
    }, {
      id: 7,
      team: 'RIVERS ANGELS F.C.',
      country: 'Nigeria',
      years_es: '2011 - 2012',
      years_en: '2011 - 2012',
      years_pt: '2011 - 2012',
      image: require('../images/clubs/trayectoria/rivers-angels-nigeria-futbol-femenino.png'),
      alt: 'rivers-angels-nigeria-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'ordegafrancisca17',
    instagramId: '549426809',
    instagram: 'https://www.instagram.com/ordegafrancisca17/',
    twitter: 'https://twitter.com/ordegaf',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'EL VEE CLOTHING',
      url: 'https://www.instagram.com/p/B8oqFHTJdeD/',
      image: require('../images/players/francisca-ordega/last-campaigns/campana-francisca-ordega-el-vee-clothing.jpg'),
      alt: 'campana-francisca-ordega-el-vee-clothing'
    }, {
      id: 2,
      brand: 'MADE IN BENU MAGAZINE',
      url: 'https://www.instagram.com/p/B6k6_ael4w4/',
      image: require('../images/players/francisca-ordega/last-campaigns/francisca-ordega-made-in-benu-magazine-.jpg'),
      alt: 'francisca-ordega-made-in-benu-magazine'
    }, {
      id: 3,
      brand: 'VISA',
      url: '',
      image: require('../images/players/francisca-ordega/last-campaigns/campana-francisca-ordega-visa-copa90.jpg'),
      alt: 'campana-francisca-ordega-visa-copa90'
    }],
    press: [{
      id: 1,
      media: 'THE NEW YORK TIMES',
      url: 'https://www.nytimes.com/2019/06/20/style/world-cup-women-hair-gender.html',
      image: require('../images/players/francisca-ordega/press/francisca-ordega-prensa-the-new-york-times.jpg'),
      alt: 'francisca-ordega-prensa-the-new-york-times'
    }, {
      id: 2,
      media: 'BBC',
      url: 'https://www.bbc.com/sport/football/48297084',
      image: require('../images/players/francisca-ordega/press/francisca-ordega-prensa-the-bbc.jpg'),
      alt: 'francisca-ordega-prensa-the-bbc'
    }, {
      id: 3,
      media: 'GOAL',
      url: 'https://www.goal.com/en-gh/news/i-didnt-see-that-coming-francisca-ordega-surprised-by-caf/1sejbgwxlhup1su1gzbvs3m89',
      image: require('../images/players/francisca-ordega/press/francisca-ordega-prensa-goal.jpg'),
      alt: 'francisca-ordega-prensa-goal'
    }, {
      id: 4,
      media: 'DAILY POST',
      url: 'https://dailypost.ng/2019/06/17/nigeria-vs-france-can-beat-french-team-francisca-ordega/',
      image: require('../images/players/francisca-ordega/press/francisca-ordega-prensa-daily-post.jpg'),
      alt: 'francisca-ordega-prensa-daily-post'
    }, {
      id: 5,
      media: 'RFI',
      url: 'https://www.youtube.com/watch?v=gUl2yK6Q4yI',
      image: require('../images/players/francisca-ordega/press/francisca-ordega-prensa-rfi.jpg'),
      alt: 'francisca-ordega-prensa-rfi'
    }, {
      id: 6,
      media: 'GOAL',
      url: 'https://www.youtube.com/watch?v=gUl2yK6Q4yI',
      image: require('../images/players/francisca-ordega/press/francisca-ordega-prensa-goal-2.jpg'),
      alt: 'francisca-ordega-prensa-goal-2'
    }, {
      id: 7,
      media: 'PULSE',
      url: 'https://www.pulse.ng/lifestyle/food-travel/super-falcons-player-francisca-ordega-is-our-wcw-today/drjbpfc',
      image: require('../images/players/francisca-ordega/press/francisca-ordega-prensa-pulse.jpg'),
      alt: 'francisca-ordega-prensa-pulse'
    }, {
      id: 8,
      media: 'CHANNELS TELEVISION',
      url: 'https://www.youtube.com/watch?v=DHsdq2z6590',
      image: require('../images/players/francisca-ordega/press/francisca-ordega-prensa-channel-television.jpg'),
      alt: 'francisca-ordega-prensa-channel-television'
    }, {
      id: 9,
      media: 'THE GUARDIAN',
      url: 'https://guardian.ng/sport/nigeria-meets-cameroun-in-semi-finals/',
      image: require('../images/players/francisca-ordega/press/francisca-ordega-prensa-the-guardian.jpg'),
      alt: 'francisca-ordega-prensa-the-guardian'
    }, {
      id: 10,
      media: 'DAILY TRUST',
      url: 'https://www.dailytrust.com.ng/ordega-oshoala-make-caf-award-top-three-list.html',
      image: require('../images/players/francisca-ordega/press/francisca-ordega-prensa-channel-television-daily-trust.jpg'),
      alt: 'francisca-ordega-prensa-channel-television-daily-trust'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },/* {
    id: 18,
    title: '',
    metadescription: '',
    url: require('../images/players-profile-photo/test2.png'),
    alt: '',
    name: 'GABY GARCÍA',
    nameUrl: 'gaby-garcia',
    team: 'RC Deportivo',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : '',
    mainPosition_en: '',
    mainPosition_pt: ' ',
    birthDate: '',
    birthYear: '1900',
    town: '',
    country: '',
    nationality_es : 'Venezolana',
    nationality_en : '',
    nationality_pt: '',
    height: '',
    weight: '',
    signaturePhoto: '',
    signaturePhotoAlt: '',
    headerPhotos: [],
    mobileHeaderPhoto: '',
    mobileHeaderPhotoAlt: '',
    nationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: '',
      team_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    internationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: '',
      team_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    individualAwards: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    skilledLeg_es: '',
    skilledLeg_en: '',
    skilledLeg_pt: '',
    skilledLegNum: 35,
    technicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    tacticalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    psychologicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    physicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 0,
      team: '',
      country: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: '',
      image: ''
    }],
    instagramAccessToken: '',
    instagramUsername: '',
    instagramId: '',
    instagram: '',
    twitter: '',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 0,
      brand: '',
      url: '',
      image: '',
    }],
    press: [{
      id: 0,
      media: '',
      url: '',
      image: '',
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 19,
    title: '',
    metadescription: '',
    url: require('../images/players-profile-photo/jovana-stojanovic-red-star-belgrade.png'),
    alt: '',
    name: 'JOVANA STOJANOVIĆ',
    nameUrl: 'jovana-stojanovic',
    team: 'Red Star Belgrade',
    league_es: 'Superliga Srbije (Serbia)',
    league_en: 'Superliga Srbije (Serbia)',
    league_pt: 'Superliga Srbije (Sérvia)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : '',
    mainPosition_en: '',
    mainPosition_pt: ' ',
    birthDate: '',
    birthYear: '1900',
    town: '',
    country: '',
    nationality_es : 'Serbia',
    nationality_en : '',
    nationality_pt: '',
    height: '',
    weight: '',
    signaturePhoto: '',
    signaturePhotoAlt: '',
    headerPhotos: [],
    mobileHeaderPhoto: '',
    mobileHeaderPhotoAlt: '',
    nationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: '',
      team_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    internationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: '',
      team_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    individualAwards: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    skilledLeg_es: '',
    skilledLeg_en: '',
    skilledLeg_pt: '',
    skilledLegNum: 35,
    technicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    tacticalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    psychologicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    physicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 0,
      team: '',
      country: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: '',
      image: ''
    }],
    instagramAccessToken: '',
    instagramUsername: '',
    instagramId: '',
    instagram: '',
    twitter: '',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 0,
      brand: '',
      url: '',
      image: '',
    }],
    press: [{
      id: 0,
      media: '',
      url: '',
      image: '',
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },*/  {
    id: 20,
    title: 'UNIK TEAM | Kelly Chiavaro | Jugadora de fútbol femenino',
    metadescription: 'Kelly Chiavaro, futbolista canadiense, actualmente juega como portera en el Emek Hefer WFC de la Ligat Nashim y en la Selección Canadiense.',
    url: require('../images/players-profile-photo/kelly-chiavaro-emek-hefer-israel.png'),
    alt: 'kelly-chiavaro-emek-hefer-israel',
    name: 'KELLY CHIAVARO',
    nameUrl: 'kelly-chiavaro',
    team: 'Emek Hefer WFC',
    league_es: 'Ligat Nashim (Israel)',
    league_en: 'Ligat Nashim (Israel)',
    league_pt: 'Ligat Nashim (Israel)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : 'PORTERA',
    mainPosition_en: 'GOALKEEPER',
    mainPosition_pt: 'GOLEIRA',
    birthDate: '03 - 07 - 1996',
    birthYear: '1996',
    town: 'Saint Eustache',
    country: 'Canadá',
    nationality_es : 'Canadiense',
    nationality_en : 'Canadian',
    nationality_pt: 'Canadense',
    height: '1,74',
    weight: '68',
    signaturePhoto: require('../images/players/kelly-chiavaro/jugadora-kelly-chiavaro-unikteam_.png'),
    signaturePhotoAlt: 'jugadora-kelly-chiavaro-unikteam',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/kelly-chiavaro/header/kelly-chiavaro-colgate-university-raiders.jpg'),
      alt: 'kelly-chiavaro-colgate-university-raiders'
    }, {
      id: 2,
      url: require('../images/players/kelly-chiavaro/header/kelly-chiavaro-colgate-university-raiders-femenino.jpg.jpg'),
      alt: 'kelly-chiavaro-colgate-university-raiders-femenino'
    }, {
      id: 3,
      url: require('../images/players/kelly-chiavaro/header/kelly-chiavaro-colgate-university-raiders-futbol-femenino.jpg.jpg'),
      alt: 'kelly-chiavaro-colgate-university-raiders-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/kelly-chiavaro/header-mobile/kelly-chiavaro-colgate-university-raiders.jpg'),
    mobileHeaderPhotoAlt: 'kelly-chiavaro-colgate-university-raiders',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Copa Canadiense',
      nameFirstRow_en: 'Canadian Cup',
      nameFirstRow_pt: 'Copa Canadense',
      nameSecondRow_es: '"Quebec-Ontario Cup"',
      nameSecondRow_en: '"Quebec-Ontario Cup"',
      nameSecondRow_pt: '"Quebec-Ontario Cup"',
      team_es: '',
      team_en: '',
      team_pt: '',
      years_es: '2010 , 2012 , 2014 y 2016',
      years_en: '2010, 2012, 2014 and 2016',
      years_pt: '2010, 2012, 2014 e 2016'
    }, {
      id: 2,
      nameFirstRow_es: '"Las Vegas Mayors Cup"',
      nameFirstRow_en: '"Las Vegas Mayors Cup"',
      nameFirstRow_pt: '"Las Vegas Mayors Cup" ',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Canadian National Training Center',
      team_en: 'Canadian National Training Center',
      team_pt: 'Canadian National Training Center',
      years_es: '2015',
      years_en: '2015',
      years_pt: '2015'
    }, {
      id: 3,
      nameFirstRow_es: 'Campeonato Nacional de Clubes',
      nameFirstRow_en: 'Club Canadian National Championship',
      nameFirstRow_pt: 'Campeonato Nacional de Clubes',
      nameSecondRow_es: '"Club Canadian National Championship"',
      nameSecondRow_en: '"Club Canadian National Championship"',
      nameSecondRow_pt: '"Club Canadian National Championship"',
      team_es: '',
      team_en: '',
      team_pt: '',
      years_es: '2011 , 2013 y 2015',
      years_en: '2011, 2013 and 2015',
      years_pt: '2011, 2013 e 2015'
    }, {
      id: 4,
      nameFirstRow_es: '"All Star Canadian National Championships"',
      nameFirstRow_en: '"All Star Canadian National Championship" ',
      nameFirstRow_pt: '"All Star Canadian National Championships"',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Quebec Provincial Team',
      team_en: 'Quebec Provincial Team',
      team_pt: 'Quebec Provincial Team',
      years_es: '2010 , 2011 y 2012',
      years_en: '2010, 2011 and 2012',
      years_pt: '2010, 2011 e 2012'
    }],
    internationalTournament: [],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Portera del Año',
      nameFirstRow_en: 'Goalkeeper of the Year',
      nameFirstRow_pt: 'Goleira do Ano',
      nameSecondRow_es: 'División I de la NCAA "Patriot League"',
      nameSecondRow_en: 'NCAA Division I League "Patriot League"',
      nameSecondRow_pt: 'Divisão I de la NCAA "Patriot League"',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    }, {
      id: 2,
      nameFirstRow_es: 'MVP',
      nameFirstRow_en: 'MVP',
      nameFirstRow_pt: 'MVP',
      nameSecondRow_es: 'Las Vegas Mayors Cup Showcase',
      nameSecondRow_en: 'Las Vegas Mayors Cup Showcase',
      nameSecondRow_pt: 'Las Vegas Mayors Cup Showcase',
      years_es: '2015',
      years_en: '2015',
      years_pt: '2015'
    }, {
      id: 3,
      nameFirstRow_es: 'Guantes de Oro',
      nameFirstRow_en: 'Golden Glove',
      nameFirstRow_pt: 'Luva de ouro',
      nameSecondRow_es: 'Primera División de Quebec "Quebec Soccer League"',
      nameSecondRow_en: 'First Division of Quebec "Quebec Soccer League"',
      nameSecondRow_pt: 'Primeira Divisão de Quebec "Quebec Soccer League"',
      years_es: '2014 y 2016',
      years_en: '2014 and 2016',
      years_pt: '2014 e 2016',
    }, {
      id: 4,
      nameFirstRow_es: '9 veces Portera de la Semana',
      nameFirstRow_en: '9 times Goalkeeper of the Week',
      nameFirstRow_pt: '9 vezes Goleira da Semana',
      nameSecondRow_es: 'División I de la NCAA "Patriot League"',
      nameSecondRow_en: 'NCAA Division I League "Patriot League"',
      nameSecondRow_pt: 'Divisão I da NCAA "Patriot League"',
      years_es: '',
      years_en: '',
      years_pt: ''
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Aerial ball control',
      characteristic_pt: 'Jogo aéreo',
      characteristic_es: 'Juego Aéreo',
      num: 19
    }, {
      id: 2,
      characteristic_en: 'Blocking',
      characteristic_pt: 'Agarrar',
      characteristic_es: 'Blocaje',
      num: 4
    }, {
      id: 3,
      characteristic_en: 'Punt',
      characteristic_pt: 'Espalmar',
      characteristic_es: 'Despeje',
      num: 12
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Space control',
      characteristic_pt: 'Colocação no campo',
      characteristic_es: 'Colocación',
      num: 6
    }, {
      id: 2,
      characteristic_en: 'Communication skills',
      characteristic_pt: 'Comunicação',
      characteristic_es: 'Comunicación',
      num: 7
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Strong personality',
      characteristic_pt: 'Personalidade forte',
      characteristic_es: 'Carácter',
      num: 5
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Agility/Flexibility',
      characteristic_pt: 'Agilidade/Flexibilidade',
      characteristic_es: 'Agilidad/Flexibilidad',
      num: 15
    }, {
      id: 2,
      characteristic_en: 'Reflexes',
      characteristic_pt: 'Reflexos',
      characteristic_es: 'Reflejos',
      num: 29
    }],
    positions: [{
      id: 0,
      selected: true,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'EMEK HEFER WFC',
      country: 'ISRAEL',
      years_es: '2020 - Actualidad',
      years_en: '2020 - Currently',
      years_pt: '2020 - Presente',
      image: require('../images/clubs/trayectoria/Emek-Hefer-WFC.png'),
      alt: 'Emek-Hefer-WFC-futbol-femenino'
    }, {
      id: 2,
      team: 'COLGATE RAIDERS',
      country: 'USA',
      years_es: '2016 - 2019',
      years_en: '2016 - 2019',
      years_pt: '2016 - 2019',
      image: require('../images/clubs/trayectoria/colgate-raiders-estados-unidos-futbol-femenino..png'),
      alt: 'colgate-raiders-estados-unidos-futbol-femenino'
    }, {
      id: 3,
      team: 'CANADIAN NATIONAL TRAINING CENTER',
      country: 'Canadá',
      years_es: '2010 - 2015',
      years_en: '2010 - 2015',
      years_pt: '2010 - 2015',
      image: require('../images/clubs/trayectoria/canadian-national-training-center-canada-futbol-femenino.png'),
      alt: 'canadian-national-training-center-canada-futbol-femenino'
    }, {
      id: 4,
      team: 'QUEBEC PROVINCIAL TEAM',
      country: 'Canadá',
      years_es: '2010 - 2012',
      years_en: '2010 - 2012',
      years_pt: '2010 - 2012',
      image: require('../images/clubs/trayectoria/quebec-provincial-team-canada-futbol-femenino.png'),
      alt: 'quebec-provincial-team-canada-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'kellyychiavaro',
    instagramId: '27021089',
    instagram: 'https://www.instagram.com/kellyychiavaro/',
    twitter: 'https://twitter.com/Kellychiavaro',
    facebook: 'https://www.facebook.com/kelly.chiavaro',
    tikTok: '',
    lastsCampaigns: [],
    press: [{
      id: 1,
      media: 'PATRIOT LEAGUE',
      url: 'https://patriotleague.org/news/2018/10/29/patriot-league-announces-2018-womens-soccer-postseason-awards.aspx?path=wsoc',
      image: require('../images/players/kelly-chiavaro/press/kelly-chiavaro-press-patriot-league.jpg'),
      alt: 'kelly-chiavaro-press-patriot-league'
    }, {
      id: 2,
      media: 'COLGATE RAIDERS',
      url: 'https://gocolgateraiders.com/sports/womens-soccer/roster/kelly-chiavaro/10262',
      image: require('../images/players/kelly-chiavaro/press/kelly-chiavaro-press-colgate-raiders.jpg'),
      alt: 'kelly-chiavaro-press-colgate-raiders'
    }, {
      id: 3,
      media: 'PATRIOT LEAGUE',
      url: 'https://patriotleague.org/news/2019/1/30/patriot-league-announces-197-student-athletes-named-to-2018-womens-soccer-academic-honor-roll.aspx',
      image: require('../images/players/kelly-chiavaro/press/kelly-chiavaro-press-patriot-league-2.jpg'),
      alt: 'kelly-chiavaro-press-patriot-league-2'
    }, {
      id: 4,
      media: 'COLGATE RAIDERS',
      url: 'https://gocolgateraiders.com/news/2018/8/27/womens-soccer-chiavaro-tabbed-goalkeeper-of-the-week.aspx?fbclid=IwAR06xavwJDtXebpPOlWF8-JAxE3uQJ17q6WP2VJkHpnEK5fEIiMedkE4Csk',
      image: require('../images/players/kelly-chiavaro/press/kelly-chiavaro-press-colgate-raiders-2.jpg'),
      alt: 'kelly-chiavaro-press-colgate-raiders-2'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  } /*, {
    id: 21,
    title: '',
    metadescription: '',
    url: require('../images/players-profile-photo/test3.jpg'),
    alt: '',
    name: 'KIKA MORENO',
    nameUrl: 'kika-moreno',
    team: 'RC Deportivo',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : '',
    mainPosition_en: '',
    mainPosition_pt: ' ',
    birthDate: '',
    birthYear: '1900',
    town: '',
    country: '',
    nationality_es : 'Venezolana',
    nationality_en : '',
    nationality_pt: '',
    height: '',
    weight: '',
    signaturePhoto: '',
    signaturePhotoAlt: '',
    headerPhotos: [],
    mobileHeaderPhoto: '',
    mobileHeaderPhotoAlt: '',
    nationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: '',
      team_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    internationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: '',
      team_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    individualAwards: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    skilledLeg_es: '',
    skilledLeg_en: '',
    skilledLeg_pt: '',
    skilledLegNum: 35,
    technicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    tacticalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    psychologicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    physicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 0,
      team: '',
      country: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: '',
      image: ''
    }],
    instagramAccessToken: '',
    instagramUsername: '',
    instagramId: '',
    instagram: '',
    twitter: '',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 0,
      brand: '',
      url: '',
      image: '',
    }],
    press: [{
      id: 0,
      media: '',
      url: '',
      image: '',
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },*/ /* {
    id: 22,
    title: '',
    metadescription: '',
    url: require('../images/players-profile-photo/lady-andrade-ac-milan.png'),
    alt: '',
    name: 'LADY ANDRADE',
    nameUrl: 'lady-andrade',
    team: 'AC Milan',
    league_es: 'Serie A (Italia)',
    league_en: 'Serie A (Italy)',
    league_pt: 'Serie A (Itália)',
    selection_es: 'SEL. COLOMBIANA',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : 'DELANTERA',
    mainPosition_en: '',
    mainPosition_pt: ' ',
    birthDate: '10 - 01 - 1994',
    birthYear: '1994',
    town: 'Bogotá',
    country: 'Colombia',
    nationality_es : 'Colombiana',
    nationality_en : '',
    nationality_pt: '',
    height: '1,69',
    weight: '62',
    signaturePhoto: require('../images/players/lady-andrade/jugadora-lady-andrade-unikteam.png'),
    signaturePhotoAlt: '',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/lady-andrade/header/lady-andrade-ac-milan-futbol-femenino.jpg'),
      alt: ''
    }, {
      id: 2,
      url: require('../images/players/lady-andrade/header/lady-andrade-seleccion-colombia-futbol-femenino.jpg'),
      alt: ''
    }],
    mobileHeaderPhoto: require('../images/home/mobile/lady-andrade-seleccion-colobiana.jpg'),
    mobileHeaderPhotoAlt: '',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera División Colombiana',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '"Campeonato Colombiano de Futebol Feminino"',
      nameSecondRow_en: '',
      team_es: 'Santa Fé',
      team_en: 'Santa Fé',
      nameSecondRow_pt: '',
      years_es: '2017',
      years_en: '2017'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Medalla de oro en los ',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: 'Juegos Panamericanos',
      nameSecondRow_en: '',
      team_es: 'Selección Colombiana',
      team_en: 'Colombian National Team',
      nameSecondRow_pt: '',
      years_es: 'Perú 2019',
      years_en: 'Peru 2019'
    }, {
      id: 2,
      nameFirstRow_es: 'Medalla de plata en los',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: 'Juegos Centroamericanos y del Caribe',
      nameSecondRow_en: '',
      team_es: 'Selección Colombiana',
      team_en: 'Colombian National Team',
      nameSecondRow_pt: '',
      years_es: 'México 2014',
      years_en: 'Mexico 2014'
    }, {
      id: 3,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: 'Copa América',
      nameSecondRow_en: '',
      team_es: 'Selección Colombiana',
      team_es: 'Colombian National Team',
      nameSecondRow_pt: '',
      years_es: 'Ecuador 2014',
      years_en: 'Ecuador 2014'
    }, {
      id: 4,
      nameFirstRow_es: 'Copa Prelibertadores',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: 'Club Deportivo Formas Íntimas',
      team_en: 'Club Deportivo Formas Íntimas',
      nameSecondRow_pt: '',
      years_es: 'Colombia 2012',
      years_en: 'Colombia 2012'
    }, {
      id: 5,
      nameFirstRow_es: 'Subcampeona del',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: 'Campeonato Sudamericano Sub-20',
      nameSecondRow_en: '',
      team_es: 'Selección Colombiana',
      team_en: 'Colombian National Team',
      nameSecondRow_pt: '',
      years_es: 'Colombia 2010',
      years_en: 'Colombia 2010'
    }, {
      id: 6,
      nameFirstRow_es: 'Sudamericano Sub-17',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: 'Selección Colombiana',
      team_en: 'Colombian National Team',
      nameSecondRow_pt: '',
      years_es: 'Chile 2008',
      years_en: 'Chile 2008'
    }],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'MVP del partido Colombia vs Francia',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: 'Mundial',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      years_es: 'Canadá 2015',
      years_en: 'Canada 2015'
    }, {
      id: 2,
      nameFirstRow_es: 'Mejor Jugadora',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: 'Copa América de Fútbol Sala',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      years_es: '2015',
      years_en: '2015'
    }, {
      id: 3,
      nameFirstRow_es: 'Nominada al Balón de Oro Adidas',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: 'Mundial Sub-20',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      years_es: 'Alemania 2010',
      years_en: 'Germany 2010'
    }],
    skilledLeg_es: 'Zurda',
    skilledLeg_en: '',
    skilledLeg_pt: '',
    skilledLegNum: 35,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: 'Regate',
      num: 30
    }, {
      id: 2,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: 'Gol',
      num: 18
    }, {
      id: 3,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: 'Asistencias',
      num: 2
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }, {
      id: 2,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: 'Visión de juego',
      num: 34
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: '',
      characteristic_pt: 'Personalidade forte',
      characteristic_es: 'Carácter',
      num: 5
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: '',
      characteristic_pt: 'Potência',
      characteristic_es: 'Potencia',
      num: 27
    }, {
      id: 2,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: 'Velocidad',
      num: 33
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: true,
      position: 'INTi'
    },{
      id: 9,
      selected: true,
      position: 'INTd'
    },{
      id: 10,
      selected: true,
      position: 'MP'
    },{
      id: 11,
      selected: true,
      position: 'EXTi'
    },{
      id: 12,
      selected: true,
      position: 'EXTd'
    },{
      id: 13,
      selected: true,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'AC MILAN',
      country: 'Italia',
      nameSecondRow_pt: '',
      years_es: '2019 - Actualidad',
      years_en: '2019 - Currently',
      image: require('../images/clubs/trayectoria/ac-milan-italia-futbol-femenino.png')
    }, {
      id: 2,
      team: 'CLUB ATLÉTICO NACIONAL',
      country: 'Colombia',
      nameSecondRow_pt: '',
      years_es: '2018',
      years_en: '2018',
      image: require('../images/clubs/trayectoria/club-atletico-nacional-colombia-futbol-femenino.png')
    }, {
      id: 3,
      team: 'SANTA FÉ',
      country: 'Colombia',
      nameSecondRow_pt: '',
      years_es: '2017',
      years_en: '2017',
      image: require('../images/clubs/trayectoria/santa-fe-colombia-futbol-femenino.png')
    }, {
      id: 4,
      team: '1207 ANTALYASPOR',
      country: 'Turquía',
      nameSecondRow_pt: '',
      years_es: '2016',
      years_en: '2016',
      image: require('../images/clubs/trayectoria/1207-antalyasport-turquia--futbol-femenino.png')
    }, {
      id: 5,
      team: 'WESTERN NEW YORK FLASH',
      country: 'Estados Unidos',
      nameSecondRow_pt: '',
      years_es: '2015 - 2016',
      years_en: '2015 - 2016',
      image: require('../images/clubs/trayectoria/western-new-york-flash-estados-unidos-futbol-femenino..png')
    }, {
      id: 6,
      team: 'PK-35 VANTAA',
      country: 'Finlandia',
      nameSecondRow_pt: '',
      years_es: '2013',
      years_en: '2013',
      image: require('../images/clubs/trayectoria/pk-35-vantaa-finlandia-futbol-femenino..png')
    }, {
      id: 7,
      team: 'SPORTING HUELVA',
      country: 'España',
      nameSecondRow_pt: '',
      years_es: '2013',
      years_en: '2013',
      image: require('../images/clubs/trayectoria/sporting-club-huelva-espana-futbol-femenino.png')
    }],
    instagramAccessToken: '',
    instagramUsername: 'ladyandrade16',
    instagramId: '303064028',
    instagram: 'https://www.instagram.com/ladyandrade16/',
    twitter: 'https://twitter.com/ladyandrade16?lang=en',
    facebook: 'https://www.facebook.com/ladyandrade16/',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'NIKE',
      url: 'https://www.instagram.com/p/BaSbOn0ABvD/',
      image: require('../images/players/lady-andrade/last-campaigns/campana-lady-andrade-nike-colombia.jpg')
    }, {
      id: 2,
      brand: 'AMWAY',
      url: 'https://www.instagram.com/p/BEtxkqjPvWD/',
      image: require('../images/players/lady-andrade/last-campaigns/campana-lady-andrade-amway-colombia.jpg')
    }],
    press: [{
      id: 1,
      media: 'DIARIO AS',
      url: 'https://colombia.as.com/colombia/2019/10/11/videos/1570816494_609228.html',
      image: require('../images/players/lady-andrade/press/lady-andrade-prensa-diario-as.jpg')
    }, {
      id: 2,
      media: 'DIARIO AS',
      url: 'https://colombia.as.com/colombia/2019/07/17/futbol/1563328593_990881.html',
      image: require('../images/players/lady-andrade/press/lady-andrade-prensa-diario-as-2.jpg')
    }, {
      id: 3,
      media: 'DIARIO AS',
      url: 'https://colombia.as.com/colombia/2019/10/13/futbol/1570985407_522957.html',
      image: require('../images/players/lady-andrade/press/lady-andrade-prensa-diario-as-3.jpg')
    }, {
      id: 4,
      media: 'CARACOL RADIO',
      url: 'https://caracol.com.co/radio/2019/12/11/deportes/1576079848_422474.html',
      image: require('../images/players/lady-andrade/press/lady-andrade-prensa-caracol-radio.jpg')
    }, {
      id: 5,
      media: 'EL ESPECTADOR',
      url: 'https://www.elespectador.com/deportes/futbol-internacional/lady-andrade-la-futbolista-colombiana-que-llego-al-milan-de-italia-articulo-871329',
      image: require('../images/players/lady-andrade/press/lady-andrade-prensa-el-espectador.jpg')
    }, {
      id: 6,
      media: 'RCN RADIO',
      url: 'https://www.rcnradio.com/deportes/lady-andrade-marco-en-su-debut-con-el-milan-femenino',
      image: require('../images/players/lady-andrade/press/lady-andrade-prensa-rcn-radio.jpg')
    }, {
      id: 7,
      media: 'CANAL RCN',
      url: 'https://deportes.canalrcn.com/futbol/lady-andrade-anota-su-primer-gol-con-el-ac-milan-en-el-derbi-femenino-contra-inter-116805',
      image: require('../images/players/lady-andrade/press/lady-andrade-prensa-canal-rcn.jpg')
    }, {
      id: 8,
      media: 'EL ESPECTADOR',
      url: 'https://www.elespectador.com/deportes/futbol-colombiano/lady-andrade-quiere-ser-la-primera-bicampeona-en-colombia-articulo-791785',
      image: require('../images/players/lady-andrade/press/lady-andrade-prensa-el-espectador-2.jpg')
    }, {
      id: 9,
      media: 'DIARIO AS',
      url: 'https://colombia.as.com/colombia/2019/09/10/futbol/1568142855_502594.html',
      image: require('../images/players/lady-andrade/press/lady-andrade-prensa-diario-as-4.jpg')
    }, {
      id: 10,
      media: 'FEMINA FÚTBOL',
      url: 'https://feminafutbol.com/noticias/lady-andrade-es-nueva-jugadora-del-ac-milan-de-italia-medios-italianos-18293/',
      image: require('../images/players/lady-andrade/press/lady-andrade-prensa-femina-futbol.jpg')
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }*/, {
    id: 23,
    title: 'UNIK TEAM | Leila Ouahabi | Jugadora de fútbol femenino',
    metadescription: 'Leila Ouahabi, futbolista española, actualmente juega como defensa en el FC Barcelona de la Primera Iberdrola y en la Selección Española.',
    url: require('../images/players-profile-photo/leila-ouahabi-fc-barcelona.png'),
    alt: 'leila-ouahabi-fc-barcelona',
    name: 'LEILA OUAHABI',
    nameUrl: 'leila-ouahabi',
    team: 'FC Barcelona',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: 'SEL. ESPAÑOLA',
    selection_en: 'SPANISH TEAM',
    selection_pt: 'SEL. ESPANHOLA',
    mainPosition_es : 'DEFENSA',
    mainPosition_en: 'DEFENDER',
    mainPosition_pt: 'DEFESA',
    birthDate: '22 - 03 - 1993',
    birthYear: '1993',
    town: 'Mataró',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,72',
    weight: '59',
    signaturePhoto: require('../images/players/leila-ouahabi/jugadora-leila-ouahabi-unik-team.png'),
    signaturePhotoAlt: 'jugadora-leila-ouahabi-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/leila-ouahabi/header/Leila-Ouahabi-FC-Barcelona-femenino-.jpg'),
      alt: 'Leila-Ouahabi-FC-Barcelona-femenino-'
    }, {
      id: 2,
      url: require('../images/players/leila-ouahabi/header/Leila-Ouahabi-FC-Barcelona-femenino.jpg'),
      alt: 'Leila-Ouahabi-FC-Barcelona-femenino'
    }, {
      id: 3,
      url: require('../images/players/leila-ouahabi/header/Leila-Ouahabi-Seleccion-Espanola.jpg'),
      alt: 'Leila-Ouahabi-Seleccion-Espanola'
    }],
    mobileHeaderPhoto: require('../images/home/mobile/leila-ouahabi-fc-barcelona.jpg'),
    mobileHeaderPhotoAlt: 'leila-ouahabi-fc-barcelona',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2019 - 2020',
      years_en: '2019 - 2020',
      years_pt: '2019 - 2020'
    },{
      id: 2,
      nameFirstRow_es: 'Supercopa de España',
      nameFirstRow_en: 'Spanish SuperCup',
      nameFirstRow_pt: 'Supercopa da Espanha',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2019 - 2020',
      years_en: '2019 - 2020',
      years_pt: '2019 - 2020'
    },{
      id: 3,
      nameFirstRow_es: 'Copa Catalunya',
      nameFirstRow_en: 'Catalonia Cup',
      nameFirstRow_pt: 'Copa Catalunha',
      nameSecondRow_es: 'Femenina',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2018 y 2019',
      years_en: '2018 and 2019',
      years_pt: '2018 e 2019',
    },{
      id: 4,
      nameFirstRow_es: 'Copa de España',
      nameFirstRow_en: 'Spanish Cup',
      nameFirstRow_pt: 'Copa da Espanha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2017 y 2018',
      years_en: '2017 and 2018',
      years_pt: '2017 e 2018',
    },{
      id: 5,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2012 - 2013',
      years_en: '2012 - 2013',
      years_pt: '2012 - 2013'
    },{
      id: 6,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2011 - 2012',
      years_en: '2011 - 2012',
      years_pt: '2011 - 2012'
    },{
      id: 7,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2011 - 2012',
      years_en: '2011 - 2012',
      years_pt: '2011 - 2012'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'Champions League',
      nameSecondRow_en: 'Champions League',
      nameSecondRow_pt: 'Champions League',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: 'Hungría 2018 - 2019',
      years_en: '2018 - 2019',
      years_pt: '2018 - 2019',
    },{
      id: 2,
      nameFirstRow_es: 'Copa de Algarve',
      nameFirstRow_en: 'Algarve Cup',
      nameFirstRow_pt: 'Copa do Algarve',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Portugal 2017',
      years_en: 'Portugal 2017',
      years_pt: 'Portugal 2017'
    },{
      id: 3,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'Eurocopa Sub-19',
      nameSecondRow_en: 'UEFA Euro U-19',
      nameSecondRow_pt: 'Eurocopa Sub-19',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: '2011',
      years_en: 'Turkey 2012',
      years_pt: ' 2012',
    }],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Once de Oro',
      nameFirstRow_en: 'Best 11',
      nameFirstRow_pt: 'Onze de Ouro',
      nameSecondRow_es: 'Fútbol Draft',
      nameSecondRow_en: 'Fútbol Draft',
      nameSecondRow_pt: 'Fútbol Draft',
      years_es: '2014',
      years_en: '2014',
      years_pt: '2014'
    }],
    skilledLeg_es: 'Zurda',
    skilledLeg_en: 'Left-footed',
    skilledLeg_pt: 'Canhota',
    skilledLegNum: 35,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Clearing the ball',
      characteristic_pt: 'Saída de bola',
      characteristic_es: 'Salida de balón',
      num: 32
    }, {
      id: 2,
      characteristic_en: 'Medium range passes',
      characteristic_pt: 'Passe médio',
      characteristic_es: 'Pase medio',
      num: 26
    }, {
      id: 3,
      characteristic_en: 'Ball control',
      characteristic_pt: 'Controle de bola',
      characteristic_es: 'Control de balón',
      num: 9
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Defensive player',
      characteristic_pt: 'Jogadora defensiva',
      characteristic_es: 'Criterio defensivo',
      num: 11
    }, {
      id: 2,
      characteristic_en: 'Skilled passer',
      characteristic_pt: 'Ligação',
      characteristic_es: 'Asociación',
      num: 3
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura del partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Speed',
      characteristic_pt: 'Velocidade',
      characteristic_es: 'Velocidad',
      num: 33
    }, {
      id: 2,
      characteristic_en: 'Resistance',
      characteristic_pt: 'Resistência',
      characteristic_es: 'Resistencia',
      num: 31
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: true,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: true,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: true,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [
      {
        id: 1,
        team: 'FC BARCELONA',
        country: 'España',
        years_es: '2016 - Actualidad',
        years_en: '2016 - Currently',
        years_pt: '2016 - Presente',
        image: require('../images/clubs/trayectoria/fc-barcelona-espana-futbol-femenino.png'),
        alt: 'fc-barcelona-espana-futbol-femenino'
      },{
        id: 2,
        team: 'VALENCIA CF',
        country: 'España',
        years_es: '2013 - 2016',
        years_en: '2013 - 2016',
        years_pt: '2013 - 2016',
        image: require('../images/clubs/trayectoria/valencia-cf-espana-futbol-femenino.png'),
        alt: 'valencia-cf-espana-futbol-femenino'
      },{
        id: 3,
        team: 'FC BARCELONA',
        country: 'España',
        years_es: '2011 - 2013',
        years_en: '2011 - 2013',
        years_pt: '2011 - 2013',
        image: require('../images/clubs/trayectoria/fc-barcelona-espana-futbol-femenino.png'),
        alt: 'fc-barcelona-espana-futbol-femenino'
      }],
    instagramAccessToken: '',
    instagramUsername: 'leilaouahabi',
    instagramId: '271684277',
    instagram: 'https://www.instagram.com/leilaouahabi',
    twitter: 'https://twitter.com/LeilaOuahabi',
    facebook: 'https://www.facebook.com/LeilaOuahabi15',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'NIKE',
      url: 'https://www.instagram.com/p/B5vZdZuqWVW/',
      image: require('../images/players/leila-ouahabi/last-campaigns/Campana-Leila-Ouahabi-Nike.jpg'),
      alt: 'Campana-Leila-Ouahabi-Nike'
    }, {
      id: 2,
      brand: 'MARGARI SOUND',
      url: 'https://www.instagram.com/p/B-et7YRqSTd/',
      image: require('../images/players/leila-ouahabi/last-campaigns/Campana-Leila-Ouahabi-Magari-Sound.jpg'),
      alt: 'Campana-Leila-Ouahabi-Magari-Sound'
    }, {
      id: 3,
      brand: 'FILMIN',
      url: null,
      image: require('../images/players/leila-ouahabi/last-campaigns/Campana-Leila-Ouahabi-Filmin.jpg'),
      alt: 'Campana-Leila-Ouahabi-Filmin'
    }, {
      id: 4,
      brand: 'AIRE BARCELONA',
      url: null,
      image: require('../images/players/leila-ouahabi/last-campaigns/Activacion-Leila-Ouahabi-Aire-Ancient-Baths.jpg'),
      alt: 'Activacion-Leila-Ouahabi-Aire-Ancient-Baths'
    }, {
      id: 5,
      brand: 'JUNTAS VENCEREMOS',
      url: 'https://www.instagram.com/p/B-k4BfhKMEv/',
      image: require('../images/players/leila-ouahabi/last-campaigns/Campana-Leila-Ouahabi-Juntas-Venceremos.jpg'),
      alt: 'Campana-Leila-Ouahabi-Juntas-Venceremos'
    }],
    press: [{
      id: 1,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/femenino/20200401/48255390092/asi-entrena-leila-ouahabi-en-su-confinamiento-por-el-covid-19.html',
      image: require('../images/players/leila-ouahabi/press/Leila-Ouahabi-prensa-mundo-deportivo.jpg'),
      alt: 'Leila-Ouahabi-prensa-mundo-deportivo'
    }, {
      id: 2,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/seleccion/2019/06/14/5d0298cf22601ddc0d8b45b9.html',
      image: require('../images/players/leila-ouahabi/press/Leila-Ouahabi-prensa-marca.jpg'),
      alt: 'Leila-Ouahabi-prensa-marca'
    }, {
      id: 3,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2019/06/16/videos/1560700872_434552.html',
      image: require('../images/players/leila-ouahabi/press/Leila-Ouahabi-prensa-diario-AS.jpg'),
      alt: 'Leila-Ouahabi-prensa-diario-AS'
    }, {
      id: 4,
      media: 'VAVEL',
      url: 'https://www.vavel.com/es/futbol/2019/10/14/fc-barcelona/999902-leila-ouahabi-un-filondesde-la-banda.html',
      image: require('../images/players/leila-ouahabi/press/Leila-Ouahabi-prensa-vavel.jpg'),
      alt: 'Leila-Ouahabi-prensa-vavel'
    }, {
      id: 5,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/fc-barcelona/20190910/47292976283/leila-ouahabi-este-ano-queremos-la-liga.html',
      image: require('../images/players/leila-ouahabi/press/Leila-Ouahabi-prensa-mundo-deportivo-2.jpg'),
      alt: 'Leila-Ouahabi-prensa-mundo-deportivo-2'
    }, {
      id: 6,
      media: 'EL PERIÓDICO',
      url: 'https://www.elperiodico.com/es/barca/20190319/leila-ouahabi-estamos-abriendo-ojos-mucha-gente-7363472',
      image: require('../images/players/leila-ouahabi/press/Leila-Ouahabi-prensa-el-periodico.jpg'),
      alt: 'Leila-Ouahabi-prensa-el-periodico'
    }, {
      id: 7,
      media: 'EL DESMARQUE',
      url: 'https://eldesmarque.com/mundial/femenino-francia-2019/noticias-2019/espana-2019/1240688-leila',
      image: require('../images/players/leila-ouahabi/press/Leila-Ouahabi-prensa-el-desmarque.jpg'),
      alt: 'Leila-Ouahabi-prensa-el-desmarque'
    }, {
      id: 8,
      media: 'EUROPAPRESS',
      url: 'https://www.europapress.es/deportes/futbol-00162/noticia-leila-ouahabi-si-seguimos-dinamica-juego-goles-llegaran-20190618185149.html',
      image: require('../images/players/leila-ouahabi/press/Leila-Ouahabi-prensa-europapress.jpg'),
      alt: 'Leila-Ouahabi-prensa-europapress'
    }, {
      id: 9,
      media: 'EUROSPORT',
      url: 'https://www.eurosport.es/futbol/leila-ouahabi-el-equipo-ha-competido-bien-estamos-satisfechas_vid1210743/video.shtml',
      image: require('../images/players/leila-ouahabi/press/Leila-Ouahabi-prensa-eurosport.jpg'),
      alt: 'Leila-Ouahabi-prensa-eurosport'
    }, {
      id: 10,
      media: 'BETEVÉ',
      url: 'https://www.youtube.com/watch?v=7wUS2xjOt1c',
      image: require('../images/players/leila-ouahabi/press/Leila-Ouahabi-prensa-beteve.jpg'),
      alt: 'Leila-Ouahabi-prensa-beteve'
    }],
    instagramImages: [{
      id: 1,
      image: 'https://instagram.fbcn9-1.fna.fbcdn.net/v/t51.2885-15/e35/c0.180.1440.1440a/s480x480/101430498_302677730767068_8939603245793760010_n.jpg?_nc_ht=instagram.fbcn9-1.fna.fbcdn.net&_nc_cat=100&_nc_ohc=JBQD_8a7A30AX9v4okA&oh=2d19a99053e5553a53ac7af8e0a5b82d&oe=5EFF47DD',
      url: 'https://www.instagram.com/p/CA0xNYEK6Ca/'
    }, {
      id: 2,
      image: 'https://instagram.fbcn9-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/p750x750/98486178_3226401640703707_6142543137533977888_n.jpg?_nc_ht=instagram.fbcn9-1.fna.fbcdn.net&_nc_cat=107&_nc_ohc=kGV6rdB8J28AX_DLqXE&oh=f69246457768b386716efbc8e4e5e28c&oe=5EFDAB95',
      url: 'https://www.instagram.com/p/CAfgEb-Ke7L/'
    }, {
      id: 3,
      image: 'https://instagram.fbcn9-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/p750x750/96293549_546231012924679_5779715775852724462_n.jpg?_nc_ht=instagram.fbcn9-1.fna.fbcdn.net&_nc_cat=107&_nc_ohc=vDt47miVgycAX8Nb3_S&oh=f5ce0352f4b317b3c32b4bf868c90001&oe=5EFE19FF',
      url: 'https://www.instagram.com/p/CAI0gCgKcdG/'
    }, {
      id: 4,
      image: 'https://instagram.fbcn9-1.fna.fbcdn.net/v/t51.2885-15/e35/s1080x1080/96549578_263245388192264_1628262657175648346_n.jpg?_nc_ht=instagram.fbcn9-1.fna.fbcdn.net&_nc_cat=108&_nc_ohc=wWrHeBovi3wAX9tbXl-&oh=5cb3de28a400d407a6731c966ed2c5e2&oe=5EFDC69B',
      url: 'https://www.instagram.com/p/B_7t2efqE3p/'
    }, {
      id: 5,
      image: 'https://instagram.fbcn9-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/p750x750/95265011_231968994562051_4641844213279099381_n.jpg?_nc_ht=instagram.fbcn9-1.fna.fbcdn.net&_nc_cat=107&_nc_ohc=_CqPVDr7KQAAX_Uotff&oh=0cde1fd5e439707564a23dda6015240d&oe=5EFDAFFF',
      url: 'https://www.instagram.com/p/B_fAcaUKI-5/'
    }, {
      id: 6,
      image: 'https://instagram.fbcn9-1.fna.fbcdn.net/v/t51.2885-15/sh0.08/e35/p750x750/93758480_654291222073841_7689488619188324942_n.jpg?_nc_ht=instagram.fbcn9-1.fna.fbcdn.net&_nc_cat=105&_nc_ohc=r9izt1AeyGUAX8o_xUg&oh=482ddf3b94d33f1b1b3707605b238c7a&oe=5EFDD730',
      url: 'https://www.instagram.com/p/B_NZ7ocK_JN/'
    }]
  }, {
    id: 24,
    title: 'UNIK TEAM | Lidija Kuliš | Jugadora de fútbol femenino',
    metadescription: 'Lidija Kuliš, futbolista bosnia, actualmente juega como centrocampista en el AC Milan de la Serie A y en la Selección de Bosnia y Herzegovina.',
    url: require('../images/players-profile-photo/lidija-kulis-ac-milan.png'),
    alt: 'lidija-kulis-ac-milan',
    name: 'LIDIJA KULIŠ',
    nameUrl: 'lidija-kulis',
    team: 'AC Milan',
    league_es: 'Serie A (Italia)',
    league_en: 'Serie A (Italy)',
    league_pt: 'Serie A (Itália)',
    selection_es: 'SEL. DE BIH',
    selection_en: 'BiH TEAM',
    selection_pt: 'SEL. BÓSNIA',
    mainPosition_es : 'CENTROCAMPISTA',
    mainPosition_en: 'MIDFIELDER',
    mainPosition_pt: 'MEIO-CAMPISTA',
    birthDate: '22 - 05 - 1992',
    birthYear: '1992',
    town: 'Visoko',
    country: 'Bosnia y Herzegovina',
    nationality_es : 'Bosnia',
    nationality_en : 'Bosnian',
    nationality_pt: 'Bosnia',
    height: '1,68',
    weight: '60',
    signaturePhoto: require('../images/players/lidija-kulis/jugadora-lidija-kulis-unikteam.png'),
    signaturePhotoAlt: 'jugadora-lidija-kulis-unikteam',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/lidija-kulis/header/lidija-kulis-ac-milan-femenino.jpg'),
      alt: 'lidija-kulis-ac-milan-femenino'
    }, {
      id: 2,
      url: require('../images/players/lidija-kulis/header/lidija-kulis-ac-milan-futbol-femenino..jpg'),
      alt: 'lidija-kulis-ac-milan-futbol-femenino'
    }, {
      id: 3,
      url: require('../images/players/lidija-kulis/header/lidija-kulis-seleccion-bosnia-herzegovina.jpg'),
      alt: 'lidija-kulis-seleccion-bosnia-herzegovina'
    }],
    mobileHeaderPhoto: require('../images/players/lidija-kulis/header-mobile/lidija-kulis-ac-milan.jpg'),
    mobileHeaderPhotoAlt: 'lidija-kulis-ac-milan',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera División de Escocia',
      nameFirstRow_en: '',
      nameFirstRow_pt: 'Primeira Divisão da Escócia',
      nameSecondRow_es: "Scottish Women's Premier League",
      nameSecondRow_en: "Scottish Women's Premier League",
      nameSecondRow_pt: "Scottish Women's Premier League",
      team_es: 'Glasgow City',
      team_en: 'Glasgow City',
      team_pt: 'Glasgow City',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    }, {
      id: 2,
      nameFirstRow_es: 'Primera División Alemana',
      nameFirstRow_en: 'German First Division',
      nameFirstRow_pt: 'Primeira Divisão Alemã',
      nameSecondRow_es: '"Allianz Frauen-Bundesliga"',
      nameSecondRow_en: '"Allianz Frauen-Bundesliga"',
      nameSecondRow_pt: '"Allianz Frauen-Bundesliga"',
      team_es: 'Turbine Potsdam',
      team_en: 'Turbine Potsdam',
      team_pt: 'Turbine Potsdam',
      years_es: '2012',
      years_en: '2012',
      years_pt: '2012'
    }, {
      id: 3,
      nameFirstRow_es: 'Segunda División Alemana',
      nameFirstRow_en: 'German Second Division',
      nameFirstRow_pt: 'Segunda Divisão Alemã',
      nameSecondRow_es: '"2. Frauen-Bundesliga"',
      nameSecondRow_en: '"2. Frauen-Bundesliga"',
      nameSecondRow_pt: '"2. Frauen-Bundesliga"',
      team_es: 'Turbine Potsdam II',
      team_en: 'Turbine Potsdam II',
      team_pt: 'Turbine Potsdam II',
      years_es: '2011',
      years_en: '2011',
      years_pt: '2011'
    }, {
      id: 4,
      nameFirstRow_es: 'Primera División Bosnia',
      nameFirstRow_en: 'Bosnian First Division',
      nameFirstRow_pt: 'Primeira Divisão Bósnia',
      nameSecondRow_es: '"Premijer Liga BiH"',
      nameSecondRow_en: '"Premijer Liga BiH"',
      nameSecondRow_pt: '"Premijer Liga BiH"',
      team_es: 'SFK Sarajevo',
      team_en: 'SFK Sarajevo',
      team_pt: 'SFK Sarajevo',
      years_es: '2007 - 2008, 2008 - 2009, 2009 - 2010 y 2010 - 2011',
      years_en: '2007 - 2008, 2008 - 2009, 2009 - 2010 and 2010 - 2011',
      years_pt: '2007 - 2008, 2008 - 2009, 2009 - 2010 e 2010 - 2011'
    }, {
      id: 5,
      nameFirstRow_es: 'Copa de Bosnia',
      nameFirstRow_en: 'Bosnian Cup',
      nameFirstRow_pt: 'Copa da Bósnia',
      nameSecondRow_es: '"Kup Bosne i Hercegovine"',
      nameSecondRow_en: '"Kup Bosne i Hercegovine"',
      nameSecondRow_pt: '"Kup Bosne i Hercegovine"',
      team_es: 'SFK Sarajevo',
      team_en: 'SFK Sarajevo',
      team_pt: 'SFK Sarajevo',
      years_es: '2008, 2009, 2010 y 2011',
      years_en: '2008, 2009, 2010 and 2011',
      years_pt: '2008, 2009, 2010 e 2011'
    }],
    internationalTournament: [],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Máxima Goleadora',
      nameFirstRow_en: 'Top Scorer',
      nameFirstRow_pt: 'Artilheira',
      nameSecondRow_es: 'Segunda División Alemana "2. Bundesliga" - Turbine Potsdam II ',
      nameSecondRow_en: 'German Second Division "2. Bundesliga" - Turbine Potsdam II',
      nameSecondRow_pt: 'Segunda Divisão Alemã "2. Bundesliga" - Turbine Potsdam II',
      years_es: '',
      years_en: '',
      years_pt: '',
    }, {
      id: 2,
      nameFirstRow_es: 'Mejor Jugadora de',
      nameFirstRow_en: 'Best Bosnian Female',
      nameFirstRow_pt: 'Melhor Jogadora da',
      nameSecondRow_es: 'Bosnia y Herzegovina',
      nameSecondRow_en: 'Football Player',
      nameSecondRow_pt: 'Bósnia e Herzegovina',
      years_es: '2008',
      years_en: '2008',
      years_pt: '2008'
    }],
    skilledLeg_es: 'Zurda',
    skilledLeg_en: 'Left-footed',
    skilledLeg_pt: 'Canhota',
    skilledLegNum: 35,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Assists',
      characteristic_pt: 'Assistências',
      characteristic_es: 'Asistencias',
      num: 2
    }, {
      id: 2,
      characteristic_en: 'Ball handling',
      characteristic_pt: 'Condução',
      characteristic_es: 'Conducción',
      num: 8
    }, {
      id: 3,
      characteristic_en: 'Medium range passes',
      characteristic_pt: 'Passe médio',
      characteristic_es: 'Pase medio',
      num: 26
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Defensive player',
      characteristic_pt: 'Jogadora defensiva',
      characteristic_es: 'Criterio defensivo',
      num: 10
    }, {
      id: 2,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Power',
      characteristic_pt: 'Potência',
      characteristic_es: 'Potencia',
      num: 27
    }, {
      id: 2,
      characteristic_en: 'Strenght',
      characteristic_pt: 'Força',
      characteristic_es: 'Fuerza',
      num: 17
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: true,
      position: 'MC'
    },{
      id: 7,
      selected: true,
      position: 'MCO'
    },{
      id: 8,
      selected: true,
      position: 'INTi'
    },{
      id: 9,
      selected: true,
      position: 'INTd'
    },{
      id: 10,
      selected: true,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'AC MILAN',
      country: 'Italia',
      years_es: '2019 - Actualidad',
      years_en: '2019 - Currently',
      years_pt: '2019 - Presente',
      image: require('../images/clubs/trayectoria/ac-milan-italia-futbol-femenino.png'),
      alt: 'ac-milan-italia-futbol-femenino'
    }, {
      id: 2,
      team: 'GLASGOW CITY',
      country: 'Escocia',
      years_es: '2018 - 2019',
      years_en: '2018 - 2019',
      years_pt: '2018 - 2019',
      image: require('../images/clubs/trayectoria/glasgow-city-escocia-futbol-femenino.png'),
      alt: 'glasgow-city-escocia-futbol-femenino'
    }, {
      id: 3,
      team: 'FC KÖLN',
      country: 'Alemania (cedida)',
      years_es: '2017 - 2018',
      years_en: '2017 - 2018',
      years_pt: '2017 - 2018',
      image: require('../images/clubs/trayectoria/fc-koln-alemania-futbol-femenino.png'),
      alt: 'fc-koln-alemania-futbol-femenino'
    }, {
      id: 4,
      team: 'TURBINE POTSDAM',
      country: 'Alemania',
      years_es: '2011 - 2018',
      years_en: '2011 - 2018',
      years_pt: '2011 - 2018',
      image: require('../images/clubs/trayectoria/turbine-postdam-alemania-futbol-femenino.png'),
      alt: 'turbine-postdam-alemania-futbol-femenino'
    }, {
      id: 5,
      team: 'SFK 2000 SARAJEVO',
      country: '',
      years_es: '2007 - 2011',
      years_en: '2007 - 2011',
      years_pt: '2007 - 2011',
      image: require('../images/clubs/trayectoria/sfk-2000-sarajevo-bosnia-herzegovina-futbol-femenino.png'),
      alt: 'sfk-2000-sarajevo-bosnia-herzegovina-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: '',
    instagramId: '',
    instagram: '',
    twitter: '',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [],
    press: [{
      id: 1,
      media: 'MILAN NEWS',
      url: 'https://www.milannews.it/social-network/milan-femminile-gli-auguri-all-attaccante-lidija-kuli-369688',
      image: require('../images/players/lidija-kulis/press/lidija-kulis-prensa-milan-news.jpg'),
      alt: 'lidija-kulis-prensa-milan-news'
    }, {
      id: 2,
      media: 'SPAZIO MILAN',
      url: 'https://www.spaziomilan.it/2019/07/news-sm-idea-lidija-kulis-per-lattacco/',
      image: require('../images/players/lidija-kulis/press/lidija-kulis-prensa-spazio-milan.jpg'),
      alt: 'lidija-kulis-prensa-spazio-milan'
    }, {
      id: 3,
      media: 'MILAN NEWS 24',
      url: 'https://www.milannews24.com/milan-femminile-oggi-lidija-kulis-compie-28-anni-gli-auguri-del-club/',
      image: require('../images/players/lidija-kulis/press/lidija-kulis-prensa-milan-news-24.jpg'),
      alt: 'lidija-kulis-prensa-milan-news-24'
    }, {
      id: 4,
      media: 'MILAN NEWS',
      url: 'https://www.milannews.it/news/bonansea-kulis-e-mauro-3-nuovi-vecchi-acquisti-nella-corsa-al-titolo-353291',
      image: require('../images/players/lidija-kulis/press/lidija-kulis-prensa-milan-news_2.jpg'),
      alt: 'lidija-kulis-prensa-milan-news_2'
    }, {
      id: 5,
      media: 'SPAZIO MILAN',
      url: 'https://www.spaziomilan.it/2020/01/sm-milan-femminile-contro-lempoli-out-anche-salvatori-rinaldi-e-kulis/',
      image: require('../images/players/lidija-kulis/press/lidija-kulis-prensa-spazio-milan_2.jpg'),
      alt: 'lidija-kulis-prensa-spazio-milan_2'
    }, {
      id: 6,
      media: 'DONNE SUL WEB',
      url: 'https://www.donnesulweb.it/news/sport/milan-femminile-giocatrici-e-quanto-guadagnano/',
      image: require('../images/players/lidija-kulis/press/lidija-kulis-prensa-donne-sul-web.jpg'),
      alt: 'lidija-kulis-prensa-donne-sul-web'
    }, {
      id: 7,
      media: 'SPAZIO MILAN',
      url: 'https://www.spaziomilan.it/2019/11/milan-femminile-ieri-esordio-per-lidija-kulis/',
      image: require('../images/players/lidija-kulis/press/lidija-kulis-prensa-spazio-milan_3.jpg'),
      alt: 'lidija-kulis-prensa-spazio-milan_3'
    }, {
      id: 8,
      media: 'GLASGOW CITY FC',
      url: 'https://www.youtube.com/watch?v=sZJCVPgZVnU',
      image: require('../images/players/lidija-kulis/press/lidija-kulis-prensa-glasgow-city-fc.jpg'),
      alt: 'lidija-kulis-prensa-glasgow-city-fc'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 25,
    title: 'UNIK TEAM | Macarena Portales| Jugadora de fútbol femenino',
    metadescription: 'Macarena Portales, futbolista española, actualmente juega como delantera en el Madrid CFF de la Primera Iberdrola y en la Selección Española Sub-20.',
    url: require('../images/players-profile-photo/macarena-portales-madrid-cff.png'),
    alt: 'macarena-portales-madrid-cff',
    name: 'MACARENA PORTALES',
    nameUrl: 'macarena-portales',
    team: 'Madrid CFF',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: 'SEL. ESPAÑOLA SUB-20',
    selection_en: 'SPAIN U-20',
    selection_pt: 'SEL. ESPANHOLA SUB-20',
    mainPosition_es : 'DELANTERA',
    mainPosition_en: 'FORWARD',
    mainPosition_pt: 'ATACANTE',
    birthDate: '02 - 08 - 1998',
    birthYear: '1998',
    town: 'Madrid',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,62',
    weight: '64',
    signaturePhoto: require('../images/players/macarena-portales/jugadoras-macarena-portales-unikteam.png'),
    signaturePhotoAlt: 'jugadoras-macarena-portales-unikteam',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/macarena-portales/header/jugadora-macarena-portales-madrid-cff-femenino.jpg'),
      alt: 'jugadora-macarena-portales-madrid-cff-femenino'
    }, {
      id: 2,
      url: require('../images/players/macarena-portales/header/macarena-portales-madrid-cff-femenino.jpg'),
      alt: 'macarena-portales-madrid-cff-femenino'
    }, {
      id: 3,
      url: require('../images/players/macarena-portales/header/macarena-portales-madrid-cff-futbol-femenino.jpg'),
      alt: 'macarena-portales-madrid-cff-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/macarena-portales/header-mobile/macarena-portales-madrid-cff.jpg'),
    mobileHeaderPhotoAlt: 'macarena-portales-madrid-cff',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Campeonato de España de',
      nameFirstRow_en: 'U-18 Spanish Champion',
      nameFirstRow_pt: 'Campeonato Espanhol de',
      nameSecondRow_es: 'Sel. Autonómicas Sub-18',
      nameSecondRow_en: '',
      nameSecondRow_pt: 'Seleções Autônomas Sub-18',
      team_es: 'Selección Madrileña',
      team_en: 'Madrid Autonomus Team',
      team_pt: 'Seleção Madrilenha',
      years_es: '',
      years_en: '',
      years_pt: ''
    }, {
      id: 2,
      nameFirstRow_es: 'Campeonato de España de',
      nameFirstRow_en: 'U-16 Spanish Champion',
      nameFirstRow_pt: 'Campeonato Espanhol de',
      nameSecondRow_es: 'Sel. Autonómicas Sub-16',
      nameSecondRow_en: '',
      nameSecondRow_pt: 'Seleções Autônomas Sub-16',
      team_es: 'Selección Madrileña',
      team_en: 'Madrid Autonomus Team',
      team_pt: 'Seleção Madrilenha',
      years_es: '',
      years_en: '',
      years_pt: ''
    }],
    internationalTournament: [],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Mejor Deportista Madrileña',
      nameFirstRow_en: 'Best Madrilean Athlete',
      nameFirstRow_pt: 'Melhor Atleta Madrilenha',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      years_es: '2013',
      years_en: '2013',
      years_pt: '2013'
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Assists',
      characteristic_pt: 'Assistências',
      characteristic_es: 'Asistencias',
      num: 2
    }, {
      id: 2,
      characteristic_en: 'Goals',
      characteristic_pt: 'Gol',
      characteristic_es: 'Gol',
      num: 18
    }, {
      id: 3,
      characteristic_en: 'Dribbling',
      characteristic_pt: 'Drible',
      characteristic_es: 'Regate',
      num: 30
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }, {
      id: 2,
      characteristic_en: 'Vision',
      characteristic_pt: 'Visão de jogo',
      characteristic_es: 'Visión de juego',
      num: 34
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Speed',
      characteristic_pt: 'Velocidade',
      characteristic_es: 'Velocidad',
      num: 33
    }, {
      id: 2,
      characteristic_en: 'Agility/Flexibility',
      characteristic_pt: 'Agilidade/Flexibilidade',
      characteristic_es: 'Agilidad/Flexibilidad',
      num: 15
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: true,
      position: 'INTd'
    },{
      id: 10,
      selected: true,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: true,
      position: 'EXTd'
    },{
      id: 13,
      selected: true,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'MADRID CCF',
      country: 'España',
      years_es: '2019 - Actualidad',
      years_en: '2019 - Currently',
      years_pt: '2019 - Presente',
      image: require('../images/clubs/trayectoria/madrid-cff-espana-futbol-femenino.png'),
      alt: 'madrid-cff-espana-futbol-femenino'
    }, {
      id: 2,
      team: 'SEVILLA FC',
      country: 'España',
      years_es: '2018 - 2019',
      years_en: '2018 - 2019',
      years_pt: '2018 - 2019',
      image: require('../images/clubs/trayectoria/sevilla-fc-espana-futbol-femenino.png'),
      alt: 'sevilla-fc-espana-futbol-femenino'
    }, {
      id: 3,
      team: 'ZARAGOZA CFF',
      country: 'España',
      years_es: '2017 - 2018',
      years_en: '2017 - 2018',
      years_pt: '2017 - 2018',
      image: require('../images/clubs/trayectoria/zaragoza-cff-espana-futbol-femenino.png'),
      alt: 'zaragoza-cff-espana-futbol-femenino'
    }, {
      id: 4,
      team: 'FUNDACIÓN ALBACETE',
      country: 'España',
      years_es: '2016 - 2017',
      years_en: '2016 - 2017',
      years_pt: '2016 - 2017',
      image: require('../images/clubs/trayectoria/albacete-balompie-espana-futbol-femenino.png'),
      alt: 'albacete-balompie-espana-futbol-femenino'
    }, {
      id: 5,
      team: 'MADRID CF',
      country: 'España',
      years_es: '2015 - 2016',
      years_en: '2015 - 2016',
      years_pt: '2015 - 2016',
      image: require('../images/clubs/trayectoria/madrid-cff-espana-futbol-femenino.png'),
      alt: 'madrid-cff-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'macarenaportales',
    instagramId: '2140078950',
    instagram: 'https://www.instagram.com/macarenaportales/',
    twitter: '',
    facebook: 'https://www.facebook.com/profile.php?id=100006310261059',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'SPRINTER',
      url: '',
      image: require('../images/players/macarena-portales/last-campaigns/campana-macarena-portales-sprinter.jpg'),
      alt: 'campana-macarena-portales-sprinter'
    }, {
      id: 2,
      brand: 'SCALPERS',
      url: 'https://www.instagram.com/p/Buwk6O6HbOL/',
      image: require('../images/players/macarena-portales/last-campaigns/campana-macarena-portales-scalpers.jpg'),
      alt: 'campana-macarena-portales-scalpers'
    }, {
      id: 3,
      brand: 'CASINO GRAN MADRID',
      url: 'https://www.instagram.com/p/B8CSMEqoK8D/',
      image: require('../images/players/macarena-portales/last-campaigns/campana-macarena-portales-casino-gran-madrid.jpg'),
      alt: 'campana-macarena-portales-casino-gran-madrid'
    }],
    press: [{
      id: 1,
      media: 'EFE',
      url: 'https://www.efe.com/efe/espana/practicodeporte/el-madrid-cff-tapado-madrileno-de-la-primera-iberdrola/50000944-4043656',
      image: require('../images/players/macarena-portales/press/macarena-portales-prensa-efe.jpg'),
      alt: 'macarena-portales-prensa-efe'
    }, {
      id: 2,
      media: 'TELE MADRID',
      url: 'http://www.telemadrid.es/deportes/mercado-fichajes-madrilenos-Liga-Iberdrola-0-2141485848--20190719084144.html',
      image: require('../images/players/macarena-portales/press/macarena-portales-prensa-telemadrid.jpg'),
      alt: 'macarena-portales-prensa-telemadrid'
    }, {
      id: 3,
      media: 'CLM 24',
      url: 'https://www.clm24.es/articulo/deportes/yolanda-aguirre-convocada-seleccion-nacional-femenina-sub-19/20160830212850128253.html',
      image: require('../images/players/macarena-portales/press/macarena-portales-prensa-clm-24.jpg'),
      alt: 'macarena-portales-prensa-clm-24'
    }, {
      id: 4,
      media: 'LA COLINA DE NERVION',
      url: 'https://www.lacolinadenervion.com/entrevista-macarena-portales-sevilla-femenino/',
      image: require('../images/players/macarena-portales/press/macarena-portales-prensa-la-colina-de-nervion.jpg'),
      alt: 'macarena-portales-prensa-la-colina-de-nervion'
    }, {
      id: 5,
      media: 'EL CÓRNER DEL SUR',
      url: 'https://www.elcornerdelsur.com/macarena-portales-mas-talento-sevilla-fc-femenino/',
      image: require('../images/players/macarena-portales/press/macarena-portales-prensa-el-corner-del-sur.jpg'),
      alt: 'macarena-portales-prensa-el-corner-del-sur'
    }, {
      id: 6,
      media: 'BESOCCER',
      url: 'https://es.besoccer.com/noticia/macarena-portales-cambia-el-zaragoza-por-el-sevilla-469192',
      image: require('../images/players/macarena-portales/press/macarena-portales-prensa-besoccer.jpg'),
      alt: 'macarena-portales-prensa-besoccer'
    }, {
      id: 7,
      media: 'SEVILLA FC',
      url: 'https://www.youtube.com/watch?v=B9BLXOcsk0A',
      image: require('../images/players/macarena-portales/press/macarena-portales-prensa-sevilla-fc.jpg'),
      alt: 'macarena-portales-prensa-sevilla-fc'
    }, {
      id: 8,
      media: 'CLM 24',
      url: 'https://www.clm24.es/articulo/deportes/zaragoza-cff-ficha-jugadora-albacete-macarena-portales/20170708164915165442.html',
      image: require('../images/players/macarena-portales/press/macarena-portales-prensa-clm-24_2.jpg'),
      alt: 'macarena-portales-prensa-clm-24_2'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 26,
    title: 'UNIK TEAM | Maitane López | Jugadora de fútbol femenino',
    metadescription: 'Maitane López, futbolista española, actualmente juega como centrocampista en la Real Sociedad de la Primera Iberdrola y en la Selección Española.',
    url: require('../images/players-profile-photo/maitane-lopez-real-sociedad.png'),
    alt: 'maitane-lopez-real-sociedad.png',
    name: 'MAITANE LÓPEZ',
    nameUrl: 'maitane-lopez',
    team: 'Real Sociedad',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: 'SEL. ESPAÑOLA',
    selection_en: 'SPANISH TEAM',
    selection_pt: 'SEL. ESPANHOLA',
    mainPosition_es : 'CENTROCAMPISTA',
    mainPosition_en: 'MIDFIELDER',
    mainPosition_pt: 'MEIO-CAMPISTA',
    birthDate: '13 - 03 - 1995',
    birthYear: '1995',
    town: 'Murcia',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,80',
    weight: '61',
    signaturePhoto: require('../images/players/maitane-lopez/jugadoras-maitane-lopez-unik-sports-management.png'),
    signaturePhotoAlt: 'jugadoras-maitane-lopez-unik-sports-management',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/maitane-lopez/header/maitane-lopez-levante-ud-femenino.jpg'),
      alt: 'maitane-lopez-levante-ud-femenino'
    }, {
      id: 2,
      url: require('../images/players/maitane-lopez/header/maitane-lopez-levante-ud-futbol-femenino.jpg'),
      alt: 'maitane-lopez-levante-ud-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/maitane-lopez/header-mobile/maitane-lopez-levante-ud.jpg'),
    mobileHeaderPhotoAlt: 'maitane-lopez-levante-ud',
    nationalTournament: [],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Eurocopa Sub-17',
      nameFirstRow_en: 'UEFA Euro U-17',
      nameFirstRow_pt: 'Eurocopa Sub-17',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Islandia 2015',
      years_en: 'Iceland 2015',
      years_pt: ''
    }, {
      id: 2,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'Eurocopa Sub-19',
      nameSecondRow_en: 'UEFA Euro U-19',
      nameSecondRow_pt: 'Eurocopa Sub-19',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Eslovaquia 2016',
      years_en: 'Slovakia 2016',
      years_pt: 'Eslovaquia 2016'
    }],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Once de Oro',
      nameFirstRow_en: 'Best 11',
      nameFirstRow_pt: 'Onze de Ouro',
      nameSecondRow_es: 'Fútbol Draft',
      nameSecondRow_en: 'Fútbol Draft',
      nameSecondRow_pt: 'Fútbol Draft',
      years_es: '2016',
      years_en: '2016',
      years_pt: '2016'
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Assists',
      characteristic_pt: 'Assistências',
      characteristic_es: 'Asistencias',
      num: 2
    }, {
      id: 2,
      characteristic_en: 'Ball handling',
      characteristic_pt: 'Condução',
      characteristic_es: 'Conducción',
      num: 8
    }, {
      id: 3,
      characteristic_en: 'Medium range passes',
      characteristic_pt: 'Passe médio',
      characteristic_es: 'Pase medio',
      num: 26
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Defensive player',
      characteristic_pt: 'Jogadora defensiva',
      characteristic_es: 'Criterio defensivo',
      num: 10
    }, {
      id: 2,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Power',
      characteristic_pt: 'Potência',
      characteristic_es: 'Potencia',
      num: 27
    }, {
      id: 2,
      characteristic_en: 'Strenght',
      characteristic_pt: 'Força',
      characteristic_es: 'Fuerza',
      num: 17
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: true,
      position: 'LIB'
    },{
      id: 6,
      selected: true,
      position: 'MC'
    },{
      id: 7,
      selected: true,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'REAL SOCIEDAD',
      country: 'España',
      years_es: '2020 - Actualidad',
      years_en: '2020 - Currently',
      years_pt: '2020 - Currently',
      image: require('../images/clubs/trayectoria/real-sociedad-espana-futbol-femenino.png'),
      alt: 'real-sociedad-espana-futbol-femenino'
    }, {
      id: 2,
      team: 'LEVANTE UD',
      country: 'España',
      years_es: '2015 - 2020',
      years_en: '2015 - 2020',
      years_pt: '2015 - 2020',
      image: require('../images/clubs/trayectoria/levante-ud-espana-futbol-femenino.png'),
      alt: 'levante-ud-espana-futbol-femenino'
    }, {
      id: 3,
      team: 'COLLERENSE UD',
      country: 'España',
      years_es: '2012 - 2015',
      years_en: '2012 - 2015',
      years_pt: '2012 - 2015',
      image: require('../images/clubs/trayectoria/ud-collerense-futbol-femenino.png'),
      alt: 'ud-collerense-espana-futbol-femenino'
    }, {
      id: 4,
      team: 'AT. CIUTAT DE PALMA',
      country: 'España',
      years_es: '2011 - 2012',
      years_en: '2011 - 2012',
      years_pt: '2011 - 2012',
      image: require('../images/clubs/trayectoria/sporting-ciutat-de-palma-espana-futbol-femenino.png'),
      alt: 'sporting-ciutat-de-palma-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'maiitane9',
    instagramId: '182216764',
    instagram: 'https://www.instagram.com/maiitane9/',
    twitter: 'https://twitter.com/maiitane9',
    facebook: 'https://www.facebook.com/maitanel',
    tikTok: 'https://www.tiktok.com/@maiitane9',
    lastsCampaigns: [{
      id: 1,
      brand: 'SPRINTER',
      url: '',
      image: require('../images/players/maitane-lopez/last-campaigns/campana-maitane-lopez-sprinter.jpg'),
      alt: 'campana-maitane-lopez-sprinter'
    }, {
      id: 2,
      brand: 'SAN BENEDETTO',
      url: 'https://www.instagram.com/p/BySzWOSik8J/',
      image: require('../images/players/maitane-lopez/last-campaigns/campana-maitane-lopez-san-benedetto.jpg'),
      alt: 'campana-maitane-lopez-san-benedetto'
    }, {
      id: 3,
      brand: 'TEIKA',
      url: 'https://www.instagram.com/p/B45IMiZICvL/',
      image:  require('../images/players/maitane-lopez/last-campaigns/campana-maitane-lopez-teika.jpg'),
      alt: 'campana-maitane-lopez-teika'
    }, {
      id: 4,
      brand: 'VISA',
      url: '',
      image:  require('../images/players/maitane-lopez/last-campaigns/campana-maitane-lopez-visa.jpg'),
      alt: 'campana-maitane-lopez-visa'
    }, {
      id: 5,
      brand: 'EL FARO CLOTHING',
      url: 'https://www.instagram.com/p/BbICUVfg-X9/',
      image:  require('../images/players/maitane-lopez/last-campaigns/campana-maitane-lopez-el-faro-clothing.jpg'),
      alt: 'campana-maitane-lopez-el-faro-clothing'
    }],
    press: [{
      id: 1,
      media: 'MARCA',
      url: 'https://www.marca.com/primera-plana/2019/12/21/5de5422622601de10c8b45c8.html',
      image:  require('../images/players/maitane-lopez/press/Maitane-Lopez-prensa-marca.jpg'),
      alt: 'Maitane-Lopez-prensa-marca'
    }, {
      id: 2,
      media: 'LA OPINIÓN DE MURCIA',
      url: 'https://www.laopiniondemurcia.es/deportes/2019/10/30/aguilena-maitane-lopez-convocada-seleccion/1064379.html',
      image: require('../images/players/maitane-lopez/press/maitane-lopez-prensa-la-opinion-de-murcia.jpg'),
      alt: 'maitane-lopez-prensa-la-opinion-de-murcia'
    }, {
      id: 3,
      media: 'EL ESPAÑOL',
      url: 'https://www.elespanol.com/branded/maitane-lopez-futbol-femenino/',
      image: require('../images/players/maitane-lopez/press/maitane-lopez-prensa-el-espanol.jpg'),
      alt: 'maitane-lopez-prensa-el-espanol'
    }, {
      id: 4,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2020/04/03/fotorrelato/1585919275_087440.html',
      image: require('../images/players/maitane-lopez/press/maitane-lopez-prensa-diario-as.jpg'),
      alt: 'maitane-lopez-prensa-diario-as'
    }, {
      id: 5,
      media: 'DIARIO DE MALLORCA',
      url: 'https://www.diariodemallorca.es/deportes/2020/04/05/guijarro-caldentey-maitane-impulsan-iniciativa/1500344.html',
      image: require('../images/players/maitane-lopez/press/maitane-lopez-prensa-diario-de-mallorca.jpg'),
      alt: 'maitane-lopez-prensa-diario-de-mallorca'
    }, {
      id: 6,
      media: 'EL DESMARQUE',
      url: 'https://eldesmarque.com/valencia/levante-ud/209542-maitane-para-competir-con-los-de-arriba-ya-no-se-puede-fallar-mas',
      image: require('../images/players/maitane-lopez/press/maitane-lopez-prensa-el-desmarque.jpg'),
      alt: 'maitane-lopez-prensa-el-desmarque'
    }, {
      id: 7,
      media: 'EFE',
      url: 'https://www.efe.com/efe/espana/practicodeporte/llega-la-primera-convocatoria-de-seleccion-femenina-promesas/50000944-4100060',
      image: require('../images/players/maitane-lopez/press/maitane-lopez-prensa-efe.jpg'),
      alt: 'maitane-lopez-prensa-efe'
    }, {
      id: 8,
      media: 'MACHO LEVANTE',
      url: 'https://macholevante.com/maitane-tenemos-equipo-para-luchar-por-una-plaza-de-champions-nos-lo-tenemos-que-creer/',
      image: require('../images/players/maitane-lopez/press/maitane-lopez-prensa-macho-levante.jpg'),
      alt: 'maitane-lopez-prensa-macho-levante'
    }, {
      id: 9,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/femenino/20171218/433738216305/maitane-lopez-embajadora-de-la-copa-de-la-reina.html',
      image: require('../images/players/maitane-lopez/press/maitane-lopez-prensa-mundo-deportivo.jpg'),
      alt: 'maitane-lopez-prensa-mundo-deportivo'
    }, {
      id: 10,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/primera-division/2020/04/04/5e88e21522601d1e288b4612.html',
      image: require('../images/players/maitane-lopez/press/maitane-lopez-prensa-marca_2.jpg'),
      alt: 'maitane-lopez-prensa-marca_2'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 27,
    title: 'UNIK TEAM | Misa Rodríguez | Jugadora de fútbol femenino',
    metadescription: 'Misa Rodríguez, futbolista española, actualmente juega como portera en el Real Madrid CF de la Primera Iberdrola y en la Selección Española Sub-20.',
    url: require('../images/players-profile-photo/misa-rodriguez-real-madrid-fem.png'),
    alt: 'misa-rodriguez-real-madrid-femenino',
    name: 'MISA RODRÍGUEZ',
    nameUrl: 'misa-rodriguez',
    team: 'Real Madrid CF',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: 'SEL. ESPAÑOLA',
    selection_en: 'SPANISH TEAM',
    selection_pt: 'SEL. ESPANHOLA',
    mainPosition_es : 'PORTERA',
    mainPosition_en: 'GOALKEEPER',
    mainPosition_pt: 'GOLEIRA',
    birthDate: '22 - 07 - 1999',
    birthYear: '1999',
    town: 'Las Palmas de G. Canaria',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,73',
    weight: '66',
    signaturePhoto: require('../images/players/misa-rodriguez/jugadora-maria-isabel-misa-unik-sports-management.png'),
    signaturePhotoAlt: 'jugadora-maria-isabel-misa-unik-sports-management',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/misa-rodriguez/header/portera-misa-rodriguez-real-madrid-futbol-femenino.jpg'),
      alt: 'portera-misa-rodriguez-real-madrid-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/home/mobile/misa-rodriguez-real-madrid-femenino.jpg'),
    mobileHeaderPhotoAlt: 'misa-rodriguez-real-madrid-femenino',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2018 - 2019',
      years_en: '2018 - 2019',
      years_pt: '2018 - 2019'
    }, {
      id: 2,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2017 - 2018',
      years_en: '2017 - 2018',
      years_pt: '2017 - 2018'
    }, {
      id: 3,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2016 - 2017',
      years_en: '2016 - 2017',
      years_pt: '2016 - 2017'
    }, {
      id: 4,
      nameFirstRow_es: 'Subcampeona de la Copa de España',
      nameFirstRow_en: 'Runner-up of the Spanish Cup',
      nameFirstRow_pt: 'Vice-campeã da Copa da Espanha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2019',
      years_en: '2019',
      years_pt: '2019'
    }, {
      id: 5,
      nameFirstRow_es: 'Subcampeona de la Copa de España',
      nameFirstRow_en: 'Runner-up of the Spanish Cup',
      nameFirstRow_pt: 'Vice-campeã da Copa da Espanha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    }, {
      id: 6,
      nameFirstRow_es: 'Subcampeona de la Copa de España',
      nameFirstRow_en: 'Runner-up of the Spanish Cup',
      nameFirstRow_pt: 'Vice-campeã da Copa da Espanha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2017',
      years_en: '2017',
      years_pt: '2017'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Subcampeona del',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da Copa',
      nameSecondRow_es: 'Mundial Sub-20',
      nameSecondRow_en: 'U-20 World Cup',
      nameSecondRow_pt: 'do Mundo Sub-20',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Francia 2018',
      years_en: 'France 2018',
      years_pt: 'França 2018'
    }, {
      id: 2,
      nameFirstRow_es: 'Eurocopa Sub-19',
      nameFirstRow_en: 'UEFA Euro U-19',
      nameFirstRow_pt: 'Eurocopa Sub-19',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Irlanda del Norte 2017',
      years_en: 'Northern Ireland 2017',
      years_pt: 'Irlanda do Norte 2017'
    }],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Finalista',
      nameFirstRow_en: 'Fútbol Draft',
      nameFirstRow_pt: 'Finalista Fútbol',
      nameSecondRow_es: 'Fútbol Draft',
      nameSecondRow_en: 'Finalist',
      nameSecondRow_pt: 'Draft',
      years_es: '2019',
      years_en: '2019',
      years_pt: '2019'
    }],
    skilledLeg_es: 'Zurda',
    skilledLeg_en: 'Left-footed',
    skilledLeg_pt: 'Canhota',
    skilledLegNum: 35,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Ball control with feet',
      characteristic_pt: 'Domínio bola c/ pés',
      characteristic_es: 'Dominio balón con los pies',
      num: 14
    }, {
      id: 2,
      characteristic_en: 'Punt',
      characteristic_pt: 'Espalmar',
      characteristic_es: 'Despeje',
      num: 12
    }, {
      id: 3,
      characteristic_en: 'Blocking',
      characteristic_pt: 'Agarrar',
      characteristic_es: 'Blocaje',
      num: 4
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Space control',
      characteristic_pt: 'Colocação no campo',
      characteristic_es: 'Colocación',
      num: 6
    }, {
      id: 2,
      characteristic_en: 'Communication skills',
      characteristic_pt: 'Comunicação',
      characteristic_es: 'Comunicación',
      num: 7
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Leader',
      characteristic_pt: 'Líder',
      characteristic_es: 'Líder',
      num: 23
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Agility/Flexibility',
      characteristic_pt: 'Agilidade/Flexibilidade',
      characteristic_es: 'Agilidad/Flexibilidad',
      num: 15
    }, {
      id: 2,
      characteristic_en: 'Reflexes',
      characteristic_pt: 'Reflexos',
      characteristic_es: 'Reflejos',
      num: 29
    }],
    positions: [{
      id: 0,
      selected: true,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'REAL MADRID CF',
      country: 'España',
      nameSecondRow_pt: '',
      years_es: '2020 - Actualidad',
      years_en: '2020 - Currently',
      years_pt: '2020 - Presente',
      image: require('../images/clubs/trayectoria/real-madrid-fc-espana-futbol-femenino.png'),
      alt: 'real-madrid-fc-espana-futbol-femenino'
    }, {
      id: 2,
      team: 'DEPORTIVO ABANCA',
      country: 'España',
      nameSecondRow_pt: '',
      years_es: '2019 - 2020',
      years_en: '2019 - 2020',
      years_pt: '2019 - 2020',
      image: require('../images/clubs/trayectoria/deportivo-la-coruna-espana-futbol-femenino.png'),
      alt: 'deportivo-la-coruna-espana-futbol-femenino'
    }, {
      id: 3,
      team: 'ATLÉTICO DE MADRID',
      country: 'España',
      nameSecondRow_pt: '',
      years_es: '2017 - 2019',
      years_en: '2017 - 2019',
      years_pt: '2017 - 2019',
      image: require('../images/clubs/trayectoria/atletico-de-madrid-espana-futbol-femenino.png'),
      alt: 'atletico-de-madrid-espana-futbol-femenino'
    }, {
      id: 4,
      team: 'CD FEMARGUIN',
      country: 'España',
      nameSecondRow_pt: '',
      years_es: '2013 - 2016',
      years_en: '2013 - 2016',
      years_pt: '2013 - 2016',
      image: require('../images/clubs/trayectoria/cd-femarguin-espana-futbol-femenino.png'),
      alt: 'cd-femarguin-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'marisabel_rguez',
    instagramId: '776644462',
    instagram: 'https://www.instagram.com/marisabel_rguez/',
    twitter: 'https://twitter.com/marisabelrr1',
    facebook: '',
    tikTok: 'marisabelrodrigue3',
    lastsCampaigns: [{
      id: 1,
      brand: 'HO SOCCER',
      url: 'https://www.instagram.com/p/B7jVyUXoEuY/',
      image: require('../images/players/misa-rodriguez/last-campaigns/campana-misa-rodriguez-ho-soccer.jpg'),
      alt: 'campana-misa-rodriguez-ho-soccer'
    }, {
      id: 2,
      brand: 'PUMA',
      url: 'https://www.instagram.com/p/B0bo62_oXaW/',
      image: require('../images/players/misa-rodriguez/last-campaigns/campana-misa-rodriguez-puma.jpg'),
      alt: 'campana-misa-rodriguez-puma'
    }, {
      id: 3,
      brand: 'SPRINTER',
      url: 'https://www.instagram.com/p/B7qIUjKonXJ/',
      image: require('../images/players/misa-rodriguez/last-campaigns/campana-misa-rodriguez-sprinter.jpg'),
      alt: 'campana-misa-rodriguez-sprinter'
    }],
    press: [{
      id: 1,
      media: 'LA VOZ DE GALÍCIA',
      url: 'https://www.lavozdegalicia.es/noticia/torremarathon/2020/04/21/misa-ficha-real-madridpecas-va-levante-enquista-negociacion-tere-abelleira/00031587483231468998938.htm',
      image: require('../images/players/misa-rodriguez/press/misa-rodriguez-prensa-la-voz-de-galicia.jpg'),
      alt: 'misa-rodriguez-prensa-la-voz-de-galicia'
    }, {
      id: 2,
      media: 'TELE ACTUALIDAD',
      url: 'https://www.teldeactualidad.com/noticia/sociedad/2020/05/04/17181.html',
      image: require('../images/players/misa-rodriguez/press/misa-rodriguez-prensa-tel-de-actualidad.jpg'),
      alt: 'misa-rodriguez-prensa-tel-de-actualidad'
    }, {
      id: 3,
      media: 'LA VOZ DE GALÍCIA',
      url: 'https://www.lavozdegalicia.es/noticia/torremarathon/depor-femenino/2020/02/01/misa-barca-me-quiero-ir-depor-aposto-mi/00031580565100927520744.htm',
      image: require('../images/players/misa-rodriguez/press/misa-rodriguez-prensa-la-voz-de-galicia-2.jpg'),
      alt: 'misa-rodriguez-prensa-la-voz-de-galicia-2'
    }, {
      id: 4,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2020/04/18/femenino/1587238145_148632.html',
      image: require('../images/players/misa-rodriguez/press/misa-rodriguez-prensa-diario-as.jpg'),
      alt: 'misa-rodriguez-prensa-diario-as'
    }, {
      id: 5,
      media: 'LA OPINIÓN CORUÑA',
      url: 'https://www.laopinioncoruna.es/deportivo/2019/10/31/misa-villegas-primera-lista-seleccion/1448156.html',
      image: require('../images/players/misa-rodriguez/press/misa-rodriguez-prensa-la-opinion-coruna.jpg'),
      alt: 'misa-rodriguez-prensa-la-opinion-coruna'
    }, {
      id: 7,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/2017/02/07/5899d90f22601dc2608b461d.html',
      image: require('../images/players/misa-rodriguez/press/misa-rodriguez-prensa-marca.jpg'),
      alt: 'misa-rodriguez-prensa-marca'
    }, {
      id: 8,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2019/07/02/mas_futbol/1562084744_985255.html',
      image: require('../images/players/misa-rodriguez/press/misa-rodriguez-prensa-diario-as-2.jpg'),
      alt: 'misa-rodriguez-prensa-diario-as-2'
    }, {
      id: 9,
      media: 'BESOCCER',
      url: 'https://es.besoccer.com/noticia/la-porteria-del-depor-bendito-problema-819480',
      image: require('../images/players/misa-rodriguez/press/misa-rodriguez-prensa-besoccer.jpg'),
      alt: 'misa-rodriguez-prensa-besoccer'
    }, {
      id: 10,
      media: 'EUROPAPRESS',
      url: 'https://www.europapress.es/deportes/futbol-00162/noticia-seleccion-femenina-futbol-concentra-martes-preparar-visita-polonia-20191104182333.html',
      image: require('../images/players/misa-rodriguez/press/misa-rodriguez-prensa-europapress.jpg'),
      alt: 'misa-rodriguez-prensa-europapress'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 28,
    title: 'UNIK TEAM | Marta Carro | Jugadora de fútbol femenino',
    metadescription: 'Marta Carro, futbolista española, actualmente juega como centrocampista en el Valencia CF de la Primera Iberdrola y en la Selección Española.',
    url: require('../images/players-profile-photo/marta-carro-valencia-cf.png'),
    alt: 'marta-carro-valencia-cf',
    name: 'MARTA CARRO',
    nameUrl: 'marta-carro',
    team: 'Valencia CF',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: 'SEL. ESPAÑOLA',
    selection_en: 'SPANISH TEAM',
    selection_pt: 'SEL. ESPANHOLA',
    mainPosition_es : 'CENTROCAMPISTA',
    mainPosition_en: 'MIDFIELDER',
    mainPosition_pt: 'MEIO-CAMPISTA',
    birthDate: '06 - 01 - 1991',
    birthYear: '1991',
    town: 'Cádiz',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,76',
    weight: '63',
    signaturePhoto: require('../images/players/marta-carro/jugadora-marta-carro-unik-team.png'),
    signaturePhotoAlt: 'jugadora-marta-carro-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/marta-carro/header/Marta_Carro.jpg'),
      alt: 'Marta_Carro'
    }, {
      id: 2,
      url: require('../images/players/marta-carro/header/Marta_Carro2.jpg'),
      alt: 'Marta_Carro2'
    }, {
      id: 3,
      url: require('../images/players/marta-carro/header/Marta_Carro3.jpg'),
      alt: 'Marta_Carro3'
    }],
    mobileHeaderPhoto: require('../images/players/marta-carro/header-mobile/marta-carro-seleccion-espanola.jpg'),
    mobileHeaderPhotoAlt: 'marta-carro-seleccion-espanola',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Copa de España',
      nameFirstRow_en: 'Spanish Cup',
      nameFirstRow_pt: 'Copa da Espanha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2016',
      years_en: '2016',
      years_pt: '2016'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: "Cyprus Women's Cup",
      nameFirstRow_en: "Cyprus Women's Cup",
      nameFirstRow_pt: "Cyprus Women's Cup",
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Chipre 2018',
      years_en: 'Cyprus 2018',
      years_pt: ''
    }],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Mejor Futbolista',
      nameFirstRow_en: 'Cadiz Best Player',
      nameFirstRow_pt: 'Melhor Jogadora',
      nameSecondRow_es: 'Gaditana',
      nameSecondRow_en: '',
      nameSecondRow_pt: 'de Cádiz',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Clearing the ball',
      characteristic_pt: 'Saída de bola',
      characteristic_es: 'Salida de balón',
      num: 32
    }, {
      id: 2,
      characteristic_en: 'Ball control',
      characteristic_pt: 'Controle de bola',
      characteristic_es: 'Control de balón',
      num: 9
    }, {
      id: 3,
      characteristic_en: 'Aerial ball control',
      characteristic_pt: 'Jogo aéreo',
      characteristic_es: 'Juego aéreo',
      num: 19
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Defensive player',
      characteristic_pt: 'Jogadora defensiva',
      characteristic_es: 'Criterio defensivo',
      num: 10
    }, {
      id: 2,
      characteristic_en: 'Space control',
      characteristic_pt: 'Colocação no campo',
      characteristic_es: 'Colocación',
      num: 6
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Strenght',
      characteristic_pt: 'Força',
      characteristic_es: 'Fuerza',
      num: 17
    }, {
      id: 2,
      characteristic_en: 'Resistance',
      characteristic_pt: 'Resistência',
      characteristic_es: 'Resistencia',
      num: 31
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: true,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: true,
      position: 'LIB'
    },{
      id: 6,
      selected: true,
      position: 'MC'
    },{
      id: 7,
      selected: true,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'VALENCIA CF',
      country: 'España',
      years_es: '2017 - Actualidad',
      years_en: '2017 - Currently',
      years_pt: '2017 - Presente',
      image: require('../images/clubs/trayectoria/valencia-cf-espana-futbol-femenino.png'),
      alt: 'valencia-cf-espana-futbol-femenino'
    }, {
      id: 2,
      team: 'MADRID CFF',
      country: 'España',
      years_es: '2017',
      years_en: '2017',
      years_pt: '2017',
      image: require('../images/clubs/trayectoria/madrid-cff-espana-futbol-femenino.png'),
      alt: 'madrid-cff-espana-futbol-femenino'
    }, {
      id: 3,
      team: 'AGSM VERONA',
      country: 'Italia',
      years_es: '2016 - 2017',
      years_en: '2016 - 2017',
      years_pt: '2016 - 2017',
      image: require('../images/clubs/trayectoria/agsm-verona-italia-futbol-femenino.png'),
      alt: 'agsm-verona-italia-futbol-femenino'
    }, {
      id: 4,
      team: 'ATLÉTICO DE MADRID',
      country: 'España',
      years_es: '2007 - 2016',
      years_en: '2007 - 2016',
      years_pt: '2007 - 2016',
      image: require('../images/clubs/trayectoria/atletico-de-madrid-espana-futbol-femenino.png'),
      alt: 'atletico-de-madrid-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'martacarro5',
    instagramId: '487299636',
    instagram: 'https://www.instagram.com/martacarro5/',
    twitter: 'https://twitter.com/MartaCarro5',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'PUMA',
      url: 'https://www.instagram.com/p/B0I2NPLI6sW/',
      image: require('../images/players/marta-carro/last-campaigns/campana-marta-carro-puma.jpg'),
      alt: 'campana-marta-carro-puma'
    }, {
      id: 2,
      brand: 'EL CAJÓN TECH',
      url: 'https://www.instagram.com/p/B9HPAUvKMaL/',
      image: require('../images/players/marta-carro/last-campaigns/campana-marta-carro-el-cajon-tech.jpg'),
      alt: 'campana-marta-carro-el-cajon-tech'
    }, {
      id: 3,
      brand: 'MIS ESPINILLERAS',
      url: 'https://www.instagram.com/p/BnbIF84n7Ua/',
      image: require('../images/players/marta-carro/last-campaigns/campana-marta-carro-mis-espinilleras.jpg'),
      alt: 'campana-marta-carro-mis-espinilleras'
    }, {
      id: 4,
      brand: 'JOMA',
      url: 'https://www.instagram.com/p/BiCGG7wH5Jq/',
      image: require('../images/players/marta-carro/last-campaigns/campana-marta-carro-joma.jpg'),
      alt: 'campana-marta-carro-joma'
    }],
    press: [{
      id: 1,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/primera-division/2020/05/01/5eab2969268e3eff4d8b4615.html',
      image: require('../images/players/marta-carro/press/marta-carro-prensa-marca.jpg'),
      alt: 'marta-carro-prensa-marca'
    }, {
      id: 2,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2020/02/18/femenino/1582023117_043598.html',
      image: require('../images/players/marta-carro/press/marta-carro-prensa-diario-as.jpg'),
      alt: 'marta-carro-prensa-diario-as'
    }, {
      id: 3,
      media: 'ONDA CERO',
      url: 'https://www.ondacero.es/solo-ondaceroes/ellas-juegan-en-la-onda/podcast/ellas-juegan-0333-marta-carro-me-quede-muy-cerca-de-estar-en-un-mundial-ojala-pueda-jugar-la-proxima-eurocopa_202004285ea7f6db3b1d860001d3bbdb.html',
      image: require('../images/players/marta-carro/press/marta-carro-prensa-onda-cero.jpg'),
      alt: 'marta-carro-prensa-onda-cero'
    }, {
      id: 4,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/seleccion/2019/06/10/5cfe7f57e2704ead208b45d0.html',
      image: require('../images/players/marta-carro/press/marta-carro-prensa-marca_2.jpg'),
      alt: 'marta-carro-prensa-marca_2'
    }, {
      id: 5,
      media: 'BESOCCER',
      url: 'https://es.besoccer.com/noticia/el-valencia-renueva-a-marta-carro-hasta-2021-656262',
      image: require('../images/players/marta-carro/press/marta-carro-prensa-besoccer.jpg'),
      alt: 'marta-carro-prensa-besoccer'
    }, {
      id: 6,
      media: 'RFEF',
      url: 'https://www.rfef.es/noticias/valencia-cf-femenino-dedica-su-victoria-marta-carro',
      image: require('../images/players/marta-carro/press/marta-carro-prensa-rfef.jpg'),
      alt: 'marta-carro-prensa-rfef'
    }, {
      id: 7,
      media: 'THE CHAMPIONS VOICE',
      url: 'https://www.thechampionsvoice.com/marta-carro-agradecimiento-a-sus-recuperadores-y-donacion-a-esclerosis-multiple-espana-tras-un-ano-ko/',
      image: require('../images/players/marta-carro/press/marta-carro-prensa-the-champions-voice.jpg'),
      alt: 'marta-carro-prensa-the-champions-voice'
    }, {
      id: 8,
      media: 'ESPORTBASE',
      url: 'https://esportbase.valenciaplaza.com/2020/04/10/marta-carro-sentia-impotencia-no-pudiendo-ayudar-al-equipo/',
      image: require('../images/players/marta-carro/press/marta-carro-prensa-esportbase.jpg'),
      alt: 'marta-carro-prensa-esportbase'
    }, {
      id: 9,
      media: 'EL DESMARQUE',
      url: 'https://eldesmarque.com/valencia/valencia-cf/177350-marta-carro-se-rompe-con-espana',
      image: require('../images/players/marta-carro/press/marta-carro-prensa-el-desmarque.jpg'),
      alt: 'marta-carro-prensa-el-desmarque'
    }, {
      id: 10,
      media: 'EL DESMARQUE',
      url: 'https://eldesmarque.com/valencia/valencia-cf/174859-marta-carro-se-sube-al-tren-de-la-roja',
      image: require('../images/players/marta-carro/press/marta-carro-prensa-el-desmarque_2.jpg'),
      alt: 'marta-carro-prensa-el-desmarque_2'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },/* {
    id: 29,
    title: 'UNIK TEAM | Méline Gérard | Jugadora de fútbol femenino',
    metadescription: 'Méline Gérard, futbolista francesa, actualmente juega como portera en el Real Betis de la Primera Iberdrolay en la Selección Francesa.',
    url: require('../images/players-profile-photo/meline-gerard-real-betis-balompie.png'),
    alt: 'meline-gerard-real-betis-balompie',
    name: 'MÉLINE GERARD',
    nameUrl: 'meline-gerard',
    team: 'Real Betis',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: 'SEL. FRANCESA',
    selection_en: 'FRENCH TEAM',
    selection_pt: '',
    mainPosition_es : 'PORTERA',
    mainPosition_en: 'GOALKEEPER',
    mainPosition_pt: 'GOLEIRA',
    birthDate: '30 - 05 - 1990',
    birthYear: '1990',
    town: 'Massy',
    country: 'Francia',
    nationality_es : 'Francesa',
    nationality_en : 'French',
    nationality_pt: '',
    height: '1,69',
    weight: '57',
    signaturePhoto: require('../images/players/meline-gerard/jugadora-meline-gerard-unik-team.png'),
    signaturePhotoAlt: 'jugadora-meline-gerard-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/meline-gerard/header/Méline_Gérard.jpg'),
      alt: 'Méline_Gérard'
    }, {
      id: 2,
      url: require('../images/players/meline-gerard/header/Méline_Gérard2.jpg'),
      alt: 'Méline_Gérard2'
    }, {
      id: 3,
      url: require('../images/players/meline-gerard/header/Méline_Gérard3.jpg'),
      alt: 'Méline_Gérard3'
    }],
    mobileHeaderPhoto: require('../images/players/meline-gerard/header-mobile/portera-meline-gerard-real-betis.jpg'),
    mobileHeaderPhotoAlt: 'portera-meline-gerard-real-betis',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Copa de Francia',
      nameFirstRow_en: 'French Cup',
      nameFirstRow_pt: '',
      nameSecondRow_es: '"Coupe de France"',
      nameSecondRow_en: '"Coupe de France"',
      team_es: 'Olympique de Lyon',
      team_en: 'Olympique de Lyon',
      nameSecondRow_pt: '',
      years_es: '2016 - 2017',
      years_en: '2016 - 2017'
    }, {
      id: 2,
      nameFirstRow_es: 'Primera División Francesa',
      nameFirstRow_en: 'French First Division',
      nameFirstRow_pt: '',
      nameSecondRow_es: '"Division 1 Féminine"',
      nameSecondRow_en: '"Division 1 Féminine"',
      team_es: 'Olympique de Lyon',
      team_en: 'Olympique de Lyon',
      nameSecondRow_pt: '',
      years_es: '2016 - 2017',
      years_en: '2016 - 2017'
    }, {
      id: 3,
      nameFirstRow_es: 'Copa de Francia',
      nameFirstRow_en: 'French Cup',
      nameFirstRow_pt: '',
      nameSecondRow_es: '"Coupe de France"',
      nameSecondRow_en: '"Coupe de France"',
      team_es: 'Olympique de Lyon',
      team_en: 'Olympique de Lyon',
      nameSecondRow_pt: '',
      years_es: '2015 - 2016',
      years_en: '2015 - 2016'
    }, {
      id: 4,
      nameFirstRow_es: 'Primera División Francesa',
      nameFirstRow_en: 'French First Division',
      nameFirstRow_pt: '',
      nameSecondRow_es: '"Division 1 Féminine"',
      nameSecondRow_en: '"Division 1 Féminine"',
      team_es: 'Olympique de Lyon',
      team_en: 'Olympique de Lyon',
      nameSecondRow_pt: '',
      years_es: '2015 - 2016',
      years_en: '2015 - 2016'
    }, {
      id: 5,
      nameFirstRow_es: 'Copa de Francia',
      nameFirstRow_en: 'French Cup',
      nameFirstRow_pt: '',
      nameSecondRow_es: '"Coupe de France"',
      nameSecondRow_en: '"Coupe de France"',
      team_es: 'Olympique de Lyon',
      team_en: 'Olympique de Lyon',
      nameSecondRow_pt: '',
      years_es: '2014 - 2015',
      years_en: '2014 - 2015'
    }, {
      id: 6,
      nameFirstRow_es: 'Primera División Francesa',
      nameFirstRow_en: 'French First Division',
      nameFirstRow_pt: '',
      nameSecondRow_es: '"Division 1 Féminine"',
      nameSecondRow_en: '"Division 1 Féminine"',
      team_es: 'Olympique de Lyon',
      team_en: 'Olympique de Lyon',
      nameSecondRow_pt: '',
      years_es: '2014 - 2015',
      years_en: '2014 - 2015'
    }, {
      id: 7,
      nameFirstRow_es: 'Copa de Francia',
      nameFirstRow_en: 'French Cup',
      nameFirstRow_pt: '',
      nameSecondRow_es: '"Coupe de France"',
      nameSecondRow_en: '"Coupe de France"',
      team_es: 'AS Saint-Ètienne',
      team_en: 'AS Saint-Ètienne',
      nameSecondRow_pt: '',
      years_es: '2010 - 2011',
      years_en: '2010 - 2011'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Champions League',
      nameFirstRow_en: 'Champions League',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: 'Olympique de Lyon',
      team_en: 'Olympique de Lyon',
      nameSecondRow_pt: '',
      years_es: '2016 - 2017',
      years_en: '2016 - 2017'
    }, {
      id: 2,
      nameFirstRow_es: 'Champions League',
      nameFirstRow_en: 'Champions League',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: 'Olympique de Lyon',
      team_en: 'Olympique de Lyon',
      nameSecondRow_pt: '',
      years_es: '2015 - 2016',
      years_en: '2015 - 2016'
    }],
    individualAwards: [],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Blocking',
      characteristic_pt: 'Agarrar',
      characteristic_es: 'Blocaje',
      num: 4
    }, {
      id: 2,
      characteristic_en: 'Ball control with feet',
      characteristic_pt: 'Domínio bola c/ pés',
      characteristic_es: 'Dominio balón con los pies',
      num: 14
    }, {
      id: 3,
      characteristic_en: 'Aerial ball control',
      characteristic_pt: 'Jogo aéreo',
      characteristic_es: 'Juego Aéreo',
      num: 19
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Space control',
      characteristic_pt: 'Colocação no campo',
      characteristic_es: 'Colocación',
      num: 6
    }, {
      id: 2,
      characteristic_en: 'Communication skills',
      characteristic_pt: '',
      characteristic_es: 'Comuniación',
      num: 7
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Leader',
      characteristic_pt: 'Líder',
      characteristic_es: 'Líder',
      num: 23
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Agility/Flexibility',
      characteristic_pt: 'Agilidade/Flexibilidade',
      characteristic_es: 'Agilidad/Flexibilidad',
      num: 15
    }, {
      id: 2,
      characteristic_en: 'Reflexes',
      characteristic_pt: 'Reflexos',
      characteristic_es: 'Reflejos',
      num: 29
    }],
    positions: [{
      id: 0,
      selected: true,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'REAL BETIS',
      country: 'España',
      nameSecondRow_pt: '',
      years_es: '2019 - Actualidad',
      years_en: '2019 - Currently',
      image: require('../images/clubs/trayectoria/real-betis-balompie-espana-futbol-femenino.png'),
      alt: 'real-betis-balompie-espana-futbol-femenino'
    }, {
      id: 2,
      team: 'MONTPELLIER HSC',
      country: 'Francia',
      nameSecondRow_pt: '',
      years_es: '2017 - 2019',
      years_en: '2017 - 2019',
      image: require('../images/clubs/trayectoria/montpellier-hsc-francia-futbol-femenino.png'),
      alt: 'montpellier-hsc-francia-futbol-femenino'
    }, {
      id: 3,
      team: 'OLYMPIQUE DE LYON',
      country: 'Francia',
      nameSecondRow_pt: '',
      years_es: '2014 - 2017',
      years_en: '2014 - 2017',
      image: require('../images/clubs/trayectoria/olympique-lyonnais-francia-futbol-femenin..png'),
      alt: 'olympique-lyonnais-francia-futbol-femenin'
    }, {
      id: 4,
      team: 'AS SAINT-ÈTIENNE',
      country: 'Francia',
      nameSecondRow_pt: '',
      years_es: '2010 - 2014',
      years_en: '2010 - 2014',
      image: require('../images/clubs/trayectoria/saint-etienne-francia-futbol-femenino.png'),
      alt: 'saint-etienne-francia-futbol-femenino'
    }, {
      id: 5,
      team: 'AS MONTIGNY',
      country: 'Francia',
      nameSecondRow_pt: '',
      years_es: '2008 - 2010',
      years_en: '2008 - 2010',
      image: require('../images/clubs/trayectoria/as-montigny-les-metz-francia-futbol-femenino.png'),
      alt: 'as-montigny-les-metz-francia-futbol-femenino'
    }, {
      id: 6,
      team: 'PSG',
      country: 'Francia',
      nameSecondRow_pt: '',
      years_es: '2007 - 2008',
      years_en: '2007 - 2008',
      image: require('../images/clubs/trayectoria/paris-saint-germain-francia-futbol-femenino.png'),
      alt: 'paris-saint-germain-francia-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'melinegerard',
    instagramId: '3858730297',
    instagram: 'https://www.instagram.com/melinegerard/',
    twitter: 'https://twitter.com/MelineGerard',
    facebook: 'https://www.facebook.com/M%C3%A9line-Gerard-1743955399158887/',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'RG INTERNATIONAL',
      url: 'https://www.instagram.com/p/B0ycJzHHQiz/',
      image: require('../images/players/meline-gerard/last-campaigns/campana-meline-gerard-rg-goalkeeper-gloves.jpg'),
      alt: 'campana-meline-gerard-rg-goalkeeper-gloves'
    }, {
      id: 2,
      brand: 'PUMA',
      url: '',
      image: require('../images/players/meline-gerard/last-campaigns/campana-meline-gerard-puma.jpg'),
      alt: 'campana-meline-gerard-puma'
    }],
    press: [{
      id: 1,
      media: 'ESTADIO DEPORTIVO',
      url: 'https://www.estadiodeportivo.com/noticias-futbol/futbol-femenino/2020/05/06/meline-gerard-betis-he-recuperado/277687.html',
      image: require('../images/players/meline-gerard/press/Meline-Gerard-prensa-estadio-deportivo.jpg'),
      alt: 'Meline-Gerard-prensa-estadio-deportivo'
    }, {
      id: 2,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2020/05/07/femenino/1588858506_098828.html',
      image: require('../images/players/meline-gerard/press/Meline-Gerard-prensa-diario-AS.jpg'),
      alt: 'Meline-Gerard-prensa-diario-AS'
    }, {
      id: 3,
      media: 'ESTADIO DEPORTIVO',
      url: 'https://www.estadiodeportivo.com/noticias-futbol/futbol-femenino/2020/02/13/noche-meline-gerard/266200.html',
      image: require('../images/players/meline-gerard/press/Meline-Gerard-prensa-estadio-deportivo-2.jpg'),
      alt: 'Meline-Gerard-prensa-estadio-deportivo-2'
    }, {
      id: 4,
      media: 'BESOCCER',
      url: 'https://es.besoccer.com/noticia/meline-gerard-ficha-por-el-betis-677440',
      image: require('../images/players/meline-gerard/press/Meline-Gerard-prensa-Besoccer.jpg'),
      alt: 'Meline-Gerard-prensa-Besoccer'
    }, {
      id: 5,
      media: 'EL DESMARQUE',
      url: 'https://eldesmarque.com/sevilla/primera-iberdrola/1164350-meline-gerard-nueva-jugadora-del-betis-feminas',
      image: require('../images/players/meline-gerard/press/Meline-Gerard-prensa-El-desmarque.jpg'),
      alt: 'Meline-Gerard-prensa-El-desmarque'
    }, {
      id: 6,
      media: 'CANAL DEL SUR',
      url: 'http://www.canalsur.es/noticias/deportes/el-betis-ficha-por-dos-anos-a-la-portera-internacional-francesa-meline-gerard/1460831.html',
      image: require('../images/players/meline-gerard/press/Meline-Gerard-prensa-canal-sur.jpg'),
      alt: 'Meline-Gerard-prensa-canal-sur'
    }, {
      id: 7,
      media: 'ESTADIO DEPORTIVO',
      url: 'https://www.estadiodeportivo.com/noticias-futbol/futbol-femenino/2020/01/13/meline-gerard-echa-cerrojo/260908.html',
      image: require('../images/players/meline-gerard/press/Meline-Gerard-prensa-estadio-deportivo-3.jpg'),
      alt: 'Meline-Gerard-prensa-estadio-deportivo-3'
    }, {
      id: 8,
      media: 'SPORT',
      url: 'https://www.sport.es/es/noticias/futbol-femenino/portera-francesa-meline-gerard-firma-por-dos-temporadas-con-betis-7566001',
      image: require('../images/players/meline-gerard/press/Meline-Gerard-prensa-sport.jpg'),
      alt: 'Meline-Gerard-prensa-sport'
    }, {
      id: 9,
      media: 'COEUR DE FOOT',
      url: 'https://coeursdefoot.fr/event/meline-gerard-betis-seville-pourquoi-pas-entra%C3%AEner-a-l-ol-un-jour-/',
      image: require('../images/players/meline-gerard/press/Meline-Gerard-prensa-Coeurs-de-foot.jpg'),
      alt: 'Meline-Gerard-prensa-Coeurs-de-foot'
    }, {
      id: 10,
      media: 'COEUR DE FOOT',
      url: 'https://www.coeursdefoot.fr/event/meline-gerard-betis-seville-c-est-une-avancee-vraiment-enorme-pour-le-foot-feminin-en-espagne-la-convention-collective-/',
      image: require('../images/players/meline-gerard/press/Meline-Gerard-prensa-Coeurs-de-foot-2.jpg'),
      alt: 'Meline-Gerard-prensa-Coeurs-de-foot-2'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },*/ {
    id: 30,
    title: 'UNIK TEAM | Miriam Rodríguez “Kuki” | Jugadora de fútbol femenino',
    metadescription: 'Miriam Rodríguez “Kuki”, futbolista española, actualmente juega como delantera en el SD Eibar del Reto Iberdrola.',
    url: require('../images/players-profile-photo/miriam-kuki-sd-eibar.png'),
    alt: 'miriam-kuki-sd-eibar',
    name: 'MIRIAM R. “KUKI”',
    nameUrl: 'miriam-rodriguez-kuki',
    team: 'SD Eibar',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : 'DELANTERA',
    mainPosition_en: 'FORWARD',
    mainPosition_pt: 'ATACANTE',
    birthDate: '18 - 10 - 1994',
    birthYear: '1994',
    town: 'Tal. de la Reina',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,59',
    weight: '54',
    signaturePhoto: require('../images/players/miriam-rodriguez/jugadora-miriam-kuki-unikteam.png'),
    signaturePhotoAlt: 'jugadora-miriam-kuki-unikteam',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/miriam-rodriguez/header/miriam-rodriguez-kuki-sd-eibar-femenino.jpg'),
      alt: 'miriam-rodriguez-kuki-sd-eibar-femenino'
    }, {
      id: 2,
      url: require('../images/players/miriam-rodriguez/header/miriam-rodriguez-kuki-sd-eibar-futbol-femenino.jpg'),
      alt: 'miriam-rodriguez-kuki-sd-eibar-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/miriam-rodriguez/header-mobile/miriam-rodriguez-kuki-sd-eibar.jpg'),
    mobileHeaderPhotoAlt: 'miriam-rodriguez-kuki-sd-eibar',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Subcampeona del ',
      nameFirstRow_en: 'Runner-up of',
      nameFirstRow_pt: 'Vice-campeã do',
      nameSecondRow_es: 'Grupo Norte de Segunda División y Ascenso a Primera',
      nameSecondRow_en: 'Group North of the Spanish Second Division and Promotion to the First Division',
      nameSecondRow_pt: 'Grupo Norte da Segunda Divisão e Subida à Primeira',
      team_es: 'SD Eibar',
      team_en: 'SD Eibar',
      team_pt: 'SD Eibar',
      years_es: '2019 - 2020',
      years_en: '2019 - 2020',
      years_pt: '2019 - 2020'
    }, {
      id: 2,
      nameFirstRow_es: 'Copa de España',
      nameFirstRow_en: 'Spanish Cup',
      nameFirstRow_pt: 'Copa da Espanha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2016',
      years_en: '2016',
      years_pt: '2016'
    }, {
      id: 3,
      nameFirstRow_es: 'Subcampeonato de España',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'de Sel. Autonómicas Sub-15',
      nameSecondRow_en: 'U-15 Spanish Championship',
      nameSecondRow_pt: 'Espanha de Seleções Autônomas Sub-15',
      team_es: 'Selección Madrileña',
      team_en: 'Madrid Autonomus Team',
      team_pt: 'Seleção Madrilenha',
      years_es: '',
      years_en: '',
      years_pt: ''
    }],
    internationalTournament: [],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Segunda Mejor Jugadora',
      nameFirstRow_en: 'Second Best Player',
      nameFirstRow_pt: 'Segunda Melhor Jogadora',
      nameSecondRow_es: 'Castilla La Mancha',
      nameSecondRow_en: 'Castilla La Mancha',
      nameSecondRow_pt: 'Castilla La Mancha',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Assists',
      characteristic_pt: 'Assistências',
      characteristic_es: 'Asistencias',
      num: 2
    }, {
      id: 2,
      characteristic_en: 'Goals',
      characteristic_pt: 'Gol',
      characteristic_es: 'Gol',
      num: 18
    }, {
      id: 3,
      characteristic_en: 'Ball control',
      characteristic_pt: 'Controle de bola',
      characteristic_es: 'Control de balón',
      num: 9
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }, {
      id: 2,
      characteristic_en: 'Vision',
      characteristic_pt: 'Visão de jogo',
      characteristic_es: 'Visión de juego',
      num: 34
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Agility/Flexibility',
      characteristic_pt: 'Agilidade/Flexibilidade',
      characteristic_es: 'Agilidad/Flexibilidad',
      num: 15
    }, {
      id: 2,
      characteristic_en: 'Speed',
      characteristic_pt: 'Velocidade',
      characteristic_es: 'Velocidad',
      num: 33
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: true,
      position: 'MP'
    },{
      id: 11,
      selected: true,
      position: 'EXTi'
    },{
      id: 12,
      selected: true,
      position: 'EXTd'
    },{
      id: 13,
      selected: true,
      position: 'SDC'
    },{
      id: 14,
      selected: true,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'SD EIBAR',
      country: 'España',
      years_es: '2019 - Actualidad',
      years_en: '2019 - Currently',
      years_pt: '2019 - Presente',
      image: require('../images/clubs/trayectoria/sd-eibar-espana-futbol-femenino.png'),
      alt: 'sd-eibar-espana-futbol-femenino'
    }, {
      id: 2,
      team: 'FUNDACIÓN ALBACETE',
      country: 'España',
      years_es: '2017 - 2019',
      years_en: '2017 - 2019',
      years_pt: '2017 - 2019',
      image: require('../images/clubs/trayectoria/albacete-balompie-espana-futbol-femenino.png'),
      alt: 'albacete-balompie-espana-futbol-femenino'
    }, {
      id: 3,
      team: 'REAL BETIS',
      country: 'España',
      years_es: '2016 - 2017',
      years_en: '2016 - 2017',
      years_pt: '2016 - 2017',
      image: require('../images/clubs/trayectoria/real-betis-balompie-espana-futbol-femenino.png'),
      alt: 'real-betis-balompie-espana-futbol-femenino'
    }, {
      id: 4,
      team: 'ATLÉTICO DE MADRID',
      country: 'España',
      years_es: '2014 - 2016',
      years_en: '2014 - 2016',
      years_pt: '2014 - 2016',
      image: require('../images/clubs/trayectoria/atletico-de-madrid-espana-futbol-femenino.png'),
      alt: 'atletico-de-madrid-espana-futbol-femenino'
    }, {
      id: 5,
      team: 'ATLÉTICO DE MADRID B',
      country: 'España',
      years_es: '2012 - 2014',
      years_en: '2012 - 2014',
      years_pt: '2012 - 2014',
      image: require('../images/clubs/trayectoria/atletico-de-madrid-espana-futbol-femenino.png'),
      alt: 'atletico-de-madrid-espana-futbol-femenino'
    }, {
      id: 6,
      team: 'RAYO VALLECANO B',
      country: 'España',
      years_es: '2011 - 2012',
      years_en: '2011 - 2012',
      years_pt: '2011 - 2012',
      image: require('../images/clubs/trayectoria/rayo-vallecano-de-madrid-espana-futbol-femenino.png'),
      alt: 'rayo-vallecano-de-madrid-espana-futbol-femenino'
    }, {
      id: 7,
      team: 'REAL VALLADOLID',
      country: 'España',
      years_es: '2010 - 2011',
      years_en: '2010 - 2011',
      years_pt: '2010 - 2011',
      image: require('../images/clubs/trayectoria/real-valladolid-espana-futbol-femenino.png'),
      alt: 'real-valladolid-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'miriam.kuki7',
    instagramId: '656013597',
    instagram: 'https://www.instagram.com/miriam.kuki7',
    twitter: 'https://twitter.com/rkuki7',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'FUNDACIÓN VITAL',
      url: '',
      image: require('../images/players/miriam-rodriguez/last-campaigns/campana-miriam-kuki-fundación-vital.jpg'),
      alt: 'campana-miriam-kuki-fundación-vital'
    }, {
      id: 2,
      brand: 'COSMOPOLITAN',
      url: '',
      image: require('../images/players/miriam-rodriguez/last-campaigns/campana-miriam-kuki-cosmopolitan.jpg'),
      alt: 'campana-miriam-kuki-cosmopolitan'
    }, {
      id: 3,
      brand: 'HERBALIFE',
      url: 'https://www.instagram.com/p/BFgYt01uDuI/',
      image: require('../images/players/miriam-rodriguez/last-campaigns/campana-miriam-kuki-herbalife.jpg'),
      alt: 'campana-miriam-kuki-herbalife'
    }],
    press: [{
      id: 1,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/2017/10/10/59da34c622601d48778b4599.html',
      image: require('../images/players/miriam-rodriguez/press/miriam-rodriguez-kuki-prensa-marca.jpg'),
      alt: 'miriam-rodriguez-kuki-prensa-marca'
    }, {
      id: 2,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/2016/02/23/56cb015b22601d05328b457e.html',
      image: require('../images/players/miriam-rodriguez/press/miriam-rodriguez-kuki-prensa-marca-2.png.jpg'),
      alt: 'miriam-rodriguez-kuki-prensa-marca-2'
    }, {
      id: 3,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2018/04/29/mas_futbol/1525005344_576002.html',
      image: require('../images/players/miriam-rodriguez/press/miriam-rodriguez-kuki-prensa-diario-as.jpg'),
      alt: 'miriam-rodriguez-kuki-prensa-diario-as'
    }, {
      id: 4,
      media: 'AUPA ALBA',
      url: 'http://www.aupaalba.es/2019/02/08/elena-de-toro-y-kuki-regresan-a-la-convocatoria-del-fundacion-albacete-para-viajar-a-huelva/',
      image: require('../images/players/miriam-rodriguez/press/miriam-rodriguez-kuki-prensa-aupa-alba.jpg'),
      alt: 'miriam-rodriguez-kuki-prensa-aupa-alba'
    }, {
      id: 5,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/2017/10/01/59d0c2d4268e3e41028b4632.html',
      image: require('../images/players/miriam-rodriguez/press/miriam-rodriguez-kuki-prensa-marca-3.jpg'),
      alt: 'miriam-rodriguez-kuki-prensa-marca-3'
    }, {
      id: 6,
      media: 'LOS OTROS 18',
      url: 'https://www.losotros18.com/futfem/alba-redondo-kuki-lanzan-al-albacete/',
      image: require('../images/players/miriam-rodriguez/press/miriam-rodriguez-kuki-prensa-los-otros18.jpg'),
      alt: 'miriam-rodriguez-kuki-prensa-los-otros18'
    }, {
      id: 7,
      media: 'AUPA ALBA',
      url: 'http://www.aupaalba.es/2017/08/01/fundacion-albacete-nexus-presenta-siete-fichajes/',
      image: require('../images/players/miriam-rodriguez/press/miriam-rodriguez-kuki-prensa-aupa-alba-2.jpg'),
      alt: 'miriam-rodriguez-kuki-prensa-aupa-alba-2'
    }, {
      id: 8,
      media: 'LOS OTROS 18',
      url: 'https://www.losotros18.com/futfem/los-fichajes-mas-destacados-del-verano/',
      image: require('../images/players/miriam-rodriguez/press/miriam-rodriguez-kuki-prensa-los-otros18_2.jpg'),
      alt: 'miriam-rodriguez-kuki-prensa-los-otros18_2'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },/* {
    id: 31,
    title: '',
    metadescription: '',
    url: require('../images/players-profile-photo/naiara-beristain-valencia-cf.png'),
    alt: '',
    name: 'NAIARA BERISTAIN',
    nameUrl: 'naiara-beristain',
    team: 'Valencia CF',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : '',
    mainPosition_en: '',
    mainPosition_pt: ' ',
    birthDate: '',
    birthYear: '1900',
    town: '',
    country: '',
    nationality_es : 'Española',
    nationality_en : '',
    nationality_pt: '',
    height: '',
    weight: '',
    signaturePhoto: '',
    signaturePhotoAlt: '',
    headerPhotos: [],
    mobileHeaderPhoto: '',
    mobileHeaderPhotoAlt: '',
    nationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: '',
      team_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    internationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: '',
      team_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    individualAwards: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    skilledLeg_es: '',
    skilledLeg_en: '',
    skilledLeg_pt: '',
    skilledLegNum: 35,
    technicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    tacticalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    psychologicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    physicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 0,
      team: '',
      country: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: '',
      image: ''
    }],
    instagramAccessToken: '',
    instagramUsername: '',
    instagramId: '',
    instagram: '',
    twitter: '',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 0,
      brand: '',
      url: '',
      image: '',
    }],
    press: [{
      id: 0,
      media: '',
      url: '',
      image: '',
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 32,
    title: '',
    metadescription: '',
    url: require('../images/players-profile-photo/test.png'),
    alt: '',
    name: 'NATAŠA ANDONOVA',
    nameUrl: 'natasa-andonova',
    team: 'Levante UD',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : '',
    mainPosition_en: '',
    mainPosition_pt: ' ',
    birthDate: '',
    birthYear: '1900',
    town: '',
    country: '',
    nationality_es : 'Macedonia',
    nationality_en : '',
    nationality_pt: '',
    height: '',
    weight: '',
    signaturePhoto: '',
    signaturePhotoAlt: '',
    headerPhotos: [],
    mobileHeaderPhoto: '',
    mobileHeaderPhotoAlt: '',
    nationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: '',
      team_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    internationalTournament: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      team_es: '',
      team_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    individualAwards: [{
      id: 0,
      nameFirstRow_es: '',
      nameFirstRow_en: '',
      nameFirstRow_pt: '',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: ''
    }],
    skilledLeg_es: '',
    skilledLeg_en: '',
    skilledLeg_pt: '',
    skilledLegNum: 35,
    technicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    tacticalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    psychologicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    physicalCharacteristics: [{
      id: 0,
      characteristic_en: '',
      characteristic_pt: '',
      characteristic_es: '',
      num: 0
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 0,
      team: '',
      country: '',
      nameSecondRow_pt: '',
      years_es: '',
      years_en: '',
      image: ''
    }],
    instagramAccessToken: '',
    instagramUsername: '',
    instagramId: '',
    instagram: '',
    twitter: '',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 0,
      brand: '',
      url: '',
      image: '',
    }],
    press: [{
      id: 0,
      media: '',
      url: '',
      image: '',
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },*/{
    id: 33,
    title: 'UNIK TEAM | Nayeli Rangel | Jugadora de fútbol femenino',
    metadescription: 'Nayeli Rangel, futbolista mexicana, actualmente juega como centrocampista en el Tigres UANL de la Liga MX Femenil y en la Selección Mexicana.',
    url: require('../images/players-profile-photo/nayeli-rangel-tigres-uanl.png'),
    alt: 'nayeli-rangel-tigres-uanl',
    name: 'NAYELI RANGEL',
    nameUrl: 'nayeli-rangel',
    team: 'Tigres UANL',
    league_es: 'Liga MX Femenil (México)',
    league_en: 'Liga MX Femenil (Mexico)',
    league_pt: 'Liga MX Femenil (México)',
    selection_es: 'SEL. MEXICANA',
    selection_en: 'MEXICAN TEAM',
    selection_pt: '',
    mainPosition_es : 'CENTROCAMPISTA',
    mainPosition_en: 'MIDFIELDER',
    mainPosition_pt: 'MEIO-CAMPISTA',
    birthDate: '28 - 02 - 1992',
    birthYear: '1992',
    town: 'Monterrey',
    country: '',
    nationality_es : 'Mexicana',
    nationality_en : 'Mexican',
    nationality_pt: 'Mexicana',
    height: '1,72',
    weight: '67',
    signaturePhoto: require('../images/players/nayeli-rangel/jugadora-nayeli-rangel-unik-team.png'),
    signaturePhotoAlt: 'jugadora-nayeli-rangel-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/nayeli-rangel/header/nayeli-rangel-mexico-tigres-uanl-femenino.jpg'),
      alt: 'nayeli-rangel-mexico-tigres-uanl-femenino'
    }, {
      id: 2,
      url: require('../images/players/nayeli-rangel/header/nayeli-rangel-mexico-tigres-uanl-futbol-femenino_.jpg'),
      alt: 'nayeli-rangel-mexico-tigres-uanl-futbol-femenino'
    }, {
      id: 3,
      url: require('../images/players/nayeli-rangel/header/nayeli-rangel-tigres-uanl-futbol-femenino-mexico.jpg'),
      alt: 'nayeli-rangel-tigres-uanl-futbol-femenino-mexico'
    }],
    mobileHeaderPhoto: require('../images/players/nayeli-rangel/header-mobile/nayeli-rangel-seleccion-mexicana.jpg'),
    mobileHeaderPhotoAlt: 'nayeli-rangel-seleccion-mexicana',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera División Mexicana',
      nameFirstRow_en: 'Mexican First Division',
      nameFirstRow_pt: 'Primeira Divisão Mexicana',
      nameSecondRow_es: '"Liga MX Femenil"',
      nameSecondRow_en: '"Liga MX Femenil"',
      nameSecondRow_pt: '"Liga MX Femenil"',
      team_es: 'Tigres UANL',
      team_en: 'Tigres UANL',
      team_pt: 'Tigres UANL',
      years_es: '2020',
      years_en: '2020',
      years_pt: '2020'
    },{
      id: 2,
      nameFirstRow_es: 'Primera División Mexicana',
      nameFirstRow_en: 'Mexican First Division',
      nameFirstRow_pt: 'Primeira Divisão Mexicana',
      nameSecondRow_es: '"Liga MX Femenil"',
      nameSecondRow_en: '"Liga MX Femenil"',
      nameSecondRow_pt: '"Liga MX Femenil"',
      team_es: 'Tigres UANL',
      team_en: 'Tigres UANL',
      team_pt: 'Tigres UANL',
      years_es: '2019',
      years_en: '2019',
      years_pt: '2019'
    }, {
      id: 3,
      nameFirstRow_es: 'Primera División Mexicana',
      nameFirstRow_en: 'Mexican First Division',
      nameFirstRow_pt: 'Primeira Divisão Mexicana',
      nameSecondRow_es: '"Liga MX Femenil"',
      nameSecondRow_en: '"Liga MX Femenil"',
      nameSecondRow_pt: '"Liga MX Femenil"',
      team_es: 'Tigres UANL',
      team_en: 'Tigres UANL',
      team_pt: 'Tigres UANL',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Pre-mundial CONCACAF Sub-20',
      nameFirstRow_en: 'CONCACAF U-20 Championship',
      nameFirstRow_pt: 'Campeonato feminino da CONCACAF Sub-20',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: '',
      team_en: '',
      team_pt: '',
      years_es: 'Trinidad y Tobago 2018',
      years_en: 'Trinidad y Tobago 2018',
      years_pt: 'Trinidad y Tobago 2018',
    }],
    individualAwards: [{
      id: 0,
      nameFirstRow_es: 'MVP partido vs Colombia',
      nameFirstRow_en: 'MVP of the match',
      nameFirstRow_pt: 'MVP do jogo',
      nameSecondRow_es: '',
      nameSecondRow_en: 'Mexico vs Colombia',
      nameSecondRow_pt: 'México vs Colômbia',
      years_es: 'Mundial 2015',
      years_en: 'World Cup 2015',
      years_pt: 'Copa do Mundo 2015'
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Ball control with feet',
      characteristic_pt: 'Domínio bola c/ pés',
      characteristic_es: 'Control de balón',
      num: 9
    }, {
      id: 2,
      characteristic_en: 'Clearing the ball',
      characteristic_pt: 'Saída de bola',
      characteristic_es: 'Salida de balón',
      num: 32
    }, {
      id: 3,
      characteristic_en: 'Medium range passes',
      characteristic_pt: 'Passe médio',
      characteristic_es: 'Pase medio',
      num: 26
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Defensive player',
      characteristic_pt: 'Jogadora defensiva',
      characteristic_es: 'Criterio defensivo',
      num: 10
    }, {
      id: 2,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura del partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Power',
      characteristic_pt: 'Potência',
      characteristic_es: 'Potencia',
      num: 27
    }, {
      id: 2,
      characteristic_en: 'Strenght',
      characteristic_pt: 'Força',
      characteristic_es: 'Fuerza',
      num: 17
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: true,
      position: 'MC'
    },{
      id: 7,
      selected: true,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: true,
      position: 'INTd'
    },{
      id: 10,
      selected: true,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'Tigres UANL',
      country: 'México',
      years_es: '2017 - Actualidad',
      years_en: '2017 - Currently',
      years_pt: '2017 - Presente',
      image: require('../images/clubs/trayectoria/tigres-uanl-mexico-futbol-femenino.png'),
      alt: 'tigres-uanl-mexico-futbol-femenino'
    }, {
      id: 2,
      team: 'Sporting Club Huelva',
      country: 'España',
      years_es: '2017',
      years_en: '2017',
      years_pt: '2017',
      image: require('../images/clubs/trayectoria/sporting-club-huelva-espana-futbol-femenino.png'),
      alt: 'sporting-club-huelva-espana-futbol-femenino'
    }, {
      id: 3,
      team: 'Sky Blue FC',
      country: 'USA',
      years_es: '2013',
      years_en: '2013',
      years_pt: '2013',
      image: require('../images/clubs/trayectoria/sky-blue-estados-unidos-futbol-femenino.png'),
      alt: 'sky-blue-estados-unidos-futbol-femenino'
    }, {
      id: 4,
      team: 'Tigres UANL',
      country: 'México',
      years_es: '2009 - 2012',
      years_en: '2009 - 2012',
      years_pt: '2009 - 2012',
      image: require('../images/clubs/trayectoria/tigres-uanl-mexico-futbol-femenino.png'),
      alt: 'tigres-uanl-mexico-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'nayerangel',
    instagramId: '21484755',
    instagram: 'https://www.instagram.com/nayerangel/',
    twitter: 'https://twitter.com/nayeRangel7',
    facebook: 'https://www.facebook.com/NayeliRangelOficial7',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'NIKE "JUNTAS IMPARABES',
      url: 'https://www.youtube.com/watch?v=sht5LcpEhPU',
      image: require('../images/players/nayeli-rangel/last-campaigns/campana-nayeli-rangel-nike-unstoppable-together.jpg'),
      alt: 'campana-nayeli-rangel-nike-unstoppable-together'
    }, {
      id: 2,
      brand: 'NIKE "GAME KILLER"',
      url: 'https://www.instagram.com/p/BtWQjotnAS4/',
      image: require('../images/players/nayeli-rangel/last-campaigns/campana-nayeli-rangel-nike-game-killer.jpg'),
      alt: 'campana-nayeli-rangel-nike-game-killer'
    }, {
      id: 3,
      brand: 'INNOVASPORT "MUJER ES PODER"',
      url: 'https://www.instagram.com/p/B34obOcgx2g/',
      image: require('../images/players/nayeli-rangel/last-campaigns/campana-nayeli-rangel-innovasport-mujer-es-poder.jpg'),
      alt: 'campana-nayeli-rangel-innovasport-mujer-es-poder'
    }, {
      id: 4,
      brand: 'AFIRME "FOOTBALL FOR EVERYONE"',
      url: 'https://www.facebook.com/watch/?v=561091481360738',
      image: require('../images/players/nayeli-rangel/last-campaigns/campana-nayeli-rangel-afirme-futbol-para-todos.jpg'),
      alt: 'campana-nayeli-rangel-afirme-futbol-para-todos'
    }, {
      id: 5,
      brand: 'OXXO',
      url: 'https://www.instagram.com/p/B0XMwWiA9LF/',
      image: require('../images/players/nayeli-rangel/last-campaigns/campana-nayeli-rangel-tiendas-oxxo.jpg'),
      alt: 'campana-nayeli-rangel-tiendas-oxxo'
    }, {
      id: 6,
      brand: 'MAVY PALACIO',
      url: 'https://www.instagram.com/p/BeivBb_BI4w/',
      image: require('../images/players/nayeli-rangel/last-campaigns/campana-nayeli-rangel-mavy-palacio.jpg'),
      alt: 'campana-nayeli-rangel-mavy-palacio'
    }],
    press: [{
      id: 1,
      media: 'PUBLIMETRO',
      url: 'https://www.publimetro.com.mx/mx/publisport/2020/04/19/acelera-nayeli-rangel-recuperacion.html',
      image: require('../images/players/nayeli-rangel/press/nayeli-rangel-prensa-publimetro.jpg'),
      alt: 'nayeli-rangel-prensa-publimetro'
    }, {
      id: 2,
      media: 'FOX SPORT',
      url: 'https://www.foxsports.com.mx/mx/article/tigres-femenil-y-las-camisetas-en-apoyo-para-nayeli-rangel_ct3ywv',
      image: require('../images/players/nayeli-rangel/press/nayeli-rangel-prensa-fox-sports.jpg'),
      alt: 'nayeli-rangel-prensa-fox-sports'
    }, {
      id: 3,
      media: 'DIARIO AS',
      url: 'https://mexico.as.com/mexico/2019/03/21/futbol/1553191047_592957.html',
      image: require('../images/players/nayeli-rangel/press/nayeli-rangel-prensa-diario-as.jpg'),
      alt: 'nayeli-rangel-prensa-diario-as'
    }, {
      id: 4,
      media: 'EL MAÑANA',
      url: 'https://www.elmanana.com/videos/deportes/futbol/entrevista-con-nayeli-rangel-y-mariana-cadena-5090338.html',
      image: require('../images/players/nayeli-rangel/press/nayeli-rangel-prensa-el-manana.jpg'),
      alt: 'nayeli-rangel-prensa-el-manana'
    }, {
      id: 5,
      media: 'MARCA',
      url: 'https://www.marca.com/claro-mx/futbol/futbol-femenil/2019/03/21/5c93f45ce5fdeaa9488b45f8.html',
      image: require('../images/players/nayeli-rangel/press/nayeli-rangel-prensa-marca.jpg'),
      alt: 'nayeli-rangel-prensa-marca'
    }, {
      id: 6,
      media: 'DIARIO AS',
      url: 'https://mexico.as.com/mexico/2020/04/14/futbol/1586877088_869357.html',
      image: require('../images/players/nayeli-rangel/press/nayeli-rangel-prensa-diario-as_2.jpg'),
      alt: 'nayeli-rangel-prensa-diario-as_2'
    }, {
      id: 7,
      media: 'MEDIO TIEMPO',
      url: 'https://www.mediotiempo.com/futbol/liga-mx-femenil/nayeli-rangel-rompe-rodilla-perdera-8-meses-tigres-femenil',
      image: require('../images/players/nayeli-rangel/press/nayeli-rangel-prensa-medio-tiempo.jpg'),
      alt: 'nayeli-rangel-prensa-medio-tiempo'
    }, {
      id: 8,
      media: 'MEDIO TIEMPO',
      url: 'https://www.mediotiempo.com/futbol/liga-mx-femenil/dejar-europa-tigres-decision-tomado-nayeli-rangel',
      image: require('../images/players/nayeli-rangel/press/nayeli-rangel-prensa-medio-tiempo_2.jpg'),
      alt: 'nayeli-rangel-prensa-medio-tiempo_2'
    }, {
      id: 9,
      media: 'DIARIO AS',
      url: 'https://mexico.as.com/mexico/2018/05/05/futbol/1525555724_846442.html',
      image: require('../images/players/nayeli-rangel/press/nayeli-rangel-prensa-diario-as_3.jpg'),
      alt: 'nayeli-rangel-prensa-diario-as_3'
    }, {
      id: 10,
      media: 'MARCA',
      url: 'https://www.marca.com/claro-mx/futbol/futbol-femenil/2017/09/17/59be02d946163f4c368b45e0.html',
      image: require('../images/players/nayeli-rangel/press/nayeli-rangel-prensa-marca_2.jpg'),
      alt: 'nayeli-rangel-prensa-marca_2'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 34,
    title: 'UNIK TEAM | Noelia Bermúdez | Jugadora de fútbol femenino',
    metadescription: 'Noelia Bermúdez, futbolista costarricense, actualmente juega como portera en el Deportivo Abanca de la Primera Iberdrola y en la Selección de Costa Rica.',
    url: require('../images/players-profile-photo/noelia-bermudez-deportivo-de-a-coruna.png'),
    alt: 'noelia-bermudez-deportivo-de-a-coruna',
    name: 'NOELIA BERMÚDEZ',
    nameUrl: 'noelia-bermudez',
    team: 'Deportivo Abanca',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: 'SEL. DE COSTA RICA',
    selection_en: 'COSTA RICAN TEAM',
    selection_pt: 'SEL. COSTA RICA',
    mainPosition_es : 'PORTERA',
    mainPosition_en: 'GOALKEEPER',
    mainPosition_pt: 'GOLEIRA',
    birthDate: '20 - 09 - 1994',
    birthYear: '1994',
    town: 'San Carlos',
    country: 'Costa Rica',
    nationality_es : 'Costarricense',
    nationality_en : 'Costa Rican',
    nationality_pt: 'Costarriquenha',
    height: '1,74',
    weight: '65',
    signaturePhoto: require('../images/players/noelia-bermudez/jugadora-noelia-bermudez-unik-team.png'),
    signaturePhotoAlt: 'jugadora-noelia-bermudez-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/noelia-bermudez/header/noelia-bermudez-costa-rica-femenino.jpg'),
      alt: 'noelia-bermudez-costa-rica-femenino'
    }, {
      id: 2,
      url: require('../images/players/noelia-bermudez/header/noelia-bermudez-costa-rica-futbol-femenino.jpg'),
      alt: 'noelia-bermudez-costa-rica-futbol-femenino'
    }, {
      id: 3,
      url: require('../images/players/noelia-bermudez/header/portera-noelia-bermudez-costa-rica-futbol-femenino.jpg'),
      alt: 'portera-noelia-bermudez-costa-rica-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/noelia-bermudez/header-mobile/noelia-bermudez-costa-rica-futbol-femenino.jpg'),
    mobileHeaderPhotoAlt: 'noelia-bermudez-costa-rica-futbol-femenino',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera División de Costa Rica',
      nameFirstRow_en: 'Costa Rican First Division',
      nameFirstRow_pt: 'Primeira Divisão da Costa Rica',
      nameSecondRow_es: '"Torneo de Apertura"',
      nameSecondRow_en: '"Torneo de Apertura"',
      nameSecondRow_pt: '"Torneo de Apertura"',
      team_es: 'Alajuelense FF',
      team_en: 'Alajuelense FF',
      team_pt: 'Alajuelense FF',
      years_es: '2019',
      years_en: '2019',
      years_pt: '2019'
    }, {
      id: 2,
      nameFirstRow_es: 'Primera División de Costa Rica',
      nameFirstRow_en: 'Costa Rican First Division',
      nameFirstRow_pt: 'Primeira Divisão da Costa Rica',
      nameSecondRow_es: '"Torneo de Apertura"',
      nameSecondRow_en: '"Torneo de Apertura"',
      nameSecondRow_pt: '"Torneo de Apertura"',
      team_es: 'Deportivo Saprissa',
      team_en: 'Deportivo Saprissa',
      team_pt: 'Deportivo Saprissa',
      years_es: '2014',
      years_en: '2014',
      years_pt: '2014'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Medalla de bronce en los',
      nameFirstRow_en: 'Bronze medal in the',
      nameFirstRow_pt: 'Medalha de bronze nos',
      nameSecondRow_es: 'Juegos Panamericanos',
      nameSecondRow_en: 'Pan American Games',
      nameSecondRow_pt: 'Jogos Panamericanos',
      team_es: 'Selección de Costa Rica',
      team_en: 'Costarican National Team',
      team_pt: 'Seleção da Costa Rica',
      years_es: 'Peru 2019',
      years_en: 'Peru 2019',
      years_pt: 'Peru 2019'
    }, {
      id: 2,
      nameFirstRow_es: 'Medalla de plata en los',
      nameFirstRow_en: 'Silver medal in the',
      nameFirstRow_pt: 'Medalha de prata nos',
      nameSecondRow_es: 'Juegos Centroamericanos y del Caribe',
      nameSecondRow_en: 'Central American and Caribbean Games',
      nameSecondRow_pt: 'Jogos Centro-Americanos e do Caribe',
      team_es: 'Selección de Costa Rica',
      team_en: 'Costarican National Team',
      team_pt: 'Seleção da Costa Rica',
      years_es: 'Colombia 2018',
      years_en: 'Colombia 2018',
      years_pt: 'Colombia 2018',
    }, {
      id: 3,
      nameFirstRow_es: 'Medalla de plata en el',
      nameFirstRow_en: 'Silver medal in the',
      nameFirstRow_pt: 'Medalha de prata no',
      nameSecondRow_es: 'Premundial CONCACAF',
      nameSecondRow_en: 'CONCACAF World Cup qualifying',
      nameSecondRow_pt: 'Campeonato Feminino da CONCACAF',
      team_es: 'Selección de Costa Rica',
      team_en: 'Costarican National Team',
      team_pt: 'Seleção da Costa Rica',
      years_es: 'Estados Unidos 2014',
      years_en: 'United States 2014',
      years_pt: 'Estados Unidos 2014',
    }, {
      id: 4,
      nameFirstRow_es: 'Medalla de bronce en los',
      nameFirstRow_en: 'Bronze medal in the',
      nameFirstRow_pt: 'Medalha de bronze nos',
      nameSecondRow_es: 'Juegos Centroamericanos y del Caribe',
      nameSecondRow_en: 'Central American and Caribbean Games',
      nameSecondRow_pt: 'Jogos Centro-Americanos e do Caribe',
      team_es: 'Selección de Costa Rica',
      team_en: 'Costarican de Costa Rica',
      team_pt: 'Seleção da Costa Rica',
      years_es: 'México 2014',
      years_en: 'México 2014',
      years_pt: 'México 2014',
    }, {
      id: 5,
      nameFirstRow_es: 'Medalla de bronce en el',
      nameFirstRow_en: 'Bronze medal in the',
      nameFirstRow_pt: 'Medalha de bronze no',
      nameSecondRow_es: 'Premundial CONCACAF Sub-20',
      nameSecondRow_en: 'U-20 CONCACAF World Cup qualifying',
      nameSecondRow_pt: 'Campeonato Feminino da CONCACAF Sub-20',
      team_es: 'Selección de Costa Rica',
      team_en: 'Costarican National Team',
      team_pt: 'Seleção da Costa Rica',
      years_es: 'Islas Caimán 2014',
      years_en: 'Cayman Islands 2014',
      years_pt: 'Ilhas Caimán 2014',
    }, {
      id: 6,
      nameFirstRow_es: 'Copa Interclubes de la',
      nameFirstRow_en: 'UNCAF Interclub Championship',
      nameFirstRow_pt: 'Copa Interclubes da',
      nameSecondRow_es: 'UNCAF',
      nameSecondRow_en: '',
      nameSecondRow_pt: 'UNCAF',
      team_es: 'Deportivo Saprissa',
      team_en: 'Deportivo Saprissa',
      team_pt: 'Deportivo Saprissa',
      years_es: '2014',
      years_en: '2014',
      years_pt: '2014',
    }, {
      id: 7,
      nameFirstRow_es: 'Copa Interclubes de la',
      nameFirstRow_en: 'U-20 UNCAF Interclub Championship',
      nameFirstRow_pt: 'Copa Interclubes da',
      nameSecondRow_es: 'UNCAF Sub-20',
      nameSecondRow_en: '',
      nameSecondRow_pt: 'UNCAF Sub-20',
      team_es: 'Deportivo Saprissa',
      team_en: 'Deportivo Saprissa',
      team_pt: 'Deportivo Saprissa',
      years_es: '2013',
      years_en: '2013',
      years_pt: '2013'
    }],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Mejor Portera',
      nameFirstRow_en: 'Best Goalkeeper',
      nameFirstRow_pt: 'Mejor Goleira',
      nameSecondRow_es: 'Primera División Española "Primera Iberdrola"',
      nameSecondRow_en: 'Spanish First Division "Primera Iberdrola"',
      nameSecondRow_pt: 'Primeira Divisão Espanhola "Primera Iberdrola"',
      years_es: '2016',
      years_en: '2016',
      years_pt: '2016'
    }, {
      id: 2,
      nameFirstRow_es: 'Mejor Portera de',
      nameFirstRow_en: "Costa Rica's",
      nameFirstRow_pt: 'Mejor Goleira da',
      nameSecondRow_es: 'Costa Rica',
      nameSecondRow_en: 'Best Goalkeeper',
      nameSecondRow_pt: 'Costa Rica',
      years_es: '2012 y 2013',
      years_en: '2012 and 2013',
      years_pt: '2012 e 2013'
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Aerial ball control',
      characteristic_pt: 'Jogo aéreo',
      characteristic_es: 'Juego Aéreo',
      num: 19
    }, {
      id: 2,
      characteristic_en: 'Ball control with feet',
      characteristic_pt: 'Domínio bola c/ pés',
      characteristic_es: 'Dominio balón con los pies',
      num: 14
    }, {
      id: 3,
      characteristic_en: 'Blocking',
      characteristic_pt: 'Agarrar',
      characteristic_es: 'Blocaje',
      num: 4
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Space control',
      characteristic_pt: 'Colocação no campo',
      characteristic_es: 'Colocación',
      num: 6
    }, {
      id: 2,
      characteristic_en: 'Communication skills',
      characteristic_pt: 'Comunicação',
      characteristic_es: 'Comunicación',
      num: 7
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Leader',
      characteristic_pt: 'Líder',
      characteristic_es: 'Líder',
      num: 23
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Agility/Flexibility',
      characteristic_pt: 'Agilidade/Flexibilidade',
      characteristic_es: 'Agilidad/Flexibilidad',
      num: 15
    }, {
      id: 2,
      characteristic_en: 'Reflexes',
      characteristic_pt: 'Reflexos',
      characteristic_es: 'Reflejos',
      num: 29
    }],
    positions: [{
      id: 0,
      selected: true,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'DEPORTIVO ABANCA',
      country: 'España',
      years_es: '2020 - Actualidad',
      years_en: '2020 - Currently',
      years_pt: '2020 - Presente',
      image: require('../images/clubs/trayectoria/deportivo-la-coruna-espana-futbol-femenino.png'),
      alt: 'deportivo-la-coruna-espana-futbol-femenino'
    }, {
      id: 2,
      team: 'ALAJUENSE FF',
      country: 'Costa Rica',
      years_es: '2019 - 2020',
      years_en: '2019 - 2020',
      years_pt: '2019 - 2020',
      image: require('../images/clubs/trayectoria/alajuelense-ff-costa-rica-futbol-femenino.png'),
      alt: 'alajuelense-ff-costa-rica-futbol-femenino'
    }, {
      id: 3,
      team: 'DEPORTIVO SAPRISSA',
      country: 'Costa Rica',
      years_es: '2019',
      years_en: '2019',
      years_pt: '2019',
      image: require('../images/clubs/trayectoria/deportivo-saprissa-costa-rica-futbol-femenino.png'),
      alt: 'deportivo-saprissa-costa-rica-futbol-femenino'
    }, {
      id: 4,
      team: 'VALENCIA CF',
      country: 'España',
      years_es: '2017 - 2018',
      years_en: '2017 - 2018',
      years_pt: '2017 - 2018',
      image: require('../images/clubs/trayectoria/valencia-cf-espana-futbol-femenino.png'),
      alt: 'valencia-cf-espana-futbol-femenino'
    }, {
      id: 5,
      team: 'LEVANTE UD',
      country: 'España',
      years_es: '2015 - 2017',
      years_en: '2015 - 2017',
      years_pt: '2015 - 2017',
      image: require('../images/clubs/trayectoria/levante-ud-espana-futbol-femenino.png'),
      alt: 'levante-ud-espana-futbol-femenino'
    }, {
      id: 6,
      team: 'DEPORTIVO SAPRISSA',
      country: 'Costa Rica',
      years_es: '2012 - 2015',
      years_en: '2012 - 2015',
      years_pt: '2012 - 2015',
      image: require('../images/clubs/trayectoria/deportivo-saprissa-costa-rica-futbol-femenino.png'),
      alt: 'deportivo-saprissa-costa-rica-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'noebermudez1',
    instagramId: '264638222',
    instagram: 'https://www.instagram.com/noebermudez1/',
    twitter: 'https://twitter.com/noebermudez_13',
    facebook: 'https://www.facebook.com/NoeliaBermudez13/',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'RINAT',
      url: 'https://www.instagram.com/p/B378oZ9gZL9/',
      image: require('../images/players/noelia-bermudez/last-campaigns/campana-noelia-bermudez-rinat-costa-rica.jpg'),
      alt: 'campana-noelia-bermudez-rinat-costa-rica'
    }, {
      id: 2,
      brand: 'DORIVAL-BAYER',
      url: 'https://www.instagram.com/p/B2R0nPTApEO/',
      image: require('../images/players/noelia-bermudez/last-campaigns/campana-noelia-bermudez-dorival-bayer-costa-rica.jpg'),
      alt: 'campana-noelia-bermudez-dorival-bayer-costa-rica'
    }, {
      id: 3,
      brand: 'GOODLIFE ESCAZÚ',
      url: 'https://www.instagram.com/p/Bu2T_4DAS2v/',
      image: require('../images/players/noelia-bermudez/last-campaigns/campana-noelia-bermudez-goodlife-escazu-costa-rica.jpg'),
      alt: 'campana-noelia-bermudez-goodlife-escazu-costa-rica'
    }, {
      id: 4,
      brand: 'P-NUTS',
      url: '',
      image: require('../images/players/noelia-bermudez/last-campaigns/campana-noelia-bermudez-pnuts-costa-rica.jpg'),
      alt: 'campana-noelia-bermudez-pnuts-costa-rica'
    }, {
      id: 5,
      brand: 'KOTEX',
      url: '',
      image: require('../images/players/noelia-bermudez/last-campaigns/campana-noelia-bermudez-kotex-costa-rica.jpg'),
      alt: 'campana-noelia-bermudez-kotex-costa-rica'
    }],
    press: [{
      id: 1,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/primera-division/2020/05/01/5eac515046163f2e938b4606.html',
      image: require('../images/players/noelia-bermudez/press/noelia-bermudez-prensa-marca.jpg'),
      alt: 'noelia-bermudez-prensa-marca'
    }, {
      id: 2,
      media: 'TELETICA',
      url: 'https://www.teletica.com/255778_la-coruna-de-espana-sigue-de-cerca-los-pasos-de-noelia-bermudez',
      image: require('../images/players/noelia-bermudez/press/noelia-bermudez-prensa-teletica.jpg'),
      alt: 'noelia-bermudez-prensa-teletica'
    }, {
      id: 3,
      media: 'LA VOZ DE GALÍCIA',
      url: 'https://www.lavozdegalicia.es/noticia/torremarathon/2020/05/01/costarricense-noelia-bermudez-primer-fichaje-depor-abanca/00031588351729106949432.htm',
      image: require('../images/players/noelia-bermudez/press/noelia-bermudez-prensa-la-voz-de-galicia.jpg'),
      alt: 'noelia-bermudez-prensa-la-voz-de-galicia'
    }, {
      id: 4,
      media: 'RC DEPORTIVO',
      url: 'https://www.rcdeportivo.es/noticia/noelia-bermudez-primer-fichaje-del-depor-abanca-2020-2021',
      image: require('../images/players/noelia-bermudez/press/noelia-bermudez-prensa-rc-deportivo.jpg'),
      alt: 'noelia-bermudez-prensa-rc-deportivo'
    }, {
      id: 5,
      media: 'LA NACIÓN',
      url: 'https://www.nacion.com/puro-deporte/futbol-nacional/video-noelia-bermudez-portera-de-la-liga-hace/ef81e6c1-ec0a-402b-ac7d-3d9b4c08bd2b/video/',
      image: require('../images/players/noelia-bermudez/press/noelia-bermudez-prensa-nacion.jpg'),
      alt: 'noelia-bermudez-prensa-nacion'
    }, {
      id: 6,
      media: 'TVN DEPORTES',
      url: 'https://www.youtube.com/watch?v=3-8Yt8gcITQ&list=PUGZg4aYxKCFEi3EVbre8NZA&index=43',
      image: require('../images/players/noelia-bermudez/press/noelia-bermudez-prensa-tvn-deportes.jpg'),
      alt: 'noelia-bermudez-prensa-tvn-deportes'
    }, {
      id: 7,
      media: 'RIAZOR',
      url: 'https://www.riazor.org/noelia-bermudez-nueva-jugadora-del-depor-abanca/',
      image: require('../images/players/noelia-bermudez/press/noelia-bermudez-prensa-riazor.jpg'),
      alt: 'noelia-bermudez-prensa-riazor'
    }, {
      id: 8,
      media: 'LA VOZ DE GALÍCIA',
      url: 'https://www.lavozdegalicia.es/noticia/torremarathon/2020/04/26/tres-internacionales-opciones-porteria-deportivo-femenino/00031587935822078742322.htm',
      image: require('../images/players/noelia-bermudez/press/noelia-bermudez-prensa-la-voz-de-galicia-2.jpg'),
      alt: 'noelia-bermudez-prensa-la-voz-de-galicia-2'
    }, {
      id: 9,
      media: 'CORREO GALEGO',
      url: 'https://www.elcorreogallego.es/deportes/la-plantilla-del-deportivo-se-prepara-para-afrontar-el-desconfinamiento-AF2777406',
      image: require('../images/players/noelia-bermudez/press/noelia-bermudez-prensa-correo-galego.jpg'),
      alt: 'noelia-bermudez-prensa-correo-galego'
    }, {
      id: 10,
      media: 'CR HOY',
      url: 'https://www.crhoy.com/deportes/noelia-bermudez-una-herida-de-guerra-la-marco-de-por-vida/',
      image: require('../images/players/noelia-bermudez/press/noelia-bermudez-prensa-cr-hoy.jpg'),
      alt: 'noelia-bermudez-prensa-cr-hoy'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 35,
    title: 'UNIK TEAM | Noelia Gil | Jugadora de fútbol femenino',
    metadescription: 'Noelia Gil, futbolista española, actualmente juega como portera en el Valencia CF de la Primera Iberdrola y en la Selección Española Sub-20.',
    url: require('../images/players-profile-photo/noelia-gil-malaga-cf.png'),
    alt: 'noelia-gil-malaga-cf',
    name: 'NOELIA GIL',
    nameUrl: 'noelia-gil',
    team: 'Valencia CF',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : 'PORTERA',
    mainPosition_en: 'GOALKEEPER',
    mainPosition_pt: 'GOLEIRA',
    birthDate: '23 - 05 - 1994',
    birthYear: '1994',
    town: 'Madrid',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,74',
    weight: '65',
    signaturePhoto: require('../images/players/noelia-gil/jugadora-noelia-gil-unik-team.png'),
    signaturePhotoAlt: 'jugadora-noelia-gil-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/noelia-gil/header/noelia-gil-malaga-cff-femenino.jpg'),
      alt: 'noelia-gil-malaga-cff-femenino'
    }, {
      id: 2,
      url: require('../images/players/noelia-gil/header/noelia-gil-malaga-cff-futbol-femenino.jpg'),
      alt: 'noelia-gil-malaga-cff-futbol-femenino'
    }, {
      id: 3,
      url: require('../images/players/noelia-gil/header/portera-noelia-gil-malaga-cff-femenino.jpg'),
      alt: 'portera-noelia-gil-malaga-cff-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/noelia-gil/header-mobile/noelia-gil-malaga-CFF-femenino.jpg'),
    mobileHeaderPhotoAlt: 'noelia-gil-malaga-CFF-femenino',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Copa de España',
      nameFirstRow_en: 'Spanish Cup',
      nameFirstRow_pt: 'Copa da Espanha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2016',
      years_en: '2016',
      years_pt: '2016'
    }, {
      id: 2,
      nameFirstRow_es: 'Dos veces Campeona de España',
      nameFirstRow_en: 'Two-times',
      nameFirstRow_pt: 'Bicampeã do Campeonato Espanhol',
      nameSecondRow_es: 'de Sel. Autonómicas Sub-18',
      nameSecondRow_en: 'U-18 Spanish Champion',
      nameSecondRow_pt: 'de Seleções Autônomas Sub-18',
      team_es: 'Selección Madrileña',
      team_en: 'Madrid Autonomus Team',
      team_pt: 'Seleção Madrilenha',
      years_es: '',
      years_en: '',
      years_pt: ''
    }, {
      id: 3,
      nameFirstRow_es: 'Dos veces Subcampeona de España',
      nameFirstRow_en: 'Two-times runner-up of the',
      nameFirstRow_pt: 'Duas vezes Vice-campeã do Campeonato Espanhol',
      nameSecondRow_es: 'de Sel. Autonómicas Sub-16',
      nameSecondRow_en: 'U-16 Spanish Championship',
      nameSecondRow_pt: 'de Seleções Autônomas Sub-16 ',
      team_es: 'Selección Madrileña',
      team_en: 'Madrid Autonomus Team',
      team_pt: 'Seleção Madrilenha',
      years_es: '',
      years_en: '',
      years_pt: ''
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Eurocopa Sub-17',
      nameFirstRow_en: 'UEFA Euro U-17',
      nameFirstRow_pt: 'Eurocopa Sub-17',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Suiza 2011',
      years_en: 'Switzerland 2011',
      years_pt: 'Suíça 2011'
    }],
    individualAwards: [],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Aerial ball control',
      characteristic_pt: 'Jogo aéreo',
      characteristic_es: 'Juego Aéreo',
      num: 19
    }, {
      id: 2,
      characteristic_en: 'Punt',
      characteristic_pt: 'Espalmar',
      characteristic_es: 'Despeje',
      num: 12
    }, {
      id: 3,
      characteristic_en: 'Blocking',
      characteristic_pt: 'Agarrar',
      characteristic_es: 'Blocaje',
      num: 4
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Space control',
      characteristic_pt: 'Colocação no campo',
      characteristic_es: 'Colocación',
      num: 6
    }, {
      id: 2,
      characteristic_en: 'Communication skills',
      characteristic_pt: 'Comunicação',
      characteristic_es: 'Comunicación',
      num: 7
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Leader',
      characteristic_pt: 'Líder',
      characteristic_es: 'Líder',
      num: 23
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Agility/Flexibility',
      characteristic_pt: 'Agilidade/Flexibilidade',
      characteristic_es: 'Agilidad/Flexibilidad',
      num: 15
    }, {
      id: 2,
      characteristic_en: 'Reflexes',
      characteristic_pt: 'Reflexos',
      characteristic_es: 'Reflejos',
      num: 29
    }],
    positions: [{
      id: 0,
      selected: true,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'VALENCIA CF',
      country: 'España',
      years_es: '2020 - Actualidad',
      years_en: '2020 - Currently',
      years_pt: '2020 - Presente',
      image: require('../images/clubs/trayectoria/valencia-cf-espana-futbol-femenino.png'),
      alt: 'valencia-cf-espana-futbol-femenino'
    },{
      id: 2,
      team: 'MÁLAGA CFF',
      country: 'España',
      years_es: '2019 - 2020',
      years_en: '2019 - 2020',
      years_pt: '2019 - 2020',
      image: require('../images/clubs/trayectoria/malaga-cff-espana-futbol-femenino.png'),
      alt: 'malaga-cff-espana-futbol-femenino'
    }, {
      id: 3,
      team: 'SEVILLA FC',
      country: 'España',
      years_es: '2018 - 2019',
      years_en: '2018 - 2019',
      years_pt: '2018 - 2019',
      image: require('../images/clubs/trayectoria/sevilla-fc-espana-futbol-femenino.png'),
      alt: 'sevilla-fc-espana-futbol-femenino'
    }, {
      id: 4,
      team: 'UD GRANADILLA TENERIFE',
      country: 'España',
      years_es: '2016 - 2018',
      years_en: '2016 - 2018',
      years_pt: '2016 - 2018',
      image: require('../images/clubs/trayectoria/ud-granadilla-tenerife-espana-futbol-femenino.png'),
      alt: 'ud-granadilla-tenerife-espana-futbol-femenino'
    }, {
      id: 5,
      team: 'ATLÉTICO MADRID',
      country: 'España',
      years_es: '2011 - 2016',
      years_en: '2011 - 2016',
      years_pt: '2011 - 2016',
      image: require('../images/clubs/trayectoria/atletico-de-madrid-espana-futbol-femenino.png'),
      alt: 'atletico-de-madrid-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'noeliagil13',
    instagramId: '1225600814',
    instagram: 'https://www.instagram.com/noeliagil13/',
    twitter: 'https://www.twitter.com/Noelia_gil_13',
    facebook: 'https://www.facebook.com/Noelia-Gil-P%C3%A9rez-883548805077992/',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'SOLOPORTEROS',
      url: 'https://www.instagram.com/p/BsQeAReHwUk/',
      image: require('../images/players/noelia-gil/last-campaigns/campana-noelia-gil-solo-porteros.jpg'),
      alt: 'campana-noelia-gil-solo-porteros'
    }],
    press: [{
      id: 1,
      media: 'MALAGA HOY',
      url: 'https://www.malagahoy.es/malaga_cf/Noelia-Gil-portera-Malaga-Femenino_0_1374162969.html',
      image: require('../images/players/noelia-gil/press/noelia-gil-prensa-malaga-hoy.jpg'),
      alt: 'noelia-gil-prensa-malaga-hoy'
    }, {
      id: 2,
      media: 'BESOCCER',
      url: 'https://es.besoccer.com/noticia/dos-nuevos-refuerzos-bajo-palos-para-el-malaga-femenino-674869',
      image: require('../images/players/noelia-gil/press/noelia-gil-prensa-besoccer.jpg'),
      alt: 'noelia-gil-prensa-besoccer'
    }, {
      id: 3,
      media: 'DIARIO SUR',
      url: 'https://www.diariosur.es/malagacf/femenino/parcela-buenas-manos-20200123190955-nt.html?ref=https:%2F%2Fwww.google.com%2F',
      image: require('../images/players/noelia-gil/press/noelia-gil-prensa-diario-sur.jpg'),
      alt: 'noelia-gil-prensa-diario-sur'
    }, {
      id: 4,
      media: 'ESTADIO DEPORTIVO',
      url: 'https://www.estadiodeportivo.com/noticias-futbol/2019/03/06/futbol-emotion-apoya-dia-mujer/213172.html',
      image: require('../images/players/noelia-gil/press/noelia-gil-prensa-estadio-deportivo.jpg'),
      alt: 'noelia-gil-prensa-estadio-deportivo'
    }, {
      id: 5,
      media: 'DIARIO DE SEVILLA',
      url: 'https://www.diariodesevilla.es/deportes/Noelia-Gil-nueva-guardameta-sevillista_0_1210079311.html',
      image: require('../images/players/noelia-gil/press/noelia-gil-prensa-diario-de-sevilla.jpg'),
      alt: 'noelia-gil-prensa-diario-de-sevilla'
    }, {
      id: 6,
      media: 'EL DORSAL',
      url: 'https://eldorsal.com/la-portera-de-la-udg-tenerife-noelia-gil-de-vuelta-a-su-casa-rojiblanca/',
      image: require('../images/players/noelia-gil/press/noelia-gil-prensa-el-dorsal-2.jpg'),
      alt: 'noelia-gil-prensa-el-dorsal-2'
    }, {
      id: 7,
      media: 'ESTADIO DEPORTIVO',
      url: 'https://www.estadiodeportivo.com/estar-al-dia/2019/03/09/futbol-emotion-apoya-dia-mujer/213650.html',
      image: require('../images/players/noelia-gil/press/noelia-gil-prensa-Estadio-deportivo-2.jpg'),
      alt: 'noelia-gil-prensa-Estadio-deportivo-2'
    }, {
      id: 8,
      media: 'EL DORSAL',
      url: 'https://eldorsal.com/noelia-gil-ante-partido-especial-frente-al-atletico-madrid/',
      image: require('../images/players/noelia-gil/press/noelia-gil-prensa-el-dorsal.jpg'),
      alt: 'noelia-gil-prensa-el-dorsal'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 36,
    title: 'UNIK TEAM | Rosa Otermín | Jugadora de fútbol femenino',
    metadescription: 'Rosa Otermín, futbolista española, actualmente juega como defensa en el Real Betis de la Primera Iberdrola y en la Selección Española Sub-20.',
    url: require('../images/players-profile-photo/rosa-otermin-ral-betis-balompie.png'),
    alt: 'rosa-otermin-ral-betis-balompie',
    name: 'ROSA OTERMÍN',
    nameUrl: 'rosa-otermin',
    team: 'Real Betis',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: 'SEL. ESPAÑOLA SUB-20',
    selection_en: 'SPAIN U20',
    selection_pt: 'SEL. ESPANHOLA SUB-20',
    mainPosition_es : 'DEFENSA',
    mainPosition_en: 'DEFENDER',
    mainPosition_pt: 'DEFESA',
    birthDate: '02 - 10 - 2000',
    birthYear: '2000',
    town: 'Alcorcón',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,66',
    weight: '60',
    signaturePhoto: require('../images/players/rosa-otermin/jugadora-rosa-otermin-unik-team.png'),
    signaturePhotoAlt: 'jugadora-rosa-otermin-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/rosa-otermin/header/rosa-otermin-real-betis-femenino.jpg'),
      alt: 'rosa-otermin-real-betis-femenino'
    }, {
      id: 2,
      url: require('../images/players/rosa-otermin/header/rosa-otermin-real-betis-futbol-femenino.jpg'),
      alt: 'rosa-otermin-real-betis-futbol-femenino'
    }, {
      id: 3,
      url: require('../images/players/rosa-otermin/header/rosa-otermin--seleccion-espanola-futbol-femenino.jpg'),
      alt: 'rosa-otermin--seleccion-espanola-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/rosa-otermin/header-mobile/rosa-otermin-real-betis-balompie.jpg'),
    mobileHeaderPhotoAlt: 'rosa-otermin-real-betis-balompie',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2018 - 2019',
      years_en: '2018 - 2019',
      years_pt: '2018 - 2019'
    }, {
      id: 2,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2017 - 2018',
      years_en: '2017 - 2018',
      years_pt: '2017 - 2018'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Eurocopa Sub-19',
      nameFirstRow_en: 'UEFA Euro U-19',
      nameFirstRow_pt: 'Eurocopa Sub-19',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Suiza 2018',
      years_en: 'Switzerland 2018',
      years_pt: 'Suiza 2018'
    }, {
      id: 2,
      nameFirstRow_es: 'Subcampeona de la ',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã de la',
      nameSecondRow_es: 'Eurocopa Sub-17',
      nameSecondRow_en: 'UEFA Euro U-17',
      nameSecondRow_pt: 'Eurocopa Sub-17',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'República Checa 2017',
      years_en: 'Czech Republic 2017',
      years_pt: 'República Checa 2017'
    }],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Jugadora Revelación',
      nameFirstRow_en: 'Revelation Player',
      nameFirstRow_pt: 'Jugadora Revelación',
      nameSecondRow_es: 'Atlético de Madrid B',
      nameSecondRow_en: 'Atlético de Madrid B',
      nameSecondRow_pt: 'Atlético de Madrid B',
      years_es: '2016 - 2017',
      years_en: '2016 - 2017',
      years_pt: '2016 - 2017',
    }, {
      id: 2,
      nameFirstRow_es: 'Máxima Goleadora',
      nameFirstRow_en: 'Top Scorer',
      nameFirstRow_pt: 'Artilheira',
      nameSecondRow_es: 'Atlético de Madrid C',
      nameSecondRow_en: 'Atlético de Madrid C',
      nameSecondRow_pt: 'Atlético de Madrid C',
      years_es: '2015 - 2016',
      years_en: '2015 - 2016',
      years_pt: '2015 - 2016'
    }],
    skilledLeg_es: 'Zurda',
    skilledLeg_en: 'Left-footed',
    skilledLeg_pt: 'Canhota',
    skilledLegNum: 35,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Clearing the ball',
      characteristic_pt: 'Saída de bola',
      characteristic_es: 'Salida de balón',
      num: 32
    }, {
      id: 2,
      characteristic_en: 'Ball control',
      characteristic_pt: 'Controle de bola',
      characteristic_es: 'Control de balón',
      num: 9
    }, {
      id: 3,
      characteristic_en: 'Ball handling',
      characteristic_pt: 'Condução',
      characteristic_es: 'Conducción',
      num: 8
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Skilled passer',
      characteristic_pt: 'Ligação',
      characteristic_es: 'Asociación',
      num: 3
    }, {
      id: 2,
      characteristic_en: 'Defensive player',
      characteristic_pt: 'Jogadora defensiva',
      characteristic_es: 'Criterio defensivo',
      num: 10
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Rational thinking',
      characteristic_pt: 'Racional',
      characteristic_es: 'Racional',
      num: 28
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Speed',
      characteristic_pt: 'Velocidade',
      characteristic_es: 'Velocidad',
      num: 33
    }, {
      id: 1,
      characteristic_en: 'Power',
      characteristic_pt: 'Potência',
      characteristic_es: 'Potencia',
      num: 27
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: true,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: true,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: true,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'REAL BETIS',
      country: 'España',
      years_es: '2019 - Actualidad',
      years_en: '2019 - Currently',
      years_pt: '2019 - Presente',
      image: require('../images/clubs/trayectoria/real-betis-balompie-espana-futbol-femenino.png'),
      alt: 'real-betis-balompie-espana-futbol-femenino'
    }, {
      id: 2,
      team: 'ATLÉTICO DE MADRID',
      country: 'España',
      years_es: '2017 - 2019',
      years_en: '2017 - 2019',
      years_pt: '2017 - 2019',
      image: require('../images/clubs/trayectoria/atletico-de-madrid-espana-futbol-femenino.png'),
      alt: 'atletico-de-madrid-espana-futbol-femenino'
    }, {
      id: 3,
      team: 'ATLÉTICO DE MADRID B',
      country: 'España',
      years_es: '2016 - 2018',
      years_en: '2016 - 2018',
      years_pt: '2016 - 2018',
      image: require('../images/clubs/trayectoria/atletico-de-madrid-espana-futbol-femenino.png'),
      alt: 'atletico-de-madrid-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: '12rosa_',
    instagramId: '1429870623',
    instagram: 'https://www.instagram.com/12rosa_/',
    twitter: 'https://twitter.com/OterminRosa',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'PUMA',
      url: 'https://www.instagram.com/p/B-cvaWbiW4C/',
      image: require('../images/players/rosa-otermin/last-campaigns/campana-rosa-otermin-puma.jpg'),
      alt: 'campana-rosa-otermin-puma'
    }, {
      id: 2,
      brand: 'PUMA',
      url: 'https://www.instagram.com/p/B677GZ7ImaU/',
      image: require('../images/players/rosa-otermin/last-campaigns/campana-rosa-otermin-botas-puma.jpg'),
      alt: 'campana-rosa-otermin-botas-puma'
    }, {
      id: 3,
      brand: 'PULSERAS COLLECTION',
      url: 'https://www.instagram.com/p/B37svsmotoc/',
      image: require('../images/players/rosa-otermin/last-campaigns/campana-rosa-otermin-pulseras-collection.jpg'),
      alt: 'campana-rosa-otermin-pulseras-collection'
    }],
    press: [{
      id: 1,
      media: 'ESTADIO DEPORTIVO',
      url: 'https://www.estadiodeportivo.com/noticias-futbol/futbol-femenino/2020/02/20/rosa-otermin-pier-cien-cien/267336.html',
      image: require('../images/players/rosa-otermin/press/rosa-otermin-prensa-estadio-deportivo.jpg'),
      alt: 'rosa-otermin-prensa-estadio-deportivo'
    }, {
      id: 2,
      media: 'BESOCCER',
      url: 'https://es.besoccer.com/noticia/rosa-otermin-firma-con-el-betis-685535',
      image: require('../images/players/rosa-otermin/press/rosa-otermin-prensa-besoccer.jpg'),
      alt: 'rosa-otermin-prensa-besoccer'
    }, {
      id: 3,
      media: 'ESTADIO DEPORTIVO',
      url: 'https://www.estadiodeportivo.com/noticias-futbol/futbol-femenino/2020/01/09/rosa-otermin-escena/260264.html',
      image: require('../images/players/rosa-otermin/press/rosa-otermin-prensa-estadio-deportivo-2.jpg'),
      alt: 'rosa-otermin-prensa-estadio-deportivo-2'
    }, {
      id: 4,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/2018/07/24/5b48bae6468aeb88778b4609.html',
      image: require('../images/players/rosa-otermin/press/rosa-otermin-prensa-marca.jpg'),
      alt: 'rosa-otermin-prensa-marca'
    }, {
      id: 5,
      media: 'EN CASTILLA LA MANCHA',
      url: 'https://www.encastillalamancha.es/deportes/futbol/rosa-otermin-y-carla-bautista-dos-castellano-manchegas-campeonas-de-europa-de-futbol/',
      image: require('../images/players/rosa-otermin/press/rosa-otermin-prensa-en-clm.jpg'),
      alt: 'rosa-otermin-prensa-en-clm'
    }, {
      id: 6,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2019/08/06/femenino/1565120889_998712.html',
      image: require('../images/players/rosa-otermin/press/rosa-otermin-prensa-diario-as.jpg'),
      alt: 'rosa-otermin-prensa-diario-as'
    }, {
      id: 7,
      media: 'SEFÚTBOL',
      url: 'https://www.sefutbol.com/no-te-pierdas-zurdazo-rosa-otermin-entrenamiento',
      image: require('../images/players/rosa-otermin/press/rosa-otermin-prensa-sefutbol.jpg'),
      alt: 'rosa-otermin-prensa-sefutbol'
    }, {
      id: 8,
      media: 'GRADA 3',
      url: 'https://www.grada3.com/2019/08/06/betis-ficha-rosa-otermin/',
      image: require('../images/players/rosa-otermin/press/rosa-otermin-prensa-grada-3.jpg'),
      alt: 'rosa-otermin-prensa-grada-3'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, /*{
    id: 37,
    title: 'UNIK TEAM | Sarah Palacin | Jugadora de fútbol femenino',
    metadescription: 'Sarah Palacin, futbolista francesa, actualmente juega como delantera en el OGC Nice de la Division 1 Féminine.',
    url: require('../images/players-profile-photo/sarah-palacin-olympique-de-marselle.png'),
    alt: 'sarah-palacin-olympique-de-marselle',
    name: 'SARAH PALACIN',
    nameUrl: 'sarah-palacin',
    team: 'OGC Nice',
    league_es: 'Division 2 Féminine (Francia)',
    league_en: 'Division 2 Féminine (France)',
    league_pt: 'Division 2 Féminine (França)',
    selection_es: 'SEL. FRANCESA',
    selection_en: 'FRENCH TEAM',
    selection_pt: '',
    mainPosition_es : 'DELANTERA',
    mainPosition_en: 'FORWARD',
    mainPosition_pt: 'ATACANTE',
    birthDate: '02 - 09 - 1988',
    birthYear: '1988',
    town: 'Longjumeau',
    country: 'Francia',
    nationality_es : 'Francesa',
    nationality_en : 'French',
    nationality_pt: '',
    height: '1,60',
    weight: '53',
    signaturePhoto: require('../images/players/sarah-palacin/jugadora-sarah-palacin-unikteam.png'),
    signaturePhotoAlt: 'jugadora-sarah-palacin-unikteam',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/sarah-palacin/header/sarah-palacin-olimpique-de-marseille-francia-futbol-femenino.jpg'),
      alt: 'sarah-palacin-olimpique-de-marseille-francia-futbol-femenino'
    }, {
      id: 2,
      url: require('../images/players/sarah-palacin/header/sarah-palacin-psg-francia-futbol-femenino.jpg'),
      alt: 'sarah-palacin-psg-francia-futbol-femenino'
    }, {
      id: 3,
      url: require('../images/players/sarah-palacin/header/sarah-palacin-psg-futbol-femenino.jpg'),
      alt: 'sarah-palacin-psg-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/sarah-palacin/header-mobile/sarah-palacin-futbol-femenino-francia.jpg'),
    mobileHeaderPhotoAlt: 'sarah-palacin-futbol-femenino-francia',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Subcampeona de la Copa de Francia',
      nameFirstRow_en: 'Runner-up of the French Cup',
      nameFirstRow_pt: '',
      nameSecondRow_es: '"Coupe de France"',
      nameSecondRow_en: '"Coupe de France"',
      team_es: '',
      team_en: '',
      nameSecondRow_pt: '',
      years_es: '2013 , 2015 y 2017',
      years_en: '2013, 2015 and 2017'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: '',
      nameSecondRow_es: 'Champions League',
      nameSecondRow_en: 'Champions League',
      team_es: 'Paris Saint-Germain F.C.',
      team_en: 'Paris Saint-Germain F.C.',
      nameSecondRow_pt: '',
      years_es: '2017',
      years_en: '2017'
    }],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Tercera Máxima Goleadora',
      nameFirstRow_en: 'Third Top Scorer',
      nameFirstRow_pt: '',
      nameSecondRow_es: 'Primera División Francesa "Division 1 Féminine"',
      nameSecondRow_en: 'French First Division "Division 1 Féminine"',
      nameSecondRow_pt: '',
      years_es: '2015',
      years_en: '2015'
    }, {
      id: 2,
      nameFirstRow_es: 'Mejor Jugadora',
      nameFirstRow_en: 'Best Player',
      nameFirstRow_pt: '',
      nameSecondRow_es: 'AS Saint-Étienne',
      nameSecondRow_en: 'AS Saint-Étienne',
      nameSecondRow_pt: '',
      years_es: '2015 - 2016',
      years_en: '2015 and 2016'
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Assists',
      characteristic_pt: 'Assistências',
      characteristic_es: 'Asistencias',
      num: 2
    }, {
      id: 2,
      characteristic_en: 'Ball handling',
      characteristic_pt: 'Condução',
      characteristic_es: 'Conducción',
      num: 8
    }, {
      id: 3,
      characteristic_en: 'Medium range passes',
      characteristic_pt: 'Passe médio',
      characteristic_es: 'Pase medio',
      num: 26
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Defensive player',
      characteristic_pt: 'Jogadora defensiva',
      characteristic_es: 'Criterio defensivo',
      num: 10
    }, {
      id: 2,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Power',
      characteristic_pt: 'Potência',
      characteristic_es: 'Potencia',
      num: 27
    }, {
      id: 2,
      characteristic_en: 'Strenght',
      characteristic_pt: 'Força',
      characteristic_es: 'Fuerza',
      num: 17
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: true,
      position: 'MP'
    },{
      id: 11,
      selected: true,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: true,
      position: 'SDC'
    },{
      id: 14,
      selected: true,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'OGC NICE',
      country: 'Francia',
      nameSecondRow_pt: '',
      years_es: '2020 - Actualidad',
      years_en: '2020 - Currently',
      image: require('../images/clubs/trayectoria/ogc-nice-francia-futbol-femenino.png'),
      alt: 'ogc-nice-francia-futbol-femenino'
    },{
      id: 2,
      team: 'OLYMPIQUE DE MARSEILLE',
      country: 'Francia',
      nameSecondRow_pt: '',
      years_es: '2019 - 2020',
      years_en: '2019 - 2020',
      image: require('../images/clubs/trayectoria/olympique-de-marseille.png'),
      alt: 'olympique-de-marseille'
    }, {
      id: 3,
      team: 'FLEURY',
      country: 'Francia',
      nameSecondRow_pt: '',
      years_es: '2017 - 2019',
      years_en: '2017 - 2019',
      image: require('../images/clubs/trayectoria/fleury-francia-futbol-femenino..png'),
      alt: 'fleury-francia-futbol-femenino'
    }, {
      id: 4,
      team: 'PARIS SAINT-GERMAIN F.C.',
      country: 'Francia',
      nameSecondRow_pt: '',
      years_es: '2016 - 2017',
      years_en: '2016 - 2017',
      image: require('../images/clubs/trayectoria/paris-saint-germain-francia-futbol-femenino.png'),
      alt: 'paris-saint-germain-francia-futbol-femenino'
    }, {
      id: 5,
      team: 'AS SAINT-ÉTIENNE',
      country: 'Francia',
      nameSecondRow_pt: '',
      years_es: '2011 - 2016',
      years_en: '2011 - 2016',
      image: require('../images/clubs/trayectoria/saint-etienne-francia-futbol-femenino.png'),
      alt: 'saint-etienne-francia-futbol-femenino'
    }, {
      id: 6,
      team: 'ISSY',
      country: 'Francia',
      nameSecondRow_pt: '',
      years_es: '2008 - 2011',
      years_en: '2008 - 2011',
      image: require('../images/clubs/trayectoria/issy-ff-francia-futbol-femenino.png'),
      alt: 'issy-ff-francia-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'sarahpalacin',
    instagramId: '1693497402',
    instagram: 'https://www.instagram.com/sarahpalacin',
    twitter: 'https://twitter.com/SarahPalacin',
    facebook: 'https://www.facebook.com/sarah.palacin',
    tikTok: '',
    lastsCampaigns: [],
    press: [{
      id: 1,
      media: 'DAILY MOTION',
      url: 'https://www.dailymotion.com/video/x7cby77',
      image: require('../images/players/sarah-palacin/press/sarah-palacin-prensa-daily-motion.jpg'),
      alt: 'sarah-palacin-prensa-daily-motion'
    }, {
      id: 2,
      media: 'LE PHOCEEN',
      url: 'https://www.lephoceen.fr/infos-om/autour-de-l-om/une-recrue-de-plus-et-quatre-prolongations-chez-les-feminines-de-l-om-171130',
      image: require('../images/players/sarah-palacin/press/sarah-palacin-prensa-le-phoceen.jpg'),
      alt: 'sarah-palacin-prensa-le-phoceen'
    }, {
      id: 3,
      media: 'ACTUFOOT',
      url: 'http://www.actufoot.com/06/om-feminines-4-1-ogc-nice-buts-video',
      image: require('../images/players/sarah-palacin/press/sarah-palacin-prensa-actufoot.jpg'),
      alt: 'sarah-palacin-prensa-actufoot'
    }, {
      id: 4,
      media: 'WEEPLAY',
      url: 'https://weeplay.media/retrouvailles-entre-lom-et-la-d1f-24-08-a-lombre/',
      image: require('../images/players/sarah-palacin/press/sarah-palacin-prensa-weeplay.jpg'),
      alt: 'sarah-palacin-prensa-weeplay'
    }, {
      id: 5,
      media: 'GRADA 3',
      url: 'https://www.grada3.com/2019/08/24/francia-su-primera-division-se-transforma/',
      image: require('../images/players/sarah-palacin/press/sarah-palacin-prensa-grada3.jpg'),
      alt: 'sarah-palacin-prensa-grada3'
    }, {
      id: 6,
      media: "L'EQUIPE",
      url: 'https://www.lequipe.fr/Football/Actualites/Psg-sarah-palacin-operee-ce-mercredi/794386',
      image: require('../images/players/sarah-palacin/press/sarah-palacin-prensa-lequipe.jpg'),
      alt: 'sarah-palacin-prensa-lequipe'
    }, {
      id: 7,
      media: "FOOT D'ELLES",
      url: 'https://www.footdelles.com/sarah-palacin-je-voulais-franchir-un-cap/',
      image: require('../images/players/sarah-palacin/press/sarah-palacin-prensa-footdelles.jpg'),
      alt: 'sarah-palacin-prensa-footdelles'
    }, {
      id: 8,
      media: 'DAILY MOTION',
      url: 'https://www.dailymotion.com/video/x4eazia',
      image: require('../images/players/sarah-palacin/press/sarah-palacin-prensa-daily-motion-2.jpg'),
      alt: 'sarah-palacin-prensa-daily-motion-2'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },*/ {
    id: 38,
    title: 'UNIK TEAM | Tatiana Pinto | Jugadora de fútbol femenino',
    metadescription: 'Tatiana Pinto futbolista portuguesa, actualmente juega como centrocampista en el Sporting Clube de Portugal de la Liga BPI y en la Selección Portuguesa.',
    url: require('../images/players-profile-photo/tatiana-pinto-sporting-clube-de-portugal.png'),
    alt: 'tatiana-pinto-sporting-clube-de-portugal',
    name: 'TATIANA PINTO',
    nameUrl: 'tatiana-pinto',
    team: 'Sporting de Lisboa',
    league_es: 'Liga BPI (Portugal)',
    league_en: 'Liga BPI (Portugal)',
    league_pt: 'Liga BPI (Portugal)',
    selection_es: 'SEL. PORTUGUESA',
    selection_en: 'PORTUGUESE TEAM',
    selection_pt: 'SEL. PORTUGUESA',
    mainPosition_es : 'CENTROCAMPISTA',
    mainPosition_en: 'MIDFIELDER',
    mainPosition_pt: 'MEIO-CAMPISTA',
    birthDate: '28 - 03 - 1994',
    birthYear: '1994',
    town: 'Oliveira do Bairro',
    country: 'Portugal',
    nationality_es : 'Portuguesa',
    nationality_en : 'Portuguese',
    nationality_pt: 'Portuguesa',
    height: '1,67',
    weight: '55',
    signaturePhoto: require('../images/players/tatiana-pinto/jugadora-tatiana-pinto-unik-team.png'),
    signaturePhotoAlt: 'jugadora-tatiana-pinto-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/tatiana-pinto/header/Tatiana_Pinto2.jpg'),
      alt: 'Tatiana_Pinto2'
    }],
    mobileHeaderPhoto: require('../images/players/tatiana-pinto/header-mobile/tatiana-pinto-sporting-portugal.jpg'),
    mobileHeaderPhotoAlt: 'tatiana-pinto-sporting-portugal',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera División Portuguesa',
      nameFirstRow_en: 'Portuguese First Division',
      nameFirstRow_pt: 'Primeira Divisão Portuguesa',
      nameSecondRow_es: '"Liga BPI"',
      nameSecondRow_en: '"Liga BPI"',
      nameSecondRow_pt: '"Liga BPI"',
      team_es: 'Sporting Clube de Portugal',
      team_en: 'Sporting Clube de Portugal',
      team_pt: 'Sporting Clube de Portugal',
      years_es: '2016 - 2017 y 2017 - 2018',
      years_en: '2016 - 2017 and 2017 - 2018',
      years_pt: '2016 - 2017 e 2017 - 2018',
    }, {
      id: 2,
      nameFirstRow_es: 'Copa de Portugal',
      nameFirstRow_en: 'Copa de Portugal',
      nameFirstRow_pt: 'Copa de Portugal',
      nameSecondRow_es: '"Taça de Portugal"',
      nameSecondRow_en: '"Taça de Portugal"',
      nameSecondRow_pt: '"Taça de Portugal"',
      team_es: 'Sporting Clube de Portugal',
      team_en: 'Sporting Clube de Portugal',
      team_pt: 'Sporting Clube de Portugal',
      years_es: '2016 - 2017 y 2017 - 2018',
      years_en: '2016 - 2017 and 2017 - 2018',
      years_pt: '2016 - 2017 e 2017 - 2018',
    }, {
      id: 3,
      nameFirstRow_es: 'SuperCopa de Portugal',
      nameFirstRow_en: 'Portuguese SuperCup',
      nameFirstRow_pt: 'SuperCopa de Portugal',
      nameSecondRow_es: '"Supertaça"',
      nameSecondRow_en: '"Supertaça"',
      nameSecondRow_pt: '"Supertaça"',
      team_es: 'Sporting Clube de Portugal',
      team_en: 'Sporting Clube de Portugal',
      team_pt: 'Sporting Clube de Portugal',
      years_es: '2017',
      years_en: '2017',
      years_pt: '2017'
    }],
    internationalTournament: [],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Mejor 11',
      nameFirstRow_en: 'Best 11',
      nameFirstRow_pt: 'Melhor 11',
      nameSecondRow_es: 'Primera División Portuguesa "Liga BPI"',
      nameSecondRow_en: 'Portuguese First Division',
      nameSecondRow_pt: 'Primeira Divisão Portuguesa',
      years_es: '2018 - 2019',
      years_en: '2018 - 2019',
      years_pt: '2018 - 2019',
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Light-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Clearing the ball',
      characteristic_pt: 'Saída de bola',
      characteristic_es: 'Salida de balón',
      num: 32
    }, {
      id: 2,
      characteristic_en: 'Dribbling',
      characteristic_pt: 'Drible',
      characteristic_es: 'Regate',
      num: 30
    }, {
      id: 3,
      characteristic_en: 'Medium range passes',
      characteristic_pt: 'Passe médio',
      characteristic_es: 'Pase medio',
      num: 26
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Defensive player',
      characteristic_pt: 'Jogadora defensiva',
      characteristic_es: 'Criterio defensivo',
      num: 10
    }, {
      id: 2,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Reflexes',
      characteristic_pt: 'Reflexos',
      characteristic_es: 'Reflejos',
      num: 29
    }, {
      id: 2,
      characteristic_en: 'Agility/Flexibility',
      characteristic_pt: 'Agilidade/Flexibilidade',
      characteristic_es: 'Agilidad/Flexibilidad',
      num: 15
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: true,
      position: 'MC'
    },{
      id: 7,
      selected: true,
      position: 'MCO'
    },{
      id: 8,
      selected: true,
      position: 'INTi'
    },{
      id: 9,
      selected: true,
      position: 'INTd'
    },{
      id: 10,
      selected: true,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: true,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'SPORTING CP',
      country: 'Portugal',
      years_es: '2016 - Actualidad',
      years_en: '2016 - Currently',
      years_pt: '2016 - Presente',
      image: require('../images/clubs/trayectoria/sporting-portugal-futbol-femenino.png'),
      alt: 'sporting-portugal-futbol-femenino'
    }, {
      id: 2,
      team: 'BRISTOL CITY',
      country: 'Inglaterra',
      years_es: '2014 - 2016',
      years_en: '2014 - 2016',
      years_pt: '2014 - 2016',
      image: require('../images/clubs/trayectoria/bristol-city-inglaterra-futbol-femenino.png'),
      alt: 'bristol-city-inglaterra-futbol-femenino'
    }, {
      id: 3,
      team: 'SC SAND',
      country: 'Alemania',
      years_es: '2013 - 2014',
      years_en: '2013 - 2014',
      years_pt: '2013 - 2014',
      image: require('../images/clubs/trayectoria/sc-sand-alemania-futbol-femenino.png'),
      alt: 'sc-sand-alemania-futbol-femenino'
    }, {
      id: 4,
      team: 'CLUB DE ALBERGARIA',
      country: 'Portugal',
      years_es: '2007 - 2013',
      years_en: '2007 - 2013',
      years_pt: '2007 - 2013',
      image: require('../images/clubs/trayectoria/clube-albergaria-portugal-futbol-femenino.png'),
      alt: 'clube-albergaria-portugal-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'tatiana.pinto11',
    instagramId: '263881859',
    instagram: 'https://www.instagram.com/tatiana.pinto11/',
    twitter: 'https://twitter.com/tativfp',
    facebook: 'https://www.facebook.com/tativfp/',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'NEW BALANCE',
      url: 'https://www.instagram.com/p/B7dEsUIhqjN/',
      image: require('../images/players/tatiana-pinto/last-campaigns/campana-tatiana-pinto-new-balance.jpg'),
      alt: 'campana-tatiana-pinto-new-balance'
    }, {
      id: 2,
      brand: 'PROZIS',
      url: '',
      image: require('../images/players/tatiana-pinto/last-campaigns/campana-tatiana-pinto-prozis.jpg'),
      alt: 'campana-tatiana-pinto-prozis'
    }, {
      id: 3,
      brand: 'PRIMOR',
      url: 'https://www.instagram.com/p/B5uln5chjst/',
      image: require('../images/players/tatiana-pinto/last-campaigns/campana-tatiana-pinto-primor.jpg'),
      alt: 'campana-tatiana-pinto-primor'
    }, {
      id: 4,
      brand: 'SAK PROJECT',
      url: 'https://www.instagram.com/p/BjMw2mEgFkI/',
      image: require('../images/players/tatiana-pinto/last-campaigns/campana-tatiana-pinto-sak-project.jpg'),
      alt: 'campana-tatiana-pinto-sak-project'
    }, {
      id: 5,
      brand: 'SUPER BOCK',
      url: 'https://www.instagram.com/p/BkdcE0lAQI5/',
      image: require('../images/players/tatiana-pinto/last-campaigns/campana-tatiana-pinto-super-bock.jpg'),
      alt: 'campana-tatiana-pinto-super-bock'
    }, {
      id: 6,
      brand: 'ISDIN',
      url: 'https://www.instagram.com/p/BkXa55RAROV/',
      image: require('../images/players/tatiana-pinto/last-campaigns/campana-tatiana-pinto-isdin.jpg'),
      alt: 'campana-tatiana-pinto-isdin'
    }, {
      id: 7,
      brand: 'RECOVERY PUMP',
      url: '',
      image: require('../images/players/tatiana-pinto/last-campaigns/campana-tatiana-pinto-recovery-pump.jpg'),
      alt: 'campana-tatiana-pinto-recovery-pump'
    }, {
      id: 8,
      brand: 'THERAGUN',
      url: '',
      image: require('../images/players/tatiana-pinto/last-campaigns/campana-tatiana-pinto-theragun.jpg'),
      alt: 'campana-tatiana-pinto-theragun'
    }, {
      id: 9,
      brand: 'CONTINENTE',
      url: 'https://www.instagram.com/p/BrAg0JUABYR/?igshid=pg2chz1z613w',
      image: require('../images/players/tatiana-pinto/last-campaigns/campana-tatiana-pinto-continente.jpg'),
      alt: 'campana-tatiana-pinto-continente'
    }],
    press: [{
      id: 1,
      media: 'DESPORTO AO MINUTO',
      url: 'https://www.noticiasaominuto.com/desporto/1341600/pauleta-e-tatiana-pinto-lancam-derbi-mas-nao-arriscam-prognosticos',
      image: require('../images/players/tatiana-pinto/press/tatiana-pinto-prensa-deporto-ao-minuto.jpg'),
      alt: 'tatiana-pinto-prensa-deporto-ao-minuto'
    }, {
      id: 2,
      media: 'REMATE DIGITAL',
      url: 'https://rematedigital.pt/2019/06/20/acho-que-nasci-com-este-carinho-especial-pelo-futebol-tatiana-pinto/',
      image: require('../images/players/tatiana-pinto/press/tatiana-pinto-prensa-remate-digital.jpg'),
      alt: 'tatiana-pinto-prensa-remate-digital'
    }, {
      id: 3,
      media: 'ZERO ZERO',
      url: 'https://www.zerozero.pt/news.php?id=261547',
      image: require('../images/players/tatiana-pinto/press/tatiana-pinto-prensa-zero-zero-pt.jpg'),
      alt: 'tatiana-pinto-prensa-zero-zero-pt'
    }, {
      id: 4,
      media: 'SAPO DEPORTO',
      url: 'https://desporto.sapo.pt/geral/artigos/tatiana-pinto-francisca-jorge-e-maria-martins-mesmo-em-isolamento-elas-mantem-a-forma-prontas-para-o-que-der-e-vier',
      image: require('../images/players/tatiana-pinto/press/tatiana-pinto-prensa-sapo-deporto.jpg'),
      alt: 'tatiana-pinto-prensa-sapo-deporto'
    }, {
      id: 5,
      media: 'RENASCENÇA',
      url: 'https://rr.sapo.pt/2020/02/24/clube-portugal/sporting-domina-convocatoria-de-portugal-para-a-algarve-cup/noticia/183131/',
      image: require('../images/players/tatiana-pinto/press/tatiana-pinto-prensa-renascenca.jpg'),
      alt: 'tatiana-pinto-prensa-renascenca'
    }, {
      id: 6,
      media: 'RECORD',
      url: 'https://www.record.pt/futebol/futebol-feminino/detalhe/jogadoras-do-sporting-destacam-foco-e-concentracao-para-vencer-o-derbi',
      image: require('../images/players/tatiana-pinto/press/tatiana-pinto-prensa-record_2.jpg'),
      alt: 'tatiana-pinto-prensa-record_2'
    }, {
      id: 7,
      media: 'SAPO DEPORTO',
      url: 'https://desporto.sapo.pt/futebol/futebol-feminino/artigos/jogadoras-do-sporting-destacam-foco-e-concentracao-para-vencer-o-derbi',
      image: require('../images/players/tatiana-pinto/press/tatiana-pinto-prensa-sapo-deporto_2.jpg'),
      alt: 'tatiana-pinto-prensa-sapo-deporto_2'
    }, {
      id: 8,
      media: 'RECORD',
      url: 'https://www.record.pt/futebol/futebol-feminino/detalhe/benfica-sporting-rivalidade-a-parte-antes-do-derbi',
      image: require('../images/players/tatiana-pinto/press/Tatiana-Pinto-prensa-record_3.jpg'),
      alt: 'Tatiana-Pinto-prensa-record_3'
    }, {
      id: 9,
      media: 'A BOLA',
      url: 'https://www.abola.pt/nnh/2020-02-24/futebol-feminino-sporting-domina-convocatoria-para-a-algarve-cup-com-11-jogadoras/830836',
      image: require('../images/players/tatiana-pinto/press/tatiana-pinto-prensa-a-bola.jpg'),
      alt: 'tatiana-pinto-prensa-a-bola'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 39,
    title: 'UNIK TEAM | Viola Calligaris | Jugadora de fútbol femenino',
    metadescription: 'Viola Calligaris, futbolista suiza, actualmente juega como defensa en el Levante UD de la Primera Iberdrola y en la Selección Suiza.',
    url: require('../images/players-profile-photo/viola-calligaris-valencia-cf.png'),
    alt: 'viola-calligaris-valencia-cf',
    name: 'VIOLA CALLIGARIS',
    nameUrl: 'viola-calligaris',
    team: 'Levante UD',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: 'SEL. SUIZA',
    selection_en: 'SWISS TEAM',
    selection_pt: 'SELEÇÃO SUÍÇA',
    mainPosition_es : 'DEFENSA',
    mainPosition_en: 'DEFENDER',
    mainPosition_pt: 'DEFESA',
    birthDate: '17 - 03 - 1996',
    birthYear: '1996',
    town: 'Lucerna',
    country: 'Suiza',
    nationality_es : 'Suiza',
    nationality_en : 'Swiss',
    nationality_pt: 'Suíça',
    height: '1,65',
    weight: '58',
    signaturePhoto: require('../images/players/viola-calligaris/jugadora-viola-calligaris-unikteam.png'),
    signaturePhotoAlt: 'jugadora-viola-calligaris-unikteam',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/viola-calligaris/header/viola-calligaris-seleccion-suiza-femenina.jpg'),
      alt: 'viola-calligaris-seleccion-suiza-femenina'
    }, {
      id: 2,
      url: require('../images/players/viola-calligaris/header/viola-calligaris-seleccion-suiza-futbol-femenino.jpg'),
      alt: 'viola-calligaris-seleccion-suiza-futbol-femenino'
    }, {
      id: 3,
      url: require('../images/players/viola-calligaris/header/viola-calligaris-valencia-cf-femenino.jpg'),
      alt: 'viola-calligaris-valencia-cf-femenino'
    }],
    mobileHeaderPhoto: require('../images/home/mobile/viola-calligaris-seleccion-suiza.jpg'),
    mobileHeaderPhotoAlt: 'viola-calligaris-seleccion-suiza',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2018 - 2019',
      years_en: '2018 - 2019',
      years_pt: '2018 - 2019'
    }, {
      id: 2,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2017 - 2018',
      years_en: '2017 - 2018',
      years_pt: '2017 - 2018'
    }],
    internationalTournament: [],
    individualAwards: [],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Assists',
      characteristic_pt: 'Assistências',
      characteristic_es: 'Asistencias',
      num: 2
    }, {
      id: 2,
      characteristic_en: 'Ball handling',
      characteristic_pt: 'Condução',
      characteristic_es: 'Conducción',
      num: 8
    }, {
      id: 3,
      characteristic_en: 'Medium range passes',
      characteristic_pt: 'Passe médio',
      characteristic_es: 'Pase medio',
      num: 26
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Defensive player',
      characteristic_pt: 'Jogadora defensiva',
      characteristic_es: 'Criterio defensivo',
      num: 10
    }, {
      id: 2,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Power',
      characteristic_pt: 'Potência',
      characteristic_es: 'Potencia',
      num: 27
    }, {
      id: 2,
      characteristic_en: 'Strenght',
      characteristic_pt: 'Força',
      characteristic_es: 'Fuerza',
      num: 17
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: true,
      position: 'DFCi'
    },{
      id: 3,
      selected: true,
      position: 'DFCd'
    },{
      id: 4,
      selected: true,
      position: 'LD'
    },{
      id: 5,
      selected: true,
      position: 'LIB'
    },{
      id: 6,
      selected: true,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: true,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'LEVANTE UD',
      country: 'España',
      years_es: '2020 - Actualidad',
      years_en: '2020 - Currently',
      years_pt: '2020 - Presente',
      image: require('../images/clubs/trayectoria/levante-ud-espana-futbol-femenino.png'),
      alt: 'levante-ud-espana-futbol-femenino'
    },{
      id: 2,
      team: 'VALENCIA C.F.',
      country: 'España',
      years_es: '2019 - 2020',
      years_en: '2019 - 2020',
      years_pt: '2019 - 2020',
      image: require('../images/clubs/trayectoria/valencia-cf-espana-futbol-femenino.png'),
      alt: 'valencia-cf-espana-futbol-femenino'
    },{
      id: 3,
      team: 'ATLÉTICO DE MADRID',
      country: 'España',
      years_es: '2017 - 2019',
      years_en: '2017 - 2019',
      years_pt: '2017 - 2019',
      image: require('../images/clubs/trayectoria/atletico-de-madrid-espana-futbol-femenino.png'),
      alt: 'atletico-de-madrid-espana-futbol-femenino'
    }, {
      id: 4,
      team: 'BSC YOUNG BOYS',
      country: 'Suiza',
      years_es: '2013 - 2017',
      years_en: '2013 - 2017',
      years_pt: '2013 - 2017',
      image: require('../images/clubs/trayectoria/bsc-young-boys-suiza-futbol-femenino.png'),
      alt: 'bsc-young-boys-suiza-futbol-femenino'
    }, {
      id: 5,
      team: 'S.C. KRIENS',
      country: 'Suiza',
      years_es: '2012 - 2013',
      years_en: '2012 - 2013',
      years_pt: '2012 - 2013',
      image: require('../images/clubs/trayectoria/sc-kriens-suiza-futbol-femenino.png'),
      alt: 'sc-kriens-suiza-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'viola_calligaris',
    instagramId: '5595806304',
    instagram: 'https://www.instagram.com/viola_calligaris/',
    twitter: '',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'SAN MIGUEL',
      url: 'https://www.instagram.com/p/B5IFKIxInFp/',
      image: require('../images/players/viola-calligaris/last-campaigns/campana-viola-calligaris-cerveza-san-miguel.jpg'),
      alt: 'campana-viola-calligaris-cerveza-san-miguel'
    }, {
      id: 2,
      brand: 'VOLKSWAGEN SWITZERLAND',
      url: 'https://www.instagram.com/p/B4z57SgIlf_/',
      image: require('../images/players/viola-calligaris/last-campaigns/campana-viola-calligaris-volkswagen-switzerland.jpg'),
      alt: 'campana-viola-calligaris-volkswagen-switzerland'
    }, {
      id: 3,
      brand: 'SPRINTER',
      url: '',
      image: require('../images/players/viola-calligaris/last-campaigns/campana-viola-calligaris-sprinterball-challenge.jpg'),
      alt: 'campana-viola-calligaris-sprinterball-challenge'
    }, {
      id: 4,
      brand: 'FOODSPRING',
      url: '',
      image: require('../images/players/viola-calligaris/last-campaigns/campana-viola-calligaris-foodspring.jpg'),
      alt: 'campana-viola-calligaris-foodspring'
    }, {
      id: 5,
      brand: 'STROOPS FITNESS',
      url: '',
      image: require('../images/players/viola-calligaris/last-campaigns/campana-viola-calligaris-stroopsfitness.jpg'),
      alt: 'campana-viola-calligaris-stroopsfitness'
    }],
    press: [{
      id: 1,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2017/09/24/mas_futbol/1506283141_187260.html',
      image: require('../images/players/viola-calligaris/press/viola-calligaris-prensa-diario-as.jpg'),
      alt: 'viola-calligaris-prensa-diario-as'
    }, {
      id: 2,
      media: 'PLAZA DEPORTIVA',
      url: 'https://plazadeportiva.valenciaplaza.com/futbol-entrevista-podcast-viola-calligaris-valencia-cf-femenino',
      image: require('../images/players/viola-calligaris/press/viola-calligaris-prensa-plaza-deportiva-1.jpg'),
      alt: 'viola-calligaris-prensa-plaza-deportiva-1'
    }, {
      id: 3,
      media: 'EL DESMARQUE',
      url: 'https://eldesmarque.com/valencia/primera-iberdrola/223761-viola-calligaris-calienta-el-derbi-ver-quien-es-el-mejor-equipo-de-valencia',
      image: require('../images/players/viola-calligaris/press/viola-calligaris-prensa-el-desmarque.jpg'),
      alt: 'viola-calligaris-prensa-el-desmarque'
    }, {
      id: 4,
      media: 'LA VANGUARDIA',
      url: 'https://www.lavanguardia.com/deportes/20200326/48106791750/calligaris-pasa-cuarentena-en-suiza-y-ayuda-a-sus-padres-y-abuelos.html',
      image: require('../images/players/viola-calligaris/press/viola-calligaris-prensa-la-vanguardia.jpg'),
      alt: 'viola-calligaris-prensa-la-vanguardia'
    }, {
      id: 5,
      media: 'SUPERDEPORTE',
      url: 'https://www.superdeporte.es/valencia/2020/02/22/viola-calligaris-da-paso-frente/442897.html',
      image: require('../images/players/viola-calligaris/press/viola-calligaris-prensa-superdeporte.jpg'),
      alt: 'viola-calligaris-prensa-superdeporte'
    }, {
      id: 6,
      media: 'EL DESMARQUE',
      url: 'https://eldesmarque.com/valencia/primera-iberdrola/214280-viola-calligaris-mejor-jugadora-de-la-jornada-para-laliga',
      image: require('../images/players/viola-calligaris/press/viola-calligaris-prensa-el-desmarque-2.jpg'),
      alt: 'viola-calligaris-prensa-el-desmarque-2'
    }, {
      id: 7,
      media: 'BMQ',
      url: 'https://www.bmq.ch/viola-calligaris/',
      image: require('../images/players/viola-calligaris/press/viola-calligaris-prensa-bmq.jpg'),
      alt: 'viola-calligaris-prensa-bmq'
    }, {
      id: 8,
      media: 'SRG SSR',
      url: 'https://www.srf.ch/play/tv/sporthome/video/sporthome-mit-viola-calligaris?id=015cd3b2-2cf2-4275-872b-38385fa5ca4e',
      image: require('../images/players/viola-calligaris/press/viola-calligaris-prensa-srg-ssr.jpg'),
      alt: 'viola-calligaris-prensa-srg-ssr'
    }, {
      id: 9,
      media: 'SUPERDEPORTE',
      url: 'https://www.superdeporte.es/valencia/2019/12/14/viola-calligaris-cerrojo-suizo-puchades/434563.html',
      image: require('../images/players/viola-calligaris/press/viola-calligaris-prensa-super-deporte.jpg'),
      alt: 'viola-calligaris-prensa-super-deporte'
    }, {
      id: 10,
      media: 'PLAZA DEPORTIVA',
      url: 'https://plazadeportiva.valenciaplaza.com/viola-calligaris-y-rocio-galvez-las-futbolistas-con-mas-minutos-en-el-valencia-y-el-levante',
      image: require('../images/players/viola-calligaris/press/viola-calligaris-prensa-plaza-deportiva-1.jpg'),
      alt: 'viola-calligaris-prensa-plaza-deportiva-1'
    }, {
      id: 11,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2020/03/18/femenino/1584548274_854917.html',
      image: require('../images/players/viola-calligaris/press/viola-calligaris-prensa-diario-as-2.jpg'),
      alt: 'viola-calligaris-prensa-diario-as-2'
    }, {
      id: 12,
      media: 'PLAZA DEPORTIVA',
      url: 'https://plazadeportiva.valenciaplaza.com/valencia-femenino-ficha-calligaris',
      image: require('../images/players/viola-calligaris/press/viola-calligaris-prensa-plaza-deportiva-2.jpg'),
      alt: 'viola-calligaris-prensa-plaza-deportiva-2'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 40,
    title: 'UNIK TEAM | María Molina| Jugadora de fútbol femenino',
    metadescription: 'María Molina, futbolista española, actualmente juega como defensa en el FC Barcelona B de Reto Iberdrola.',
    url: require('../images/players-profile-photo/maria-molina-barcelona.png'),
    alt: 'maria-molina-barcelona',
    name: 'MARÍA MOLINA',
    nameUrl: 'maria-molina',
    team: 'FC Barcelona B',
    league_es: 'Reto Iberdrola (España)',
    league_en: 'Reto Iberdrola (Spain)',
    league_pt: 'Reto Iberdrola (Espanha)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : 'DEFENSA',
    mainPosition_en: 'DEFENDER',
    mainPosition_pt: 'DEFESA',
    birthDate: '09 - 05 - 2000',
    birthYear: '2000',
    town: 'Cornellà de Llobregat',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,73',
    weight: '62',
    signaturePhoto: require('../images/players/maria-molina/jugadora-maria-molina-barcelona-unik-team.png'),
    signaturePhotoAlt: 'jugadora-maria-molina-barcelona-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/maria-molina/header/jugadora-maria-molina-rcd-espanyol.jpg'),
      alt: 'jugadora-maria-molina-rcd-espanyol'
    }, {
      id: 2,
      url: require('../images/players/maria-molina/header/maria-molina-rcd-espanyol-femenino.jpg'),
      alt: 'maria-molina-rcd-espanyol-femenino'
    }, {
      id: 3,
      url: require('../images/players/maria-molina/header/maria-molina-rcd-espanyol-futbol-femenino.jpg'),
      alt: 'maria-molina-rcd-espanyol-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/maria-molina/header-mobile/maria-molina-rcd-espanyol.jpg'),
    mobileHeaderPhotoAlt: 'maria-molina-rcd-espanyol',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Campeonato de España de',
      nameFirstRow_en: 'U-16 Spanish Championship',
      nameFirstRow_pt: 'Campeonato Espanhol de',
      nameSecondRow_es: 'Sel. Autonómicas Sub-16',
      nameSecondRow_en: '',
      nameSecondRow_pt: 'Seleções Autônomas Sub-16',
      team_es: 'Selección de Cataluña',
      team_en: 'Catalunya Autonomus Team',
      team_pt: 'Seleção da Catalunha',
      years_es: '',
      years_en: '',
      years_pt: ''
    }, {
      id: 2,
      nameFirstRow_es: 'Dos veces Subcampeona de',
      nameFirstRow_en: 'Two-times runner-up of the',
      nameFirstRow_pt: 'Duas vezes Vice-campeã',
      nameSecondRow_es: 'Sel. Autonómicas Sub-18',
      nameSecondRow_en: 'U-18 Spanish Championship',
      nameSecondRow_pt: 'Espanhola de Seleções Autônomas Sub-18',
      team_es: 'Selección de Cataluña',
      team_en: 'Catalunya Autonomus Team',
      team_pt: 'Seleção da Catalunha',
      years_es: '',
      years_en: '',
      years_pt: ''
    }],
    internationalTournament: [],
    individualAwards: [],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Long passes',
      characteristic_pt: 'Passe longo',
      characteristic_es: 'Pase largo',
      num: 25
    }, {
      id: 2,
      characteristic_en: 'Assists',
      characteristic_pt: 'Assistências',
      characteristic_es: 'Asistencias',
      num: 2
    }, {
      id: 3,
      characteristic_en: 'Clearing the ball',
      characteristic_pt: 'Saída de bola',
      characteristic_es: 'Salida de balón',
      num: 32
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Vision',
      characteristic_pt: 'Visão de jogo',
      characteristic_es: 'Visión de juego',
      num: 34
    }, {
      id: 2,
      characteristic_en: 'Communication skills',
      characteristic_pt: 'Comunicação',
      characteristic_es: 'Comunicación',
      num: 7
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Strenght',
      characteristic_pt: 'Força',
      characteristic_es: 'Fuerza',
      num: 17
    }, {
      id: 2,
      characteristic_en: 'Resistance',
      characteristic_pt: 'Resistência',
      characteristic_es: 'Resistencia',
      num: 31
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: true,
      position: 'DFCi'
    },{
      id: 3,
      selected: true,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: true,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'FC BARCELONA B',
      country: 'España',
      years_es: '2020 - Actualidad',
      years_en: '2020 - Currently',
      years_pt: '2020 - Presente',
      image: require('../images/clubs/trayectoria/fc-barcelona-espana-futbol-femenino.png'),
      alt: 'fc-barcelona-espana-futbol-femenino'
    },{
      id: 2,
      team: 'RCD ESPANYOL B',
      country: 'España',
      years_es: '2009 - 2020',
      years_en: '2009 - 2020',
      years_pt: '2009 - 2020',
      image: require('../images/clubs/trayectoria/rcd-espanyol-espana-futbol-femenino.png'),
      alt: 'rcd-espanyol-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'mariaamolina5',
    instagramId: '567136452',
    instagram: 'https://www.instagram.com/mariaamolina5/',
    twitter: '',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [],
    press: [{
      id: 1,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/rcd-espanyol/cantera/20160709/403078382551/maria-molina-del-filial-al-primer-equipo.html',
      image: require('../images/players/maria-molina/press/maria-molina-prensa-mundo-deportivo-1.jpg'),
      alt: 'maria-molina-prensa-mundo-deportivo-1'
    }, {
      id: 2,
      media: 'RCD ESPANYOL',
      url: 'https://www.rcdespanyol.com/es/actualidad/maria-molina-al-primer-equipo/_n:3146/',
      image: require('../images/players/maria-molina/press/maria-molina-prensa-rcd-espanyol.jpg'),
      alt: 'maria-molina-prensa-rcd-espanyol'
    }, {
      id: 3,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/rcd-espanyol/20191016/471024886765/presentado-el-futbol-base-del-espanyol.html',
      image: require('../images/players/maria-molina/press/maria-molina-prensa-mundo-deportivo-2.jpg'),
      alt: 'maria-molina-prensa-mundo-deportivo-2'
    }, {
      id: 4,
      media: 'DIARIO LA GRADA',
      url: 'https://www.diariolagrada.com/noticias/futbolfemenino/diez-jugadoras-blanquiazules-seleccio-catalana_27475.html',
      image: require('../images/players/maria-molina/press/maria-molina-prensa-diario-la-grada.jpg'),
      alt: 'maria-molina-prensa-diario-la-grada'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 41,
    title: 'UNIK TEAM | Andrea de la Nava | Jugadora de fútbol femenino',
    metadescription: 'Andrea de la Nava, futbolista española, actualmente juega como portera en el Athletic Club de la Primera Iberdrola y en la Selección Española Sub-20.',
    url: require('../images/players-profile-photo/andrea-de-la-nava-athletic-club.png'),
    alt: 'andrea-de-la-nava-athletic-club',
    name: 'ANDREA DE LA NAVA',
    nameUrl: 'andrea-de-la-nava',
    team: 'Athletic Club',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : 'PORTERA',
    mainPosition_en: 'GOALKEEPER',
    mainPosition_pt: 'GOLEIRA',
    birthDate: '11 - 02 - 1994',
    birthYear: '1994',
    town: 'Barakaldo',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,67',
    weight: '60',
    signaturePhoto: require('../images/players/andrea-de-la-nava/jugadora-andrea-de-la-nava.png'),
    signaturePhotoAlt: 'jugadora-andrea-de-la-nava',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/andrea-de-la-nava/header/andrea-de-la-nava-athletic-club-bilbao-femenino.jpg'),
      alt: 'andrea-de-la-nava-athletic-club-bilbao-femenino'
    }, {
      id: 2,
      url: require('../images/players/andrea-de-la-nava/header/andrea-de-la-nava-athletic-club-bilbao-futtbol-femenino.jpg'),
      alt: 'andrea-de-la-nava-athletic-club-bilbao-futtbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/andrea-de-la-nava/header-mobile/andrea-de-la-nava-athletic-club-bilbao-femenino.jpg'),
    mobileHeaderPhotoAlt: 'andrea-de-la-nava-athletic-club-bilbao-femenino',
    nationalTournament: [],
    internationalTournament: [],
    individualAwards: [],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Ball control with feet',
      characteristic_pt: 'Domínio bola c/ pés',
      characteristic_es: 'Dominio de pies',
      num: 14
    }, {
      id: 2,
      characteristic_en: 'Blocking',
      characteristic_pt: 'Agarrar',
      characteristic_es: 'Blocaje',
      num: 4
    }, {
      id: 3,
      characteristic_en: 'Aerial ball control',
      characteristic_pt: 'Jogo aéreo',
      characteristic_es: 'Juego Aéreo',
      num: 19
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Communication skills',
      characteristic_pt: 'Comunicação',
      characteristic_es: 'Comunicación',
      num: 7
    }, {
      id: 2,
      characteristic_en: 'Space control',
      characteristic_pt: 'Colocação no campo',
      characteristic_es: 'Colocación',
      num: 6
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Strong personality',
      characteristic_pt: 'Personalidade forte',
      characteristic_es: 'Carácter',
      num: 5
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Reflexes',
      characteristic_pt: 'Reflexos',
      characteristic_es: 'Reflejos',
      num: 29
    }, {
      id: 2,
      characteristic_en: 'Agility/Flexibility',
      characteristic_pt: 'Agilidade/Flexibilidade',
      characteristic_es: 'Agilidad/Flexibilidad',
      num: 15
    }],
    positions: [{
      id: 0,
      selected: true,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'ATHLETIC CLUB BILBAO',
      country: 'España',
      years_es: '2019 - Actualidad',
      years_en: '2019 - Currently',
      years_pt: '2019 - Presente',
      image: require('../images/clubs/trayectoria/athletic-club-bilbao-espana-futbol-femenino.png'),
      alt: 'athletic-club-bilbao-espana-futbol-femenino'
    },{
      id: 2,
      team: 'EDF LOGROÑO',
      country: 'España',
      nameSecondRow_pt: '',
      years_es: '2015 - 2019',
      years_en: '2015 - 2019',
      years_pt: '2015 - 2019',
      image: require('../images/clubs/trayectoria/logrono-edf-espana-futbol-femenino.png'),
      alt: 'logrono-edf-espana-futbol-femenino'
    },{
      id: 3,
      team: 'PAULDARRAK FKT',
      country: 'España',
      nameSecondRow_pt: '',
      years_es: '2004 - 2015',
      years_en: '2004 - 2015',
      years_pt: '2004 - 2015',
      image: require('../images/clubs/trayectoria/pauldarrak-espana-futbol-femenino.png'),
      alt: 'pauldarrak-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'andrea_delanava',
    instagramId: '439782496',
    instagram: 'https://www.instagram.com/andrea_delanava/',
    twitter: 'https://twitter.com/andreadelanava',
    facebook: 'https://www.facebook.com/andrea.delanava.18',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'RG GOALKEEPER GLOVES',
      url: 'https://www.instagram.com/p/CBLZKN2nNGT/',
      image: require('../images/players/andrea-de-la-nava/last-campaigns/campana-andrea-de-la-nava-rg-goalkeeper-gloves.jpg'),
      alt: 'campana-andrea-de-la-nava-rg-goalkeeper-gloves'
    }],
    press: [{
      id: 1,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/athletic-bilbao/20200605/481600714297/athletic-lub-femenino-andrea-de-la-nava-amaia-pena-pauldarrak-joseba-agirre.html',
      image: require('../images/players/andrea-de-la-nava/press/andrea-de-la-nava-prensa-mundo-deportivo.jpg'),
      alt: 'andrea-de-la-nava-prensa-mundo-deportivo'
    }, {
      id: 2,
      media: 'EL DESMARQUE',
      url: 'https://eldesmarque.com/bizkaia/primera-iberdrola/230541-andrea-de-la-nava-la-cara-nueva-de-la-porteria',
      image: require('../images/players/andrea-de-la-nava/press/andrea-de-la-nava-prensa-el-desmarque.jpg'),
      alt: 'andrea-de-la-nava-prensa-el-desmarque'
    }, {
      id: 3,
      media: 'BESOCCER',
      url: 'https://es.besoccer.com/noticia/de-la-nava-sobre-el-athletic-senti-la-llamada-como-una-oportunidad-para-crecer-826579',
      image: require('../images/players/andrea-de-la-nava/press/andrea-de-la-nava-prensa-besoccer.jpg'),
      alt: 'andrea-de-la-nava-prensa-besoccer'
    }, {
      id: 4,
      media: 'EL DESMARQUE',
      url: 'https://eldesmarque.com/bizkaia/athletic-club/223237-andrea-de-la-nava-debuta-en-la-porteria-del-athletic',
      image: require('../images/players/andrea-de-la-nava/press/andrea-de-la-nava-prensa-el-desmarque-2.jpg'),
      alt: 'andrea-de-la-nava-prensa-el-desmarque-2'
    }, {
      id: 5,
      media: 'EN BARAKALDO',
      url: 'https://www.enbarakaldo.com/es/actualidad/la-barakaldesa-andrea-de-la-nava-fichaje-para-la-porteria-del-athletic-pasa-las-pruebas-medicas/9042',
      image: require('../images/players/andrea-de-la-nava/press/andrea-de-la-nava-prensa-en-barakaldo.jpg'),
      alt: 'andrea-de-la-nava-prensa-en-barakaldo'
    }, {
      id: 6,
      media: 'EL DESMARQUE',
      url: 'https://eldesmarque.com/bizkaia/athletic-club/214137-andrea-se-despide-del-logrono',
      image: require('../images/players/andrea-de-la-nava/press/andrea-de-la-nava-prensa-el-desmarque-3.jpg'),
      alt: 'andrea-de-la-nava-prensa-el-desmarque-3'
    }, {
      id: 7,
      media: 'NAIZ',
      url: 'https://www.naiz.eus/eu/actualidad/noticia/20190627/el-athletic-ficha-a-la-portera-del-logrono-andrea-de-la-nava',
      image: require('../images/players/andrea-de-la-nava/press/andrea-de-la-nava-prensa-naiz.jpg'),
      alt: 'andrea-de-la-nava-prensa-naiz'
    }, {
      id: 8,
      media: 'ATHLETIC CLUB',
      url: 'https://www.youtube.com/watch?v=Ip4Ad9wfgkE',
      image: require('../images/players/andrea-de-la-nava/press/andrea-de-la-nava-prensa-athletic-club.jpg'),
      alt: 'andrea-de-la-nava-prensa-athletic-club'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 42,
    title: 'UNIK TEAM | Sofía Álvarez | Jugadora de fútbol femenino',
    metadescription: 'Sofía Álvarez, futbolista mexicana, actualmente juega como centrocampista en el Querétaro Femenil de la Liga MX Femenil y en la Selección Mexicana Sub-17.',
    url: require('../images/players-profile-photo/sofía-alvarez-club-queretaro.png'),
    alt: 'sofía-alvarez-club-queretaro',
    name: 'SOFÍA ÁLVAREZ',
    nameUrl: 'sofia-alvarez',
    team: 'Querétaro FC',
    league_es: 'Liga MX Femenil (México)',
    league_en: 'Liga MX Femenil (Mexico)',
    league_pt: 'Liga MX Femenil (México)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : 'DELANTERA',
    mainPosition_en: 'FORWARD',
    mainPosition_pt: 'ATACANTE',
    birthDate: '05 - 06 - 2000',
    birthYear: '2000',
    town: 'Querétaro',
    country: 'México',
    nationality_es : 'Mexicana',
    nationality_en : 'Mexican',
    nationality_pt: '',
    height: '1,58',
    weight: '53',
    signaturePhoto: require('../images/players/sofia-alvarez/jugadora-sofia-alvarez-unik-team.png'),
    signaturePhotoAlt: 'jugadora-sofia-alvarez-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/sofia-alvarez/header/sofia-alvarez-club-queretaro-futbol-femenino-mexico.jpg'),
      alt: 'sofia-alvarez-club-queretaro-futbol-femenino-mexico'
    }, {
      id: 2,
      url: require('../images/players/sofia-alvarez/header/sofia-alvarez-club-queretaro-femenino.jpg'),
      alt: 'sofia-alvarez-club-queretaro-femenino'
    }, {
      id: 3,
      url: require('../images/players/sofia-alvarez/header/sofia-alvarez-club-queretaro-futbol-femenino.jpg'),
      alt: 'sofia-alvarez-club-queretaro-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/sofia-alvarez/header-mobile/sofia-alvarez-queretaro-fc.jpg'),
    mobileHeaderPhotoAlt: 'sofia-alvarez-queretaro-fc',
    nationalTournament: [],
    internationalTournament: [],
    individualAwards: [],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Medium range passes',
      characteristic_pt: 'Passe médio',
      characteristic_es: 'Pase medio',
      num: 26
    }, {
      id: 2,
      characteristic_en: 'Assists',
      characteristic_pt: 'Assistências',
      characteristic_es: 'Asistencias',
      num: 2
    }, {
      id: 3,
      characteristic_en: 'Dribbling',
      characteristic_pt: 'Drible',
      characteristic_es: 'Regate',
      num: 30
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Vision',
      characteristic_pt: 'Visão de jogo',
      characteristic_es: 'Visión de juego',
      num: 34
    }, {
      id: 2,
      characteristic_en: 'Skilled passer',
      characteristic_pt: 'Ligação',
      characteristic_es: 'Asociación',
      num: 3
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partida',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Speed',
      characteristic_pt: 'Velocidade',
      characteristic_es: 'Velocidad',
      num: 33
    }, {
      id: 2,
      characteristic_en: 'Resistance',
      characteristic_pt: 'Resistência',
      characteristic_es: 'Resistencia',
      num: 31
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: true,
      position: 'EXTi'
    },{
      id: 12,
      selected: true,
      position: 'EXTd'
    },{
      id: 13,
      selected: true,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'QUERÉTARO FC',
      country: 'México',
      years_es: '2018 - Actualidad',
      years_en: '2018 - Currently',
      years_pt: '2018 - Presente',
      image: require('../images/clubs/trayectoria/queretaro-futbol-club-mexico-futbol-femenino.png'),
      alt: 'queretaro-futbol-club-mexico-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'sofialtos',
    instagramId: '339821916',
    instagram: 'https://instagram.com/sofialtos/',
    twitter: 'https://twitter.com/sofialtos00',
    facebook: 'https://www.facebook.com/sofia.alvareztostadomacin',
    tikTok: '',
    lastsCampaigns: [],
    press: [{
      id: 1,
      media: 'DIARIO AS',
      url: 'https://mexico.as.com/mexico/2019/07/27/futbol/1564245486_996515.html',
      image: require('../images/players/sofia-alvarez/press/sofia-alvarez-prensa-diario-as.jpg'),
      alt: 'sofia-alvarez-prensa-diario-as'
    }, {
      id: 2,
      media: 'DIARIO AS',
      url: 'https://mexico.as.com/mexico/2020/02/08/futbol/1581173845_830897.html',
      image: require('../images/players/sofia-alvarez/press/sofia-alvarez-prensa-diario-as_2.jpg'),
      alt: 'sofia-alvarez-prensa-diario-as_2'
    }, {
      id: 3,
      media: 'QUERÉTARO FC',
      url: 'https://www.youtube.com/watch?v=q9LCbSAF6j8',
      image: require('../images/players/sofia-alvarez/press/sofia-alvarez-prensa-club-quetaro.jpg'),
      alt: 'sofia-alvarez-prensa-club-quetaro'
    }, {
      id: 4,
      media: 'SOY FÚTBOL',
      url: 'https://www.soyfutbol.com/ligas/Queretaro-obtiene-su-primera-victoria-ante-Leon-en-la-Liga-Femenil-MX-20190719-0033.html',
      image: require('../images/players/sofia-alvarez/press/sofia-alvarez-prensa-soy-futbol.jpg'),
      alt: 'sofia-alvarez-prensa-soy-futbol'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 43,
    title: 'UNIK TEAM | Leah Lewis | Jugadora de fútbol femenino',
    metadescription: 'Leah Lewis, futbolista estadounidense, actualmente juega como defensa en el Emek Hefer WFC de la Ligat Nashim.',
    url: require('../images/players-profile-photo/leah-lewis-emek-hefer-israel.png'),
    alt: 'leah-lewis-emek-hefer-israel',
    name: 'LEAH LEWIS',
    nameUrl: 'leah-lewis',
    team: 'Emek Hefer WFC',
    league_es: 'Ligat Nashim (Israel)',
    league_en: 'Ligat Nashim (Israel)',
    league_pt: 'Ligat Nashim (Israel)',
    selection_es: '',
    selection_en: '',
    selection_pt: '',
    mainPosition_es : 'DEFENSA',
    mainPosition_en: 'DEFENDER',
    mainPosition_pt: 'DEFESA',
    birthDate: '23 - 07 - 1998',
    birthYear: '1998',
    town: 'Connecticut',
    country: 'Estados Unidos',
    nationality_es : 'Estadounidense',
    nationality_en : 'American',
    nationality_pt: '',
    height: '1,70',
    weight: '68',
    signaturePhoto: require('../images/players/leah-lewis/player-leah-lewis-efer-emek-israel-unik-team.png'),
    signaturePhotoAlt: 'player-leah-lewis-efer-emek-israel-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/leah-lewis/header/jugadora-leah-lewis-colgate-university.jpg'),
      alt: 'jugadora-leah-lewis-colgate-university'
    }, {
      id: 2,
      url: require('../images/players/leah-lewis/header/leah-lewis-colgate-university-femenino.jpg'),
      alt: 'leah-lewis-colgate-university-femenino'
    }, {
      id: 3,
      url: require('../images/players/leah-lewis/header/leah-lewis-colgate-university-futbol-femenino.jpg'),
      alt: 'leah-lewis-colgate-university-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/leah-lewis/header-mobile/leah-lewis-colgate-university.jpg'),
    mobileHeaderPhotoAlt: 'leah-lewis-colgate-university',
    nationalTournament: [],
    internationalTournament: [],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Colgate University Defensive',
      nameFirstRow_en: 'Colgate University Defensive',
      nameFirstRow_pt: 'Colgate University Defensive',
      nameSecondRow_es: 'Player of the Year',
      nameSecondRow_en: 'Player of the Year',
      nameSecondRow_pt: 'Player of the Year',
      years_es: '2019',
      years_en: '2019',
      years_pt: '2019'
    },{
      id: 2,
      nameFirstRow_es: 'NCAA All Patriot',
      nameFirstRow_en: 'NCAA All Patriot',
      nameFirstRow_pt: 'NCAA All Patriot',
      nameSecondRow_es: 'League First Team',
      nameSecondRow_en: 'League First Team',
      nameSecondRow_pt: 'League First Team',
      years_es: '2019',
      years_en: '2019',
      years_pt: '2019'
    },{
      id: 3,
      nameFirstRow_es: 'United Soccer Coaches',
      nameFirstRow_en: 'United Soccer Coaches',
      nameFirstRow_pt: 'United Soccer Coaches',
      nameSecondRow_es: 'All-Region Third Team',
      nameSecondRow_en: 'All-Region Third Team',
      nameSecondRow_pt: 'All-Region Third Team',
      years_es: '2019',
      years_en: '2019',
      years_pt: '2019'
    },{
      id: 4,
      nameFirstRow_es: '4 veces NCAA Patriot League',
      nameFirstRow_en: '4 times NCAA Patriot League',
      nameFirstRow_pt: '4 vezes NCAA Patriot League',
      nameSecondRow_es: 'Player of the Week',
      nameSecondRow_en: 'Player of the Week',
      nameSecondRow_pt: 'Player of the Week',
      years_es: '',
      years_en: '',
      years_pt: ''
    },{
      id: 5,
      nameFirstRow_es: 'United Soccer Coaches',
      nameFirstRow_en: 'United Soccer Coaches',
      nameFirstRow_pt: 'United Soccer Coaches',
      nameSecondRow_es: 'All-Region Second Team',
      nameSecondRow_en: 'All-Region Second Team',
      nameSecondRow_pt: 'All-Region Second Team',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    },{
      id: 6,
      nameFirstRow_es: 'NCAA All-Patriot',
      nameFirstRow_en: 'NCAA All-Patriot',
      nameFirstRow_pt: 'NCAA All-Patriot',
      nameSecondRow_es: 'League Second Team',
      nameSecondRow_en: 'League Second Team',
      nameSecondRow_pt: 'League Second Team',
      years_es: '2017 y 2018',
      years_en: '2017 and 2018',
      years_pt: '2017 e 2018'
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Aerial ball control',
      characteristic_pt: 'Jogo aéreo',
      characteristic_es: 'Juego aéreo',
      num: 19
    }, {
      id: 2,
      characteristic_en: 'Short passes',
      characteristic_pt: 'Passe curto',
      characteristic_es: 'Pase corto',
      num: 24
    }, {
      id: 3,
      characteristic_en: 'Clearing the ball',
      characteristic_pt: 'Saída de bola',
      characteristic_es: 'Salida de balón',
      num: 32
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Vision',
      characteristic_pt: 'Visão de jogo',
      characteristic_es: 'Visión de juego',
      num: 34
    }, {
      id: 2,
      characteristic_en: 'Defensive player',
      characteristic_pt: 'Jogadora defensiva',
      characteristic_es: 'Criterio defensivo',
      num: 10
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partida',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Speed',
      characteristic_pt: 'Velocidade',
      characteristic_es: 'Velocidad',
      num: 33
    }, {
      id: 2,
      characteristic_en: 'Power',
      characteristic_pt: 'Potência',
      characteristic_es: 'Potencia',
      num: 27
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: true,
      position: 'DFCi'
    },{
      id: 3,
      selected: true,
      position: 'DFCd'
    },{
      id: 4,
      selected: true,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'EMEK HEFER WFC',
      country: 'USA',
      years_es: '2020 - Actualidad',
      years_en: '2020 - Currently',
      years_pt: '2020 - Presente',
      image: require('../images/clubs/trayectoria/Emek-Hefer-WFC.png'),
      alt: 'Emek-Hefer-WFC-futbol-femenino'
    }, {
      id: 2,
      team: 'COLGATE RAIDERS',
      country: 'ESTADOS UNIDOS',
      years_es: '2016 - 2019',
      years_en: '2016 - 2019',
      years_pt: '2016 - 2019',
      image: require('../images/clubs/trayectoria/colgate-raiders-estados-unidos-futbol-femenino..png'),
      alt: 'colgate-raiders-estados-unidos-futbol-femenino'
    }, {
      id: 3,
      team: 'CONNECTICUT FC',
      country: 'ESTADOS UNIDOS',
      years_es: '2015 - 2016',
      years_en: '2015 - 2016',
      years_pt: '2015 - 2016',
      image: require('../images/clubs/trayectoria/connecticut-football-club.png'),
      alt: 'connecticut-football-club'
    }],
    instagramAccessToken: '',
    instagramUsername: 'leahlew__',
    instagramId: '2243710204',
    instagram: 'https://www.instagram.com/leahlew__/',
    twitter: '',
    facebook: 'https://www.facebook.com/leah.nicole.33',
    tikTok: '',
    lastsCampaigns: [],
    press: [],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 44,
    title: 'UNIK TEAM | Patri Guijarro | Jugadora de fútbol femenino',
    metadescription: 'Patri Guijarro, futbolista española, actualmente juega como centrocampista en el FC Barcelona de la Primera Iberdrola y en la Selección Española.',
    url: require('../images/players-profile-photo/patri-guijarro-prensa-diario-as.png'),
    alt: 'patri-guijarro-prensa-diario-as',
    name: 'PATRI GUIJARRO',
    nameUrl: 'patri-guijarro',
    team: 'FC Barcelona',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: 'SEL. ESPAÑOLA',
    selection_en: 'SPANISH TEAM',
    selection_pt: 'SEL. ESPANHOLA',
    mainPosition_es : 'CENTROCAMPISTA',
    mainPosition_en: 'MIDFIELDER',
    mainPosition_pt: 'MEIO-CAMPISTA',
    birthDate: '17 - 05 - 1998',
    birthYear: '1998',
    town: 'Palma de Mallorca',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,70',
    weight: '66',
    signaturePhoto: require('../images/players/patri-guijarro/jugadora-patri.guijarro-unik-sports-management.png'),
    signaturePhotoAlt: 'patri-guijarro-fc-barcelona',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/patri-guijarro/header/patri-guijarro-fc-barcelona-futbol-femenino.jpg'),
      alt: 'patri-guijarro-fc-barcelona-futbol-femenino'
    }, {
      id: 2,
      url: require('../images/players/patri-guijarro/header/patri-guijarro-seleccion-espanola-futbol-femenino.jpg'),
      alt: 'patri-guijarro-seleccion-espanola-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/patri-guijarro/header-mobile/patri-guijarro.fc-barcelona-futbol-femenino.jpg'),
    mobileHeaderPhotoAlt: 'patri-guijarro.fc-barcelona-futbol-femenino',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2019 - 2020',
      years_en: '2019 - 2020',
      years_pt: '2019 - 2020'
    }, {
      id: 2,
      nameFirstRow_es: 'Supercopa de España',
      nameFirstRow_en: 'Spanish Supercup',
      nameFirstRow_pt: 'Supercopa da Espanha',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2019 - 2020',
      years_en: '2019 - 2020',
      years_pt: '2019 - 2020',
    }, {
      id: 3,
      nameFirstRow_es: 'Copa Catalunya',
      nameFirstRow_en: 'Catalonia Cup',
      nameFirstRow_pt: 'Copa Catalunha',
      nameSecondRow_es: 'Femenina',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2015, 2016, 2017, 2018 y 2019',
      years_en: '2015, 2016, 2017, 2018 and 2019',
      years_pt: '2015, 2016, 2017, 2018 e 2019',
    }, {
      id: 4,
      nameFirstRow_es: 'Copa de España',
      nameFirstRow_en: 'Spanish Cup',
      nameFirstRow_pt: 'Copa da Espanha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2017 y 2018',
      years_en: '2017 and 2018',
      years_pt: '2017 e 2018'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'Champions League',
      nameSecondRow_en: 'Champions League',
      nameSecondRow_pt: 'Champions League',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2019',
      years_en: '2018-2019',
      years_pt: '2018-2019'
    }, {
      id: 2,
      nameFirstRow_es: 'Subcampeona del',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da Copa',
      nameSecondRow_es: 'Mundial Sub-20',
      nameSecondRow_en: 'U-20 World Cup',
      nameSecondRow_pt: 'do Mundo Sub-20',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Francia 2018',
      years_en: 'France 2018',
      years_pt: 'França 2018'
    }, {
      id: 3,
      nameFirstRow_es: 'Eurocopa Sub-19',
      nameFirstRow_en: 'UEFA Euro U-19',
      nameFirstRow_pt: 'Eurocopa Sub-19 ',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Irlanda del Norte 2017',
      years_en: 'Northern Ireland 2017',
      years_pt: 'Irlanda 2017'
    }, {
      id: 4,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'Eurocopa Sub-19',
      nameSecondRow_en: 'UEFA Euro U-19',
      nameSecondRow_pt: 'Eurocopa Sub-19',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Eslovaquia 2016',
      years_en: 'Slovakia 2016',
      years_pt: 'Eslovaquia 2016'
    }, {
      id: 5,
      nameFirstRow_es: 'Eurocopa Sub-17',
      nameFirstRow_en: 'UEFA Euro U-17',
      nameFirstRow_pt: 'Eurocopa Sub-17',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Islandia 2015',
      years_en: 'Iceland 2015',
      years_pt: 'Islândia 2015'
    }, {
      id: 6,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da Copa',
      nameSecondRow_es: 'Mundial Sub-17',
      nameSecondRow_en: 'U-17 World Cup',
      nameSecondRow_pt: 'do Mundo Sub-17',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Costa Rica 2014',
      years_en: 'Costa Rica 2014',
      years_pt: 'Costa Rica 2014'
    }, {
      id: 7,
      nameFirstRow_es: 'Subcampeona de la',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'Eurocopa Sub-17',
      nameSecondRow_en: 'UEFA Euro U-17',
      nameSecondRow_pt: 'Eurocopa Sub-17',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Inglaterra 2014',
      years_en: 'England 2014',
      years_pt: 'Inglaterra 2014'
    }, {
      id: 8,
      nameFirstRow_es: 'Medalla de bronze en la',
      nameFirstRow_en: 'Bronze medal in the',
      nameFirstRow_pt: 'Medalha de bronze na',
      nameSecondRow_es: 'Eurocopa Sub-17',
      nameSecondRow_en: 'UEFA Euro U-17',
      nameSecondRow_pt: 'Eurocopa Sub-17',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Suiza 2014',
      years_en: 'Switzerland 2014',
      years_pt: 'Suiza 2013'
    }],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Balón de Oro Adidas',
      nameFirstRow_en: 'Adidas Golden Ball',
      nameFirstRow_pt: 'Bola de Ouro Adidas',
      nameSecondRow_es: 'Mundial Sub-20',
      nameSecondRow_en: 'U-20 World Cup',
      nameSecondRow_pt: 'Copa do Mundo Sub-20',
      years_es: 'Francia 2018',
      years_en: 'France 2018',
      years_pt: 'França 2018'
    }, {
      id: 2,
      nameFirstRow_es: 'Bota de Oro Adidas',
      nameFirstRow_en: 'Adidas Golden Boot',
      nameFirstRow_pt: 'Chuteira de Ouro Adidas',
      nameSecondRow_es: 'Mundial Sub-20',
      nameSecondRow_en: 'U-20 World Cup',
      nameSecondRow_pt: 'Copa do Mundo Sub-20',
      years_es: 'Francia 2018',
      years_en: 'France 2018',
      years_pt: 'França 2018'
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Ball control with feet',
      characteristic_pt: 'Domínio bola c/ pés',
      characteristic_es: 'Dominio de pies',
      num: 14
    }, {
      id: 2,
      characteristic_en: 'Long passes',
      characteristic_pt: 'Passe longo',
      characteristic_es: 'Pase largo',
      num: 25
    }, {
      id: 3,
      characteristic_en: 'Penalty kicks',
      characteristic_pt: 'Lançamento de pênaltis',
      characteristic_es: 'Lanzamiento de penaltis',
      num: 21
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Vision',
      characteristic_pt: 'Visão de jogo',
      characteristic_es: 'Visión de juego',
      num: 34
    }, {
      id: 2,
      characteristic_en: 'Skilled passer',
      characteristic_pt: 'Ligação',
      characteristic_es: 'Asociación',
      num: 3
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partida',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 2,
      characteristic_en: 'Resistance',
      characteristic_pt: 'Resistência',
      characteristic_es: 'Resistencia',
      num: 31
    }, {
      id: 1,
      characteristic_en: 'Strenght',
      characteristic_pt: 'Força',
      characteristic_es: 'Fuerza',
      num: 17
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: true,
      position: 'LIB'
    },{
      id: 6,
      selected: true,
      position: 'MC'
    },{
      id: 7,
      selected: true,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: true,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'FC BARCELONA',
      country: 'ESPAÑA',
      years_es: '2015 - Actualidad',
      years_en: '2015 - Currently',
      years_pt: '2015 - Presente',
      image: require('../images/clubs/trayectoria/fc-barcelona-espana-futbol-femenino.png'),
      alt: 'fc-barcelona-espana-futbol-femenino'
    }, {
      id: 2,
      team: 'UD COLLERENSE',
      country: 'ESPAÑA',
      nameSecondRow_pt: '',
      years_es: '2008 - 2015',
      years_en: '2008 - 2015',
      years_pt: '2008 - 2015',
      image: require('../images/clubs/trayectoria/ud-collerense-futbol-femenino.png'),
      alt: 'ud-collerense-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'patri8guijarro',
    instagramId: '328170868',
    instagram: 'https://www.instagram.com/patri8guijarro/',
    twitter: 'https://twitter.com/Patri8Guijarro',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'NIKE',
      url: 'https://www.instagram.com/p/B7qZ0oSiuMY/',
      image: require('../images/players/patri-guijarro/last-campaigns/campana-patri-guijarro-game-nike-football.jpg'),
      alt: 'campana-patri-guijarro-game-nike-football'
    }, {
      id: 2,
      brand: 'NIKE',
      url: 'https://www.instagram.com/p/BwE7yYXBlsP/',
      image: require('../images/players/patri-guijarro/last-campaigns/campana-patri-guijarro-nike.jpg'),
      alt: 'campana-patri-guijarro-nike'
    }, {
      id: 3,
      brand: 'GAME READY',
      url: '',
      image: require('../images/players/patri-guijarro/last-campaigns/campana-patri-guijarro-game-ready.gif'),
      alt: 'campana-patri-guijarro-game-ready'
    }],
    press: [{
      id: 1,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/2020/02/09/5e3ea198e2704e1ab38b45ca.html',
      image: require('../images/players/patri-guijarro/press/patri-guijarro-prensa-marca.jpg'),
      alt: ''
    }, {
      id: 2,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/femenino/20200207/473321844612/patri-guijarro-mi-gol-sabia-que-si-controlaba-se-me-iba-a-ir.html',
      image: require('../images/players/patri-guijarro/press/patri-guijarro-prensa-mundo-deportivo.jpg'),
      alt: ''
    }, {
      id: 3,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2020/05/03/femenino/1588520721_070981.html',
      image: require('../images/players/patri-guijarro/press/patri-guijarro-prensa-diario-as.jpg'),
      alt: ''
    }, {
      id: 4,
      media: 'TVE',
      url: 'https://www.rtve.es/alacarta/videos/futbol/patri-guijarro-quedamos-ganas-mas/5441860/',
      image: require('../images/players/patri-guijarro/press/patri-guijarro-prensa-tve.jpg'),
      alt: ''
    }, {
      id: 5,
      media: 'FIFA',
      url: 'https://www.fifa.com/womensworldcup/news/guijarro-times-her-return-to-perfection',
      image: require('../images/players/patri-guijarro/press/patri-guijarro-prensa-mundo-fifa.jpg'),
      alt: ''
    }, {
      id: 6,
      media: 'EL PAÍS',
      url: 'https://elpais.com/deportes/2019/05/23/actualidad/1558608053_838298.html',
      image: require('../images/players/patri-guijarro/press/patri-guijarro-prensa-el-pais.jpg'),
      alt: ''
    }, {
      id: 7,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/fc-barcelona/20200823/482969225456/patri-guijarro.html',
      image: require('../images/players/patri-guijarro/press/patri-guijarro-prensa-mundo-deportivo-2.jpg'),
      alt: ''
    }, {
      id: 8,
      media: 'AGENCIA EFE',
      url: 'https://www.efe.com/efe/espana/deportes/patri-guijarro-he-demostrado-en-el-campo-lo-que-soy/10006-3994199',
      image: require('../images/players/patri-guijarro/press/patri-guijarro-prensa-agencia-EFE.jpg'),
      alt: ''
    }, {
      id: 9,
      media: 'THE CHAMPIONS VOICE',
      url: 'https://www.thechampionsvoice.com/lider-de-futuro-patri-guijarro-supera-a-aitana-bonmati-lucia-garcia-y-nahikari-en-la-votacion-fifa/',
      image: require('../images/players/patri-guijarro/press/patri-guijarro-prensa-the-champions-voice.jpg'),
      alt: ''
    }, {
      id: 10,
      media: 'VAVEL',
      url: 'https://www.vavel.com/es/futbol/2020/08/06/fc-barcelona/1031318-patri-guijarro-podemos-volver-incluso-mejor-de-como-acabamos.html',
      image: require('../images/players/patri-guijarro/press/patri-guijarro-prensa-vavel.jpg'),
      alt: ''
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 45,
    title: 'UNIK TEAM | Julia Simic | Jugadora de fútbol femenino',
    metadescription: 'Julia Simic, futbolista alemana, actualmente juega como centrocampista en el AC Milan de la Serie A Italiana y en la Selección Alemana.',
    url: require('../images/players-profile-photo/juila-simic-ac-milan.png'),
    alt: 'juila-simic-ac-milan',
    name: 'JULIA SIMIC',
    nameUrl: 'julia-simic',
    team: 'AC Milan',
    league_es: 'Serie A (Italia)',
    league_en: 'Serie A (Italy)',
    league_pt: 'Serie A (Itália)',
    selection_es: 'SEL. ALEMANA',
    selection_en: 'GERMAN TEAM',
    selection_pt: 'SEL. ALEMÃ',
    mainPosition_es: 'CENTROCAMPISTA',
    mainPosition_en: 'MIDFIELDER',
    mainPosition_pt: 'MEIO-CAMPISTA',
    birthDate: '14 - 05 - 1989',
    birthYear: '1989',
    town: 'Fürth',
    country: 'Alemania',
    nationality_es : 'Alemana',
    nationality_en : 'German',
    nationality_pt: 'Alemã',
    height: '1,62',
    weight: '51',
    signaturePhoto: require('../images/players/julia-simic/Julia-Simic-Unik-Sports-Management.png'),
    signaturePhotoAlt: 'patri-guijarro-fc-barcelona',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/julia-simic/header/julia-simic-westham-united-futbol-femenino.jpg'),
      alt: 'julia-simic-westham-united-futbol-femenino'
    }, {
      id: 2,
      url: require('../images/players/julia-simic/header/julia-simic-wolsburg-futbol-femenino..jpg'),
      alt: 'julia-simic-wolsburg-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/julia-simic/header-mobile/julia-simic-ac-milan-futbol-femenino.jpg'),
    mobileHeaderPhotoAlt: 'julia-simic-ac-milan-futbol-femenino',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Subcampeona de la Copa de Inglaterra',
      nameFirstRow_en: 'Runner-up of the English Cup',
      nameFirstRow_pt: 'Vice-campeã da Copa da Inglaterra',
      nameSecondRow_es: "Women's FA Cup",
      nameSecondRow_en: "Women's FA Cup",
      nameSecondRow_pt: "Women's FA Cup",
      team_es: 'West Ham United',
      team_en: 'West Ham United',
      team_pt: 'West Ham United',
      years_es: '2018 - 2019',
      years_en: '2018 - 2019',
      years_pt: '2018 - 2019'
    }, {
      id: 2,
      nameFirstRow_es: 'Primera División Alemana',
      nameFirstRow_en: 'German First Division',
      nameFirstRow_pt: 'Primeira Divisão Alemã',
      nameSecondRow_es: '"Frauen Bundesliga"',
      nameSecondRow_en: '"Frauen Bundesliga"',
      nameSecondRow_pt: '"Frauen Bundesliga"',
      team_es: 'VfL Wolfsburg',
      team_en: 'VfL Wolfsburg',
      team_pt: 'VfL Wolfsburg',
      years_es: '2016 - 2017',
      years_en: '2016 - 2017',
      years_pt: '2016 - 2017'
    }, {
      id: 3,
      nameFirstRow_es: 'Copa Alemana',
      nameFirstRow_en: 'German Cup',
      nameFirstRow_pt: 'Copa da Alemanha',
      nameSecondRow_es: '"DFB-Pokal Frauen"',
      nameSecondRow_en: '"DFB-Pokal Frauen"',
      nameSecondRow_pt: '"DFB-Pokal Frauen"',
      team_es: 'VfL Wolfsburg',
      team_en: 'VfL Wolfsburg',
      team_pt: 'VfL Wolfsburg',
      years_es: '2014 - 2015, 2015 - 2016 y 2016 - 2017',
      years_en: '2014 - 2015, 2015 - 2016 and 2016 - 2017',
      years_pt: '2014 - 2015, 2015 - 2016 e 2016 - 2017'
    }, {
      id: 4,
      nameFirstRow_es: 'Copa Alemana',
      nameFirstRow_en: 'German Cup',
      nameFirstRow_pt: 'Copa da Alemanha',
      nameSecondRow_es: '"DFB-Pokal Frauen"',
      nameSecondRow_en: '"DFB-Pokal Frauen"',
      nameSecondRow_pt: '"DFB-Pokal Frauen"',
      team_es: 'FC Bayern Munich',
      team_en: 'FC Bayern Munich',
      team_pt: 'FC Bayern Munich',
      years_es: '2011 - 2012',
      years_en: '2011 - 2012',
      years_pt: '2011 - 2012'
    }, {
      id: 5,
      nameFirstRow_es: 'Copa Alemana',
      nameFirstRow_en: 'German Cup',
      nameFirstRow_pt: 'Copa da Alemanha',
      nameSecondRow_es: '"Bundesliga Cup"',
      nameSecondRow_en: '"Bundesliga Cup"',
      nameSecondRow_pt: '"Bundesliga Cup"',
      team_es: 'FC Bayern Munich',
      team_en: 'FC Bayern Munich',
      team_pt: 'FC Bayern Munich',
      years_es: '2011',
      years_en: '2011',
      years_pt: '2011'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'UEFA Euro U-19',
      nameFirstRow_en: 'UEFA Euro U-19',
      nameFirstRow_pt: 'Eurocopa Sub-19',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Alemana',
      team_en: 'German National Team',
      team_pt: 'Seleção Alemã',
      years_es: 'Iceland 2007',
      years_en: 'Iceland 2007',
      years_pt: 'Islândia 2007'
    }],
    individualAwards: [],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Assists',
      characteristic_pt: 'Assistências',
      characteristic_es: 'Asistencias',
      num: 2
    }, {
      id: 2,
      characteristic_en: 'Free kicks',
      characteristic_pt: 'Lançamento de faltas',
      characteristic_es: 'Lanzamiento de faltas',
      num: 20
    }, {
      id: 3,
      characteristic_en: 'Ball control',
      characteristic_pt: 'Controle de bola',
      characteristic_es: 'Control de balón',
      num: 9
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Vision',
      characteristic_pt: 'Visão de jogo',
      characteristic_es: 'Visión de juego',
      num: 34
    }, {
      id: 2,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Leader',
      characteristic_pt: 'Líder',
      characteristic_es: 'Líder',
      num: 23
    }],
    physicalCharacteristics: [{
      id: 2,
      characteristic_en: 'Resistance',
      characteristic_pt: 'Resistência',
      characteristic_es: 'Resistencia',
      num: 31
    }, {
      id: 1,
      characteristic_en: 'Strenght',
      characteristic_pt: 'Força',
      characteristic_es: 'Fuerza',
      num: 17
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: true,
      position: 'MCO'
    },{
      id: 8,
      selected: true,
      position: 'INTi'
    },{
      id: 9,
      selected: true,
      position: 'INTd'
    },{
      id: 10,
      selected: true,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'AC MILAN',
      country: 'ITALIA',
      years_es: '2020 - Actualidad',
      years_en: '2020 - Currently',
      years_pt: '2020 - Presente',
      image: require('../images/clubs/trayectoria/ac-milan-italia-futbol-femenino.png'),
      alt: 'ac-milan-italia-futbol-femenino'
    }, {
      id: 2,
      team: 'WEST HAM UNITED',
      country: 'INGLATERRA',
      years_es: '2018 - 2020',
      years_en: '2018 - 2020',
      years_pt: '2018 - 2020',
      image: require('../images/clubs/trayectoria/west ham-futbol-femenino.png'),
      alt: 'west-ham-futbol-femenino'
    }, {
      id: 3,
      team: 'SC FREIBURG',
      country: 'ALEMANIA',
      years_es: '2017 - 2018',
      years_en: '2017 - 2018',
      years_pt: '2017 - 2018',
      image: require('../images/clubs/trayectoria/freiburg-futbol-femenino.png'),
      alt: 'freiburg-futbol-femenino'
    }, {
      id: 4,
      team: 'VFL WOLFSBURG',
      country: 'ALEMANIA',
      years_es: '2015 - 2017',
      years_en: '2015 - 2017',
      years_pt: '2015 - 2017',
      image: require('../images/clubs/trayectoria/wolfsburg-futbol-femenino.png'),
      alt: 'wolfsburg-futbol-femenino'
    }, {
      id: 5,
      team: '1. FFC TURBINE POTSDAM',
      country: 'ALEMANIA',
      years_es: '2013 - 2015',
      years_en: '2013 - 2015',
      years_pt: '2013 - 2015',
      image: require('../images/clubs/trayectoria/turbine-postdam-alemania-futbol-femenino.png'),
      alt: 'turbine-postdam-alemania-futbol-femenino'
    }, {
      id: 6,
      team: 'FC BAYERN MUNICH',
      country: 'ALEMANIA',
      years_es: '2005 - 2013',
      years_en: '2005 - 2013',
      years_pt: '2005 - 2013',
      image: require('../images/clubs/trayectoria/bayern-futbol-femenino.png'),
      alt: 'bayern-futbol-femenino'
    } ],
    instagramAccessToken: '',
    instagramUsername: 'simicjulia',
    instagramId: '307910648',
    instagram: 'https://www.instagram.com/simicjulia/',
    twitter: 'https://twitter.com/juliasimic',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'Puma',
      url: 'https://www.instagram.com/p/B1vqJG2gpvf/',
      image: require('../images/players/julia-simic/last-campaigns/campanas-julia-simic-puma.jpg'),
      alt: 'campanas-julia-simic-puma'
    }, {
      id: 2,
      brand: 'BALR',
      url: 'https://www.instagram.com/p/B45obFloet6/',
      image: require('../images/players/julia-simic/last-campaigns/campanas-julia-simic-balr.jpg'),
      alt: 'campanas-julia-simic-balr'
    }, {
      id: 3,
      brand: 'Footbal Ramble Daily',
      url: 'https://www.instagram.com/p/B7WkalpIRhq/',
      image: require('../images/players/julia-simic/last-campaigns/campanas-julia-simic-football-ramble-daily.jpg'),
      alt: 'campanas-julia-simic-football-ramble-daily'
    }, {
      id: 4,
      brand: 'Opa Helmut',
      url: 'https://www.instagram.com/p/CB8Wtq6I_DE/',
      image: require('../images/players/julia-simic/last-campaigns/campanas-julia-simic-opa-welmut.jpg'),
      alt: 'campanas-julia-simic-opa-welmut'
    }, {
      id: 5,
      brand: 'FIFA',
      url: 'https://www.instagram.com/p/CBN3ZTtoYsa/',
      image: require('../images/players/julia-simic/last-campaigns/campanas-julia-simic-fifa.jpg'),
      alt: 'campanas-julia-simic-fifa'
    }, {
      id: 6,
      brand: 'Rad-Sportshop Odenwaldbike',
      url: 'https://www.instagram.com/p/B_Z560Aon4f/',
      image: require('../images/players/julia-simic/last-campaigns/campanas-julia-simic-owden-bikejpg.jpg'),
      alt: 'campanas-julia-simic-owden-bike'
    }, {
      id: 7,
      brand: 'FIFA',
      url: 'https://www.instagram.com/p/CAQIvKYoU8s/',
      image: require('../images/players/julia-simic/last-campaigns/campanas-julia-simic-fifatv.jpg'),
      alt: 'campanas-julia-simic-fifatv'
    }, {
      id: 8,
      brand: 'Smilodox',
      url: 'https://www.instagram.com/smilodox/',
      image: require('../images/players/julia-simic/last-campaigns/campanas-julia-simic-smilodox.jpg'),
      alt: 'campanas-julia-simic-smilodox'
    }, {
      id: 9,
      brand: 'Copa 90',
      url: 'https://www.instagram.com/p/B5s4NFaFrt_/',
      image: require('../images/players/julia-simic/last-campaigns/campanas-julia-simic-copa90.jpg'),
      alt: 'campanas-julia-simic-copa90'
    }],
    press: [{
      id: 1,
      media: 'BBC Sport',
      url: 'https://www.bbc.com/sport/football/53715659',
      image: require('../images/players/julia-simic/press/julia-simic-prensa-bbc-sport.jpg'),
      alt: 'julia-simic-prensa-bbc-sport'
    }, {
      id: 2,
      media: 'BBC Sport',
      url: 'https://www.bbc.com/sport/av/football/50183366',
      image: require('../images/players/julia-simic/press/julia-simic-prensa-bbc-sport-2.jpg'),
      alt: 'julia-simic-prensa-bbc-sport-2'
    }, {
      id: 3,
      media: 'FIFA',
      url: 'https://www.fifa.com/who-we-are/news/footballers-amplify-call-to-end-racism-violence-and-discrimination',
      image: require('../images/players/julia-simic/press/julia-simic-prensa-fifa.jpg'),
      alt: 'julia-simic-prensa-fifa'
    }, {
      id: 4,
      media: 'Milan News 24h',
      url: 'https://www.milannews24.com/esclusiva-mn24-milan-femminile-ag-julia-simic-e-un-acquisto-top/',
      image: require('../images/players/julia-simic/press/julia-simic-prensa-milan-new-24.jpg'),
      alt: 'julia-simic-prensa-milan-new-24'
    }, {
      id: 5,
      media: 'Zeenews',
      url: 'https://zeenews.india.com/football/womens-football-julia-simic-joins-ac-milan-from-west-ham-united-2301830.html',
      image: require('../images/players/julia-simic/press/julia-simic-prensa-zeenews.jpg'),
      alt: 'julia-simic-prensa-zeenews'
    }, {
      id: 6,
      media: 'FIFA',
      url: 'https://es.fifa.com/who-we-are/videos/julia-simic-beactive-and-remain-healthyathome-at-all-times',
      image: require('../images/players/julia-simic/press/julia-simic-prensa-fifa-2.jpg'),
      alt: 'julia-simic-prensa-fifa-2'
    }, {
      id: 7,
      media: 'Total Football Analysis',
      url: 'https://totalfootballanalysis.com/player-analysis/julia-simic-west-ham-united-women-2019-2020-scout-report-tactical-analysis-tactics',
      image: require('../images/players/julia-simic/press/julia-simic-prensa-total-football-analysis.jpg'),
      alt: 'julia-simic-prensa-total-football-analysis'
    }, {
      id: 8,
      media: 'Live Mint',
      url: 'https://www.livemint.com/mint-lounge/features/these-four-recent-transfers-may-be-a-gamechanger-for-women-s-football-in-europe-11597638034471.html',
      image: require('../images/players/julia-simic/press/julia-simic-prensa-live-mint.jpg'),
      alt: 'julia-simic-prensa-live-mint'
    }, {
      id: 9,
      media: 'Vavel',
      url: 'https://www.vavel.com/en/football/2019/01/10/womens-football/963082-julia-simic-talks-about-settling-at-west-ham.html',
      image: require('../images/players/julia-simic/press/julia-simic-prensa-vavel.jpg'),
      alt: 'julia-simic-prensa-vavel'
    }, {
      id: 10,
      media: 'DerbyDerbyDerby',
      url: 'https://www.derbyderbyderby.it/notizie-calcio/milan-femminile-julia-simic-e-ufficialmente-rossonera/',
      image: require('../images/players/julia-simic/press/julia-simic-prensa-DerbyDerbyDerby.jpg'),
      alt: 'julia-simic-prensa-DerbyDerbyDerby'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 46,
    title: 'UNIK TEAM | Lucía León | Jugadora de fútbol femenino',
    metadescription: '',
    url: require('../images/players-profile-photo/lucia-leon-tottenham-hotspur.png'),
    alt: 'lucia-leon-tottenham-hotspur',
    name: 'LUCÍA LEÓN',
    nameUrl: 'lucia-leon',
    team: 'Tottenham Hotspur',
    league_es: "Barclays FA Women's Super League (Inglaterra)",
    league_en: "Barclays FA Women's Super League (England)",
    league_pt: "Barclays FA Women's Super League (Inglaterra)",
    selection_es: 'SEL. ESPAÑOLA',
    selection_en: 'SPANISH TEAM',
    selection_pt: 'SEL. ESPANHOLA',
    mainPosition_es : 'DEFENSA',
    mainPosition_en: 'DEFENDER',
    mainPosition_pt: 'DEFESA',
    birthDate: '14 - 08 - 1997',
    birthYear: '1997',
    town: 'Madrid',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,66',
    weight: '63',
    signaturePhoto: require('../images/players/lucia-leon/lucia-leon-unik-sports-management.png'),
    signaturePhotoAlt: 'lucia-leon-unik-sports-management',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/lucia-leon/header/lucia-leon-tottenham-hotspur-futbol-femenino.jpg'),
      alt: 'lucia-leon-tottenham-hotspur-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/lucia-leon/header-mobile/lucia-leon-tottenham-hotspur-futbol-femenino.jpg'),
    mobileHeaderPhotoAlt: 'lucia-leon-tottenham-hotspur-futbol-femenino',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Campeonato de España de',
      nameFirstRow_en: 'U-16 Spanish Championship',
      nameFirstRow_pt: 'Campeonato Espanhol de',
      nameSecondRow_es: "Sel. Autonómicas Sub-16",
      nameSecondRow_en: "",
      nameSecondRow_pt: 'Seleções Autônomas Sub-16',
      team_es: 'Selección Madrileña',
      team_en: 'Madrid Autonomus Team',
      team_pt: 'Seleção Madrilenha',
      years_es: '',
      years_en: '',
      years_pt: ''
    }, {
      id: 2,
      nameFirstRow_es: 'Copa Inglesa',
      nameFirstRow_en: 'English Cup',
      nameFirstRow_pt: 'Copa Inglesa',
      nameSecondRow_es: "FA Women's National League Cup",
      nameSecondRow_en: "FA Women's National League Cup",
      nameSecondRow_pt: "FA Women's National League Cup",
      team_es: 'Tottenham Hotspur',
      team_en: 'Tottenham Hotspur',
      team_pt: 'Tottenham Hotspur',
      years_es: '2015 - 2016 y 2016 - 2017',
      years_en: '2015 - 2016 and 2016 - 2017',
      years_pt: '2015 - 2016 e 2016 - 2017'
    }, {
      id: 3,
      nameFirstRow_es: 'Ascenso a Primera División',
      nameFirstRow_en: 'Promotion to the First Division',
      nameFirstRow_pt: 'Subida à Primeira Divisão',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Tottenham Hotspur',
      team_en: 'Tottenham Hotspur',
      team_pt: 'Tottenham Hotspur',
      years_es: '2015 - 2016 y 2018 - 2019',
      years_en: '2015 - 2016 and 2018 - 2019',
      years_pt: '2015 - 2016 e 2018 - 2019'
    }],
    internationalTournament: [],
    individualAwards: [],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Dribbling',
      characteristic_pt: 'Drible',
      characteristic_es: 'Regate',
      num: 30
    }, {
      id: 2,
      characteristic_en: 'Ball control',
      characteristic_pt: 'Controle de bola',
      characteristic_es: 'Control de balón',
      num: 9
    }, {
      id: 3,
      characteristic_en: 'Ball handling',
      characteristic_pt: 'Condução',
      characteristic_es: 'Conducción',
      num: 8
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Offensive player',
      characteristic_pt: 'Jogadora ofensiva',
      characteristic_es: 'Criterio ofensivo',
      num: 11
    }, {
      id: 2,
      characteristic_en: 'Space control',
      characteristic_pt: 'Colocação no campo',
      characteristic_es: 'Colocación',
      num: 6
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Strong personality',
      characteristic_pt: 'Personalidade forte',
      characteristic_es: 'Carácter',
      num: 5
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Strenght',
      characteristic_pt: 'Força',
      characteristic_es: 'Fuerza',
      num: 17
    }, {
      id: 2,
      characteristic_en: 'Speed',
      characteristic_pt: 'Velocidade',
      characteristic_es: 'Velocidad',
      num: 33
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: true,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: true,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: true,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'TOTTENHAM HOTSPUR',
      country: 'INGLATERRA',
      years_es: '2013 - Actualidad',
      years_en: '2013 - Currently',
      years_pt: '2013 - Presente',
      image: require('../images/clubs/trayectoria/Tottenham-futbol-femenino.png'),
      alt: 'Tottenham-futbol-femenino'
    }, {
      id: 2,
      team: 'MADRID CFF',
      country: 'ESPAÑA',
      years_es: '2011 - 2013',
      years_en: '2011 - 2013',
      years_pt: '2011 - 2013',
      image: require('../images/clubs/trayectoria/madrid-cff-espana-futbol-femenino.png'),
      alt: 'madrid-cff-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'lucia.leon2',
    instagramId: '598303444',
    instagram: 'https://www.instagram.com/lucia.leon2/',
    twitter: 'https://twitter.com/luciamarte90',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [],
    press: [{
      id: 1,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2019/11/05/femenino/1572952505_349854.html',
      image: require('../images/players/lucia-leon/press/lucia-leon-diario-as.jpg'),
      alt: 'lucia-leon-diario-as'
    }, {
      id: 2,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/2016/10/29/580cd2a1e2704e8b0b8b45ac.html',
      image: require('../images/players/lucia-leon/press/lucia-leon-marca.jpg'),
      alt: 'lucia-leon-marca'
    }, {
      id: 3,
      media: 'TOTTENHAM HOTSPUR',
      url: 'https://www.tottenhamhotspur.com/news/2020/april/18-82-qa-lucia-leon/',
      image: require('../images/players/lucia-leon/press/lucia-leon-tottenham-hotspur.jpg'),
      alt: 'lucia-leon-tottenham-hotspur'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }, {
    id: 47,
    title: 'UNIK TEAM | Mariona Caldentey | Jugadora de fútbol femenino',
    metadescription: '',
    url: require('../images/players-profile-photo/mariona-caldentey-fc-barcelona.png'),
    alt: 'mariona-caldentey-fc-barcelona',
    name: 'MARIONA CALDENTEY',
    nameUrl: 'mariona-caldentey',
    team: 'FC Barcelona',
    league_es: 'Primera Iberdrola (España)',
    league_en: 'Primera Iberdrola (Spain)',
    league_pt: 'Primera Iberdrola (Espanha)',
    selection_es: 'SEL. ESPAÑOLA',
    selection_en: 'SPANISH TEAM',
    selection_pt: 'SEL. ESPANHOLA',
    mainPosition_es : 'DELANTERA',
    mainPosition_en: 'FORWARD',
    mainPosition_pt: 'ATACANTE',
    birthDate: '19 - 03 - 1996',
    birthYear: '1996',
    town: 'Felanitx',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,64',
    weight: '62',
    signaturePhoto: require('../images/players/mariona-caldentey/mariona-caldentey-unik-sports-management.png'),
    signaturePhotoAlt: 'mariona-caldentey-unik-sports-management',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/mariona-caldentey/header/mariona-caldentey-seleccion-espanola.jpg'),
      alt: 'mariona-caldentey-seleccion-espanola'
    }],
    mobileHeaderPhoto: require('../images/players/mariona-caldentey/header-mobile/mariona-caldentey-unik-sports-management.jpg'),
    mobileHeaderPhotoAlt: 'mariona-caldentey-unik-sports-management',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera Division Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2014 - 2015 y 2019 - 2020',
      years_en: '2014 - 2015 and 2019 - 2020',
      years_pt: '2014 - 2015 e 2019 - 2020'
    }, {
      id: 2,
      nameFirstRow_es: 'Supercopa de España',
      nameFirstRow_en: 'Spanish Supercup',
      nameFirstRow_pt: 'Supercopa da Espanha',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2019 - 2020',
      years_en: '2019 - 2020',
      years_pt: '2019 - 2020'
    }, {
      id: 3,
      nameFirstRow_es: 'Copa Catalunya',
      nameFirstRow_en: 'Catalonia Cup',
      nameFirstRow_pt: 'Copa Catalunha',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2014, 2015, 2016, 2017, 2018 y 2019',
      years_en: '2014, 2015, 2016, 2017, 2018 and 2019',
      years_pt: '2014, 2015, 2016, 2017, 2018 e 2019',
    }, {
      id: 4,
      nameFirstRow_es: 'Copa de España',
      nameFirstRow_en: 'Spanish Cup',
      nameFirstRow_pt: 'Copa Catalunha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2017 y 2018',
      years_en: '2017 and 2018',
      years_pt: '2017 e 2018',
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Subcampeona del',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'Champions League',
      nameSecondRow_en: 'Champions League',
      nameSecondRow_pt: 'Champions League',
      team_es: 'FC Barcelona',
      team_en: 'FC Barcelona',
      team_pt: 'FC Barcelona',
      years_es: '2018-2019',
      years_en: '2018-2019',
      years_pt: '2018-2019',
    }, {
      id: 2,
      nameFirstRow_es: 'Algarve Cup',
      nameFirstRow_en: 'Algarve Cup',
      nameFirstRow_pt: 'Copa do Algarve',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Portugal 2017',
      years_en: 'Portugal 2017',
      years_pt: 'Portugal 2017',
    }, {
      id: 3,
      nameFirstRow_es: 'Subcampeona del',
      nameFirstRow_en: 'Runner-up of the',
      nameFirstRow_pt: 'Vice-campeã da',
      nameSecondRow_es: 'UEFA Euro U-19',
      nameSecondRow_en: 'UEFA Euro U-19',
      nameSecondRow_pt: 'Eurocopa Sub-19',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: '2013 - 2014 y 2014 - 2015',
      years_en: '2013 - 2014 and 2014 - 2015',
      years_pt: '2013 - 2014 e 2014 - 2015',
    }],
    individualAwards: [],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Goals',
      characteristic_pt: 'Gol',
      characteristic_es: 'Gol',
      num: 18
    }, {
      id: 2,
      characteristic_en: 'Assists',
      characteristic_pt: 'Assistências',
      characteristic_es: 'Asistencias',
      num: 2
    }, {
      id: 3,
      characteristic_en: 'Penalty kicks',
      characteristic_pt: 'Lançamento de pênaltis',
      characteristic_es: 'Lanzamiento de penaltis',
      num: 21
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Vision',
      characteristic_pt: 'Visão de jogo',
      characteristic_es: 'Visión de juego',
      num: 34
    }, {
      id: 2,
      characteristic_en: 'Space control',
      characteristic_pt: 'Colocação no campo',
      characteristic_es: 'Colocación',
      num: 6
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Game reading',
      characteristic_pt: 'Leitura de jogo',
      characteristic_es: 'Lectura de partido',
      num: 22
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Resistance',
      characteristic_pt: 'Resistência',
      characteristic_es: 'Resistencia',
      num: 31
    }, {
      id: 2,
      characteristic_en: 'Strenght',
      characteristic_pt: 'Força',
      characteristic_es: 'Fuerza',
      num: 17
    }],
    positions: [{
      id: 0,
      selected: false,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: true,
      position: 'MP'
    },{
      id: 11,
      selected: true,
      position: 'EXTi'
    },{
      id: 12,
      selected: true,
      position: 'EXTd'
    },{
      id: 13,
      selected: true,
      position: 'SDC'
    },{
      id: 14,
      selected: true,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'FC BARCELONA',
      country: 'ESPAÑA',
      years_es: '2014 - Actualidad',
      years_en: '2014 - Currently',
      years_pt: '2014 - Presente',
      image: require('../images/clubs/trayectoria/fc-barcelona-espana-futbol-femenino.png'),
      alt: 'fc-barcelona-espana-futbol-femenino'
    }, {
      id: 2,
      team: 'UD COLLERENSE',
      country: 'ESPAÑA',
      years_es: '2012 - 2014',
      years_en: '2012 - 2014',
      years_pt: '2012 - 2014',
      image: require('../images/clubs/trayectoria/ud-collerense-futbol-femenino.png'),
      alt: 'ud-collerense-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'mariona8co',
    instagramId: '225411731',
    instagram: 'https://www.instagram.com/mariona8co/',
    twitter: 'https://twitter.com/mariona8co',
    facebook: '',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'NIKE',
      url: 'https://www.instagram.com/p/B-PHxUFKlqI/',
      image: require('../images/players/mariona-caldentey/last-campaigns/campana-mariona-caldentey-nike.jpg'),
      alt: 'campana-mariona-caldentey-nike'
    }, {
      id: 2,
      brand: 'NIKE',
      url: 'https://www.instagram.com/p/B9Hq0rnKm9Z/',
      image: require('../images/players/mariona-caldentey/last-campaigns/Mariona Caldentey nk.jpg'),
      alt: 'Mariona Caldentey-nk'
    }, {
      id: 3,
      brand: 'FC BARCELONA',
      url: 'https://www.instagram.com/p/Bzp9ma1Icej/',
      image: require('../images/players/mariona-caldentey/last-campaigns/campana-mariona-caldentey-fc-barcelona.jpg'),
      alt: 'campana-mariona-caldentey-fc-barcelona'
    }, {
      id: 4,
      brand: 'FC BARCELONA',
      url: 'https://www.instagram.com/p/ByStOykIQWR/',
      image: require('../images/players/mariona-caldentey/last-campaigns/campana-mariona-caldentey-fc-barcelona_.jpg'),
      alt: 'campana-mariona-caldentey-fc-barcelona_'
    }],
    press: [{
      id: 1,
      media: 'VAVEL',
      url: 'https://www.vavel.com/es/futbol/2020/09/24/seleccion-espanola/1038602-mariona-caldentey-queria-jugar-a-futbol-casi-desde-que-tengo-memoria.html',
      image: require('../images/players/mariona-caldentey/press/mariona-caldentey-prensa-vavel.jpg'),
      alt: 'mariona-caldentey-prensa-vavel'
    }, {
      id: 2,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/primera-division/2020/09/21/5f64a93f22601dea3e8b4590.html',
      image: require('../images/players/mariona-caldentey/press/mariona-caldentey-prensa-marca.jpg'),
      alt: 'mariona-caldentey-prensa-marca'
    }, {
      id: 3,
      media: 'TVE',
      url: 'https://www.rtve.es/alacarta/videos/futbol/moldavia-espana-gol-caldentey-0-7/5666373/',
      image: require('../images/players/mariona-caldentey/press/mariona-caldentey-prensa-television-espanola.jpg'),
      alt: 'mariona-caldentey-prensa-television-espanola'
    }, {
      id: 4,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2020/07/10/femenino/1594337650_517383.html',
      image: require('../images/players/mariona-caldentey/press/mariona-caldentey-prensa-diario-as.jpg'),
      alt: 'mariona-caldentey-prensa-diario-as'
    }, {
      id: 5,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/fc-barcelona/20200105/472720692151/mariona-caldentey-ha-sido-un-dia-para-no-olvidar.html',
      image: require('../images/players/mariona-caldentey/press/mariona-caldentey-prensa-mundo-deportivo.jpg'),
      alt: 'mariona-caldentey-prensa-mundo-deportivo'
    }, {
      id: 6,
      media: 'MARCA 2',
      url: 'https://www.marca.com/en/football/barcelona/2018/07/27/5b5ae7c6468aeba6368b4640.html',
      image: require('../images/players/mariona-caldentey/press/mariona-caldentey-prensa-marca-2.jpg'),
      alt: 'mariona-caldentey-prensa-marca-2'
    }, {
      id: 7,
      media: 'EL PAÍS',
      url: 'https://elpais.com/deportes/2019/06/09/actualidad/1560080826_428178.html',
      image: require('../images/players/mariona-caldentey/press/mariona-caldentey-prensa-el-pais.jpg'),
      alt: 'mariona-caldentey-prensa-el-pais'
    }, {
      id: 8,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/fc-barcelona/20191022/471105563781/mariona-y-alexia-un-tandem-para-disfrutar-del-futbol.html',
      image: require('../images/players/mariona-caldentey/press/mariona-caldentey-prensa-mundo-deportivo-2.jpg'),
      alt: 'mariona-caldentey-prensa-mundo-deportivo-2'
    }, {
      id: 9,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2019/05/27/mas_futbol/1558988661_164487.html',
      image: require('../images/players/mariona-caldentey/press/mariona-caldentey-prensa-diario-as-2.jpg'),
      alt: 'mariona-caldentey-prensa-diario-as-2'
    }, {
      id: 10,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/20190624/463092856590/mariona-caldentey-el-futuro-puede-ser-nuestro.html',
      image: require('../images/players/mariona-caldentey/press/mariona-caldentey-prensa-mundo-deportivo-3.jpg'),
      alt: 'mariona-caldentey-prensa-mundo-deportivo-3'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  },  {
    id: 48,
    title: 'UNIK TEAM | Lola Gallardo | Jugadora de fútbol femenino',
    metadescription: 'Lola Gallardo, futbolista española, actualmente juega como portera en el Olympique de Lyon de la Division 1 Féminine y en la Selección Española.',
    url: require('../images/players-profile-photo/lola-gallardo-olympique-lyon.png'),
    alt: 'lola-gallardo-olympique-lyon',
    name: 'LOLA GALLARDO',
    nameUrl: 'lola-gallardo',
    team: 'Olympique de Lyon',
    league_es: 'Division 1 Féminine (Francia)',
    league_en: 'Division 1 Féminine (France)',
    league_pt: 'Division 1 Féminine (France)',
    selection_es: 'SEL. ESPAÑOLA',
    selection_en: 'SPANISH TEAM',
    selection_pt: 'SEL. ESPANHOLA',
    mainPosition_es : 'PORTERA',
    mainPosition_en: 'GOALKEEPER',
    mainPosition_pt: 'GOLEIRA',
    birthDate: '10 - 06 - 1993',
    birthYear: '1993',
    town: 'Sevilla',
    country: 'España',
    nationality_es : 'Española',
    nationality_en : 'Spanish',
    nationality_pt: 'Espanhola',
    height: '1,74',
    weight: '61',
    signaturePhoto: require('../images/players/lola-gallardo/jugadora-lola-gallardo-unik-team.png'),
    signaturePhotoAlt: 'jugadora-lola-gallardo-unik-team',
    headerPhotos: [{
      id: 1,
      url: require('../images/players/lola-gallardo/header/lola-gallardo-olympique-lyon.jpg'),
      alt: 'lola-gallardo-olympique-lyon'
    }, {
      id: 2,
      url: require('../images/players/lola-gallardo/header/lola-gallardo-seleccion-espanola-futbol-femenino.jpg'),
      alt: 'lola-gallardo-seleccion-espanola-futbol-femenino'
    }],
    mobileHeaderPhoto: require('../images/players/lola-gallardo/header-mobile/lola-gallardo-seleccion-espanola-futbol-femenino..jpg'),
    mobileHeaderPhotoAlt: 'lola-gallardo-seleccion-espanola-futbol-femenino',
    nationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2018 - 2019',
      years_en: '2018 - 2019',
      years_pt: '2018 - 2019'
    }, {
      id: 2,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2017 - 2018',
      years_en: '2017 - 2018',
      years_pt: '2017 - 2018'
    }, {
      id: 3,
      nameFirstRow_es: 'Primera División Española',
      nameFirstRow_en: 'Spanish First Division',
      nameFirstRow_pt: 'Primeira Divisão Espanhola',
      nameSecondRow_es: '"Primera Iberdrola"',
      nameSecondRow_en: '"Primera Iberdrola"',
      nameSecondRow_pt: '"Primera Iberdrola"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2016 - 2017',
      years_en: '2016 - 2017',
      years_pt: '2016 - 2017'
    }, {
      id: 4,
      nameFirstRow_es: 'Subcampeona de la Copa de España',
      nameFirstRow_en: 'Runner-up of the Spanish Cup',
      nameFirstRow_pt: 'Vice-campeã da Copa da Espanha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2019',
      years_en: '2019',
      years_pt: '2019'
    }, {
      id: 5,
      nameFirstRow_es: 'Subcampeona de la Copa de España',
      nameFirstRow_en: 'Runner-up of the Spanish Cup',
      nameFirstRow_pt: 'Vice-campeã da Copa da Espanha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2018',
      years_en: '2018',
      years_pt: '2018'
    }, {
      id: 6,
      nameFirstRow_es: 'Subcampeona de la Copa de España',
      nameFirstRow_en: 'Runner-up of the Spanish Cup',
      nameFirstRow_pt: 'Vice-campeã da Copa da Espanha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2017',
      years_en: '2017',
      years_pt: '2017'
    }, {
      id: 7,
      nameFirstRow_es: 'Copa de España',
      nameFirstRow_en: 'Spanish Cup',
      nameFirstRow_pt: 'Copa da Espanha',
      nameSecondRow_es: '"Copa de la Reina"',
      nameSecondRow_en: '"Copa de la Reina"',
      nameSecondRow_pt: '"Copa de la Reina"',
      team_es: 'Atlético de Madrid',
      team_en: 'Atlético de Madrid',
      team_pt: 'Atlético de Madrid',
      years_es: '2016',
      years_en: '2016',
      years_pt: '2016'
    }],
    internationalTournament: [{
      id: 1,
      nameFirstRow_es: 'Champions League',
      nameFirstRow_en: 'Champions League',
      nameFirstRow_pt: 'Champions League',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Olympique de Lyon',
      team_en: 'Olympique de Lyon',
      team_pt: 'Olympique de Lyon',
      years_es: '2019 - 2020',
      years_en: '2019 - 2020',
      years_pt: '2019 - 2020'
    }, {
      id: 2,
      nameFirstRow_es: 'Eurocopa Sub-17',
      nameFirstRow_en: 'UEFA Euro U-17',
      nameFirstRow_pt: 'Eurocopa Sub-17',
      nameSecondRow_es: '',
      nameSecondRow_en: '',
      nameSecondRow_pt: '',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Suiza 2010',
      years_en: 'Switzerland 2010',
      years_pt: 'Suíça 2010'
    }, {
      id: 3,
      nameFirstRow_es: 'Tercer lugar en el ',
      nameFirstRow_en: 'Third place in the ',
      nameFirstRow_pt: 'Terceiro lugar na',
      nameSecondRow_es: 'Mundial Sub-17',
      nameSecondRow_en: 'U-17 World Cup',
      nameSecondRow_pt: 'Copa do Mundo Sub-17',
      team_es: 'Selección Española',
      team_en: 'Spanish National Team',
      team_pt: 'Seleção Espanhola',
      years_es: 'Trinidad y Tobago 2010',
      years_en: 'Trinidad y Tobago 2010',
      years_pt: 'Trinidad y Tobago 2010'
    }],
    individualAwards: [{
      id: 1,
      nameFirstRow_es: 'Mejor Jugadora',
      nameFirstRow_en: 'Best Player',
      nameFirstRow_pt: 'Melhor Jogadora',
      nameSecondRow_es: 'Eurocopa Sub-17',
      nameSecondRow_en: 'UEFA Euro U-17',
      nameSecondRow_pt: 'Eurocopa Sub-17',
      years_es: 'Suiza 2010',
      years_en: 'Switzerland 2010',
      years_pt: 'Suíça 2010'
    }, {
      id: 2,
      nameFirstRow_es: 'Guante de Oro',
      nameFirstRow_en: 'Golden Glove',
      nameFirstRow_pt: 'Luva de Ouro',
      nameSecondRow_es: 'Mundial Sub-17',
      nameSecondRow_en: 'U-17 World Cup',
      nameSecondRow_pt: 'Copa do Mundo Sub-17',
      years_es: 'Trinidad y Tobago 2010',
      years_en: 'Trinidad y Tobago 2010',
      years_pt: 'Trinidad y Tobago 2010'
    }, {
      id: 3,
      nameFirstRow_es: 'Once de Oro',
      nameFirstRow_en: 'Best 11',
      nameFirstRow_pt: 'Onze de Ouro',
      nameSecondRow_es: 'Fútbol Draft',
      nameSecondRow_en: 'Fútbol Draft',
      nameSecondRow_pt: 'Fútbol Draft',
      years_es: '2010',
      years_en: '2010',
      years_pt: '2010',
    }, {
      id: 4,
      nameFirstRow_es: 'Once de Oro',
      nameFirstRow_en: 'Best 11',
      nameFirstRow_pt: 'Onze de Ouro',
      nameSecondRow_es: 'Fútbol Draft',
      nameSecondRow_en: 'Fútbol Draft',
      nameSecondRow_pt: 'Fútbol Draft',
      years_es: '2013',
      years_en: '2013',
      years_pt: '2013',
    }, {
      id: 4,
      nameFirstRow_es: 'Once ideal',
      nameFirstRow_en: 'Best 11',
      nameFirstRow_pt: 'Onze de Ouro',
      nameSecondRow_es: 'J21 de la Primera Iberdrola',
      nameSecondRow_en: '"Primera Iberdrola" 7th Round',
      nameSecondRow_pt: 'Rodada 21 da "Primera Iberdrola"',
      years_es: '2020',
      years_en: '2020',
      years_pt: '2020',
    }],
    skilledLeg_es: 'Diestra',
    skilledLeg_en: 'Right-footed',
    skilledLeg_pt: 'Destra',
    skilledLegNum: 13,
    technicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Blocking',
      characteristic_pt: 'Agarrar',
      characteristic_es: 'Blocaje',
      num: 4
    }, {
      id: 2,
      characteristic_en: 'Punt',
      characteristic_pt: 'Espalmar',
      characteristic_es: 'Despeje',
      num: 12
    }, {
      id: 3,
      characteristic_en: 'Ball control with feet',
      characteristic_pt: 'Domínio bola c/ pés',
      characteristic_es: 'Dominio balón con los pies',
      num: 14
    }],
    tacticalCharacteristics: [{
      id: 1,
      characteristic_en: 'Space control',
      characteristic_pt: 'Colocação no campo',
      characteristic_es: 'Colocación',
      num: 6
    }, {
      id: 2,
      characteristic_en: 'Communication skills',
      characteristic_pt: 'Comunicação',
      characteristic_es: 'Comunicación',
      num: 7
    }],
    psychologicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Leader',
      characteristic_pt: 'Líder',
      characteristic_es: 'Líder',
      num: 23
    }],
    physicalCharacteristics: [{
      id: 1,
      characteristic_en: 'Agility/Flexibility',
      characteristic_pt: 'Agilidade/Flexibilidade',
      characteristic_es: 'Agilidad/Flexibilidad',
      num: 15
    }, {
      id: 2,
      characteristic_en: 'Reflexes',
      characteristic_pt: 'Reflexos',
      characteristic_es: 'Reflejos',
      num: 29
    }],
    positions: [{
      id: 0,
      selected: true,
      position: 'POR'
    },{
      id: 1,
      selected: false,
      position: 'LI'
    },{
      id: 2,
      selected: false,
      position: 'DFCi'
    },{
      id: 3,
      selected: false,
      position: 'DFCd'
    },{
      id: 4,
      selected: false,
      position: 'LD'
    },{
      id: 5,
      selected: false,
      position: 'LIB'
    },{
      id: 6,
      selected: false,
      position: 'MC'
    },{
      id: 7,
      selected: false,
      position: 'MCO'
    },{
      id: 8,
      selected: false,
      position: 'INTi'
    },{
      id: 9,
      selected: false,
      position: 'INTd'
    },{
      id: 10,
      selected: false,
      position: 'MP'
    },{
      id: 11,
      selected: false,
      position: 'EXTi'
    },{
      id: 12,
      selected: false,
      position: 'EXTd'
    },{
      id: 13,
      selected: false,
      position: 'SDC'
    },{
      id: 14,
      selected: false,
      position: 'DC'
    }],
    trajectory: [{
      id: 1,
      team: 'OLYMPIQUE DE LYON',
      country: 'España',
      nameSecondRow_pt: '',
      years_es: '2020 - Actualidad',
      years_en: '2020 - Currently',
      years_pt: '2020 - Presente',
      image: require('../images/clubs/trayectoria/olympique-lyonnais-francia-futbol-femenin..png'),
      alt: 'olympique-lyonnais-francia-futbol-femenin'
    }, {
      id: 2,
      team: 'ATLÉTICO MADRID',
      country: 'España',
      years_es: '2012 - 2020',
      years_en: '2012 - 2020',
      years_pt: '2012 - 2020',
      image: require('../images/clubs/trayectoria/atletico-de-madrid-espana-futbol-femenino.png'),
      alt: 'atletico-de-madrid-espana-futbol-femenino'
    }, {
      id: 3,
      team: 'SPORTING HUELVA',
      country: 'España',
      nameSecondRow_pt: '',
      years_es: '2011 - 2012',
      years_en: '2011 - 2012',
      years_pt: '2011 - 2012',
      image: require('../images/clubs/trayectoria/sporting-club-huelva-espana-futbol-femenino.png'),
      alt: 'sporting-club-huelva-espana-futbol-femenino'
    }, {
      id: 4,
      team: 'SEVILLA FC',
      country: 'España',
      years_es: '2008 - 2011',
      years_en: '2008 - 2011',
      years_pt: '2008 - 2011',
      image: require('../images/clubs/trayectoria/sevilla-fc-espana-futbol-femenino.png'),
      alt: 'sevilla-fc-espana-futbol-femenino'
    }],
    instagramAccessToken: '',
    instagramUsername: 'lola_gallardo1',
    instagramId: '456422000',
    instagram: 'https://www.instagram.com/lola_gallardo1/',
    twitter: 'https://twitter.com/Lola_Gallardo1',
    facebook: 'https://www.facebook.com/Lola1Gallardo',
    tikTok: '',
    lastsCampaigns: [{
      id: 1,
      brand: 'EA SPORTS FIFA',
      url: 'https://www.instagram.com/p/CG3CvpUAh4J/',
      image: require('../images/players/lola-gallardo/last-campaigns/lola-gallardo-colab-ea-sports-fifa.jpg'),
      alt: 'lola-gallardo-colab-ea-sports-fifa'
    }, {
      id: 2,
      brand: 'CLUB DEL DEPORTISTA',
      url: 'https://www.instagram.com/p/CB9AB_3pi6g/',
      image: require('../images/players/lola-gallardo/last-campaigns/lola-gallardo-colab-club-de-deportista.jpg'),
      alt: 'lola-gallardo-colab-club-de-deportista'
    }, {
      id: 3,
      brand: 'NIKE',
      url: 'https://www.instagram.com/p/B-EqQ8yChQR/',
      image: require('../images/players/lola-gallardo/last-campaigns/lola-gallardo-colab-nike.jpg'),
      alt: 'lola-gallardo-colab-nike'
    }, {
      id: 4,
      brand: 'MY MASK',
      url: 'https://www.instagram.com/p/CAAPwqag5W-/',
      image: require('../images/players/lola-gallardo/last-campaigns/lola-gallardo-colab-my-mask.jpg'),
      alt: 'lola-gallardo-colab-my-mask'
    }, {
      id: 5,
      brand: 'MUJER HOY',
      url: 'https://www.instagram.com/p/B9Zkv6nC78R/',
      image: require('../images/players/lola-gallardo/last-campaigns/lola-gallardo-colab-mujer-hoy.jpg'),
      alt: 'lola-gallardo-colab-mujer-hoy'
    }, {
      id: 6,
      brand: 'JUNTAS VENCEREMOS',
      url: 'https://www.instagram.com/p/B-rLIikgZp_/',
      image: require('../images/players/lola-gallardo/last-campaigns/lola-gallardo-colab-juntas-venceremos.jpg'),
      alt: 'lola-gallardo-colab-juntas-venceremos'
    }],
    press: [{
      id: 1,
      media: 'MARCA',
      url: 'https://www.marca.com/futbol/futbol-femenino/seleccion/2020/10/23/5f91e7d1268e3e85688b45e8.html',
      image: require('../images/players/lola-gallardo/press/marca.jpg'),
      alt: 'lola-gallardo-marca'
    }, {
      id: 2,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2020/10/13/femenino/1602592787_155472.html',
      image: require('../images/players/lola-gallardo/press/Diario AS.jpg'),
      alt: 'lola-gallardo-portera-diario-as'
    }, {
      id: 3,
      media: 'RTVE',
      url: 'https://www.rtve.es/alacarta/videos/programa/lola-gallardo-quiero-titulo-importante-seleccion/5689748/',
      image: require('../images/players/lola-gallardo/press/tve.jpg'),
      alt: 'lola-gallardo-rtve'
    }, {
      id: 4,
      media: 'LA VANGUARDIA',
      url: 'https://www.lavanguardia.com/deportes/20201021/484212475835/lola-gallardo-poder-entrenar-con-las-mejores-para-mi-ya-es-un-logro.html',
      image: require('../images/players/lola-gallardo/press/Vanguardia 1.jpg'),
      alt: 'lola-gallardo-portera-la-vanguardia'
    }, {
      id: 5,
      media: 'MUNDO DEPORTIVO',
      url: 'https://www.mundodeportivo.com/futbol/femenino/20200831/483222230463/lola-gallardo-campeona-de-la-champions-en-apenas-dos-meses.html',
      image: require('../images/players/lola-gallardo/press/Mundo deportivo.jpg'),
      alt: 'lola-gallardo-mundo-deportivo'
    }, {
      id: 6,
      media: 'TELEMADRID',
      url: 'http://www.telemadrid.es/programas/el-partido-de-la-una/Lola-Gallardo-llama-mejor-equipo-9-2266663339--20200908052120.html',
      image: require('../images/players/lola-gallardo/press/Tele Madrid.jpg'),
      alt: 'lola-gallardo-telemadrid'
    }, {
      id: 7,
      media: 'DIARIO AS',
      url: 'https://as.com/futbol/2020/08/28/femenino/1598622716_901299.html',
      image: require('../images/players/lola-gallardo/press/diario as 2.jpg'),
      alt: 'lola-gallardo-diario-as'
    }, {
      id: 8,
      media: 'LA VANGUARDIA',
      url: 'https://www.lavanguardia.com/deportes/20201023/484243097921/lola-gallardo-titular-ante-la-republica-checa-en-la-cartuja.html',
      image: require('../images/players/lola-gallardo/press/Vanguardia 2.jpg'),
      alt: 'lola-gallardo-la-vanguardia'
    }],
    instagramImages: [{
      id: 1,
      url: ''
    }, {
      id: 2,
      url: ''
    }, {
      id: 3,
      url: ''
    }, {
      id: 4,
      url: ''
    }, {
      id: 5,
      url: ''
    }, {
      id: 6,
      url: ''
    }]
  }
];