import React, { useState, useEffect } from 'react';
import '../../../App.css';
import './Trajectory-bar.css'
import Grid from '@material-ui/core/Grid';
import {useTranslation} from "react-i18next";

const leftArrow = require('../../../images/left_arrow.png');
const rightArrow = require('../../../images/right_arrow.png');

const TrajectoryBar = ({trajectory}) => {

  const [originalTrajectory, setOriginalTrajectory] = useState([]);
  const [trajectoryList, setTrajectoryList] = useState([]);
  const [numPressList, setNumPressList] = useState({
    initial: 0,
    end: 5,
  });
  const [width, setWidth] = useState(0);
  const [numDependingWidth, setNumDependingWidth] = useState(5);

  const [lang, setLang] = useState('en');
  const [t, i18n] = useTranslation();

  useEffect(() => {
    setLang(i18n.languages[1]);
    console.log('player', i18n.languages[1]);
  }, [i18n.languages[1]]);

  useEffect(() => {
    let srch = trajectory.filter((item) => item.id < numDependingWidth);
    setTrajectoryList(() => [...srch]);
    setOriginalTrajectory(() => [...trajectory]);
  }, [trajectory]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let wdth = width;
    if(wdth === 0) {
      wdth = window.innerWidth;
      setWidth(wdth);
    }
    if(wdth <= 960 && wdth >= 600) {
      setNumDependingWidth(4);
      setNumPressList((prev) => {
        return({
          initial: prev.initial,
          end: prev.initial + 4,
        })
      });
    } else if(wdth < 600) {
      setNumDependingWidth(3);
      setNumPressList((prev) => {
        return({
          initial: prev.initial,
          end: prev.initial + 3,
        })
      });
    } else {
      setNumDependingWidth(5);
      setNumPressList((prev) => {
        return({
          initial: prev.initial,
          end: prev.initial + 5,
        })
      });
    }
  }, [width]);

  const handleResize = () => {
    const windowSize = window.innerWidth;
    setWidth(windowSize);
  };

  useEffect(() => {
    let srch = trajectory.filter((item) => item.id < numDependingWidth);
    setTrajectoryList(() => [...srch]);
    setOriginalTrajectory(() => [...trajectory]);
  }, []);

  useEffect(() => {
    let srch = trajectory.filter((item) => item.id > numPressList.initial && item.id < numPressList.end);
    setTrajectoryList(() => [...srch]);
    setOriginalTrajectory(() => [...trajectory]);
  }, [numPressList.initial, numPressList.end]);

  const goForward = () => {
    let initial = numPressList.initial + 1;
    let end = numPressList.end + 1;
    let array = [...originalTrajectory];

    setNumPressList((nums) => {
      nums.initial = initial;
      nums.end = end;
      return nums;
    });
    setTrajectoryList(() => array.filter((item, index) => index + 1 > initial && index + 1 < end));

  };

  const goBackward = () => {
    let initial = numPressList.initial - 1;
    let end = numPressList.end - 1;
    let array = [...originalTrajectory];

    setNumPressList((nums) => {
      nums.initial = initial;
      nums.end = end;
      return nums;
    });
    setTrajectoryList(() => array.filter((item, index) => index + 1 > initial && index + 1 < end));

  };

  return(
    <div>
      <Grid container
            spacing={0}
            direction="row"
            alignItems="flex-start"
            justify="center">
        {
          (trajectory && numPressList.initial !== 0) ?
              <Grid item className="arrows" xs={1}
                    onClick={() => goBackward()}>
                <div className="arrowsDiv" style={{
                  /* Full height */
                  width: '100%',
                  height: '100%',
                  margin: 'auto',
                  cursor: 'pointer'
                  }}>
                  <img src={leftArrow}
                       alt="left-arrow"
                       className="arrowsImg"/>
                </div>
              </Grid> : <Grid item className="arrows" xs={1}>
                <div style={{
                  /* Full height */
                  width: '100%',
                  height: '100%',
                  margin: 'auto'
                }}>
                </div>
              </Grid>
        }
        <Grid item xs={10}>
          <Grid container
                spacing={0}
                direction="row"
                alignItems="center"
                justify="flex-start">
            {
              (trajectoryList && trajectoryList[0]) ?
                <Grid item xs={6} sm={4} md={3}
                      id="firstColumn"
                      className="borderLeft">
                  <div className="teamTrajectory">
                    {trajectoryList[0].team}
                  </div>
                  <div className="yearsTrajectory">
                    {trajectoryList[0]['years_'+lang]}
                  </div>
                  <img className="escudo"
                       src={trajectoryList[0].image}
                       alt={trajectoryList[0].alt}/>
                </Grid> : null
            }
            {
              (trajectoryList && trajectoryList[1]) ?
                <Grid item xs={6} sm={4} md={3}
                      className="borderLeft">
                  <div className="teamTrajectory">
                    {trajectoryList[1].team}
                  </div>
                  <div className="yearsTrajectory">
                    {trajectoryList[1]['years_'+lang]}
                  </div>
                  <img className="escudo"
                       src={trajectoryList[1].image}
                       alt={trajectoryList[1].alt}/>
                </Grid> : null
            }
            {
              (trajectoryList && trajectoryList[2]) ?
                <Grid item xs={6} sm={4} md={3}
                      className="borderLeft">
                  <div className="teamTrajectory">
                    {trajectoryList[2].team}
                  </div>
                  <div className="yearsTrajectory">
                    {trajectoryList[2]['years_'+lang]}
                  </div>
                  <img className="escudo"
                       src={trajectoryList[2].image}
                       alt={trajectoryList[2].alt}/>
                </Grid> : null
            }
            {
              (trajectoryList && trajectoryList[3]) ?
                <Grid item xs={6} sm={4} md={3}
                      className="bordersHorizontal">
                  <div className="teamTrajectory">
                    {trajectoryList[3].team}
                  </div>
                  <div className="yearsTrajectory">
                    {trajectoryList[3]['years_'+lang]}
                  </div>
                  <img className="escudo"
                       src={trajectoryList[3].image}
                       alt={trajectoryList[3].alt}/>
                </Grid> : null
            }
            {
              (trajectoryList && !trajectoryList[3] ) ?
                <Grid item xs={6} sm={4} md={3}
                      className="borderLeftExtra">

                </Grid> : null
            }
          </Grid>
        </Grid>
        {
          (trajectory && trajectory.length > numPressList.end - 1 && numPressList.end !== trajectory.length + 1) ?
            <Grid item className="arrows" xs={1}
                  onClick={() => goForward()}>
                  <div className="arrowsDiv" style={{
                    /* Full height */
                    width: '100%',
                    height: '100%',
                    margin: 'auto',
                    cursor: 'pointer'
                  }}>
                    <img src={rightArrow}
                         alt="right-arrow"
                         className="arrowsImg"/>
                  </div>
            </Grid> : <Grid item className="arrows" xs={1}>
              <div style={{
                /* Full height */
                width: '100%',
                height: '100%',
                margin: 'auto'
              }}>
              </div>
            </Grid>
        }
      </Grid>
    </div>
  )
};

export default TrajectoryBar;