import * as actionTypes from '../actions/actionTypes';

const initialState = {
  selectedPage: '/'
};

// Set Modified
const setSelectedPage = (state, action) => {
  state.selectedPage = action.selectedPage;
  return state;
};


const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SELECTED_PAGE: return setSelectedPage(state, action);
    default: return state;
  }
};

export default reducer;